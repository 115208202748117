export const THREAD_TYPE_ANNOUNCEMENT = 'announcement';
export const THREAD_TYPE_COMMUNITY = 'communityThread';
export const THREAD_TYPE_EVENT = 'eventThread';
export const THREAD_TYPE_DIRECT = 'chatDirect';
export const THREAD_TYPE_GROUP = 'chatGroup';
export const THREAD_TYPE_SUPPORT = 'support';

export const IsThreadTypeCommunityBased = (threadType?: string): boolean => {
  switch (threadType) {
    case THREAD_TYPE_ANNOUNCEMENT:
    case THREAD_TYPE_COMMUNITY:
    case THREAD_TYPE_EVENT:
      return true;
  }
  return false;
};

export const IsPrivateThreadType = (threadType?: string): boolean => {
  switch (threadType) {
    case THREAD_TYPE_DIRECT:
    case THREAD_TYPE_GROUP:
    case THREAD_TYPE_SUPPORT:
      return true;
  }
  return false;
};
