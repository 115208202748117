import React, {useContext} from 'react';
import {StyleSheet, Text, TouchableWithoutFeedback} from 'react-native';
import {ChatScreenContext} from './ChatScreenContext';

type Props = {
  onPress?: () => void,
  userId?: string,
  value: string,
};

export const MentionText = React.memo(({onPress, userId, value}: Props) => {
  const {onUserPress} = useContext(ChatScreenContext);

  return (
      <TouchableWithoutFeedback onPress={() => {
        if (userId) {
          onUserPress(userId);
        }
        if (typeof onPress === 'function') {
          onPress();
        }
      }}>
        <Text style={styles.mentionText}>
          {value}
        </Text>
      </TouchableWithoutFeedback>
  );
});

const styles = StyleSheet.create({
  mentionText: {
    fontWeight: 'bold',
  },
});