import React from 'react';
import {Title} from 'react-native-paper';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {TextStyle} from 'react-native';

export const HeyloTitle = (
    {
      children,
      style,
    }: {
      children: any,
      style?: TextStyle
    }) => {
  const styles = useTextStyles();
  return <Title style={style ?? styles.h6}>
    {children}
  </Title>;
};