import React from 'react';
import {SignInLinkScreen} from '../auth/SignInLinkScreen';
import {Route} from 'react-router-dom';

export const AuthApp = () => {
  return (
      <Route path={'/confirmEmail'}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          minHeight: '100vh',
          maxHeight: '100vh',
        }}>
          <SignInLinkScreen/>
        </div>
      </Route>
  );
};