import React, {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {ActivityIndicator, HelperText} from 'react-native-paper';
import {EmailTextInput} from '@heylo/components/src/features/auth/EmailTextInput';
import {PasswordTextInput} from '@heylo/components/src/features/auth/PasswordTextInput';
import {ResetPassword} from '@heylo/shared/src/features/auth/ResetPassword';
import {LoginState} from '@heylo/shared/src/features/auth/useLoginScreenState';
import {HeyloButton} from '@heylo/components/src/ui/button/HeyloButton';
import {EmailVerificationResult} from '@heylo/components/src/features/auth/EmailVerificationResult';
import {EmailVerificationContext} from '@heylo/shared/src/features/auth/Slice';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {HeyloHyperlink} from '../../ui/link/Hyperlink';

type Props = {
  accountHasPassword?: boolean,
  initialEmail?: string
  onReset: () => void,
  onSubmitEmail: (email: string) => void,
  onSubmitEmailPassword: (email: string, password: string) => void,
  onSwitchToPassword: () => void,
  onSignInWithLink: (email: string, signInLink: string) => Promise<any>,
  state: LoginState,
}

export const LoginForm = (props: Props) => {
  const {
    accountHasPassword,
    initialEmail,
    onReset: parentOnReset,
    onSignInWithLink,
    onSubmitEmail,
    onSubmitEmailPassword,
    onSwitchToPassword,
    state,
  } = props;

  const [email, setEmail] = useState(initialEmail || '');
  const submitEmail = () => {
    onSubmitEmail(email);
  };

  const [password, setPassword] = useState('');
  const submitPassword = () => {
    onSubmitEmailPassword(email, password);
  };

  const onReset = () => {
    setEmail('');
    setPassword('');
    parentOnReset();
  }

  const text = useTextStyles();

  return (
      <View style={styles.loginContainer}>
        {state === LoginState.EMAIL_LINK_SENT ? (
            <>
              <EmailVerificationResult
                  context={EmailVerificationContext.LOGIN}
                  onReset={onReset}
                  onSignIn={onSignInWithLink}
              />
              {accountHasPassword && (
                  <View style={styles.containerButton}>
                    <HeyloButton
                        label={'use password instead'}
                        mode={'outlined'}
                        onPress={onSwitchToPassword}
                    />
                  </View>
              )}
            </>
        ) : (state === LoginState.PASSWORD_REQUIRED
            || state === LoginState.PASSWORD_SUBMITTED
            || state === LoginState.PASSWORD_ERROR) ? (
            <>
              <Text style={[text.body1, text.center]}>
                Enter password for <Text
                  style={{fontWeight: 'bold'}}>{email}</Text>
              </Text>
              <View style={styles.inputWrapper}>
                <PasswordTextInput
                    autoFocus={true}
                    onChangeText={setPassword}
                    onSubmitEditing={submitPassword}
                />
              </View>
              {state === LoginState.PASSWORD_ERROR && (
                  <HelperText type={'error'}>
                    The email and password combination doesn't match. Please double check your entry and try again!
                  </HelperText>
              )}
              <View style={styles.containerButton}>
                <HeyloButton
                    busy={state === LoginState.PASSWORD_SUBMITTED}
                    onPress={submitPassword}
                    label={'Login'}
                />
              </View>
              <ResetPassword email={email}/>
            </>
        ) : state === LoginState.SUCCESS ? (
            <View style={{alignItems: 'center'}}>
              <ActivityIndicator size={'large'}/>
              <Text style={[text.body1, text.center, {marginTop: StyleConstants.SPACING}]}>
                Loading your data...
              </Text>
            </View>
        ) : (
            <>
              <View style={styles.inputWrapper}>
                <EmailTextInput
                    autoFocus={true}
                    onChangeText={setEmail}
                    onSubmitEditing={submitEmail}
                    value={email}
                />
                {state === LoginState.EMAIL_ERROR && (
                    <HelperText type={'error'}>
                      There's something wrong with the email you entered. Please double check it.
                    </HelperText>
                )}
                {state === LoginState.ERROR_OTHER && (
                    <HelperText type={'error'}>
                      Oops, we could not find an account matching this email. Please double check your entry.
                    </HelperText>
                )}
              </View>
              <View style={styles.containerButton}>
                <HeyloButton
                    busy={state === LoginState.EMAIL_SUBMITTED}
                    label={'Next'}
                    onPress={submitEmail}
                />
              </View>
            </>
        )}

        {(state === LoginState.EMAIL_REQUIRED || state === LoginState.EMAIL_ERROR) ? (
            <View style={styles.textContainer}>
              <HeyloHyperlink
                  linkText={url => url === 'https://www.heylo.co/terms' ? 'Terms of Service' : 'Privacy Policy'}
              >
                <Text
                    style={{
                      fontSize: 14,
                      textAlign: 'center',
                      color: '#999898',
                    }}>
                  By logging in to Heylo, you agree to our
                  https://www.heylo.co/terms and
                  https://www.heylo.co/privacy.
                </Text>
              </HeyloHyperlink>
            </View>
        ) : null}
      </View>
  );
};

const styles = StyleSheet.create({
  containerButton: {
    alignItems: 'center',
    marginTop: StyleConstants.SPACING,
  },
  loginContainer: {
    maxWidth: 400,
    paddingHorizontal: StyleConstants.SPACING * 2,
    paddingVertical: StyleConstants.SPACING,
    width: '100%',
  },
  inputWrapper: {
    marginTop: StyleConstants.SPACING,
    paddingHorizontal: 5,
  },
  textContainer: {
    alignItems: 'center',
    marginTop: StyleConstants.SPACING * 2,
    marginHorizontal: StyleConstants.SPACING * 2,
  },
});