import {Query, Reference} from '@heylo/firebase-database';
import retry from 'retry';

type ListenersMap = { [key: string]: { [listenerKey: string]: Query | Reference } };

export const Listeners: ListenersMap = {
  COMMUNITIES: {},
  COMMUNITY_DELETIONS: {},
  COMMUNITY_MEMBERS: {},
  COMMUNITY_MEMBER_FIELD_RESPONSES: {},
  COMMUNITY_MEMBER_FIELD_SPEC: {},
  COMMUNITY_PER_USER_THREAD_STATE: {},
  COMMUNITY_PROFILES: {},
  COMMUNITY_PROSPECTS: {},
  COMMUNITY_SHARED_THREAD_STATE: {},
  COMMUNITY_THREADS: {},
  DIRECT_CHAT_LOOKUP: {},
  DOCUMENTS: {},
  DOCUMENTS_COMMUNITY_DOCUMENTS: {},
  EVENTS: {},
  EVENT_ATTENDANCE: {},
  MARKETPLACE_COMMUNITIES: {},
  MARKETPLACE_MEMBERSHIPS: {},
  NETWORK: {},
  PHOTOS_COMMUNITY_ALBUMS: {},
  PHOTOS_COMMUNITY_PHOTOS: {},
  SIGN_IN_EMAILS: {},
  STRIPE_ACCOUNTS: {},
  STRIPE_COMMUNITY_METADATA: {},
  STRIPE_COMMUNITY_PAYMENTS: {},
  STRIPE_EVENT_USER_DONATIONS: {},
  STRIPE_USER: {},
  USER_CHAT_STATE_DIRECT: {},
  USER_CHAT_STATE_GROUP: {},
  USER_CHAT_STATE_SUPPORT: {},
  USER_SETTINGS: {},
  USER_SETTINGS_NOTIFICATIONS: {},
  USER_PROFILES: {},
};

export const ThreadListeners: ListenersMap = {
  DELETIONS: {},
  MEMBERS: {},
  MESSAGES: {},
  PRIVATE_CHAT_PHOTOS: {},
  REACTIONS: {},
  SHARED_STATE: {},
  UPDATES: {},
}

export const CleanUpOneThreadListeners = (threadId: string) => {
  for (const [name, mapping] of Object.entries(ThreadListeners)) {
    const ref = mapping[threadId];
    if (ref && typeof ref.off === 'function') {
      ref.off();
    }
    delete mapping[threadId];
  }
};

const cleanupAllListeners = (obj: any) => {
  for (const [name, mapping] of Object.entries(Listeners)) {
    for (const ref of Object.values(mapping || {})) {
      if (ref && typeof ref.off === 'function') {
        ref.off();
      }
    }
    obj[name] = {};
  }
};

export const CleanUpAllSharedListeners = () => {
  cleanupAllListeners(Listeners);
  cleanupAllListeners(ThreadListeners);
};


export const RetriableFirebaseListener = (callback: (shouldRetryCb: (e: Error) => boolean) => void) => {
  const options = {
    // minTimeout: 500
  };
  var operation = retry.operation(options);

  const shouldRetry = (e: Error) => {
    // @ts-ignore
    return e?.code === 'database/permission-denied'
        && operation.retry(e);
  };

  operation.attempt(currentAttempt => {
    // console.warn('current attempt', currentAttempt);
    callback(shouldRetry);
  });
};