import {
  PhotoMetadata,
  SharedPhoto,
} from '@heylo/shared/src/types/firebase-types';

const ConvertPhotoMetadataToArray = (metadata: PhotoMetadata): [string, number, number] => {
  const {dimensionsHeight, dimensionsWidth, url} = metadata;
  return [url || '', dimensionsWidth || 0, dimensionsHeight || 0];
};

export const GetBestSharedPhoto = (sharedPhoto: SharedPhoto | null, size: number): [string, number, number] => {
  if (!sharedPhoto) {
    return ['', 0, 0];
  }
  const {imageLowRes, imageMediumRes, imageHighRes} = sharedPhoto;
  if (size <= 200 && imageLowRes) {
    return ConvertPhotoMetadataToArray(imageLowRes);
  }
  if (size <= 500 && imageMediumRes) {
    return ConvertPhotoMetadataToArray(imageMediumRes);
  }
  if (imageHighRes) {
    return ConvertPhotoMetadataToArray(imageHighRes);
  }
  return ['', 0, 0];
};
