import React from 'react';
import './App.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {Provider} from 'react-redux';
import store, {persistor} from './store';
import {Home} from 'features/navigation/Home';
import {ThemeProvider} from '@material-ui/core/styles';
import Theme from './styles/Theme';
import {LoggingContextProvider} from 'features/logging/LoggingContextProvider';
import {AuthGate} from 'features/auth';
import {BrowserRouter as Router} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {HeyloTheme} from '@heylo/shared/src/services/paper/Theme';
import {Portal, Provider as PaperProvider} from 'react-native-paper';
import {AppBootstrap} from '@heylo/shared/src/features/AppBootstrap';
import {SignInEmailListeners} from '@heylo/components/src/features/auth/SignInEmailListeners';
import {HeyloConfigContextProvider} from '@heylo/shared/src/features/app/useHeyloConfig';
import {AuthApp} from './features/navigation/AuthApp';

export const App: React.FunctionComponent = () => {
  const includeAuthApp =
      process.env.REACT_APP_SITE_VARIANT === 'auth'
      || process.env.REACT_APP_SITE_VARIANT === 'dev';
  const includeWebApp =
      process.env.REACT_APP_SITE_VARIANT === 'app'
      || process.env.REACT_APP_SITE_VARIANT === 'dev';
  return (
      <HeyloConfigContextProvider
          buildVariant={process.env.REACT_APP_BUILD_VARIANT || ''}>
        {includeAuthApp ? (
            <Router>
              <AuthApp/>
            </Router>
        ) : null}
        {includeWebApp ? (
            <Provider store={store}>
              <PersistGate persistor={persistor}>
                <LoggingContextProvider>
                  <AppBootstrap>
                    <AuthGate>
                      <Router>
                        <PaperProvider theme={HeyloTheme}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={Theme}>
                              <style type="text/css">{`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'MaterialIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
        }
      `}
                              </style>
                              <Portal.Host>
                                <Home/>
                              </Portal.Host>
                              <SignInEmailListeners/>
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </PaperProvider>
                      </Router>
                    </AuthGate>
                  </AppBootstrap>
                </LoggingContextProvider>
              </PersistGate>
            </Provider>
        ) : null}
      </HeyloConfigContextProvider>
  );
};
