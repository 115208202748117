import React, {useState} from 'react';
import {HeyloButton, HeyloText} from '../../lib/materialUi';
import ShareIcon from '@material-ui/icons/Reply';
import Popover from '@material-ui/core/Popover';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {IncrementUserInteraction} from '@heylo/shared/src/features/userEvents/Actions';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {useDispatch} from 'react-redux';

type Props = {
  communityId: string,
};

export const ShareCommunityButton = (props: Props) => {
  const {communityId} = props;

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const logger = useLoggingService();

  const copyCommunityUrl = (e: any) => {
    setPopoverAnchorEl(e.currentTarget);
    setTimeout(() => setPopoverAnchorEl(null), 2000);
    navigator.clipboard.writeText(`https://app.heylo.co/community?communityId=${communityId}`);
    logger.logEvent(AnalyticsEvent.COMMUNITY_SHARE_LINK);
    dispatch(IncrementUserInteraction(AnalyticsEvent.COMMUNITY_SHARE_LINK));
  }

  return (
      <>
        <HeyloButton
            color={'primary'}
            fullWidth={false}
            label={'Share'}
            onClick={copyCommunityUrl}
            startIcon={<ShareIcon className={'fa-flip-horizontal'}/>}
        />
        <Popover
            anchorEl={popoverAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={!!popoverAnchorEl}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
        >
          <div style={{padding: '1rem'}}>
            <HeyloText variant={'body1'}>
              Group link copied to clipboard! 👍
            </HeyloText>
          </div>
        </Popover>
      </>
  );
};