import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {
  NewCommunityNameForm,
  NewCommunityNameFormState,
} from '@heylo/components/src/features/communityCreation/NewCommunityNameForm';
import {ActiveCommunityUpdated} from '@heylo/shared/src/features/communities/Slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {NextButton, ProgressHeader} from 'features/navigation/ProgressHeader';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  selectActiveUserId,
} from '@heylo/shared/src/features/auth/Selectors';

export const NewGroupNameScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    activeUserId,
  } = useSelector((state: RootState) => ({
    activeUserId: selectActiveUserId(state),
  }), shallowEqual);

  useEffect(() => {
    dispatch(ActiveCommunityUpdated(''));
  }, []);

  const [nameState, setNameState] = useState(NewCommunityNameFormState.IDLE);

  const onBackPress = () => {
    setNameState(NewCommunityNameFormState.RESET);
    history.goBack();
  };

  const [nextButtonVisible, setNextButtonVisible] = useState(false);

  const onSubmit = async () => {
    setNameState(NewCommunityNameFormState.SUBMIT_PRESSED);
  };

  useEffect(() => {
    switch (nameState) {
      case NewCommunityNameFormState.IDLE:
        setNextButtonVisible(false);
        break;
      case NewCommunityNameFormState.VALID:
        setNextButtonVisible(true);
        break;
      case NewCommunityNameFormState.SUBMIT_SUCCESS:
        history.push('/newGroup/location');
        break;
    }
  }, [nameState]);

  return (
      <ProgressHeader
          onBackPress={!!activeUserId ? null : onBackPress}
          nextButton={nextButtonVisible ? NextButton.NEXT : NextButton.HIDDEN}
          onNextPress={onSubmit}
          percent={'20%'}
          showSwitcherIcon={!!activeUserId}
          title={'Create group name'}
      >
        <NewCommunityNameForm state={nameState}
                              setState={setNameState}/>
      </ProgressHeader>
  );
};
