import React, {createContext, useContext} from 'react';
import {GetHeyloConfig, HeyloConfig} from '@heylo/shared/src/config/App';

const HeyloConfigContext = createContext<HeyloConfig>(GetHeyloConfig());

export const useHeyloConfig = () => {
  return useContext(HeyloConfigContext);
};

export const HeyloConfigContextProvider = (
    {
      buildVariant,
      children,
    }: {
      buildVariant?: string,
      children: any | any[],
    }) => {
  return (
      <HeyloConfigContext.Provider value={GetHeyloConfig(buildVariant)}>
        {children}
      </HeyloConfigContext.Provider>
  );
};
