import React from 'react';
import {IconButton} from 'react-native-paper';

type Props = {
  onPress?: () => void,
  size?: number,
}

export const CloseButton = (props: Props) => {
  const {
    onPress,
    size,
  } = props;

  return (
      <IconButton
          icon={'close'}
          onPress={onPress}
          size={size || 24}
      />
  );
};