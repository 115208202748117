import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {FirebaseDatabase, FirebaseFunctions} from '@heylo/firebase-database';
import isEmail from 'validator/lib/isEmail';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {LoggingService} from '@heylo/shared/src/services/logging/LoggingService';
import {v4 as uuidv4} from 'uuid';
import {
  EmailVerificationContext,
  SignInWithEmailComplete,
  SignInWithEmailLinkOpened,
  SignInWithEmailStarted,
} from '@heylo/shared/src/features/auth/Slice';
import {Listeners} from '../firebase/Listeners';
import {PendingEmailSignInByUser} from '../../types/firebase-types';

export class AuthFirebase {

  static AttachPendingEmailSignInListener = (userId: string, secret: string)
      : ThunkReturnType<void> => dispatch => {
    if (Listeners.SIGN_IN_EMAILS[secret]) {
      return;
    }
    const ref = FirebaseDatabase().ref(`/auth/pendingEmailVerificationByUser/${userId}/${secret}`);
    Listeners.SIGN_IN_EMAILS[secret] = ref;
    ref.on('value',
        snapshot => {
          const pending: PendingEmailSignInByUser = snapshot?.val() || {};
          const {signInLink = ''} = pending;
          dispatch(SignInWithEmailLinkOpened(signInLink));
        },
        (e: Error) => {
          delete Listeners.SIGN_IN_EMAILS[secret];
        });
  };

  static DetachPendingEmailSignInListener = (secret: string) => {
    Listeners.SIGN_IN_EMAILS[secret]?.off();
    delete Listeners.SIGN_IN_EMAILS[secret];
  }


  static SendPasswordResetEmail = (email: string, build: string, logger: LoggingService) => {
    if (!email || !isEmail(email)) {
      return Promise.reject('email-invalid');
    }
    return FirebaseFunctions().httpsCallable('sendResetPasswordEmail')({
      build,
      email,
    })
        .then(() => {
          logger.logEvent(AnalyticsEvent.ACCOUNT_PASSWORD_RESET_START);
        })
  };

  static SendSignInWithEmailLink = ({
                                      build,
                                      context,
                                      email,
                                      fullName = '',
                                    }: {
    build: string,
    context: EmailVerificationContext,
    email: string,
    fullName?: string,
  })
      : ThunkReturnType<Promise<void>> => dispatch => {
    const secret = uuidv4();
    return FirebaseFunctions().httpsCallable('sendSignInWithEmailLink')({
      build,
      email,
      secret,
    })
        .then(() => {
          // TODO: log success
          // AppLoggingService.logEvent(AnalyticsEvent.REGISTRATION_VERIFY_EMAIL_START);
          dispatch(SignInWithEmailStarted({
            context,
            email,
            fullName,
            secret,
          }));
        })
  };

  static VerifyEmailDomain = (email: string, signInLink: string)
      : ThunkReturnType<Promise<any>> => (dispatch) => {
    try {
      const secret = new URL(signInLink).searchParams.get('secret') || '';
      const params = {
        email,
        secret,
      };
      return FirebaseFunctions().httpsCallable('verifyEmailDomain')(params)
          .then(() => {
            dispatch(SignInWithEmailComplete());
          })
    } catch (e) {
      console.warn('VerifyEmailDomain error', signInLink, e);
      return Promise.reject();
    }
  }

  static SignInLinkOpened = async (signInLink: string): Promise<'bad-request' | 'expired' | 'success'> => {
    try {
      const linkSecret = new URL(signInLink).searchParams.get('secret') || '';
      const params = {
        secret: linkSecret,
        signInLink,
      };
      const response = await FirebaseFunctions().httpsCallable('signInLinkOpened')(params);
      switch (response?.data?.error) {
        case 'bad-request':
          return 'bad-request';
        case 'expired':
          return 'expired';
      }
      return 'success';
    } catch (e) {
      console.warn('SignInLinkOpened error', signInLink, e);
    }
    return 'bad-request';
  }


}
