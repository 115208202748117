import React from 'react';
import {HeyloText} from 'lib/materialUi/HeyloText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export const SQUARE = 'square';
export const RECTANGLE = 'rectangle';

const VARIANTS: { [key: string]: React.CSSProperties } = {
  [SQUARE]: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
  },
  [RECTANGLE]: {
    alignItems: 'center',
    borderRadius: 8,
    border: 'solid #BFBFBF 2px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: '0.2rem',
    width: '16rem',
  },
}

type Props = {
  busy?: boolean,
  icon: JSX.Element,
  label: string,
  onClickHandler?: (e: any) => void,
  showArrow?: boolean,
  variant: 'square' | 'rectangle',
}

export const ActionButton = (props: Props) => {
  const {
    busy, icon, label, onClickHandler,
    showArrow,
    variant,
  } = props;
  return (
      <>
        <div
            style={VARIANTS[variant]}
            onClick={e => {
              if (!busy && onClickHandler) {
                onClickHandler(e);
              }
            }}
        >
          {busy ? (
              <i className="fa fa-cog fa-spin" style={{fontSize: '2rem'}}/>
          ) : (
              <>
                <div className={'flexrow flexcenter'} style={{position: 'relative'}}>
                  {icon}
                  {showArrow && variant === SQUARE && (
                      <ArrowDropDownIcon style={{
                        fontSize: '2rem',
                        left: '1.8rem',
                        position: 'absolute',
                      }}/>
                  )}
                </div>
                <HeyloText noWrap
                           style={{fontSize: '1.2rem'}}
                           variant={'body2'}>
                  {label}
                </HeyloText>
              </>
          )}
        </div>
      </>
  );
};
