import React, {useState} from 'react';
import {HeyloButton, HeyloText, HeyloTextInput} from 'lib/materialUi';
import {CommunityProfile} from '@heylo/shared/src/types/firebase-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {CommunityProfilesFirebase} from '@heylo/shared/src/features/communityProfiles/Firebase';
import {StripeConnect} from '../payments/StripeConnect';
import {Divider} from '../ui/Divider';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {EditableImage} from '@heylo/components/src/ui/image/EditableImage';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectActiveCommunityProfile} from '@heylo/shared/src/features/communityProfiles/Selectors';
import {HeyloContainer} from '@heylo/components/src/ui/container/HeyloContainer';
import {Redirect, useHistory} from 'react-router-dom';
import {SelectActiveUserIsAdminInActiveCommunity} from '@heylo/shared/src/features/communityMembers/Selectors';
import {useNavigationUtils} from '../navigation/useNavigationUtils';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

const useStyles = makeStyles(theme => ({
  containerButton: {
    padding: '0 2%',
    width: '40%',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
}));

enum State {
  OPEN,
  BUSY,
  CLOSE,
  NAME_ERROR,
  SUCCESS,
  UNKNOWN_ERROR,
}

export const EditGroupScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const logger = useLoggingService();

  const {initialCommunity, isAdmin} = useSelector((state: RootState) => ({
    initialCommunity: selectActiveCommunityProfile(state),
    isAdmin: SelectActiveUserIsAdminInActiveCommunity(state),
  }), shallowEqual);

  const communityId = initialCommunity.communityId || '';
  const [communityName, setCommunityName] = useState(initialCommunity.communityName || '');
  const [communityPhoto, setCommunityPhoto] = useState(initialCommunity.heroImageUrl || '');
  const [description, setDescription] = useState(initialCommunity.description || '');
  const [locationName, setLocationName] = useState(initialCommunity.locationName || '');
  const [requirements, setRequirements] = useState(initialCommunity.requirements || '');

  const [state, setState] = useState(State.OPEN);

  const nameFieldRef = React.createRef<HTMLInputElement>();
  const handleNewPhoto = async (downloadUri: Promise<string>) => {
    const uri = await downloadUri;
    setCommunityPhoto(uri);
  };

  const saveCommunity = async () => {
    if (!validateForm()) {
      setState(State.UNKNOWN_ERROR)
      return;
    }
    const newCommunityProfile: CommunityProfile = {
      communityName,
      description,
      heroImageUrl: communityPhoto,
      locationName,
      requirements,
    };

    try {
      await dispatch(CommunityProfilesFirebase.UpdateCommunityProfile(communityId, newCommunityProfile));
      setState(State.SUCCESS);
      logger.logEvent(AnalyticsEvent.COMMUNITY_PROFILE_UPDATED);
    } catch {
      setState(State.UNKNOWN_ERROR);
    }
  };

  const validateForm = (): boolean => {
    let hasError = false;
    if (communityName.length <= 3) {
      hasError = true;
      setState(State.NAME_ERROR);
      if (nameFieldRef.current) {
        nameFieldRef.current.focus();
      }
    }
    if (hasError) {
      setState(State.UNKNOWN_ERROR);
      return false;
    }
    return true;
  };

  const nav = useNavigationUtils();

  const navigateAway = () => {
    history.push(nav.resolveUriForGroupPage());
  };

  const handleSaveClick = () => {
    if (validateForm()) {
      saveCommunity();
      navigateAway();
    }
  };

  const handleCancel = () => {
    navigateAway();
  };

  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  if (!isAdmin) {
    return <Redirect to={nav.resolveUriForGroupPage()}/>
  }
  return (
      <HeyloContainer maxWidth={'xs'} style={{padding: StyleConstants.SPACING}}>
        <form className={'scrollbar'} noValidate>
          <Grid className={'scrollbar'} container spacing={2}
                style={{margin: 0, padding: 0, maxWidth: '100%'}}>
            <Grid item xs={12}>
              <EditableImage
                  aspectRatio={communityPhoto ? 1.5 : 3}
                  cropShape={'square'}
                  imageUri={communityPhoto}
                  onChange={handleNewPhoto}
                  storagePath={`communities/${communityId}/heroImage`}
              />
            </Grid>

            <Grid item xs={12}>
              <HeyloText variant={'h5'}>
                Group Info
              </HeyloText>
            </Grid>

            <Grid item xs={12}>
              <HeyloTextInput
                  error={state === State.NAME_ERROR}
                  helperText={state === State.NAME_ERROR ? 'Group name is too short' : ''}
                  label={'Group name'}
                  ref={nameFieldRef}
                  onChange={setCommunityName}
                  value={communityName}
              />
            </Grid>

            <Grid item xs={12}>
              <HeyloTextInput
                  label={'Location'}
                  multiline
                  onChange={setLocationName}
                  rows={1}
                  rowsMax={100}
                  value={locationName}
              />
            </Grid>

            <Grid item xs={12}>
              <HeyloTextInput
                  label={'Description'}
                  multiline
                  onChange={setDescription}
                  rows={2}
                  rowsMax={100}
                  value={description}
              />
            </Grid>

            <Grid item xs={12}>
              <HeyloTextInput
                  label={'Member requirements'}
                  multiline
                  onChange={setRequirements}
                  rows={2}
                  rowsMax={100}
                  value={requirements}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider/>
            </Grid>

            <Grid item xs={12}>
              <StripeConnect/>
            </Grid>

            <Grid item xs={12}>
              <Divider/>
            </Grid>

            {state === State.UNKNOWN_ERROR && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    Please try again. If it still doesn\'t work, please contact support@heylo.co.
                  </Alert>
                </Grid>
            )}

            {state === State.SUCCESS && (
                <Grid item xs={12}>
                  <Alert severity="success">
                    Changes were made successfully!
                  </Alert>
                </Grid>
            )}

            <Grid item xs={12}>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <div className={classes.containerButton}>
                  <HeyloButton
                      onClick={handleCancel}
                      label={'cancel'}
                  />
                </div>
                <div className={classes.containerButton}>
                  <HeyloButton
                      busy={state === State.BUSY}
                      color={'primary'}
                      onClick={handleSaveClick}
                      label={'Save'}
                  />
                </div>
              </div>
            </Grid>

          </Grid>
        </form>
      </HeyloContainer>
  );
};
