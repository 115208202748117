import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {
  Keyboard,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import {SwitchControl} from '@heylo/components/src/features/topics/SwitchControl';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {
  IsThreadTypeCommunityBased,
  THREAD_TYPE_ANNOUNCEMENT,
  THREAD_TYPE_COMMUNITY,
  THREAD_TYPE_DIRECT,
  THREAD_TYPE_GROUP,
  THREAD_TYPE_SUPPORT,
} from '@heylo/shared/src/types/ThreadTypes';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {SelectActiveUserIsAdminInActiveCommunity} from '@heylo/shared/src/features/communityMembers/Selectors';
import {ActiveThreadSelector} from '@heylo/shared/src/features/threads/Selectors';
import {ActiveThreadIdSelector} from '@heylo/shared/src/features/threads/SimpleSelectors';
import {
  threadNewMemberNotificationsEnabledSelector,
  threadNewMessageNotificationsEnabledSelector,
} from '@heylo/shared/src/features/notifications/Selectors';
import {FirebaseUpdateGroupChat} from '@heylo/shared/src/features/threads/Firebase';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {EditableImage} from '@heylo/components/src/ui/image';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {StyleVars} from '@heylo/components/src/styles/StyleVars';
import {HeyloTextInput} from '@heylo/components/src/ui/input/HeyloTextInput';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {ImageUploadMetadata} from '@heylo/shared/src/services/image/ImageUploader';
import {HeyloDialog} from '@heylo/components/src/ui/dialog/HeyloDialog';
import {PrivateTopicLabel} from './PrivateTopicLabel';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  updateNotificationSettingsChatsNewMembersPerThread,
  updateNotificationSettingsChatsNewMessagesPerThread,
} from '@heylo/shared/src/features/notifications/Firebase';
import {FirebaseUpdateThread} from '@heylo/shared/src/features/topics/Firebase';
import {DeleteConversationAlert, LeaveChatAlert} from '../threads/ThreadUtils';
import {ChatMembers} from '../chat/ChatMembers';

const mapState = (state: RootState) => {
  const thread = ActiveThreadSelector(state);
  const threadId = ActiveThreadIdSelector(state);
  return {
    activeCommunityId: SelectActiveCommunityId(state),
    activeUserId: selectActiveUserId(state) || '',
    activeUserIsAdmin: SelectActiveUserIsAdminInActiveCommunity(state),
    newMemberNotifications: threadNewMemberNotificationsEnabledSelector(state, threadId),
    newMessageNotifications: threadNewMessageNotificationsEnabledSelector(state, threadId),
    thread,
    threadId,
    threadImageUrl: thread.heroImageUrl || '',
    threadName: thread.name || '',
    threadNotes: thread.notes || '',
    threadType: thread.threadType || '',
  };
};
const connector = connect(mapState);

export enum ChatInfoState {
  UNEDITED,
  EDITED,
  SUBMIT_START,
  SUBMIT_PENDING
}

type Props = ConnectedProps<typeof connector> & {
  onAddMembers: () => void,
  onLeave: () => void,
  onSuccess: () => void,
  state: ChatInfoState,
  setState: Dispatch<SetStateAction<ChatInfoState>>,
};

export const EditTopic = connector((props: Props) => {
  const {
    activeCommunityId,
    activeUserId,
    activeUserIsAdmin,
    newMemberNotifications,
    newMessageNotifications,
    onAddMembers,
    onLeave,
    onSuccess,
    setState,
    state,
    thread,
    threadId,
    threadImageUrl,
    threadName,
    threadNotes,
    threadType,
  } = props;

  const isDirectChat = threadType === THREAD_TYPE_DIRECT;

  const logger = useLoggingService();

  const [currentImageUrl, setCurrentImageUrl] = useState(threadImageUrl);
  const [currentThreadName, setCurrentThreadName] = useState(threadName);
  const [currentThreadNotes, setCurrentThreadNotes] = useState(threadNotes);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [alert, setAlert] = useState<[string, string] | null>(null);

  const showError = (title: string, body: string) => {
    setAlert([title, body]);
    setState(ChatInfoState.EDITED);
  }

  useEffect(() => {
    const hasChanged = currentImageUrl !== threadImageUrl
        || currentThreadName !== threadName
        || currentThreadNotes !== threadNotes;
    setState(hasChanged ? ChatInfoState.EDITED : ChatInfoState.UNEDITED);
  }, [
    currentImageUrl,
    currentThreadName,
    currentThreadNotes,
  ]);

  const [newMemberNotificationState, setNewMemberNotificationState] = useState(newMemberNotifications);
  useEffect(() => {
    setNewMemberNotificationState(newMemberNotifications);
  }, [newMemberNotifications]);

  const [newMessageNotificationState, setNewMessageNotificationState] = useState(newMessageNotifications);
  useEffect(() => {
    setNewMessageNotificationState(newMessageNotifications);
  }, [newMessageNotifications]);

  const ToggleNewMemberNotifications = () => {
    const newValue = !newMemberNotificationState;
    setNewMemberNotificationState(newValue);
    updateNotificationSettingsChatsNewMembersPerThread({
      communityId: activeCommunityId,
      enabled: newValue,
      threadId,
      threadType,
      userId: activeUserId,
    })
        .then(() => {
          logger.logEvent(
              newValue ? AnalyticsEvent.CHAT_NEW_MEMBER_NOTIFICATIONS_ON : AnalyticsEvent.CHAT_NEW_MEMBER_NOTIFICATIONS_OFF,
              {threadType});
        })
        .catch((e: Error) => {
          console.warn('error updating new member notifications', e.message);
        });
  };

  const ToggleNewMessageNotifications = () => {
    const newValue = !newMessageNotificationState;
    setNewMessageNotificationState(newValue);
    updateNotificationSettingsChatsNewMessagesPerThread({
      communityId: activeCommunityId,
      enabled: newValue,
      threadId,
      threadType,
      userId: activeUserId,
    })
        .then(() => {
          logger.logEvent(
              newValue ? AnalyticsEvent.CHAT_NOTIFICATIONS_OPT_IN : AnalyticsEvent.CHAT_NOTIFICATIONS_OPT_OUT,
              {threadType});
        })
        .catch((e: Error) => {
          console.warn('error updating new message notifications', e.message);
        });
  };

  useEffect(() => {
    if (state === ChatInfoState.SUBMIT_START) {
      Keyboard.dismiss();
      if (isUploadingImage) {
        showError('Image has not uploaded yet', 'Give it a moment and then try again');
      } else {
        saveChanges();
      }
    }
  }, [state])

  const loggingParams = () => ({
    changedImage: currentImageUrl !== threadImageUrl,
    changedName: currentThreadName !== threadName,
    changedNotes: currentThreadNotes !== threadNotes,
    threadType,
  });

  const validate = (name: string): boolean => {
    return name.length >= 3;
  };

  const saveChanges = async () => {
    setState(ChatInfoState.SUBMIT_PENDING);
    const trimmedName = currentThreadName.trim();
    const trimmedNotes = currentThreadNotes.trim();
    try {
      if (!validate(trimmedName)) {
        showError('Topic name is too short', 'Topic names must be at least 3+ characters long.');
        return;
      }
      logger.logEvent(AnalyticsEvent.CHAT_EDIT_SUBMIT, loggingParams());
      if (IsThreadTypeCommunityBased(threadType)) {
        await FirebaseUpdateThread({
          communityId: activeCommunityId,
          imageUrl: currentImageUrl,
          threadId,
          name: trimmedName,
          notes: trimmedNotes,
          userId: activeUserId,
        })
      } else {
        await FirebaseUpdateGroupChat({
          chatImageUrl: currentImageUrl,
          chatName: trimmedName,
          threadId,
          userId: activeUserId,
        });
      }
      onSuccess();
    } catch (e) {
      showError('Something went wrong', 'Please try again or contact Heylo Support.');
      console.warn('failed to update topic', e);
    }
  };

  const canAddMembers = threadType === THREAD_TYPE_GROUP;
  const canDeleteTopic = threadType === THREAD_TYPE_COMMUNITY && activeUserIsAdmin;
  const canEditTopic = threadType === THREAD_TYPE_COMMUNITY || threadType === THREAD_TYPE_GROUP;
  const canLeaveTopic = threadType !== THREAD_TYPE_SUPPORT && threadType !== THREAD_TYPE_ANNOUNCEMENT;

  const [leaveDialogVisible, setLeaveDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const uploadStarted = (downloadUrlPromise: Promise<string>, imageMetadata: ImageUploadMetadata) => {
    setIsUploadingImage(true);
    downloadUrlPromise.then(url => {
      setCurrentImageUrl(url);
      setIsUploadingImage(false);
    });
  };

  const text = useTextStyles();

  return (
      <>
        {canEditTopic ? (
            <>
              <View
                  style={{alignSelf: 'center', minWidth: 200, width: '50%'}}>
                <EditableImage
                    aspectRatio={1}
                    callToAction={(Boolean(threadImageUrl) ? 'Edit' : 'Add') + ' topic photo'}
                    cropShape={'circle'}
                    imageUri={currentImageUrl}
                    onChange={uploadStarted}
                    storagePath={`communities/${activeCommunityId}/threads`}
                />
              </View>

              <View style={styles.containerTopText}>
                <HeyloTextInput
                    autoCapitalize={'sentences'}
                    dense
                    label={'Topic name'}
                    onChangeText={value => setCurrentThreadName(value)}
                    scrollEnabled={false}
                    value={currentThreadName}
                />
              </View>
              <View style={styles.containerTopText}>
                {threadType === THREAD_TYPE_COMMUNITY && (
                    <HeyloTextInput
                        autoCapitalize={'sentences'}
                        dense
                        label={'Topic description'}
                        multiline={true}
                        onChangeText={value => setCurrentThreadNotes(value)}
                        placeholder={'Add topic notes or a description'}
                        scrollEnabled={false}
                        value={currentThreadNotes}
                    />
                )}
                {threadType === THREAD_TYPE_GROUP && (
                    <PrivateTopicLabel threadType={threadType}/>
                )}
              </View>

              <View style={styles.divider}/>
            </>
        ) : null}

        <SwitchControl
            onValueChange={ToggleNewMessageNotifications}
            subtitle={'You will be notified for new messages.'}
            title={'Message notifications'}
            value={newMessageNotificationState}
        />

        {threadType !== THREAD_TYPE_ANNOUNCEMENT && (
            <>
              {!isDirectChat && (
                  <>
                    <View style={styles.divider}/>
                    <SwitchControl
                        onValueChange={ToggleNewMemberNotifications}
                        subtitle={'You will be notified when a new member joins.'}
                        title={'New member notifications'}
                        value={newMemberNotificationState}
                    />
                  </>
              )}

              {canLeaveTopic && (
                  <>
                    <View style={styles.divider}/>
                    <TouchableOpacity
                        onPress={() => setLeaveDialogVisible(true)}
                        style={styles.containerButton}
                    >
                      <MaterialCommunityIcon
                          color={StyleVars.Colors.Black}
                          name={'exit-to-app'}
                          size={StyleConstants.SPACING * 1.5}
                          style={styles.containerButtonIcon}
                      />
                      <Text style={text.body1}>
                        {isDirectChat ? 'Leave chat' : 'Leave topic'}
                      </Text>
                    </TouchableOpacity>
                  </>
              )}

              {canDeleteTopic && (
                  <>
                    <View style={styles.divider}/>
                    <TouchableOpacity
                        onPress={() => setDeleteDialogVisible(true)}
                        style={styles.containerButton}
                    >
                      <MaterialCommunityIcon
                          color={StyleVars.Colors.Black}
                          name={'delete'}
                          size={StyleConstants.SPACING * 1.5}
                          style={styles.containerButtonIcon}
                      />
                      <Text style={text.body1}>
                        Delete topic
                      </Text>
                    </TouchableOpacity>
                  </>
              )}

              {canAddMembers && (
                  <>
                    <View style={styles.divider}/>
                    <TouchableOpacity
                        onPress={onAddMembers}
                        style={styles.containerButton}
                    >
                      <MaterialCommunityIcon
                          color={StyleVars.Colors.Black}
                          name={'account-plus'}
                          size={StyleConstants.SPACING * 1.5}
                          style={styles.containerButtonIcon}
                      />
                      <Text style={text.body1}>
                        Add members
                      </Text>
                    </TouchableOpacity>
                  </>
              )}

              <View style={styles.divider}/>

              <ChatMembers threadId={threadId} threadType={threadType}/>

              <HeyloDialog
                  body={alert?.[1] || ''}
                  onDismiss={() => setAlert(null)}
                  title={alert?.[0] || ''}
                  visible={Boolean(alert)}
              />
              <DeleteConversationAlert
                  onDelete={() => onLeave()}
                  onDismiss={() => setDeleteDialogVisible(false)}
                  thread={thread}
                  visible={deleteDialogVisible}
              />
              <LeaveChatAlert
                  onDismiss={() => setLeaveDialogVisible(false)}
                  onLeave={() => onLeave()}
                  thread={thread}
                  visible={leaveDialogVisible}
              />
            </>
        )}
      </>
  );
});

const styles = StyleSheet.create({
  containerButton: {
    alignItems: 'center',
    alignSelf: 'flex-start',
    flexDirection: 'row',
  },
  containerButtonIcon: {
    marginLeft: 0,
    marginRight: StyleConstants.SPACING / 2,
  },
  containerTopText: {
    marginTop: StyleConstants.SPACING,
  },
  divider: {
    alignSelf: 'center',
    borderTopWidth: 1,
    borderColor: StyleVars.Colors.GreyLightest,
    height: 0,
    marginVertical: StyleConstants.SPACING,
    width: '100%',
  },
});