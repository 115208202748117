import {
  Event,
  EventAttendance,
  PaymentSummary,
} from '@heylo/shared/src/types/firebase-types';
import {
  EventAttendanceUpdated,
  EventRemoved,
  EventUpdated,
} from '@heylo/shared/src/features/events/Slice';
import {Listeners} from '@heylo/shared/src/features/firebase/Listeners';
import {
  FirebaseDatabase,
  FirebaseServerTimestamp,
  ThenableReference,
} from '@heylo/firebase-database';
import {FirebaseCommunityScopedDeletion} from '@heylo/shared/src/features/communities/Firebase';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {IncrementUserInteraction} from '@heylo/shared/src/features/userEvents/Actions';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {StripeEventDonationsUpdated} from '@heylo/shared/src/features/stripe/Slice';

export function attachAllEventListeners(communityId: string) {
  return (dispatch: any) => {
    if (Listeners.EVENTS[communityId])
      return;
    const cancelCallback = (e: Error) => {
      console.log('lost connection to events', e.message);
      delete Listeners.EVENTS[communityId];
    };
    const ref = FirebaseDatabase().ref(`/events/${communityId}`).orderByChild('timestamp').limitToLast(500);
    Listeners.EVENTS[communityId] = ref;
    ref.on('child_added', (snapshot) => {
      const eventId = snapshot?.key || '';
      const event: Event = snapshot?.val();
      dispatch(EventUpdated({communityId, eventId, event}));
    }, cancelCallback);
    ref.on('child_removed', (snapshot) => {
      const eventId = snapshot?.key || '';
      dispatch(EventRemoved({communityId, eventId}));
    }, cancelCallback);
    ref.on('child_changed', (snapshot) => {
      const eventId = snapshot?.key || '';
      const event: Event = snapshot?.val();
      dispatch(EventUpdated({communityId, eventId, event}));
    }, cancelCallback);
  };
}

export const AttachListenersForEvent = (communityId: string, eventId: string, userId: string)
    : ThunkReturnType<void> => dispatch => {
  dispatch(attachEventAttendanceListener(communityId, eventId));
  dispatch(attachEventDonationsListener(communityId, eventId, userId));
};

export const attachEventAttendanceListener = (communityId: string, eventId: string)
    : ThunkReturnType<void> => dispatch => {
  if (Listeners.EVENT_ATTENDANCE[eventId]) {
    return;
  }
  console.count('AttachListenersForEvent');
  const ref = FirebaseDatabase().ref(`/eventAttendance/${communityId}/${eventId}`);
  Listeners.EVENT_ATTENDANCE[eventId] = ref;
  ref.on('value', function (snapshot) {
    const attendance: { [key: string]: EventAttendance } = snapshot?.val() || {};
    dispatch(EventAttendanceUpdated({eventId, attendance}));
  }, (e: Error) => {
    console.log('lost connection to event attendance', e.message);
    delete Listeners.EVENT_ATTENDANCE[eventId];
  });
};

export const attachEventDonationsListener = (communityId: string, eventId: string, userId: string)
    : ThunkReturnType<void> => dispatch => {
  if (Listeners.STRIPE_EVENT_USER_DONATIONS[eventId]) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/stripe/eventDonations/${communityId}/${eventId}/${userId}`);
  Listeners.STRIPE_EVENT_USER_DONATIONS[eventId] = ref;
  ref.on('value', snapshot => {
    const donations: { [paymentId: string]: PaymentSummary } = snapshot?.val() ?? null;
    dispatch(StripeEventDonationsUpdated({eventId, donations}));
  }, (e: Error) => {
    delete Listeners.STRIPE_EVENT_USER_DONATIONS[eventId];
    console.warn('lost connection to stripe event donations', communityId, eventId, e.message);
  });
};

export const FirebaseCreateEvent = (communityId: string, eventId: string, event: Event)
    : ThunkReturnType<Promise<void>> => dispatch => {
  event.createdAt = FirebaseServerTimestamp();
  event.source = 'app';
  dispatch(EventUpdated({communityId, eventId, event}));
  dispatch(IncrementUserInteraction(AnalyticsEvent.EVENTS_CREATE));
  return FirebaseDatabase().ref(`/events/${communityId}/${eventId}`).set(event);
};

export const FirebaseUpdateEvent = (communityId: string, eventId: string, event: Event)
    : ThunkReturnType<Promise<void>> => dispatch => {
  const finalEvent = Object.assign({}, event, {
    lastUpdateTimestamp: FirebaseServerTimestamp(),
  });
  dispatch(EventUpdated({communityId, eventId, event: finalEvent}));
  dispatch(IncrementUserInteraction(AnalyticsEvent.EVENTS_UPDATE));
  return FirebaseDatabase().ref(`/events/${communityId}/${eventId}`).update(finalEvent);
};

export const FirebaseRemoveEvent = (communityId: string, eventId: string, userId: string)
    : ThunkReturnType<ThenableReference> => dispatch => {
  dispatch(EventRemoved({communityId, eventId}));
  dispatch(IncrementUserInteraction(AnalyticsEvent.EVENTS_DELETE));
  return FirebaseCommunityScopedDeletion(communityId, {
    ownerId: userId,
    eventId,
  });
};

export const FirebaseUpdateEventRsvp = (communityId: string, eventId: string, userId: string, goingString: 'yes' | 'no' | 'maybe') => {
  const updates: any = {};
  updates[`/eventAttendance/${communityId}/${eventId}/${userId}/going`] = goingString;
  return FirebaseDatabase().ref().update(updates);
};
