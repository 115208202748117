import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DeviceInfo} from '@heylo/shared/src/types/firebase-types';
import {AppReset} from '@heylo/shared/src/features/app/Actions';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {DeepReadonly} from 'utility-types';

export type UserDevicesState = {
  devices: DeepReadonly<{ [deviceId: string]: DeviceInfo }>,
};

const initialState: UserDevicesState = {
  devices: {},
};

export const slice = createSlice({
  name: 'userDevices',
  initialState,
  reducers: {
    UserDevicesLoaded: (state, action: PayloadAction<{ [deviceId: string]: DeviceInfo }>) => {
      state.devices = action.payload;
    },
  },

  extraReducers: builder => builder
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState),

});

const {actions, reducer: UserDevicesReducer} = slice;

const {
  UserDevicesLoaded,
} = actions;

export {
  UserDevicesLoaded,
  UserDevicesReducer,
};
