import React, {useEffect} from 'react';
import {Route, Switch, useLocation, useRouteMatch} from 'react-router-dom';
import ReactGA from 'react-ga';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {InstallHeylo} from '../install/InstallHeylo';
import {CommunityNavigator} from '../community/CommunityNavigator';
import {Sidebar} from '../sidebar';
import {Footer} from './Footer';
import {UserSettings} from '../userSettings';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {AuthenticationScreen} from 'features/auth/AuthenticationScreen';
import {CommunityProfilesFirebase} from '@heylo/shared/src/features/communityProfiles/Firebase';
import {ActiveCommunityUpdated} from '@heylo/shared/src/features/communities/Slice';
import {selectActiveUserIsMemberOfActiveCommunity} from '@heylo/shared/src/features/communityMembers/Selectors';
import {attachAllEventListeners} from '@heylo/shared/src/features/events/Firebase';
import {
  listenForCommunityMembers,
  MembersFirebase,
} from '@heylo/shared/src/features/communityMembers/Firebase';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {StripeFirebase} from '@heylo/shared/src/features/stripe/Firebase';
import {DonationResultScreen} from '../payments/DonationResultScreen';
import {OauthResultScreen} from '../payments/OauthResultScreen';
import {
  attachAllCommunityThreadListeners,
  AttachCommunityPerUserThreadStateListener,
  AttachCommunityThreadStateListener,
} from '@heylo/shared/src/features/threads/Firebase';
import {LoadCommunityImpressionMetrics} from '@heylo/shared/src/features/userEvents/Firebase';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {MarketplaceGroupsScreen} from '../marketplaces/MarketplaceGroupsScreen';
import {MarketplacesScreen} from '../marketplaces/MarketplacesScreen';
import {NewGroupNameScreen} from '../communityCreation/NewGroupNameScreen';
import {NewGroupLocationScreen} from '../communityCreation/NewGroupLocationScreen';
import {NewGroupEmailVerificationScreen} from '../communityCreation/NewGroupEmailVerificationScreen';
import {NewGroupDetailsScreen} from '../communityCreation/NewGroupDetailsScreen';
import {NewGroupReviewScreen} from '../communityCreation/NewGroupReviewScreen';
import {NewGroupCreatedScreen} from '../communityCreation/NewGroupCreatedScreen';
import {JoinGroupEditProfileScreen} from '../marketplaces/JoinGroupEditProfileScreen';
import {JoinGroupRequestSentScreen} from '../marketplaces/JoinGroupRequestSent';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {IntroScreen} from '../intro/IntroScreen';
import {DiscoverCommunityScreen} from '../marketplaces/DiscoverCommunityScreen';
import {PhotosFirebase} from '@heylo/shared/src/features/photos/Firebase';
import {FLAG_WEB_CHAT_ENABLED} from '../../Flags';
import {
  AttachCommunityScopedDeletionsListener,
  CommunityFirebase,
} from '@heylo/shared/src/features/communities/Firebase';
import {View} from 'react-native';

const convertPathnameToScreenName = (pathName: string): string => {
  const pathsToScreens: { [path: string]: string } = {
    ['/community']: 'CommunityPageScreen',
    ['/createCommunity']: 'CreateCommunityScreen',
    ['/events']: 'EventSummaryScreen',
    ['/event']: 'EventScreen',
    ['/login']: 'LoginScreen',
    ['/members']: 'MembersScreen',
    ['/newGroup/name']: 'CreateCommunityScreen',
    ['/topics']: 'TopicsScreen',
    ['/userSettings']: 'SettingsScreen',
  };
  return pathsToScreens[pathName] ?? '';
};

const mapState = (state: RootState) => ({
  activeCommunityId: SelectActiveCommunityId(state),
  activeUserId: selectActiveUserId(state),
  isMember: selectActiveUserIsMemberOfActiveCommunity(state),
});

const connector = connect(mapState, {});
type Props = ConnectedProps<typeof connector>;

export const PureHome = React.memo((props: Props) => {
  console.count('count Home');
  const {
    activeCommunityId,
    activeUserId,
    isMember,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const logger = useLoggingService();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const communityId = query.get('communityId') || query.get('id') || '';
    if (communityId) {
      dispatch(ActiveCommunityUpdated(communityId));
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (activeCommunityId) {
      dispatch(CommunityProfilesFirebase.AttachCommunityProfileListener(activeCommunityId));
      if (isMember) {
        dispatch(MembersFirebase.LoadOneCommunityMember(activeCommunityId, activeUserId));
        dispatch(attachAllEventListeners(activeCommunityId));
        dispatch(listenForCommunityMembers(activeCommunityId))
        dispatch(CommunityProfilesFirebase.AttachCommunityProfileListener(activeCommunityId));
        dispatch(StripeFirebase.CommunityMetadataListener(activeCommunityId));
        dispatch(LoadCommunityImpressionMetrics(activeUserId, activeCommunityId));
        dispatch(AttachCommunityScopedDeletionsListener(activeCommunityId));
        dispatch(CommunityFirebase.ProspectsListener(activeCommunityId));
        if (FLAG_WEB_CHAT_ENABLED) {
          dispatch(AttachCommunityPerUserThreadStateListener(activeUserId, activeCommunityId));
          dispatch(AttachCommunityThreadStateListener(activeCommunityId));
          dispatch(attachAllCommunityThreadListeners(activeUserId, activeCommunityId));
          dispatch(PhotosFirebase.attachCommunityPhotosListener(activeCommunityId));
        }
      }
    }
  }, [dispatch, activeCommunityId, activeUserId, isMember]);

  const isUserSettings = useRouteMatch('/userSettings');
  const isCreateCommunity = useRouteMatch('/createCommunity');
  useEffect(() => {
    if (isUserSettings || isCreateCommunity) {
      dispatch(ActiveCommunityUpdated(''));
    }
  }, [isUserSettings, isCreateCommunity]);

  const stripeUtilityScreenPaths = [
    '/donation/success',
    '/donation/cancel',
    '/stripe/oauth/success',
    '/stripe/oauth/error',
  ];
  const isStripeUtilityScreen = useRouteMatch(stripeUtilityScreenPaths);
  const isNativeEmailSignIn = useRouteMatch('/confirmEmail');
  const communityContentRoutes = [
    '/community',
    '/content',
    '/event',
    '/events',
    '/install',
    '/invite',
    '/members',
    '/message',
    '/topics/:communityId/:threadId',
    '/topics/:communityId',
    '/topics',
  ];
  const isCommunityContentRoute = useRouteMatch(communityContentRoutes);

  return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        minHeight: '100vh',
        maxHeight: '100vh',
      }}>
        <Route
            path="/"
            render={({location: loc}) => {
              const page = loc.pathname;
              ReactGA.set({page});
              ReactGA.pageview(`${page}${loc.search}`);
              const screenName = convertPathnameToScreenName(page);
              if (screenName) {
                logger.setCurrentScreen(screenName);
              }
              return null;
            }}
        />

        {isStripeUtilityScreen ? (
            <Switch>
              <Route path={'/donation'}>
                <DonationResultScreen/>
              </Route>
              <Route path={'/stripe/oauth'}>
                <OauthResultScreen/>
              </Route>
            </Switch>
        ) : isNativeEmailSignIn ? (
            // This route is handled by `AuthApp`. Only needed for development
            // builds.
            null
        ) : (
            <>
              <View style={{
                flex: 1,
                flexDirection: 'row',
              }}>
                {activeUserId ? (
                    <Sidebar/>
                ) : null}

                {/* Make right hand side shrink instead of the sidebar */}
                <View style={{
                  flex: 1,
                  flexShrink: 2,
                }}>
                  <Switch>
                    {activeUserId ? (
                        <Route path={'/userSettings'}>
                          <UserSettings/>
                        </Route>
                    ) : null}
                    <Route path={[
                      '/createCommunity',
                      '/newGroup/name',
                    ]}>
                      <NewGroupNameScreen/>
                    </Route>
                    <Route path={'/newGroup/location'}>
                      <NewGroupLocationScreen/>
                    </Route>
                    <Route path={'/newGroup/verification'}>
                      <NewGroupEmailVerificationScreen/>
                    </Route>
                    <Route path={'/newGroup/details'}>
                      <NewGroupDetailsScreen/>
                    </Route>
                    <Route path={'/newGroup/review'}>
                      <NewGroupReviewScreen/>
                    </Route>
                    <Route path={'/newGroup/done'}>
                      <NewGroupCreatedScreen/>
                    </Route>
                    {/* TODO: handle /profile and /requestSent without navigating */}
                    <Route path={'/g/:communityId/profile'}>
                      <JoinGroupEditProfileScreen/>
                    </Route>
                    <Route path={'/g/:communityId/requestSent'}>
                      <JoinGroupRequestSentScreen/>
                    </Route>
                    <Route path={'/g/:communityId'}>
                      <DiscoverCommunityScreen/>
                    </Route>
                    <Route path={communityContentRoutes}>
                      <CommunityNavigator/>
                    </Route>
                    <Route path={['/install', '/link', '/message']}>
                      <InstallHeylo/>
                    </Route>
                    <Route path={['/login', '/resetpassword']}>
                      <AuthenticationScreen/>
                    </Route>
                    <Route path={'/m/:marketplaceId'}>
                      <MarketplaceGroupsScreen/>
                    </Route>
                    <Route path={'/m'}>
                      <MarketplacesScreen/>
                    </Route>
                    <Route>
                      <IntroScreen/>
                    </Route>
                  </Switch>
                </View>
              </View>
              {(!activeUserId && !matchXs && !isCommunityContentRoute) ? (
                  <Footer/>
              ) : null}
            </>
        )}
      </div>
  );
});

export const Home = connector(PureHome);