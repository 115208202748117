import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {
  CommunityMember,
  UserEventMetrics,
} from '@heylo/shared/src/types/firebase-types';
import {DeepReadonly} from 'utility-types';
import {AppColdStart, AppReset} from '@heylo/shared/src/features/app/Actions';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {
  ActiveCommunityUpdated,
  UserJoinedCommunity,
} from '../communities/Slice';
import moment from 'moment';
import {UserProfileCreated} from '../userProfiles/Slice';

export interface UserEventsState {
  // Only used to record the last viewed timestamp of communities when switching
  // between communities. Should NOT be used externally to this reducer.
  activeCommunityId: string,
  FIELD_COMMUNITY_IMPRESSION_METRICS: DeepReadonly<{ [communityId: string]: { [event: string]: UserEventMetrics } }>,
  FIELD_USER_IMPRESSION_METRICS: DeepReadonly<{ [event: string]: UserEventMetrics }>,
  FIELD_USER_INTERACTION_METRICS: DeepReadonly<{ [event: string]: UserEventMetrics }>,
  userInteractionMetricsLoaded: boolean,
}

const initialState: UserEventsState = {
  activeCommunityId: '',
  FIELD_COMMUNITY_IMPRESSION_METRICS: {},
  FIELD_USER_IMPRESSION_METRICS: {},
  FIELD_USER_INTERACTION_METRICS: {},
  userInteractionMetricsLoaded: false,
};

const initializeCommunityMetric = (metricsMap: { [communityId: string]: { [event: string]: UserEventMetrics } }, communityId: string, event: string, timestamp: number)
    : UserEventMetrics => {
  if (!metricsMap[communityId]) {
    metricsMap[communityId] = {};
  }
  if (!metricsMap[communityId][event]) {
    metricsMap[communityId][event] = {count: 0, firstTimestamp: timestamp};
  }
  return metricsMap[communityId][event];
};

const initializeUserMetric = (metricsMap: { [event: string]: UserEventMetrics }, event: string, timestamp: number)
    : UserEventMetrics => {
  if (!metricsMap[event]) {
    metricsMap[event] = {count: 0, firstTimestamp: timestamp};
  }
  return metricsMap[event];
};

const incrementUserMetricsState = (metrics: UserEventMetrics, timestamp: number) => {
  const {count = 0} = metrics;
  metrics.count = count + 1;
  metrics.lastTimestamp = timestamp;
};

const mergeMetricsState = (
    local: { [event: string]: UserEventMetrics },
    updates: { [event: string]: UserEventMetrics }) => {
  for (const [event, metric] of Object.entries(updates)) {
    const {
      count = 0,
      firstTimestamp = Number.MAX_SAFE_INTEGER,
      lastTimestamp = 0,
    } = local[event] || {};

    local[event] = {
      count: Math.max(count, metric.count ?? 0),
      firstTimestamp: Math.min(firstTimestamp, metric.firstTimestamp ?? Number.MAX_SAFE_INTEGER),
      lastTimestamp: Math.max(lastTimestamp, metric.lastTimestamp ?? 0),
    };
  }
};

const incrementCommunityImpression = (communityId: string, event: string, timestamp: number, state: Draft<UserEventsState>) => {
  if (!communityId || !event) {
    return;
  }
  if (!state.FIELD_COMMUNITY_IMPRESSION_METRICS) {
    state.FIELD_COMMUNITY_IMPRESSION_METRICS = {};
  }
  const metric = initializeCommunityMetric(state.FIELD_COMMUNITY_IMPRESSION_METRICS, communityId, event, timestamp);
  incrementUserMetricsState(metric, timestamp);
};

const slice = createSlice({
  name: 'userEvents',
  initialState,
  reducers: {

    CommunityImpressionHappened: (state, action: PayloadAction<{ communityId: string, event: string, timestamp: number }>) => {
      const {communityId, event, timestamp} = action.payload;
      incrementCommunityImpression(communityId, event, timestamp, state);
    },

    UserImpressionHappened: (state, action: PayloadAction<{ event: string, timestamp: number }>) => {
      const {event, timestamp} = action.payload;
      if (!state.FIELD_USER_IMPRESSION_METRICS) {
        state.FIELD_USER_IMPRESSION_METRICS = {};
      }
      const metric = initializeUserMetric(state.FIELD_USER_IMPRESSION_METRICS, event, timestamp);
      incrementUserMetricsState(metric, timestamp);
    },

    UserInteractionHappened: (state, action: PayloadAction<{ event: string, timestamp: number }>) => {
      const {event, timestamp} = action.payload;
      if (!state.FIELD_USER_INTERACTION_METRICS) {
        state.FIELD_USER_INTERACTION_METRICS = {}
      }
      const metric = initializeUserMetric(state.FIELD_USER_INTERACTION_METRICS, event, timestamp);
      incrementUserMetricsState(metric, timestamp);
    },

    CommunityImpressionMetricsLoaded: (state, action: PayloadAction<{ communityId: string, metrics: { [event: string]: UserEventMetrics } }>) => {
      const {communityId, metrics} = action.payload;
      if (!communityId) {
        return;
      }
      if (!state.FIELD_COMMUNITY_IMPRESSION_METRICS) {
        state.FIELD_COMMUNITY_IMPRESSION_METRICS = {};
      }
      if (!state.FIELD_COMMUNITY_IMPRESSION_METRICS[communityId]) {
        state.FIELD_COMMUNITY_IMPRESSION_METRICS[communityId] = {};
      }
      mergeMetricsState(state.FIELD_COMMUNITY_IMPRESSION_METRICS[communityId], metrics);
    },

    UserImpressionMetricsLoaded: (state, action: PayloadAction<{ [event: string]: UserEventMetrics }>) => {
      if (!state.FIELD_USER_IMPRESSION_METRICS) {
        state.FIELD_USER_IMPRESSION_METRICS = {}
      }
      mergeMetricsState(state.FIELD_USER_IMPRESSION_METRICS, action.payload);
    },

    UserInteractionMetricsLoaded: (state, action: PayloadAction<{ [event: string]: UserEventMetrics }>) => {
      state.userInteractionMetricsLoaded = true;
      if (!state.FIELD_USER_INTERACTION_METRICS) {
        state.FIELD_USER_INTERACTION_METRICS = {};
      }
      mergeMetricsState(state.FIELD_USER_INTERACTION_METRICS, action.payload);
    },

  },

  extraReducers: builder => builder

      .addCase(ActiveCommunityUpdated, (state, action: PayloadAction<string>) => {
        const communityId = action.payload;
        const timestamp = moment().valueOf();
        const communityIdBefore = state.activeCommunityId;
        if (communityIdBefore) {
          incrementCommunityImpression(communityIdBefore, 'Community', timestamp, state);
        }
        if (communityId) {
          state.activeCommunityId = communityId;
          incrementCommunityImpression(communityId, 'Community', timestamp, state);
        }
      })
      .addCase(AppColdStart, (state) => {
        state.userInteractionMetricsLoaded = false;
      })
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState)
      .addCase(UserJoinedCommunity, (state, action: PayloadAction<{ communityId: string, userId: string, member: CommunityMember }>) => {
        const {communityId} = action.payload;
        // Only count messages, events, members that were created in the last
        // week as "new".
        const oneWeekAgo = moment().subtract(7, 'days').valueOf();
        const now = moment().valueOf();
        const oneWeekAgoMetrics: UserEventMetrics = {
          count: 1,
          firstTimestamp: oneWeekAgo,
          lastTimestamp: oneWeekAgo,
        };
        const nowMetrics: UserEventMetrics = {
          count: 1,
          firstTimestamp: now,
          lastTimestamp: now,
        };
        const metrics: { [event: string]: UserEventMetrics } = {
          'EventsScreen': oneWeekAgoMetrics,
          'TopicsScreen': oneWeekAgoMetrics,
          'MembersScreen': nowMetrics,
        };
        if (!state.FIELD_COMMUNITY_IMPRESSION_METRICS[communityId]) {
          state.FIELD_COMMUNITY_IMPRESSION_METRICS[communityId] = {};
        }
        mergeMetricsState(state.FIELD_COMMUNITY_IMPRESSION_METRICS[communityId], metrics);
      })
      .addCase(UserProfileCreated, (state) => {
        if (!state.FIELD_USER_IMPRESSION_METRICS) {
          state.FIELD_USER_IMPRESSION_METRICS = {};
        }
        const now = moment().valueOf();
        state.FIELD_USER_IMPRESSION_METRICS['WhatsNewScreen'] = {
          count: 1,
          firstTimestamp: now,
          lastTimestamp: now,
        };
      }),

});

const {actions, reducer: UserEventsReducer} = slice;

const {
  CommunityImpressionHappened,
  CommunityImpressionMetricsLoaded,
  UserImpressionHappened,
  UserImpressionMetricsLoaded,
  UserInteractionHappened,
  UserInteractionMetricsLoaded,
} = actions;

export {
  CommunityImpressionHappened,
  CommunityImpressionMetricsLoaded,
  UserImpressionHappened,
  UserImpressionMetricsLoaded,
  UserInteractionHappened,
  UserInteractionMetricsLoaded,
  UserEventsReducer,
};