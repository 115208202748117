import {configureFonts} from 'react-native-paper';
import color from 'color';

// const fontConfig = {
//   default: {
//     regular: {
//       fontFamily: 'Roboto-Regular',
//       fontWeight: 'normal',
//     },
//     medium: {
//       fontFamily: 'Roboto-Medium',
//       fontWeight: 'normal',
//     },
//     light: {
//       fontFamily: 'Roboto-Light',
//       fontWeight: 'normal',
//     },
//     thin: {
//       fontFamily: 'Roboto-Thin',
//       fontWeight: 'normal',
//     },
//   },
// };

export const HeyloTheme = {
  dark: false,
  roundness: 10,
  colors: {
    primary: '#32C884',
    accent: '#7D50E7',
    background: '#f6f6f6',
    surface: '#ffffff',
    error: 'red',
    text: '#000000',
    onBackground: '#000000',
    onSurface: '#000000',
    disabled: color('#000000')
        .alpha(0.26)
        .rgb()
        .string(),
    placeholder: color('#000000')
        .alpha(0.54)
        .rgb()
        .string(),
    backdrop: color('#000000')
        .alpha(0.5)
        .rgb()
        .string(),
    notification: color('pink').rgb().string(),
  },
  fonts: configureFonts(),
  animation: {
    scale: 1.0,
  },
};