import React, {useEffect, useState} from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {GetReactionLabel} from '@heylo/shared/src/types/reactions';
import {StyleVars} from '../../styles/StyleVars';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';

type Props = {
  alwaysShowPlusButton?: boolean,
  containerStyle?: ViewStyle,
  darkMode?: boolean,
  onPressAdd: () => void,
  onPressCount: () => void,
  reactions?: { [userId: string]: number },
  uniqueId: string,
};

type ReactionCountsMap = { [reactionType: number]: number };

export const ReactionCounts = (props: Props) => {
  const {
    alwaysShowPlusButton,
    containerStyle,
    darkMode,
    onPressAdd,
    onPressCount,
    reactions,
    uniqueId,
  } = props;

  const [reactionCounts, setReactionCounts] = useState<ReactionCountsMap>({});
  useEffect(() => {
    const counts: ReactionCountsMap = {};
    for (const reaction of Object.values(reactions || {})) {
      const currentValue = counts[reaction];
      if (!currentValue) {
        counts[reaction] = 0;
      }
      counts[reaction]++;
    }
    setReactionCounts(counts);
  }, [reactions]);

  const renderPill = (key: string, onPress: () => void, child: any) => {
    return (
        <View
            key={key}
            style={{
              paddingEnd: 5,
            }}
        >
          <TouchableOpacity
              onPress={onPress}
              style={[styles.reactionCountContainer, {
                backgroundColor: darkMode ? StyleVars.Colors.GreyDarkest : StyleVars.Colors.White,
                borderColor: darkMode ? StyleVars.Colors.GreyDarkest : StyleVars.Colors.GreyLightest,
              }]}
          >
            {child}
          </TouchableOpacity>
        </View>
    );
  };

  return (
      <>
        {(alwaysShowPlusButton || Object.keys(reactionCounts).length > 0) && (
            <View style={{
              ...containerStyle,
              flexDirection: 'row',
            }}>
              {Object.entries(reactionCounts).map(([reactionTypeStr, count]) => {
                return renderPill(
                    `${uniqueId}${reactionTypeStr}`,
                    onPressCount,
                    <Text
                        key={`${uniqueId}_${reactionTypeStr}`}
                        style={[styles.reactionCountText, {
                          // NB: Max opacity is necessary to make sure emoji
                          // are not transparent on Android.
                          color: darkMode ? '#ffff' : '#000f',
                        }]}
                    >
                      {`${GetReactionLabel(parseInt(reactionTypeStr))} ${count}`}
                    </Text>,
                );
              })}
              {renderPill(`${uniqueId}Add`,
                  onPressAdd,
                  <View style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}>
                    <MaterialCommunityIcon
                        color={darkMode ? StyleVars.Colors.White : StyleVars.Colors.GreyDark}
                        name={'emoticon-outline'}
                        size={14}
                        style={{width: 14}}
                    />
                    <MaterialCommunityIcon
                        color={darkMode ? StyleVars.Colors.White : StyleVars.Colors.GreyDark}
                        name={'plus'}
                        size={12}
                    />
                  </View>,
              )}
            </View>
        )}
      </>
  );
};


const styles = StyleSheet.create({
  reactionCountContainer: {
    borderWidth: 1,
    borderRadius: 30,
    paddingHorizontal: 5,
    paddingVertical: 4,
  },
  reactionCountText: {
    fontSize: Platform.OS === 'web' ? 14 : 12,
    lineHeight: Platform.OS == 'web' ? 14 : undefined,
  },
});