import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import './DiscoverCommunity.css'
import {Loading} from '../navigation/Loading';
import {
  selectActiveCommunityPageAmbassadors,
  selectActiveCommunityProfile,
} from '@heylo/shared/src/features/communityProfiles/Selectors';
import {HeyloButton, HeyloDialog, HeyloText} from 'lib/materialUi';
import {listenForUserProfile} from '@heylo/shared/src/features/userProfiles/Firebase';
import {Attendee} from 'features/event';
import CloseIcon from '@material-ui/icons/Close';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CreateIcon from '@material-ui/icons/Create';
import Grid from '@material-ui/core/Grid';
import {WebAppDispatch} from 'store';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {UserSettingsFirebase} from '@heylo/shared/src/features/userSettings/Firebase';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {selectPendingJoinRequestCommunityId} from '@heylo/shared/src/features/userSettings/Selectors';
import {PendingCommunityJoinRequest} from '@heylo/shared/src/features/userSettings/Slice';
import {ShareCommunityButton} from '../community/ShareCommunityButton';
import {FirebaseAddUserToCommunity} from '@heylo/shared/src/features/communities/Firebase';
import {
  SelectActiveUserIsAdminInActiveCommunity,
  selectActiveUserIsMemberOfActiveCommunity,
  selectActiveUserIsProspectOfActiveCommunity,
} from '@heylo/shared/src/features/communityMembers/Selectors';
import {EditCommunityInfoButton} from '../community/EditCommunityInfoButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Linkify from 'linkifyjs/react';
import {useRootStyles} from 'styles/RootStyles';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {useJoinRequestState} from './useJoinRequestState';
import {CommunityJoinRequestUpdated} from '@heylo/shared/src/features/marketplaces/Slice';
import {SignInWithEmailReset} from '@heylo/shared/src/features/auth/Slice';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {Text, View} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {useNavigationUtils} from '../navigation/useNavigationUtils';
import {HeyloContainer} from '@heylo/components/src/ui/container/HeyloContainer';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  containerButtons: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    width: '100%',
  },
}));

enum JoinRequestState {
  INACTIVE,
  JOIN_REQUEST_PENDING,
  MEMBER,
  CANCEL_PENDING,
}

const mapState = (state: RootState) => {
  return {
    activeUserId: selectActiveUserId(state),
    ambassadorUserProfiles: selectActiveCommunityPageAmbassadors(state),
    communityProfile: selectActiveCommunityProfile(state),
    isAdmin: SelectActiveUserIsAdminInActiveCommunity(state),
    isMember: selectActiveUserIsMemberOfActiveCommunity(state),
    isProspect: selectActiveUserIsProspectOfActiveCommunity(state),
  };
};

const connector = connect(mapState, {});

type Props = ConnectedProps<typeof connector>;

export const DiscoverCommunity = connector((props: Props) => {
  return <PureDiscoverCommunity {...props}/>
});

export const PureDiscoverCommunity = (props: Props) => {
  const {
    activeUserId,
    ambassadorUserProfiles,
    communityProfile,
    isAdmin,
    isMember,
    isProspect,
  } = props;

  const {
    ambassadorUserIds,
    communityId = '',
    communityName = '',
    heroImageUrl,
    description,
    locationName,
    requirements,
  } = communityProfile || {};

  const dispatch: WebAppDispatch = useDispatch();
  const history = useHistory();
  const nav = useNavigationUtils();

  const isInvite = useRouteMatch('/invite');
  const logger = useLoggingService();

  const pendingJoinRequestCommunityId = useSelector((state: RootState) => selectPendingJoinRequestCommunityId(state));
  useEffect(() => {
    if (pendingJoinRequestCommunityId && activeUserId) {
      if (isInvite) {
        dispatch(FirebaseAddUserToCommunity(pendingJoinRequestCommunityId, activeUserId, {joinChannel: 'web'}))
            .then(() => {
              history.replace(`/members?communityId=${pendingJoinRequestCommunityId}`);
            })
            .catch((e: Error) => {
              console.warn('could not join', e);
            });
      } else {
        UserSettingsFirebase.AddProspectiveCommunity(activeUserId, pendingJoinRequestCommunityId);
      }
      dispatch(PendingCommunityJoinRequest(''));
    }
  }, [history, isInvite, pendingJoinRequestCommunityId, activeUserId]);

  const [joinRequestState, setJoinRequestState] = useState(JoinRequestState.INACTIVE);

  useEffect(() => {
    setJoinRequestState(isMember ? JoinRequestState.MEMBER
        : isProspect ? JoinRequestState.JOIN_REQUEST_PENDING
            : JoinRequestState.INACTIVE);
  }, [isMember, isProspect]);

  const {navigateToNextScreen} = useJoinRequestState();
  const handleJoinClick = () => {
    logger.logEvent(AnalyticsEvent.COMMUNITY_JOIN_REQUEST);
    dispatch(CommunityJoinRequestUpdated(communityProfile));
    dispatch(SignInWithEmailReset());
    navigateToNextScreen(false);
  };

  const confirmCancel = async () => {
    await UserSettingsFirebase.RemoveProspectiveCommunity(activeUserId, communityId);
    setJoinRequestState(JoinRequestState.INACTIVE);
  };

  const navToEdit = () => {
    if (isAdmin) {
      history.push(nav.resolveUriForEditGroupPage());
    }
  };

  useEffect(() => {
    for (const uid of Object.keys(ambassadorUserIds || {})) {
      dispatch(listenForUserProfile(uid));
    }
  }, [ambassadorUserIds, dispatch]);

  const rootClasses = useRootStyles();
  const classes = useStyles();
  const text = useTextStyles();
  const palette = usePalette();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  const visibleDescription = description || (isAdmin ? `Share what ${communityName} is all about!` : '');
  const visibleRequirements = requirements || (isAdmin ? `List any requirements to join here` : '');

  if (!communityProfile) {
    return <Loading/>
  }
  return (
      <>
        <div style={{backgroundColor: 'black'}}>
          <HeyloContainer maxWidth={'xs'}>
            <div
                className={'CommunityProfile-HeroImage'}
                style={{
                  background: `center / cover url(${heroImageUrl || 'https://firebasestorage.googleapis.com/v0/b/piccup-82257.appspot.com/o/assets%2Fcommunity-profile-default-hero.jpg?alt=media&token=82a41155-2f08-4838-b19c-64f4d499f76f'})`,
                }}
            />
          </HeyloContainer>
        </div>

        <HeyloContainer maxWidth={'xs'}
                        style={{padding: StyleConstants.SPACING}}>
          <Grid container spacing={2}
                style={{margin: 0, maxWidth: '100%', padding: 0}}>
            {isMember ? (
                <div className={classes.containerButtons}>
                  <ShareCommunityButton communityId={communityId}/>
                  <EditCommunityInfoButton/>
                </div>
            ) : (
                <Grid item xs={12}
                      style={{display: 'flex', justifyContent: 'center'}}>
                  <div style={{margin: '0 1rem'}}>
                    {(joinRequestState === JoinRequestState.JOIN_REQUEST_PENDING
                        || joinRequestState === JoinRequestState.CANCEL_PENDING) ? (
                        <>
                          <View>
                            <HeyloButton
                                color={'secondary'}
                                fullWidth={false}
                                label={'Cancel request'}
                                onClick={() => setJoinRequestState(JoinRequestState.CANCEL_PENDING)}
                                startIcon={<CloseIcon/>}
                            />
                            <Text
                                style={[text.body2, text.center, {
                                  color: palette.grey.main,
                                  marginTop: StyleConstants.SPACING / 2,
                                }]}>
                              Your join request has been sent.
                            </Text>
                          </View>
                          <HeyloDialog
                              actions={[{label: 'Not now'}, {
                                color: 'secondary',
                                label: 'Cancel request',
                                onClick: confirmCancel,
                              }]}
                              closeButton={false}
                              maxWidth={'xs'}
                              onClose={() => setJoinRequestState(JoinRequestState.JOIN_REQUEST_PENDING)}
                              open={joinRequestState === JoinRequestState.CANCEL_PENDING}
                              title={'Confirm cancellation?'}>
                            <HeyloText variant={'body1'}>
                              You can always request to join again in the future.
                            </HeyloText>
                          </HeyloDialog>
                        </>
                    ) : (
                        <HeyloButton
                            color={'primary'}
                            fullWidth={false}
                            label={isInvite ? 'Join now' : 'Request to join'}
                            onClick={handleJoinClick}
                            startIcon={<PersonAddIcon/>}
                        />
                    )}
                  </div>
                </Grid>
            )}

            <Grid item xs={12}>
              {locationName && (
                  <HeyloText variant={'subtitle2'}>
                    {locationName}
                  </HeyloText>
              )}

            </Grid>

            {visibleDescription ? (
                <Grid item xs={12}>
                  <div onClick={navToEdit}>
                    <HeyloText variant={'subtitle1'}
                               style={{display: 'flex', alignSelf: 'center'}}>
                      About us&nbsp;{isAdmin && <CreateIcon style={{}}/>}
                    </HeyloText>
                  </div>
                  <HeyloText style={{whiteSpace: 'pre-line'}} variant={'body1'}>
                    <Linkify options={{className: rootClasses.textLink}}>
                      {visibleDescription}
                    </Linkify>
                  </HeyloText>
                </Grid>
            ) : !isMember ? (
                <Grid item xs={12}>
                  <HeyloText variant={'body1'}>
                    Become a member to get access to the upcoming schedule, event details, member profiles, and chat!
                  </HeyloText>
                </Grid>
            ) : null}

            {visibleRequirements && (
                <Grid item xs={12}>
                  <div onClick={navToEdit}>
                    <HeyloText variant={'subtitle1'}
                               style={{display: 'flex', alignSelf: 'center'}}>
                      Member requirements&nbsp;{isAdmin &&
                    <CreateIcon style={{}}/>}
                    </HeyloText>
                  </div>
                  <HeyloText style={{whiteSpace: 'pre-line'}} variant={'body1'}>
                    <Linkify options={{className: rootClasses.textLink}}>
                      {visibleRequirements}
                    </Linkify>
                  </HeyloText>
                </Grid>
            )}

            {ambassadorUserProfiles.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <HeyloText variant={'subtitle1'}>
                      Ambassadors
                    </HeyloText>
                  </Grid>
                  {ambassadorUserProfiles.map(userProfile => {
                    const {id} = userProfile;
                    if (!id) {
                      return;
                    }
                    return (
                        <Grid key={`user${id}`} item xs={12}>
                          <Attendee userProfile={userProfile}/>
                        </Grid>
                    );
                  })}
                </>
            )}
          </Grid>

          {(matchXs && activeUserId) ? (
              <View style={{height: 50}}/>
          ) : null}
        </HeyloContainer>
      </>
  );
};
