import {Props} from './ChatScrollAreaAdapter';
import React, {useCallback, useEffect, useState} from 'react';
import {LayoutChangeEvent, View} from 'react-native';
import {ChatScrollArea, ChatScrollAreaApi} from './ChatScrollArea';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

export const ChatScrollAreaAdapter = React.memo((
    {
      data,
      footerElement,
      headerElement,
      onApiReady,
      onLoadMoreMessages,
      renderItem,
      showLastReadMessageIndicator,
      stableLastReadTimestamp,
    }: Props) => {
  console.count('ChatScrollAreaAdapter');
  const onLoadOldMessages = () => {
    console.log('onLoadOldMessages called');
    onLoadMoreMessages();
    return Promise.resolve();
  }

  const [apiRef, setApiRef] = useState<ChatScrollAreaApi | null>(null);
  const localOnApiReady = (api: ChatScrollAreaApi) => {
    setApiRef(api);
    onApiReady(api);
  };

  useEffect(() => {
    if (apiRef) {
      apiRef.scrollToBottom();
    }
  }, [apiRef]);

  const [scrollAreaHeight, setScrollAreaHeight] = useState(0);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const {height} = event.nativeEvent.layout
    setScrollAreaHeight(height);
    if (apiRef) {
      apiRef.scrollToBottom();
    }
  }, [apiRef]);

  return (
      <View
          onLayout={onLayout}
          style={{flex: 1, height: '100%'}}
      >
        <ChatScrollArea
            apiRef={localOnApiReady}
            loadOldMessagesThreshold={100}
            onLoadOldMessages={onLoadOldMessages}
            maxHeight={scrollAreaHeight}
        >
          <>
            <View style={{height: StyleConstants.SPACING}}/>
            {headerElement}
            {data.map((item, index) => {
              const {key = ''} = item;
              return (
                  <View key={key}>
                    {renderItem({item, index})}
                  </View>
              );
            })}
            {footerElement}
          </>
        </ChatScrollArea>
      </View>
  );
});