import React, {useEffect, useState} from 'react';
import {QrCode} from '../navigation/QrCode';
import {AppStoreBadge} from '../navigation/AppStoreBadge';
import {GenerateDynamicLinkForScreen, Screen} from '../navigation/DynamicLink';
import Container from '@material-ui/core/Container';
import {HeyloText} from 'lib/materialUi';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectActiveCommunityProfile} from '@heylo/shared/src/features/communityProfiles/Selectors';

export const InstallHeylo = () => {
  const {
    communityProfile,
  } = useSelector((state: RootState) => ({
    communityProfile: selectActiveCommunityProfile(state),
  }), shallowEqual);

  const {communityName = 'your group'} = communityProfile;

  const [installLink, setInstallLink] = useState('');
  useEffect(() => {
    setInstallLink(GenerateDynamicLinkForScreen(Screen.INVITE));
  }, []);

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'));
  const spacing = matchSm ? 4 : 2;
  return (
      <>
        <div style={{width: '100%'}}>
          <div style={{
            background: 'center / cover url(https://firebasestorage.googleapis.com/v0/b/piccup-82257.appspot.com/o/assets%2FgetTheApp%2Fmountainclimbing.jpg?alt=media&token=3caf9be9-958f-4156-883b-3fefa8fc440e)',
            padding: '5rem 10% 15rem 10%',
          }}>
            {matchSm ? (
                <>
                  <HeyloText align={'center'} paragraph variant={'h3'}>
                    Download the Heylo app and get {communityName} on the go
                  </HeyloText>
                  <AppStoreBadge/>
                </>
            ) : (
                <>
                  <HeyloText align={'center'} paragraph variant={'h3'}>
                    Scan the QR code to download the Heylo app and get {communityName} on the go
                  </HeyloText>

                  <HeyloText align={'center'} paragraph variant={'body1'}>
                    Point your phone camera at this QR code. When the link appears, tap it to download the Heylo app.
                  </HeyloText>

                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                    <QrCode link={installLink}/>
                  </div>
                </>
            )}
          </div>

          <Container maxWidth={'md'}
                     style={{padding: '2rem', paddingTop: '2rem'}}>
            <Grid alignItems={'center'}
                  container
                  spacing={spacing}>
              <Grid item xs={12} md={6}>
                <HeyloText variant={'h3'}>
                  Create topics for group messaging
                </HeyloText>
                <HeyloText variant={'body1'}>
                  Topics organize group messaging so everyone can share more, join only the discussions that are interesting and nothing gets too noisy.
                </HeyloText>
              </Grid>

              <Grid item xs={12} md={6}>
                <img
                    src={'https://firebasestorage.googleapis.com/v0/b/piccup-82257.appspot.com/o/assets%2FgetTheApp%2Ftopics.jpg?alt=media&token=e849c0fb-68d2-4c83-b3ad-37dac5168092'}
                    style={{
                      width: '100%',
                    }}
                />
              </Grid>

              <Grid container
                    alignItems={'center'}
                    direction={matchSm ? 'row' : 'row-reverse'}
                    spacing={spacing}>
                <Grid item xs={12} md={6}>
                  <HeyloText variant={'h3'}>
                    Use direct messaging to keep in touch
                  </HeyloText>
                  <HeyloText variant={'body1'}>
                    Get in contat with specific members of the group to say hi or follow-up.
                  </HeyloText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <img
                      src={'https://firebasestorage.googleapis.com/v0/b/piccup-82257.appspot.com/o/assets%2FgetTheApp%2Fchat.jpg?alt=media&token=fdde1418-d909-4f61-b0d9-8b5181db2794'}
                      style={{
                        width: '100%',
                      }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <HeyloText variant={'h3'}>
                  Share photos just to your group
                </HeyloText>
                <HeyloText variant={'body1'}>
                  Group members can discuss, react or download the photos you share
                </HeyloText>
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                    src={'https://firebasestorage.googleapis.com/v0/b/piccup-82257.appspot.com/o/assets%2FgetTheApp%2Fphotos.jpg?alt=media&token=f3a534a3-2ace-47aa-bcdd-21a867a55444'}
                    style={{
                      width: '100%',
                    }}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
  );
};
