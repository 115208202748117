import React from 'react';
import {Text} from 'react-native';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';

type Props = {
  title: string
};

export const TextHeader = React.memo((props: Props) => {
  const {title} = props;
  const text = useTextStyles();
  return (
      <Text
          ellipsizeMode={'tail'}
          numberOfLines={1}
          style={{
            ...text.h5,
            flex: 1,
            textAlign: 'center',
          }}
      >
        {title}
      </Text>
  );
});