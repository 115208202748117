import React, {useCallback, useEffect, useState} from 'react';
import {
  Linking,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {THREAD_TYPE_ANNOUNCEMENT} from '@heylo/shared/src/types/ThreadTypes';
import moment from 'moment';
import {Message as FirebaseMessage} from '@heylo/shared/src/types/firebase-types';
import {ReplyToMessage} from './ReplyToMessage';
import {
  MESSAGE_BORDER_RADIUS,
  MESSAGE_MARGIN,
  MESSAGE_MAX_WIDTH,
  MESSAGE_PADDING,
} from './Styles';
import {MessageImage} from './MessageImage';
import {MentionText} from './MentionText';
import {SendState} from '@heylo/shared/src/features/ui/Slice';
import {ActivityIndicator} from 'react-native-paper';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {StyleVars} from '../../styles/StyleVars';
import {StringContainsOnlyEmoji} from '@heylo/shared/src/util/strings';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {HeyloHyperlink} from '../../ui/link/Hyperlink';

type Props = {
  anyMessageIsSelected: boolean,
  isSelected: boolean,
  isUser: boolean,
  message: FirebaseMessage,
  messageId: string,
  messageRepliedTo: FirebaseMessage | null,
  onImageSelected: (messageId: string) => void,
  onMessageSelected: (message: FirebaseMessage | null) => void,
  sendState: SendState,
  threadType: string,
}

export const Message = React.memo((props: Props) => {
  const {
    anyMessageIsSelected,
    isSelected,
    isUser,
    message,
    messageId,
    messageRepliedTo,
    onImageSelected,
    onMessageSelected,
    sendState,
    threadType,
  } = props;

  const {deleted: isDeleted, image} = message;
  const isImageMessage = Boolean(image);

  const [messageElement, setMessageElement] = useState<any>(null);
  useEffect(() => {
    if (isDeleted) {
      // TODO: add italics
      setMessageElement(<Text>Message deleted</Text>);
      return;
    }

    const tokens: any[] = [message.content || ''];
    for (const [userId, mention] of Object.entries(message.mentions || {})) {
      // NB: `tokens` will grow in size as we iterate through this loop.
      for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];
        if (typeof token !== 'string')
          continue;
        const mentionText = '@' + mention.fullName;
        const index = token.indexOf(mentionText);
        if (index >= 0) {
          // Replace the current token with three: text up to the mention, the
          // mention itself, and the text after the mention.
          tokens.splice(i, 1,
              token.substring(0, index),
              <MentionText
                  key={i + userId}
                  onPress={() => setTimeout(() => onMessageSelected(null), 500)}
                  userId={userId}
                  value={mentionText}
              />,
              token.substring(index + mentionText.length));
          i++;
        }
      }
    }
    setMessageElement(<Text>{tokens}</Text>);
  }, [message]);

  const useAltBackground = isSelected || isDeleted;
  let textBackgroundColor = useAltBackground ? StyleVars.Colors.GreyLight : StyleVars.Colors.GreyLightest;
  if (isUser) {
    textBackgroundColor = useAltBackground ? StyleVars.Colors.PurpleLight : StyleVars.Colors.Purple;
  }

  const selectMessageAndShowMenus = useCallback(() => {
    if (isDeleted) {
      return;
    }
    onMessageSelected(message);
  }, [isDeleted, onMessageSelected]);

  const _onSingleTap = useCallback(() => {
    if (isDeleted) {
      return;
    }
    if (anyMessageIsSelected) {
      onMessageSelected(null);
    } else if (isImageMessage) {
      onImageSelected(messageId);
    } else {
      onMessageSelected(message);
    }
  }, [isDeleted, isImageMessage, anyMessageIsSelected, onImageSelected, onMessageSelected]);

  const onlyEmoji = StringContainsOnlyEmoji(message.content || '');

  /*
  const [pendingShareToInstagram, setPendingShareToInstagram] = useState(false);
  const _shareToInstagram = async () => {
    setPendingShareToInstagram(true);
    const photoUri = message.image ?? '';
    await ShareToInstagram(photoUri, communityInstagramHandle, communityLogo, {
      communityId: communityId || '',
      threadType,
    });
    setPendingShareToInstagram(false);
  };
  */

  const text = useTextStyles();

  return (
      <View style={{
        ...styles.containerAll,
        alignItems: isUser ? 'flex-end' : 'flex-start',
      }}>
        {messageRepliedTo && (
            <ReplyToMessage
                currentMessage={message}
                replyToMessage={messageRepliedTo}
            />
        )}

        <TouchableWithoutFeedback
            onPress={_onSingleTap}
            onLongPress={selectMessageAndShowMenus}
        >
          <View style={{
            alignItems: 'center',
            flexDirection: isUser ? 'row-reverse' : 'row',
            justifyContent: 'flex-start',
          }}>
            {onlyEmoji ? (
                <View style={{
                  maxWidth: threadType === THREAD_TYPE_ANNOUNCEMENT ? 'auto' : MESSAGE_MAX_WIDTH,
                  marginLeft: isUser ? 0 : MESSAGE_MARGIN,
                  marginRight: isUser ? MESSAGE_MARGIN : 0,
                  marginTop: 3,
                }}>
                  <Text style={{fontSize: 35}}>
                    {message.content || ''}
                  </Text>
                </View>
            ) : (
                <View style={{
                  backgroundColor: isImageMessage ? 'white' : textBackgroundColor,
                  borderRadius: MESSAGE_BORDER_RADIUS,
                  marginLeft: isUser ? 0 : MESSAGE_MARGIN,
                  marginRight: isUser ? MESSAGE_MARGIN : 0,
                  marginTop: 3,
                  // For announcements, allow messages to occupy the whole
                  // width of the screen to give users a noticeably
                  // different experience.
                  maxWidth: threadType === THREAD_TYPE_ANNOUNCEMENT ? '90%' : MESSAGE_MAX_WIDTH,
                  paddingHorizontal: isImageMessage ? 0 : MESSAGE_PADDING,
                  paddingVertical: isImageMessage ? 0 : MESSAGE_PADDING,
                }}>
                  {isImageMessage ? (
                      <MessageImage message={message}/>
                  ) : (
                      <HeyloHyperlink
                          linkStyle={{
                            ...text.link,
                            color: isUser ? 'white' : StyleVars.Colors.Purple,
                          }}
                      >
                        <Text
                            style={{
                              fontFamily: StyleVars.Font,
                              fontSize: 16,
                              textAlign: 'left',
                              color: isUser ? 'white' : StyleVars.Colors.Black,
                            }}
                        >
                          {messageElement}
                        </Text>
                      </HeyloHyperlink>
                  )}
                </View>
            )}

            {/*{ExperimentFlags.enableInstagramInChat.value() && (*/}
            {/*    <>*/}
            {/*      {isImageMessage && !pendingShareToInstagram && (*/}
            {/*          <TouchableOpacity onPress={_shareToInstagram}>*/}
            {/*            <Icon*/}
            {/*                color={StyleVars.Colors.GreyLighter}*/}
            {/*                containerStyle={{marginHorizontal: width * 0.02}}*/}
            {/*                iconStyle={{*/}
            {/*                  justifyContent: 'center',*/}
            {/*                  top: Platform.OS === 'ios' ? 2 : 0,*/}
            {/*                }}*/}
            {/*                type={'material-community'}*/}
            {/*                name={'instagram'}*/}
            {/*                size={36}*/}
            {/*            />*/}
            {/*          </TouchableOpacity>*/}
            {/*      )}*/}
            {/*      {pendingShareToInstagram && (*/}
            {/*          <ActivityIndicator*/}
            {/*              color={StyleVars.Colors.GreyLighter}*/}
            {/*              size={'large'}*/}
            {/*              style={{marginHorizontal: width * 0.02}}*/}
            {/*          />*/}
            {/*      )}*/}
            {/*    </>*/}
            {/*)}*/}

            {isUser && sendState === SendState.STARTED && (
                <ActivityIndicator
                    color={StyleVars.Colors.GreyMedium}
                    size={'small'}
                    style={{
                      height: 25,
                      marginRight: 10,
                      width: 25,
                    }}
                />
            )}
            {isUser && sendState === SendState.ERROR && (
                <MaterialCommunityIcon
                    color={StyleVars.Colors.Red}
                    name={'alert-circle'}
                    size={24}
                    style={{marginRight: 10, top: 2}}
                />
            )}
            {isSelected && (
                <Text style={styles.timestamp}>
                  {moment(message.createdAt || 0).format('HH:mm')}
                </Text>
            )}
          </View>
        </TouchableWithoutFeedback>

      </View>
  );
});

// @ts-ignore
Message.whyDidYouRender = true;

const styles = StyleSheet.create({
  containerAll: {
    flex: 1,
    paddingHorizontal: 10,
    width: '100%',
  },
  containerReplyTo: {
    flex: 1,
  },
  timestamp: {
    alignSelf: 'flex-end',
    color: StyleVars.Colors.GreyMedium,
    fontSize: 10,
    margin: '1%',
  },
});