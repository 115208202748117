import {createSelector} from 'reselect';
import _ from 'lodash';
import {CommunitySearchIndexSpec} from '@heylo/shared/src/types/firebase-types';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

const SearchIndexSelector = (state: RootState) => state.search.SEARCH_INDEX;

export const SearchSelectors = {
  SearchIndexLastUpdateTimestamp: (state: RootState) => state.search.LAST_UPDATE_TIMESTAMP,

  SearchIndex: createSelector(
      [SearchIndexSelector],
      (searchIndex): CommunitySearchIndexSpec[] => {
        return _.sortBy(
            Object.values(searchIndex),
            (indexSpec) => indexSpec.memberCount)
            .reverse();
      }),
};