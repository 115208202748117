import React, {useEffect, useMemo} from 'react';
import {Platform, View} from 'react-native';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {selectActiveUserProfilePhotoUri} from '@heylo/shared/src/features/userSettings/Selectors';
import {SelectUserProfilePictureUrl} from '@heylo/shared/src/features/userProfiles/Selectors';
import {listenForUserProfile} from '@heylo/shared/src/features/userProfiles/Firebase';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {HeyloImage} from '../../ui/image/HeyloImage';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {StyleVars} from '../../styles/StyleVars';

type OwnProps = {
  size?: number,
  userId: string,
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const {size: avatarSize = 30, userId} = ownProps;
  const activeUserId = selectActiveUserId(state);
  return {
    avatarSize,
    photoUrl: activeUserId === userId
        ? selectActiveUserProfilePhotoUri(state)
        : SelectUserProfilePictureUrl(state, userId, avatarSize),
    userId,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & OwnProps;

export const Avatar = connector((props: Props) => {
  const {
    avatarSize,
    photoUrl,
    userId,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listenForUserProfile(userId));
  }, [userId]);

  const palette = usePalette();

  const style = useMemo(() => (photoUrl ? {
    borderRadius: avatarSize / 2,
    height: avatarSize,
    width: avatarSize,
  } : {
    backgroundColor: StyleVars.Colors.White,
    borderRadius: avatarSize / 2,
    height: avatarSize,
    width: avatarSize,
  }), [avatarSize, photoUrl]);

  return (
      <View style={{
        height: avatarSize,
        width: avatarSize,
      }}>
        <View style={{
          alignItems: 'center',
          borderRadius: avatarSize / 2,
          height: avatarSize,
          justifyContent: 'center',
          width: avatarSize,
        }}>
          {photoUrl ? (
              <HeyloImage
                  aspectRatio={1}
                  showLoadingIndicator={false}
                  style={style}
                  uri={photoUrl}
              />
          ) : (
              <HeyloImage
                  aspectRatio={1}
                  key={'defaultAvatar' + userId}
                  showLoadingIndicator={false}
                  source={Platform.OS !== 'web' ? require('../../../assets/person_circle.png') : undefined}
                  style={style}
                  tintColor={palette.primary.main}
                  uri={Platform.OS === 'web' ? '/person_circle.png' : undefined}
              />
          )}
        </View>
      </View>
  );
});

// @ts-ignore
Avatar.whyDidYouRender = true;