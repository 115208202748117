import _ from 'lodash';
import {createSelector} from 'reselect';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

export const selectActiveUserAboutMe = (state: RootState) => state.userSettings?.FIELD_USER_BIO;
export const selectActiveUserCommunityIds = (state: RootState) => state.userSettings?.FIELD_COMMUNITY_IDS;
export const selectActiveUserCreationTimestamp = (state: RootState) => state.userSettings?.FIELD_USER_CREATION_TIMESTAMP;
export const selectActiveUserEmail = (state: RootState) => state.userSettings?.email;
export const selectActiveUserExperiments = (state: RootState) => state.userSettings?.FIELD_USER_EXPERIMENTS;
export const selectActiveUserFullName = (state: RootState) => state.userSettings?.FIELD_USER_FULL_NAME;
export const selectActiveUserInterests = (state: RootState) => state.userSettings?.FIELD_USER_GOALS;
export const selectActiveUserProfilePhotoUri = (state: RootState) => state.userSettings?.FIELD_USER_AVATAR_URI;
export const selectAllNetworks = (state: RootState) => state.userSettings?.FIELD_NETWORKS;
export const selectChatInfoVisible = (state:RootState) => state.userSettings?.chatInfoPaneVisible ?? false;
const selectProspectiveCommunityIds = (state: RootState) => state.userSettings?.FIELD_PROSPECTIVE_COMMUNITY_IDS;
export const selectPendingJoinRequestCommunityId = (state: RootState) => state.userSettings?.pendingJoinRequestCommunityId;
export const selectUserSettingsFetched = (state: RootState) => state.userSettings?.userSettingsFetched;

export const selectProspectiveCommunityIdsSet = createSelector(
    [selectProspectiveCommunityIds],
    (communityIds): Set<string> => {
      return new Set(communityIds ?? []);
    });

export const makeCommunityMemberFieldSpecSelector = () => createSelector(
    (state: RootState) => state.communityMemberFields.FIELD_MEMBER_FIELD_SPECS,
    (_state: RootState, communityId: string) => communityId,
    (allFieldSpecs, communityId) => {
      return _.sortBy(Object.values(allFieldSpecs[communityId] || {}),
          field => field.fieldId);
    });

export const selectAllMemberSinceDrafts = (state: RootState) => state.communityMemberFields.FIELD_MEMBER_SINCE_DRAFTS;

export const selectAllMemberFieldResponses = (state: RootState, communityId: string) =>
    state.communityMemberFields.FIELD_MEMBER_FIELD_RESPONSES[communityId] || {};

export const selectOneMemberFieldResponses = (state: RootState, communityId: string, userId: string) =>
    selectAllMemberFieldResponses(state, communityId)[userId] || {};

export const selectOneMemberOneFieldResponse = (state: RootState, communityId: string, userId: string, fieldId: string)
    : string =>
    selectOneMemberFieldResponses(state, communityId, userId)[fieldId]?.valueString || '';

export const selectAllMemberFieldResponseDrafts = (state: RootState) =>
    state.communityMemberFields.FIELD_MEMBER_FIELD_RESPONSE_DRAFTS;
