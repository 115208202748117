import SmsIcon from '@material-ui/icons/Sms';
import EventIcon from '@material-ui/icons/Event';
import GroupIcon from '@material-ui/icons/Group';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {FLAG_WEB_CHAT_ENABLED} from '../../Flags';

type Nav = {
  key: string,
  label: string,
  Icon: React.ElementType,
  routeMatch: string,
  to: string,
};

export const NAV_ITEMS: Array<Nav | null> = [
  FLAG_WEB_CHAT_ENABLED ?
      {
        key: 'topics',
        label: 'Topics',
        Icon: SmsIcon,
        routeMatch: '/topics',
        to: '/topics/',
      } : null,
  {
    key: 'events',
    label: 'Events',
    Icon: EventIcon,
    routeMatch: '/event',
    to: '/events?communityId=',
  },
  {
    key: 'members',
    label: 'Members',
    Icon: GroupIcon,
    routeMatch: '/members',
    to: '/members?communityId=',
  },
  // {
  //   key: 'content',
  //   label: 'Content',
  //   Icon: MenuBookIcon,
  //   routeMatch: '/content',
  //   to: '/content?communityId=',
  // },
  {
    key: 'app',
    label: 'App',
    Icon: PhoneIphoneIcon,
    routeMatch: '/install',
    to: '/install?communityId=',
  },
  {
    key: 'info',
    label: 'Info',
    Icon: InfoIcon,
    routeMatch: '/community',
    to: '/community?communityId=',
  },
];