import {Dimensions, Platform, TextStyle} from 'react-native';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';

export const useTextStyles = (): { [className: string]: TextStyle } => {
  const {width, height} = Dimensions.get('window');
  const palette = usePalette();

  let fontFamily = 'Roboto';
  switch (Platform.OS) {
    case 'android':
      fontFamily = 'Roboto';
      break;
    case 'ios':
      fontFamily = 'System';
      break;
    case 'web':
      fontFamily = 'Roboto, Helvetica, Arial, sans-serif';
      break;
  }

  const baseTextStyles: TextStyle = Object.assign<TextStyle, TextStyle>({
        fontFamily,
      },
      Platform.OS === 'web' ? {
        // @ts-ignore: web only
        userSelect: 'none',
      } : {});

  const headerStyle: TextStyle = Object.assign<TextStyle, TextStyle>({
        ...baseTextStyles,
        fontWeight: 'bold',
      },
      Platform.OS === 'web' ? {
        // @ts-ignore: web only
        userSelect: 'none',
      } : {});

  // For background on typography classes, see
  // https://material-ui.com/components/typography/#component.
  return {
    h1: {
      ...headerStyle,
      fontSize: 80,
    },
    h2: {
      ...headerStyle,
      fontSize: 60,
    },
    h3: {
      ...headerStyle,
      fontSize: 48,
    },
    h4: {
      ...headerStyle,
      fontSize: 34,
    },
    h5: {
      ...headerStyle,
      fontSize: 24,
    },
    h6: {
      ...headerStyle,
      fontSize: 20,
    },
    body1: {
      ...baseTextStyles,
      fontSize: 16,
    },
    body2: {
      ...baseTextStyles,
      fontSize: 14,
    },
    subtitle1: {
      ...baseTextStyles,
      color: palette.grey.main,
      fontSize: 16,
      fontWeight: '600',
    },
    subtitle2: {
      ...baseTextStyles,
      fontSize: 14,
      fontWeight: '600',
    },
    bold: {
      fontWeight: 'bold',
    },
    center: {
      textAlign: 'center',
    },
    error: {
      color: palette.error.medium,
    },
    link: {
      color: palette.secondary.main,
      fontWeight: 'bold',
    },
  };
};