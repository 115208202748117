import {createSelector} from 'reselect';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {
  CommunityPhotoAlbum,
  SharedPhoto,
} from '@heylo/shared/src/types/firebase-types';
import {ActiveThreadIdSelector} from '@heylo/shared/src/features/threads/SimpleSelectors';
import _ from 'lodash';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

const AllCommunityAlbumsSelector = (state: RootState) => state.photos.FIELD_COMMUNITY_ALBUMS;
const AllCommunityPhotosSelector = (state: RootState) => state.photos.FIELD_COMMUNITY_PHOTOS;
const AllPrivateChatPhotosSelector = (state: RootState) => state.photos.FIELD_PRIVATE_CHAT_PHOTOS;

const LastPhotoTimestampForCommunitySelector = (state: RootState, communityId: string): number =>
    state.photos.FIELD_MOST_RECENT_COMMUNITY_PHOTO_TIMESTAMP[communityId] || 0;

const LastPhotoTimestampForPrivateChatSelector = (state: RootState, threadId: string): number =>
    state.photos.FIELD_MOST_RECENT_PRIVATE_CHAT_PHOTO_TIMESTAMP[threadId] || 0;

const ActiveCommunityAlbumsSelector = createSelector(
    [AllCommunityAlbumsSelector, SelectActiveCommunityId],
    (
        albums,
        activeCommunityId,
    ): { [albumId: string]: CommunityPhotoAlbum } => {
      return albums[activeCommunityId] || {};
    });

const ActiveCommunityPhotosSelector = createSelector(
    [AllCommunityPhotosSelector, SelectActiveCommunityId],
    (allPhotos, activeCommunityId)
        : { [photoId: string]: SharedPhoto } => {
      return allPhotos[activeCommunityId] || {};
    });

const ActivePrivateChatPhotosSelector = createSelector(
    [AllPrivateChatPhotosSelector, ActiveThreadIdSelector],
    (allPhotos, activeThreadId)
        : { [photoId: string]: SharedPhoto } => {
      return allPhotos[activeThreadId] || {};
    });

const CommunityPhotoAlbumSortedPhotosSelector = (state: RootState, communityId: string, albumId: string)
    : SharedPhoto[] => {
  const albums = AllCommunityAlbumsSelector(state);
  const photos = AllCommunityPhotosSelector(state)[communityId] || {};
  return _.sortBy(
      Object.entries(albums[communityId]?.[albumId]?.photos || {}),
      ([photoId, updateTimestamp]) => -1 * updateTimestamp)
      .map(([photoId]) => photos[photoId])
      .filter(photo => photo);
};

export {
  LastPhotoTimestampForCommunitySelector,
  LastPhotoTimestampForPrivateChatSelector,
  ActiveCommunityAlbumsSelector,
  ActiveCommunityPhotosSelector,
  ActivePrivateChatPhotosSelector,
  CommunityPhotoAlbumSortedPhotosSelector,
};
