import {connect, useDispatch, useSelector} from 'react-redux';
import {
  CloseDrawer,
  OpenDrawer,
} from '@heylo/shared/src/features/navigation/Slice';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import React, {useEffect, useState} from 'react';
import {selectDrawerIsOpen} from '@heylo/shared/src/features/navigation/Selectors';
import {HeyloText} from '../../lib/materialUi';
import useTheme from '@material-ui/core/styles/useTheme';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

type Props = {
  title: string,
}

export const ScreenHeader = connect()((props: Props) => {
  const {title} = props;

  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    dispatch(drawerOpen ? CloseDrawer() : OpenDrawer());
  };

  const isDrawerOpen = useSelector((state: RootState) => selectDrawerIsOpen(state));
  useEffect(() => {
    setDrawerOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  const theme = useTheme();
  return (
      <div style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        position: 'relative',
      }}>
        <HeyloText variant={'h3'}>
          {title}
        </HeyloText>

        <div style={{
          position: 'absolute',
          left: 0,
        }}>
          <IconButton
              aria-label="open drawer"
              onClick={handleDrawerToggle}
          >
            <MenuIcon/>
          </IconButton>
        </div>
      </div>
  );
});