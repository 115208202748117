import {FirebaseStorage} from '@heylo/firebase-database';

const CACHE_CONTROL = 'public, max-age=604800, immutable';

export const UploadImageWithContentType = (
    {
      contentType,
      imageWidth,
      imageHeight,
      fileName,
      storagePath,
      localUri = '',
      blob,
    }: {
      contentType: string,
      fileName: string,
      imageHeight?: number,
      imageWidth?: number,
      storagePath: string,
      localUri?: string,
      blob?: Blob | ArrayBuffer,
    }) => {
  return new Promise<{
    downloadURL: string,
    metadata: any
  }>((resolve, reject) => {
    const childRef = FirebaseStorage().ref(storagePath).child(fileName);
    const metadata = {
      cacheControl: CACHE_CONTROL,
      contentType,
      customMetadata: {
        imageWidth: imageWidth ? imageWidth.toString() : '',
        imageHeight: imageHeight ? imageHeight.toString() : '',
      },
    };
    let task;
    if (blob) {
      task = childRef.put(blob, metadata)
    } else {
      task = childRef.putFile(localUri, metadata);
    }
    let taskMetadata: any;
    task.then(task => {
      taskMetadata = task.metadata;
      return childRef.getDownloadURL();
    }).then(url => {
      resolve({
        downloadURL: url,
        metadata: taskMetadata,
      });
    }).catch(e => {
      console.error('error uploading image', e);
      reject(e);
    });
  });
};