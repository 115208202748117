import React from 'react';
import {View, ViewStyle} from 'react-native';
import {Badge} from 'react-native-paper';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';

type Props = {
  backgroundColor?: string,
  color?: string,
  containerStyle?: ViewStyle,
  size?: 'small' | 'medium',
  value?: number | string,
};

export const GreenBadge = React.memo((props: Props) => {
  const {
    backgroundColor,
    color,
    containerStyle,
    value,
  } = props;

  const palette = usePalette();
  const text = useTextStyles();

  return (
      <View style={containerStyle || {}}>
        <Badge
            size={value ? 24 : 12}
            style={[{
              color: color || 'white',
              backgroundColor: backgroundColor || palette.primary.main,
              paddingHorizontal: 5,
            }, text.bold, text.body2]}
            visible={true}
        >
          {value}
        </Badge>
      </View>
  );
});
