import * as firebase from 'firebase/app';
// @ts-ignore
import 'firebase/installations';

// TODO: pull this out into a separate lib
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(config);
}

export class Firebase {
  static auth = (): firebase.auth.Auth => {
    return firebase.auth();
  };

  static database = (): firebase.database.Database => {
    return firebase.database();
  };

  static installations = (): firebase.installations.Installations => {
    return firebase.app().installations();
  };

  static serverTimestamp = (): number => {
    // @ts-ignore
    return firebase.database.ServerValue.TIMESTAMP;
  };
}