import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppReset} from '@heylo/shared/src/features/app/Actions';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {DeepReadonly} from 'utility-types';
import {
  PaymentSummary,
  StripeAccount,
  StripeUser,
} from '@heylo/shared/src/types/firebase-types';

export type StripeState = {
  accounts: DeepReadonly<{ [accountId: string]: StripeAccount }>,
  accountToCommunityMap: DeepReadonly<{ [accountId: string]: string }>,
  communityToAccountMap: DeepReadonly<{ [communityId: string]: string }>,
  eventDonations: DeepReadonly<{ [eventId: string]: { [paymentId: string]: PaymentSummary | null } }>,
  user: DeepReadonly<StripeUser | null>,
};

const initialState: StripeState = {
  accounts: {},
  accountToCommunityMap: {},
  communityToAccountMap: {},
  eventDonations: {},
  user: null,
};

export const slice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {

    StripeAccountUpdated: (state, action: PayloadAction<{
      accountId: string,
      account: DeepReadonly<StripeAccount>
    }>) => {
      const {accountId, account} = action.payload;
      if (accountId) {
        state.accounts[accountId] = account;
      }
    },

    StripeAccountMappingUpdated: (state, action: PayloadAction<{
      communityId: string,
      accountId: string
    }>) => {
      const {communityId, accountId} = action.payload;
      if (accountId) {
        state.accountToCommunityMap[accountId] = communityId;
      }
      if (communityId) {
        state.communityToAccountMap[communityId] = accountId;
      }
    },

    StripeEventDonationsUpdated: (state, action: PayloadAction<{
      eventId: string,
      donations: DeepReadonly<{ [paymentId: string]: PaymentSummary | null }>,
    }>) => {
      const {eventId, donations} = action.payload;
      if (!state.eventDonations) {
        state.eventDonations = {};
      }
      state.eventDonations[eventId] = donations;
    },

    StripeRemovePaymentMethod: (state) => {
      if (state.user?.paymentMethod) {
        state.user.paymentMethod = undefined;
      }
    },

    StripeUserUpdated: (state, action: PayloadAction<DeepReadonly<StripeUser> | null>) => {
      state.user = action.payload;
    },

  },

  extraReducers: builder => builder
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState),
});

const {actions, reducer: StripeReducer} = slice;

const {
  StripeAccountUpdated,
  StripeAccountMappingUpdated,
  StripeEventDonationsUpdated,
  StripeRemovePaymentMethod,
  StripeUserUpdated,
} = actions;

export {
  StripeAccountUpdated,
  StripeAccountMappingUpdated,
  StripeEventDonationsUpdated,
  StripeRemovePaymentMethod,
  StripeUserUpdated,
  StripeReducer,
};
