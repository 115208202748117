import {selectUserInteractionMetrics} from './Selectors';
import {UserEventMetrics} from '@heylo/shared/src/types/firebase-types';
import {
  selectCommunityImpressions,
  selectUserImpressionMetrics,
} from '@heylo/shared/src/features/userEvents/Selectors';
import {FirebaseDatabase} from '@heylo/firebase-database';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {
  CommunityImpressionMetricsLoaded,
  UserImpressionMetricsLoaded,
  UserInteractionMetricsLoaded,
} from '@heylo/shared/src/features/userEvents/Reducer';

export const FirebaseUpdateCommunityImpressionMetrics = (userId: string)
    : ThunkReturnType<Promise<void>> => (dispatch, getState) => {
  if (!userId) {
    return Promise.reject();
  }
  const state = getState();
  const allImpressions = selectCommunityImpressions(state);
  const updates: any = {};
  for (const [communityId, communityImpressions] of Object.entries(allImpressions)) {
    if (!communityImpressions) {
      continue;
    }
    for (const [contentId, metrics] of Object.entries(communityImpressions || {})) {
      if (!communityId || !contentId) {
        continue;
      }
      updates[`/${communityId}/${contentId}`] = metrics;
    }
  }
  return FirebaseDatabase().ref(`/userImpressionsByCommunity/${userId}`)
      .update(updates)
      .catch(e => console.warn('failed to update /userImpressionsByCommunity', e.message, e));
};

export const FirebaseUpdateUserImpressionMetrics = (userId: string)
    : ThunkReturnType<Promise<void>> => (dispatch, getState) => {
  if (!userId) {
    return Promise.reject();
  }
  const state = getState();
  const allImpressions = selectUserImpressionMetrics(state);
  const updates: any = {};
  for (const [contentId, metrics] of Object.entries(allImpressions)) {
    if (!contentId)
      continue;
    updates[contentId] = metrics;
  }
  return FirebaseDatabase().ref(`/userImpressions/${userId}`).update(updates)
      .catch(e => console.warn('failed to update /userImpressions', e.message, e));
};


export const FirebaseUpdateUserInteractionMetrics = (userId: string)
    : ThunkReturnType<Promise<void>> => (dispatch, getState) => {
  if (!userId) {
    return Promise.reject();
  }
  const state = getState();
  const updates: { [key: string]: UserEventMetrics } = {};
  const allInteractionMetrics = selectUserInteractionMetrics(state);
  for (const [event, metrics] of Object.entries(allInteractionMetrics)) {
    if (!event)
      continue;
    updates[event] = metrics;
  }
  return FirebaseDatabase().ref(`/userInteractions/${userId}`).update(updates)
      .catch(e => console.warn('failed to update /userInteractions', e.message, e));
};

export const LoadCommunityImpressionMetrics = (userId: string, communityId: string)
    : ThunkReturnType<void> => dispatch => {
  FirebaseDatabase().ref(`/userImpressionsByCommunity/${userId}/${communityId}`).once('value')
      .then(snapshot => {
        const metrics: { [event: string]: UserEventMetrics } = snapshot.val() || {};
        dispatch(CommunityImpressionMetricsLoaded({communityId, metrics}));
      })
      .catch(e => {
        console.warn(`Failed to load /userImpressionsByCommunity/${userId}/${communityId}`, e);
      });
};

export const LoadUserImpressionMetrics = (userId: string)
    : ThunkReturnType<void> => dispatch => {
  FirebaseDatabase().ref(`/userImpressions/${userId}`).once('value')
      .then(snapshot => {
        const metricsMap: { [event: string]: UserEventMetrics } = snapshot.val() || {};
        dispatch(UserImpressionMetricsLoaded(metricsMap));
      });
};

export const LoadUserInteractionMetrics = (userId: string)
    : ThunkReturnType<void> => dispatch => {
  FirebaseDatabase().ref(`/userInteractions/${userId}`).once('value')
      .then(snapshot => {
        const metricsMap: { [event: string]: UserEventMetrics } = snapshot.val() || {};
        dispatch(UserInteractionMetricsLoaded(metricsMap));
      });
};