import React, {Ref} from 'react';
import {TextInput, TextInputProps} from 'react-native';
import TextField from '@material-ui/core/TextField';

type Props = TextInputProps & {
  dense?: boolean,
  error?: boolean,
  label?: string,
};

export const HeyloTextInput = React.forwardRef((
    {
      autoCompleteType,
      autoFocus,
      dense,
      error,
      label,
      multiline,
      numberOfLines,
      onChange,
      onChangeText,
      onSubmitEditing,
      placeholder,
      secureTextEntry,
      value,
      style,
      ...rest
    }: Props, ref: Ref<TextInput>) => {

  let autoComplete = '';
  switch(autoCompleteType) {
    case 'password':
      autoComplete = 'current-password';
      break;
    case 'email':
      autoComplete = 'email';
      break;
  }
  return (
      <TextField
          autoComplete={autoComplete || undefined}
          autoFocus={autoFocus}
          color={'secondary'}
          error={error}
          fullWidth={true}
          // helperText={helperText}
          // id={id}
          // inputProps={{
          //   ...inputProps,
          //   maxLength,
          // }}
          // InputProps={IconStart ? {
          //   startAdornment: (
          //       <InputAdornment position="start">
          //         <IconStart/>
          //       </InputAdornment>
          //   ),
          // } : {}}
          inputRef={ref}
          label={label}
          margin={dense ? 'dense' : undefined}
          multiline={multiline}
          // Using onChange first, if it is provided (e.g., by
          // `GooglePlacesAutocomplete`)
          //
          // @ts-ignore
          onChange={e => onChange?.(e) || onChangeText?.(e.target.value)}
          // @ts-ignore: no native event to provide
          onKeyPress={(event) => event.key === 'Enter' && onSubmitEditing?.()}
          type={secureTextEntry ? 'password' : undefined}
          placeholder={placeholder}
          // required={required}
          // rows={rows}
          rowsMax={numberOfLines}
          // style={style}
          // value={value}
          variant={'outlined'}
          value={value}
          // @ts-ignore
          style={style}
      />
  );
});