import {StripeAccount} from '@heylo/shared/src/types/firebase-types';
import React from 'react';
import {ResolveDefaultExternalAccount} from '@heylo/shared/src/features/stripe/Account';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import {HeyloText} from '../../lib/materialUi';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClockIcon from '@material-ui/icons/Schedule';
import WarningIcon from '@material-ui/icons/Warning';
import useTheme from '@material-ui/core/styles/useTheme';

type Props = {
  account: StripeAccount,
};

export const StripeExternalAccount = (props: Props) => {
  const {
    account,
  } = props;
  const {
    cardPayments = 'pending',
    transfers = 'pending',
  } = account.capabilities || {};

  const externalAccount = ResolveDefaultExternalAccount(account);
  const theme = useTheme();

  const {
    bankName = 'Bank account',
    cardBrand = 'Debit card',
    expiresMonth = 0,
    expiresYear = 0,
    last4 = '',
    routingNumber,
    type = '',
  } = externalAccount ?? {};

  const renderAccount = (Icon: any, line1: string, line2: string) => {
    return (
        <>
          <Icon/>
          <div style={{margin: '1rem'}}>
            <HeyloText variant={'body1'}>
              {line1}
            </HeyloText>
            <HeyloText variant={'body1'}>
              {line2}
            </HeyloText>
          </div>
        </>
    );
  }

  const renderCapabilityWarning = () => {
    if (cardPayments === 'active' && transfers === 'active') {
      return null;
    }
    const needsAttention = cardPayments === 'inactive' || transfers === 'inactive';
    return (
        <div style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 10,
        }}>
          {needsAttention ? <WarningIcon color={'error'}/> : <ClockIcon/>}
          <HeyloText
              style={{
                display: 'flex',
                minWidth: 0,
                marginLeft: 10,
              }}
              variant={'body1'}
          >
            {needsAttention ? 'Your Stripe account requires attention.' : 'Verification pending; this can take several minutes.'} Tap to update.
          </HeyloText>
        </div>
    );
  };

  if (type !== 'card' && type !== 'bank_account') {
    return (
        <div style={{marginTop: theme.spacing(1)}}>
          {renderCapabilityWarning()}
        </div>
    );
  }
  const isValid = cardPayments === 'active' && transfers === 'active';
  return (
      <>
        <div style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          minWidth: 0,
        }}>
          {type === 'bank_account' && renderAccount(AccountBalanceIcon, bankName, `⑆ ${routingNumber} ⑆ •••• ${last4}`)}
          {type === 'card' && renderAccount(PaymentIcon, cardBrand, `•••• ${last4 || '????'} Exp ${expiresMonth || '??'}/${expiresYear || '??'}`)}
          {isValid && <CheckCircleIcon color={'primary'}/>}
          <ChevronRightIcon/>
        </div>
        {renderCapabilityWarning()}
      </>
  );
};