import React from 'react';
import {View} from 'react-native';
import {List} from 'react-native-paper';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {HeyloSwitch} from '../../ui/switch';

type Props = {
  onValueChange: (value: boolean) => void,
  subtitle?: string,
  title: string,
  value: boolean,
};

export const SwitchControl = React.memo((props: Props) => {
  const {
    onValueChange,
    subtitle,
    title,
    value,
  } = props;
  const text = useTextStyles();
  const palette = usePalette();
  return (
      <List.Item
          description={subtitle}
          descriptionEllipsizeMode={'tail'}
          descriptionNumberOfLines={2}
          descriptionStyle={[text.body2, {color: palette.grey.main}]}
          onPress={() => onValueChange(!value)}
          right={() => (
              <View style={{
                marginLeft: StyleConstants.SPACING,
                justifyContent: 'center',
              }}>
                <HeyloSwitch
                    onChange={onValueChange}
                    value={value}
                />
              </View>
          )}
          style={{padding: 0}}
          title={title}
          titleEllipsizeMode={'tail'}
          titleNumberOfLines={2}
          titleStyle={text.body1}

      />
  );
});