import React, {useEffect, useState} from 'react';
import {Clipboard, StyleSheet, View} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {Message} from '@heylo/shared/src/types/firebase-types';
import {
  IsThreadTypeCommunityBased,
  THREAD_TYPE_ANNOUNCEMENT,
} from '@heylo/shared/src/types/ThreadTypes';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {SelectActiveUserIsAdminInActiveCommunity} from '@heylo/shared/src/features/communityMembers/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {HeyloButton} from '../../ui/button/HeyloButton';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {HeyloDialog} from '../../ui/dialog/HeyloDialog';

const mapStateToProps = (state: RootState) => {
  return {
    isAdmin: SelectActiveUserIsAdminInActiveCommunity(state),
    userId: selectActiveUserId(state),
  };
};
const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & {
  deleteMessage: (messageId: string) => void,
  isPrivateChat: boolean,
  message: Message | null,
  onCopy: () => void,
  replyToMessage: (messageId: string) => void,
  threadType: string,
  unselectMessage: () => void,
  userHasJoined: boolean,
};

export const SelectedMessageActions = connector((props: Props) => {
  const {
    deleteMessage,
    isAdmin,
    isPrivateChat,
    message,
    onCopy,
    replyToMessage,
    threadType,
    unselectMessage,
    userHasJoined,
    userId,
  } = props;

  const logger = useLoggingService();

  const [hasDeletePermissions, setHasDeletePermissions] = useState(false);

  useEffect(() => {
    const ownerId = message && message.ownerId || '';
    const hasCommunityAdminPermissions = isAdmin && !isPrivateChat;
    const hasMessageOwnerPermissions = userId === ownerId;
    setHasDeletePermissions(hasCommunityAdminPermissions || hasMessageOwnerPermissions);
  }, [isAdmin, message, isPrivateChat]);

  const [showCopyOption, setShowCopyOption] = useState(false);
  useEffect(() => {
    setShowCopyOption(!Boolean(message?.image));
  }, [message]);

  const copySelectedMessage = () => {
    if (!message) {
      return;
    }
    Clipboard.setString(message.content || '');
    unselectMessage();
    onCopy();
  };

  const deleteSelectedMessage = () => {
    if (!message) {
      return;
    }
    setAlertVisible(true);
  };

  const replyToSelectedMessage = () => {
    if (!message) {
      return;
    }
    unselectMessage();
    replyToMessage(message.key || '');
  };

  const showReplyOption =
      !IsThreadTypeCommunityBased(threadType)
      || (userHasJoined && threadType !== THREAD_TYPE_ANNOUNCEMENT);

  const [alertVisible, setAlertVisible] = useState(false);
  const hideAlert = () => {
    setAlertVisible(false);
    unselectMessage();
  };

  return (
      <View style={styles.containerActions}>
        {showCopyOption && (
            <View style={styles.containerButton}>
              <HeyloButton
                  icon={'content-copy'}
                  label={'Copy'}
                  onPress={copySelectedMessage}
              />
            </View>
        )}

        {hasDeletePermissions && (
            <View style={styles.containerButton}>
              <HeyloButton
                  icon={'delete'}
                  label={'Delete'}
                  onPress={deleteSelectedMessage}
              />
            </View>
        )}

        {showReplyOption && (
            <View style={styles.containerButton}>
              <HeyloButton
                  icon={'reply'}
                  label={'Reply'}
                  onPress={replyToSelectedMessage}
              />
            </View>
        )}

        <HeyloDialog
            body={'The message will be deleted for everyone.'}
            actions={[{
              color: 'black',
              label: 'Cancel',
              onPress: hideAlert,
            },
              {
                color: 'red',
                label: 'Delete',
                onPress: () => {
                  logger.logEvent(AnalyticsEvent.CHAT_DELETE_MESSAGE);
                  const messageId = message?.key || '';
                  if (messageId) {
                    deleteMessage(messageId);
                  }
                  hideAlert();
                },
              }]}
            onDismiss={hideAlert}
            title={'Delete this message?'}
            visible={alertVisible}
        />
      </View>
  );
});

const styles = StyleSheet.create({
  containerActions: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: StyleConstants.SPACING / 2,
    width: '100%',
  },
  containerButton: {
    flexGrow: 1,
    marginHorizontal: 4,
  },
});
