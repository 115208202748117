import React, {useEffect} from 'react';
import {GroupCard, GroupCardUiType} from './GroupCard';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  selectActiveMarketId,
  selectActiveMarketplaceCommunities,
  selectActiveMarketplaceProspectiveCommunities,
} from '@heylo/shared/src/features/marketplaces/Selectors';
import {FlatList, View} from 'react-native';
import {CommunityProfile} from '@heylo/shared/src/types/firebase-types';
import {HeyloListSubheader} from '../../ui/list/HeyloListSubheader';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {useGroupSearchState} from './useGroupSearchState';
import {MarketplacesFirebase} from '@heylo/shared/src/features/marketplaces/Firebase';

type Props = {
  filterText: string,
  onPress: (communityId: string) => void,
};

export const MarketplaceCommunitiesList = (props: Props) => {
  const dispatch = useDispatch();
  const {filterText, onPress} = props;

  const {
    communities,
    marketplaceId,
    prospectiveCommunities,
  } = useSelector((state: RootState) => ({
    communities: selectActiveMarketplaceCommunities(state),
    marketplaceId: selectActiveMarketId(state),
    prospectiveCommunities: selectActiveMarketplaceProspectiveCommunities(state),
  }), shallowEqual);

  useEffect(() => {
    if (marketplaceId) {
      dispatch(MarketplacesFirebase.AttachCommunitiesListener(marketplaceId));
    }
  }, [marketplaceId]);

  const {searchResults} = useGroupSearchState({filterText});

  const renderCommunityProfile = (item: CommunityProfile, uiType: GroupCardUiType) => {
    const {communityId} = item;
    if (!communityId) {
      return null;
    }
    return (
        <View style={{
          // NB: extra padding on the bottom to ensure drop shadow isn't cut off
          paddingBottom: StyleConstants.SPACING,
          paddingHorizontal: StyleConstants.SPACING,
          paddingTop: 5,
        }}>
          <GroupCard
              community={item}
              onPress={onPress}
              uiType={uiType}
          />
        </View>
    );
  };

  if (filterText) {
    return (
        <View style={{paddingTop: 5}}>
          <FlatList
              data={searchResults}
              keyExtractor={item => item.communityId || ''}
              renderItem={({item}) => renderCommunityProfile(item, GroupCardUiType.SEARCH_RESULT)}
          />
        </View>
    );
  }
  return (
      <>
        <View style={{paddingTop: 5}}>
          {prospectiveCommunities.length > 0 && (
              <>
                <HeyloListSubheader>JOIN REQUEST PENDING</HeyloListSubheader>
                <FlatList
                    data={prospectiveCommunities}
                    keyExtractor={item => item.communityId || ''}
                    renderItem={({item}) => renderCommunityProfile(item, GroupCardUiType.PROSPECTIVE)}
                />
              </>
          )}

          {prospectiveCommunities.length > 0 && (
              <HeyloListSubheader>MORE GROUPS</HeyloListSubheader>
          )}
          <FlatList
              data={communities}
              keyExtractor={item => item.communityId || ''}
              renderItem={({item}) => renderCommunityProfile(item, GroupCardUiType.DISCOVER)}
          />
        </View>
      </>
  );
};
