import React, {useEffect, useState} from 'react';
import {Text, View} from 'react-native';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {LoggingService} from '@heylo/shared/src/services/logging/LoggingService';
import {HeyloButton} from '@heylo/components/src/ui/button/HeyloButton';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {ActivityIndicator} from 'react-native-paper';

enum State {
  INITIALIZING,
  PENDING_SIGN_IN_LINK,
  SIGNING_IN,
  SUCCESS,
  ERROR_NO_EMAIL,
  ERROR_SIGNING_IN,
};

type Props = {
  activeUserId: string,
  email: string,
  logger?: LoggingService,
  // Called after the user presses the "Start over" button in response to an
  // error.
  onReset?: () => void,
  // Called after a user has been successfully signed in.
  onSuccess?: () => void,
  // Called to actually sign the user in with an email and sign-in link.
  onSignIn: () => Promise<void>,
  // Called to verify the user's email.
  onVerifyEmail: () => Promise<void>,
  signInLink: string,
};

export const PureEmailVerificationResult = (props: Props) => {
  const {
    activeUserId,
    email,
    logger,
    onReset,
    onSuccess,
    onSignIn,
    onVerifyEmail,
    signInLink,
  } = props;

  const [state, setState] = useState(State.INITIALIZING);

  useEffect(() => {
    switch (state) {
      case State.SUCCESS:
        onSuccess && onSuccess();
        break;
    }
  }, [state]);

  useEffect(() => {
    if (state === State.SIGNING_IN || state === State.SUCCESS) {
      return;
    }
    if (!email) {
      setState(State.ERROR_NO_EMAIL);
      return;
    }
    if (state === State.INITIALIZING && !signInLink) {
      setState(State.PENDING_SIGN_IN_LINK);
      return;
    }
    setState(State.SIGNING_IN);

    logger?.logEvent(AnalyticsEvent.REGISTRATION_EMAIL_SIGNING_IN);
    onSignIn()
        .then(onVerifyEmail)
        .then(() => {
          logger?.logEvent(AnalyticsEvent.REGISTRATION_EMAIL_VERIFIED);
          setState(State.SUCCESS);
        })
        .catch(e => {
          logger?.logEvent(AnalyticsEvent.REGISTRATION_EMAIL_FAILURE, {error: e.toString()});
          console.warn('sign in with email link error', email, e);
          setState(State.ERROR_SIGNING_IN);
        });
  }, [email, activeUserId, onSignIn, onVerifyEmail, signInLink]);

  const handleReset = () => {
    logger && logger.logEvent(AnalyticsEvent.REGISTRATION_RESET);
    onReset && onReset();
  }

  const palette = usePalette();
  const text = useTextStyles();
  const containerText = {
    ...text.body1,
    marginBottom: 20,
  };

  return (
      <View style={{
        alignItems: 'center',
        maxWidth: 500,
      }}>
        {state === State.PENDING_SIGN_IN_LINK && (
            <View style={{alignItems: 'center', maxWidth: 300}}>
              <MaterialCommunityIcon
                  color={palette.grey.main}
                  name={'email'}
                  size={100}
              />
              <View style={{width: '100%'}}>
                <Text style={containerText}>
                  Email successfully sent to <Text
                    style={{fontWeight: 'bold'}}>{email}</Text>.
                </Text>
                <Text style={containerText}>
                  Open the email and tap the "sign in" button to proceed.
                </Text>
                <View style={{marginTop: StyleConstants.SPACING * 2}}>
                  <Text style={[text.body2, {color: palette.grey.main}]}>
                    Don't see the email yet?{'\n\n'}
                    1. Double check the email address above.{'\n'}
                    2. Search your inbox for "Heylo".{'\n'}
                    3. Give it a few minutes and try again.
                  </Text>
                </View>
              </View>
              <View style={{marginTop: StyleConstants.SPACING * 3}}>
                <HeyloButton
                    label={'reset'}
                    mode={'outlined'}
                    onPress={handleReset}
                />
              </View>
              <Text style={[text.body2, {
                color: palette.grey.main,
                marginTop: StyleConstants.SPACING / 2,
              }]}>
                Stuck? Try starting over
              </Text>
            </View>
        )}

        {(state === State.ERROR_NO_EMAIL || state === State.ERROR_SIGNING_IN) && (
            <View style={{alignItems: 'center', maxWidth: 300}}>
              <Text style={[containerText, {textAlign: 'center'}]}>
                Something went wrong. This can happen if your sign-in email expired, has already been used, or if a newer one exists.
              </Text>
              <Text style={[containerText, {textAlign: 'center'}]}>
                Try starting over and be careful to use only the most recent sign-in email.
              </Text>
              <HeyloButton
                  label={'Start over'}
                  onPress={handleReset}
              />
            </View>
        )}

        {(state === State.SIGNING_IN || state === State.SUCCESS) && (
            <>
              <ActivityIndicator size={'large'}/>
              <Text style={[containerText, {
                marginTop: StyleConstants.SPACING,
                textAlign: 'center',
              }]}>
                {state === State.SUCCESS ? 'Loading your data...' : 'Completing email verification...\nHang tight!'}
              </Text>
            </>
        )}

      </View>
  );
};
