import {Marketplace} from '../../types/firebase-types';

export const resolveMarketplaceFullName = (marketplace: Marketplace) => {
  const {iconEmoji, nameFull} = marketplace;
  return [iconEmoji, nameFull].join(' ');
};

export const resolveMarketplaceCasualName = (marketplace: Marketplace) => {
  const {iconEmoji, nameFull, nameAbbrev} = marketplace;
  return [iconEmoji, nameAbbrev || nameFull].join(' ');
};

export const resolveUriForMarketplace = (marketplace: Marketplace) => {
  const {marketplaceId, vanityUri} = marketplace;
  return `/m/${vanityUri || marketplaceId}`;
}

export const resolveUriForGroup = (communityId: string) => {
  return `/g/${communityId}`;
}
