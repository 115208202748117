import {
  Community,
  CommunityMember,
  CommunityProspect,
  CommunityScopedDeletion,
} from '@heylo/shared/src/types/firebase-types';
import {
  CommunityCreated,
  CommunityProspectsLoaded,
  CommunityUpdated,
  UserJoinedCommunity,
  UserLeftCommunity,
} from '@heylo/shared/src/features/communities/Slice';
import {
  FirebaseDatabase,
  FirebaseServerTimestamp,
  ThenableReference,
} from '@heylo/firebase-database';
import {Listeners} from '@heylo/shared/src/features/firebase/Listeners';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {COMMUNITY_SETTINGS_POLICY_EVERYONE} from '@heylo/shared/src/features/communities/Constants';
import {CommunityProspectStatus} from '@heylo/shared/src/types/CommunityProspect';
import {listenForUserProfile} from '@heylo/shared/src/features/userProfiles/Firebase';
import {IncrementUserInteraction} from '@heylo/shared/src/features/userEvents/Actions';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {SelectMostRecentCommunityScopedDeletionId} from './Selectors';
import {CommunityScopedDeletionAction} from './Actions';
import {ACTION_DELETE_MESSAGE, ACTION_REMOVE_THREAD} from '../threads/Slice';
import {selectCanViewCommunityProspects} from '../communityMembers/Selectors';

export const AttachCommunityMetadataListener = (userId: string, communityId: string) => {
  return (dispatch: any) => {
    if (Listeners.COMMUNITIES[communityId])
      return;
    const ref = FirebaseDatabase().ref(`/communities/${communityId}`);
    Listeners.COMMUNITIES[communityId] = ref;
    console.count('AttachCommunityMetadataListener');
    ref.on('value',
        snapshot => {
          const community: Community = snapshot?.val();
          if (!community) {
            return;
          }
          dispatch(CommunityUpdated({communityId, community}));
        },
        (e: Error) => {
          console.log('Lost permissions to community', communityId);
          delete Listeners.COMMUNITIES[communityId];
        });
  };
};

export const FirebaseCommunityScopedDeletion = (communityId: string, deletion: CommunityScopedDeletion)
    : ThenableReference => {
  return FirebaseDatabase().ref(`/deletions/communityScoped/${communityId}`).push(deletion);
};

export const CommunityFirebase = {
  ProspectsListener: (communityId: string): ThunkReturnType<void> => (dispatch, getState) => {
    if (!communityId || Listeners.COMMUNITY_PROSPECTS[communityId]) {
      return;
    }
    const state = getState();
    if (!selectCanViewCommunityProspects(state)) {
      return;
    }
    const ref = FirebaseDatabase().ref(`/communityProspects/${communityId}`);
    Listeners.COMMUNITY_PROSPECTS[communityId] = ref;
    ref.orderByChild('status')
        .equalTo(CommunityProspectStatus.PENDING)
        .on('value',
            snapshot => {
              const prospects: { [userId: string]: CommunityProspect } = snapshot?.val() || {};
              dispatch(CommunityProspectsLoaded({communityId, prospects}));
              for (const userId of Object.keys(prospects)) {
                dispatch(listenForUserProfile(userId));
              }
            },
            (e: Error) => {
              console.log('lost connection to /communityProspects', communityId, e.message);
              delete Listeners.COMMUNITY_PROSPECTS[communityId];
            });
  },

  UpdateCommunityLinks: (communityId: string, links: any) => {
    if (communityId) {
      return FirebaseDatabase().ref(`/communities/${communityId}/settings`).update(links);
    }
    return Promise.resolve();
  },

  UpdateCommunityLogo: (communityId: string, logoUrl: string | null) => {
    if (communityId) {
      const updates: any = {};
      updates[`/communities/${communityId}/logo`] = logoUrl;
      updates[`/communityProfiles/${communityId}/communityLogo`] = logoUrl;
      return FirebaseDatabase().ref('').update(updates);
    }
    return Promise.resolve();
  },

  UpdateLastActiveTimestamp: (communityId: string, userId: string) => {
    if (communityId && userId) {
      return FirebaseDatabase()
          .ref(`/communityMembers/${communityId}/${userId}/lastActiveTimestamp`)
          .set(FirebaseServerTimestamp())
          .catch(e => {
            console.warn('UpdateLastActiveTimestamp error', e);
          });
    }
    return Promise.resolve();
  },
};

export const FirebaseCreateCommunity = (
    {
      buildVariant,
      communityId,
      name,
      userId,
    }: {
      buildVariant: string,
      communityId: string,
      name: string,
      userId: string,
    })
    : ThunkReturnType<Promise<void>> => dispatch => {
  return new Promise<void>((resolve, reject) => {
    const community: Community = {
      buildVariant,
      creationTimestamp: FirebaseServerTimestamp(),
      name,
      ownerId: userId,
      settings: {
        contentWritePolicy: COMMUNITY_SETTINGS_POLICY_EVERYONE,
        eventCreatePolicy: COMMUNITY_SETTINGS_POLICY_EVERYONE,
        inviteMembersPolicy: COMMUNITY_SETTINGS_POLICY_EVERYONE,
        eventDigestEmail: true,
      },
      source: 'app',
      type: 'consumer',
    };
    dispatch(IncrementUserInteraction(AnalyticsEvent.COMMUNITY_CREATED));
    return FirebaseDatabase().ref(`/communities/${communityId}`).update(community)
        .then(() => {
          dispatch(CommunityCreated({communityId, community}));
          resolve();
        })
        .catch((e: Error) => {
          console.warn('error updating /communities', communityId, community);
          reject(e);
        });
  });
};

export const FirebaseAddUserToCommunity = (communityId: string, userId: string, params: Pick<CommunityMember, 'isLead' | 'joinChannel'>)
    : ThunkReturnType<Promise<void>> => dispatch => {
  return new Promise<void>((resolve, reject) => {
    if (!communityId || !userId) {
      reject('cannot add to community; invalid params');
      return;
    }
    const member: CommunityMember = {
      ...params,
      joinTimestamp: FirebaseServerTimestamp(),
      profileUpdatedTimestamp: FirebaseServerTimestamp(),
    };
    dispatch(UserJoinedCommunity({communityId, userId, member}));
    Promise.all([
      FirebaseDatabase().ref(`/communityMembers/${communityId}/${userId}`).set(member),
      FirebaseDatabase().ref(`/userSettings/${userId}/communities/${communityId}`).set('true'),
    ])
        .then(() => {
          resolve();
        })
        .catch((e: Error) => {
          console.warn('error adding user to community', e.message);
          reject(e);
        });
  });
};

export const FirebaseLeaveCommunity = (communityId: string, userId: string): ThunkReturnType<void> => dispatch => {
  if (!userId || !communityId) {
    return Promise.reject('cannot leave due to invalid params');
  }
  dispatch(UserLeftCommunity(communityId));
  return FirebaseDatabase().ref(`/userSettings/${userId}/communities/${communityId}`).set(null);
};

export const FirebaseUpdateCommunity = (communityId: string, updates: any) => {
  if (communityId) {
    return FirebaseDatabase().ref(`/communities/${communityId}`).update(updates);
  }
  return Promise.resolve();
};


export const AttachCommunityScopedDeletionsListener = (communityId: string)
    : ThunkReturnType<void> => (dispatch, getState) => {
  if (Listeners.COMMUNITY_DELETIONS[communityId])
    return;
  const state = getState();
  const deletionId = SelectMostRecentCommunityScopedDeletionId(state) || '';
  const ref = FirebaseDatabase().ref(`/deletions/communityScoped/${communityId}`)
      .orderByKey()
      .startAt(deletionId);
  Listeners.COMMUNITY_DELETIONS[communityId] = ref;
  ref.on('child_added',
      snapshot => {
        const deletion: CommunityScopedDeletion = snapshot?.val();
        const deletionId = snapshot?.key || '';
        if (!deletion || !deletionId) {
          return;
        }
        const {messageId, threadId} = deletion;
        dispatch(CommunityScopedDeletionAction({
          communityId,
          deletionId,
          deletion,
        }));
        // TODO: handle the `CommunityScopedDeletionAction` action in the
        //  threads slice directly, instead of custom actions.
        if (threadId) {
          if (messageId) {
            dispatch(ACTION_DELETE_MESSAGE({threadId, messageId}));
          } else {
            dispatch(ACTION_REMOVE_THREAD({communityId, threadId}));
          }
        }
      },
      (e: Error) => {
        console.log('lost connection to community deletions', e.message);
        delete Listeners.COMMUNITY_DELETIONS[communityId];
      });
};
