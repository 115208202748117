import React from 'react';
import {StyleSheet, Text, TouchableWithoutFeedback, View} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import {Avatar} from './Avatar';
import {GreenBadge} from '@heylo/components/src/ui/badge/GreenBadge';
import {SelectUserFullName} from '@heylo/shared/src/features/userProfiles/Selectors';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

type OwnProps = {
  darkMode?: boolean,
  isNew?: boolean,
  onPress?: () => void,
  userId: string,
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const {userId} = ownProps;
  return {
    name: SelectUserFullName(state, userId),
  };
};
const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & OwnProps;

export const MemberListItemCompact = connector((props: Props) => {
  // console.count('MemberListItemCompact');
  const {
    darkMode,
    isNew,
    name,
    onPress,
    userId,
  } = props;

  const text = useTextStyles();

  return (
      <View style={styles.rowContainer}>
        <View style={styles.userContainer}>
          <TouchableWithoutFeedback onPress={onPress}>
            <View>
              <Avatar size={40} userId={userId}/>
            </View>
          </TouchableWithoutFeedback>
          <Text
              onPress={onPress}
              style={[text.body1, {
                color: darkMode ? 'white' : 'black',
                marginLeft: 8,
              }]}
          >
            {name}
          </Text>
        </View>
        {isNew && (
            <GreenBadge
                containerStyle={{
                  marginStart: 10,
                }}
                value={'New'}
            />
        )}
      </View>
  );
});

const styles = StyleSheet.create({
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  userContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    marginVertical: 5,
  },
});