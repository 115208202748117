import {assign, createMachine} from 'xstate';
import moment from 'moment-timezone';
import {Event} from '@heylo/shared/src/types/firebase-types';
import _ from 'lodash';

const parseMomentToObj = (moment: moment.Moment) => {
  return {
    minutes: moment.minutes(),
    hour: moment.hour(),
    day: moment.date(),
    month: moment.month(),
    year: moment.year(),
  }
};

export interface EditEventContext {
  donationsEnabled: boolean,
  donationsSuggestedAmounts: number[],
  eventId: string,
  eventMoment: moment.Moment,
  year: number,
  month: number,
  day: number,
  hour: number,
  minutes: number,
  timezone: string;
}

const initialContext: EditEventContext = {
  donationsEnabled: false,
  donationsSuggestedAmounts: [500, 1000, 2000],
  eventId: '',
  eventMoment: moment(),
  year: 0,
  month: 0,
  day: 0,
  hour: 0,
  minutes: 0,
  timezone: '',
};

type EditEventEvent =
    | { type: 'START_EDITING', event: Event }
    | { type: 'CHANGE_DATE_TIME', moment: moment.Moment }
    | { type: 'CHANGE_DONATION_SUGGESTED_AMOUNT', index: number, amountInDollarsString: string }
    | { type: 'CHANGE_TIMEZONE', timezone: string }
    | { type: 'TOGGLE_DONATIONS' }
    // TODO: replace this with separate "SAVE" and "CANCEL" events, once we
    // hookup this machine to firebase mutators
    | { type: 'RESET' }

type EditEventState =
    | {
  value: 'inactive';
  context: EditEventContext;
}
    | {
  value: 'editing';
  context: EditEventContext;
}

export const EditEventMachine = createMachine<EditEventContext, EditEventEvent, EditEventState>({
  context: initialContext,
  id: 'editEvent',
  initial: 'inactive',
  states: {
    inactive: {
      on: {
        START_EDITING: {
          actions: 'initEvent',
          target: 'editing',
        },
      },
    },
    editing: {
      on: {
        CHANGE_DATE_TIME: {actions: 'changeDateTime'},
        CHANGE_DONATION_SUGGESTED_AMOUNT: {actions: 'changeDonationSuggestedAmount'},
        CHANGE_TIMEZONE: {actions: 'changeTimezone'},
        RESET: {target: 'inactive', actions: 'reset'},
        TOGGLE_DONATIONS: {actions: 'toggleDonations'},
      },
    },

  },
}, {
  actions: {
    changeDateTime: assign((ctx, event: EditEventEvent) => {
      if (event.type !== 'CHANGE_DATE_TIME') {
        return {};
      }
      const {moment} = event;
      return parseMomentToObj(moment);
    }),

    changeDonationSuggestedAmount: assign((ctx, event) => {
      if (event.type !== 'CHANGE_DONATION_SUGGESTED_AMOUNT') {
        return {};
      }
      let {amountInDollarsString, index} = event;
      if (amountInDollarsString.startsWith('$')) {
        amountInDollarsString = amountInDollarsString.slice(1);
      }
      const amountInDollarsNumber = Number(amountInDollarsString) || 0;
      const newDonationAmounts = [...ctx.donationsSuggestedAmounts];
      newDonationAmounts[index] = amountInDollarsNumber * 100;
      return {
        donationsSuggestedAmounts: newDonationAmounts,
      }
    }),

    changeTimezone: assign((ctx, event: EditEventEvent) => {
      if (event.type !== 'CHANGE_TIMEZONE') {
        return {};
      }
      return {timezone: event.timezone};
    }),

    initEvent: assign((ctx, e: EditEventEvent) => {
      if (e.type !== 'START_EDITING') {
        return {};
      }
      const {event} = e;
      let {
        donationsEnabled,
        donationsSuggestedAmountCents,
        timestamp,
        timezone,
      } = event;
      donationsEnabled = donationsEnabled || false;
      const suggestions = Object.values(donationsSuggestedAmountCents || {});
      const donationsSuggestedAmounts = _.sortBy(
          suggestions.length ? suggestions : [500, 1000, 2000],
          v => v);
      timestamp = timestamp || 0;
      timezone = timezone || moment.tz.guess();

      let eventMoment = timestamp
          ? moment.tz(timestamp, timezone)
          : moment().add(1, 'day').hours(18).minutes(0).seconds(0);

      return {
        ...parseMomentToObj(eventMoment),
        donationsEnabled,
        donationsSuggestedAmounts,
        timezone,
      };
    }),

    reset: assign(() => initialContext),

    toggleDonations: assign((ctx) => {
      return {donationsEnabled: !ctx.donationsEnabled}
    }),
  },
});
