import React from 'react';
import {useHistory} from 'react-router-dom';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {View} from 'react-native';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {JoinRequestSuccessfullySent} from '@heylo/components/src/features/marketplaces/JoinRequestSuccessfullySent';
import {resolveUriForMarketplace} from '@heylo/shared/src/features/marketplaces/Util';
import {selectActiveMarketplace} from '@heylo/shared/src/features/marketplaces/Selectors';

export const JoinGroupRequestSentScreen = () => {
  const history = useHistory();

  const {
    activeMarketplace,
  } = useSelector((state: RootState) => ({
    activeMarketplace: selectActiveMarketplace(state),
  }), shallowEqual);

  const onPress = () => {
    history.replace(`${resolveUriForMarketplace(activeMarketplace)}`);
  };

  return (
      <View style={{
        alignItems: 'center',
        alignSelf: 'center',
        flex: 1,
        justifyContent: 'center',
        maxWidth: 500,
        padding: StyleConstants.SPACING,
      }}>
        <JoinRequestSuccessfullySent onPress={onPress}/>
      </View>
  );
};