import React from 'react';
import {Thread} from '@heylo/shared/src/types/firebase-types';
import {threadNewMessageNotificationsEnabledSelector} from '@heylo/shared/src/features/notifications/Selectors';
import {
  AllThreadLastActivityTimestampsSelector,
  selectThreadNumUnreadMessages,
} from '@heylo/shared/src/features/threads/SimpleSelectors';
import {connect, ConnectedProps} from 'react-redux';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {
  THREAD_TYPE_COMMUNITY,
  THREAD_TYPE_GROUP,
  THREAD_TYPE_SUPPORT,
} from '@heylo/shared/src/types/ThreadTypes';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {PrivateTopicLabel} from '@heylo/components/src/features/topics/PrivateTopicLabel';
import {ThreadImage} from '../threads/ThreadImage';
import {GreenBadge} from '../ui/GreenBadge';
import NotificationsOff from '@material-ui/icons/NotificationsOff';
import useTheme from '@material-ui/core/styles/useTheme';
import {LastActiveMessage} from './LastActiveMessage';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {useNavigationUtils} from '../navigation/useNavigationUtils';
import {useHistory} from 'react-router-dom';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {Text} from 'react-native';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';

type OwnProps = {
  isActive?: boolean,
  isNew?: boolean,
  joined: boolean,
  thread: Thread,
};

const mapState = (state: RootState, ownProps: OwnProps) => {
  const {joined, thread} = ownProps;
  const {threadId = ''} = thread;
  return {
    newMessageNotifications: threadNewMessageNotificationsEnabledSelector(state, threadId),
    numUnreadMessages: joined ? (selectThreadNumUnreadMessages(state)[threadId] || 0) : 0,
    threadId,
    threadLastActivityTimestamp: AllThreadLastActivityTimestampsSelector(state)[threadId] || 0,
  };
};
const connector = connect(mapState);
type Props = ConnectedProps<typeof connector> & OwnProps;

export const TopicCard = connector((props: Props) => {
  const {
    isActive,
    isNew,
    newMessageNotifications,
    numUnreadMessages,
    thread,
    threadId,
    threadLastActivityTimestamp,
  } = props;

  const history = useHistory();
  const logger = useLoggingService();
  const nav = useNavigationUtils();

  const {
    name = 'Unnamed topic',
    notes,
    threadType,
  } = thread;

  const openThread = () => {
    logger.logEvent(AnalyticsEvent.INTERESTED_CONTENT_TAPPED, {
      contentType: (threadType === THREAD_TYPE_SUPPORT) ? 'support'
          : (threadType === THREAD_TYPE_COMMUNITY) ? 'topic'
              : '',
    });
    history.push(nav.resolveUriForTopic(threadId));
  };

  const fontWeightOverride = 'normal';
  const showNotificationsMutedBadge = !newMessageNotifications;

  const renderBadges = () => {
    if (isNew) {
      return <GreenBadge value={'New'}/>;
    } else if (showNotificationsMutedBadge || numUnreadMessages) {
      return (
          <div style={{
            alignItems: 'center',
            flexDirection: 'row',
            height: '7%',
          }}>
            {showNotificationsMutedBadge && (
                <NotificationsOff color={'disabled'}/>
            )}
            {numUnreadMessages > 0 && (
                <GreenBadge
                    value={(numUnreadMessages < 100) ? numUnreadMessages : '99+'}
                />
            )}
          </div>
      );
    } else if (threadLastActivityTimestamp > 0) {
      return (
          <div>
            <LastActiveMessage timestamp={threadLastActivityTimestamp}/>
          </div>
      );
    }
    return null;
  }

  const palette = usePalette();
  const text = useTextStyles();
  const theme = useTheme();
  const imageSize = StyleConstants.SPACING * 3;
  return (
      <>
        {/* TODO: support right click menu */}
        <div className={'flexrow'}
             onClick={openThread}
             style={{
               alignItems: 'center',
               backgroundColor: isActive ? palette.secondary.main : 'white',
               cursor: 'pointer',
               paddingBottom: StyleConstants.SPACING / 2,
               paddingTop: StyleConstants.SPACING / 2,
               paddingLeft: StyleConstants.SPACING,
               paddingRight: StyleConstants.SPACING,
             }}>
          <div style={{
            height: imageSize,
            minWidth: imageSize,
            width: imageSize,
          }}>
            <ThreadImage
                hasUnreadMessages={!!numUnreadMessages}
                thread={thread}
            />
          </div>

          <div className={'flexcolumn'} style={{
            flexGrow: 1,
            marginLeft: StyleConstants.SPACING,
            minWidth: 0,
            textOverflow: 'ellipsis',
          }}>
            <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={[text.body1, {
                  color: isActive ? 'white' : 'black',
                  fontWeight: isActive ? 'bold' : fontWeightOverride,
                }]}>
              {name}
            </Text>

            {!!notes && (
                <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={[text.body1, {color: isActive ? 'white' : palette.grey.main}]}
                >
                  {notes}
                </Text>
            )}

            {threadType === THREAD_TYPE_GROUP && (
                <PrivateTopicLabel isActive={isActive} threadType={threadType}/>
            )}
          </div>

          {!isActive ? (
              <div>
                {renderBadges()}
              </div>
          ) : null}
        </div>
      </>
  );
});