import React from 'react';
import {Platform} from 'react-native';
import {Card} from 'react-native-paper';
import {HeyloTitle} from '../../ui/text/HeyloTitle';
import {HeyloParagraph} from '../../ui/text/HeyloParagraph';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {HeyloContainer} from '../../ui/container/HeyloContainer';

export const RequestNewLocationCard = ({onPress}: {onPress: ()=>void}) => {

  const palette = usePalette();
  const text = useTextStyles();

  return (
      <HeyloContainer>
        <Card
            onPress={onPress}
            style={{
              alignSelf: 'center',
              backgroundColor: palette.primary.lightest,
              marginTop: 30,
              width: '80%',
            }}
        >
          <Card.Content>
            <HeyloTitle style={{textAlign: 'center'}}>
              Don't see your school or city?
            </HeyloTitle>
            <HeyloParagraph style={{...text.subtitle1, textAlign: 'center'}}>
              {Platform.OS === 'web' ? 'Email us at support@heylo.co to let us know' : 'Let us know'}
            </HeyloParagraph>
          </Card.Content>
        </Card>
      </HeyloContainer>
  );
}