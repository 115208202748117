import {
  StripeCard,
  StripeExternalAccount,
  StripePaymentMethod,
} from '@heylo/shared/src/types/firebase-types';

const normalizeCardBrand = (brand?: string) => {
  switch (brand) {
    case 'amex':
      return 'American Express';
    case 'diners':
      return 'Diners Club';
    case 'discover':
      return 'Discover';
    case 'jcb':
      return 'JCB';
    case 'mastercard':
    case 'MasterCard':
      return 'Mastercard';
    case 'unionpay':
      return 'UnionPay';
    case 'visa':
      return 'Visa';
    case 'unknown':
      return 'Card';
  }
  return brand ?? 'Unknown card';
};


export const NormalizeStripeCard = (card: StripeCard): StripeCard => {
  const normalized: StripeCard = Object.assign({}, card);
  normalized.brand = normalizeCardBrand(card.brand);
  return normalized;
};

export const ResolveStripeCardFromPaymentMethod = (paymentMethod?: StripePaymentMethod): StripeCard | null => {
  if (!paymentMethod) {
    return null;
  }
  const {card, type} = paymentMethod;
  if (type !== 'card') {
    return null;
  }
  if (card) {
    return NormalizeStripeCard(card);
  }
  // TODO: remove after all paymentMethods have been rewritten in Firebase RTDB
  const {expiresYear = 0, expiresMonth = 0, cardBrand = '', last4 = ''} = paymentMethod;
  return NormalizeStripeCard({
    brand: cardBrand,
    currency: 'usd',
    expiresMonth,
    expiresYear,
    last4,
  });
};

export const ResolveStripeCardFromExternalAccount = (externalAccount?: StripeExternalAccount | null): StripeCard | null => {
  if (!externalAccount) {
    return null;
  }
  const {card, type} = externalAccount;
  if (type !== 'card') {
    return null;
  }
  if (card) {
    return NormalizeStripeCard(card);
  }
  // TODO: remove after all paymentMethods have been rewritten in Firebase RTDB
  const {currency = '', expiresYear = 0, expiresMonth = 0, cardBrand = '', last4 = ''} = externalAccount;
  return NormalizeStripeCard({
    brand: cardBrand,
    currency,
    expiresMonth,
    expiresYear,
    last4,
  });
};