import {
  StripeAccount,
  StripeExternalAccount,
} from '@heylo/shared/src/types/firebase-types';

export enum StripeAccountStatus {
  DOES_NOT_EXIST,
  // TODO: rename to NEEDS_ATTENTION or ACTION_REQUIRED
  PAYMENTS_DISABLED,
  // TODO: rename to PENDING_VERIFICATION
  PAYMENTS_PENDING,
  PAYMENTS_ENABLED,
  ERROR,
}

export const ResolveDefaultExternalAccount = (account: StripeAccount | null): StripeExternalAccount | null => {
  for (const ea of Object.values(account?.externalAccounts ?? {})) {
    if (ea.isDefault) {
      return ea;
    }
  }
  return null;
};

export const ResolveStripeAccountStatus = (account?: StripeAccount | null): StripeAccountStatus => {
  if (!account) {
    return StripeAccountStatus.DOES_NOT_EXIST;
  }

  switch (account.capabilities?.cardPayments) {
    case 'active':
      return StripeAccountStatus.PAYMENTS_ENABLED;
    case 'pending':
      return StripeAccountStatus.PAYMENTS_PENDING;
    default:
      return StripeAccountStatus.PAYMENTS_DISABLED;
  }
};