import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {HelperText} from 'react-native-paper';
import {HeyloTextInput} from '../../ui/input/HeyloTextInput';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectDraftCommunityProfile} from '@heylo/shared/src/features/communityCreation/Selectors';
import {
  DraftCommunityReset,
  DraftCommunityUpdated,
} from '@heylo/shared/src/features/communityCreation/Slice';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';

export enum NewCommunityNameFormState {
  IDLE,
  VALID,
  SUBMIT_PRESSED,
  SUBMIT_SUCCESS,
  SUBMIT_ERROR,
  RESET
}


type Props = {
  state: NewCommunityNameFormState,
  setState: Dispatch<SetStateAction<NewCommunityNameFormState>>
};

export const NewCommunityNameForm = (props: Props) => {
  const {state, setState} = props;
  const dispatch = useDispatch();

  const {communityProfile} = useSelector((state: RootState) => ({
    communityProfile: selectDraftCommunityProfile(state),
  }), shallowEqual);

  const [communityName, setCommunityName] = useState('');

  const {communityName: initialCommunityName = ''} = communityProfile;
  useEffect(() => {
    setCommunityName(initialCommunityName);
  }, [initialCommunityName]);


  useEffect(() => {
    switch (state) {
      case  NewCommunityNameFormState.SUBMIT_PRESSED:
        dispatch(DraftCommunityUpdated({communityName}));
        setState(NewCommunityNameFormState.IDLE);
        break;
      case NewCommunityNameFormState.RESET:
        dispatch(DraftCommunityReset());
        setCommunityName('');

    }
  }, [state]);

  const logger = useLoggingService();

  useEffect(() => {
    const isValid = communityName.length > 1;
    if (state === NewCommunityNameFormState.SUBMIT_PRESSED) {
      if (isValid) {
        dispatch(DraftCommunityUpdated({communityName}));
        logger.logEvent(AnalyticsEvent.NEW_GROUP_NAME_SUBMIT, {name: communityName});
        setState(NewCommunityNameFormState.SUBMIT_SUCCESS);
      } else {
        logger.logEvent(AnalyticsEvent.SUBMIT_ERROR);
        setState(NewCommunityNameFormState.SUBMIT_ERROR);
      }
    } else if (isValid) {
      setState(NewCommunityNameFormState.VALID);
    } else {
      setState(NewCommunityNameFormState.IDLE);
    }
  }, [state, communityName]);

  return (
      <View style={styles.container}>
        <View style={{marginTop: 20, width: '100%'}}>
          <HeyloTextInput
              autoCapitalize={'words'}
              autoCorrect={false}
              autoFocus={true}
              dense
              error={state === NewCommunityNameFormState.SUBMIT_ERROR}
              label={'Enter your group name'}
              multiline={false}
              onChangeText={setCommunityName}
              onSubmitEditing={() => setState(NewCommunityNameFormState.SUBMIT_PRESSED)}
              returnKeyType={'done'}
              style={{
                marginTop: '5%',
              }}
              textContentType={'organizationName'}
              underlineColorAndroid={'transparent'}
              value={communityName}
          />
          {state === NewCommunityNameFormState.SUBMIT_ERROR ? (
              <HelperText type={'error'}>
                Group name too short; must be longer than 3 characters
              </HelperText>
          ) : (
              <HelperText type={'info'}>
                Don't worry, you can always change it later!
              </HelperText>
          )}
        </View>
      </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    paddingHorizontal: 20,
    width: '100%',
  },
});