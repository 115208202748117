import React, {useState} from 'react';
import {NextButton, ProgressHeader} from '../navigation/ProgressHeader';
import {
  EmailVerificationStartContext,
  EmailVerificationStartState,
} from '@heylo/components/src/features/marketplaces/EmailVerificationStartContext';
import {View} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {EmailVerificationForm} from '@heylo/components/src/features/auth';
import {EmailVerificationContext} from '@heylo/shared/src/features/auth/Slice';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {useHistory} from 'react-router-dom';

type Props = {
  context: EmailVerificationContext,
  percent: string,
};

export const WebEmailVerificationForm = (props: Props) => {
  const {context, percent} = props;

  const history = useHistory();

  const {
    activeUserId,
  } = useSelector((state: RootState) => ({
    activeUserId: selectActiveUserId(state),
  }), shallowEqual);

  const onNextPress = () => {
    setState(EmailVerificationStartState.SUBMIT_PRESSED);
  }

  const onBackPress = () => {
    history.goBack();
  }

  const onEmailVerification = () => {
    history.replace('/newGroup/details');
  };

  const [state, setState] = useState(EmailVerificationStartState.IDLE);


  const isBusy = state === EmailVerificationStartState.SUBMIT_PRESSED
      || state === EmailVerificationStartState.SENDING_EMAIL_LINK;

  return (
      <ProgressHeader
          nextButton={state === EmailVerificationStartState.SUCCESS
              ? NextButton.HIDDEN
              : isBusy
                  ? NextButton.NEXT_BUSY
                  : NextButton.NEXT
          }
          onBackPress={onBackPress}
          onNextPress={onNextPress}
          percent={percent}
          title={activeUserId ? 'Confirm your email' : 'Create your account'}
      >
        <View style={{padding: StyleConstants.SPACING}}>
          <EmailVerificationStartContext.Provider
              value={[state, setState]}>
            <EmailVerificationForm
                context={context}
                onSuccess={onEmailVerification}
            />
          </EmailVerificationStartContext.Provider>
        </View>
      </ProgressHeader>
  );
};