import React, {useEffect, useState} from 'react';
import {
  HeyloButton,
  HeyloDialog,
  HeyloText,
  HeyloTextInput,
} from 'lib/materialUi';
import {Event} from '@heylo/shared/src/types/firebase-types';
import {DateTimePicker} from "@material-ui/pickers";
import moment from 'moment-timezone';
import {TimezonePicker} from 'features/event/TimezonePicker';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import LocationIcon from '@material-ui/icons/Room';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {v4 as uuidv4} from 'uuid';
import Alert from '@material-ui/lab/Alert';
import useTheme from '@material-ui/core/styles/useTheme';
import {
  FirebaseCreateEvent,
  FirebaseUpdateEvent,
} from '@heylo/shared/src/features/events/Firebase';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Redirect, useHistory, useRouteMatch} from 'react-router-dom';
import {WebAppDispatch} from 'store';
import {Firebase} from 'features/firebase';
import isURL from 'validator/lib/isURL';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {EditEventMachine} from '@heylo/shared/src/features/events/EditEventMachine';
import {useMachine} from '@xstate/react';
import Switch from '@material-ui/core/Switch';
import {Divider} from '../ui/Divider';
import {GreenBadge} from '../ui/GreenBadge';
import Collapse from '@material-ui/core/Collapse';
import {EditableImage} from '@heylo/components/src/ui/image/EditableImage';
import {useNavigationUtils} from '../navigation/useNavigationUtils';
import {HeyloContainer} from '@heylo/components/src/ui/container/HeyloContainer';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {useActiveEventState} from './useActiveEventState';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {selectActiveUserHasEventWritePermissions} from '@heylo/shared/src/features/events/Selectors';
import {selectLinkedStripeAccount} from '@heylo/shared/src/features/stripe/Selectors';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

const useStyles = makeStyles(theme => ({
  containerButton: {
    padding: '0 2%',
    width: '40%',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
}));


export enum WriteEventDialogMode {
  INACTIVE,
  CREATE,
  DUPLICATE,
  EDIT,
}

export const EditEventScreen = () => {
  const {event: initialEvent, eventId} = useActiveEventState();

  const isAdd = useRouteMatch('/event/add');
  const isDup = useRouteMatch('/event/duplicate');
  const isEdit = useRouteMatch('/event/edit');
  const mode = isAdd ? WriteEventDialogMode.CREATE
      : isDup ? WriteEventDialogMode.DUPLICATE
          : isEdit ? WriteEventDialogMode.EDIT
              : WriteEventDialogMode.INACTIVE;

  const {
    communityId,
    editEventPermissions,
    hasLinkedStripeAccount,
  } = useSelector((state: RootState) => ({
    communityId: SelectActiveCommunityId(state),
    editEventPermissions: selectActiveUserHasEventWritePermissions(state),
    hasLinkedStripeAccount: !!selectLinkedStripeAccount(state),
  }), shallowEqual);

  const dispatch: WebAppDispatch = useDispatch();
  const history = useHistory();
  const logger = useLoggingService();

  const [name, setName] = useState(initialEvent.name ?? '');
  const [description, setDescription] = useState(initialEvent.notes ?? '');
  const [location, setLocation] = useState(initialEvent.location ?? '');
  const [recurrenceInterval, setRecurrenceInterval] = useState(initialEvent.recurrenceInterval ?? 0);
  const [videoLink, setVideoLink] = useState(initialEvent.video ?? '');

  const [state, send] = useMachine(EditEventMachine);
  const {
    donationsEnabled,
    donationsSuggestedAmounts,
    year,
    month,
    day,
    hour,
    minutes,
    timezone,
  } = state.context;

  useEffect(() => {
    send({type: 'START_EDITING', event: initialEvent ?? {}})
  }, [initialEvent, send]);

  const onChangeDateTime = (moment: null | moment.Moment) => {
    if (!moment) {
      return;
    }
    send({type: 'CHANGE_DATE_TIME', moment});
  };

  const onChangeTimezone = (timezone: string) => {
    send({type: 'CHANGE_TIMEZONE', timezone});
  }

  const onToggleDonations = () => {
    send({type: 'TOGGLE_DONATIONS'});
  };

  const nameFieldRef = React.createRef<HTMLInputElement>();
  const videoFieldRef = React.createRef<HTMLInputElement>();
  const [nameError, setNameError] = useState(false);
  const [videoError, setVideoError] = useState(false);

  const [photoUri, setPhotoUri] = useState(initialEvent.image ?? '');

  const [busy, setBusy] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleNewPhoto = async (downloadUri: Promise<string>) => {
    const uri = await downloadUri;
    setPhotoUri(uri);
  };

  const saveEvent = (notify: boolean) => {
    setBusy(true);
    setShowNotifyDialog(false);
    if (!validateForm()) {
      setFormHasError(true);
      setBusy(false);
      return;
    }

    const donationAmounts: { [key: number]: number } = {};
    if (donationsEnabled) {
      donationsSuggestedAmounts.map((value, index) => {
        donationAmounts[index] = value;
      });
    }

    const newEvent: Event = {
      donationsEnabled,
      donationsSuggestedAmountCents: donationAmounts,
      image: photoUri,
      location,
      name,
      notes: description,
      timestamp: moment.tz({
        year,
        month,
        day,
        hour,
        minutes,
      }, timezone).valueOf(),
      timezone,
      video: videoLink,
    };
    if (recurrenceInterval) {
      newEvent.recurrenceInterval = recurrenceInterval;
      newEvent.recurrenceUnits = 'weeks';
    }
    if (notify) {
      newEvent.sendNotificationTimestamp = Firebase.serverTimestamp();
    }

    if (mode === WriteEventDialogMode.CREATE || mode === WriteEventDialogMode.DUPLICATE) {
      const newEventId = uuidv4();
      dispatch(FirebaseCreateEvent(communityId, newEventId, newEvent))
          .then(() => {
            setSuccess(true);
            logger.logEvent(AnalyticsEvent.EVENTS_CREATE);
            setTimeout(() => {
              history.push(nav.resolveUriForEventDetails(newEventId));
            }, 1500);
          });
    } else if (mode === WriteEventDialogMode.EDIT) {
      dispatch(FirebaseUpdateEvent(communityId, eventId, newEvent))
          .then(() => {
            setSuccess(true);
            logger.logEvent(AnalyticsEvent.EVENTS_UPDATE);
            setTimeout(() => {
              history.push(nav.resolveUriForEventDetails(eventId));
            }, 1500);
          });
    } else {
      setFormHasError(true);
      console.error('Something went wrong. Invalid mode: ', mode);
    }
    setBusy(false);
  };


  const [showNotifyDialog, setShowNotifyDialog] = useState(false);
  const [formHasError, setFormHasError] = useState(false);

  const validateForm = (): boolean => {
    setNameError(false);
    setVideoError(false);
    setFormHasError(false);
    let hasError = false;
    if (!name.trim()) {
      hasError = true;
      setNameError(true);
      if (nameFieldRef.current) {
        nameFieldRef.current.focus();
      }
    }
    if (videoLink && !isURL(videoLink, {require_protocol: false})) {
      hasError = true;
      setVideoError(true);
      if (videoFieldRef.current) {
        videoFieldRef.current.focus();
      }
    }
    if (hasError) {
      setFormHasError(true);
      return false;
    }
    return true;
  };
  const nav = useNavigationUtils();
  // history.push(nav.resolveUriForEventSummary());

  const handleSaveClick = () => {
    if (validateForm()) {
      setFormHasError(false);
      setShowNotifyDialog(true)
    }
  };

  const theme = useTheme();
  const classes = useStyles();

  if (!editEventPermissions) {
    return <Redirect to={nav.resolveUriForEventDetails()}/>
  }
  return (
      <HeyloContainer maxWidth={'xs'} style={{padding: StyleConstants.SPACING}}>
        <HeyloDialog
            actions={[
              {label: 'Cancel', onClick: () => setShowNotifyDialog(false)},
              {label: 'Silent', onClick: () => saveEvent(false)},
              {label: 'Notify', onClick: () => saveEvent(true)},
            ]}
            maxWidth={'xs'}
            onClose={() => setShowNotifyDialog(false)}
            open={showNotifyDialog}
            title={'Notify your group?'}
        >
          <HeyloText variant={'body1'}>
            You can have your group members notified immediately about changes to events, or you can make changes silently.
          </HeyloText>
        </HeyloDialog>

        <form noValidate>
          <Grid container spacing={1}
                style={{margin: 0, padding: 0, maxWidth: '100%'}}>
            <Grid item xs={12}>
              <EditableImage
                  aspectRatio={photoUri ? 1.5 : 3}
                  alt={'Event banner'}
                  callToAction={photoUri ? 'Change event photo' : 'Add event photo'}
                  cropShape={'square'}
                  imageUri={photoUri}
                  onChange={handleNewPhoto}
                  storagePath={`communities/${communityId}/events`}
              />
            </Grid>
            <Grid item xs={12}>
              <HeyloTextInput
                  error={nameError}
                  helperText={nameError ? 'Event name is required' : ''}
                  label={'Event name'}
                  margin={'dense'}
                  ref={nameFieldRef}
                  onChange={setName}
                  value={name}
              />
            </Grid>
            <Grid item xs={12}
                  style={{alignItems: 'center', display: 'flex'}}>
              <DateTimePicker
                  format={'ddd MMM D [@] h:mma'}
                  inputVariant="outlined"
                  label='Date & time'
                  margin={'dense'}
                  minutesStep={5}
                  onChange={onChangeDateTime}
                  value={moment.tz({
                    year,
                    month,
                    day,
                    hour,
                    minutes,
                  }, timezone)}
                  variant="inline"
              />

              <div style={{
                marginLeft: theme.spacing(1.5),
                position: 'relative',
                top: 3,
              }}>
                <TimezonePicker
                    onChange={onChangeTimezone}
                    value={timezone}
                />
              </div>
            </Grid>

            <Grid item xs={12}
                  style={{display: 'flex', justifyContent: 'space-between'}}>
              <FormControl variant={'outlined'} style={{minWidth: 120}}>
                <InputLabel id={'repeats-label'}>
                  Repeats
                </InputLabel>
                <Select
                    autoWidth
                    labelId='repeats-label'
                    label={'Repeats'}
                    margin={'dense'}
                    onChange={event => setRecurrenceInterval(event.target.value as number)}
                    renderValue={value => value === 0 ? 'Does not repeat' : value === 1 ? 'Every week' : 'Every other week'}
                    value={recurrenceInterval}
                    variant={'outlined'}
                >
                  <MenuItem value={0}>Does not repeat</MenuItem>
                  <MenuItem value={1}>Every week</MenuItem>
                  <MenuItem value={2}>Every other week</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <HeyloTextInput
                  error={videoError}
                  helperText={videoError ? 'Make sure this is a valid link' : ''}
                  IconStart={VideoCallIcon}
                  label={'Video link'}
                  margin={'dense'}
                  onChange={setVideoLink}
                  ref={videoFieldRef}
                  value={videoLink}
              />
            </Grid>

            <Grid item xs={12}>
              <HeyloTextInput
                  IconStart={LocationIcon}
                  label={'Location'}
                  margin={'dense'}
                  onChange={setLocation}
                  value={location}
              />
            </Grid>

            {/*<Grid item xs={12}>*/}
            {/*  <HeyloText variant={'subtitle2'}>*/}
            {/*    OPTIONAL*/}
            {/*  </HeyloText>*/}
            {/*</Grid>*/}

            <Grid item xs={12}>
              <HeyloTextInput
                  label={'Description'}
                  margin={'dense'}
                  multiline
                  onChange={setDescription}
                  rows={2}
                  rowsMax={100}
                  value={description}
              />
            </Grid>

            <Divider/>

            <Grid item xs={12}>
              <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}>
                <HeyloText variant={'subtitle1'}>
                  Accept donations?
                </HeyloText>
                <Switch
                    checked={donationsEnabled}
                    color={'primary'}
                    inputProps={{'aria-label': 'Accept donations switch'}}
                    name={'toggle-donations'}
                    onChange={onToggleDonations}
                />
                {!hasLinkedStripeAccount && !donationsEnabled && (
                    <GreenBadge value={'NEW'}/>
                )}
              </div>
              <Collapse in={donationsEnabled}>
                <div>
                  <HeyloText variant={'body1'}>
                    {hasLinkedStripeAccount && 'Donations are deposited into the group\'s linked bank account. '}
                    Choose your suggested donation amounts:
                  </HeyloText>
                  <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    marginTop: theme.spacing(1),
                  }}>
                    {donationsSuggestedAmounts.map((amountInCents, index) => {
                      return (
                          <HeyloTextInput
                              key={`donationAmount${index}`}
                              label={`Suggestion ${index + 1}`}
                              margin={'dense'}
                              maxLength={4}
                              multiline={false}
                              onChange={text => send({
                                type: 'CHANGE_DONATION_SUGGESTED_AMOUNT',
                                index,
                                amountInDollarsString: text,
                              })}
                              style={{
                                display: 'inline-block',
                                textAlign: 'center',
                                width: '25%',
                              }}
                              value={`$${Math.round(amountInCents / 100) || ''}`}
                          />
                      );
                    })}
                  </div>
                </div>
              </Collapse>
            </Grid>

            {formHasError && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    Please correct the event details above and try again.
                  </Alert>
                </Grid>
            )}

            {success && (
                <Grid item xs={12}>
                  <Alert severity="success">
                    Changes were made successfully!
                  </Alert>
                </Grid>
            )}

            <Divider/>

            <Grid item xs={12}>

              <div style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <div className={classes.containerButton}>
                  <HeyloButton
                      busy={busy}
                      color={'primary'}
                      onClick={handleSaveClick}
                      label={'Save'}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </HeyloContainer>
  );
};
