import React, {useContext, useEffect, useState} from 'react';
import {Platform, View} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import {GetBestSharedPhoto} from '@heylo/shared/src/features/photos/SharedPhotoUtil';
import {Message, SharedPhoto} from '@heylo/shared/src/types/firebase-types';
import {MESSAGE_BORDER_RADIUS, MESSAGE_MAX_WIDTH} from './Styles';
import {ChatScreenContext} from './ChatScreenContext';
import {IsPrivateThreadType} from '@heylo/shared/src/types/ThreadTypes';
import {
  ActiveCommunityPhotosSelector,
  ActivePrivateChatPhotosSelector,
} from '@heylo/shared/src/features/photos/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {StyleVars} from '../../styles/StyleVars';
import {HeyloImage} from '../../ui/image/HeyloImage';
import {selectLocalPhotoUris} from '@heylo/shared/src/features/ui/Selectors';

const BOTTOM_CAP_HEIGHT = 21;

type OwnProps = {
  isReplyImage?: boolean,
  message: Message,
};

const mapState = (state: RootState, ownProps: OwnProps) => {
  const {message} = ownProps;
  const {
    key: photoId = '',
    ownerId,
  } = message;

  return {
    communityTopicPhoto: ActiveCommunityPhotosSelector(state)[photoId],
    isUser: selectActiveUserId(state) === ownerId,
    localUri: selectLocalPhotoUris(state)[photoId],
    privateTopicPhoto: ActivePrivateChatPhotosSelector(state)[photoId],
  };
};
const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & OwnProps;

export const MessageImage = connector((props: Props) => {
  const {
    communityTopicPhoto,
    isReplyImage,
    isUser,
    localUri,
    message,
    privateTopicPhoto,
  } = props;

  const {threadType} = useContext(ChatScreenContext);

  const [photo, setPhoto] = useState<SharedPhoto | null>(null);
  useEffect(() => {
        setPhoto((IsPrivateThreadType(threadType)
            ? privateTopicPhoto
            : communityTopicPhoto) || null);
      },
      [privateTopicPhoto, communityTopicPhoto, threadType]);

  const [imageUrl, setImageUrl] = useState('');
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);

  useEffect(() => {
    const updateStateForImage = (url: string, width: number, height: number) => {
      setImageUrl(localUri || url || '');
      const messageMaxWidth = isReplyImage
          ? (Platform.OS === 'web' ? 150 : 100)
          : 250;
      if (width && height) {
        const maxWidth = Math.min(width / 2, messageMaxWidth);
        const maxHeight = height / 2;
        const aspectRatio = Math.min(maxWidth / width, maxHeight / height);
        setImageWidth(width * aspectRatio);
        setImageHeight(height * aspectRatio);
      } else {
        // This image does not have dimensions stored; just render as a
        // square.
        setImageWidth(messageMaxWidth);
        setImageHeight(messageMaxWidth);
      }
    };

    // Only set the imageUrl once during the lifetime of this component. For
    // an image uploaded by a user, this will typically mean the
    // localImageUri will be used the first time, and the remote URI will be
    // used all other times in the future.
    if (imageUrl) {
      return;
    }
    const {image = '', imageWidth = 0, imageHeight = 0} = message;
    const [url, width, height] = GetBestSharedPhoto(photo, MESSAGE_MAX_WIDTH);
    if (url) {
      updateStateForImage(url, width, height);
    } else {
      updateStateForImage(image, imageWidth, imageHeight);
    }
  }, [localUri, message, photo, threadType]);

  let loadingBackgroundColor = StyleVars.Colors.GreyLighter;
  if (isUser) {
    loadingBackgroundColor = StyleVars.Colors.PurpleLightest;
  }

  return (
      <View style={{
        height: isReplyImage ? imageHeight - BOTTOM_CAP_HEIGHT : 'auto',
        width: imageWidth,
      }}>
        {!!imageUrl && (
            <HeyloImage
                aspectRatio={imageWidth / imageHeight}
                resizeMode={'cover'}
                showLoadingIndicator={!isReplyImage}
                style={{
                  backgroundColor: loadingBackgroundColor,
                  borderRadius: MESSAGE_BORDER_RADIUS,
                }}
                uri={imageUrl}
            />
        )}
      </View>
  )
});
