import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {DeepReadonly} from 'utility-types';
import {
  Community,
  CommunityProfile,
  MarketplaceCommunity,
} from '@heylo/shared/src/types/firebase-types';
import {AppReset} from '@heylo/shared/src/features/app/Actions';
import {CommunityCreated} from '@heylo/shared/src/features/communities/Slice';
import {MarketplaceCommunityLoaded} from '@heylo/shared/src/features/marketplaces/Slice';
import _ from 'lodash';

export type CommunityProfilesState = {
  FIELD_COMMUNITY_PROFILES: DeepReadonly<{ [communityId: string]: CommunityProfile }>,
};

const initialState: CommunityProfilesState = {
  FIELD_COMMUNITY_PROFILES: {},
};

const setOneCommunityProfile = (communityId: string, newProfile: CommunityProfile, state: Draft<CommunityProfilesState>) => {
  if (!state.FIELD_COMMUNITY_PROFILES) {
    state.FIELD_COMMUNITY_PROFILES = {};
  }
  const profileBefore = state.FIELD_COMMUNITY_PROFILES[communityId] || {};
  const finalProfile = {communityId, ...newProfile};
  if (_.isEqual(profileBefore, finalProfile)) {
    return;
  }
  state.FIELD_COMMUNITY_PROFILES[communityId] = finalProfile;
}

const mergeCommunityProfile = (communityId: string, newProfile: CommunityProfile, state: Draft<CommunityProfilesState>) => {
  if (!state.FIELD_COMMUNITY_PROFILES) {
    state.FIELD_COMMUNITY_PROFILES = {};
  }
  const profileBefore = state.FIELD_COMMUNITY_PROFILES[communityId] || {};
  state.FIELD_COMMUNITY_PROFILES[communityId] = {
    ...profileBefore,
    ...newProfile,
    communityId,
  };
};


export const slice = createSlice({
  name: 'communityProfiles',
  initialState,
  reducers: {
    CommunityProfileLoaded: (state, action: PayloadAction<{ communityId: string, profile: CommunityProfile }>) => {
      const {communityId, profile} = action.payload;
      setOneCommunityProfile(communityId, profile, state);
    },
  },
  extraReducers: builder => builder
      .addCase(AppReset, () => initialState)
      .addCase(CommunityCreated, (state, action: PayloadAction<{ communityId: string, community: Community }>) => {
        const {communityId, community} = action.payload;
        mergeCommunityProfile(communityId, {communityName: community.name}, state);
      })
      .addCase(MarketplaceCommunityLoaded, (state, action: PayloadAction<{ marketplaceId: string, communityId: string, community: MarketplaceCommunity }>) => {
        const {communityId, community} = action.payload;
        const {profile = {}} = community;
        mergeCommunityProfile(communityId, profile, state);
      })
      .addCase(SigningOutAction, () => initialState),

});

const {actions, reducer: CommunityProfilesReducer} = slice;

const {
  CommunityProfileLoaded,
} = actions;

export {
  CommunityProfileLoaded,
  CommunityProfilesReducer,
};