import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {User} from './User';
import {Link, useRouteMatch} from 'react-router-dom';
import {CommunityTab} from './CommunityTab';
import {SelectActiveUserProfile} from '@heylo/shared/src/features/userProfiles/Selectors';
import {useSidebarStyles} from 'features/sidebar/Styles';
import {HeyloText} from 'lib/materialUi';
import {
  selectJoinedCommunityProfiles,
  selectProspectiveCommunityProfiles,
} from '@heylo/shared/src/features/communityProfiles/Selectors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {
  CommunitiesWithUnviewedContentSelector,
  selectDrawerIsOpen,
} from '@heylo/shared/src/features/navigation/Selectors';
import {
  CloseDrawer,
  OpenDrawer,
} from '@heylo/shared/src/features/navigation/Slice';
import {HeyloLogo} from '../ui';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {FLAG_WEB_CHAT_ENABLED} from '../../Flags';
import {useNavigationUtils} from '../navigation/useNavigationUtils';
import {TouchableWithoutFeedback, View} from 'react-native';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import PerfectScrollbar from 'react-perfect-scrollbar'

const mapStateToProps = (state: RootState) => ({
  activeCommunityId: SelectActiveCommunityId(state),
  activeUserId: selectActiveUserId(state),
  activeUserProfile: SelectActiveUserProfile(state),
  communitiesWithUnviewedContent: CommunitiesWithUnviewedContentSelector(state),
  isDrawerOpen: selectDrawerIsOpen(state),
  joinedCommunityProfiles: selectJoinedCommunityProfiles(state),
  prospectiveCommunityProfiles: selectProspectiveCommunityProfiles(state),
});

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector>;

export const PureSidebar = (props: Props) => {
  const {
    activeCommunityId,
    activeUserId,
    activeUserProfile,
    communitiesWithUnviewedContent,
    isDrawerOpen,
    joinedCommunityProfiles,
    prospectiveCommunityProfiles,
  } = props;

  const dispatch = useDispatch();
  const logger = useLoggingService();

  const isCreateGroup = Boolean(useRouteMatch(['/createCommunity', '/newGroup']));
  const isFindGroups = Boolean(useRouteMatch('/m'));
  const isUserSettings = Boolean(useRouteMatch('/userSettings'));

  const communityIsNotInForeground = isCreateGroup || isFindGroups || isUserSettings;

  const openDrawer = () => {
    dispatch(OpenDrawer());
  };

  const closeDrawer = () => {
    dispatch(CloseDrawer());
  };

  const text = useTextStyles();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useSidebarStyles();
  const nav = useNavigationUtils();

  const drawer = (
      <>
        <div style={{
          paddingBottom: theme.spacing(1),
          paddingTop: theme.spacing(2),
        }}>
          <HeyloLogo/>
        </div>

        <View style={{
          flex: 1,
          height: '100%',
          width: '100%',
        }}>
          <nav style={{width: '100%'}}>
            <div className={isUserSettings ? classes.selected : undefined}>
              <User userProfile={activeUserProfile}/>
            </div>

            <div className={isFindGroups ? classes.selected : undefined}>
              <Link to={'/m'} className={classes.containerRow}
                    onClick={() => logger.logEvent(AnalyticsEvent.DRAWER_DISCOVER_COMMUNITIES)}>
                <div className={classes.containerIcon}>
                  <SearchIcon style={{width: '100%', height: '100%'}}/>
                </div>
                <HeyloText noWrap color='inherit' variant='body1'>
                  Find groups
                </HeyloText>
              </Link>
            </div>

            <div className={isCreateGroup ? classes.selected : undefined}>
              <Link to={'/newGroup/name'} className={classes.containerRow}
                    onClick={() => logger.logEvent(AnalyticsEvent.DRAWER_ADD_COMMUNITY)}>
                <div className={classes.containerIcon}>
                  <AddIcon style={{width: '100%', height: '100%'}}/>
                </div>
                <HeyloText noWrap color='inherit' variant='body1'>
                  Create new group
                </HeyloText>
              </Link>
            </div>

            {/*<div className={classes.textNavLink}>*/}
            {/*  <div className={classes.containerIcon}>*/}
            {/*    <FavoriteBorderIcon/>*/}
            {/*  </div>*/}
            {/*  <HeyloText variant='body1'>*/}
            {/*    Recommend Heylo*/}
            {/*  </HeyloText>*/}
            {/*</div>*/}

            {prospectiveCommunityProfiles.length > 0 && (
                <>
                  <div className={classes.containerRow} style={{
                    alignSelf: 'flex-start',
                    height: '3rem',
                    paddingTop: theme.spacing(3),
                  }}>
                    <HeyloText variant={'subtitle2'}>
                      JOIN REQUESTS
                    </HeyloText>
                  </div>
                  {prospectiveCommunityProfiles.map((community) => {
                    const {communityId = '', communityName = 'Unknown'} = community;
                    return (
                        <Link
                            key={`community-${communityId}`}
                            onClick={() => logger.logEvent(AnalyticsEvent.DRAWER_SWITCH_COMMUNITY)}
                            style={{textDecoration: 'none'}}
                            to={`/g/${communityId}`}
                        >
                          <CommunityTab
                              isSelected={!communityIsNotInForeground && activeCommunityId === communityId}
                              name={communityName}
                          />
                        </Link>
                    )
                  })}
                </>
            )}

            {joinedCommunityProfiles.length > 0 && (
                <>
                  <div className={classes.containerRow} style={{
                    alignSelf: 'flex-start',
                    height: '3rem',
                    paddingTop: theme.spacing(3),
                  }}>
                    <HeyloText variant={'subtitle2'}>
                      MY GROUPS
                    </HeyloText>
                  </div>
                  {joinedCommunityProfiles.map((community) => {
                    const {communityId = '', communityName = 'Unknown'} = community;
                    return (
                        <Link
                            key={`community-${communityId}`}
                            onClick={() => logger.logEvent(AnalyticsEvent.DRAWER_SWITCH_COMMUNITY)}
                            style={{textDecoration: 'none'}}
                            to={FLAG_WEB_CHAT_ENABLED
                                ? nav.resolveUriForTopicsSummary(communityId)
                                : nav.resolveUriForEventSummary(communityId)
                            }
                        >
                          <CommunityTab
                              isSelected={!communityIsNotInForeground && activeCommunityId === communityId}
                              name={communityName}
                              showBadge={communitiesWithUnviewedContent.has(communityId)}
                          />
                        </Link>
                    )
                  })}
                </>
            )}
          </nav>
        </View>
      </>
  );

  return (
      <>
        {matchXs ? (
            <>
              <SwipeableDrawer
                  anchor={'left'}
                  open={isDrawerOpen}
                  onOpen={openDrawer}
                  onClose={closeDrawer}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
              >
                <TouchableWithoutFeedback
                    onPress={closeDrawer}
                >
                  <View style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    {drawer}
                  </View>
                </TouchableWithoutFeedback>
              </SwipeableDrawer>
            </>
        ) : (
            <PerfectScrollbar>
              <div style={{
                alignItems: 'center',
                backgroundColor: '#F2F2F2',
                borderRight: 'solid #c4c4c4 1px',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                flexShrink: 0,
                minHeight: '100vh',
                maxWidth: 300,
              }}>
                {drawer}
              </div>
            </PerfectScrollbar>
        )}
      </>
  );
};

export const Sidebar = connector(PureSidebar);
