import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Event, EventAttendance} from '@heylo/shared/src/types/firebase-types';
import {AppColdStart, AppReset} from '@heylo/shared/src/features/app/Actions';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {DeepReadonly} from 'utility-types';
import {UserLeftCommunity} from '../communities/Slice';
import {CommunityScopedDeletionAction} from '@heylo/shared/src/features/communities/Actions';
import {Platform} from 'react-native';

export type EventsState = {
  activeEventId: string,
  communityEventIds: DeepReadonly<{ [communityId: string]: { [eventId: string]: boolean } }>,
  eventAttendance: DeepReadonly<{ [eventId: string]: { [userId: string]: EventAttendance } }>,
  events: DeepReadonly<{ [eventId: string]: Event }>,
};

const initialState: EventsState = {
  activeEventId: '',
  communityEventIds: {},
  eventAttendance: {},
  events: {},
};

export const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {

    ActiveEventChanged: (state, action: PayloadAction<string>) => {
      state.activeEventId = action.payload;
    },

    EventAttendanceUpdated: (state, action: PayloadAction<{ eventId: string, attendance: DeepReadonly<{ [userId: string]: EventAttendance }> }>) => {
      const {eventId, attendance} = action.payload;
      state.eventAttendance[eventId] = Object.assign({}, state.eventAttendance[eventId], attendance);
    },

    EventRemoved: (state, action: PayloadAction<{ communityId: string, eventId: string }>) => {
      const {communityId, eventId} = action.payload;
      if (!state.communityEventIds[communityId]) {
        return;
      }
      delete state.communityEventIds[communityId][eventId];
      delete state.events[eventId];
      delete state.eventAttendance[eventId];
    },

    EventUpdated: (state, action: PayloadAction<{ communityId: string, eventId: string, event: DeepReadonly<Event> }>) => {
      const {communityId, eventId, event} = action.payload;
      const preExistingEvent = state.events[eventId] ?? {};
      const mergedEvent: Event = Object.assign({}, preExistingEvent, event);
      if (!state.communityEventIds[communityId]) {
        state.communityEventIds[communityId] = {};
      }
      state.communityEventIds[communityId][eventId] = true;

      const shouldUpdateEvent =
          !preExistingEvent.eventId
          || mergedEvent.lastUpdateTimestamp !== (preExistingEvent.lastUpdateTimestamp ?? -1);
      if (shouldUpdateEvent) {
        mergedEvent.eventId = eventId;
        mergedEvent.communityId = communityId;
        state.events[eventId] = mergedEvent;
      }
    },

  },

  extraReducers: builder => builder
      .addCase(AppColdStart, (state) => {
        // On web, we use URL params to determine what event ID is active.
        if (Platform.OS === 'web') {
          return;
        }
        state.activeEventId = '';
      })
      .addCase(AppReset, () => initialState)
      .addCase(CommunityScopedDeletionAction, (state, action) => {
        const {communityId, deletion} = action.payload;
        const {eventId: deletedEventId} = deletion || {};
        if (!deletedEventId) {
          return;
        }
        if (state.communityEventIds[communityId]) {
          delete state.communityEventIds[communityId][deletedEventId];
        }
        delete state.eventAttendance[deletedEventId];
        delete state.events[deletedEventId];
        if (state.activeEventId === deletedEventId) {
          state.activeEventId = '';
        }
      })
      .addCase(SigningOutAction, () => initialState)
      .addCase(UserLeftCommunity, (state, action: PayloadAction<string>) => {
        const communityId = action.payload;
        const eventIds = Object.keys(state.communityEventIds[communityId] ?? {});
        for (const eid of eventIds) {
          delete state.events[eid];
        }
        delete state.communityEventIds[communityId];
      }),

});

const {actions, reducer: EventsReducer} = slice;

const {
  ActiveEventChanged,
  EventAttendanceUpdated,
  EventRemoved,
  EventUpdated,
} = actions;

export {
  ActiveEventChanged,
  EventAttendanceUpdated,
  EventRemoved,
  EventUpdated,
  EventsReducer,
};
