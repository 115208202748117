import {createSelector} from 'reselect';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

export const selectUserDevices = (state: RootState) => state.userDevices.devices;

export const selectHasHeyloMobileApp = createSelector(
    [selectUserDevices],
    (devices): boolean => {
      for (const device of Object.values(devices)) {
        if (device.appVersion) {
          return true;
        }
      }
      return false;
    });

