export const REACTION_UNKNOWN = 0;
export const REACTION_HEART = 1;
export const REACTION_LAUGH = 2;
export const REACTION_SURPRISED = 3;
export const REACTION_SAD = 4;
export const REACTION_ANGRY = 5;
export const REACTION_FIRE = 6;
export const REACTION_FLEX = 7;
export const REACTION_CLAP = 8;
export const REACTION_LIKE = 9;
export const REACTION_DISLIKE = 10;

export const REACTIONS: { [reactionType: number]: { label: string, name: string, order: number } } = {
  [REACTION_HEART]: {label: '😍️', name: 'heart', order: 1},
  [REACTION_LAUGH]: {label: '😆', name: 'laugh', order: 2},
  [REACTION_SURPRISED]: {label: '😲', name: 'surprise', order: 3},
  [REACTION_SAD]: {label: '😢', name: 'sad', order: 4},
  [REACTION_ANGRY]: {label: '😠', name: 'angry', order: 5},
  // [REACTION_FLEX]: {label: '💪', order: 6},
  [REACTION_FIRE]: {label: '🔥', name: 'fire', order: 7},
  [REACTION_CLAP]: {label: '👏', name: 'clap', order: 8},
  [REACTION_LIKE]: {label: '👍', name: 'like', order: 9},
  [REACTION_DISLIKE]: {label: '👎', name: 'dislike', order: 10},
};

export const GetReactionLabel = (reactionType: number): string => {
  return REACTIONS[reactionType] && REACTIONS[reactionType].label || '';
};