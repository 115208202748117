import {DeepReadonly} from 'utility-types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppReset} from '@heylo/shared/src/features/app/Actions';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {Document} from '@heylo/shared/src/types/firebase-types';

export type DocumentsState = {
  FIELD_DOCUMENTS_BY_COMMUNITY: DeepReadonly<{ [communityId: string]: { [documentId: string]: boolean } }>,
  FIELD_ALL_DOCUMENTS: DeepReadonly<{ [communityId: string]: Document }>,
};

const initialState: DocumentsState = {
  FIELD_DOCUMENTS_BY_COMMUNITY: {},
  FIELD_ALL_DOCUMENTS: {},
};

export const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    ACTION_COMPACT_DOCUMENTS: (state) => {
      let linkedDocuments = new Set<string>();
      for (const documentSet of Object.values(state.FIELD_DOCUMENTS_BY_COMMUNITY)) {
        for (const documentId of Object.keys(documentSet)) {
          linkedDocuments = linkedDocuments.add(documentId);
        }
      }
      for (const documentId of Object.keys(state.FIELD_ALL_DOCUMENTS)) {
        if (!linkedDocuments.has(documentId)) {
          delete state.FIELD_ALL_DOCUMENTS[documentId];
        }
      }
    },

    ACTION_LINK_DOCUMENT_TO_COMMUNITY: (state, action: PayloadAction<{ communityId: string, documentId: string }>) => {
      const {communityId, documentId} = action.payload;
      if (!state.FIELD_DOCUMENTS_BY_COMMUNITY[communityId]) {
        state.FIELD_DOCUMENTS_BY_COMMUNITY[communityId] = {};
      }
      state.FIELD_DOCUMENTS_BY_COMMUNITY[communityId][documentId] = true;
    },

    ACTION_SET_DOCUMENT: (state, action: PayloadAction<{ documentId: string, document: DeepReadonly<Document> | null }>) => {
      const {documentId, document} = action.payload;
      if (!document) {
        delete state.FIELD_ALL_DOCUMENTS[documentId];
        return;
      }
      state.FIELD_ALL_DOCUMENTS[documentId] = {
        ...document,
        id: documentId,
      };
    },

    ACTION_UNLINK_DOCUMENT_TO_COMMUNITY: (state, action: PayloadAction<{ communityId: string, documentId: string }>) => {
      const {communityId, documentId} = action.payload;
      if (!state.FIELD_DOCUMENTS_BY_COMMUNITY[communityId]) {
        state.FIELD_DOCUMENTS_BY_COMMUNITY[communityId] = {};
      }
      delete state.FIELD_DOCUMENTS_BY_COMMUNITY[communityId][documentId];
    },
  },

  extraReducers: builder => builder
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState),
});

const {actions, reducer: DocumentsReducer} = slice;

const {
  ACTION_COMPACT_DOCUMENTS,
  ACTION_LINK_DOCUMENT_TO_COMMUNITY,
  ACTION_SET_DOCUMENT,
  ACTION_UNLINK_DOCUMENT_TO_COMMUNITY,
} = actions;

export {
  ACTION_COMPACT_DOCUMENTS,
  ACTION_LINK_DOCUMENT_TO_COMMUNITY,
  ACTION_SET_DOCUMENT,
  ACTION_UNLINK_DOCUMENT_TO_COMMUNITY,
  DocumentsReducer,
};
