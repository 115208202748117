import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {HeyloButton, HeyloDialog, HeyloText} from '../../lib/materialUi';
import {
  selectActiveEvent,
  selectActiveUserHasEventWritePermissions,
} from '@heylo/shared/src/features/events/Selectors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {HeaderIcon} from '@heylo/components/src/features/navigation/HeaderIcon';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Menu} from 'react-native-paper';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {FirebaseRemoveEvent} from '@heylo/shared/src/features/events/Firebase';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {WebAppDispatch} from '../../store';
import {useHistory} from 'react-router-dom';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {useNavigationUtils} from '../navigation/useNavigationUtils';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';


export const EventSettingsButton = () => {
  const {
    activeEvent,
    activeUserId,
    editEventPermissions,
  } = useSelector((state: RootState) => ({
    activeEvent: selectActiveEvent(state),
    activeUserId: selectActiveUserId(state),
    editEventPermissions: selectActiveUserHasEventWritePermissions(state),
  }), shallowEqual);

  const {communityId = '', eventId = ''} = activeEvent;

  const dispatch: WebAppDispatch = useDispatch();
  const history = useHistory();
  const logger = useLoggingService();
  const nav = useNavigationUtils();
  const text = useTextStyles();

  const [menuVisible, setMenuVisible] = useState(false);
  const hideMenu = () => setMenuVisible(false);
  const showMenu = () => setMenuVisible(true);

  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const showEditEventDialog = () => {
    history.push(nav.resolveUriForEditEvent());
  };

  const showDuplicateEventDialog = () => {
    history.push(nav.resolveUriForDuplicateEvent());
  };

  const showDeleteDialog = () => {
    hideMenu();
    setIsDeleteDialogVisible(true);
  };

  const deleteEvent = () => {
    logger.logEvent(AnalyticsEvent.EVENTS_DELETE);
    dispatch(FirebaseRemoveEvent(communityId, eventId, activeUserId));
    history.goBack()
  };

  if (!editEventPermissions) {
    return null;
  }
  return (
      <>
        <Menu
            anchor={matchXs ? (
                <HeaderIcon
                    iconName={'dots-vertical'}
                    onPress={showMenu}
                />
            ) : (
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <div style={{
                    width: 'fit-content',
                    position: 'relative',
                    right: '2vw',
                  }}>
                    <HeyloButton
                        color={'primary'}
                        label={'MORE'}
                        onClick={showMenu}
                        endIcon={<ArrowDropDownIcon/>}
                    />
                  </div>
                </div>
            )}
            onDismiss={hideMenu}
            theme={{roundness: 5}}
            visible={menuVisible}
        >
          <Menu.Item
              icon="pencil"
              onPress={showEditEventDialog}
              title="Edit"
              titleStyle={text.body1}
          />
          <Menu.Item
              icon="content-copy"
              onPress={showDuplicateEventDialog}
              title="Duplicate"
              titleStyle={text.body1}
          />
          <Menu.Item
              icon="delete"
              onPress={showDeleteDialog}
              title="Delete"
              titleStyle={text.body1}
          />
        </Menu>

        <HeyloDialog
            actions={[
              {
                label: 'Cancel',
                onClick: () => setIsDeleteDialogVisible(false),
              },
              {
                label: 'Delete',
                onClick: deleteEvent,
              },
            ]}
            maxWidth='xs'
            onClose={() => setIsDeleteDialogVisible(false)}
            open={isDeleteDialogVisible}
            title={'Permanently delete event?'}
        >
          <HeyloText variant={'body1'}>
            The event will be removed, and a notification will be sent to members that have RSVP'd.
          </HeyloText>
        </HeyloDialog>
      </>
  );
};