import React, {useEffect, useState} from 'react';
import EventIcon from '@material-ui/icons/Event';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {selectAllEventAttendance} from '@heylo/shared/src/features/events/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import {FirebaseUpdateEventRsvp} from '@heylo/shared/src/features/events/Firebase';
import {ActionButton} from './ActionButton';
import {EventAttendanceUpdated} from '@heylo/shared/src/features/events/Slice';
import {Event} from '@heylo/shared/src/types/firebase-types';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

const GOING = 'yes';
const INTERESTED = 'maybe';
const NOT_GOING = 'no';

const mapState = (state: RootState, ownProps: OwnProps) => {
  const {eventId} = ownProps;
  const userId = selectActiveUserId(state);
  return {
    eventAttendance: selectAllEventAttendance(state)[eventId]?.[userId]?.going ?? '',
    userId,
  };
};

const connector = connect(mapState);

type OwnProps = {
  event: Event,
  eventId: string,
  variant: 'square' | 'rectangle',
}

type Props = ConnectedProps<typeof connector> & OwnProps;

export const RsvpButton = connector((props: Props) => {
  const {
    event = {},
    eventAttendance,
    eventId,
    userId,
    variant,
  } = props;
  const {
    communityId = '',
    timestamp: eventTimestamp = 0,
  } = event;

  const logger = useLoggingService();

  const [attendanceStatus, setAttendanceStatus] = useState('');
  useEffect(() => {
    setAttendanceStatus(eventAttendance);
  }, [eventAttendance]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const now = moment().valueOf();
  const inTheFuture = eventTimestamp > now;

  let rsvpLabel = inTheFuture ? 'Going?' : 'Went?';
  let rsvpIcon = <EventIcon/>;
  const rsvpOptions: any = {
    [GOING]: {
      icon: <CheckIcon/>,
      label: inTheFuture ? 'Going' : 'Went',
    },
    [INTERESTED]: {
      icon: <StarIcon/>,
      label: 'Interested',
    },
    [NOT_GOING]: {
      icon: <CloseIcon/>,
      label: inTheFuture ? 'Not going' : 'Did not go',
    },
  };
  if (rsvpOptions[attendanceStatus]) {
    rsvpLabel = rsvpOptions[attendanceStatus].label;
    rsvpIcon = rsvpOptions[attendanceStatus].icon;
  }

  const showPopover = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const updateEventRsvp = (newRsvpStatus: 'yes' | 'maybe' | 'no') => {
    setAttendanceStatus(newRsvpStatus);
    dispatch(EventAttendanceUpdated({
      eventId,
      attendance: {[userId]: {going: newRsvpStatus}},
    }));
    FirebaseUpdateEventRsvp(communityId, eventId, userId, newRsvpStatus)
        .catch((e: Error) => {
          // TODO: handle error
          console.log(e);
        });
    switch (newRsvpStatus) {
      case 'yes':
        logger.logEvent(AnalyticsEvent.EVENTS_RSVP_JOINING);
        break;
      case 'maybe':
        logger.logEvent(AnalyticsEvent.EVENTS_RSVP_INTERESTED);
        break;
      case 'no':
        logger.logEvent(AnalyticsEvent.EVENTS_RSVP_NOT_JOINING);
        break;
    }
    handleClose();
  }

  return (
      <>
        <ActionButton
            icon={rsvpIcon}
            label={rsvpLabel}
            onClickHandler={showPopover}
            showArrow={true}
            variant={variant}
        />
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
        >
          <List>
            <ListItem
                button
                onClick={() => updateEventRsvp(GOING)}
                selected={attendanceStatus === GOING}
            >
              <CheckIcon/>
              <ListItemText primary={'Going'}/>
            </ListItem>

            <ListItem
                button
                onClick={() => updateEventRsvp(INTERESTED)}
                selected={attendanceStatus === INTERESTED}
            >
              <StarIcon/>
              <ListItemText primary={'Interested'}/>
            </ListItem>

            <ListItem
                button
                onClick={() => updateEventRsvp(NOT_GOING)}
                selected={attendanceStatus === NOT_GOING}
            >
              <CloseIcon/>
              <ListItemText primary={'Not going'}/>
            </ListItem>
          </List>
        </Popover>
      </>
  );
});
