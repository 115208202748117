import React from 'react';
import {ScrollView, Text, View, ViewStyle} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {HeyloButton} from '@heylo/components/src/ui/button/HeyloButton';
import {NewCommunityProgressBar} from '@heylo/components/src/features/communityCreation/NewCommunityProgressBar';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {SkipButton} from '@heylo/components/src/ui/header/SkipButton';
import {SidebarIcon} from './SidebarIcon';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export enum NextButton {
  HIDDEN,
  SKIP,
  NEXT,
  NEXT_BUSY,
}

export const ProgressHeader = (
    {
      children,
      containerStyle = {},
      nextButton,
      onBackPress,
      onNextPress,
      percent,
      showSwitcherIcon,
      title,
    }: {
      children: React.ReactNode,
      containerStyle?: ViewStyle,
      nextButton: NextButton,
      onBackPress: (() => void) | null,
      onNextPress: () => void,
      percent: string,
      showSwitcherIcon?: boolean,
      title: string,
    }) => {

  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  const text = useTextStyles();

  return (
      <View style={{display: 'flex', flex: 1}}>
        <div style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}>
          <View style={{
            alignSelf: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: StyleConstants.SPACING,
            maxWidth: 600,
            width: '100%',
          }}>
            <Text style={text.h5}>
              {title}
            </Text>
            {onBackPress ? (
                <IconButton
                    aria-label="go back"
                    onClick={onBackPress}
                    style={{
                      left: StyleConstants.SPACING / 2,
                      position: 'absolute',
                    }}
                >
                  <ArrowBackIcon/>
                </IconButton>
            ) : (matchXs && showSwitcherIcon) ? (
                <View style={{
                  left: StyleConstants.SPACING / 2,
                  position: 'absolute',
                }}>
                  <SidebarIcon/>
                </View>
            ) : null}
            <View style={{
              position: 'absolute',
              right: StyleConstants.SPACING,
            }}>
              {nextButton === NextButton.NEXT_BUSY ? (
                  <HeyloButton
                      busy={true}
                      label={'Next'}
                      onPress={() => {
                      }}
                  />
              ) : nextButton === NextButton.NEXT ? (
                  <HeyloButton
                      onPress={onNextPress}
                      label={'Next'}
                  />
              ) : nextButton === NextButton.SKIP ? (
                  <SkipButton onPress={onNextPress}/>
              ) : null}
            </View>
          </View>

          <NewCommunityProgressBar width={percent}/>
        </div>

        <ScrollView
            contentContainerStyle={{alignItems: 'center', minHeight: '100%'}}
            style={{height: '100%'}}
        >
          <View style={{
            flex: 1,
            maxWidth: 500,
            ...containerStyle,
          }}>
            {children}
          </View>
        </ScrollView>
      </View>
  );
};
