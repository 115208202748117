export enum BuildVariant {
  ALPHA,
  BETA,
  PRODUCTION,
}

export const ResolveBuildVariant = (build?: string) => {
  switch (build) {
    case 'alpha':
      return BuildVariant.ALPHA;
    case 'beta':
      return BuildVariant.BETA;
    case 'production':
    default:
      return BuildVariant.PRODUCTION;
  }
};

export const ResolveAuthHostForBuildVariant = (variant: BuildVariant): string => {
  switch (variant) {
    case BuildVariant.ALPHA:
      return 'http://localhost:3000';
    case BuildVariant.BETA:
      return 'https://heylo-auth-beta.web.app';
    case BuildVariant.PRODUCTION:
    default:
      return 'https://auth.heylo.co';
  }
};

export const ResolveHostForBuildVariant = (variant: BuildVariant): string => {
  switch (variant) {
    case BuildVariant.ALPHA:
      return 'http://localhost:3000';
    case BuildVariant.BETA:
      return 'https://heylo-beta.web.app';
    case BuildVariant.PRODUCTION:
    default:
      return 'https://app.heylo.co';
  }
};