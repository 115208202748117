import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Keyboard,
  Platform,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {ActivityIndicator, HelperText, Menu} from 'react-native-paper';
import {useImageUploader} from '@heylo/shared/src/services/image/ImageUploaderContext';
import {ImageUploadMetadata} from '@heylo/shared/src/services/image/ImageUploader';
import {HeyloImage} from './HeyloImage';
import {WebPhotoUploadDialog} from './WebPhotoUploadDialog';

export type Props = {
  alt?: string,
  aspectRatio?: number,
  callToAction?: string,
  cropShape: 'circle' | 'square',
  imageUri: string,
  maximumHeight?: number,
  maximumWidth?: number,
  onChange: (downloadUrlPromise: Promise<string>, image: ImageUploadMetadata) => void,
  storagePath: string,
  width?: string | number,
  height?: string | number,
};

export enum EditableImageState {
  IDLE,
  UPLOADING,
  UPLOAD_ERROR,
}

export const EditableImage = (props: Props) => {
  const [state, setState] = useState(EditableImageState.IDLE);
  return <PureEditableImage
      {...props}
      state={state}
      setState={setState}/>;
};

export const PureEditableImage = (props: Props & {
  state: EditableImageState,
  setState: Dispatch<SetStateAction<EditableImageState>>
}) => {
  const {
    aspectRatio = 1,
    callToAction = 'Edit photo',
    cropShape,
    imageUri: externalImageUri,
    maximumHeight = 2000,
    maximumWidth = 2000,
    onChange,
    state,
    setState,
    storagePath,
  } = props;

  const [imageUri, setImageUri] = useState(externalImageUri);
  useEffect(() => setImageUri(externalImageUri), [externalImageUri]);

  const isCircle = cropShape === 'circle';
  const borderRadius = isCircle ? 1000 : 0;
  const palette = usePalette();
  const text = useTextStyles();

  const [menuVisible, setMenuVisible] = useState(false);
  const showMenu = () => {
    Keyboard.dismiss();
    setMenuVisible(true);
  }
  const hideMenu = () => setMenuVisible(false);

  const imageUploader = useImageUploader();
  // const {state:newImageState, newImageUri, uploader} = useImageUploader({
  //   cropShape,
  //   storagePath,
  // });
  const handleFromCamera = () => {
    hideMenu();
    if (Platform.OS === 'web') {
      console.warn('handleFromCamera not implemented on web');
      return;
    }
    uploadImageForMobile(true);
  }

  const fileInputRef = React.createRef<HTMLInputElement>();

  const handleFromStorage = () => {
    hideMenu();
    if (Platform.OS === 'web') {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      uploadImageForMobile(false);
    }
  }

  const handleRemove = () => {
    hideMenu();
    onChange(Promise.resolve(''), {
      contentType: '',
      localUri: '',
      height: 0,
      width: 0,
    });
  }

  const uploadImageForMobile = async (useCamera: boolean) => {
    hideMenu();

    let metadata: ImageUploadMetadata | null = await imageUploader.onChooseImage(useCamera, {
      cropShape,
      maximumHeight,
      maximumWidth,
    });
    if (!metadata) {
      return;
    }
    setState(EditableImageState.UPLOADING);
    try {
      // NB: in rare cases, the keyboard will appear on iOS (e.g., create
      // community flow)
      Keyboard.dismiss();
      const downloadUriPromise = imageUploader.uploadImage(storagePath, metadata)
          .then(downloadUri => {
            setState(EditableImageState.IDLE);
            setImageUri(downloadUri);
            return Promise.resolve(downloadUri);
          });
      onChange(downloadUriPromise, metadata);
    } catch (e) {
      console.warn('Error uplaoding image', e);
      setState(EditableImageState.UPLOAD_ERROR);
    }
  }

  const overlayColor = imageUri ? 'white' : palette.grey.main;

  const onPress = () => {
    if (Platform.OS === 'web' && !imageUri) {
      handleFromStorage();
    } else {
      showMenu();
    }
  };

  const imageStyle = useMemo(() => ({
    height: '100%',
    borderRadius,
    width: '100%',
  }), []);

  const containerStyle = useMemo(() => {
    return Platform.OS === 'web'
        ? {height: '100%', width: '100%'}
        : {aspectRatio: 1, width: '100%'}
  }, []);

  const editableImageElement = (
      <>
        {Platform.OS === 'web' && (
            <WebPhotoUploadDialog
                aspectRatio={1}
                cropShape={cropShape === 'square' ? 'rect' : 'round'}
                onCancel={() => setState(EditableImageState.IDLE)}
                onChange={onChange}
                onError={() => setState(EditableImageState.UPLOAD_ERROR)}
                ref={fileInputRef}
                storagePath={storagePath}
            />
        )}

        <TouchableWithoutFeedback onPress={onPress}>
          <View style={containerStyle}>
            <View style={containerStyle}>
              <View style={{
                aspectRatio,
                borderRadius,
                width: '100%',
              }}>
                {(Boolean(imageUri) && state !== EditableImageState.UPLOADING) ? (
                    <HeyloImage
                        aspectRatio={aspectRatio}
                        key={'image' + !!imageUri}
                        resizeMode='cover'
                        style={imageStyle}
                        uri={imageUri}
                    />
                ) : null}
              </View>

              <View style={{
                alignItems: 'center',
                backgroundColor: '#0002',
                borderRadius,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
              }}>
                {state === EditableImageState.UPLOADING ? (
                    <ActivityIndicator color={palette.grey.main}
                                       size={'large'}/>
                ) : (state === EditableImageState.IDLE || state === EditableImageState.UPLOAD_ERROR) && (
                    <>
                      <MaterialCommunityIcon
                          color={overlayColor}
                          name={'camera'}
                          size={40}
                      />
                      <Menu
                          anchor={
                            <Text style={{
                              ...text.h6,
                              color: overlayColor,
                              textAlign: 'center',
                            }}
                            >
                              {callToAction}
                            </Text>
                          }
                          onDismiss={hideMenu}
                          theme={{roundness: 5}}
                          visible={menuVisible}
                      >
                        {Platform.OS !== 'web' && (
                            <Menu.Item
                                icon="camera"
                                onPress={handleFromCamera}
                                title="Use camera"
                                titleStyle={text.body1}
                            />
                        )}
                        <Menu.Item
                            icon="upload"
                            onPress={handleFromStorage}
                            title="Upload photo"
                            titleStyle={text.body1}
                        />
                        <Menu.Item
                            disabled={!imageUri}
                            icon="delete"
                            onPress={handleRemove}
                            title="Remove photo"
                            titleStyle={text.body1}
                        />
                      </Menu>
                    </>
                )}
              </View>
            </View>
            {state === EditableImageState.UPLOAD_ERROR && (
                <HelperText type={'error'} style={{textAlign: 'center'}}>
                  Upload failed. Please try again!
                </HelperText>
            )}
          </View>
        </TouchableWithoutFeedback>
      </>
  );

  if (Platform.OS === 'web') {
    return (
        <View style={{
          alignSelf: 'center',
          height: 0,
          paddingTop: `${1 / aspectRatio * 100}%`,
          width: '100%',
        }}>
          <View style={{
            height: '100%',
            position: 'absolute',
            top: 0,
            width: '100%',
          }}>
            {editableImageElement}
          </View>
        </View>
    );
  }
  return editableImageElement;
}