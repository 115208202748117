import React, {useEffect, useState} from 'react';
import {HeyloText} from '../../lib/materialUi';
import {useRouteMatch} from 'react-router-dom';
import Confetti from 'react-dom-confetti';
import Grow from '@material-ui/core/Grow';
import BlockIcon from '@material-ui/icons/Block';

export const OauthResultScreen = () => {
  const isSuccess = useRouteMatch('/stripe/oauth/success');

  const [shootConfetti, setShootConfetti] = useState(false);
  useEffect(() => {
    setTimeout(() => setShootConfetti(true), 2000);
  }, []);

  return (
      <div className={'flexcolumn flexcenter'}
           style={{flex: 1}}>
        {isSuccess ? (
            <Grow in={true} timeout={2500}>
              <div className={'flexcolumn flexcenter'}>
                <div style={{fontSize: '6rem'}}>
                  🎉
                </div>
                <HeyloText align='center' variant={'h5'}>
                  Your account has been linked!
                </HeyloText>
                <HeyloText align='center' variant={'body1'}>
                  Give Stripe a few minutes to verify your information.
                </HeyloText>
                <Confetti
                    active={shootConfetti}
                    config={{
                      angle: 110,
                      duration: 6000,
                      stagger: 1,
                    }}
                />
                <Confetti
                    active={shootConfetti}
                    config={{
                      angle: 70,
                      duration: 6000,
                      stagger: 1,
                    }}
                />
              </div>
            </Grow>
        ) : (
            <div className={'flexcolumn flexcenter'}>
              <BlockIcon style={{
                height: '6rem',
                marginBottom: '2rem',
                width: '6rem',
              }}/>
              <HeyloText variant={'h5'}>
                Something went wrong, and your account has not been linked.
              </HeyloText>
              <HeyloText variant={'body1'}>
                Please try again or contact support@heylo.co
              </HeyloText>
            </div>
        )}
        <div style={{marginTop: '6rem'}}>
          <HeyloText variant={'body1'}>
            You can close this window to go back to Heylo
          </HeyloText>
        </div>
      </div>
  );
};