import React, {useEffect, useState} from 'react';
import {
  FlatList,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {shallowEqual, useSelector} from 'react-redux';
import {UserProfile} from '@heylo/shared/src/types/firebase-types';
import {FilterMemberByName} from '@heylo/shared/src/features/userProfiles/MemberNameFilter';
import {Avatar} from '@heylo/components/src/features/userProfile/Avatar';
import {SelectActiveCommunityUserProfiles} from '@heylo/shared/src/features/communityMembers/Selectors';
import _ from 'lodash';
import {ActiveThreadMembersSelector} from '@heylo/shared/src/features/threads/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {HeyloFilterInput} from '@heylo/components/src/ui/input/HeyloFilterInput';
import {StyleVars} from '@heylo/components/src/styles/StyleVars';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

export const AddThreadMembersList = (
    {onChange}: { onChange: (members: { [userId: string]: boolean }) => void },
) => {

  const {
    activeThreadMembers,
    activeUserId,
    userProfiles,
  } = useSelector((state: RootState) => ({
    activeThreadMembers: ActiveThreadMembersSelector(state),
    activeUserId: selectActiveUserId(state) || '',
    userProfiles: SelectActiveCommunityUserProfiles(state),
  }), shallowEqual);

  const text = useTextStyles();
  const [memberNameFilter, setMemberNameFilter] = useState('');
  const [currentMembersArray, setCurrentMembersArray] = useState<UserProfile[]>([]);

  const [currentMemberMap, setCurrentMemberMap] = useState<{ [userId: string]: boolean }>({});
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(currentMemberMap);
    }
  }, [currentMemberMap]);

  const addMember = (item: UserProfile) => {
    setCurrentMembersArray([...currentMembersArray, item]);
    currentMemberMap[item.id || ''] = true;
    setCurrentMemberMap(Object.assign({}, currentMemberMap));
  };

  const removeMember = (deletedUserId: string) => {
    const newMembers = currentMembersArray.filter(member => member.id !== deletedUserId);
    setCurrentMembersArray(newMembers);
    delete currentMemberMap[deletedUserId];
    setCurrentMemberMap(Object.assign({}, currentMemberMap));
  };

  const [currentSuggestions, setCurrentSuggestions] = useState<UserProfile[]>([]);
  useEffect(() => {
    const filteredUsers: UserProfile[] = _.sortBy(
        Object.values(userProfiles)
            .filter(user => {
              const userId = user.id || '';
              return userId
                  && userId !== activeUserId
                  && !activeThreadMembers[userId]
                  && FilterMemberByName(user, memberNameFilter);
            }),
        profile => profile.fullName?.toLocaleLowerCase() || '');
    setCurrentSuggestions(filteredUsers);
  }, [activeThreadMembers, activeUserId, userProfiles, currentMemberMap, memberNameFilter]);


  const renderSuggestionsRow = ({item}: { item: UserProfile }) => {
    const {
      fullName = '',
      id: userId = '',
    } = item;
    const isAdded = currentMemberMap[userId];
    return (
        <TouchableOpacity
            onPress={() => isAdded ? removeMember(userId) : addMember(item)}
            style={styles.suggestionsRowContainer}
        >
          <Avatar size={30} userId={userId}/>
          <Text
              ellipsizeMode={'tail'}
              numberOfLines={1}
              style={[text.body1, {marginLeft: 4}]}
          >
            {fullName}
          </Text>
          {isAdded && (
              <MaterialCommunityIcon
                  color={StyleVars.Colors.GreyDark}
                  name={'check-circle-outline'}
                  size={20}
                  style={{position: 'absolute', right: 0}}
              />
          )}
        </TouchableOpacity>
    );
  };

  return (
      <View>
        <View style={{
          marginBottom: 8,
          marginHorizontal: StyleVars.Dimensions.ScreenMargins,
        }}>
          <HeyloFilterInput
              onChangeText={setMemberNameFilter}
              label={'Search by name'}
              value={memberNameFilter}
          />
        </View>

        <SafeAreaView style={{
          marginHorizontal: StyleConstants.SPACING,
        }}>
          <FlatList
              data={currentSuggestions}
              initialNumToRender={20}
              keyboardShouldPersistTaps={'always'}
              keyExtractor={item => item.id || ''}
              ListEmptyComponent={
                <View style={{marginTop: StyleConstants.SPACING}}>
                  <Text style={[text.body1, text.center]}>
                    No results!{'\n\n'}Someone missing?{'\n'}Add them to your group!
                  </Text>
                </View>
              }
              renderItem={renderSuggestionsRow}
              scrollEnabled={false}
          />
        </SafeAreaView>
      </View>
  );
};

const styles = StyleSheet.create({
  suggestionsRowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 8,
  },
});