import React from 'react';

export const Loading = () => {
  return (
      <div className={'LoadingScreen'}>
        <div>
          <i className="fa fa-cog fa-spin"/>
        </div>
      </div>
  );
};