import {FirebaseDatabase} from '@heylo/firebase-database';
import {DeviceInfo} from '@heylo/shared/src/types/firebase-types';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {UserDevicesLoaded} from './Slice';

export const UserDevicesFirebase = {

  LoadUserDevices: (userId: string):
      ThunkReturnType<void> => dispatch => {
    FirebaseDatabase().ref(`/userDevices/${userId}`).once('value')
        .then(snapshot => {
          const devices: { [deviceId: string]: DeviceInfo } = snapshot.val() ?? {};
          dispatch(UserDevicesLoaded(devices));
        });
  },

};