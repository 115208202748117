import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {selectActiveCommunityProfile} from '@heylo/shared/src/features/communityProfiles/Selectors';
import {AddMembersDialog} from '../communityMembers/AddMembersDialog';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {selectActiveEvent} from '@heylo/shared/src/features/events/Selectors';
import {EventSettingsButton} from '../event';
import {AddEventButton} from '../event/AddEventButton';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {IncrementUserInteraction} from '@heylo/shared/src/features/userEvents/Actions';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {Breadcrumb, Breadcrumbs} from './Breadcrumbs';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {View} from 'react-native';
import {useNavigationUtils} from '../navigation/useNavigationUtils';
import {ActiveThreadSelector} from '@heylo/shared/src/features/threads/Selectors';
import {HeaderIcon} from '@heylo/components/src/features/navigation/HeaderIcon';
import {CreateTopicDialog} from '../topics/CreateTopicDialog';
import {HeyloButton} from '@heylo/components/src/ui/button/HeyloButton';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {selectChatInfoVisible} from '@heylo/shared/src/features/userSettings/Selectors';
import {ToggleChatInfoVisible} from '@heylo/shared/src/features/userSettings/Slice';

export enum Variant {
  ADD_EVENT,
  DUPLICATE_EVENT,
  EDIT_EVENT,
  EVENT_DETAILS,
  EVENTS,
  GET_THE_APP,
  MEMBERS,
  GROUP_INFO,
  EDIT_GROUP_INFO,
  TOPICS,
}

type Props = {
  variant: Variant
};

export const CommunityHeader = (props: Props) => {
  const {variant} = props;
  const dispatch = useDispatch();
  const logger = useLoggingService();
  const nav = useNavigationUtils();

  const {
    activeEvent,
    activeThread,
    chatInfoVisible,
    communityProfile,
  } = useSelector((state: RootState) => ({
    activeEvent: selectActiveEvent(state),
    activeThread: ActiveThreadSelector(state),
    chatInfoVisible: selectChatInfoVisible(state),
    communityProfile: selectActiveCommunityProfile(state),
  }), shallowEqual);

  const {name: activeEventName = ''} = activeEvent || {};
  const {name: activeThreadName = ''} = activeThread || {};

  const [isAddMembersDialogVisible, setIsAddMembersDialogVisible] = useState(false);
  const [isCreateTopicDialogVisible, setIsCreateTopicDialogVisible] = useState(false);

  const showAddMembersDialog = () => {
    setIsAddMembersDialogVisible(true);
    logger.logEvent(AnalyticsEvent.MEMBER_ADD_MORE, {context: 'header'});
    dispatch(IncrementUserInteraction(AnalyticsEvent.MEMBER_ADD_MORE));
  };

  const toggleChatInfoPane = () => {
    dispatch(ToggleChatInfoVisible());
  }

  const palette = usePalette();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'));

  let title = '';
  let breadcrumbs: Breadcrumb[] = [];
  let headerIcon: JSX.Element | null = null;
  switch (variant) {
    case Variant.ADD_EVENT:
      breadcrumbs = [{
        label: 'Events',
        to: nav.resolveUriForEventSummary(),
      }];
      title = 'Add event';
      break;

    case Variant.DUPLICATE_EVENT:
      breadcrumbs = [{
        label: 'Events',
        to: nav.resolveUriForEventSummary(),
      }, {
        label: activeEventName,
        to: nav.resolveUriForEventDetails(),
      }];
      title = 'Duplicate event';
      break;

    case Variant.EDIT_EVENT:
      breadcrumbs = [{
        label: 'Events',
        to: nav.resolveUriForEventSummary(),
      }, {
        label: activeEventName,
        to: nav.resolveUriForEventDetails(),
      }];
      title = 'Edit event';
      break;

    case Variant.EVENT_DETAILS:
      breadcrumbs = [{
        label: 'Events',
        to: nav.resolveUriForEventSummary(),
      }];
      title = activeEventName || 'Event details';
      headerIcon = <EventSettingsButton/>;
      break;

    case Variant.EVENTS:
      title = 'Events';
      headerIcon = <AddEventButton/>;
      break;

    case Variant.GET_THE_APP:
      title = 'Get the app';
      break;

    case Variant.MEMBERS:
      title = 'Members';
      headerIcon = (
          <>
            {matchXs
                ? <HeaderIcon
                    iconName={'account-plus'}
                    onPress={showAddMembersDialog}
                /> : (
                    <HeyloButton
                        icon={'account-plus'}
                        label={'Add members'}
                        onPress={showAddMembersDialog}
                    />
                )}
            {isAddMembersDialogVisible ? (
                <AddMembersDialog
                    onClose={() => setIsAddMembersDialogVisible(false)}
                    open={true}
                />
            ) : null}
          </>
      );
      break;

    case Variant.EDIT_GROUP_INFO:
      breadcrumbs = [{
        label: 'Group info',
        to: nav.resolveUriForGroupPage(),
      }];
      title = 'Edit';
      break;

    case Variant.GROUP_INFO:
      title = communityProfile.communityName ?? 'Group info';
      break;

    case Variant.TOPICS:
      if (activeThreadName) {
        breadcrumbs = [{
          label: 'Topics',
          to: nav.resolveUriForTopicsSummary(),
        }];
        title = activeThreadName;
        headerIcon = <HeaderIcon
            color={Boolean(chatInfoVisible) ? palette.primary.main : palette.primary.light}
            iconName={'information'}
            onPress={toggleChatInfoPane}
        />;
      } else {
        title = 'Topics';
        headerIcon = (
            <>
              {matchXs
                  ? <HeaderIcon
                      iconName={'comment-plus'}
                      onPress={() => setIsCreateTopicDialogVisible(true)}
                  /> : <HeyloButton
                      label={'New topic'}
                      onPress={() => setIsCreateTopicDialogVisible(true)}
                      icon={'comment-plus'}
                  />}
              {isCreateTopicDialogVisible ? (
                  <CreateTopicDialog
                      onClose={() => setIsCreateTopicDialogVisible(false)}
                      open={true}/>
              ) : null}
            </>
        );
      }
      break;

  }

  return (
      <View style={{
        backgroundColor: 'white',
        borderBottomWidth: 1,
        borderColor: '#c4c4c4',
        justifyContent: 'center',
        paddingBottom: StyleConstants.SPACING,
        maxWidth: '100%',
      }}>
        <Breadcrumbs crumbs={breadcrumbs} title={title}/>

        <div style={{
          paddingTop: StyleConstants.SPACING,
          position: 'absolute',
          right: matchXs ? 0 : StyleConstants.SPACING,
        }}>
          {headerIcon}
        </div>
      </View>
  );
};