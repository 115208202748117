import React, {useEffect} from 'react';
import Container from '@material-ui/core/Container';
import {useHistory} from 'react-router-dom';
import {
  LoginState,
  useLoginScreenState,
} from '@heylo/shared/src/features/auth/useLoginScreenState';
import {LoginForm} from '@heylo/components/src/features/auth/LoginForm';

export const AuthenticationScreen = () => {
  const {
    accountHasPassword,
    onReset,
    onSignInWithEmailLink,
    onSubmitEmail,
    onSubmitEmailPassword,
    onSwitchToPassword,
    state,
  } = useLoginScreenState();

  const history = useHistory();

  useEffect(() => {
    if (state === LoginState.READY_TO_NAVIGATE) {
      history.replace('/userSettings');
    }
  }, [state]);

  return (
      <Container maxWidth={'md'} style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        padding: '5rem 0',
      }}>
        <img
            alt={'heylo logo'}
            className={'App-logo-large'}
            src={'/heylo-small.png'}
            style={{height: 97, width: 106}}
        />

        <LoginForm
            accountHasPassword={accountHasPassword}
            onReset={onReset}
            onSignInWithLink={onSignInWithEmailLink}
            onSubmitEmail={onSubmitEmail}
            onSubmitEmailPassword={onSubmitEmailPassword}
            onSwitchToPassword={onSwitchToPassword}
            state={state}
        />
      </Container>
  );
};