import {getServerTimestamp} from '@heylo/shared/src/services/firebase/ServerTime';
import {
  CommunityImpressionHappened,
  UserImpressionHappened,
  UserInteractionHappened,
} from './Reducer';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';

export const IncrementCommunityImpression = (communityId: string, event: string)
    : ThunkReturnType<void> => async dispatch => {
  const timestamp = await getServerTimestamp();
  dispatch(CommunityImpressionHappened({communityId, event, timestamp}));
};

export const IncrementUserImpression = (event: string)
    : ThunkReturnType<void> => async dispatch => {
  const timestamp = await getServerTimestamp();
  dispatch(UserImpressionHappened({event, timestamp}));
};

export const IncrementUserInteraction = (event: string)
    : ThunkReturnType<void> => async dispatch => {
  const timestamp = await getServerTimestamp();
  dispatch(UserInteractionHappened({event, timestamp}));
};
