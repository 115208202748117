import React, {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {AuthFirebase} from '@heylo/shared/src/features/auth/Firebase';
import {UserSettingsFirebase} from '@heylo/shared/src/features/userSettings/Firebase';
import {PureEmailVerificationResult} from './PureEmailVerificationResult';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  selectActiveUserId,
  selectEmailBeingVerified,
  selectEmailVerificationFullName,
  selectSignInWithEmailLink,
} from '@heylo/shared/src/features/auth/Selectors';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {
  EmailVerificationContext,
  SignInWithEmailReset,
} from '@heylo/shared/src/features/auth/Slice';

type Props = {
  context: EmailVerificationContext,
  onReset?: () => void,
  onSignIn: (email: string, signInLink: string) => Promise<string>,
  onSuccess?: () => void,
};

export const EmailVerificationResult = (props: Props) => {
  const {
    context,
    onReset: parentOnReset,
    onSignIn: parentOnSignIn,
    onSuccess: parentOnSuccess,
  } = props;

  const dispatch = useDispatch();
  const logger = useLoggingService();

  const {
    emailBeingVerified,
    fullName,
    signInLink,
    userId,
  } = useSelector((state: RootState) => ({
    emailBeingVerified: selectEmailBeingVerified(state),
    fullName: selectEmailVerificationFullName(state),
    signInLink: selectSignInWithEmailLink(state),
    userId: selectActiveUserId(state),
  }), shallowEqual);

  const onVerifyEmail = useCallback((): Promise<void> => {
    // @ts-ignore
    return dispatch(AuthFirebase.VerifyEmailDomain(emailBeingVerified, signInLink));
  }, [emailBeingVerified, signInLink]);

  const onSignIn = useCallback((): Promise<void> => {
    if (userId) {
      return Promise.resolve();
    }
    return parentOnSignIn(emailBeingVerified, signInLink)
        .then((userId: string): Promise<any> => {
          if (context === EmailVerificationContext.LOGIN) {
            return Promise.resolve();
          }
          // @ts-ignore
          return dispatch(
              UserSettingsFirebase.CreateUserSettings(userId, {
                email: emailBeingVerified,
                fullName,
              }));
        })
        .then(() => {
          Promise.resolve()
        });
  }, [userId, emailBeingVerified, signInLink, fullName]);


  const onReset = () => {
    parentOnReset && parentOnReset();
    dispatch(SignInWithEmailReset());
  }
  const onSuccess = () => {
    parentOnSuccess && parentOnSuccess();
    dispatch(SignInWithEmailReset());
  };

  return <PureEmailVerificationResult
      activeUserId={userId}
      email={emailBeingVerified}
      logger={logger}
      onReset={onReset}
      onSuccess={onSuccess}
      onSignIn={onSignIn}
      onVerifyEmail={onVerifyEmail}
      signInLink={signInLink}
  />;
}