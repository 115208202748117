import {createSelector} from 'reselect';
import {
  SelectActiveCommunityId,
  SelectActiveCommunityIsSupportCommunity,
  SelectActiveUserBelongsToSupportCommunity,
} from '@heylo/shared/src/features/communities/Selectors';
import {TopicsScreenLastViewedTimestampSelector} from '@heylo/shared/src/features/userEvents/Selectors';
import {
  IsThreadTypeCommunityBased,
  THREAD_TYPE_ANNOUNCEMENT,
  THREAD_TYPE_COMMUNITY,
  THREAD_TYPE_DIRECT,
  THREAD_TYPE_EVENT,
  THREAD_TYPE_GROUP,
  THREAD_TYPE_SUPPORT,
} from '@heylo/shared/src/types/ThreadTypes';
import {
  CommunityMember,
  Message,
  SharedPhoto,
  Thread,
  ThreadUpdate,
  UserProfile,
} from '@heylo/shared/src/types/firebase-types';
import {
  ActiveThreadIdSelector,
  AllThreadJoinStatesSelector,
  AllThreadLastActivityTimestampsSelector,
  AllThreadLastReadTimestampsSelector,
  AllThreadLastUserMessageTimestampsSelector,
  AllThreadMembersSelector,
  AllThreadMessagesSelector,
  AllThreadsSelector,
  AllThreadUpdatesSelector,
  selectRecentThreadContributorUserIds,
  selectThreadNumUnreadMessages,
} from './SimpleSelectors';
import _ from 'lodash';
import {
  GetBestUserProfilePhotoUrl,
  SelectUserProfiles,
} from '@heylo/shared/src/features/userProfiles/Selectors';
import {
  SelectActiveCommunityUserProfiles,
  SelectSupportCommunityMembers,
} from '@heylo/shared/src/features/communityMembers/Selectors';
import {
  ActiveCommunityPhotosSelector,
  ActivePrivateChatPhotosSelector,
} from '@heylo/shared/src/features/photos/Selectors';
import {getLastActivityString} from '@heylo/shared/src/util/Time';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {DeepReadonly} from 'utility-types';
import {threadNewMessageNotificationsSelector} from '@heylo/shared/src/features/notifications/Selectors';
import {SortThreadMessages} from '@heylo/shared/src/features/threads/Slice';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

export const ActiveThreadSelector = createSelector(
    [AllThreadsSelector, ActiveThreadIdSelector],
    (allThreads, activeThreadId)
        : Readonly<Thread> => {
      return allThreads[activeThreadId] || {};
    });

export const ActiveThreadLastReadTimestampSelector = createSelector(
    [AllThreadLastReadTimestampsSelector, ActiveThreadIdSelector],
    (lastReadTimestamps, activeThreadId) => {
      return lastReadTimestamps[activeThreadId] || 0;
    });

export const ActiveThreadMembersSelector = createSelector(
    [AllThreadMembersSelector, ActiveThreadIdSelector],
    (allThreadMembers, activeThreadId) => {
      return allThreadMembers[activeThreadId] ?? {};
    });

export const ActiveThreadMessagesSelector = createSelector(
    [AllThreadMessagesSelector, ActiveThreadIdSelector],
    (allMessages, activeThreadId) => {
      return allMessages[activeThreadId] || {};
    });

export const selectSortedActiveThreadMessages = createSelector(
    [ActiveThreadMessagesSelector],
    (threadMessages): ReadonlyArray<[string, Message]> => {
      return SortThreadMessages(threadMessages);
    });

export const ActiveThreadPhotosSelector = createSelector(
    [ActiveThreadSelector, selectSortedActiveThreadMessages, ActiveCommunityPhotosSelector, ActivePrivateChatPhotosSelector],
    (activeThread,
     sortedMessages,
     communityPhotos,
     privateThreadPhotos)
        : ReadonlyArray<SharedPhoto> => {
      const threadType = activeThread.threadType || THREAD_TYPE_COMMUNITY;
      const isCommunityThread = IsThreadTypeCommunityBased(threadType);
      const imageMessages = sortedMessages.filter(([_, message]) => message?.image);
      return imageMessages.map(([messageId, message]) => {
        // NB: major hackery ensues! In order to get images to render
        // correctly in support chats, we have to manually construct
        // SharedPhoto records. This is because support chats are a hybrid
        // of private and community chats, and there is no valid collection
        // to store their photos in for now. Ultimately, support chat
        // photos can be safely read from /chatPhotos once support chat has
        // been migrated to be fully modeled as private chats. Support chat
        // images are already being writtten to /chatPhotos (2019-12-27).
        if (threadType === THREAD_TYPE_SUPPORT) {
          const {
            createdAt: creationTimestamp,
            image: url,
            imageHeight: dimensionsHeight,
            imageWidth: dimensionsWidth,
            ownerId,
          } = message;
          const photo: SharedPhoto = {
            creationTimestamp,
            imageHighRes: {
              dimensionsHeight,
              dimensionsWidth,
              url,
            },
            photoId: messageId,
            ownerId,
          };
          return photo;
        }
        return (isCommunityThread ? communityPhotos[messageId] : privateThreadPhotos[messageId])
            || {};
      });
    });

export const ActiveThreadMergedMessagesAndUpdatesSelector = createSelector(
    [ActiveThreadSelector, AllThreadUpdatesSelector, selectSortedActiveThreadMessages],
    (activeThread, threadUpdates, sortedThreadMessages)
        : ReadonlyArray<Message & ThreadUpdate> => {
      const {threadId = '', threadType = ''} = activeThread;
      const suppressThreadUpdates = (threadType === THREAD_TYPE_DIRECT || threadType === THREAD_TYPE_SUPPORT);
      const updates: ThreadUpdate[] = suppressThreadUpdates ? [] : Object.values(threadUpdates[threadId] || {});
      const messages = sortedThreadMessages.map(([_, message]) => message);
      const combined = messages.concat(updates).filter(item => item);
      return _.sortBy(combined,
          // @ts-ignore
          item => item?.createdAt || item?.updateTimestamp || 0)
          .reverse();
    });

export const SupportCommunityThreadsSelector = createSelector(
    [AllThreadsSelector, selectActiveUserId, AllThreadMembersSelector, SelectActiveUserBelongsToSupportCommunity, SelectSupportCommunityMembers, SelectUserProfiles],
    (allThreads, currentUserId, threadMembers, userBelongsToSupportCommunity, supportCommunityMembers, userProfiles)
        : ReadonlyArray<Thread> => {
      const supportThreadName = (threadMemberIds: ReadonlyArray<string>,
                                 supportCommunityMembers: DeepReadonly<{ [userId: string]: CommunityMember }>,
                                 userProfiles: DeepReadonly<{ [userId: string]: UserProfile }>) => {
        const memberId = threadMemberIds.find(userId => !supportCommunityMembers[userId]);
        if (memberId) {
          return userProfiles[memberId]?.fullName || 'Unknown user';
        }
        return null;
      };

      return Object.values(allThreads)
          .filter(thread => thread.communityId === 'support')
          .map(thread => {
            if (typeof userBelongsToSupportCommunity === 'boolean' && !userBelongsToSupportCommunity) {
              return thread;
            }
            const threadId = thread.threadId || '';
            const threadMemberIds = Object.keys(threadMembers[threadId] || {});
            const nameOverride = supportThreadName(threadMemberIds, supportCommunityMembers, userProfiles);
            if (nameOverride) {
              return {
                ...thread,
                name: 'Support: ' + nameOverride,
              };
            }
            return thread;
          });
    });

export const ActiveCommunityAnnouncementThreadSelector = createSelector(
    [AllThreadsSelector, SelectActiveCommunityId],
    (allThreads, currentCommunityId)
        : Readonly<Thread> => {
      return Object.values(allThreads || {})
              .find(thread => thread.communityId === currentCommunityId && thread.threadType === THREAD_TYPE_ANNOUNCEMENT)
          || {};
    });

export const ActiveCommunityThreadsSelector = createSelector(
    [AllThreadsSelector, SelectActiveCommunityIsSupportCommunity, SelectActiveCommunityId, SupportCommunityThreadsSelector],
    (allThreads, isSupportCommunityActive, currentCommunityId, supportThreads)
        : ReadonlyArray<Thread> => {
      if (isSupportCommunityActive) {
        return supportThreads;
      }
      return Object.values(allThreads)
          .filter(thread => {
            const {communityId, threadType} = thread;
            return communityId === currentCommunityId
                && (threadType === THREAD_TYPE_COMMUNITY || threadType === THREAD_TYPE_GROUP);
          });
    });

export const ActiveCommunityTopicPhotoSelector = createSelector(
    [ActiveCommunityThreadsSelector, selectRecentThreadContributorUserIds, SelectUserProfiles],
    (threads, recentContributorUserIds, userProfiles)
        : { [threadId: string]: string[] } => {
      const allTopicPhotos: { [threadId: string]: string[] } = {};
      for (const thread of threads) {
        const {threadId = ''} = thread;
        const oneTopicPhotos: string[] = [];
        const threadImage: string = thread.heroImageUrl || '';
        if (threadImage) {
          oneTopicPhotos.push(threadImage);
        } else {
          const userIds = recentContributorUserIds[threadId] || [];
          if (userIds.length === 0) {
            continue;
          }
          const userIdSet = new Set();
          for (const userId of userIds) {
            const profilePhotoImageUrl = GetBestUserProfilePhotoUrl(userProfiles[userId], 100);
            if (profilePhotoImageUrl && !userIdSet.has(userId)) {
              userIdSet.add(userId);
              oneTopicPhotos.push(profilePhotoImageUrl);
            }
            if (oneTopicPhotos.length >= 3) {
              break;
            }
          }
        }
        allTopicPhotos[threadId] = oneTopicPhotos.reverse();
      }
      return allTopicPhotos;
    });

const SortedActiveCommunityThreadsSelector = createSelector(
    [ActiveCommunityThreadsSelector, AllThreadLastActivityTimestampsSelector],
    (threads, lastActivityTimestamps)
        : ReadonlyArray<Thread> => {
      return _.sortBy(threads,
          thread =>
              Math.max(thread.creationTimestamp || 0,
                  lastActivityTimestamps[thread.threadId || ''] || 0))
          .reverse();
    });

const JoinedThreadsSelector = createSelector(
    [SortedActiveCommunityThreadsSelector, threadNewMessageNotificationsSelector, AllThreadJoinStatesSelector],
    (threads, notificationSettingsMap, threadJoinStates)
        : ReadonlyArray<Thread> => {
      return threads
          .filter(thread => thread.threadType === THREAD_TYPE_GROUP || threadJoinStates[thread.threadId || '']);
    },
);

const threadIsInactive = (thread: Thread, lastActivityTimestamp: number, numUnreadMessages: number): boolean => {
  const timestamp = Math.max(lastActivityTimestamp, thread.creationTimestamp || 0);
  return getLastActivityString(timestamp) === '' && numUnreadMessages === 0;
};

export const JoinedActiveThreadsSelector = createSelector(
    [JoinedThreadsSelector, AllThreadLastActivityTimestampsSelector, selectThreadNumUnreadMessages],
    (joinedThreads, lastActivityTimestamps, numUnreadMessages)
        : ReadonlyArray<Thread> => {
      let count = 0;
      return joinedThreads
          .filter((thread, threadId) => {
            count++;
            // Show the first few joined threads regardless of activity level,
            // and then any other joined threads that are considered active.
            if (count <= 5) {
              return true;
            }
            return !threadIsInactive(thread, lastActivityTimestamps[threadId] || 0, numUnreadMessages[threadId] || 0);
          });
    });

export const JoinedInactiveThreadsSelector = createSelector(
    [JoinedThreadsSelector, JoinedActiveThreadsSelector],
    (allJoinedThreads, visibleJoinedThreads)
        : ReadonlyArray<Thread> => {
      const activeThreadIds = new Set<string>(visibleJoinedThreads.map(thread => thread.threadId || ''));
      return allJoinedThreads.filter(thread => !activeThreadIds.has(thread.threadId || ''));
    });

export const NotJoinedThreadsSelector = createSelector(
    [SortedActiveCommunityThreadsSelector, JoinedThreadsSelector],
    (threads, joinedThreads)
        : ReadonlyArray<Thread> => {
      const joinedThreadIds = new Set(joinedThreads.map(thread => thread.threadId || ''));
      return threads.filter(thread => !joinedThreadIds.has(thread.threadId || ''));
    });

export const NewNotJoinedTopicsSelector = createSelector(
    [TopicsScreenLastViewedTimestampSelector, NotJoinedThreadsSelector],
    (lastViewedTimestamp, notJoinedTopics)
        : ReadonlyArray<Thread> => {
      return notJoinedTopics.filter(thread => (thread.creationTimestamp || 0) > lastViewedTimestamp);
    });

export const ActiveEventThreadsSelector = createSelector(
    [AllThreadsSelector, SelectActiveCommunityId],
    (allThreads, currentCommunityId)
        : ReadonlyArray<Thread> => {
      return Object.values(allThreads)
          .filter(thread => {
            const {communityId = '', threadType} = thread;
            return communityId === currentCommunityId
                // TODO: remove 'eventChat' after 2020-01-01
                && (threadType === 'eventChat' || threadType === THREAD_TYPE_EVENT);
          });
    });

export const ActiveUserIsInterestedInActiveThread = createSelector(
    [ActiveThreadIdSelector, AllThreadJoinStatesSelector],
    (activeThreadId, joinedThreadStates) => {
      return joinedThreadStates[activeThreadId];
    });

export function threadJoinTimestampForUserSelector(state: RootState, threadId: string, userId: string) {
  const member = state.threads.FIELD_THREAD_MEMBERS[threadId]?.[userId];
  return member ? member.joinTimestamp : 0;
}

export function isEventChatSelector(state: RootState, threadId: string): boolean {
  return state.threads.FIELD_THREADS[threadId]?.threadType === 'eventChat';
}

export function isSupportChatSelector(state: RootState, threadId: string): boolean {
  return state.threads.FIELD_THREADS[threadId]?.threadType === 'support';
}

export function threadHeroImageUrlSelector(state: RootState, threadId: string): string {
  return state.threads.FIELD_THREADS[threadId].heroImageUrl || '';
};

export function threadNameSelector(state: RootState, threadId: string): string {
  const thread = state.threads.FIELD_THREADS[threadId] || {};
  const {name, threadType} = thread;
  if (threadType === THREAD_TYPE_SUPPORT) {
    return 'Heylo Support';
  }
  return name || 'Unnamed chat';
}

export const TotalUnreadTopicsSelector = createSelector(
    [JoinedThreadsSelector, NewNotJoinedTopicsSelector, ActiveCommunityAnnouncementThreadSelector, selectThreadNumUnreadMessages],
    (
        joinedThreads,
        newNotJoinedThreads,
        announcementThread,
        threadNumUnreadMessages,
    ) => {
      const threadIds = new Set<string>();
      for (const thread of joinedThreads) {
        const {threadId = ''} = thread;
        if (threadId && threadNumUnreadMessages[threadId] > 0) {
          threadIds.add(threadId);
        }
      }
      for (const thread of newNotJoinedThreads) {
        const {threadId = ''} = thread;
        if (threadId) {
          threadIds.add(threadId);
        }
      }
      const {threadId: announcementThreadId = ''} = announcementThread;
      if (announcementThreadId && threadNumUnreadMessages[announcementThreadId] > 0) {
        threadIds.add(announcementThreadId);
      }
      return Math.min(99, threadIds.size);
    });

export const ActiveThreadMentionsAudience = createSelector(
    [ActiveThreadSelector, SelectActiveCommunityUserProfiles, ActiveThreadMembersSelector, SelectUserProfiles],
    (thread,
     allCommunityMembers,
     threadMembers,
     allUserProfiles)
        : UserProfile[] => {
      let userProfiles: UserProfile[];
      if (IsThreadTypeCommunityBased(thread.threadType)) {
        userProfiles = Object.values(allCommunityMembers)
      } else {
        userProfiles = Object.values(_.mapValues(threadMembers, (_, userId) => allUserProfiles[userId] || {}));
      }
      return _.sortBy(userProfiles.filter(userProfile => userProfile.fullName),
          userProfile => userProfile.fullName!.toLocaleLowerCase());
    });

export const AllDirectChatsSelector = createSelector(
    [AllThreadsSelector],
    (threads)
        : ReadonlyArray<Thread> => {
      return Object.values(threads)
          .filter(thread => thread.threadType === THREAD_TYPE_DIRECT);
    });

export const AllGroupChatsSelector = createSelector([AllThreadsSelector],
    (threads): ReadonlyArray<Thread> => {
      return Object.values(threads)
          .filter(thread => thread.threadType === THREAD_TYPE_GROUP);
    });

export const AllSupportChatsSelector = createSelector([AllThreadsSelector],
    (threads): ReadonlyArray<Thread> => {
      return Object.values(threads)
          .filter(thread => thread.threadType === THREAD_TYPE_SUPPORT);
    });

export const MostRecentDirectChatMessageTimestamp = createSelector(
    [AllDirectChatsSelector, AllThreadLastActivityTimestampsSelector],
    (threads, lastActivityTimestamps) => {
      return threads.reduce<number>(
          (maxTimestamp, thread) => Math.max(
              lastActivityTimestamps[thread.threadId || ''] || 0,
              maxTimestamp),
          0);
    });

export const MostRecentSupportChatMessageTimestamp = createSelector(
    [AllSupportChatsSelector, AllThreadLastActivityTimestampsSelector],
    (threads, lastActivityTimestamps) => {
      return threads.reduce<number>(
          (maxTimestamp, thread) => Math.max(
              lastActivityTimestamps[thread.threadId || ''] || 0,
              maxTimestamp),
          0);
    });

export const SortedSupportChatsSelector = createSelector(
    [AllSupportChatsSelector, AllThreadLastUserMessageTimestampsSelector],
    (supportThreads, threadLastUserMessageTimestamps)
        : ReadonlyArray<Thread> => {
      return _.sortBy(supportThreads,
          thread => threadLastUserMessageTimestamps[thread.threadId || ''] || 0)
          .reverse();
    });

export const SortedPrivateChatsSelector = createSelector(
    [
      AllDirectChatsSelector,
      AllSupportChatsSelector,
      SelectActiveUserBelongsToSupportCommunity,
      AllThreadLastReadTimestampsSelector,
      AllThreadLastActivityTimestampsSelector,
      selectThreadNumUnreadMessages,
    ],
    (
        directChats,
        supportChats,
        activeUserBelongsToSupportCommunity,
        threadLastReadTimestamps,
        threadLastActivityTimestamps,
        threadNumUnreadMessages,
    ): ReadonlyArray<Thread> => {
      const allPrivateChats = directChats.concat(activeUserBelongsToSupportCommunity === false
          ? supportChats
          : [])
          .filter(thread => {
            const {threadId = '', threadType} = thread;
            if (threadType === THREAD_TYPE_DIRECT) {
              // Only show private threads that have been read before or have an
              // unread message.
              return (threadNumUnreadMessages[threadId] > 0 ||
                  threadLastReadTimestamps[threadId] > 0);
            }
            return true;
          })
          .map(thread => {
            const {name, threadType} = thread;
            if (threadType === THREAD_TYPE_SUPPORT) {
              thread.name = 'Heylo Support';
            }
            thread.name = name || 'Unnamed chat';
            return thread;
          });
      return _.sortBy(allPrivateChats,
          thread => threadLastActivityTimestamps[thread.threadId || ''] || 0)
          .reverse();
    });
