import React, {useEffect, useState} from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import {Modal, Portal} from 'react-native-paper';
import {Dimensions} from 'react-native';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {AddThreadMembers, AddThreadMembersState} from './AddThreadMembers';
import {BottomStickyButton} from '../../ui/button/BottomStickyButton';
import {MobileHeader} from '../../ui/header/MobileHeader';
import {CloseButton} from '../../ui/header/CloseButton';


export const AddThreadMembersModal = ({onClose, open}: {
  onClose: () => void,
  open: boolean,
}) => {
  const theme = useTheme();

  const [state, setState] = useState(AddThreadMembersState.UNEDITED);

  useEffect(() => {
    if (state === AddThreadMembersState.SUBMIT_SUCCESS) {
      onClose();
    }
  }, [state]);

  const {height} = Dimensions.get('window');

  return (
      <Portal>
        <Modal
            contentContainerStyle={{
              alignSelf: 'center',
              backgroundColor: 'white',
              borderRadius: theme.shape.borderRadius,
              maxWidth: 500,
              minWidth: 400,
              zIndex: 1300,
            }}
            onDismiss={onClose}
            visible={open}
        >
          <MobileHeader
              left={<CloseButton onPress={onClose}/>}
              title={'Add members'}
          />
          <PerfectScrollbar style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: height * 0.75,
            maxHeight: height * 0.75,
          }}>
            <AddThreadMembers
                setState={setState}
                state={state}
            />
            <BottomStickyButton
                busy={state === AddThreadMembersState.SUBMIT_PENDING}
                label={'add members'}
                onPress={() => setState(AddThreadMembersState.SUBMIT_START)}
                style={{alignSelf: 'center', width: '50%'}}
                visible={state === AddThreadMembersState.EDITED}
            />
          </PerfectScrollbar>
        </Modal>
      </Portal>
  );
};