import React, {CSSProperties, useEffect, useRef} from 'react';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {Animated} from 'react-native';
import {HeyloButton} from './HeyloButton';

export const BottomStickyButton = (
    {
      busy = false,
      label,
      onPress,
      style = {},
      visible,
    }: {
      busy?: boolean,
      label: string,
      onPress: () => void,
      style?: CSSProperties
      visible: boolean,
    }) => {

  const animatedHeight = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedHeight, {
      toValue: visible ? 40 : 0,
      duration: 400,
      useNativeDriver: false,
    }).start();
  }, [visible]);

  return (
      <div style={{
        bottom: StyleConstants.SPACING,
        display: visible ? 'block' : 'none',
        position: 'sticky',
        ...style,
      }}>
        <Animated.View
            style={{
              height: animatedHeight,
              marginTop: StyleConstants.SPACING,
            }}>
          <HeyloButton
              busy={busy}
              label={label}
              onPress={onPress}
              style={{elevation: 10}}
          />
        </Animated.View>
      </div>
  );
};