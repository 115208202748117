import {Dimensions, Platform} from 'react-native';

const {width} = Dimensions.get('window');

export const StyleVars = {
  Colors: {
    Black: 'hsl(0, 0%, 0%)',
    GreyDarkest: 'hsl(0, 0%, 20%)',
    GreyDark: 'hsl(0, 0%, 30%)',
    GreyMedium: 'hsl(0, 0%, 50%)',
    GreyLight: 'hsl(0, 0%, 70%)',
    GreyLighter: 'hsl(0, 0%, 85%)',
    GreyLightest: 'hsl(0, 0%, 95%)',
    Red: 'hsl(0, 100%, 50%)',
    Purple: 'hsl(258, 76%, 61%)',  // #7D50E7
    PurpleLight: 'hsl(258, 76%, 78%)',
    PurpleLightest: 'hsl(258, 76%, 94%)',
    Seafoam: 'hsl(153, 60%, 49%)',  // #32C884
    SeafoamLight: 'hsl(153, 60%, 90%)',
    SeafoamLightest: 'hsl(153, 60%, 97%)',
    White: 'hsl(0, 100%, 100%)',
  },
  Font: Platform.OS === 'ios' ? 'System' : 'Roboto',
  Opacity: {
    Light: Platform.OS === 'ios' ? .72 : .48,
    Default: .87,
  },
  Dimensions: {
    BottomTabBarHeight: 50,
    HeaderHeight: 66,
    HeroImageAspectRatio: 1,
    ScreenMargins: width * 0.04,
  },
};