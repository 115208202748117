import amplitude, {AmplitudeClient} from 'amplitude-js';
import {
  ILogger,
  PropertiesMap,
} from '@heylo/shared/src/services/logging/Logger';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';

// Base class. Implements most common logic.
export abstract class AmplitudeLogger implements ILogger {
  protected client: AmplitudeClient;
  protected loggingKey: string | null = null;
  protected name = '';
  protected superProperties: PropertiesMap = {};
  protected userProperties: PropertiesMap = {};

  protected constructor(name: string, apiKey: string, buildVariant: string) {
    if (!name || !apiKey) {
      console.error('invalid use of AmplitudeLogger', name, apiKey);
    }
    this.client = amplitude.getInstance(name);
    this.client.init(apiKey, '', {
      batchEvents: true,
      eventUploadPeriodMillis: 10 * 1000,
    });
    this.name = name;
    this.superProperties = {
      buildVariant,
    };
  };

  logEvent(eventCode: string, params?: any) {
    if (this.loggingKey) {
      const mergedParams = Object.assign({}, this.superProperties, params || {});
      console.log(`[Analytics : ${this.name}] event ${eventCode}`, mergedParams);
      this.client.logEvent(eventCode, mergedParams);
    }
  }

  setLoggingKey(key: string | null) {
    this.loggingKey = key;
    this.client.setUserId(key);
  }

  setCurrentScreen(screenName: string) {
    this.logEvent(AnalyticsEvent.SCREEN_VIEW, {screenName});
  }

  setSuperProperties(props: PropertiesMap) {
    console.log(`[Analytics : ${this.name}] super properties`, props);
    this.superProperties = Object.assign({}, this.superProperties, props);
  }

  setUserProperties(props: PropertiesMap) {
    console.log(`[Analytics : ${this.name}] user properties`, props);
    this.userProperties = Object.assign({}, this.userProperties, props);
    const identify = new amplitude.Identify();
    for (const [key, value] of Object.entries(this.userProperties)) {
      identify.set(key, value);
    }
    this.client.identify(identify);
  }

  setOneTimeUserProperties(props: PropertiesMap) {
    console.log(`[Analytics : ${this.name}] one-time user properties`, props);
    if (!this.loggingKey) {
      console.warn('setting one-time user props before setting logging key');
    }
    const identify = new amplitude.Identify();
    for (const [key, value] of Object.entries(props)) {
      identify.setOnce(key, value);
    }
    this.client.identify(identify);
  }

}