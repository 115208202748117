import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {UserProfile} from '@heylo/shared/src/types/firebase-types';
import {DeepReadonly} from 'utility-types';
import {AppColdStart, AppReset} from '@heylo/shared/src/features/app/Actions';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import _ from 'lodash';

export type UserProfilesState = {
  FIELD_USER_PROFILES: DeepReadonly<{ [userId: string]: UserProfile }>,
  userProfilesLoaded: DeepReadonly<{ [userId: string]: boolean }>,
};

const initialState: UserProfilesState = {
  FIELD_USER_PROFILES: {},
  userProfilesLoaded: {},
};

const updateUserProfile = (params: { userId: string, profile: UserProfile }, state: Draft<UserProfilesState>) => {
  const {userId, profile} = params;
  const current = state.FIELD_USER_PROFILES[userId];
  profile.id = userId;
  if (_.isEqual(current, profile)) {
    return;
  }
  state.FIELD_USER_PROFILES[userId] = profile;
  state.userProfilesLoaded[userId] = true;
};

export const userProfilesSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    UserProfileChanged: (state, action: PayloadAction<{ userId: string, profile: UserProfile }>) => {
      updateUserProfile(action.payload, state);
    },
    UserProfileCreated: (state,  action: PayloadAction<{ userId: string, profile: UserProfile }>) => {
      updateUserProfile(action.payload, state);
    },
    UserProfileDeleted: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      delete state.FIELD_USER_PROFILES[userId];
      state.userProfilesLoaded[userId] = false;
    },
  },

  extraReducers: builder => builder
      .addCase(AppColdStart, (state) => {
        state.userProfilesLoaded = {};
      })
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState),

});

const {actions, reducer: UserProfilesReducer} = userProfilesSlice;

const {
  UserProfileChanged,
  UserProfileCreated,
  UserProfileDeleted,
} = actions;

export {
  UserProfileChanged,
  UserProfileCreated,
  UserProfileDeleted,
  UserProfilesReducer,
};