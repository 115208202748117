import React, {useEffect, useState} from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {EventSummary} from '../community/EventSummary';
import {EventDetails} from 'features/event';
import {Members} from 'features/communityMembers';
import {MemberDetails} from 'features/communityMembers/MemberDetails';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import {InstallHeylo} from 'features/install/InstallHeylo';
import {DiscoverCommunity} from 'features/marketplaces';
import {EditGroupScreen} from './EditGroupScreen';
import {EditEventScreen} from '../event/EditEventScreen';
import {Topics} from '../topics/Topics';
import {FLAG_WEB_CHAT_ENABLED} from '../../Flags';
import {ScrollView, Text, View} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {MembersOnlyRedirect} from '../navigation/MembersOnlyRedirect';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';

export const CommunityContent = React.memo(() => {
  console.count('count CommunityContent');
  const [activeMemberId, setActiveMemberId] = useState('');

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setActiveMemberId(query.get('memberId') || '');
  }, [location]);

  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));
  const rightPaneVisible = !!activeMemberId;
  const rightPaneInFullScreenMode = rightPaneVisible && matchXs;
  const text = useTextStyles();

  const closeRightPane = () => {
    history.goBack();
  };

  // All routes that need custom scrolling behavior.
  const customScrollViewScreen = useRouteMatch([
    '/topics/:communityId/:threadId',
    '/topics/:communityId',
  ]);

  const rightPanelWidth = matchXs ? '100%' : 250;

  return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        position: 'relative',
      }}>
        {!rightPaneInFullScreenMode && (
            <div style={{
              display: 'flex',
              flex: 1,
              borderRight: rightPaneVisible ? 'solid #c4c4c4 1px' : '',
              height: '100%',
              minWidth: 250,
            }}>
              {customScrollViewScreen ? (
                  <Switch>
                    {FLAG_WEB_CHAT_ENABLED && (
                        <Route path={[
                          '/topics/:communityId/:threadId',
                          '/topics/:communityId',
                        ]}>
                          <MembersOnlyRedirect>
                            <Topics/>
                          </MembersOnlyRedirect>
                        </Route>
                    )}
                  </Switch>
              ) : (
                  <React.Fragment key={location.key}>
                    {/* Fragment is used to reset scroll on every navigation */}
                    <ScrollView
                        contentContainerStyle={{minHeight: '100%'}}
                        style={{height: '100%'}}
                    >
                      <Switch>
                        <Route path={'/community/edit'}>
                          <MembersOnlyRedirect>
                            <EditGroupScreen/>
                          </MembersOnlyRedirect>
                        </Route>
                        <Route path={['/community', '/invite']}>
                          <DiscoverCommunity/>
                        </Route>
                        <Route path={`/events`}>
                          <MembersOnlyRedirect>
                            <EventSummary/>
                          </MembersOnlyRedirect>
                        </Route>
                        <Route path={[
                          '/event/add',
                          '/event/duplicate',
                          '/event/edit',
                        ]}>
                          <MembersOnlyRedirect>
                            <EditEventScreen/>
                          </MembersOnlyRedirect>
                        </Route>
                        <Route path={`/event`}>
                          <MembersOnlyRedirect>
                            <EventDetails/>
                          </MembersOnlyRedirect>
                        </Route>
                        <Route path={`/members`}>
                          <MembersOnlyRedirect>
                            <Members selectedMemberId={activeMemberId}/>
                          </MembersOnlyRedirect>
                        </Route>
                        <Route path={['/install', '/message']}>
                          <MembersOnlyRedirect>
                            <InstallHeylo/>
                          </MembersOnlyRedirect>
                        </Route>
                      </Switch>
                      {/*{!matchXs ? (*/}
                      {/*    <Footer/>*/}
                      {/*) : null}*/}
                    </ScrollView>
                  </React.Fragment>
              )}
            </div>
        )}
        {rightPaneVisible && (
            <View style={{
              flex: 1,
              minWidth: rightPanelWidth,
              maxWidth: rightPanelWidth,
              width: rightPanelWidth,
            }}>
              <ScrollView
                  contentContainerStyle={{padding: StyleConstants.SPACING}}
                  style={{height: '100%'}}
              >
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                  <Text style={text.h6}>
                    {activeMemberId && 'Profile'}
                  </Text>
                  <CloseIcon onClick={closeRightPane}/>
                </div>

                <div style={{marginTop: '2rem'}}>
                  {activeMemberId && (
                      <MemberDetails memberId={activeMemberId}/>
                  )}
                </div>
              </ScrollView>
            </View>
        )}
      </div>
  );
});