import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {AppReset} from '@heylo/shared/src/features/app/Actions';
import {
  ACTION_THREAD_STATE_PER_USER_UPDATED,
  ThreadStateLoaded,
} from '../threads/Slice';
import {ThreadStatePerUser, UserChatState} from '../../types/firebase-types';

export type NotificationState = {
  // All community chat related notification settings.
  communityChatsMentionNotifications: boolean | null,
  communityChatNewMessageNotifications: boolean | null,
  chatNewMemberNotifications: boolean | null,
  // Map from threadId to whether or not notifications are enabled.
  chatNewMemberNotificationsPerThread: { [threadId: string]: boolean },
  individualChatNewMessageNotifications: { [threadId: string]: boolean },

  // All members related notification settings.
  communityNewMembersNotifications: boolean | null,
  communityNewTopicNotifications: boolean | null,

  // All event related notification settings.
  eventDayOfReminderNotifications: boolean | null,
  eventNewNotifications: boolean | null,
  eventUpdatedNotifications: boolean | null,
  eventWeeklyReminderNotifications: boolean | null,

  // The timestamp at which we showed the iOS notification permissions dialog.
  // We only get to show this to the user once. Future attempts will be no-ops.
  fieldHaveRequestedIosNotificationPermissions: number,
  iosNotificationPermissionAlert: boolean | null,
  iosNotificationPermissionBadge: boolean | null,
  iosNotificationPermissionSound: boolean | null,
}

const initialState: NotificationState = {
  communityChatsMentionNotifications: null,
  communityChatNewMessageNotifications: null,
  chatNewMemberNotifications: null,
  chatNewMemberNotificationsPerThread: {},
  individualChatNewMessageNotifications: {},
  communityNewMembersNotifications: null,
  communityNewTopicNotifications: null,
  eventDayOfReminderNotifications: null,
  eventNewNotifications: null,
  eventUpdatedNotifications: null,
  eventWeeklyReminderNotifications: null,
  fieldHaveRequestedIosNotificationPermissions: 0,
  iosNotificationPermissionAlert: null,
  iosNotificationPermissionBadge: null,
  iosNotificationPermissionSound: null,
};

export const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {

    ACTION_SET_HAVE_REQUESTED_IOS_NOTIFICATION_PERMISSIONS: (state, action: PayloadAction<number>) => {
      const timestamp = action.payload;
      state.fieldHaveRequestedIosNotificationPermissions = timestamp;
    },

    ACTION_SET_IOS_NOTIFICATION_PERMISSIONS: (state, action: PayloadAction<{ alert: boolean, badge: boolean, sound: boolean }>) => {
      const {alert, badge, sound} = action.payload;
      state.iosNotificationPermissionAlert = alert;
      state.iosNotificationPermissionBadge = badge;
      state.iosNotificationPermissionSound = sound;
    },

    ACTION_SET_NOTIFICATION_SETTING_CHATS_NEW_MEMBERS: (state, action: PayloadAction<boolean>) => {
      const enabled = action.payload;
      state.chatNewMemberNotifications = enabled;
    },

    ACTION_SET_NOTIFICATION_SETTING_CHATS_NEW_MESSAGES: (state, action: PayloadAction<boolean>) => {
      const enabled = action.payload;
      state.communityChatNewMessageNotifications = enabled;
    },

    ACTION_SET_NOTIFICATION_SETTING_CHATS_MENTIONS: (state, action: PayloadAction<boolean>) => {
      const enabled = action.payload;
      state.communityChatsMentionNotifications = enabled;
    },

    ACTION_SET_NOTIFICATION_SETTING_COMMUNITY_NEW_MEMBERS: (state, action: PayloadAction<boolean>) => {
      const enabled = action.payload;
      state.communityNewMembersNotifications = enabled;
    },

    ACTION_SET_NOTIFICATION_SETTING_COMMUNITY_NEW_TOPICS: (state, action: PayloadAction<boolean>) => {
      const enabled = action.payload;
      state.communityNewTopicNotifications = enabled;
    },

    ACTION_SET_NOTIFICATION_SETTING_EVENTS_DAY_OF_REMINDER: (state, action: PayloadAction<boolean>) => {
      const enabled = action.payload;
      state.eventDayOfReminderNotifications = enabled;
    },

    ACTION_SET_NOTIFICATION_SETTING_EVENTS_NEW: (state, action: PayloadAction<boolean>) => {
      const enabled = action.payload;
      state.eventNewNotifications = enabled;
    },

    ACTION_SET_NOTIFICATION_SETTING_EVENTS_UPDATED: (state, action: PayloadAction<boolean>) => {
      const enabled = action.payload;
      state.eventUpdatedNotifications = enabled;
    },

    ACTION_SET_NOTIFICATION_SETTING_EVENTS_WEEKLY_REMINDER: (state, action: PayloadAction<boolean>) => {
      const enabled = action.payload;
      state.eventWeeklyReminderNotifications = enabled;
    },
  },

  extraReducers: builder => builder
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState)
      .addCase(ThreadStateLoaded, (state, action: PayloadAction<{ threadId: string, chatState: UserChatState }>) => {
        const {threadId, chatState} = action.payload;
        const {notificationSettings} = chatState;
        if (notificationSettings) {
          if (typeof notificationSettings.newMessages?.enabled === 'boolean') {
            state.individualChatNewMessageNotifications[threadId] = notificationSettings.newMessages.enabled;
          }
          if (typeof notificationSettings.newMembers?.enabled === 'boolean') {
            state.chatNewMemberNotificationsPerThread[threadId] = notificationSettings.newMembers.enabled;
          }
        }
      })
      .addCase(ACTION_THREAD_STATE_PER_USER_UPDATED, (state, action: PayloadAction<{ threadId: string, threadState: ThreadStatePerUser }>) => {
        const {threadId, threadState} = action.payload;
        const {notificationSettings} = threadState;
        if (notificationSettings) {
          if (typeof notificationSettings.newMessages?.enabled === 'boolean') {
            state.individualChatNewMessageNotifications[threadId] = notificationSettings.newMessages.enabled;
          }
          if (typeof notificationSettings.newMembers?.enabled === 'boolean') {
            state.chatNewMemberNotificationsPerThread[threadId] = notificationSettings.newMembers.enabled;
          }
        }
      })
  ,

});

const {actions, reducer: NotificationsReducer} = slice;

const {
  ACTION_SET_HAVE_REQUESTED_IOS_NOTIFICATION_PERMISSIONS,
  ACTION_SET_IOS_NOTIFICATION_PERMISSIONS,
  ACTION_SET_NOTIFICATION_SETTING_CHATS_NEW_MEMBERS,
  ACTION_SET_NOTIFICATION_SETTING_CHATS_NEW_MESSAGES,
  ACTION_SET_NOTIFICATION_SETTING_CHATS_MENTIONS,
  ACTION_SET_NOTIFICATION_SETTING_COMMUNITY_NEW_MEMBERS,
  ACTION_SET_NOTIFICATION_SETTING_COMMUNITY_NEW_TOPICS,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_DAY_OF_REMINDER,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_NEW,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_UPDATED,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_WEEKLY_REMINDER,
} = actions;

export {
  ACTION_SET_HAVE_REQUESTED_IOS_NOTIFICATION_PERMISSIONS,
  ACTION_SET_IOS_NOTIFICATION_PERMISSIONS,
  ACTION_SET_NOTIFICATION_SETTING_CHATS_NEW_MEMBERS,
  ACTION_SET_NOTIFICATION_SETTING_CHATS_NEW_MESSAGES,
  ACTION_SET_NOTIFICATION_SETTING_CHATS_MENTIONS,
  ACTION_SET_NOTIFICATION_SETTING_COMMUNITY_NEW_MEMBERS,
  ACTION_SET_NOTIFICATION_SETTING_COMMUNITY_NEW_TOPICS,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_DAY_OF_REMINDER,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_NEW,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_UPDATED,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_WEEKLY_REMINDER,
  NotificationsReducer,
};