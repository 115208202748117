import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import {HeyloImage} from '@heylo/components/src/ui/image/HeyloImage';
import {ImageUploadMetadata} from '@heylo/shared/src/services/image/ImageUploader';
import {HeyloButton} from '../../ui/button/HeyloButton';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {BlackoutModal} from '../../ui/modal/BlackoutModal';

type Props = {
  closeModal: () => void,
  imageInfo: ImageUploadMetadata,
  onUploadPress: () => void,
  visible: boolean,
}

export const ImageUploadModal = React.memo((props: Props) => {
  const {
    closeModal,
    imageInfo,
    onUploadPress,
    visible,
  } = props;

  const {localUri, height, width} = imageInfo;
  return (
      <BlackoutModal visible={visible} onDismiss={closeModal}>
        <View style={styles.imageContainer}>
          <HeyloImage
              resizeMode={'contain'}
              style={{
                height: height,
                maxHeight: '90%',
                maxWidth: '100%',
                width: width,
              }}
              uri={localUri || ''}
          />
        </View>
        <View style={{
          flexDirection: 'row',
          justifyContent: 'center',
          paddingBottom: StyleConstants.SPACING,
        }}>
          <HeyloButton
              mode={'outlined'}
              label={'Cancel'}
              onPress={closeModal}
              width={'40%'}
          />
          <View style={{width: StyleConstants.SPACING}}/>
          <HeyloButton
              label={'Send'}
              onPress={onUploadPress}
              width={'40%'}
          />
        </View>
      </BlackoutModal>
  );
});

const styles = StyleSheet.create({
  imageContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginTop: StyleConstants.SPACING,
  },
});