import React from 'react';
import {Colors} from 'styles/RootStyles';
import {Link, Route, Switch, useLocation} from 'react-router-dom';
import {HeyloText} from 'lib/materialUi';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {NAV_ITEMS} from 'features/TopNav/NavItems';
import {CommunityHeader, Variant} from '../navigation/CommunityHeader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import {shallowEqual, useSelector} from 'react-redux';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {selectHasHeyloMobileApp} from '@heylo/shared/src/features/userDevices/Selectors';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  EventsBottomTabBadgeCountSelector,
  MembersBottomTabBadgeCountSelector,
} from '@heylo/shared/src/features/navigation/Selectors';
import {TotalUnreadTopicsSelector} from '@heylo/shared/src/features/threads/Selectors';
import {GreenBadge} from '@heylo/components/src/ui/badge/GreenBadge';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

const useStyles = makeStyles(theme => ({
  containerTopTab: {
    alignItems: 'center',
    color: '#000',
    cursor: 'pointer',
    display: 'flex',
    margin: '0 2vw',
    textDecoration: 'none',
  },
  root: {
    backgroundColor: 'white',
    borderBottom: 'solid #c4c4c4 1px',
    display: 'flex',
    justifyContent: 'center',
    padding: '1.8rem 0',
  },
}));

export const TopNav = () => {
  const location = useLocation();
  const logger = useLoggingService();

  const {
    badgeEvents,
    badgeMembers,
    badgeTopics,
    communityId,
    hasMobileApp,
  } = useSelector((state: RootState) => ({
    badgeEvents: EventsBottomTabBadgeCountSelector(state),
    badgeMembers: MembersBottomTabBadgeCountSelector(state),
    badgeTopics: TotalUnreadTopicsSelector(state),
    communityId: SelectActiveCommunityId(state),
    hasMobileApp: selectHasHeyloMobileApp(state),
  }), shallowEqual);

  const badges: { [key: string]: number } = {
    ['events']: badgeEvents,
    ['members']: badgeMembers,
    ['topics']: badgeTopics,
  };

  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  return (
      <div>
        {!matchXs && (
            <nav className={classes.root}>
              {NAV_ITEMS.map((navItem, index) => {
                if (!navItem) {
                  return;
                }
                const {key, routeMatch, Icon, label, to} = navItem;
                if (hasMobileApp && key === 'app') {
                  return;
                }
                const matches = location.pathname.startsWith(routeMatch);
                const badgeCount = badges[key] || 0;
                return (
                    <Link
                        className={classes.containerTopTab}
                        data-testid={`nav-${key}`}
                        key={`topNav${index}`}
                        onClick={() => logger.logEvent(AnalyticsEvent.NAV_BOTTOM_TAB_PRESSED, {tab: key})}
                        to={`${to}${communityId}`}
                    >
                      <Icon style={{
                        color: matches ? Colors.Secondary : '#000',
                        marginRight: '0.3vw',
                      }}/>
                      <HeyloText
                          color={matches ? 'secondary' : 'inherit'}
                          variant={'h6'}
                      >
                        {label}
                      </HeyloText>
                      {badgeCount > 0 ? (
                          <GreenBadge
                              containerStyle={{marginLeft: StyleConstants.SPACING / 4}}
                              value={badgeCount}
                          />
                      ) : null}
                    </Link>
                );
              })}
            </nav>
        )}

        <Switch>
          <Route path={'/community/edit'}>
            <CommunityHeader variant={Variant.EDIT_GROUP_INFO}/>
          </Route>
          <Route path={'/community'}>
            <CommunityHeader variant={Variant.GROUP_INFO}/>
          </Route>
          <Route path={'/event/add'}>
            <CommunityHeader variant={Variant.ADD_EVENT}/>
          </Route>
          <Route path={'/event/duplicate'}>
            <CommunityHeader variant={Variant.DUPLICATE_EVENT}/>
          </Route>
          <Route path={'/event/edit'}>
            <CommunityHeader variant={Variant.EDIT_EVENT}/>
          </Route>
          <Route path={'/event'}>
            <CommunityHeader variant={Variant.EVENT_DETAILS}/>
          </Route>
          <Route path={'/events'}>
            <CommunityHeader variant={Variant.EVENTS}/>
          </Route>
          <Route path={'/install'}>
            <CommunityHeader variant={Variant.GET_THE_APP}/>
          </Route>
          <Route path={'/members'}>
            <CommunityHeader variant={Variant.MEMBERS}/>
          </Route>
          <Route path={'/topics'}>
            <CommunityHeader variant={Variant.TOPICS}/>
          </Route>
        </Switch>
      </div>
  );
};

