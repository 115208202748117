import Link from '@material-ui/core/Link';

import React from 'react';
import {HeyloText} from './HeyloText';

type Props = {
  children: string | any | any[],
  color?: 'error' | 'primary' | 'secondary' | 'inherit',
  onClick?: () => void,
  variant: 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2',
};

export const HeyloLink = (props: Props) => {
  const {
    children,
    color = 'secondary',
    onClick,
    variant,
  } = props;

  return (
      <span onClick={onClick}
            style={{
              cursor: 'pointer',
              display: 'inline-block',
              minWidth: 0,
              textDecoration: 'none',
            }}>
        <HeyloText color={color}
                   variant={variant}
                   style={{fontWeight: 'bold'}}>
          {children}
        </HeyloText>
      </span>
  );
};