import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Dimensions,
  Keyboard,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {MessageContainer} from '@heylo/components/src/features/chat/MessageContainer';
import {MessageInput} from '@heylo/components/src/features/chat/MessageInput';
import {ThreadUpdateText} from './ThreadUpdateText';
import {LoadingContentIndicator} from '@heylo/components/src/features/loading/LoadingContentIndicator';
import {
  Mention,
  Message,
  ThreadUpdate as ThreadUpdateType,
} from '@heylo/shared/src/types/firebase-types';
import {
  IsPrivateThreadType,
  IsThreadTypeCommunityBased,
  THREAD_TYPE_ANNOUNCEMENT,
  THREAD_TYPE_DIRECT,
  THREAD_TYPE_GROUP,
  THREAD_TYPE_SUPPORT,
} from '@heylo/shared/src/types/ThreadTypes';
import {ActiveThreadIdSelector} from '@heylo/shared/src/features/threads/SimpleSelectors';
import {
  ActiveThreadLastReadTimestampSelector,
  ActiveThreadMessagesSelector,
  ActiveThreadPhotosSelector,
  ActiveUserIsInterestedInActiveThread,
} from '@heylo/shared/src/features/threads/Selectors';
import {ACTION_DELETE_MESSAGE} from '@heylo/shared/src/features/threads/Slice';
import {
  AttachListenersForOnePrivateChat,
  AttachThreadListeners,
  FirebaseRemoveMessage,
} from '@heylo/shared/src/features/threads/Firebase';
import {useChatScreenState} from '@heylo/shared/src/features/threads/useChatScreenState';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {StyleVars} from '../../styles/StyleVars';
import {ActivityIndicator, Snackbar, useTheme} from 'react-native-paper';
import {SelectedMessageActions} from './SelectedMessageActions';
import {ReactionsList} from './ReactionsList';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {HeyloButton} from '../../ui/button/HeyloButton';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import LinearGradient from 'react-native-linear-gradient';
import {ImageUploadModal} from './ImageUploadModal';
import {ImageUploadMetadata} from '@heylo/shared/src/services/image/ImageUploader';
import {ChatScreenContext} from './ChatScreenContext';
import _ from 'lodash';
import {ChatScrollAreaApi} from './ChatScrollArea';
import {ChatScrollAreaAdapter} from './ChatScrollAreaAdapter';
import {PhotosFirebase} from '@heylo/shared/src/features/photos/Firebase';
import {MentionsTextInputApi} from './MentionsTextInput';

const {height, width} = Dimensions.get('window');

export const Chat = React.memo(() => {
  console.count('Chat');

  const {
    communityId = '',
    onImagePress,
    thread,
    threadId,
    threadType,
    userId,
  } = useContext(ChatScreenContext);

  useEffect(() => {
    if (IsThreadTypeCommunityBased(threadType)) {
      dispatch(AttachThreadListeners(userId, communityId, threadId));
    } else if (IsPrivateThreadType(threadType)) {
      dispatch(AttachListenersForOnePrivateChat(threadType, threadId, userId));
      dispatch(PhotosFirebase.attachPrivateChatPhotoListener(threadId));
    }
    return () => {
      if (IsPrivateThreadType(threadType)) {
        PhotosFirebase.detachPrivateChatPhotoListener(threadId);
      }
    }
  }, [threadId, communityId, threadType]);

  const {
    name: threadName = '',
  } = thread;

  const {
    currentThreadId = '',
    isPrivateChat,
    joined,
    numMessages,
    threadMessagesMap,
    threadPhotos,
  } = useSelector((state: RootState) => ({
    // NB: active thread ID is required to know when the rest of these selectors
    // have actually updated to use the latest thread ID. Whenever the thread ID
    // changes, some component state needs to be updated.
    activeThreadId: ActiveThreadIdSelector(state),
    currentThreadId: threadId,
    isAnnouncementThread: threadType === THREAD_TYPE_ANNOUNCEMENT,
    isPrivateChat: threadType === THREAD_TYPE_DIRECT || threadType === THREAD_TYPE_GROUP || threadType === THREAD_TYPE_SUPPORT,
    joined: ActiveUserIsInterestedInActiveThread(state),
    lastReadTimestamp: ActiveThreadLastReadTimestampSelector(state),
    numMessages: Object.keys(ActiveThreadMessagesSelector(state)).length,
    threadMessagesMap: ActiveThreadMessagesSelector(state),
    threadPhotos: ActiveThreadPhotosSelector(state),
  }), shallowEqual);

  useEffect(() => {
    console.count('count Chat: selector updates');
  }, [
    currentThreadId,
    isPrivateChat,
    joined,
    numMessages,
    threadMessagesMap,
    threadPhotos,
  ]);

  const dispatch = useDispatch();
  const text = useTextStyles();
  const theme = useTheme();

  const [modalImage, setModalImage] = useState<ImageUploadMetadata | null>(null);
  const [imageUploadPromise, setImageUploadPromise] = useState<Promise<string>>(Promise.resolve(''));
  const messageMenuHeight = useRef(new Animated.Value(0)).current;
  const messageMenuOpacity = useRef(new Animated.Value(0)).current;
  const [apiRef, setApiRef] = useState<ChatScrollAreaApi | null>(null);
  const [messageInputApiRef, setMessageInputApiRef] = useState<MentionsTextInputApi | null>(null);
  const scrollToBottom = useCallback(() => apiRef?.scrollToBottom(), [apiRef]);

  const {
    loadingMoreMessages,
    onFinishChoosingImage,
    onJoinConversation,
    onLoadMoreMessages,
    onReactionCountPress,
    onReactionPress,
    onSendPress,
    replyToMessage,
    selectedMessage,
    setReplyToMessage,
    setSelectedMessage,
    showBottomMenu,
    showJoinButton,
    showLastReadMessageIndicator,
    showMessageInput,
    showReactionsListForMessage,
    stableLastReadTimestamp,
    threadMessagesAndUpdates,
    tutorialText,
  } = useChatScreenState();

  useEffect(() => {
    const duration = showBottomMenu ? 300 : 0;
    Animated.parallel([
      Animated.timing(messageMenuHeight, {
        toValue: showBottomMenu ? 60 : 0,
        duration,
        useNativeDriver: false,
      }),
      Animated.timing(messageMenuOpacity, {
        toValue: showBottomMenu ? 1.0 : 0,
        duration,
        useNativeDriver: false,
      }),
    ]).start();
  }, [showBottomMenu]);


  const dismissAllMenus = () => {
    setSelectedMessage(null);
  };

  const dismissKeyboardAndMenus = () => {
    Keyboard.dismiss();
    dismissAllMenus();
  };

  const openImageViewer = useCallback(
      (messageId: string) => {
        const threadPhotosArray = _.sortBy(
            threadPhotos,
            image => image.creationTimestamp ?? 0);
        for (let i = 0; i < threadPhotosArray.length; i++) {
          if (messageId === threadPhotosArray[i].photoId) {
            onImagePress({
              albumName: threadName,
              communityId,
              context: 'chat',
              index: i,
              photos: threadPhotosArray,
            });
          }
        }
      }, [threadPhotos]);

  const renderMessage = useCallback((item: Message, index: number, showTimestamp: boolean, showLastReadMessage: boolean) => {
    const prevItem = threadMessagesAndUpdates?.[index + 1];
    const ownerId = item.ownerId || '';
    const showSender = !prevItem || (ownerId !== prevItem.ownerId);
    const messageId = item.key || '';
    const replyMessageId = item.replyToMessageId || '';
    const replyMessage: Message | null = threadMessagesMap[replyMessageId] || null;
    const isSelected = selectedMessage && selectedMessage.key === messageId || false;
    const showReactionsMenu = isSelected && !showReactionsListForMessage || false;
    return (
        <MessageContainer
            anyMessageIsSelected={!!selectedMessage}
            isSelected={isSelected}
            isUser={ownerId === userId}
            message={item}
            messageRepliedTo={replyMessage}
            onImageSelected={openImageViewer}
            onMessageSelected={setSelectedMessage}
            onReactionPress={onReactionPress}
            onReactionCountsPress={onReactionCountPress}
            showLastReadMessage={showLastReadMessage}
            showReactionsMenu={showReactionsMenu}
            showSender={showSender}
            showTimestamp={showTimestamp}
            userId={ownerId}
        />
    );
  }, [
    threadMessagesAndUpdates,
    threadMessagesMap,
    selectedMessage,
    openImageViewer,
    onReactionCountPress,
    onReactionPress,
  ]);

  const renderItem = useCallback(({item, index}: { item: Message & ThreadUpdateType, index: number }) => {
    const getTimestamp = (item?: Message & ThreadUpdateType) => {
      return (item && (item.createdAt || item.updateTimestamp)) || 0;
    };

    const renderThreadUpdate = (item: ThreadUpdateType, showTimestamp: boolean) => {
      return (
          <ThreadUpdateText showTimestamp={showTimestamp} threadUpdate={item}/>
      );
    };

    const messageId = item.key || '';
    const currentTimestamp = getTimestamp(item);
    const prevItem = threadMessagesAndUpdates?.[index + 1];
    const prevTimestamp = getTimestamp(prevItem);
    const showTimestamp = !prevItem || moment(currentTimestamp).diff(moment(prevTimestamp),
        'minutes') >= 15;
    let innerComponent: any = null;
    if (item.updateTimestamp) {
      // TODO: move the suppression logic to the selectors
      const shouldSuppressUpdate = () => {
        const prevUpdateType = prevItem?.updateType || '';
        const currentUpdateType = item.updateType || '';
        const isDedupType = currentUpdateType === 'hero_image_updated'
            || currentUpdateType === 'notes_updated'
            || currentUpdateType === 'event_updated'
            || currentUpdateType === 'title_updated';
        return !showTimestamp && isDedupType && currentUpdateType === prevUpdateType;
      };
      if (shouldSuppressUpdate()) {
        return null;
      }
      innerComponent = renderThreadUpdate(item, showTimestamp);
    } else {
      let showLastReadMessage = false;
      if (showLastReadMessageIndicator) {
        if (!prevItem && stableLastReadTimestamp < currentTimestamp) {
          showLastReadMessage = true;
        } else if (prevItem && prevTimestamp < stableLastReadTimestamp && stableLastReadTimestamp < currentTimestamp) {
          showLastReadMessage = true;
        }
      }
      innerComponent = renderMessage(item, index, showTimestamp, showLastReadMessage);
    }
    return (
        <TouchableOpacity
            activeOpacity={1.0}
            onPress={dismissKeyboardAndMenus}
            style={{
              backgroundColor: 'transparent',
              paddingBottom: height * 0.002,
            }}
        >
          {innerComponent}
        </TouchableOpacity>
    );
  }, [
    showLastReadMessageIndicator,
    stableLastReadTimestamp,
    threadMessagesAndUpdates,
    renderMessage,
  ]);

  const openImageConfirmation = (imageUploadPromise: Promise<string>, image: ImageUploadMetadata) => {
    console.log('openImageConfirmation', JSON.stringify(image));
    setModalImage(image);
    setImageUploadPromise(imageUploadPromise);
  };

  const deleteMessage = (messageId: string) => {
    FirebaseRemoveMessage(threadType, communityId, currentThreadId, messageId, userId)
        .then(() => {
          dispatch(ACTION_DELETE_MESSAGE({
            threadId: currentThreadId,
            messageId,
          }));
        })
        .catch(e => {
          setSnackbarMessage('Could not delete message. Please try again or contact Heylo Support for help!');
          console.warn('error while creating message deletion', e, threadType, communityId, currentThreadId, messageId, userId);
        });
    dismissAllMenus();
  };

  const handleJoinPress = async () => {
    onJoinConversation()
        .catch((e: Error) => {
          console.warn('failed to join conversation', e.message);
          setSnackbarMessage('Unable to join. Please try again or contact Heylo Support');
        });
  }

  const handleSendPress = (message: string, mentions: { [userId: string]: Mention }, flags: any) => {
    onSendPress(message, mentions, flags)
        .catch((e: Error) => {
          console.error('error posting message', e.message);
          setSnackbarMessage('Unable to send message. Please try again or contact Heylo Support');
        });
    setTimeout(scrollToBottom, 100);
  }

  const messageBubble = (message: string) => {
    if (!message) {
      return null;
    }
    return (
        <View>
          <View
              style={[styles.containerThreadTutorial, {borderRadius: theme.roundness}]}>
            <Text style={[text.body2, styles.textTutorial]}>
              {message}
            </Text>
          </View>
        </View>
    );
  };

  const headerComponent = useMemo(() => (
      <LoadingContentIndicator
          contentId={currentThreadId}
          containerStyle={{paddingVertical: StyleConstants.SPACING}}
      />
  ), []);

  const footerComponent = useMemo(() => numMessages < 50
      ? messageBubble(tutorialText)
      : (
          <View
              style={{
                alignItems: 'center',
                height: 100,
                justifyContent: 'flex-end',
              }}
          >
            {loadingMoreMessages && (
                <ActivityIndicator
                    color={StyleVars.Colors.Seafoam}
                    size="large"
                />
            )}
          </View>
      ), [loadingMoreMessages, numMessages, tutorialText]);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const palette = usePalette();

  return (
      <View style={styles.chatScreen}>
        <TouchableOpacity
            activeOpacity={1.0}
            onPress={dismissKeyboardAndMenus}
            style={styles.listContainer}
        >
          <ChatScrollAreaAdapter
              data={threadMessagesAndUpdates}
              footerElement={footerComponent}
              headerElement={headerComponent}
              onApiReady={setApiRef}
              onLoadMoreMessages={onLoadMoreMessages}
              renderItem={renderItem}
              showLastReadMessageIndicator={showLastReadMessageIndicator}
              stableLastReadTimestamp={stableLastReadTimestamp}
          />
        </TouchableOpacity>

        {showMessageInput && (
            <MessageInput
                activeUserId={userId}
                clearReplyToMessage={() => setReplyToMessage(null)}
                onApiReady={setMessageInputApiRef}
                onError={(error) => setSnackbarMessage(error)}
                onImage={openImageConfirmation}
                onSendPress={handleSendPress}
                onTouch={() => setSelectedMessage(null)}
                replyToMessage={replyToMessage}
                threadType={threadType}
            />
        )}

        {showJoinButton && (
            <View style={{
              alignSelf: 'center',
              alignItems: 'center',
              marginTop: 5,
              maxWidth: 500,
              paddingHorizontal: StyleConstants.SPACING * 2,
              width: '100%',
            }}>
              <HeyloButton
                  label={'JOIN DISCUSSION'}
                  onPress={handleJoinPress}
                  width={'100%'}
              />
              <Text style={[text.body2, {
                color: palette.grey.main,
                marginTop: 4,
                marginBottom: 15,
                textAlign: 'center',
              }]}>
                Send messages and get notifications for this chat
              </Text>
            </View>
        )}

        {showReactionsListForMessage && (
            <TouchableOpacity
                onPress={dismissKeyboardAndMenus}
                style={{
                  backgroundColor: '#0009',
                  height: '100%',
                  position: 'absolute',
                  width: '100%',
                }}
            />
        )}

        <ReactionsList
            messageId={selectedMessage && selectedMessage.key || ''}
            visible={showReactionsListForMessage}
        />

        <Animated.View style={[
          styles.messageMenuContainer,
          {
            height: messageMenuHeight,
            opacity: messageMenuOpacity,
          },
          {bottom: 0, position: 'absolute'},
        ]}>
          <SelectedMessageActions
              deleteMessage={deleteMessage}
              isPrivateChat={isPrivateChat}
              message={selectedMessage}
              onCopy={() => setSnackbarMessage('Message copied')}
              replyToMessage={(messageId: string) => {
                setReplyToMessage(threadMessagesMap[messageId] || null);
                if (messageInputApiRef) {
                  messageInputApiRef.focus();
                }
              }}
              threadType={threadType}
              unselectMessage={dismissAllMenus}
              userHasJoined={joined}
          />
        </Animated.View>

        <Snackbar
            duration={3000}
            onDismiss={() => setSnackbarMessage('')}
            style={{bottom: 50}}
            visible={!!snackbarMessage}
        >
          {snackbarMessage}
        </Snackbar>

        {modalImage && (
            <ImageUploadModal
                closeModal={() => setModalImage(null)}
                imageInfo={modalImage}
                onUploadPress={() => {
                  onFinishChoosingImage(imageUploadPromise, modalImage)
                      .catch((e: Error) => {
                        console.warn('image upload error', e);
                        setSnackbarMessage('Could not upload image. Please try again or contact Heylo Support');
                      });
                  setModalImage(null);
                  setTimeout(scrollToBottom, 100);
                }}
                visible={true}
            />
        )}

        <LinearGradient
            colors={['#0003', '#0001', '#0000']}
            style={{
              height: 10,
              opacity: 0.5,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
        />
      </View>
  );
});

// @ts-ignore
Chat.whyDidYouRender = true;

const styles = StyleSheet.create({
  chatScreen: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  containerThreadTutorial: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: StyleVars.Colors.GreyLightest,
    justifyContent: 'center',
    margin: StyleConstants.SPACING,
    maxWidth: 400,
    padding: StyleConstants.SPACING,
  },
  listContainer: {
    flex: 1,
  },
  spacer: {
    height: 10,
  },
  messageMenuContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    flexDirection: 'row',
    overflow: 'hidden',
    width: '100%',
  },
  textTutorial: {
    alignSelf: 'center',
    color: StyleVars.Colors.GreyMedium,
    fontFamily: 'System',
    textAlign: 'center',
  },
});




