import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {createSelector} from 'reselect';
import _ from 'lodash';
import {selectProspectiveCommunityProfiles} from '../communityProfiles/Selectors';
import {CommunityProfile} from '../../types/firebase-types';
import {
  selectActiveUserFullName,
  selectActiveUserProfilePhotoUri,
} from '../userSettings/Selectors';

export const selectActiveMarketId = (state: RootState) => state.marketplaces.activeMarketplaceId ?? '';
export const selectJoinRequestCommunityProfile = (state: RootState) => state.marketplaces.activeCommunityJoinRequest ?? {};
export const selectMarketplaces = (state: RootState) => state.marketplaces.marketplaces ?? {};
export const selectMarketplaceCommunities = (state: RootState) => state.marketplaces.marketplaceCommunities ?? {};
export const selectMarketLastUpdateTimestamp = (state: RootState) => state.marketplaces.marketplaceLastUpdateTimestamp ?? {};
export const selectMarketMemberships = (state: RootState) => state.marketplaces.marketplaceMemberships ?? {};

export const selectSortedMetros = createSelector(
    [selectMarketplaces],
    (marketplaces) => {
      return _.sortBy(
          Object.values(marketplaces)
              .filter(marketplace => marketplace.category === 'metro'),
          'nameFull');
    });

export const selectSortedSchools = createSelector(
    [selectMarketplaces],
    (marketplaces) => {
      return _.sortBy(
          Object.values(marketplaces)
              .filter(marketplace => marketplace.category === 'school'),
          'nameFull');
    });

export const selectActiveMarketplace = createSelector(
    [selectActiveMarketId, selectMarketplaces],
    (marketplaceId, marketplaces) => {
      return marketplaces[marketplaceId];
    });

export const selectActiveMarketplaceProspectiveCommunities = createSelector(
    [selectActiveMarketId, selectProspectiveCommunityProfiles],
    (marketplaceId, communityProfiles)
        : CommunityProfile[] => {
      return communityProfiles.filter(profile => profile.marketplaceId === marketplaceId);
    });

export const selectActiveMarketplaceCommunities = createSelector(
    [selectActiveMarketId, selectMarketplaceCommunities, selectActiveMarketplaceProspectiveCommunities],
    (marketplaceId, marketplaceCommunities, prospectiveCommunityProfiles)
        : CommunityProfile[] => {
      const prospectiveCommunityIds = new Set(prospectiveCommunityProfiles.map(profile => profile.communityId));
      return _.sortBy(
          _.sortBy(
              Object.entries(marketplaceCommunities[marketplaceId] || {})
                  .filter(([communityId, community]) => !prospectiveCommunityIds.has(communityId) && !!community.profile)
                  .map(([communityId, community]) => community.profile || {}),
              community => community.communityName),
          community => !community.heroImageUrl);
    });

export const selectIsMemberOfActiveMarketplace = createSelector(
    [selectActiveMarketId, selectActiveMarketplace, selectMarketMemberships],
    (marketplaceId, marketplace, memberships) => {
      if (!marketplace) {
        return true;
      }
      const {emailVerificationDomain} = marketplace;
      if (emailVerificationDomain) {
        return !!memberships[marketplaceId];
      }
      return true;
    });

export enum CommunityJoinRequestNextScreen {
  EDIT_PROFILE,
  SEND_REQUEST,
};
export const selectJoinRequestNextScreen = createSelector(
    [
      selectActiveUserProfilePhotoUri,
      selectActiveUserFullName],
    (
        userProfilePhotoUri,
        userFullName) => {
      if (!userProfilePhotoUri || !userFullName) {
        return CommunityJoinRequestNextScreen.EDIT_PROFILE;
      }
      return CommunityJoinRequestNextScreen.SEND_REQUEST;
    });

