import React from 'react';
import {HeyloText} from 'lib/materialUi';
import {useSidebarStyles} from 'features/sidebar/Styles';
import {Colors} from '../../styles/RootStyles';
import {GreenBadge} from '@heylo/components/src/ui/badge/GreenBadge';

type Props = {
  isSelected: boolean,
  name: string,
  showBadge?: boolean,
}

export const CommunityTab = (props: Props) => {
  const {isSelected, name, showBadge} = props;

  const classes = useSidebarStyles();

  return (
      <div className={classes.containerRow} style={{
        backgroundColor: isSelected ? Colors.Secondary : 'transparent',
        color: isSelected ? '#FFFFFF' : '#000000',
        justifyContent: 'space-between',
      }}>
        {/*<img*/}
        {/*  alt={'Community Icon'}*/}
        {/*  src={iconUrl ? iconUrl : '/heylo-small.png'}*/}
        {/*  style={{*/}
        {/*    backgroundColor: '#FFFFFF',*/}
        {/*    borderRadius: 10,*/}
        {/*    height: 38,*/}
        {/*    margin: 10,*/}
        {/*    width: 38,*/}
        {/*  }}*/}
        {/*/>*/}
        <HeyloText noWrap
                   style={{fontWeight: isSelected ? 'bold' : 'normal'}}
                   variant={'body1'}>
          {name}
        </HeyloText>
        {showBadge ? (
            <GreenBadge containerStyle={{marginLeft: 10}}/>
        ) : null}
      </div>
  );
};
