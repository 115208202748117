import {ILogger} from '@heylo/shared/src/services/logging/Logger';

export class LoggingService {
  private loggers: ILogger[];

  constructor(loggers: ILogger[]) {
    this.loggers = loggers;
  }

  logEvent(eventCode: string, params?: any) {
    for (const logger of this.loggers) {
      logger.logEvent(eventCode, params);
    }
  }

  setCurrentScreen(screenName: string) {
    for (const logger of this.loggers) {
      logger.setCurrentScreen(screenName);
    }
  }

}