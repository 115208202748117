export const truncateString = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  const subString = text.substr(0, maxLength - 1);
  return subString.substr(0, subString.lastIndexOf(' ')) + '...';
};

export const truncateStringOnWords = (text: string, maxLength: number) => {
  const words = text.split(/\b/);
  let length = 0;
  let result: string[] = [];
  let truncated = false;
  for (const word of words) {
    length += word.length;
    result.push(word);
    if (length > maxLength) {
      truncated = true;
      break;
    }
  }
  return result.join('') + (truncated ? '...' : '');
};

export const ResolveFirstAndLastName = (fullName: string): [string, string] => {
  let firstName = '';
  let lastName = '';
  const nameTokens = (fullName || '')
      .split(' ')
      .map(x => x.trim())
      .filter(x => {
        if (x.length === 0) {
          return false;
        }
        const lower = x.toLowerCase();
        switch (lower) {
          case 'a':
          case 'dr':
          case 'dr.':
          case 'of':
          case 'the':
            return false;
        }
        return true;
      });

  if (nameTokens.length === 1) {
    firstName = nameTokens[0];
  } else if (nameTokens.length > 1) {
    firstName = nameTokens[0];
    lastName = nameTokens.slice(1).join(' ');
  }
  return [firstName, lastName];
};

export const IsValidFullName = (fullName: string): boolean => {
  // Unicode range \u0300-\u036f covers most common roman alphabet accents.
  return !!fullName.normalize('NFD').match(/^[a-z-'"‘’“”\u0300-\u036f ]+$/i);
};

// Normalize strings for comparison in search and filtering. Strips out
// accents, capital letters, and extra leading/trailing whitespace.
export const NormalizeForSearch = (input?: string | null): string => {
  if (!input) {
    return '';
  }
  return input
      .normalize('NFD')
      // Unicode range \u0300-\u036f supports most common roman alphabet accents.
      .replace(/[\u0300-\u036f]/g, '')
      .toLocaleLowerCase()
      .trim();
};

const containsEmojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
const onlyContainsEmojiRegex = /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])+$/g;

export const StringContainsEmoji = (str: string): boolean => {
  return !!str.match(containsEmojiRegex);
};

export const StringContainsOnlyEmoji = (str: string): boolean => {
  return !!str.match(onlyContainsEmojiRegex);
};