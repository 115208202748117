import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import {
  EventBucket,
  selectActiveCommunityBucketizedEvents,
  selectNumUpcomingEvents,
} from '@heylo/shared/src/features/events/Selectors';
import {EventCard} from 'features/event';
import {AttachListenersForEvent} from '@heylo/shared/src/features/events/Firebase';
import {HeyloText} from '../../lib/materialUi';
import useTheme from '@material-ui/core/styles/useTheme';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {HeyloContainer} from '@heylo/components/src/ui/container/HeyloContainer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {IncrementCommunityImpression} from '@heylo/shared/src/features/userEvents/Actions';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

const mapStateToProps = (state: RootState) => {
  return {
    activeCommunityId: SelectActiveCommunityId(state),
    activeUserId: selectActiveUserId(state),
    bucketedEvents: selectActiveCommunityBucketizedEvents(state),
    numUpcomingEvents: selectNumUpcomingEvents(state),
  };
};

const connector = connect(mapStateToProps, {
  AttachListenersForEvent,
  IncrementCommunityImpression,
});

type Props = ConnectedProps<typeof connector>;

const skipBucket = (bucket: string) => {
  return bucket === EventBucket.PAST || bucket === EventBucket.UNKNOWN;
}

const bucketLabel = (bucket: string) => {
  switch (bucket) {
    case EventBucket.RECENT:
      return 'Recent';
    case EventBucket.TODAY:
      return 'Today';
    case EventBucket.IN_NEXT_MONTH:
      return 'In the next month';
    case EventBucket.IN_NEXT_WEEK:
      return 'In the next week';
    case EventBucket.MORE_THAN_ONE_MONTH:
      return 'More than a month away';
    default:
      return '';
  }
};

export const PureEventSummary = (props: Props) => {
  const {
    activeCommunityId,
    activeUserId,
    bucketedEvents,
    numUpcomingEvents,
    AttachListenersForEvent,
    IncrementCommunityImpression,
  } = props;

  useEffect(() => {
    for (const [bucket, events] of Object.entries(bucketedEvents)) {
      if (skipBucket(bucket)) {
        continue;
      }
      for (const event of events) {
        const {communityId, eventId} = event;
        if (communityId && eventId) {
          AttachListenersForEvent(communityId, eventId, activeUserId);
        }
      }
    }
  }, [bucketedEvents, AttachListenersForEvent]);

  useEffect(() => {
    IncrementCommunityImpression(activeCommunityId, 'EventsScreen');
    return () => {
      IncrementCommunityImpression(activeCommunityId, 'EventsScreen');
    };
  }, [activeCommunityId]);

  const text = useTextStyles();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  console.count('EventSummary');

  return (
      <HeyloContainer maxWidth={'xs'}>
        {numUpcomingEvents === 0 && (
            <View style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: StyleConstants.SPACING,
              height: '100%',
            }}>
              <Text style={[text.h5, text.center]}>
                No upcoming events 😱
              </Text>
              <Text style={[text.h6, text.center]}>
                Create an event to get members together
              </Text>
            </View>
        )}

        {Object.entries(bucketedEvents).map(([bucket, events]) => {
          if (skipBucket(bucket)) {
            return null;
          }
          return (
              <div key={`events${bucket}`}>
                <div style={{
                  padding: theme.spacing(2),
                }}>
                  <HeyloText variant={'subtitle1'}>
                    {bucketLabel(bucket)}
                  </HeyloText>
                </div>
                {events.map((event, index) => {
                  const {eventId} = event;
                  return (
                      <EventCard
                          event={event}
                          index={index}
                          key={`event-${eventId}`}
                      />
                  )
                })}
              </div>
          );
        })}

        {matchXs ? (
            <View style={{height: 50}}/>
        ) : null}

      </HeyloContainer>
  );
};

export const EventSummary = connector(PureEventSummary);
