import React from 'react';
import {HeyloText} from 'lib/materialUi';
import {HeyloLogo} from '../ui';
import useTheme from '@material-ui/core/styles/useTheme';
import {View} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

export const Footer: React.FunctionComponent = () => {
  const theme = useTheme();
  return (
      <footer>
        <View style={{
          alignItems: 'center',
          backgroundColor: '#f2f2f2',
          borderTopWidth: 1,
          borderTopColor: '#c4c4c4',
          padding: StyleConstants.SPACING,
          width: '100%',
        }}>
          <div style={{
            paddingBottom: theme.spacing(1),
          }}>
            <HeyloLogo/>
          </div>
          <HeyloText variant={'body1'}>
            © 2018-2020 Heylo from Piccup, Inc.
          </HeyloText>
          <HeyloText variant={'body1'}>
            Made with&nbsp;
            <span role={'img'} aria-label={'love'}
                  style={{paddingRight: '5px'}}>💜</span>
            in San Francisco, CA
          </HeyloText>
        </View>
      </footer>
  );
};