import React, {useContext, useState} from 'react';
import {EmailVerification} from '@heylo/components/src/features/auth/EmailVerification';
import {EmailVerificationResult} from '@heylo/components/src/features/auth/EmailVerificationResult';
import {EmailVerificationContext} from '@heylo/shared/src/features/auth/Slice';
import {FirebaseAuth} from '@heylo/firebase-database';
import {
  EmailVerificationStartContext,
  EmailVerificationStartState,
} from '../marketplaces/EmailVerificationStartContext';

enum State {
  START,
  PENDING,
}

export const EmailVerificationForm = ({context, onSuccess}: {
  context: EmailVerificationContext,
  onSuccess?: () => void,
}) => {
  const [formState, setFormState] = useState(State.START);

  const onEmailSent = () => {
    setFormState(State.PENDING);
  }

  const [state, setState] = useContext(EmailVerificationStartContext);
  const onVerificationReset = () => {
    setFormState(State.START);
    setState(EmailVerificationStartState.IDLE);
  };

  const onSignIn = (email: string, signInLink: string) => {
    return new Promise<string>((resolve, reject) => {
      if (!FirebaseAuth().isSignInWithEmailLink(signInLink)) {
        console.warn('invalid sign in link', signInLink);
        return reject(new Error('invalid-signin-link'));
      }
      FirebaseAuth().signInWithEmailLink(email, signInLink)
          .then(userCredential => {
            const {uid = ''} = userCredential.user || {};
            resolve(uid);
          });
    });
  };

  return (
      <>
        {formState === State.START ? (
            <EmailVerification
                context={context}
                onSuccess={onEmailSent}
            />
        ) : formState === State.PENDING ? (
            <EmailVerificationResult
                context={context}
                onReset={onVerificationReset}
                onSignIn={onSignIn}
                onSuccess={onSuccess}
            />
        ) : null}
      </>

  );
};