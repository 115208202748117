import moment, {Moment} from 'moment-timezone';

// Converts a moment into a human-friendly datetime string.
export function shortenedHumanReadableMoment(m: Moment): string {
  if (!m) {
    return '';
  }
  const today = moment().startOf('day');
  const eventDay = moment(m).startOf('day');
  const isSameDay = today.isSame(eventDay, 'day');
  const numDaysAgo = today.diff(eventDay, 'days');
  let formatString = '';
  if (isSameDay) {
    formatString = '[Today at] h:mma';
  } else if (numDaysAgo === 1) {
    formatString = '[Yesterday at] h:mma';
  } else if (numDaysAgo < 7) {
    formatString = 'ddd [at] h:mma';
  } else if (numDaysAgo < 180) {
    formatString = 'MMM D [at] h:mma';
  } else {
    formatString = 'MMM D, YYYY [at] h:mma';
  }
  return m.format(formatString).toUpperCase();
}

export function shortenedHumanReadableTimestamp(timestamp: number): string {
  return shortenedHumanReadableMoment(moment(timestamp));
}

export function iosCalendarTimestamp(m: Moment): number {
  const referenceTime = moment('2001-01-01 00:00:00+00:00', 'YYYY-MM-DD HH:mm:ssZZ');
  return m.diff(referenceTime, 'seconds');
}

export function getLastActivityString(timestamp: number): string {
  const activityTimestamp = moment(timestamp);
  if (activityTimestamp > moment().subtract(10, 'minutes')) {
    return 'Active now';
  } else if (activityTimestamp > moment().subtract(1, 'hours')) {
    return 'Active in last hour';
  } else if (activityTimestamp > moment().startOf('day')) {
    return 'Active today';
  } else if (activityTimestamp > moment().subtract(1, 'days').startOf('day')) {
    return 'Active yesterday';
  } else if (activityTimestamp > moment().subtract(7, 'days').startOf('day')) {
    return 'Active in last week';
  } else if (activityTimestamp > moment().subtract(30, 'days').startOf('day')) {
    return 'Active in last month';
  }
  // NB: some callees of this function check for 'Inactive' return value. Do
  // not change without updating all callees.
  return '';
}

export function getAbbreviatedLastActivityString(now: moment.Moment, timestamp: number) {
  if (!now || !timestamp) {
    return '';
  }
  const m = moment(timestamp);
  const minutes = Math.round(now.diff(m, 'minutes', true));
  if (minutes <= 0) {
    return `now`;
  }
  if (minutes < 60) {
    return `${minutes}m ago`;
  }
  const hours = Math.round(now.diff(m, 'hours', true));
  if (hours < 24) {
    return `${hours}h ago`;
  }
  const days = Math.round(now.diff(m, 'days', true));
  if (days < 7) {
    return `${days}d ago`;
  }
  const weeks = Math.round(now.diff(m, 'weeks', true));
  if (weeks <= 8) {
    return `${weeks}w ago`;
  }
  return '';
}


