import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment';
import {AppReset} from '@heylo/shared/src/features/app/Actions';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {DeepReadonly} from 'utility-types';
import {EventUpdated} from '@heylo/shared/src/features/events/Slice';
import {
  Event,
  UserChatState,
  UserProfile,
} from '@heylo/shared/src/types/firebase-types';
import {UserProfileChanged} from '@heylo/shared/src/features/userProfiles/Slice';
import {
  ACTION_COMPACT_THREAD,
  ThreadStateLoaded,
} from '@heylo/shared/src/features/threads/Slice';
import {
  ACTION_STORE_COMMUNITY_PHOTO,
  ACTION_STORE_PRIVATE_CHAT_PHOTO,
} from '../photos/Slice';

export enum SendState {
  UNKNOWN = 0,
  STARTED = 1,
  SUCCESS = 2,
  ERROR = 3,
};

export type ContentSendState = {
  state: SendState,
  timestamp: number,
};

export type UiState = {
  FIELD_COMMUNITY_CONTENT_SEND_STATE: DeepReadonly<{ [communityId: string]: { [contentId: string]: ContentSendState } }>,
  FIELD_CONTENT_SEND_STATE: DeepReadonly<{ [contentId: string]: ContentSendState }>,
  localPhotoUris: DeepReadonly<{ [photoId: string]: string }>,
};

const initialState: UiState = {
  FIELD_COMMUNITY_CONTENT_SEND_STATE: {},
  FIELD_CONTENT_SEND_STATE: {},
  localPhotoUris: {},
};

const setCommunityContentSendState = (communityId: string, contentId: string, sendState: SendState, state: Draft<UiState>) => {
  if (!state.FIELD_COMMUNITY_CONTENT_SEND_STATE) {
    state.FIELD_COMMUNITY_CONTENT_SEND_STATE = {};
  }
  if (!state.FIELD_COMMUNITY_CONTENT_SEND_STATE[communityId]) {
    state.FIELD_COMMUNITY_CONTENT_SEND_STATE[communityId] = {};
  }
  state.FIELD_COMMUNITY_CONTENT_SEND_STATE[communityId][contentId] = {
    state: sendState,
    timestamp: moment().valueOf(),
  };
};

export const setContentSendState = (contentId: string, sendState: SendState, state: Draft<UiState>) => {
  if (!state.FIELD_CONTENT_SEND_STATE) {
    state.FIELD_CONTENT_SEND_STATE = {};
  }
  state.FIELD_CONTENT_SEND_STATE[contentId] = {
    state: sendState,
    timestamp: moment().valueOf(),
  };
}

const setLocalPhotoUri = (localUri: string, photoId: string, state: Draft<UiState>) => {
  if (localUri && photoId) {
    if (!state.localPhotoUris) {
      state.localPhotoUris = {};
    }
    state.localPhotoUris[photoId] = localUri;
  }
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    ACTION_SET_COMMUNITY_CONTENT_SEND_STATE: (state, action: PayloadAction<{ communityId: string, contentId: string, sendState: SendState }>) => {
      const {communityId, contentId, sendState} = action.payload;
      setCommunityContentSendState(communityId, contentId, sendState, state);
    },

    ACTION_SET_CONTENT_SEND_STATE: (state, action: PayloadAction<{ contentId: string, sendState: SendState }>) => {
      const {contentId, sendState} = action.payload;
      setContentSendState(contentId, sendState, state);
    },
  },

  extraReducers: builder => builder
      .addCase(ACTION_COMPACT_THREAD, (state, action: PayloadAction<{ threadId: string }>) => {
        const {threadId} = action.payload;
        setContentSendState(threadId, SendState.SUCCESS, state);
      })
      .addCase(AppReset, () => initialState)
      .addCase(EventUpdated, (state, action: PayloadAction<{ communityId: string, eventId: string, event: DeepReadonly<Event> }>) => {
        const {communityId, eventId} = action.payload;
        setCommunityContentSendState(communityId, eventId, SendState.SUCCESS, state);
      })
      .addCase(SigningOutAction, () => initialState)
      .addCase(ThreadStateLoaded, (state, action: PayloadAction<{ threadId: string, chatState: UserChatState }>) => {
        const {chatState, threadId} = action.payload;
        const {
          lastActivityTimestamp,
          lastReadTimestamp,
        } = chatState;
        const markThreadAsLoaded = (lastReadTimestamp || 0) >= (lastActivityTimestamp || 0);
        if (markThreadAsLoaded) {
          setContentSendState(threadId, SendState.SUCCESS, state);
        }
      })
      .addCase(UserProfileChanged, (state, action: PayloadAction<{ userId: string, profile: UserProfile }>) => {
        const {userId} = action.payload;
        setContentSendState(userId, SendState.SUCCESS, state);
      })
      .addCase(ACTION_STORE_COMMUNITY_PHOTO, (state, action: PayloadAction<{ localUri?: string, photoId: string }>) => {
        const {localUri= '', photoId = ''} = action.payload;
        setLocalPhotoUri(localUri, photoId, state);
      })
      .addCase(ACTION_STORE_PRIVATE_CHAT_PHOTO, (state, action: PayloadAction<{ localUri?: string, photoId: string }>) => {
        const {localUri = '', photoId = ''} = action.payload;
        setLocalPhotoUri(localUri, photoId, state);
      }),

});

const {actions, reducer: UiReducer} = slice;

const {
  ACTION_SET_COMMUNITY_CONTENT_SEND_STATE,
  ACTION_SET_CONTENT_SEND_STATE,
} = actions;

export {
  ACTION_SET_COMMUNITY_CONTENT_SEND_STATE,
  ACTION_SET_CONTENT_SEND_STATE,
  UiReducer,
};