import './IntroScreen.css';
import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {HeyloButton} from '@heylo/components/src/ui/button/HeyloButton';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {useHistory} from 'react-router-dom';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

export const IntroScreen = () => {
  const history = useHistory();
  const logger = useLoggingService();
  const text = useTextStyles();

  // const {
  //   activeCommunityId,
  //   activeUserId,
  // } = useSelector((state: RootState) => ({
  //   activeCommunityId: SelectActiveCommunityId(state),
  //   activeUserId: selectActiveUserId(state),
  // }));

  // const [maybeRedirect, setMaybeRedirect] = useState(false);
  //
  // useEffect(() => {
  //   setTimeout(() => setMaybeRedirect(true), 1000);
  // }, []);
  //
  // useEffect(() => {
  //
  //   if (activeUserId) {
  //     if (activeCommunityId) {
  //       history.replace(`/events?communityId=${activeCommunityId}`);
  //     } else {
  //       history.replace(`/m`);
  //     }
  //   }
  // }, [activeCommunityId, activeUserId]);

  return (
      <View style={styles.container}>
        <div className='intro-splash'/>

        <View style={styles.logoContainer}>
          <Image
              resizeMode={'contain'}
              source={{uri: 'https://firebasestorage.googleapis.com/v0/b/piccup-82257.appspot.com/o/assets%2Fheylo-white.png?alt=media&token=f0c13049-58d3-417e-9c3d-23a6465108c8'}}
              style={styles.logo}
          />
          <Text
              style={[text.h6, text.center, {color: 'white', lineHeight: 35}]}>
            <span role={'img'}>📆</span> Plan events{'\n'}
            <span role={'img'}>📘</span> Separate group messaging{'\n'}
            <span role={'img'}>👋</span> Customize profiles in private{'\n'}
          </Text>
        </View>

        <View style={styles.containerActions}>
          <HeyloButton
              icon={'magnify'}
              label={'Find groups'}
              onPress={() => {
                logger.logEvent(AnalyticsEvent.INTRO_FIND_COMMUNITY);
                history.push('/m');
              }}
              style={{marginBottom: StyleConstants.SPACING}}
              width={300}
          />

          <HeyloButton
              color={'white'}
              icon={'plus'}
              label={'Create a group'}
              mode={'outlined'}
              onPress={() => {
                logger.logEvent(AnalyticsEvent.INTRO_CREATE_COMMUNITY);
                history.push('/newGroup/name');
              }}
              style={{marginBottom: StyleConstants.SPACING}}
              width={300}
          />

          <HeyloButton
              color={'white'}
              label={'login'}
              mode={'text'}
              onPress={() => {
                logger.logEvent(AnalyticsEvent.INTRO_LOGIN);
                history.push('/login');
              }}
              style={{marginBottom: StyleConstants.SPACING}}
              width={300}
          />
        </View>
      </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerActions: {
    alignItems: 'center',
    bottom: 0,
    height: '30%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  containerBackgroundImage: {
    height: '100%',
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
  },
  containerGradient: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  containerLogin: {
    marginTop: '0.8rem',
  },
  imageBackground: {
    height: '100%',
    width: '100%',
  },
  logoContainer: {
    justifyContent: 'center',
    height: '100%',
    top: -50,
    position: 'absolute',
    width: '100%',
  },
  logo: {
    alignSelf: 'center',
    height: '100%',
    maxHeight: '25%',
    marginBottom: StyleConstants.SPACING,
    width: '66%',
  },
});