import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {
  Community,
  CommunityMember,
  CommunityProspect,
  CommunitySettings,
  UserSettings,
} from '@heylo/shared/src/types/firebase-types';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import _ from 'lodash';
import {CommunityScopedDeletionAction} from '@heylo/shared/src/features/communities/Actions';
import {DeepReadonly} from 'utility-types';
import {AppColdStart, AppReset} from '../app/Actions';
import {CommunityMemberSince} from '../communityMemberFields/Slice';
import moment from 'moment';
import {Platform} from 'react-native';

export type CommunityState = {
  FIELD_ACTIVE_COMMUNITY_ID: string,
  FIELD_COMMUNITIES: DeepReadonly<{ [communityId: string]: Community }>,
  FIELD_COMMUNITY_EXPERIMENTS: DeepReadonly<{ [communityId: string]: { [experiment: string]: string } }>,
  FIELD_COMMUNITY_MEMBERS: DeepReadonly<{ [communityId: string]: { [userId: string]: CommunityMember } }>,
  FIELD_COMMUNITY_PROSPECTS: DeepReadonly<{ [communityId: string]: { [userId: string]: CommunityProspect } }>,
  FIELD_COMMUNITY_SETTINGS: DeepReadonly<{ [communityId: string]: CommunitySettings }>,

  lastActiveTimestamps: DeepReadonly<{ [communityId: string]: number }>,
  latestDeletionId: DeepReadonly<{ [communityId: string]: string }>,

  // Holds a community object while a user is in the middle of registering an
  // account. Only needed because the component they were on could be
  // unmounted/remounted during the sign-in transition.
  pendingCreateCommunity: null | DeepReadonly<Community>,
}

const initialState: CommunityState = {
  FIELD_ACTIVE_COMMUNITY_ID: '',
  FIELD_COMMUNITIES: {},
  FIELD_COMMUNITY_EXPERIMENTS: {},
  FIELD_COMMUNITY_MEMBERS: {},
  FIELD_COMMUNITY_PROSPECTS: {},
  FIELD_COMMUNITY_SETTINGS: {},
  lastActiveTimestamps: {},
  latestDeletionId: {},
  pendingCreateCommunity: {},
};

const normalizeMember = (memberId: string, member: CommunityMember) => {
  member.id = memberId;
  // TODO: remove
  // @ts-ignore
  delete member.avatar;
  // @ts-ignore
  delete member.bio;
  // @ts-ignore
  delete member.fullName;
  // @ts-ignore
  delete member.goals;
};

const isMember = (communityId: string, state: Draft<CommunityState>) => {
  return !!state.FIELD_COMMUNITIES[communityId];
}

// Get the most recently active community ID and, in the absence of recency,
// fallback to the first community sorted alphabetically.
const nextCommunityId = (state: CommunityState): string => {
  if (Object.keys(state.FIELD_COMMUNITIES).length === 0) {
    return '';
  }

  let mostRecentCommunityId = '';
  let mostRecentTimestamp = 0;
  for (const [communityId, timestamp] of Object.entries(state.lastActiveTimestamps || {})) {
    if (!communityId || !isMember(communityId, state)) {
      continue;
    }
    if (timestamp > mostRecentTimestamp) {
      mostRecentCommunityId = communityId;
      mostRecentTimestamp = timestamp;
    }
  }
  if (mostRecentCommunityId) {
    return mostRecentCommunityId;
  }

  return _.sortBy(
      Object.entries(state.FIELD_COMMUNITIES),
      ([cid, community]) => community.name || null)
      [0][0];
};

const setActiveCommunity = (communityId: string, state: Draft<CommunityState>) => {
  state.FIELD_ACTIVE_COMMUNITY_ID = communityId;
  if (!state.lastActiveTimestamps) {
    state.lastActiveTimestamps = {};
  }
  if (communityId) {
    state.lastActiveTimestamps[communityId] = moment().valueOf();
  }
}

const removeCommunity = (communityId: string, state: Draft<CommunityState>) => {
  delete state.FIELD_COMMUNITIES[communityId];
  delete state.FIELD_COMMUNITY_EXPERIMENTS[communityId];
  delete state.FIELD_COMMUNITY_MEMBERS[communityId];
  delete state.FIELD_COMMUNITY_PROSPECTS[communityId];
  delete state.FIELD_COMMUNITY_SETTINGS[communityId];
  delete state.lastActiveTimestamps[communityId];
  delete state.latestDeletionId[communityId];
  if (state.FIELD_ACTIVE_COMMUNITY_ID === communityId) {
    setActiveCommunity(nextCommunityId(state), state);
  }
}

const updateOneCommunityMember = (communityId: string, userId: string, member: CommunityMember, state: Draft<CommunityState>) => {
  if (!state.FIELD_COMMUNITY_MEMBERS[communityId]) {
    state.FIELD_COMMUNITY_MEMBERS[communityId] = {};
  }
  normalizeMember(userId, member);
  state.FIELD_COMMUNITY_MEMBERS[communityId][userId] = member;
}

export const communitiesSlice = createSlice({
  name: 'communities',
  initialState,
  reducers: {

    ActiveCommunityUpdated: (state, action: PayloadAction<string>) => {
      const communityId = action.payload;
      if (typeof communityId === 'string') {
        setActiveCommunity(communityId, state);
      }
    },

    CommunityCreated: (state, action: PayloadAction<{ communityId: string, community: DeepReadonly<Community> }>) => {
      const {communityId, community} = action.payload;
      setActiveCommunity(communityId, state);
      state.FIELD_COMMUNITIES[communityId] = Object.assign({}, community);
    },

    CommunityUpdated: (state, action: PayloadAction<{ communityId: string, community: DeepReadonly<Community> }>) => {
      const {communityId, community} = action.payload;
      state.FIELD_COMMUNITY_EXPERIMENTS[communityId] = community.experiments || {};
      state.FIELD_COMMUNITY_SETTINGS[communityId] = community.settings || {};
      const streamlinedCommunity: Community = Object.assign({}, community, {
        communityId: communityId,
        communityThreads: null,
        experiments: null,
        eventThreads: null,
        settings: null,
      });
      state.FIELD_COMMUNITIES[communityId] = streamlinedCommunity;

      // NB: If there is no active community ID while the app is in the process
      // of bootstrapping, set the first loaded community to be active. This is
      // in absence of any other data about which community should be considered
      // the most recently active. Bypass this logic for web because it uses
      // the active URL to determine whether or not to set a default active
      // community ID.
      if (Platform.OS !== 'web' && !state.FIELD_ACTIVE_COMMUNITY_ID) {
        setActiveCommunity(nextCommunityId(state), state);
      }
    },

    CommunityMembersLoaded: (state, action: PayloadAction<{ communityId: string, members: { [userId: string]: CommunityMember } }>) => {
      const {communityId, members} = action.payload;
      for (const [userId, member] of Object.entries(members)) {
        normalizeMember(userId, member);
      }
      state.FIELD_COMMUNITY_MEMBERS[communityId] = members;
    },

    CommunityProspectsLoaded: (state, action: PayloadAction<{ communityId: string, prospects: { [userId: string]: CommunityProspect } }>) => {
      const {communityId, prospects} = action.payload;
      state.FIELD_COMMUNITY_PROSPECTS[communityId] = prospects;
    },

    CompactCommunities: (state, action: PayloadAction<UserSettings>) => {
      // Compact orphaned communities.
      const {communities: currentCommunityIds} = action.payload;
      for (const cid of Object.keys(state.FIELD_COMMUNITIES || {})) {
        if (!currentCommunityIds?.[cid]) {
          removeCommunity(cid, state);
        }
      }
    },

    OneCommunityMemberUpdated: (state, action: PayloadAction<{ communityId: string, userId: string, member: CommunityMember }>) => {
      const {communityId, userId, member} = action.payload;
      updateOneCommunityMember(communityId, userId, member, state);
    },

    MemberSinceDraftsSaved: (state, action: PayloadAction<{ userId: string, memberSinceMap: { [communityId: string]: CommunityMemberSince } }>) => {
      const {userId, memberSinceMap} = action.payload;
      for (const [communityId, memberSince] of Object.entries(memberSinceMap)) {
        if (!state.FIELD_COMMUNITY_MEMBERS[communityId]?.[userId]) {
          continue;
        }
        const {month, year} = memberSince;
        const member: CommunityMember = state.FIELD_COMMUNITY_MEMBERS[communityId][userId];
        member.memberSinceMonth = month;
        member.memberSinceYear = year;
      }
    },

    PendingCreateCommunity: (state, action: PayloadAction<null | DeepReadonly<Community>>) => {
      state.pendingCreateCommunity = action.payload;
    },

    UserJoinedCommunity: (state, action: PayloadAction<{ communityId: string, userId: string, member: CommunityMember }>) => {
      const {communityId, userId, member} = action.payload;
      updateOneCommunityMember(communityId, userId, member, state);
      setActiveCommunity(communityId, state);
    },

    UserLeftCommunity: (state, action: PayloadAction<string>) => {
      const communityId = action.payload;
      removeCommunity(communityId, state);
    },

  },

  extraReducers: builder => builder
      .addCase(AppColdStart, (state) => {
        if (Platform.OS === 'web') {
          // Web has its own handling for setting the default community ID,
          // which depends on the active URL.
          return;
        }
        if (state.FIELD_ACTIVE_COMMUNITY_ID && isMember(state.FIELD_ACTIVE_COMMUNITY_ID, state)) {
          return;
        }
        setActiveCommunity(nextCommunityId(state), state);
        state.pendingCreateCommunity = null;
      })
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState)
      .addCase(CommunityScopedDeletionAction, (state, action) => {
        const {communityId, deletion, deletionId} = action.payload;
        const {memberId} = deletion || {};
        state.latestDeletionId[communityId] = deletionId;
        if (memberId) {
          if (state.FIELD_COMMUNITY_MEMBERS[communityId]) {
            delete state.FIELD_COMMUNITY_MEMBERS[communityId][memberId];
          }
        }
      }),

});

const {actions, reducer: CommunitiesReducer} = communitiesSlice;

const {
  ActiveCommunityUpdated,
  CommunityCreated,
  CommunityMembersLoaded,
  CommunityProspectsLoaded,
  CommunityUpdated,
  CompactCommunities,
  MemberSinceDraftsSaved,
  OneCommunityMemberUpdated,
  PendingCreateCommunity,
  UserJoinedCommunity,
  UserLeftCommunity,
} = actions;

export {
  ActiveCommunityUpdated,
  CommunityCreated,
  CommunityMembersLoaded,
  CommunityProspectsLoaded,
  CommunityUpdated,
  CompactCommunities,
  MemberSinceDraftsSaved,
  OneCommunityMemberUpdated,
  PendingCreateCommunity,
  UserJoinedCommunity,
  UserLeftCommunity,
  CommunitiesReducer,
};
