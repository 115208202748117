import {createSelector} from 'reselect';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import moment from 'moment';
import {UserEventMetrics} from '@heylo/shared/src/types/firebase-types';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

export const selectCommunityImpressions = (state: RootState) => state.userEvents.FIELD_COMMUNITY_IMPRESSION_METRICS || {};
export const selectUserImpressionMetrics = (state: RootState) => state.userEvents.FIELD_USER_IMPRESSION_METRICS || {};
export const selectUserInteractionMetrics = (state: RootState) => state.userEvents.FIELD_USER_INTERACTION_METRICS || {};
export const selectUserInteractionsLoaded = (state: RootState) => state.userEvents.userInteractionMetricsLoaded || {};

export const ActiveCommunityImpressionsSelector = createSelector(
    [selectCommunityImpressions, SelectActiveCommunityId],
    (impressions, communityId): { [event: string]: UserEventMetrics } => {
      return impressions[communityId] ?? {};
    });

export const EventsScreenLastViewedTimestampSelector = createSelector(
    [ActiveCommunityImpressionsSelector],
    (impressions) => {
      return impressions['EventsScreen']?.lastTimestamp || moment().valueOf();
    });

export const MembersScreenLastViewedTimestampSelector = createSelector(
    [ActiveCommunityImpressionsSelector],
    (impressions) => {
      return impressions['MembersScreen']?.lastTimestamp || moment().valueOf();
    });

export const TopicsScreenLastViewedTimestampSelector = createSelector(
    [ActiveCommunityImpressionsSelector],
    (impressions) => {
      return impressions['TopicsScreen']?.lastTimestamp || moment().valueOf();
    });
