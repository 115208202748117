import React from 'react';
import {List} from 'react-native-paper';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {TextStyle, ViewStyle} from 'react-native';

export type HeyloListItemProps = {
  leftElement?: React.ReactNode,
  onPress?: () => void,
  primaryText?: string,
  primaryTextStyle?: TextStyle,
  rightElement?: React.ReactNode,
  secondaryText?: string,
  secondaryTextStyle?: TextStyle,
  style?: ViewStyle,
};

export const HeyloListItem = ({
                                leftElement,
                                onPress,
                                primaryText,
                                primaryTextStyle,
                                rightElement,
                                secondaryText,
                                secondaryTextStyle,
                                style,
                              }: HeyloListItemProps) => {
  const text = useTextStyles();
  return (
      <List.Item
          description={secondaryText}
          descriptionStyle={[text.subtitle1, secondaryTextStyle]}
          left={() => leftElement}
          onPress={onPress}
          right={() => rightElement}
          style={style}
          title={primaryText}
          titleStyle={[text.h6, primaryTextStyle]}
      />
  );
}