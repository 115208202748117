import React from 'react';
import {UserProfile} from '@heylo/shared/src/types/firebase-types';
import {GetBestUserProfilePhotoUrl} from '@heylo/shared/src/features/userProfiles/Selectors';
import {Link} from 'react-router-dom';
import {useSidebarStyles} from 'features/sidebar/Styles';
import {HeyloText} from 'lib/materialUi';

type Props = {
  userProfile?: UserProfile,
}

export const User = (props: Props) => {
  const {userProfile} = props;
  const {fullName: name} = userProfile ?? {};
  const photoUrl = GetBestUserProfilePhotoUrl(userProfile, 100);

  const classes = useSidebarStyles();
  return (
      <Link
          className={classes.containerRow}
          to={'/userSettings'}
      >
        <div className={classes.containerIcon}>
          <img
              alt={'user'}
              src={photoUrl ? photoUrl : '/person_circle.png'}
              style={{
                borderRadius: 50,
                width: '100%',
              }}
          />
        </div>
        <HeyloText noWrap variant={'body1'}>
          {name}
        </HeyloText>
      </Link>
  );
};
