import React, {useEffect, useState} from 'react';
import {Clipboard, Text, TouchableOpacity, View} from 'react-native';
import {HelperText, IconButton, Snackbar} from 'react-native-paper';
import {HeyloButton} from '../../ui/button/HeyloButton';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectActiveCommunityProfile} from '@heylo/shared/src/features/communityProfiles/Selectors';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {DraftCommunityReset} from '@heylo/shared/src/features/communityCreation/Slice';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';

export const NewCommunityCreated = (
    {
      onNext,
      shareLink,
    }: {
      onNext: () => void,
      shareLink: string,
    }) => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const text = useTextStyles();

  useEffect(() => {
    dispatch(DraftCommunityReset());
  }, []);

  const {communityProfile} = useSelector((state: RootState) => ({
    communityProfile: selectActiveCommunityProfile(state),
  }), shallowEqual);

  const {
    communityName,
  } = communityProfile;
  const logger = useLoggingService();

  const [linkCopiedVisible, setLinkCopiedVisible] = useState(false);
  const copyLink = () => {
    logger.logEvent(AnalyticsEvent.INVITE_LINK_COPIED);
    Clipboard.setString(shareLink);
    setLinkCopiedVisible(true);
  }

  return (
      <>
        <Text style={[text.h1, text.center]}>
          🎉
        </Text>

        <Text style={[text.h6, text.center, {marginTop: 10}]}>
          Congrats! {communityName} has been created.
        </Text>

        <Text style={[text.body1, {marginTop: 30}]}>
          Invite your group members directly by sharing the link below.{'\n'}
        </Text>

        <View>
          <TouchableOpacity
              onPress={copyLink}
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 10,
              }}
          >
            <View style={{
              alignItems: 'center',
              backgroundColor: palette.grey.lightest,
              borderRadius: 30,
              flexDirection: 'row',
              padding: 10,
            }}>
              <Text numberOfLines={1} style={[text.body2, {maxWidth: 250}]}>
                {shareLink}
              </Text>
            </View>
            <IconButton
                icon={'content-copy'}
                onPress={copyLink}
            />
          </TouchableOpacity>
          <HelperText type='info' style={{textAlign: 'center'}}>
            Members with this link can request to join.
          </HelperText>
          <View style={{
            bottom: -60,
            position: 'absolute',
            width: '100%',
          }}>
            <Snackbar
                duration={1000}
                onDismiss={() => setLinkCopiedVisible(false)}
                visible={linkCopiedVisible}
            >
              Link copied.
            </Snackbar>
          </View>
        </View>

        <View style={{alignItems: 'center', marginTop: 90}}>
          <HeyloButton
              label={'Go to group!'}
              onPress={onNext}
          />
        </View>
      </>
  );
};