import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {ActiveThreadSelector} from '@heylo/shared/src/features/threads/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {FirebaseUpdateGroupChat} from '@heylo/shared/src/features/threads/Firebase';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {THREAD_TYPE_GROUP} from '@heylo/shared/src/types/ThreadTypes';
import {AddThreadMembersList} from './AddThreadMembersList';

export enum AddThreadMembersState {
  UNEDITED,
  EDITED,
  SUBMIT_START,
  SUBMIT_PENDING,
  SUBMIT_SUCCESS,
};

export const AddThreadMembers = (
    {
      setState,
      state,
    }: {
      setState: Dispatch<SetStateAction<AddThreadMembersState>>,
      state: AddThreadMembersState,
    }) => {

  const {
    activeUserId,
    thread,
  } = useSelector((state: RootState) => ({
    activeUserId: selectActiveUserId(state) || '',
    thread: ActiveThreadSelector(state),
  }), shallowEqual);

  const logger = useLoggingService();
  const [currentMemberMap, setCurrentMemberMap] = useState<{ [userId: string]: boolean }>({});

  const onChange = (members: { [userId: string]: boolean }) => {
    setCurrentMemberMap(members);
  };

  useEffect(() => {
    const hasEdits = Object.keys(currentMemberMap).length > 0;
    if (state === AddThreadMembersState.UNEDITED && hasEdits) {
      setState(AddThreadMembersState.EDITED);
    } else if (state === AddThreadMembersState.EDITED && !hasEdits) {
      setState(AddThreadMembersState.UNEDITED);
    }
  }, [currentMemberMap]);

  const saveChanges = useCallback(() => {
    const {name = '', heroImageUrl = '', threadId = ''} = thread;
    const addedMemberIds = Object.keys(currentMemberMap);
    FirebaseUpdateGroupChat({
      addedMemberIds,
      chatImageUrl: heroImageUrl,
      chatName: name,
      threadId,
      userId: activeUserId,
    })
        .then(() => {
          logger.logEvent(AnalyticsEvent.CHAT_EDIT_SUBMIT, {
            memberCount: addedMemberIds.length,
            threadId,
            threadType: THREAD_TYPE_GROUP,
          });
          setState(AddThreadMembersState.SUBMIT_SUCCESS);
        })
        .catch((e: Error) => {
          console.warn('error adding members to group chat', e);
          setState(AddThreadMembersState.EDITED);
        })
  }, [activeUserId, currentMemberMap, thread]);

  useEffect(() => {
    if (state === AddThreadMembersState.SUBMIT_START) {
      setState(AddThreadMembersState.SUBMIT_PENDING);
      saveChanges();
    }
  }, [saveChanges, state]);

  return (
      <AddThreadMembersList onChange={onChange}/>
  );
};