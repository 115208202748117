import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DeepReadonly} from 'utility-types';
import {
  Community,
  Network,
  UserSettings,
} from '@heylo/shared/src/types/firebase-types';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {AppColdStart, AppReset} from '../app/Actions';
import {CommunityCreated} from '../communities/Slice';

export type UserSettingsState = {
  FIELD_COMMUNITY_IDS: Readonly<string[]>,
  FIELD_NETWORKS: DeepReadonly<{ [networkId: string]: Network }>,
  FIELD_NETWORK_IDS: Readonly<string[]>,
  FIELD_PROSPECTIVE_COMMUNITY_IDS: Readonly<string[]>,

  FIELD_USER_AVATAR_URI: string,
  FIELD_USER_BIO: string,
  FIELD_USER_CREATION_TIMESTAMP: number,
  FIELD_USER_EXPERIMENTS: DeepReadonly<{ [experimentId: string]: string }>,
  FIELD_USER_FULL_NAME: string,
  FIELD_USER_GOALS: string,

  // True if the user has the chat info pane expanded by default on Heylo web.
  chatInfoPaneVisible: boolean,
  email: string,
  pendingJoinRequestCommunityId: string,
  userSettingsFetched: boolean,
};

const initialState: UserSettingsState = {
  FIELD_COMMUNITY_IDS: [],
  FIELD_NETWORKS: {},
  FIELD_NETWORK_IDS: [],
  FIELD_PROSPECTIVE_COMMUNITY_IDS: [],

  FIELD_USER_AVATAR_URI: '',
  FIELD_USER_BIO: '',
  FIELD_USER_CREATION_TIMESTAMP: 0,
  FIELD_USER_EXPERIMENTS: {},
  FIELD_USER_FULL_NAME: '',
  FIELD_USER_GOALS: '',

  chatInfoPaneVisible: true,
  email: '',
  // Set only if a user has a pending join request for a community. Typically
  // this is used when a user is creating their account in order to send a join
  // request.
  pendingJoinRequestCommunityId: '',
  userSettingsFetched: false,
};

const filterIds = (ids: string[], filterIds: string[]) => {
  const set = new Set<string>(ids);
  for (const id of filterIds) {
    set.delete(id);
  }
  return Array.from(set);
};

export const slice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {

    UserSettingsChanged: (state, action: PayloadAction<UserSettings>) => {
      const {payload: userSettings} = action;
      const {
        avatar = '',
        bio = '',
        communities = {},
        creationTimestamp = 0,
        email = '',
        experiments = {},
        fullName = '',
        goals = '',
        networks = {},
        prospectiveCommunities = {},
      } = userSettings;
      state.userSettingsFetched = true;

      state.email = email;
      state.FIELD_COMMUNITY_IDS = Object.keys(communities);
      state.FIELD_NETWORK_IDS = Object.keys(networks);
      state.FIELD_PROSPECTIVE_COMMUNITY_IDS = filterIds(Object.keys(prospectiveCommunities), state.FIELD_COMMUNITY_IDS);
      state.FIELD_USER_AVATAR_URI = avatar;
      state.FIELD_USER_BIO = bio;
      state.FIELD_USER_CREATION_TIMESTAMP = creationTimestamp;
      state.FIELD_USER_EXPERIMENTS = experiments;
      state.FIELD_USER_FULL_NAME = fullName;
      state.FIELD_USER_GOALS = goals;

      // Throw out any data related to networks the user is no long a part
      // of.  TODO: move out into a separate "networks" slice, and
      // subscribe to 'UserSettingsChanged` actions there.
      for (const networkId of Object.keys(state.FIELD_NETWORKS)) {
        if (!networks[networkId]) {
          console.log('compacting network', networkId);
          delete state.FIELD_NETWORKS[networkId];
        }
      }
    },

    // TODO: move this to its own slice; it doesn't belong in the "user
    // settings" slice.
    NetworkChanged: (state, action: PayloadAction<{ networkId: string, network: Network | null }>) => {
      const {networkId, network} = action.payload;
      if (network) {
        network.networkId = networkId;
        state.FIELD_NETWORKS[networkId] = network;
      } else {
        delete state.FIELD_NETWORKS[networkId];
      }
    },

    PendingCommunityJoinRequest: (state, action: PayloadAction<string>) => {
      state.pendingJoinRequestCommunityId = action.payload;
    },

    ToggleChatInfoVisible: (state) => {
      state.chatInfoPaneVisible = !Boolean(state.chatInfoPaneVisible);
    }
  },

  extraReducers: builder => builder
      .addCase(AppColdStart, (state) => {
        state.pendingJoinRequestCommunityId = '';
        state.userSettingsFetched = false;
      })
      .addCase(AppReset, () => initialState)
      .addCase(CommunityCreated, (state, action: PayloadAction<{ communityId: string, community: Community }>) => {
        const {communityId} = action.payload;
        state.FIELD_COMMUNITY_IDS = Array.from(new Set<string>([...state.FIELD_COMMUNITY_IDS, communityId]));
      })
      .addCase(SigningOutAction, () => initialState),

});

const {actions, reducer: UserSettingsReducer} = slice;

const {
  NetworkChanged,
  PendingCommunityJoinRequest,
  ToggleChatInfoVisible,
  UserSettingsChanged,
} = actions;

export {
  NetworkChanged,
  PendingCommunityJoinRequest,
  ToggleChatInfoVisible,
  UserSettingsChanged,
  UserSettingsReducer,
};