import {
  FirebaseAddMessageToCommunityChat,
  FirebaseAddMessageToPrivateChat,
  FirebaseJoinConversation,
  FirebaseUpdateThreadLastReadTimestamp,
  getScrollMessages,
} from '@heylo/shared/src/features/threads/Firebase';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Action} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {
  ActiveThreadIdSelector,
  selectThreadIsLoadingMoreMessages,
  selectThreadNumMessagesLoadedLast,
} from '@heylo/shared/src/features/threads/SimpleSelectors';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {
  ActiveThreadLastReadTimestampSelector,
  ActiveThreadMergedMessagesAndUpdatesSelector,
  ActiveThreadSelector,
  ActiveUserIsInterestedInActiveThread,
} from '@heylo/shared/src/features/threads/Selectors';
import {
  IsPrivateThreadType,
  IsThreadTypeCommunityBased,
  THREAD_TYPE_ANNOUNCEMENT,
  THREAD_TYPE_DIRECT,
  THREAD_TYPE_EVENT,
  THREAD_TYPE_GROUP,
  THREAD_TYPE_SUPPORT,
} from '@heylo/shared/src/types/ThreadTypes';
import {SelectActiveUserIsAdminInActiveCommunity} from '@heylo/shared/src/features/communityMembers/Selectors';
import {
  Mention,
  Message,
  SharedPhoto,
} from '@heylo/shared/src/types/firebase-types';
import moment from 'moment';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {IncrementUserInteraction} from '@heylo/shared/src/features/userEvents/Actions';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {FirebaseCreateMessageReaction} from '@heylo/shared/src/features/reactions/Firebase';
import {FirebaseServerTimestamp} from '@heylo/firebase-database';
import {
  ACTION_STORE_COMMUNITY_PHOTO,
  ACTION_STORE_PRIVATE_CHAT_PHOTO,
} from '@heylo/shared/src/features/photos/Slice';
import {
  ACTION_ADD_MESSAGE_UNSORTED,
} from '@heylo/shared/src/features/threads/Slice';
import {
  ACTION_SET_CONTENT_SEND_STATE,
  SendState,
} from '@heylo/shared/src/features/ui/Slice';
import {ImageUploadMetadata} from '../../services/image/ImageUploader';
import {v4 as uuidv4} from 'uuid';

export const useChatScreenState = () => {
  // TODO: replace first type param with RootState type, once we have a shared
  // definition
  const dispatch: ThunkDispatch<any, any, Action> = useDispatch();
  const logger = useLoggingService();

  const {
    activeUserIsCommunityAdmin,
    communityId,
    joined,
    lastReadTimestamp,
    loadingMoreMessages,
    numMessagesLoadedLast,
    thread,
    threadId,
    threadMessagesAndUpdates,
    userId,
  } = useSelector((state: any) => ({
    activeUserIsCommunityAdmin: SelectActiveUserIsAdminInActiveCommunity(state),
    communityId: SelectActiveCommunityId(state),
    joined: ActiveUserIsInterestedInActiveThread(state),
    lastReadTimestamp: ActiveThreadLastReadTimestampSelector(state),
    loadingMoreMessages: selectThreadIsLoadingMoreMessages(state),
    numMessagesLoadedLast: selectThreadNumMessagesLoadedLast(state),
    thread: ActiveThreadSelector(state),
    threadId: ActiveThreadIdSelector(state),
    threadMessagesAndUpdates: ActiveThreadMergedMessagesAndUpdatesSelector(state),
    userId: selectActiveUserId(state),
  }), shallowEqual);

  const {
    threadType = '',
  } = thread;
  const isPrivateChat = threadType === THREAD_TYPE_DIRECT || threadType === THREAD_TYPE_GROUP || threadType === THREAD_TYPE_SUPPORT;
  const isAnnouncementThread = threadType === THREAD_TYPE_ANNOUNCEMENT;


  useEffect(() => {
    const updateLastReadTimestamp = async () => {
      if (userId
          && threadId
          // NB: ensure thread actually exists before writing anything to
          // Firebase. Done to prevent corrupting data.
          && thread?.threadId === threadId) {
        await dispatch(FirebaseUpdateThreadLastReadTimestamp(threadType, userId, communityId, threadId));
      }
    }
    updateLastReadTimestamp();
    return () => {
      // Update last read timestamp whenever leaving this chat screen.
      updateLastReadTimestamp();
    }
  }, [
    dispatch,
    communityId,
    thread,
    threadId,
    threadType,
    userId,
  ]);

  const stableLastReadTimestamp = useMemo(() => lastReadTimestamp, []);

  const showLastReadMessageIndicator = useMemo(() => {
    const mostRecentMessage = threadMessagesAndUpdates?.[0] ?? {};
    const mostRecentMessageTimestamp =
        mostRecentMessage.createdAt
        || mostRecentMessage.updateTimestamp
        || 0;
    return stableLastReadTimestamp < mostRecentMessageTimestamp;
  }, [stableLastReadTimestamp]);

  const [tutorialText, setTutorialText] = useState('');
  useEffect(() => {
    const {threadType, name, ownerId} = thread;
    let text = '';
    if (threadType === THREAD_TYPE_EVENT) {
      text = 'Use event chat to discuss and organize around the event.';
    } else if (threadType === THREAD_TYPE_ANNOUNCEMENT) {
      text = activeUserIsCommunityAdmin
          ? 'Only admins (that\'s you!) can send announcements. They reach everyone by notifying in-app and by email. Try sending a welcome message!'
          : 'Admins can send announcements here, notifying everyone in-app and by email.';
    } else if (threadType === THREAD_TYPE_SUPPORT) {
      text = 'Hi there! 👋 This is a private chat connecting you with the Heylo team. If you have any issues, or can think of ways Heylo could be improved, please message us here.';
    } else if (IsThreadTypeCommunityBased(threadType)) {
      text = userId === ownerId
          ? `You created "${name}"! 🎉 Everyone has been notified, and you can @mention specific people to jump start the conversation.`
          : `Welcome to "${name}"! 🎉 You can @mention anyone missing to get the conversation started.`;
    }
    setTutorialText(text);
  }, [activeUserIsCommunityAdmin, userId, thread]);


  const showMessageInput = isPrivateChat
      || (!isAnnouncementThread && joined)
      || (isAnnouncementThread && activeUserIsCommunityAdmin);

  const showJoinButton = !showMessageInput && !isAnnouncementThread;

  const [showBottomMenu, setShowBottomMenu] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [replyToMessage, setReplyToMessage] = useState<Message | null>(null);
  const [showReactionsListForMessage, setShowReactionsListForMessage] = useState(false);

  useEffect(() => {
    if (selectedMessage) {
      setReplyToMessage(null);
    } else {
      setShowReactionsListForMessage(false);
    }
    setShowBottomMenu(!!selectedMessage && !showReactionsListForMessage);
  }, [selectedMessage, showReactionsListForMessage]);

  const addMessageToThread = useCallback((messageId: string, message: Message)
      : Promise<void> => {
    if (replyToMessage) {
      message.replyToMessageId = replyToMessage.key || '';
      setReplyToMessage(null);
    }
    if (isPrivateChat) {
      return dispatch(FirebaseAddMessageToPrivateChat(threadId, messageId, message))
    }
    return dispatch(FirebaseAddMessageToCommunityChat(communityId, threadId, messageId, message));
  }, [dispatch, communityId, isPrivateChat, replyToMessage, threadId]);

  const onLoadMoreMessages = useCallback((): Promise<void> | null => {
    if (!loadingMoreMessages && numMessagesLoadedLast !== 0) {
      const numMessages = threadMessagesAndUpdates.length;
      if (numMessages) {
        const searchTimestamp = threadMessagesAndUpdates[numMessages - 1].createdAt || moment().valueOf();
        return dispatch(getScrollMessages(communityId, threadId, searchTimestamp));
      }
    }
    return null;
  }, [loadingMoreMessages, numMessagesLoadedLast, threadMessagesAndUpdates]);

  const onJoinConversation = useCallback(() => {
    logger.logEvent(AnalyticsEvent.CHAT_JOINED);
    dispatch(IncrementUserInteraction(AnalyticsEvent.CHAT_JOINED));
    return FirebaseJoinConversation(userId, communityId, threadId);
  }, []);

  const onReactionPress = useCallback((message: Message, reactionType: number | null) => {
    FirebaseCreateMessageReaction(message, communityId, threadId, userId, reactionType);
    setSelectedMessage(null);
    if (reactionType) {
      logger.logEvent(AnalyticsEvent.REACTIONS_ADD, {
        context: 'message',
        reactionType,
      });
    } else {
      logger.logEvent(AnalyticsEvent.REACTIONS_REMOVE, {context: 'message'});
    }
  }, [threadId, userId]);


  const onReactionCountPress = useCallback((message:Message) => {
    setSelectedMessage(message);
    setShowReactionsListForMessage(true);
  }, []);

  const loggingParams = useCallback(() => {
    return {
      threadId,
      threadType,
    };
  }, [threadId, threadType]);

  const onSendPress = useCallback((messageText: string, mentions: { [userId: string]: Mention }, flags: { [flag: string]: boolean })
      : Promise<any> => {
    const numMentions = Object.keys(mentions).length;
    if (numMentions > 0) {
      // strip all parantheses from mentions
      messageText = messageText.replace(/\((@[^@]+?)\)/g, '$1');
    }
    const newMessageId = uuidv4();
    const message: Message = {
      content: messageText,
      createdAt: FirebaseServerTimestamp(),
      flags,
      // @ts-ignore
      mentions: numMentions > 0 ? mentions : null,
      ownerId: userId,
    };
    logger.logEvent(AnalyticsEvent.CHAT_NEW_MESSAGE, loggingParams());
    dispatch(IncrementUserInteraction(AnalyticsEvent.CHAT_NEW_MESSAGE));
    return addMessageToThread(newMessageId, message);
  }, [addMessageToThread, dispatch, loggingParams, userId]);

  const onFinishChoosingImage = useCallback((
      downloadUrlPromise: Promise<string>,
      metadata: ImageUploadMetadata)
      : Promise<void> => {
    return new Promise((resolve, reject) => {
      const {localUri, height, contentType, width} = metadata;
      logger.logEvent(AnalyticsEvent.CHAT_NEW_IMAGE, loggingParams());

      const tempMessage: Message = {
        contentType,
        createdAt: moment().valueOf(),
        image: 'local',
        imageHeight: height,
        imageWidth: width,
        ownerId: userId,
      };
      const newMessageId = uuidv4();
      const tempPhoto: SharedPhoto = {
        communityId,
        creationTimestamp: 0,
        imageHighRes: {
          dimensionsWidth: width,
          dimensionsHeight: height,
          url: localUri,
        },
        ownerId: userId,
        photoId: newMessageId,
        threadId,
      };

      if (IsPrivateThreadType(threadType)) {
        dispatch(ACTION_STORE_PRIVATE_CHAT_PHOTO({
          localUri,
          photo: tempPhoto,
          photoId: newMessageId,
          threadId,
        }));
      } else {
        dispatch(ACTION_STORE_COMMUNITY_PHOTO({
          communityId,
          localUri,
          photo: tempPhoto,
          photoId: newMessageId,
        }));
      }
      dispatch(ACTION_ADD_MESSAGE_UNSORTED({
        threadId,
        messageId: newMessageId,
        message: tempMessage,
      }));
      dispatch(ACTION_SET_CONTENT_SEND_STATE({
        contentId: newMessageId,
        sendState: SendState.STARTED,
      }));

      downloadUrlPromise
          .then((imageUrl) => {
            dispatch(IncrementUserInteraction(AnalyticsEvent.CHAT_NEW_IMAGE));
            const firebaseMessage = Object.assign({}, tempMessage, {
              image: imageUrl,
              createdAt: FirebaseServerTimestamp(),
            });
            return addMessageToThread(newMessageId, firebaseMessage);
          })
          .then(() => {
            dispatch(ACTION_SET_CONTENT_SEND_STATE({
              contentId: newMessageId,
              sendState: SendState.SUCCESS,
            }));
            resolve();
          })
          .catch(error => {
            dispatch(ACTION_SET_CONTENT_SEND_STATE({
              contentId: newMessageId,
              sendState: SendState.ERROR,
            }));
            console.error('error posting image', error.message);
            reject(error);
          });
    });
  }, []);

  const imageUploadPath = isPrivateChat
      ? `privateChats/photos/${threadId}`
      : `communities/${communityId}/threads/${threadId}`;

  return useMemo(() => ({
    imageUploadPath,
    loadingMoreMessages,
    numMessagesLoadedLast,
    onFinishChoosingImage,
    onJoinConversation,
    onLoadMoreMessages,
    onReactionCountPress,
    onReactionPress,
    onSendPress,
    replyToMessage,
    selectedMessage,
    setReplyToMessage,
    setSelectedMessage,
    showBottomMenu,
    showJoinButton,
    showLastReadMessageIndicator,
    showMessageInput,
    showReactionsListForMessage,
    stableLastReadTimestamp,
    threadMessagesAndUpdates,
    tutorialText,
  }), [
    imageUploadPath,
    loadingMoreMessages,
    numMessagesLoadedLast,
    onFinishChoosingImage,
    onJoinConversation,
    onLoadMoreMessages,
    onReactionCountPress,
    onReactionPress,
    onSendPress,
    replyToMessage,
    selectedMessage,
    setReplyToMessage,
    setSelectedMessage,
    showBottomMenu,
    showJoinButton,
    showLastReadMessageIndicator,
    showMessageInput,
    showReactionsListForMessage,
    stableLastReadTimestamp,
    threadMessagesAndUpdates,
    tutorialText,
  ]);

};