import {HeyloButton, HeyloDialog, HeyloText} from '../../lib/materialUi';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, {useEffect, useState} from 'react';
import {Colors, useRootStyles} from '../../styles/RootStyles';
import {Divider} from '../ui/Divider';
import {StripeUserHasValidPaymentMethod} from '@heylo/shared/src/features/stripe/User';
import {PaymentMethod} from '../payments/PaymentMethod';
import {
  EventDonationContext,
  EventDonationEvent,
} from '@heylo/shared/src/features/stripe/EventDonationMachine';
import {Interpreter} from 'xstate';
import {Event, StripeUser} from '@heylo/shared/src/types/firebase-types';
import Alert from '@material-ui/lab/Alert';
import Linkify from 'linkifyjs/react';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {Text, View} from 'react-native';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';

export enum EventDonateDialogState {
  HIDDEN,
  EDITING,
  SUBMITTED,
  ERROR,
};

type Props = {
  event: Event,
  initialAmountInCents: number,
  onClose: () => void,
  send?: Interpreter<EventDonationContext, any, EventDonationEvent>['send'],
  state: EventDonateDialogState,
  stripeUser: StripeUser,
};

const TOS_URL = 'https://join.heylo.co/terms';

export const EventDonateDialog = (props: Props) => {
  const {
    event,
    initialAmountInCents,
    onClose,
    send,
    state,
    stripeUser,
  } = props;

  const rootClasses = useRootStyles();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  useEffect(() => {
    setHasPaymentMethod(StripeUserHasValidPaymentMethod(stripeUser));
  }, [stripeUser]);

  const [donationAmountDollarsString, setDonationAmountDollarsString] = useState('');
  const updateText = (event: React.ChangeEvent<HTMLInputElement>) => {
    let text = event.target.value;
    if (text.startsWith('$')) {
      text = text.slice(1);
    }
    setDonationAmountDollarsString(text);
  };

  const [donationAmountInCents, setDonationAmountInCents] = useState(0);
  useEffect(() => {
    setDonationAmountDollarsString(`${initialAmountInCents / 100}`);
  }, [initialAmountInCents]);

  useEffect(() => {
    const amountInDollars = Number(donationAmountDollarsString) || 0;
    const amountInCents = amountInDollars * 100;
    setDonationAmountInCents(amountInCents);
  }, [donationAmountDollarsString]);


  const handleDonateClick = () => {
    send && send({
      type: 'DONATION_STARTED',
      amountInCents: donationAmountInCents,
      stripeUser,
    })
  };

  const text = useTextStyles();

  return (
      <HeyloDialog
          fullScreen={matchXs}
          fullWidth
          maxWidth={'sm'}
          onClose={onClose}
          open={state !== EventDonateDialogState.HIDDEN}
          title={'Event donation'}
      >
        <HeyloText variant={'h5'}>
          Please confirm donation amount for {event.name || 'the event'}:
        </HeyloText>

        <View style={{
          alignItems: 'baseline',
          flexDirection: 'row',
          justifyContent: 'center',
          marginVertical: StyleConstants.SPACING * 2,
        }}>
          <Text style={[text.body1, text.bold, {color: Colors.Primary}]}>
            USD
          </Text>
          <input
              autoFocus={true}
              maxLength={4}
              onChange={updateText}
              style={{
                borderWidth: 0,
                color: Colors.Primary,
                width: 100,
                fontSize: '4rem',
                fontWeight: 'bold',
                marginLeft: 5,
                outlineWidth: 0,
                textAlign: 'left',
              }}
              type={'text'}
              value={`$${donationAmountInCents ? Math.round(donationAmountInCents / 100) : ''}`}
          />
        </View>

        <Divider/>

        <div className={'flexcolumn flexcenter'}>
          {hasPaymentMethod ? (
              <>
                <div style={{width: '100%'}}>
                  <HeyloText variant={'h5'}>
                    Payment method
                  </HeyloText>
                </div>
                <PaymentMethod stripeUser={stripeUser!}/>
                <Divider/>

                <Linkify options={{
                  className: rootClasses.textLink,
                  format: (value, type) => {
                    if (value === TOS_URL) {
                      return 'Terms of Service';
                    }
                    return value;
                  },
                }}>
                  <HeyloText style={{marginTop: theme.spacing(1)}}
                             variant={'body1'}>
                    By confirming your donation, you agree for Heylo to charge this payment in accordance with the Heylo {TOS_URL}
                  </HeyloText>
                </Linkify>
              </>
          ) : (
              <>
                <HeyloText align={'center'} variant={'body1'}>
                  In the next step, we'll add your payment info.
                </HeyloText>
                <HeyloText align={'center'} variant={'body1'}>
                  You'll only have to do this once!
                </HeyloText>

                <div className={'flexrow flexcenter'}
                     style={{marginTop: theme.spacing(1)}}>
                  <img
                      alt={'Apple Pay'}
                      src={'/assets/apple-pay.png'}
                      style={{
                        height: '3rem',
                        objectFit: 'contain',
                        width: '10%',
                      }}
                  />
                  <img
                      alt={'Google Pay'}
                      src={'/assets/google-pay.png'}
                      style={{
                        height: '3rem',
                        objectFit: 'contain',
                        width: '10%',
                      }}
                  />
                </div>
                <div className={'flexrow flexcenter'}
                     style={{marginTop: theme.spacing(1)}}>
                  <img
                      alt={'Visa'}
                      src={'/assets/visa.png'}
                      style={{
                        height: '3rem',
                        objectFit: 'contain',
                        width: '10%',
                      }}
                  />
                  <img
                      alt={'Mastercard'}
                      src={'/assets/mastercard.png'}
                      style={{
                        height: '3rem',
                        objectFit: 'contain',
                        width: '10%',
                      }}
                  />
                  <img
                      alt={'American Express'}
                      src={'/assets/american-express.png'}
                      style={{
                        height: '3rem',
                        objectFit: 'contain',
                        width: '10%',
                      }}
                  />
                  <img
                      alt={'Diners Club'}
                      src={'/assets/diners.png'}
                      style={{
                        height: '3rem',
                        objectFit: 'contain',
                        width: '10%',
                      }}
                  />
                  <img
                      alt={'Discover'}
                      src={'/assets/discover.png'}
                      style={{
                        height: '3rem',
                        objectFit: 'contain',
                        width: '10%',
                      }}
                  />
                </div>
              </>
          )}

          <div className={'flexcolumn flexcenter'} style={{
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(3),
          }}>
            {state === EventDonateDialogState.ERROR && (
                <Alert severity={'error'}
                       style={{marginBottom: theme.spacing(2)}}>
                  Something went wrong! Please try again or contact support@heylo.co.
                </Alert>
            )}
            <HeyloButton
                busy={state === EventDonateDialogState.SUBMITTED}
                color={'primary'}
                disabled={!donationAmountInCents}
                fullWidth={false}
                label={hasPaymentMethod
                    ? `Confirm $${Math.round(donationAmountInCents / 100)} donation`
                    : 'Continue'}
                onClick={handleDonateClick}
            />
          </div>

        </div>
      </HeyloDialog>
  );
};