import {createSelector} from 'reselect';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {Platform} from 'react-native';

export function haveRequestedIosNotificationPermissionsSelector(state: RootState) {
  return (state.notifications.fieldHaveRequestedIosNotificationPermissions || 0) > 0;
}

export function threadNewMemberNotificationsEnabledSelector(state: RootState, threadId: string) {
  const defaultValue = false;
  return state.notifications.chatNewMemberNotificationsPerThread[threadId] ?? defaultValue;
}

export const threadNewMessageNotificationsSelector = (state: RootState) =>
    state.notifications.individualChatNewMessageNotifications || {};

export function threadNewMessageNotificationsEnabledSelector(state: RootState, threadId: string) {
  // As of v1.3.4 (2019-03-14), we no longer default users into threads.
  const defaultValue = false;
  return state.notifications.individualChatNewMessageNotifications[threadId] ?? defaultValue;
}

export function chatsMentionsNotificationsEnabledSelector(state: RootState) {
  return state.notifications.communityChatsMentionNotifications ?? true;
}

export function chatsNewMembersNotificationsEnabledSelector(state: RootState) {
  return state.notifications.chatNewMemberNotifications ?? true;
}

export function chatsNewMessageNotificationsEnabledSelector(state: RootState) {
  return state.notifications.communityChatNewMessageNotifications ?? true;
}

export function communityNewMemberNotificationsEnabledSelector(state: RootState) {
  return state.notifications.communityNewMembersNotifications ?? true;
}

export function communityNewTopicNotificationsEnabledSelector(state: RootState) {
  return state.notifications.communityNewTopicNotifications ?? true;
}

export function eventDayOfReminderNotificationsEnabledSelector(state: RootState) {
  return state.notifications.eventDayOfReminderNotifications ?? true;
}

export function eventNewNotificationsEnabledSelector(state: RootState) {
  return state.notifications.eventNewNotifications ?? true;
}

export function eventUpdatedNotificationsEnabledSelector(state: RootState) {
  return state.notifications.eventUpdatedNotifications ?? true;
}

export function eventWeeklyReminderNotificationsEnabledSelector(state: RootState) {
  return state.notifications.eventWeeklyReminderNotifications ?? true;
}

export function iosNotificationPermissionAlertSelector(state: RootState) {
  return state.notifications.iosNotificationPermissionAlert ?? true;
}

export function iosNotificationPermissionBadgeSelector(state: RootState) {
  return state.notifications.iosNotificationPermissionBadge ?? true;
}

export function iosNotificationPermissionSoundSelector(state: RootState) {
  return state.notifications.iosNotificationPermissionSound ?? true;
}

export function appCanReceiveNotificationsSelector(state: RootState, platform: string)
    : boolean {
  switch (platform) {
        case 'android':
          // TODO: support android better
          return true;
        case 'ios':
      return iosNotificationPermissionAlertSelector(state) || iosNotificationPermissionBadgeSelector(state);
        default:
          return false;
      }
}