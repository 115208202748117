import React, {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {selectActiveUserCommunityIds} from '@heylo/shared/src/features/userSettings/Selectors';
import {LoadingScreen} from '@heylo/components/src/features/loading/LoadingScreen';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {Redirect, useLocation, useParams} from 'react-router-dom';

type State = {
  isMember: boolean | null,
  resolvedCommunityId: string,
};

export const MembersOnlyRedirect = ({children}: { children: JSX.Element }) => {

  const [state, setState] = useState<State>({
    isMember: null,
    resolvedCommunityId: '',
  });
  const {isMember, resolvedCommunityId} = state;

  const location = useLocation();
  const {communityId = ''} = useParams();

  const {memberCommunityIds} = useSelector((state: RootState) => ({
    memberCommunityIds: selectActiveUserCommunityIds(state),
  }), shallowEqual);


  useEffect(() => {
    if (!memberCommunityIds) {
      return;
    }
    const searchParams = new URLSearchParams(location.search)
    const resolvedCommunityId = communityId || searchParams.get('communityId') || searchParams.get('id') || '';
    const memberCommunities = new Set<string>(memberCommunityIds);
    setState({
      isMember: memberCommunities.has(resolvedCommunityId),
      resolvedCommunityId,
    });
  }, [memberCommunityIds, location, communityId]);

  if (typeof isMember !== 'boolean') {
    return <LoadingScreen/>;
  }
  if (isMember) {
    return children;
  }
  return (
      <Redirect
          to={{
            pathname: `/g/${resolvedCommunityId}`,
            state: {from: location},
          }}
      />
  );
};