import {useHistory} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {
  NewCommunityDetailsForm,
  NewCommunityDetailsFormState,
} from '@heylo/components/src/features/communityCreation/NewCommunityDetailsForm';
import {NextButton, ProgressHeader} from 'features/navigation/ProgressHeader';

export const NewGroupDetailsScreen = () => {
  const history = useHistory();

  const [state, setState] = useState(NewCommunityDetailsFormState.IDLE);

  useEffect(() => {
    switch (state) {
      case NewCommunityDetailsFormState.SUCCESS:
        history.push('/newGroup/review');
        setState(NewCommunityDetailsFormState.IDLE);
        break;
    }
  }, [state]);

  const onNextPress = () => {
    setState(NewCommunityDetailsFormState.SUBMITTING);
  }

  const onBackPress = () => {
    history.goBack();
  }

  return (
      <ProgressHeader
          onBackPress={onBackPress}
          nextButton={state === NewCommunityDetailsFormState.IDLE ? NextButton.SKIP : NextButton.NEXT }
          onNextPress={onNextPress}
          percent={'80%'}
          title={'Update group page'}
      >
        <NewCommunityDetailsForm
            setState={setState}
            state={state}
        />
      </ProgressHeader>
  );
};