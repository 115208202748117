import React, {CSSProperties} from 'react';
import Cake from '@material-ui/icons/Cake';
import Lock from '@material-ui/icons/Lock';
import ForumOutlined from '@material-ui/icons/ForumOutlined';
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  ThreadIconType,
  useThreadImageState,
} from '@heylo/shared/src/features/threads/useThreadImageState';
import {Thread} from '@heylo/shared/src/types/firebase-types';

type Props = {
  hasUnreadMessages?: boolean,
  thread: Thread,
};

export const ThreadImage = (props: Props) => {
  const {
    hasUnreadMessages,
    thread,
  } = props;

  const {threadId = ''} = thread;

  const {
    backgroundColor,
    imageUrls,
    imageStyles,
    icon,
  } = useThreadImageState({
    hasUnreadMessages,
    imageScaling: ['100%', '66%', '50%'],
    thread,
  });

  const classes = useStyles();

  if (imageUrls.length > 0) {
    const renderImages = (imageUrls: string[], styles: CSSProperties[]) => {
      return (
          <>
            {imageUrls.map((uri, index) => {
              return (
                  <div className={classes.containerImageOuter}
                       key={`topicImage${threadId}${index}`}
                       style={styles[index]}
                  >
                    <img alt={''}
                         className={classes.containerImageInner}
                         src={uri}
                    />
                  </div>
              );
            })}
          </>
      );
    };
    return (
        <div className={classes.container}>
          {renderImages(imageUrls, imageStyles)}
        </div>
    );
  }

  const iconStyles =  {
    color: 'white',
    fontSize: '3rem',
  }
  let iconElement: React.ReactElement = <PeopleAlt  style={iconStyles}/>;
  switch (icon) {
    case ThreadIconType.ANNOUNCEMENTS:
      iconElement = <i className={'fa fa-bullhorn'} style={iconStyles}/>
      break;
    case ThreadIconType.PRIVATE:
      iconElement = <Lock style={iconStyles}/>;
      break;
    case ThreadIconType.GENERAL:
      iconElement = <ForumOutlined style={iconStyles}/>;
      break;
    case ThreadIconType.SOCIAL:
      iconElement = <Cake style={iconStyles}/>;
      break;
  }

  return (
      <div className={classes.container}
           style={{
             backgroundColor,

           }}>
        {iconElement}
      </div>
  );
};

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    borderRadius: 100,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  containerImageInner: {
    alignItems: 'center',
    borderRadius: 100,
    borderColor: '#fff9',
    justifyContent: 'center',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  containerImageOuter: {
    alignItems: 'center',
    border: '2px solid white',
    borderRadius: 100,
    justifyContent: 'center',
    position: 'absolute',
  },
});