import {connect, ConnectedProps} from 'react-redux';
import {
  selectActiveUserEmail,
  selectActiveUserFullName,
} from '@heylo/shared/src/features/userSettings/Selectors';
import {
  SelectActiveCommunity,
  SelectActiveCommunityId,
} from '@heylo/shared/src/features/communities/Selectors';
import {HeyloButton, HeyloText} from '../../lib/materialUi';
import React, {useEffect, useState} from 'react';
import {GreenBadge} from '../ui/GreenBadge';
import useTheme from '@material-ui/core/styles/useTheme';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  selectActiveCommunityStripeAccountId,
  selectLinkedStripeAccount,
} from '@heylo/shared/src/features/stripe/Selectors';
import {useCommunityPaymentSettingsMachine} from '@heylo/shared/src/features/stripe/useCommunityPaymentSettingsMachine';
import {StripeExternalAccount} from './StripeExternalAccount';
import WarningIcon from '@material-ui/icons/Warning';
import {StripeAccount} from '@heylo/shared/src/types/firebase-types';
import {StripeAccountStatus} from '@heylo/shared/src/features/stripe/Account';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {RootState} from '@heylo/shared/src/services/redux/Redux';


type PureProps = {
  oauthUrl?: string,
  stripeAccount?: StripeAccount,
  stripeAccountStatus: StripeAccountStatus,
  stripeDashboardLink?: string,
  sync?: () => void,
};

export const PureStripeConnect = (props: PureProps) => {
  const {
    oauthUrl,
    stripeAccount,
    stripeAccountStatus,
    stripeDashboardLink,
    sync,
  } = props;

  const logger = useLoggingService();
  const [startOauthFlow, setStartOauthFlow] = useState(false);
  const handleStripeConnect = () => {
    logger.logEvent(AnalyticsEvent.PAYMENTS_SETUP);
    setStartOauthFlow(true);
  }
  useEffect(() => {
    if (startOauthFlow && oauthUrl) {
      window.open(oauthUrl, '_blank', 'noreferrer');
      setStartOauthFlow(false);
    }
  }, [startOauthFlow, oauthUrl]);

  const [dashboardLinkPressed, setDashboardLinkPressed] = useState(false);
  const handleManageAccountPress = () => {
    logger.logEvent(AnalyticsEvent.PAYMENTS_ACCOUNT_DASHBOARD);
    setDashboardLinkPressed(true);
    sync && sync();
  };
  useEffect(() => {
    if (dashboardLinkPressed && stripeDashboardLink) {
      window.open(stripeDashboardLink, '_blank', 'noreferrer');
      setDashboardLinkPressed(false);
    }
  }, [dashboardLinkPressed, stripeDashboardLink]);

  const renderBenefit = (text: string) => {
    return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '1rem 0',
        }}>
          <CheckCircleIcon color={'primary'}/>
          <HeyloText
              style={{
                display: 'inline-block',
                marginLeft: 5,
                marginTop: 2,
                minWidth: 0,
              }}
              variant={'body1'}
          >
            {text}
          </HeyloText>
        </div>
    );
  };

  const theme = useTheme();

  return (
      <div>
        <div style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}>
          <HeyloText
              style={{display: 'inline-block', marginRight: theme.spacing(0.5)}}
              variant={'h5'}>
            Payment Settings
          </HeyloText>
          {stripeAccountStatus === StripeAccountStatus.DOES_NOT_EXIST &&
          <GreenBadge value={'NEW'}/>}
        </div>

        {stripeAccountStatus === StripeAccountStatus.DOES_NOT_EXIST ? (
            <>
              <HeyloText style={{margin: '1rem 0'}} variant={'body1'}>
                Enable payments to collect donations for some or all of your events.
              </HeyloText>

              {renderBenefit('Remove the friction — no more sharing payment instructions! Donations can be made right from Heylo anyway your members want to pay (credit card, Apple Pay, Google Pay, etc.)')}
              {renderBenefit('More donations with Heylo automated reminders')}
              {renderBenefit('️Larger donations with suggested donation amounts')}
              {renderBenefit('Payment information is saved for next time! Your members only need to enter payment info once')}
            </>
        ) : stripeAccountStatus === StripeAccountStatus.ERROR ? (
            <div style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              margin: '1rem 0',
            }}>
              <WarningIcon color={'error'}/>
              <HeyloText
                  style={{
                    display: 'flex',
                    minWidth: 0,
                    marginLeft: 10,
                  }}
                  variant={'body1'}
              >
                Unable to sync with Stripe. Please try again later or contact support@heylo.co.
              </HeyloText>
            </div>
        ) : (
            <div onClick={handleManageAccountPress} style={{cursor: 'pointer'}}>
              <StripeExternalAccount account={stripeAccount ?? {}}/>
            </div>
        )}

        <HeyloText
            style={{color: '#777'}}
            variant={'body1'}
        >
          Payments will be deposited directly into your bank account every Friday and are subject to a 10% Heylo platform fee, and Stripe transaction fees of 2.9% + $0.30. For annual income over $600, tax forms will be sent from Stripe at year end.
        </HeyloText>

        {stripeAccountStatus === StripeAccountStatus.DOES_NOT_EXIST && (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: theme.spacing(1),
            }}>
              <HeyloButton
                  busy={startOauthFlow}
                  color={'secondary'}
                  fullWidth={false}
                  label={'Link bank account'}
                  onClick={handleStripeConnect}
                  startIcon={<AccountBalanceIcon/>}
              />
            </div>
        )}
      </div>
  );
};


const mapState = (state: RootState) => {
  return {
    communityId: SelectActiveCommunityId(state),
    communityName: SelectActiveCommunity(state)?.name ?? '',
    linkedStripeAccount: selectLinkedStripeAccount(state),
    linkedStripeAccountId: selectActiveCommunityStripeAccountId(state),
    userEmail: selectActiveUserEmail(state),
    userFullName: selectActiveUserFullName(state),
  };
};
const connector = connect(mapState, {});
type Props = ConnectedProps<typeof connector>;

export const StripeConnect = connector((props: Props) => {
  const {
    communityId,
    communityName,
    linkedStripeAccount,
    linkedStripeAccountId,
    userEmail,
    userFullName,
  } = props;

  const [state, sync] = useCommunityPaymentSettingsMachine({
    account: linkedStripeAccount,
    accountId: linkedStripeAccountId,
    buildVariant: process.env.REACT_APP_BUILD_VARIANT ?? '',
    communityId,
    communityName,
    userEmail,
    userFullName,
  });

  const {
    account,
    accountStatus,
    oauthUrl,
    stripeDashboardLink,
  } = state.context;

  return <PureStripeConnect
      oauthUrl={oauthUrl}
      stripeAccount={account}
      stripeAccountStatus={accountStatus}
      stripeDashboardLink={stripeDashboardLink}
      sync={sync}
  />
});
