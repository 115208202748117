import {
  CommunityPaymentSettingsContext,
  CommunityPaymentSettingsEvent,
  CommunityPaymentSettingsMachine,
} from '@heylo/shared/src/features/stripe/CommunityPaymentSettingsMachine';
import {StripeAccount} from '@heylo/shared/src/types/firebase-types';
import {useMachine} from '@xstate/react/lib';
import {useEffect} from 'react';
import {ResolveFirstAndLastName} from '@heylo/shared/src/util/strings';
import {StripeFirebase} from '@heylo/shared/src/features/stripe/Firebase';
import {useDispatch} from 'react-redux';
import {State} from 'xstate';

type Props = {
  account: StripeAccount | null,
  accountId: string,
  buildVariant: string,
  communityId: string,
  communityName: string,
  userEmail: string,
  userFullName: string,
};

export const useCommunityPaymentSettingsMachine = (props: Props): [
  State<CommunityPaymentSettingsContext, CommunityPaymentSettingsEvent>,
  () => void
] => {
  const {
    account,
    accountId,
    buildVariant,
    communityId,
    communityName,
    userEmail,
    userFullName,
  } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    if (communityId) {
      dispatch(StripeFirebase.CommunityMetadataListener(communityId));
    }
  }, [communityId]);

  const [state, send] = useMachine(CommunityPaymentSettingsMachine.withContext({
    ...CommunityPaymentSettingsMachine.context!,
    accountId,
    buildVariant,
  }));

  useEffect(() => {
    if (account) {
      send({type: 'ACCOUNT_UPDATED', account});
    } else {
      const [userFirstName, userLastName] = ResolveFirstAndLastName(userFullName);
      send({
        type: 'ACCOUNT_NOT_FOUND',
        communityId,
        communityName,
        userEmail,
        userFirstName,
        userLastName,
      });
    }
  }, [account]);

  return [
    state,
    () => send({type: 'SYNC_ACCOUNT'}),
  ];
};