import {useCallback, useEffect, useState} from 'react';
import isEmail from 'validator/lib/isEmail';
import {AuthFirebase} from '@heylo/shared/src/features/auth/Firebase';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {useHeyloConfig} from '@heylo/shared/src/features/app/useHeyloConfig';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {EmailVerificationContext, SignInWithEmailReset} from './Slice';
import {FirebaseAuth} from '@heylo/firebase-database';
import {RootState} from '../../services/redux/Redux';
import {SelectAreCommunitiesLoaded} from '../communities/Selectors';

export enum LoginState {
  EMAIL_REQUIRED,
  EMAIL_SUBMITTED,
  EMAIL_ERROR,
  EMAIL_LINK_SENT,
  PASSWORD_REQUIRED,
  PASSWORD_SUBMITTED,
  PASSWORD_ERROR,
  ERROR_OTHER,
  SUCCESS,
  READY_TO_NAVIGATE,
}

export const useLoginScreenState = () => {
  const dispatch = useDispatch();
  const logger = useLoggingService();
  const heyloConfig = useHeyloConfig();

  const [state, setState] = useState(LoginState.EMAIL_REQUIRED);
  const [accountHasPassword, setAccountHasPassword] = useState(false);

  const {allGroupsLoaded} = useSelector((state: RootState) => ({
    allGroupsLoaded: SelectAreCommunitiesLoaded(state),
  }), shallowEqual);

  useEffect(() => {
    if (state === LoginState.SUCCESS && allGroupsLoaded) {
      setState(LoginState.READY_TO_NAVIGATE);
    }
  }, [state, allGroupsLoaded]);

  const onSubmitEmail = useCallback((email: string) => {
    if (!isEmail(email)) {
      setState(LoginState.EMAIL_ERROR);
      return;
    }
    setState(LoginState.EMAIL_SUBMITTED);
    FirebaseAuth().fetchSignInMethodsForEmail(email)
        .then(methods => {
          let emailLink = false;
          let password = false;
          methods.forEach(value => {
            switch (value) {
              case 'password':
                password = true;
                return;
              case 'emailLink':
                emailLink = true;
            }
          });
          setAccountHasPassword(password);
          if (emailLink) {
            FirebaseAuth().signInAnonymously()
                .then(() => {
                  return dispatch(AuthFirebase.SendSignInWithEmailLink({
                    build: heyloConfig.BUILD_VARIANT_STRING,
                    context: EmailVerificationContext.LOGIN,
                    email,
                    fullName: '',
                  }));
                })
                // @ts-ignore
                .then(() => {
                  setState(LoginState.EMAIL_LINK_SENT);
                })
                .catch((e: Error) => {
                  console.warn('error sending email link', e);
                  setState(LoginState.EMAIL_ERROR);
                });
            return;
          }
          if (password) {
            setState(LoginState.PASSWORD_REQUIRED);
            return;
          }
          setState(LoginState.ERROR_OTHER);
        })
        .catch(e => {
          console.warn('error getting signin methods for email', email, e);
          setState(LoginState.ERROR_OTHER);
        });
  }, []);

  const onSubmitEmailPassword = useCallback((email: string, password: string) => {
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();
    setState(LoginState.PASSWORD_SUBMITTED);
    FirebaseAuth().signInWithEmailAndPassword(trimmedEmail, trimmedPassword)
        .then(() => {
          logger.logEvent(AnalyticsEvent.REGISTRATION_LOG_IN_SUCCESS);
          setState(LoginState.SUCCESS);
        })
        .catch((e: Error) => {
          console.warn('error signing in', e);
          setState(LoginState.PASSWORD_ERROR);
          logger.logEvent(AnalyticsEvent.REGISTRATION_LOG_IN_FAILED);
        });
  }, []);

  const onSignInWithEmailLink = useCallback((email: string, signInLink: string) => {
    return FirebaseAuth().signInWithEmailLink(email.trim(), signInLink)
        .then(() => {
          logger.logEvent(AnalyticsEvent.REGISTRATION_LOG_IN_SUCCESS);
          setState(LoginState.SUCCESS);
          return Promise.resolve('');
        })
        .catch((e: Error) => {
          console.warn('error signing in', e);
          setState(LoginState.ERROR_OTHER);
          logger.logEvent(AnalyticsEvent.REGISTRATION_LOG_IN_FAILED);
          return Promise.reject(e);
        });
  }, []);

  const onSwitchToPassword = useCallback(() => {
    setState(LoginState.PASSWORD_REQUIRED);
  }, []);

  const onReset = () => {
    dispatch(SignInWithEmailReset());
    setAccountHasPassword(false);
    setState(LoginState.EMAIL_REQUIRED);
  }

  return {
    accountHasPassword,
    onReset,
    onSignInWithEmailLink,
    onSubmitEmail,
    onSubmitEmailPassword,
    onSwitchToPassword,
    state,
  };
};