import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment';
import {
  CommunityDiscoverySpec,
  CommunitySearchIndexSpec,
} from '@heylo/shared/src/types/firebase-types';
import _ from 'lodash';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {DeepReadonly} from 'utility-types';
import {AppReset} from '@heylo/shared/src/features/app/Actions';

export type SearchState = {
  LAST_UPDATE_TIMESTAMP: number,
  SEARCH_INDEX: DeepReadonly<{ [communityId: string]: CommunitySearchIndexSpec }>,
}

const initialState: SearchState = {
  LAST_UPDATE_TIMESTAMP: 0,
  SEARCH_INDEX: {},
};

const populateCommunityIds =
    (obj: { [communityId: string]: CommunitySearchIndexSpec | CommunityDiscoverySpec }) =>
        _.mapValues(obj, (value, key) => ({...value, communityId: key}));

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchIndex: (state, action: PayloadAction<{ [communityId: string]: CommunitySearchIndexSpec }>) => {
      const searchIndex = action.payload;
      if (Object.keys(searchIndex).length > 0) {
        state.LAST_UPDATE_TIMESTAMP = moment().valueOf();
        state.SEARCH_INDEX = populateCommunityIds(searchIndex);
      }
    },
  },

  extraReducers: builder => builder
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState)
});

const {actions, reducer: SearchReducer} = slice;

const {
  updateSearchIndex,
} = actions;

export {
  updateSearchIndex,
  SearchReducer
};
