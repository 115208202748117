import React from 'react';
import {Modal, Portal, Surface} from 'react-native-paper';

export const HeyloModal = ({children, visible, onDismiss}: {
  children: React.ReactNode,
  onDismiss: () => void
  visible: boolean,
}) => {
  return (
      <Portal>
        <Modal visible={visible} onDismiss={onDismiss}>
          <Surface style={{
            alignSelf: 'center',
            padding: 20,
            width: '80%',
          }}>
            {children}
          </Surface>
        </Modal>
      </Portal>
  );
};