import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {
  Community,
  Message,
  PrivateChatScopedDeletion,
  Thread,
  ThreadMember,
  ThreadStatePerUser,
  ThreadUpdate,
  UserChatState,
} from '@heylo/shared/src/types/firebase-types';
import {AppColdStart, AppReset} from '@heylo/shared/src/features/app/Actions';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {DeepReadonly} from 'utility-types';
import {CommunityUpdated, UserLeftCommunity} from '../communities/Slice';
import {
  THREAD_TYPE_ANNOUNCEMENT,
  THREAD_TYPE_EVENT,
} from '@heylo/shared/src/types/ThreadTypes';
import {
  EventRemoved,
  EventUpdated,
} from '@heylo/shared/src/features/events/Slice';
import _ from 'lodash';
import {Platform} from 'react-native';

export type ThreadsState = {
  FIELD_COMMUNITY_THREAD_IDS: DeepReadonly<{ [communityId: string]: { [threadId: string]: boolean } }>,

  LAST_READ_TIMESTAMPS: DeepReadonly<{ [threadId: string]: number }>,

  FIELD_THREADS: DeepReadonly<{ [threadId: string]: Thread }>,

  FIELD_THREAD_JOIN_STATE: DeepReadonly<{ [threadId: string]: boolean }>,

  FIELD_THREAD_LAST_ACTIVITY_TIMESTAMPS: DeepReadonly<{ [threadId: string]: number }>,
  FIELD_THREAD_LAST_USER_MESSAGE_TIMESTAMPS: DeepReadonly<{ [threadId: string]: number }>,

  FIELD_THREAD_MEMBERS: DeepReadonly<{ [threadId: string]: { [userId: string]: ThreadMember } }>,

  FIELD_THREAD_MESSAGES: DeepReadonly<{ [threadId: string]: { [messageId: string]: Message } }>,

  // Map of threadIds to a map of ThreadUpdates.
  FIELD_THREAD_UPDATES: DeepReadonly<{ [threadId: string]: { [updateId: string]: ThreadUpdate } }>,

  // UI related state.
  CURRENT_THREAD_ID: string,

  // If additional chats are pushed onto the stack, inactive ones will be added
  // here. As of 2020-06, this is only used to allow navigating to direct chats
  // from a community chat, via member profiles.
  FIELD_THREAD_ID_STACK: ReadonlyArray<string>,

  loadingMoreMessages: boolean,
  numMessagesLoadedLast: number,

  // Stores the greatest timestamp of all loaded messages for all thread.
  threadLoadedMessagesLatestTimestamp: DeepReadonly<{ [threadId: string]: number }>,
  threadMemberCounts: DeepReadonly<{ [threadId: string]: number }>,
  threadMostRecentContributorUserIds: DeepReadonly<{ [threadId: string]: string[] }>,
  threadUnreadMessages: DeepReadonly<{ [threadId: string]: number }>,

  FIELD_PRIVATE_CHAT_DELETIONS: DeepReadonly<{ [threadId: string]: { [deletionId: string]: PrivateChatScopedDeletion } }>,
  FIELD_PRIVATE_CHAT_THREAD_ID_TO_USER_ID: DeepReadonly<{ [threadId: string]: string }>,
  FIELD_PRIVATE_CHAT_USER_ID_TO_THREAD_ID: DeepReadonly<{ [userId: string]: string }>,
  privateThreadMostRecentDeletionTimestamp: DeepReadonly<{ [threadId: string]: number }>,

  FIELD_THREAD_INFO_LAST_VIEWED_TIMESTAMP: DeepReadonly<{ [threadId: string]: number }>,
};

const initialState: ThreadsState = {
  FIELD_COMMUNITY_THREAD_IDS: {},
  LAST_READ_TIMESTAMPS: {},
  FIELD_THREADS: {},
  FIELD_THREAD_JOIN_STATE: {},
  FIELD_THREAD_LAST_ACTIVITY_TIMESTAMPS: {},
  FIELD_THREAD_LAST_USER_MESSAGE_TIMESTAMPS: {},
  FIELD_THREAD_MEMBERS: {},
  FIELD_THREAD_MESSAGES: {},
  FIELD_THREAD_UPDATES: {},
  CURRENT_THREAD_ID: '',
  FIELD_THREAD_ID_STACK: [],
  loadingMoreMessages: false,
  numMessagesLoadedLast: -1,
  threadMostRecentContributorUserIds: {},
  threadLoadedMessagesLatestTimestamp: {},
  threadMemberCounts: {},
  threadUnreadMessages: {},
  FIELD_PRIVATE_CHAT_DELETIONS: {},
  FIELD_PRIVATE_CHAT_THREAD_ID_TO_USER_ID: {},
  FIELD_PRIVATE_CHAT_USER_ID_TO_THREAD_ID: {},
  privateThreadMostRecentDeletionTimestamp: {},
  FIELD_THREAD_INFO_LAST_VIEWED_TIMESTAMP: {},
};


const addThreadMessage = (threadId: string, messageId: string, message: Message, state: Draft<ThreadsState>) => {
  if (!state.FIELD_THREAD_MESSAGES[threadId]) {
    state.FIELD_THREAD_MESSAGES[threadId] = {};
  }

  state.FIELD_THREAD_MESSAGES[threadId][messageId] = Object.assign(
      state.FIELD_THREAD_MESSAGES[threadId][messageId] ?? {},
      {
        ...message,
        key: messageId,
      });

  if (!state.threadLoadedMessagesLatestTimestamp) {
    state.threadLoadedMessagesLatestTimestamp = {};
  }
  const {createdAt, ownerId} = message;
  state.threadLoadedMessagesLatestTimestamp[threadId] = Math.max(
      state.threadLoadedMessagesLatestTimestamp[threadId] || 0,
      createdAt || 0);

  if (ownerId) {
    if (!state.threadMostRecentContributorUserIds) {
      state.threadMostRecentContributorUserIds = {};
    }
    let userIds = Array.from(state.threadMostRecentContributorUserIds[threadId] ?? []);
    userIds = userIds.filter(userId => userId !== ownerId);
    userIds.unshift(ownerId);
    state.threadMostRecentContributorUserIds[threadId] = userIds.slice(0, 3);
  }
};

const updateThread = (communityId: string, threadId: string, thread: DeepReadonly<Thread>, state: Draft<ThreadsState>) => {
  if (!threadId) {
    return;
  }
  const originalThread = Object.assign({}, state.FIELD_THREADS[threadId] || {});
  const mergedThread = Object.assign({}, originalThread, thread, {
    communityId,
    threadId,
  });
  if (_.isEqual(originalThread, mergedThread)) {
    return;
  }
  state.FIELD_THREADS[threadId] = mergedThread;
  if (communityId) {
    if (!state.FIELD_COMMUNITY_THREAD_IDS[communityId]) {
      state.FIELD_COMMUNITY_THREAD_IDS[communityId] = {};
    }
    state.FIELD_COMMUNITY_THREAD_IDS[communityId][threadId] = true;
  }
};

const removeThread = (communityId: string, threadId: string, state: Draft<ThreadsState>) => {
  if (communityId) {
    delete state.FIELD_COMMUNITY_THREAD_IDS?.[communityId]?.[threadId];
  }
  delete state.LAST_READ_TIMESTAMPS?.[threadId];
  delete state.FIELD_THREAD_JOIN_STATE?.[threadId];
  delete state.FIELD_THREAD_LAST_ACTIVITY_TIMESTAMPS?.[threadId];
  delete state.FIELD_THREAD_MEMBERS?.[threadId];
  delete state.FIELD_THREAD_MESSAGES?.[threadId];
  delete state.FIELD_THREAD_UPDATES?.[threadId];
  delete state.FIELD_THREADS?.[threadId];
};

const setActiveThread = (threadId: string, state: Draft<ThreadsState>) => {
  state.CURRENT_THREAD_ID = threadId;
  state.loadingMoreMessages = false;
  state.numMessagesLoadedLast = -1;
};

export const SortThreadMessages = (messageMap: { [messageId: string]: Message }): Array<[string, Message]> => {
  const messageArray = Object.entries(messageMap);
  return _.sortBy(messageArray, ([messageId, message]) => message.createdAt || 0).reverse();
};

const updateThreadLastActivityTimestamp = (threadId: string, timestamp: number, state: Draft<ThreadsState>) => {
  if (threadId && timestamp > 0) {
    state.FIELD_THREAD_LAST_ACTIVITY_TIMESTAMPS[threadId] = timestamp;
  }
}

const updateThreadLastReadTimestamp = (threadId: string, timestamp: number, state: Draft<ThreadsState>) => {
  if (timestamp && timestamp > (state.LAST_READ_TIMESTAMPS[threadId] || 0)) {
    state.LAST_READ_TIMESTAMPS[threadId] = timestamp;
    state.threadUnreadMessages[threadId] = 0;
  }
}

const updateThreadLastUserMessageTimestamp = (threadId: string, timestamp: number, state: Draft<ThreadsState>) => {
  if (threadId && timestamp > 0) {
    state.FIELD_THREAD_LAST_USER_MESSAGE_TIMESTAMPS[threadId] = timestamp;
  }
}

export const slice = createSlice({
  name: 'threads',
  initialState,
  reducers: {

    ACTION_ADD_MESSAGE_UNSORTED: (state, action: PayloadAction<{ threadId: string, messageId: string, message: Message }>) => {
      const {threadId, messageId, message} = action.payload;
      addThreadMessage(threadId, messageId, message, state);
    },

    ACTION_BULK_ADD_MESSAGES: (state, action: PayloadAction<{ threadId: string, messages: { [messageId: string]: Message } }>) => {
      const {threadId, messages} = action.payload;
      for (const [messageId, message] of Object.entries(messages)) {
        addThreadMessage(threadId, messageId, message, state);
      }
    },

    ACTION_ADD_THREAD_UPDATE: (state,
                               action: PayloadAction<{ threadId: string, updateId: string, update: ThreadUpdate }>) => {
      const {threadId, updateId, update} = action.payload;
      if (state.FIELD_THREAD_UPDATES?.[threadId]?.[updateId]) {
        // Duplicate message; ignore.
        return;
      }
      if (!state.FIELD_THREAD_UPDATES[threadId]) {
        state.FIELD_THREAD_UPDATES[threadId] = {};
      }
      state.FIELD_THREAD_UPDATES[threadId][updateId] = update;
    },

    ACTION_COMPACT_THREAD: (state, action: PayloadAction<{ threadId: string }>) => {
      const {threadId} = action.payload;
      const NEEDS_COMPACTION_SIZE = 500;
      const NEW_SIZE = 100;
      const messageMap = state.FIELD_THREAD_MESSAGES[threadId] || {};
      const allMessageIds = Object.keys(messageMap);
      const numMessages = allMessageIds.length;
      if (numMessages <= NEEDS_COMPACTION_SIZE) {
        return;
      }
      const sorted = SortThreadMessages(messageMap);
      for (let i = NEW_SIZE; i < sorted.length; i++) {
        const [messageId] = sorted[i];
        delete state.FIELD_THREAD_MESSAGES[threadId][messageId];
      }
    },

    ACTION_DELETE_MESSAGE: (state, action: PayloadAction<{ threadId: string, messageId: string }>) => {
      const {threadId, messageId} = action.payload;
      const originalMessage = state.FIELD_THREAD_MESSAGES?.[threadId]?.[messageId];
      if (!originalMessage) {
        return;
      }
      const {createdAt, ownerId} = originalMessage;
      const tombstoneMessage = {
        createdAt,
        deleted: true,
        key: messageId,
        ownerId,
      };
      state.FIELD_THREAD_MESSAGES[threadId][messageId] = tombstoneMessage;
    },

    // TODO: remove this action, and increment unread message counts as a side
    //  effect of adding the message to the slice. May need to model more state
    //  in the slice in order to correctly replicate the logic for deciding to
    //  increment or not.
    ACTION_INCREMENT_UNREAD_MESSAGE_COUNT: (state, action: PayloadAction<{ threadId: string, eventTimestamp: number }>) => {
      const {threadId, eventTimestamp} = action.payload;
      const lastReadTimestamp = state.LAST_READ_TIMESTAMPS[threadId] || 0;
      if (eventTimestamp <= lastReadTimestamp) {
        return;
      }
      if (!state.threadUnreadMessages[threadId]) {
        state.threadUnreadMessages[threadId] = 0;
      }
      state.threadUnreadMessages[threadId]++;
    },

    ACTION_START_LOADING_MORE_MESSAGES: (state) => {
      state.loadingMoreMessages = true;
      state.numMessagesLoadedLast = -1;
    },

    ACTION_FINISH_LOADING_MORE_MESSAGES: (state, action: PayloadAction<{ numMessagesLoaded: number }>) => {
      const {numMessagesLoaded} = action.payload;
      state.loadingMoreMessages = false;
      state.numMessagesLoadedLast = numMessagesLoaded;
    },

    ACTION_REMOVE_MESSAGE: (state, action: PayloadAction<{ threadId: string, messageId: string }>) => {
      const {threadId, messageId} = action.payload;
      delete state.FIELD_THREAD_MESSAGES?.[threadId]?.[messageId];
    },

    ACTION_REMOVE_THREAD: (state, action: PayloadAction<{ communityId: string, threadId: string }>) => {
      const {communityId, threadId} = action.payload;
      return removeThread(communityId, threadId, state);
    },

    ACTION_POP_THREAD: (state) => {
      const prevThreadId = state.FIELD_THREAD_ID_STACK.pop() || '';
      setActiveThread(prevThreadId, state);
    },

    ACTION_PUSH_THREAD: (state, action: PayloadAction<{ threadId: string }>) => {
      const {threadId} = action.payload;
      if (state.CURRENT_THREAD_ID) {
        state.FIELD_THREAD_ID_STACK.push(state.CURRENT_THREAD_ID);
      }
      setActiveThread(threadId, state);
    },

    ACTION_SET_CURRENT_THREAD: (state, action: PayloadAction<{ threadId: string }>) => {
      const {threadId} = action.payload;
      state.FIELD_THREAD_ID_STACK = [];
      setActiveThread(threadId, state);
    },

    ACTION_SET_THREAD_ACTIVITY_TIMESTAMP: (state, action: PayloadAction<{ threadId: string, timestamp: number }>) => {
      const {threadId, timestamp} = action.payload;
      updateThreadLastActivityTimestamp(threadId, timestamp, state);
    },

    ACTION_SET_THREAD_USER_MESSAGE_TIMESTAMP: (state, action: PayloadAction<{ threadId: string, timestamp: number }>) => {
      const {threadId, timestamp} = action.payload;
      updateThreadLastUserMessageTimestamp(threadId, timestamp, state);
    },

    ACTION_THREAD_STATE_PER_USER_UPDATED: (state, action: PayloadAction<{ threadId: string, threadState: ThreadStatePerUser }>) => {
      const {threadId, threadState} = action.payload;
      if (!threadId) {
        return;
      }
      const {currentState, lastReadTimestamp = 0} = threadState;
      state.FIELD_THREAD_JOIN_STATE[threadId] = currentState === 'subscribed';
      if (lastReadTimestamp > 0) {
        updateThreadLastReadTimestamp(threadId, lastReadTimestamp, state);
      }
    },

    ACTION_SET_THREAD_STATE: (state, action: PayloadAction<{ threadId: string, joined: boolean }>) => {
      const {threadId, joined} = action.payload;
      if (threadId) {
        state.FIELD_THREAD_JOIN_STATE[threadId] = joined;
      }
    },

    ACTION_UPDATE_THREAD: (state, action: PayloadAction<{ communityId: string, threadId: string, thread: DeepReadonly<Thread> }>) => {
      const {threadId, communityId, thread} = action.payload;
      updateThread(communityId, threadId, thread, state);
    },

    ACTION_UPDATE_THREAD_LAST_READ_TIMESTAMP: (state, action: PayloadAction<{ threadId: string, timestamp: number }>) => {
      const {threadId, timestamp} = action.payload;
      updateThreadLastReadTimestamp(threadId, timestamp, state);
    },

    ACTION_UPDATE_THREAD_MEMBERS: (state,
                                   action: PayloadAction<{ threadId: string, members: DeepReadonly<{ [userId: string]: ThreadMember }> }>) => {
      const {threadId, members = {}} = action.payload;
      state.FIELD_THREAD_MEMBERS[threadId] = members;
      state.threadMemberCounts[threadId] = Object.keys(members).length;
    },

    ACTION_ADD_PRIVATE_CHAT_DELETION: (state,
                                       action: PayloadAction<{ threadId: string, deletionId: string, deletion: PrivateChatScopedDeletion }>) => {
      const {threadId, deletionId, deletion} = action.payload;
      if (!state.FIELD_PRIVATE_CHAT_DELETIONS) {
        state.FIELD_PRIVATE_CHAT_DELETIONS = {};
      }
      if (!state.FIELD_PRIVATE_CHAT_DELETIONS[threadId]) {
        state.FIELD_PRIVATE_CHAT_DELETIONS[threadId] = {};
      }
      state.FIELD_PRIVATE_CHAT_DELETIONS[threadId][deletionId] = deletion;

      const {timestamp = 0} = deletion;
      if (!state.privateThreadMostRecentDeletionTimestamp) {
        state.privateThreadMostRecentDeletionTimestamp = {};
      }
      state.privateThreadMostRecentDeletionTimestamp[threadId] =
          Math.max(timestamp,
              state.privateThreadMostRecentDeletionTimestamp[threadId]);
    },

    ACTION_STORE_PRIVATE_CHAT_LOOKUPS: (state, action: PayloadAction<{ chatLookups: { [userId: string]: string } }>) => {
      const {chatLookups} = action.payload;
      state.FIELD_PRIVATE_CHAT_USER_ID_TO_THREAD_ID = {};
      state.FIELD_PRIVATE_CHAT_THREAD_ID_TO_USER_ID = {};
      for (const [userId, threadId] of Object.entries(chatLookups || {})) {
        state.FIELD_PRIVATE_CHAT_USER_ID_TO_THREAD_ID[userId] = threadId;
        state.FIELD_PRIVATE_CHAT_THREAD_ID_TO_USER_ID[threadId] = userId;
      }
    },

    ACTION_UPDATE_THREAD_INFO_LAST_VIEWED_TIMESTAMP: (state, action: PayloadAction<{ threadId: string, timestamp: number }>) => {
      const {threadId, timestamp} = action.payload;
      if (!state.FIELD_THREAD_INFO_LAST_VIEWED_TIMESTAMP) {
        state.FIELD_THREAD_INFO_LAST_VIEWED_TIMESTAMP = {};
      }
      state.FIELD_THREAD_INFO_LAST_VIEWED_TIMESTAMP[threadId] = timestamp;
    },

    ThreadStateLoaded: (state, action: PayloadAction<{ threadId: string, chatState: UserChatState }>) => {
      const {threadId, chatState} = action.payload;
      const {
        chatName,
        chatType,
        communityId = '',
        lastActivityTimestamp = 0,
        lastReadTimestamp = 0,
        lastUserMessageTimestamp = 0,
      } = chatState;
      const thread: Thread = {
        name: chatName,
        threadType: chatType,
      };
      if (lastReadTimestamp >= lastActivityTimestamp) {
        state.threadUnreadMessages[threadId] = 0;
      }
      updateThread(communityId, threadId, thread, state);
      updateThreadLastActivityTimestamp(threadId, lastActivityTimestamp, state);
      updateThreadLastReadTimestamp(threadId, lastReadTimestamp, state);
      updateThreadLastUserMessageTimestamp(threadId, lastUserMessageTimestamp, state);
    },

  },

  extraReducers: builder => builder
      .addCase(AppColdStart, (state) => {
        // Web uses URL params to determine what thread is active by default.
        if (Platform.OS === 'web') {
          return;
        }
        setActiveThread('', state);
      })
      .addCase(AppReset, () => initialState)
      .addCase(CommunityUpdated, (state, action: PayloadAction<{ communityId: string, community: DeepReadonly<Community> }>) => {
        const {communityId, community} = action.payload;
        const {announcementThreadId} = community;
        if (!announcementThreadId) {
          return;
        }
        const thread: Thread = {
          communityId,
          name: 'Announcements',
          threadType: THREAD_TYPE_ANNOUNCEMENT,
        };
        updateThread(communityId, announcementThreadId, thread, state);
      })
      .addCase(EventRemoved, (state, action) => {
        const {communityId, eventId} = action.payload;
        removeThread(communityId, eventId, state);
      })
      .addCase(EventUpdated, (state, action) => {
        const {communityId, eventId, event} = action.payload;
        const thread: Thread = {
          creationTimestamp: event.createdAt,
          name: event.name,
          threadType: THREAD_TYPE_EVENT,
        };
        updateThread(communityId, eventId, thread, state);
      })
      .addCase(SigningOutAction, () => initialState)
      .addCase(UserLeftCommunity, (state, action: PayloadAction<string>) => {
        const communityId = action.payload;
        const threadIds = Object.keys(state.FIELD_COMMUNITY_THREAD_IDS[communityId] || {});
        for (const threadId of threadIds) {
          removeThread(communityId, threadId, state);
        }
      }),

});

const {actions, reducer: ThreadsReducer} = slice;

const {
  ACTION_ADD_MESSAGE_UNSORTED,
  ACTION_ADD_PRIVATE_CHAT_DELETION,
  ACTION_ADD_THREAD_UPDATE,
  ACTION_BULK_ADD_MESSAGES,
  ACTION_COMPACT_THREAD,
  ACTION_DELETE_MESSAGE,
  ACTION_INCREMENT_UNREAD_MESSAGE_COUNT,
  ACTION_START_LOADING_MORE_MESSAGES,
  ACTION_FINISH_LOADING_MORE_MESSAGES,
  ACTION_REMOVE_MESSAGE,
  ACTION_REMOVE_THREAD,
  ACTION_POP_THREAD,
  ACTION_PUSH_THREAD,
  ACTION_SET_CURRENT_THREAD,
  ACTION_SET_THREAD_ACTIVITY_TIMESTAMP,
  ACTION_SET_THREAD_USER_MESSAGE_TIMESTAMP,
  ACTION_SET_THREAD_STATE,
  ACTION_STORE_PRIVATE_CHAT_LOOKUPS,
  ACTION_THREAD_STATE_PER_USER_UPDATED,
  ACTION_UPDATE_THREAD,
  ACTION_UPDATE_THREAD_INFO_LAST_VIEWED_TIMESTAMP,
  ACTION_UPDATE_THREAD_LAST_READ_TIMESTAMP,
  ACTION_UPDATE_THREAD_MEMBERS,
  ThreadStateLoaded,
} = actions;

export {
  ACTION_ADD_MESSAGE_UNSORTED,
  ACTION_ADD_PRIVATE_CHAT_DELETION,
  ACTION_ADD_THREAD_UPDATE,
  ACTION_BULK_ADD_MESSAGES,
  ACTION_COMPACT_THREAD,
  ACTION_DELETE_MESSAGE,
  ACTION_INCREMENT_UNREAD_MESSAGE_COUNT,
  ACTION_START_LOADING_MORE_MESSAGES,
  ACTION_FINISH_LOADING_MORE_MESSAGES,
  ACTION_REMOVE_MESSAGE,
  ACTION_REMOVE_THREAD,
  ACTION_POP_THREAD,
  ACTION_PUSH_THREAD,
  ACTION_SET_CURRENT_THREAD,
  ACTION_SET_THREAD_ACTIVITY_TIMESTAMP,
  ACTION_SET_THREAD_USER_MESSAGE_TIMESTAMP,
  ACTION_SET_THREAD_STATE,
  ACTION_STORE_PRIVATE_CHAT_LOOKUPS,
  ACTION_THREAD_STATE_PER_USER_UPDATED,
  ACTION_UPDATE_THREAD,
  ACTION_UPDATE_THREAD_INFO_LAST_VIEWED_TIMESTAMP,
  ACTION_UPDATE_THREAD_LAST_READ_TIMESTAMP,
  ACTION_UPDATE_THREAD_MEMBERS,
  ThreadStateLoaded,
  ThreadsReducer,
};
