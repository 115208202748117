import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import {Message} from '@heylo/shared/src/types/firebase-types';
import {MessageImage} from './MessageImage';
import {
  MESSAGE_BORDER_RADIUS,
  MESSAGE_MARGIN,
  MESSAGE_MAX_WIDTH,
} from './Styles';
import {ReplyToText} from './ReplyToText';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {StyleVars} from '../../styles/StyleVars';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

type OwnProps = {
  currentMessage: Message,
  replyToMessage: Message,
};

const mapState = (state: RootState, ownProps: OwnProps) => {
  const {currentMessage, replyToMessage} = ownProps;
  const senderUserId = currentMessage.ownerId || '';
  const activeUserId = selectActiveUserId(state) || '';

  return {
    isUser: activeUserId === senderUserId,
    replyToMessageIsImage: !!replyToMessage.image,
    senderUserId,
  };
};
const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & OwnProps;

export const ReplyToMessage = connector((props: Props) => {
  const {
    isUser,
    replyToMessage,
    replyToMessageIsImage,
    senderUserId,
  } = props;

  const text = useTextStyles();

  return (
      <View style={{
        marginLeft: isUser ? 0 : MESSAGE_MARGIN,
        marginRight: isUser ? MESSAGE_MARGIN : 0,
      }}>
        <ReplyToText
            containerStyle={{
              ...styles.containerReplyToText,
              justifyContent: isUser ? 'flex-start' : 'flex-end',
            }}
            pastTense={true}
            replyToMessage={replyToMessage}
            senderUserId={senderUserId}
        />


        <View style={{
          flexDirection: isUser ? 'row-reverse' : 'row',
          justifyContent: 'flex-start',
        }}>
          {replyToMessageIsImage ? (
              <MessageImage
                  isReplyImage={true}
                  message={replyToMessage}
              />
          ) : (
              <View style={{
                flexDirection: isUser ? 'row-reverse' : 'row',
                justifyContent: 'flex-start',
                maxWidth: MESSAGE_MAX_WIDTH,
              }}>
                <View style={[
                  styles.containerReplyToMessageBottom,
                  {backgroundColor: isUser ? StyleVars.Colors.PurpleLightest : StyleVars.Colors.GreyLighter},
                ]}/>
                <View style={[
                  styles.containerReplyToMessage,
                  {backgroundColor: isUser ? StyleVars.Colors.PurpleLightest : StyleVars.Colors.GreyLighter},
                ]}>
                  <Text
                      ellipsizeMode={'tail'}
                      numberOfLines={3}
                      style={[
                        text.body2,
                        {color: StyleVars.Colors.GreyMedium},
                      ]}
                  >
                    {(replyToMessage.content || '').trim()}
                  </Text>
                </View>
              </View>
          )}
        </View>
      </View>

  );
});

const styles = StyleSheet.create({
  containerReplyToText: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: StyleConstants.SPACING / 2,
  },
  containerReplyToMessage: {
    borderRadius: MESSAGE_BORDER_RADIUS,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderWidth: 0,
    flexDirection: 'row',
    padding: 8,
    paddingBottom: 0,
    width: '100%',
  },
  containerReplyToMessageBottom: {
    borderBottomLeftRadius: MESSAGE_BORDER_RADIUS,
    borderBottomRightRadius: MESSAGE_BORDER_RADIUS,
    bottom: -StyleConstants.SPACING,
    height: StyleConstants.SPACING + 1,
    position: 'absolute',
    width: '100%',
  },
});