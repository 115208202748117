import {combineReducers} from '@reduxjs/toolkit'
import {AuthReducer} from '@heylo/shared/src/features/auth/Slice';
import {CommunityProfilesReducer} from '@heylo/shared/src/features/communityProfiles/Slice';
import {CommunitiesReducer} from '@heylo/shared/src/features/communities/Slice';
import {UserSettingsReducer} from '@heylo/shared/src/features/userSettings/Slice';
import {UserProfilesReducer} from '@heylo/shared/src/features/userProfiles/Slice';
import {EventsReducer} from '@heylo/shared/src/features/events/Slice';
import {CommunityMemberFieldsReducer} from '@heylo/shared/src/features/communityMemberFields/Slice';
import {UserEventsReducer} from '@heylo/shared/src/features/userEvents/Reducer';
import {NavigationReducer} from '@heylo/shared/src/features/navigation/Slice';
import {UserDevicesReducer} from '@heylo/shared/src/features/userDevices/Slice';
import {StripeReducer} from '@heylo/shared/src/features/stripe/Slice';
import {DocumentsReducer} from '@heylo/shared/src/features/documents/Slice';
import {PhotosReducer} from '@heylo/shared/src/features/photos/Slice';
import {NotificationsReducer} from '@heylo/shared/src/features/notifications/Slice';
import {UiReducer} from '@heylo/shared/src/features/ui/Slice';
import {ThreadsReducer} from '@heylo/shared/src/features/threads/Slice';
import {ReactionsReducer} from '@heylo/shared/src/features/reactions/Slice';
import {MarketplacesReducer} from '@heylo/shared/src/features/marketplaces/Slice';
import {CommunityCreationReducer} from '../../features/communityCreation/Slice';
import {SearchReducer} from '../../features/search/Slice';

const createRootReducer = () => combineReducers({
  auth: AuthReducer,
  communities: CommunitiesReducer,
  communityCreation: CommunityCreationReducer,
  communityMemberFields: CommunityMemberFieldsReducer,
  communityProfiles: CommunityProfilesReducer,
  documents: DocumentsReducer,
  events: EventsReducer,
  marketplaces: MarketplacesReducer,
  navigation: NavigationReducer,
  notifications: NotificationsReducer,
  photos: PhotosReducer,
  reactions: ReactionsReducer,
  search: SearchReducer,
  stripe: StripeReducer,
  threads: ThreadsReducer,
  ui: UiReducer,
  userDevices: UserDevicesReducer,
  userEvents: UserEventsReducer,
  userSettings: UserSettingsReducer,
  userProfiles: UserProfilesReducer,
});

export default createRootReducer;