import {Listeners} from '@heylo/shared/src/features/firebase/Listeners';
import {FirebaseDatabase} from '@heylo/firebase-database';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {
  MarketplaceCommunityLoaded,
  MarketplaceMembershipsUpdated,
  MarketplacesLoaded,
} from '@heylo/shared/src/features/marketplaces/Slice';
import {MarketplaceCommunity} from '@heylo/shared/src/types/firebase-types';
import {selectMarketLastUpdateTimestamp} from '@heylo/shared/src/features/marketplaces/Selectors';

export class MarketplacesFirebase {

  static LoadAllMarketplaces = (): ThunkReturnType<void> => dispatch => {
    FirebaseDatabase().ref('/marketplaces').once('value')
        .then(snapshot => {
          dispatch(MarketplacesLoaded(snapshot?.val() || {}));
        })
        .catch((e: Error) => {
          console.warn('error loading /marketplaces', e);
        });
  };

  static AttachCommunitiesListener = (marketplaceId: string): ThunkReturnType<void> => (dispatch, getState) => {
    if (Listeners.MARKETPLACE_COMMUNITIES[marketplaceId]) {
      return;
    }
    const state = getState();
    const lastUpdateTimestamp = selectMarketLastUpdateTimestamp(state)?.[marketplaceId] ?? 0;
    const ref = FirebaseDatabase().ref(`/marketplaceCommunities/${marketplaceId}`)
        .orderByChild('timestampUpdated')
        .startAt(lastUpdateTimestamp);
    Listeners.MARKETPLACE_COMMUNITIES[marketplaceId] = ref;
    ref.on('value',
        snapshot => {
          if (!snapshot) {
            return;
          }
          const results: { [communityId: string]: MarketplaceCommunity } = snapshot?.val() || {};
          for (const [communityId, community] of Object.entries(results)) {
            dispatch(MarketplaceCommunityLoaded({
              marketplaceId,
              communityId,
              community,
            }));
          }
        },
        (e: Error) => {
          console.warn('error loading /marketplaces', e);
          delete Listeners.MARKETPLACE_COMMUNITIES[marketplaceId];
        });
  };

  static DetachCommunitiesListener = (marketplaceId: string) => {
    const ref = Listeners.MARKETPLACE_COMMUNITIES[marketplaceId];
    if (ref) {
      ref.off();
    }
    delete Listeners.MARKETPLACE_COMMUNITIES[marketplaceId];
  };

  static AttachMembershipListener = (userId: string)
      : ThunkReturnType<void> => (dispatch) => {
    if (Listeners.MARKETPLACE_MEMBERSHIPS[userId]) {
      return;
    }
    const ref = FirebaseDatabase().ref(`/marketplaceMemberships/byUser/${userId}`);
    Listeners.MARKETPLACE_MEMBERSHIPS[userId] = ref;
    ref.on('value',
        snapshot => {
          const membershipMap = snapshot?.val() || {};
          dispatch(MarketplaceMembershipsUpdated(membershipMap));
        },
        (e: Error) => {
          console.log('lost connection to /marketplaceMemberships/byUser', userId, e.message);
          delete Listeners.MARKETPLACE_MEMBERSHIPS[userId];
        });
  };

}
