import React, {useEffect, useState} from 'react';
import {Text, View} from 'react-native';
import {ActivityIndicator, Card} from 'react-native-paper';
import {
  NewCommunityLocationForm,
  NewCommunityLocationFormState,
} from '@heylo/components/src/features/communityCreation/NewCommunityLocationForm';
import {useHistory} from 'react-router-dom';
import GooglePlacesAutocomplete, {suggestionType} from 'react-google-places-autocomplete';
import {useHeyloConfig} from '@heylo/shared/src/features/app/useHeyloConfig';
import {HeyloTextInput} from '@heylo/components/src/ui/input/HeyloTextInput';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {NextButton, ProgressHeader} from 'features/navigation/ProgressHeader';
import {useDispatch} from 'react-redux';
import {DraftCommunityUpdated} from '@heylo/shared/src/features/communityCreation/Slice';

export const NewGroupLocationScreen = () => {
  const dispatch = useDispatch();
  const heyloConfig = useHeyloConfig();
  const history = useHistory();
  const palette = usePalette();
  const text = useTextStyles();

  const [state, setState] = useState(NewCommunityLocationFormState.IDLE);

  useEffect(() => {
    switch (state) {
      case NewCommunityLocationFormState.NAV_DETAILS:
        history.push('/newGroup/details');
        break;
      case NewCommunityLocationFormState.NAV_EMAIL_VERIFICATION:
        history.push('/newGroup/verification');
        break;
    }
  }, [state]);

  const onNextPress = () => {
    setState(NewCommunityLocationFormState.SUBMITTING);
  }

  const onBackPress = () => {
    if (state === NewCommunityLocationFormState.INPUT_CUSTOM_LOCATION) {
      setState(NewCommunityLocationFormState.IDLE);
    } else {
      history.goBack();
    }
  }

  const onSuggestionPress = (suggestion: suggestionType) => {
    const {description = ''} = suggestion;
    dispatch(DraftCommunityUpdated({
      locationName: description,
      marketplaceId: '',
    }));
    setState(NewCommunityLocationFormState.SELECTED);
  };

  const resetSearch = () => {
    setState(NewCommunityLocationFormState.IDLE);
  };

  const renderInput = (props: any) => {
    return (
        <View style={{
          alignItems: 'center',
          backgroundColor: palette.grey.lightest,
          padding: StyleConstants.SPACING,
          width: '100%',
        }}>
          <View style={{
            justifyContent: 'center',
            maxWidth: 500,
            width: '100%',
          }}>
            <HeyloTextInput
                autoFocus
                dense
                label={'Location'}
                {...props}
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                }}
            />
            <IconButton
                aria-label="cancel"
                onClick={resetSearch}
                size={'small'}
                style={{
                  marginTop: 3,
                  position: 'absolute',
                  right: StyleConstants.SPACING / 2,
                }}
            >
              <CloseIcon/>
            </IconButton>
          </View>
        </View>
    );
  }

  const renderSuggestions = (activeSuggestion: number,
                             suggestions: Array<suggestionType>) => (
      <View style={{justifyContent: 'center'}}>
        <View style={{
          alignSelf: 'center',
          maxWidth: 500,
          width: '100%',
        }}>
          {suggestions.map(suggestion => (
              <Card
                  elevation={3}
                  key={`suggestion${suggestion.place_id}`}
                  onPress={() => onSuggestionPress(suggestion)}
                  style={{marginTop: StyleConstants.SPACING}}
              >
                <Card.Content>
                  <Text style={[text.body1, text.bold]}>
                    {suggestion.structured_formatting.main_text}
                  </Text>
                  <Text style={text.body1}>
                    {suggestion.structured_formatting.secondary_text}
                  </Text>
                </Card.Content>
              </Card>
          ))}
        </View>
      </View>
  );

  const showCustomLocationInput = state === NewCommunityLocationFormState.INPUT_CUSTOM_LOCATION;

  return (
      <>
        <ProgressHeader
            containerStyle={showCustomLocationInput ? {
              maxWidth: '100%',
              width: '100%',
            } : undefined}
            nextButton={
              showCustomLocationInput
                  ? NextButton.HIDDEN
                  : state === NewCommunityLocationFormState.SELECTED
                  ? NextButton.NEXT :
                  NextButton.SKIP
            }
            onBackPress={onBackPress}
            onNextPress={onNextPress}
            percent={'40%'}
            title={'Find new members'}
        >
          {showCustomLocationInput ? (
              <View style={{flex: 1, height: '100%'}}>
                <GooglePlacesAutocomplete
                    apiKey={heyloConfig.GOOGLE_PLACES_API_KEY || ''}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: 'us',
                      },
                    }}
                    loader={(
                        <View style={{
                          justifyContent: 'center',
                          padding: StyleConstants.SPACING,
                        }}>
                          <ActivityIndicator accessibilityStates='' size={'large'}/>
                        </View>
                    )}
                    renderSuggestions={renderSuggestions}
                    placeholder='Search'
                    renderInput={renderInput}
                />
              </View>
          ) : (
              <NewCommunityLocationForm
                  state={state}
                  setState={setState}
              />
          )}
        </ProgressHeader>
      </>
  );
};