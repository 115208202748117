import React, {CSSProperties} from 'react';
import Typography from '@material-ui/core/Typography';

interface Props {
  align?: 'left' | 'center' | 'right',
  children: string | any | any[],
  className?: string,
  color?: 'error' | 'primary' | 'secondary' | 'inherit',
  noWrap?: boolean,
  paragraph?: boolean,
  variant: 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2',
  style?: CSSProperties,
};

export const HeyloText = (props: Props) => {
  const {
    align,
    children,
    className,
    color,
    noWrap,
    paragraph,
    variant,
    style,
  } = props;

  return (
      <Typography
          align={align}
          className={className}
          color={color}
          noWrap={noWrap}
          paragraph={paragraph}
          variant={variant}
          style={style}
      >
        {children}
      </Typography>
  );
};

