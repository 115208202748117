import {StyleSheet, TextInputProps, View} from 'react-native';
import React, {Ref, useState} from 'react';
import {IconButton} from 'react-native-paper';
import {HeyloTextInput} from '../../ui/input/HeyloTextInput';

export const PasswordTextInput = React.memo(React.forwardRef((props: TextInputProps, ref: Ref<any>) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
      <View>
        <HeyloTextInput
            autoCapitalize={'none'}
            autoCompleteType={'password'}
            autoCorrect={false}
            dense
            label={'Password'}
            // placeholder='Heylo Password'
            placeholderTextColor='#777'
            ref={ref}
            secureTextEntry={!passwordVisible}
            style={{width: '100%'}}
            textContentType={'password'}
            underlineColorAndroid={'transparent'}
            {...props}
        />
        <IconButton
            icon={passwordVisible ? 'eye' : 'eye-off'}
            onPress={() => setPasswordVisible(!passwordVisible)}
            style={styles.visibilityToggle}
        />
      </View>
  );
}));

const styles = StyleSheet.create({
  visibilityToggle: {
    position: 'absolute',
    right: 5,
    top: 3,
    width: 40,
    zIndex: 1000,
  },
});