import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';

export const Divider = () => {
  const theme = useTheme();
  return (
      <div style={{
        backgroundColor: '#ddd',
        height: 1,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: '100%',
      }}/>
  );
};