import React from 'react';
import {View, ViewStyle} from 'react-native';

type Props = {
  children: React.ReactNode | React.ReactNode[],
  maxWidth?: 'xs' | 'sm' | 'md',
  style?: ViewStyle,
};

export const HeyloContainer = (props: Props) => {
  const {children, maxWidth, style} = props;
  let innerWidth: string | number = '100%';
  switch (maxWidth) {
    case 'xs':
      innerWidth = 600;
      break;
    case 'sm':
      innerWidth = 960;
      break
    case 'md':
      innerWidth = 1280;
      break;
  }

  return (
      <View style={{
        alignItems: 'center',
        flex: 1,
        width: '100%',
      }}>
        <View style={{
          flex: 1,
          maxWidth: innerWidth,
          width: '100%',
          ...style,
        }}>
          {children}
        </View>
      </View>
  );
};