import React, {useEffect} from 'react';
import {Attendee} from './Attendee';
import {humanReadableEventDateAndTime} from '@heylo/shared/src/features/events/Events';
import RoomIcon from '@material-ui/icons/Room';
import VideoIcon from '@material-ui/icons/Videocam';
import {HeyloText} from 'lib/materialUi';
import {RsvpButton} from './RsvpButton';
import Grid from '@material-ui/core/Grid';
import {ActionButton, SQUARE} from './ActionButton';
import {ShareButton} from './ShareButton';
import isURL from 'validator/lib/isURL';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {HeyloLink} from '../../lib/materialUi/Link';
import Linkify from 'linkifyjs/react';
import {useRootStyles} from 'styles/RootStyles';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {ScrollToTopOnMount} from '../navigation/ScrollToTopOnMount';
import {DonateSection} from './DonateSection';
import {AttachListenersForEvent} from '@heylo/shared/src/features/events/Firebase';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  selectActiveEvent,
  selectActiveEventGoingMembers,
  selectActiveEventId,
  selectActiveEventInterestedMembers,
} from '@heylo/shared/src/features/events/Selectors';
import {SelectActiveCommunity} from '@heylo/shared/src/features/communities/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {useActiveEventState} from './useActiveEventState';
import {HeyloContainer} from '@heylo/components/src/ui/container/HeyloContainer';
import {View} from 'react-native';
import {HeyloImage} from '@heylo/components/src/ui/image';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';


const formatDateAndTime = (timestamp: number | undefined, timezone: string | undefined) => {
  if (timestamp && timezone) {
    return humanReadableEventDateAndTime(timestamp, timezone);
  }
  return '';
};

export const EventDetails = () => {
  const classes = useRootStyles();
  const dispatch = useDispatch();
  const logger = useLoggingService();

  const {event, eventId} = useActiveEventState();

  const {
    community,
    goingAttendees,
    interestedAttendees,
    userId,
  } = useSelector((state: RootState) => ({
    community: SelectActiveCommunity(state) ?? {},
    event: selectActiveEvent(state),
    eventId: selectActiveEventId(state),
    goingAttendees: selectActiveEventGoingMembers(state),
    interestedAttendees: selectActiveEventInterestedMembers(state),
    userId: selectActiveUserId(state),
  }), shallowEqual);

  const {
    communityId = '',
  } = community;

  const {
    donationsEnabled,
    image,
    location: eventLocation,
    notes,
    timestamp,
    timezone,
    video,
  } = event;

  useEffect(() => {
    if (communityId && eventId && userId) {
      dispatch(AttachListenersForEvent(communityId, eventId, userId));
    }
  }, [communityId, eventId, userId]);

  const openMap = () => {
    if (eventLocation) {
      window.open(`https://www.google.com/maps?q=${eventLocation.replace(' ', '+')}`);
      logger.logEvent(AnalyticsEvent.EVENTS_LOCATION);
    }
  };

  const openVideoLink = () => {
    if (video) {
      const videoUrl = isURL(video, {require_protocol: true}) ? video : `https://${video}`;
      window.open(videoUrl);
    }
  };

  return (
      <>
        <ScrollToTopOnMount/>
        <View style={{backgroundColor: 'black'}}>
          <HeyloContainer maxWidth={'xs'}>
            {image ? (
                <HeyloImage
                    aspectRatio={1}
                    uri={image}
                />
            ) : null}
          </HeyloContainer>
        </View>

        <HeyloContainer
            maxWidth={'xs'}
            style={{padding: StyleConstants.SPACING}}
        >
          <View style={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginBottom: donationsEnabled ? 0 : StyleConstants.SPACING,
          }}>
            {video && (
                <ActionButton
                    icon={<VideoIcon/>}
                    label={'Video'}
                    onClickHandler={openVideoLink}
                    variant={SQUARE}
                />
            )}
            {eventLocation && (
                <ActionButton
                    icon={<RoomIcon/>}
                    label={'Map'}
                    onClickHandler={openMap}
                    variant={SQUARE}
                />
            )}
            <ShareButton variant={SQUARE}/>
            <RsvpButton
                event={event}
                eventId={eventId}
                variant={SQUARE}
            />
          </View>

          {donationsEnabled && (
              <DonateSection/>
          )}

          <View>
            <HeyloText variant={'subtitle2'}>
              {formatDateAndTime(timestamp, timezone)}
            </HeyloText>
            {eventLocation && (
                <HeyloLink onClick={openMap} variant={'subtitle2'}>
                  {eventLocation}
                </HeyloLink>
            )}
          </View>

          <View>
            <HeyloText style={{whiteSpace: 'pre-wrap'}} variant={'body1'}>
              <Linkify options={{className: classes.textLink}}>
                {notes}
              </Linkify>
            </HeyloText>
          </View>

          {goingAttendees.length > 0 && (
              <>
                <Grid item xs={12}>
                  <HeyloText variant={'subtitle1'}>
                    {goingAttendees.length} going
                  </HeyloText>
                </Grid>
                {goingAttendees.map((profile, index) => {
                  return (
                      <Grid item key={`attendee${profile.id}`} xs={12} md={6}>
                        <Attendee userProfile={profile}/>
                      </Grid>
                  )
                })}
              </>
          )}

          {interestedAttendees.length > 0 && (
              <>
                <Grid item xs={12}>
                  <HeyloText variant={'subtitle1'}>
                    {interestedAttendees.length} interested
                  </HeyloText>
                </Grid>
                {interestedAttendees.map((attendee) => {
                  return (
                      <Grid item xs={12} md={6}>
                        <Attendee userProfile={attendee}/>
                      </Grid>
                  )
                })}
              </>
          )}
        </HeyloContainer>
      </>
  );
};
