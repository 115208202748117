import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {shallowEqual, useSelector} from 'react-redux';
import {
  ChatScreenState,
  ImageViewerParams,
} from '@heylo/components/src/features/chat/ChatScreenContext';
import {ActiveThreadSelector} from '@heylo/shared/src/features/threads/Selectors';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {useCallback, useMemo} from 'react';

export const useChatContextForWeb = (): ChatScreenState => {
  const {
    communityId = '',
    thread = {},
    userId,
  } = useSelector((state: RootState) => ({
    communityId: SelectActiveCommunityId(state),
    thread: ActiveThreadSelector(state),
    userId: selectActiveUserId(state),
  }), shallowEqual);

  const {threadId = '', threadType = ''} = thread;

  const onImagePress = useCallback((params: ImageViewerParams) => {
    console.warn('TODO: implement onImagePress');
  }, []);

  const onUserPress = useCallback((selectedUserId: string) => {
    // TODO: see Attendee::handleClick
    console.warn('TODO: implement onUserPress');
  }, []);

  return useMemo(() => ({
    communityId,
    onImagePress,
    onUserPress,
    thread,
    threadId,
    threadType,
    userId,
  }), [
    communityId,
    onImagePress,
    onUserPress,
    thread,
    threadId,
    threadType,
    userId,
  ]);
};