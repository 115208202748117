import React, {Ref, useEffect, useRef, useState} from 'react';
import {
  Animated,
  Dimensions, NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  View,
  ViewStyle,
} from 'react-native';
import InputScrollView, {InputScrollViewProps} from 'react-native-input-scroll-view';
import LinearGradient from 'react-native-linear-gradient';

const {height, width} = Dimensions.get('window');
const MAX_OPACITY = 0.5;

type Props = InputScrollViewProps & {
  children: any | any[],
  headerStyle?: ViewStyle,
  ref?: Ref<InputScrollView>,
};

export const DropShadowScrollView
    = React.memo(React.forwardRef((props: Props, ref: Ref<InputScrollView>) => {

  const {
    children,
  } = props;

  const currentShadowOpacity = useRef(new Animated.Value(0)).current;
  const [showDropShadow, setShowDropShadow] = useState(false);
  useEffect(() => {
    Animated.timing(currentShadowOpacity, {
      toValue: showDropShadow ? MAX_OPACITY : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [showDropShadow]);

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const {y} = event.nativeEvent.contentOffset;
    if (y <= 0) {
      setShowDropShadow(false);
    } else {
      setShowDropShadow(true);
    }
  };

  return (
      <View style={{flex: 1}}>
        <InputScrollView
            keyboardAvoidingViewProps={{
              behavior: Platform.OS === 'ios' ? 'padding' : undefined,
              keyboardVerticalOffset: Platform.OS === 'ios' ? height * 0.11 : 0,
            }}
            keyboardShouldPersistTaps={'handled'}
            onMomentumScrollEnd={handleScroll}
            onScroll={handleScroll}
            onScrollBeginDrag={() => setShowDropShadow(true)}
            ref={ref}
            scrollEventThrottle={50}
            showsVerticalScrollIndicator={false}
            {...props}
        >
          {children}
        </InputScrollView>

        <Animated.View style={{
          height: 10,
          opacity: currentShadowOpacity,
          position: 'absolute',
          width: '100%',
        }}>
          <LinearGradient
              colors={['#0003', '#0001', '#0000']}
              style={{
                height: 10,
                width: '100%',
              }}
          />
        </Animated.View>
      </View>
  );
}));