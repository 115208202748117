import React, {ChangeEvent, Ref, useEffect, useState} from 'react';
import Cropper from 'react-easy-crop';
import {GetExtensionForMimeType} from '@heylo/shared/src/util/Files';
import {v4 as uuidv4} from 'uuid';
import {
  CreateImageFromDataUri,
  CropImage,
  ReadImageFile,
  Rectangle,
} from '@heylo/components/src/lib/dom/image/Image';
import {Modal, Portal} from 'react-native-paper';
import {View} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {HeyloButton} from '@heylo/components/src/ui/button/HeyloButton';
import {UploadImageWithContentType} from './FirebaseUtils';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {ImageUploadMetadata} from '@heylo/shared/src/services/image/ImageUploader';

type Props = {
  aspectRatio?: number,
  cropShape?: 'rect' | 'round',
  onCancel: () => void,
  onChange: (downloadUriPromise: Promise<string>, image: ImageUploadMetadata) => void,
  onError: () => void,
  storagePath: string,
};

enum State {
  IDLE,
  CROPPING,
};

export const WebPhotoUploadDialog = React.forwardRef((props: Props, ref: Ref<HTMLInputElement>) => {
  const {
    aspectRatio,
    cropShape,
    onCancel,
    onChange,
    onError,
    storagePath,
  } = props;

  const [state, setState] = useState(State.IDLE);
  const [localPhotoFile, setLocalPhotoFile] = useState<File | null>(null);
  const [croppingPhotoUri, setCroppingPhotoUri] = useState('');
  const [mimeType, setMimeType] = useState('');
  const [crop, setCrop] = useState({x: 0, y: 0});
  // const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Rectangle>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const reset = () => {
    setState(State.IDLE);
    setLocalPhotoFile(null);
    setCroppingPhotoUri('');
    setMimeType('');
    setZoom(1);
    setCrop({x: 0, y: 0});
    setCroppedAreaPixels({
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    })
  };


  useEffect(() => {
    switch (state) {
      case State.CROPPING:
        document.body.classList.add('stop-scrolling');
        break;
      default:
        document.body.classList.remove('stop-scrolling');
        break;
    }
  }, [state]);


  useEffect(() => {
    if (!localPhotoFile) {
      return;
    }
    const contentType = localPhotoFile.type;
    ReadImageFile(localPhotoFile)
        .then(dataUri => {
          if (!dataUri) {
            console.error('something went wrong while loading image');
            return;
          }
          if (cropShape) {
            setCroppingPhotoUri(dataUri);
            setMimeType(contentType);
            setState(State.CROPPING);
          } else {
            let imageWidth = 0;
            let imageHeight = 0;
            CreateImageFromDataUri(dataUri)
                .then(image => {
                  const {height, width} = image;
                  imageWidth = width;
                  imageHeight = height;
                  return localPhotoFile.arrayBuffer();
                })
                .then(buffer => {
                  return upload(dataUri, buffer, imageWidth, imageHeight, contentType);
                });
          }
        });
  }, [localPhotoFile]);

  const onCropComplete = (croppedArea: any, rect: Rectangle) => {
    setCroppedAreaPixels(rect);
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  const handleSubmit = async () => {
    try {
      const croppedImage = await CropImage(croppingPhotoUri, croppedAreaPixels);
      if (croppedImage) {
        const {height, width} = croppedAreaPixels;
        await upload(croppingPhotoUri, croppedImage, width, height, mimeType);
      }
    } catch (e) {
      console.error('failed to upload photo', e);
      onError();
    }
  };

  const upload = async (
      // Local URI can be used while the image is busy being
      // uploaded+downloaded.
      localUri: string,
      blob: Blob | ArrayBuffer,
      imageWidth: number,
      imageHeight: number,
      contentType: string) => {
    reset();
    const uniqueId = uuidv4();
    const extension = GetExtensionForMimeType(contentType);
    try {
      const downloadUriPromise = UploadImageWithContentType({
        blob,
        contentType,
        fileName: `${uniqueId}${extension}`,
        imageHeight,
        imageWidth,
        storagePath,
      });
      const imageUploadMetadata = {
        contentType,
        height: imageHeight,
        localUri,
        width: imageWidth,
      };
      onChange(downloadUriPromise.then(val => Promise.resolve(val.downloadURL)), imageUploadMetadata);
    } catch (e) {
      console.error('failed to upload photo', e);
      onError();
    }
  };

  const handlePhotoSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    console.log('handlePhotoSelected', file);
    if (!file) {
      return;
    }
    setLocalPhotoFile(file);
  };

  return (
      <>
        <input
            className={'hidden'}
            onChange={handlePhotoSelected}
            ref={ref}
            type={'file'}
        />
        {state === State.CROPPING && (
            <Portal>
              <Modal
                  contentContainerStyle={{
                    backgroundColor: 'black',
                    height: '100vh',
                    justifyContent: 'flex-end',
                    position: 'absolute',
                    top: 0,
                    width: '100vw',
                    zIndex: 1400,
                  }}
                  visible={true}
              >
                <Cropper
                    aspect={aspectRatio}
                    crop={crop}
                    cropShape={cropShape}
                    image={croppingPhotoUri}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={false}
                    style={{
                      containerStyle: {
                        height: '80%',
                      },
                    }}
                    zoom={zoom}
                />
                <View style={{
                  flexDirection: 'row',
                  height: '20%',
                  justifyContent: 'center',
                  padding: StyleConstants.SPACING,
                }}>
                  <View>
                    <HeyloButton
                        label={'Cancel'}
                        mode={'outlined'}
                        onPress={handleCancel}
                        style={{margin: StyleConstants.SPACING}}
                        width={100}
                    />
                  </View>
                  <View>
                    <HeyloButton
                        label={'Save'}
                        onPress={handleSubmit}
                        style={{margin: StyleConstants.SPACING}}
                        width={100}
                    />
                  </View>
                </View>
              </Modal>
            </Portal>
        )}
      </>
  );
});
