import {UserProfileChanged} from '@heylo/shared/src/features/userProfiles/Slice';
import {FirebaseDatabase} from '@heylo/firebase-database';
import {Listeners} from '@heylo/shared/src/features/firebase/Listeners';

export const listenForUserProfile = (userId: string) => {
  return (dispatch: any) => {
    if (!userId || Listeners.USER_PROFILES[userId]) {
      return;
    }
    const ref = FirebaseDatabase().ref(`/userProfiles/${userId}`);
    Listeners.USER_PROFILES[userId] = ref;
    ref.on('value', (snapshot) => {
      const profile = snapshot?.val();
      if (profile) {
        dispatch(UserProfileChanged({userId, profile}));
      }
    }, (e: Error) => {
      console.log('lost connection to user profile', userId, e.message);
      delete Listeners.USER_PROFILES[userId];
    });
  };
};