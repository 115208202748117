import {AuthMachine} from '@heylo/shared/src/features/auth/AuthMachine';
import {useMachine} from '@xstate/react';
import {useEffect} from 'react';

type Props = {
  authState: string,
  firebaseAuthLoaded: boolean,
  firebaseUserId: string,
  isAnonymous: boolean,
  signingInService: (userId: string) => Promise<void>,
  signingOutService: (userId: string) => Promise<void>,
}

export const useAuthMachine = (props: Props) => {
  const {
    authState,
    firebaseAuthLoaded,
    firebaseUserId,
    isAnonymous,
    signingInService,
    signingOutService,
  } = props;

  const [state, send] = useMachine(AuthMachine, {
    services: {
      signingInService: (ctx) => signingInService(ctx.userId),
      signingOutService: (ctx) => signingOutService(ctx.userId),
    },
  });

  useEffect(() => {
    if (authState === 'USER_INITIATED_SIGN_OUT') {
      send({type: 'SIGN_OUT_BEGIN'});
    }
  }, [authState]);

  useEffect(() => {
    if (!firebaseAuthLoaded) {
      return;
    }
    if (firebaseUserId) {
      if (isAnonymous) {
        send({type: 'AUTH_CHANGE_ANONYMOUS_USER', userId: firebaseUserId});
      } else {
        send({type: 'AUTH_CHANGE_USER', userId: firebaseUserId});
      }
    } else {
      send({type: 'AUTH_CHANGE_NO_USER'});
    }
  }, [firebaseAuthLoaded, firebaseUserId, isAnonymous]);

  return state;
};