import makeStyles from '@material-ui/core/styles/makeStyles';

export const Colors = {
  Primary: 'rgb(50,200,132)',
  Secondary: 'rgb(125, 80, 231)',
  Grey: 'hsl(0, 0%, 50%)',
};

export const useRootStyles = makeStyles(theme => ({
  buttonPrimary: {
    color: 'white',
  },
  textLink: {
    color: Colors.Secondary,
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
}));
