import React from 'react';
import Button from '@material-ui/core/Button';
import {useRootStyles} from '../../styles/RootStyles';
import useTheme from '@material-ui/core/styles/useTheme';

interface Props {
  busy?: boolean,
  color?: 'primary' | 'secondary',
  disabled?: boolean,
  endIcon?: any,
  fullWidth?: boolean,
  label: string,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  startIcon?: any,
  variant?: "text" | "contained" | "outlined",
}

export const HeyloButton = React.forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => {
  const rootClasses = useRootStyles();
  const theme = useTheme();

  const {
    busy,
    color,
    disabled,
    endIcon,
    fullWidth = true,
    label,
    onClick,
    startIcon,
    variant = 'contained',
  } = props;

  return (
      <Button
          aria-label={label}
          buttonRef={ref}
          className={(color === 'primary' && variant === 'contained') ? rootClasses.buttonPrimary : undefined}
          color={color}
          disabled={busy || disabled}
          endIcon={endIcon}
          fullWidth={fullWidth}
          onClick={e => onClick && onClick(e)}
          startIcon={startIcon}
          variant={variant}
      >
        {label}
        {busy && (
            <i aria-hidden="true"
               className="fa fa-spinner fa-spin"
               style={{marginLeft: theme.spacing(1)}}
            />
        )}
      </Button>
  );
});