import React, {Ref} from 'react';
import {TextInput, TextInputProps, ViewStyle} from 'react-native';
import {HeyloTextInput} from '../../ui/input/HeyloTextInput';

type Props = TextInputProps & {
  containerStyle?: ViewStyle,
  error?: boolean,
};

export const EmailTextInput = React.forwardRef((props: Props, ref: Ref<TextInput>) => {
  const {error, placeholder} = props;
  return (
      <HeyloTextInput
          autoCapitalize={'none'}
          autoCompleteType={'email'}
          autoCorrect={false}
          autoFocus={false}
          dense
          keyboardType='email-address'
          label='Email'
          multiline={false}
          ref={ref}
          returnKeyType='next'
          textContentType={'emailAddress'}
          {...props}
          placeholder={error ? '' : placeholder}
      />
  );
});
