import React, {useMemo} from 'react';
import {
  Event,
  EventAttendance,
  UserProfile,
} from '@heylo/shared/src/types/firebase-types';
import {humanReadableEventDateAndTime} from '@heylo/shared/src/features/events/Events';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import {useHistory} from 'react-router-dom';
import {RsvpButton} from './RsvpButton';
import {HeyloText} from 'lib/materialUi';
import Grid from '@material-ui/core/Grid';
import {SQUARE} from './ActionButton';
import SettingsIcon from '@material-ui/icons/Settings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import {useSelector} from 'react-redux';
import {selectAllEventAttendance} from '@heylo/shared/src/features/events/Selectors';
import {createSelector} from 'reselect';
import {
  GetBestUserProfilePhotoUrl,
  HasProfilePhoto,
  SelectUserProfiles,
} from '@heylo/shared/src/features/userProfiles/Selectors';
import _ from 'lodash';
import Linkify from 'linkifyjs/react';
import {useRootStyles} from 'styles/RootStyles';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

const NUM_ATTENDEES = 3;

const formatDateAndTime = (timestamp: number | undefined, timezone: string | undefined) => {
  if (timestamp && timezone) {
    return humanReadableEventDateAndTime(timestamp, timezone);
  }
  return '';
};

const PROFILE_PHOTO_SIZE = 50;
const PHOTO_OVERLAP = 18;
const PHOTO_BORDER_SIZE = 3;

type Props = {
  event: Event,
  goingAttendees?: UserProfile[],
  index: number,
}

export const PureEventCard = (props: Props) => {
  const classes = useRootStyles();

  const {event, goingAttendees = [], index} = props;
  const {communityId = '', eventId = '', image, location, name, notes, timestamp, timezone, video} = event;

  console.count('EventCard');

  const history = useHistory();
  const logger = useLoggingService();
  const theme = useTheme();
  // const matchSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchSm = true;

  const goingAttendeesWithProfilePhotos = useMemo(() => {
    return _.sortBy(goingAttendees, profile => !HasProfilePhoto(profile))
        .slice(0, NUM_ATTENDEES)
        .reverse();
  }, [goingAttendees]);

  const numAttendees = Math.min(goingAttendeesWithProfilePhotos.length || 0, NUM_ATTENDEES);

  const navigateToEvent = () => {
    history.push(`/event?communityId=${communityId}&eventId=${eventId}`);
    logger.logEvent(AnalyticsEvent.EVENTS_CONTENT_TAPPED, {contentType: 'event'});
  };

  const venue = !!location ? location
      : !!video ? 'Video'
          : '';
  const isEven = matchSm || index % 2 === 0;
  const rowReverse = !matchSm && !isEven;
  let textPadding: string = '';
  if (matchSm) {
    textPadding = '0 2rem 1rem 2rem';
  } else if (isEven) {
    textPadding = '0 0 0 10%';
  } else {
    textPadding = '0 10% 0 0';
  }

  return (
      <Grid container direction={rowReverse ? 'row-reverse' : 'row'}
            spacing={0} style={{
        backgroundColor: '#f2f2f2',
        marginBottom: '2rem',
        position: 'relative',
      }}>
        <Grid item
              onClick={navigateToEvent}
              xs={12}
              >
          <div style={{
            cursor: 'pointer',
            height: 0,
            overflow: 'hidden',
            paddingBottom: `${100 / 1.5}%`,
            position: 'relative',
          }}>
            <div style={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              position: 'absolute',
              width: '100%',
            }}>
              {image ? (
                  <>
                    <SettingsIcon
                        className={'fa-spin'}
                        style={{
                          color: '#8f8f8f',
                          fontSize: '3rem',
                        }}
                    />
                    <img
                        alt={'event'}
                        src={image}
                        style={{
                          height: '100%',
                          objectFit: 'cover',
                          position: 'absolute',
                          width: '100%',
                        }}
                    />
                  </>
              ) : (
                  <PhotoCameraIcon style={{
                    color: '#8f8f8f',
                    fontSize: '5rem',
                  }}/>
              )}
            </div>

            {matchSm && (
                <div style={{
                  background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,  rgba(0,0,0,0.75) 100%)',
                  bottom: 0,
                  width: '100%',
                  height: '50%',
                  position: 'absolute',
                }}
                />
            )}
          </div>
        </Grid>

        {/* Event's text */}
        <Grid item
              style={{
                bottom: matchSm ? 0 : undefined,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: matchSm ? 'absolute' : 'relative',
                width: '100%',
              }}
              xs={12}
        >
          <div style={{
            color: matchSm ? 'white' : 'black',
            display: 'flex',
            flexDirection: rowReverse ? 'row-reverse' : 'row',
            padding: textPadding,
          }}>
            <div
                style={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  textOverflow: 'ellipsis',
                  width: matchSm ? '100%' : '80%',
                }}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}>
                {/* Styles here allow text to grow horizontally but not push
                    RSVP button out of place.
                    See: https://css-tricks.com/flexbox-truncated-text/
                */}
                <div onClick={navigateToEvent} style={{flex: 1, minWidth: 0}}>
                  <HeyloText noWrap variant={'h5'} style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                    {name}
                  </HeyloText>
                  <HeyloText color={matchSm ? 'inherit' : undefined} noWrap
                             variant={'subtitle2'}>
                    {formatDateAndTime(timestamp, timezone)}
                    {matchSm && ` • ${venue}`}
                  </HeyloText>
                  {!matchSm && (
                      <HeyloText color={matchSm ? 'inherit' : 'secondary'}
                                 variant={'subtitle2'}>
                        {venue}
                      </HeyloText>
                  )}
                </div>

                <div style={{marginLeft: '0.5rem'}}>
                  <RsvpButton
                      event={event}
                      eventId={eventId}
                      variant={SQUARE}
                  />
                </div>
              </div>

              {!matchSm && (
                  <div onClick={navigateToEvent}>
                    <HeyloText color={'inherit'} noWrap variant={'body1'}>
                      <Linkify options={{className: classes.textLink}}>
                        {notes}
                      </Linkify>
                    </HeyloText>

                    {goingAttendees.length > 0 && (
                        <div style={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                          <div style={{
                            display: 'inline-block',
                            height: PROFILE_PHOTO_SIZE + (PHOTO_BORDER_SIZE * 2),
                            position: 'relative',
                            width: PROFILE_PHOTO_SIZE + (numAttendees - 1) * (50 - PHOTO_OVERLAP + PHOTO_BORDER_SIZE * 2),
                          }}>
                            {goingAttendeesWithProfilePhotos.map((profile, i) => {
                              const {fullName, id} = profile;
                              const profilePhoto = GetBestUserProfilePhotoUrl(profile, 100);
                              return (
                                  <img
                                      alt={`${fullName} profile`}
                                      key={`attendee${id}`}
                                      src={profilePhoto ? profilePhoto : '/person_circle.png'}
                                      style={{
                                        backgroundColor: 'white',
                                        border: `white solid ${PHOTO_BORDER_SIZE}px`,
                                        borderRadius: 100,
                                        height: PROFILE_PHOTO_SIZE,
                                        left: i * (PROFILE_PHOTO_SIZE - PHOTO_OVERLAP),
                                        position: 'absolute',
                                        width: PROFILE_PHOTO_SIZE,
                                      }}
                                  />
                              )
                            })}
                          </div>
                          <HeyloText variant={'h6'}>
                            {goingAttendees.length ?? 0} going
                          </HeyloText>
                        </div>
                    )}
                  </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
  );
};

const makeInterestedUserProfileSelector = () => createSelector(
    selectAllEventAttendance,
    SelectUserProfiles,
    (_state: any, eventId: string) => eventId,
    (allEventAttendees,
     allUserProfiles,
     eventId) => {
      const eventAttendees: { [userId: string]: EventAttendance } = allEventAttendees[eventId] ?? {};
      const userProfiles = [];
      for (const [userId, attendance] of Object.entries(eventAttendees)) {
        const profile = allUserProfiles[userId] ?? {};
        if (attendance.going === 'yes' || attendance.going === 'maybe') {
          userProfiles.push(profile);
        }
      }
      return userProfiles;
    });

export const EventCard = (props: Props) => {
  const {event} = props;
  const {eventId = ''} = event;

  const selectInterestedUserProfiles = useMemo(makeInterestedUserProfileSelector, []);
  const interestedUserProfiles = useSelector((state: RootState) => {
    return selectInterestedUserProfiles(state, eventId).slice(0, NUM_ATTENDEES);
  });

  return (
      <PureEventCard
          goingAttendees={interestedUserProfiles}
          {...props}
      />
  );
};
