import React, {createContext} from 'react';
import {SharedPhoto, Thread} from '@heylo/shared/src/types/firebase-types';

export type ImageViewerParams = {
  albumName: string,
  communityId: string,
  context: 'chat',
  index: number,
  photos: SharedPhoto[],
};


export type ChatScreenState = {
  communityId?: string,
  onImagePress: (params: ImageViewerParams) => void,
  onUserPress: (userId: string) => void,
  thread: Thread,
  threadId: string,
  threadType: string,
  userId: string,
}

export const ChatScreenContext = createContext<ChatScreenState>({
  onImagePress: () => {
  },
  onUserPress: () => {
  },
  thread: {},
  threadId: '',
  threadType: '',
  userId: '',
});

type Props = {
  children: any | any[],
  value: ChatScreenState,
}

export const ChatScreenContextProvider = (props: Props) => {
  const {value, children} = props;
  return (
      <ChatScreenContext.Provider value={value}>
        {children}
      </ChatScreenContext.Provider>
  );
};
