import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import {SelectUserFullName} from '@heylo/shared/src/features/userProfiles/Selectors';
import {ThreadUpdate as ThreadUpdateType} from '@heylo/shared/src/types/firebase-types';
import {ThreadTimestamp} from './ThreadTimestamp';
import {StyleVars} from '../../styles/StyleVars';

type OwnProps = {
  showTimestamp?: boolean,
  threadUpdate: ThreadUpdateType,
};

const mapState = (state: any, ownProps: OwnProps) => {
  const {threadUpdate} = ownProps;
  const updateType = threadUpdate.updateType;
  const targetUserName = SelectUserFullName(state, threadUpdate.updateTargetUserId || '');
  const triggerUserName = SelectUserFullName(state, threadUpdate.updateTriggerUserId || '');
  return {
    targetUserName,
    triggerUserName,
    updateTimestamp: threadUpdate.updateTimestamp || 0,
    updateType,
  };
};
const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & OwnProps;

export const ThreadUpdateText = connector((props: Props) => {
  const {
    showTimestamp,
    targetUserName,
    triggerUserName,
    updateTimestamp,
    updateType,
  } = props;

  const eventDetailsUpdatedMessage = () => {
    if (!triggerUserName) {
      return 'Event details were updated';
    }
    return `${triggerUserName} updated the event`;
  };

  const memberAddedMessage = () => {
    if (triggerUserName && targetUserName && triggerUserName !== targetUserName) {
      return `${triggerUserName} added ${targetUserName}`;
    }
    return `${targetUserName} joined`;
  };

  const memberRemovedMessage = () => {
    if (triggerUserName && targetUserName && triggerUserName !== targetUserName) {
      return `${triggerUserName} removed ${targetUserName}`;
    }
    return `${targetUserName} left the chat`;
  };

  const heroImageUpdatedMessage = () => {
    if (!triggerUserName) {
      return 'The topic photo was updated';
    }
    return `${triggerUserName} changed the photo`;
  };

  const notesUpdatedMessage = () => {
    if (!triggerUserName) {
      return 'The notes were updated';
    }
    return `${triggerUserName} updated the notes`;
  };

  const titleUpdatedMessage = () => {
    if (!triggerUserName) {
      return 'The title was updated';
    }
    return `${triggerUserName} updated the title`;
  };

  const messageForUpdate = () => {
    // TODO: add an experiment to control which of these are shown and which
    // impact the badge count
    switch (updateType) {
      case 'event_updated':
        return eventDetailsUpdatedMessage();
      case 'member_added':
        return memberAddedMessage();
      case 'member_removed':
        return memberRemovedMessage();
      case 'hero_image_updated':
        return heroImageUpdatedMessage();
      case 'notes_updated':
        return notesUpdatedMessage();
      case 'title_updated':
        return titleUpdatedMessage();
      default:
        return '';
    }
  };

  const message = messageForUpdate();
  if (!message) {
    return null;
  }
  return (
      <>
        {showTimestamp && (
            <ThreadTimestamp timestamp={updateTimestamp}/>
        )}
        <View style={styles.updateContainer}>
          <Text style={styles.updateText}>
            {message}
          </Text>
        </View>
      </>
  );
});

const styles = StyleSheet.create({
  updateContainer: {
    marginTop: 5,
  },
  updateText: {
    color: StyleVars.Colors.GreyMedium,
    fontFamily: StyleVars.Font,
    fontSize: 11,
    textAlign: 'center',
  },
});