import moment from 'moment';
import {StripeUser} from '@heylo/shared/src/types/firebase-types';

export const StripeUserHasValidPaymentMethod = (stripeUser?: StripeUser | null): boolean => {
  if (!stripeUser?.paymentMethod) {
    return false;
  }
  const {expiresMonth, expiresYear} = stripeUser.paymentMethod;
  if (typeof expiresMonth !== 'number' || typeof expiresYear !== 'number') {
    console.log('invalid expiration');
    return false;
  }
  const expiration = moment().year(expiresYear ?? 0).month((expiresMonth ?? 0) - 1);
  return moment().isBefore(expiration);
};
