import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import {HeyloText} from '../../lib/materialUi';

type Props = {
  value?: string | number
}

export const GreenBadge = (props: Props) => {
  const {value} = props;
  const theme = useTheme();

  return (
      <div style={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: 100,
        color: 'white',
        display: 'inline-block',
        padding: '0.1rem 0.8rem',
      }}>
        <HeyloText color='inherit' style={{fontWeight: 'bold'}} variant='body2'>
          {value}
        </HeyloText>
      </div>
  );
}