import React, {useEffect, useState} from 'react';
import {
  Community,
  Event,
  StripeAccount,
  StripeUser,
} from "@heylo/shared/src/types/firebase-types";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import {HeyloText} from '../../lib/materialUi';
import {Divider} from '../ui/Divider';
import useTheme from '@material-ui/core/styles/useTheme';
import {Link} from 'react-router-dom';
import {Colors, useRootStyles} from '../../styles/RootStyles';
import {shallowEqual, useSelector} from 'react-redux';
import {SelectActiveCommunity} from '@heylo/shared/src/features/communities/Selectors';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import WarningIcon from '@material-ui/icons/Warning';
import ClockIcon from '@material-ui/icons/Schedule';
import {EventDonateDialog, EventDonateDialogState} from './EventDonateDialog';
import {
  selectHasDonatedToActiveEvent,
  selectLinkedStripeAccount,
  selectStripeUser,
} from '@heylo/shared/src/features/stripe/Selectors';
import {selectActiveUserEmail} from '@heylo/shared/src/features/userSettings/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {
  ResolveStripeAccountStatus,
  StripeAccountStatus,
} from '@heylo/shared/src/features/stripe/Account';
import {selectActiveEvent} from '@heylo/shared/src/features/events/Selectors';
import {HeyloLink} from '../../lib/materialUi/Link';
import {SelectActiveUserIsAdminInActiveCommunity} from '@heylo/shared/src/features/communityMembers/Selectors';
import {DonationSuccessDialog} from '../payments/DonationSuccessDialog';
import {useEventDonationMachine} from '@heylo/shared/src/features/stripe/useEventDonationMachine';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

type Props = {
  community: Community,
  event: Event,
  hasDonated?: boolean,
  isAdmin: boolean,
  stripeAccount: StripeAccount | null,
  stripeUser: StripeUser,
  userEmail: string,
  userId: string,
};

export const PureDonateSection = (props: Props) => {
  const {
    community,
    event,
    hasDonated,
    isAdmin,
    stripeAccount,
    stripeUser,
    userEmail,
    userId,
  } = props;

  const theme = useTheme();
  const classes = useRootStyles();

  const {
    donationsSuggestedAmountCents,
  } = event;
  const [donationAmounts, setDonationAmounts] = useState<number[]>([]);

  useEffect(() => {
    const sorted = _.sortBy(
        Object.values(donationsSuggestedAmountCents || {})
        , v => v)
        .filter(v => v);
    // Add 'other' to the end
    sorted.push(0);
    setDonationAmounts(sorted);
  }, [donationsSuggestedAmountCents]);

  const [showThankYou, setShowThankYou] = useState(hasDonated);
  useEffect(() => {
    setShowThankYou(hasDonated);
  }, [hasDonated]);

  const [accountStatus, setAccountStatus] = useState(StripeAccountStatus.DOES_NOT_EXIST);
  useEffect(() => {
    setAccountStatus(ResolveStripeAccountStatus(stripeAccount));
  }, [stripeAccount]);

  const [showSuccess, setShowSuccess] = useState(false);

  const [state, send] = useEventDonationMachine({
    build: process.env.REACT_APP_BUILD_VARIANT ?? '',
    community,
    event,
    isAdmin,
    openStripeCheckoutUri: (stripeCheckoutUri: string): Promise<void> => {
      return new Promise(resolve => {
        window.open(stripeCheckoutUri, '_blank', 'noreferrer');
        setTimeout(resolve, 2000);
      });
    },
    showSuccess: async (): Promise<void> => {
      setShowSuccess(true);
    },
    stripeAccount,
    userEmail,
    userId,
  });

  const [donationAmountInCents, setDonationAmountInCents] = useState(0);
  const handleDonateClick = async (amountInCents: number, index: number) => {
    setDonationAmountInCents(amountInCents);
    send({type: 'DIALOG_OPEN', amountInCents});
  };

  const donationsOff = state.matches('inactive.hidden');
  if (donationsOff || !stripeUser) {
    return null;
  }

  const donationsDisabled = state.matches('inactive.disabled');
  const hasError = state.matches('donationDialogOpen.error');
  const isDialogOpen = state.matches('donationDialogOpen');
  const isDonating = state.matches('donating');
  let dialogState = EventDonateDialogState.HIDDEN;
  if (hasError) {
    dialogState = EventDonateDialogState.ERROR;
  } else if (isDialogOpen) {
    dialogState = EventDonateDialogState.EDITING;
  } else if (isDonating) {
    dialogState = EventDonateDialogState.SUBMITTED;
  }

  const renderAccountDisabled = () => {
    let disabledColor = '';
    let disabledReasonText = '';
    let DisabledIcon: any;
    switch (accountStatus) {
      case StripeAccountStatus.DOES_NOT_EXIST:
        disabledColor = Colors.Secondary;
        disabledReasonText = 'Tap to link your bank account, and begin collecting donations!';
        DisabledIcon = AccountBalanceIcon;
        break;
      case StripeAccountStatus.PAYMENTS_DISABLED:
        disabledColor = 'red';
        disabledReasonText = 'Donations are disabled. Tap to update your account information.';
        DisabledIcon = WarningIcon;
        break;
      case StripeAccountStatus.PAYMENTS_PENDING:
        disabledColor = 'black';
        disabledReasonText = 'Hang tight! Your account details are being verified.';
        DisabledIcon = ClockIcon;
        break;
    }
    return (
        <Link
            className={classes.textLink}
            style={{
              color: disabledColor,
              display: 'flex',
              flexDirection: 'row',
            }}
            to={`/community?communityId=${community?.communityId}&edit=1`}
        >
          <DisabledIcon style={{
            marginRight: '0.3vw',
          }}/>
          <HeyloText
              color={'inherit'}
              variant={'body1'}
          >
            {disabledReasonText}
          </HeyloText>
        </Link>
    );
  };

  return (
      <>
        <EventDonateDialog
            event={event}
            initialAmountInCents={donationAmountInCents}
            onClose={() => send({type: 'DIALOG_CLOSE'})}
            send={send}
            state={dialogState}
            stripeUser={stripeUser}
        />

        <DonationSuccessDialog
            onClose={() => setShowSuccess(false)}
            open={showSuccess}
        />

        <Divider/>

        <div style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}>
          {showThankYou ? (
              <>
                <HeyloText style={{fontWeight: 'bold'}} variant={'body1'}>
                  Thank you for donating! 🎉
                </HeyloText>
                <HeyloLink onClick={() => setShowThankYou(false)}
                           variant={'body1'}>
                  Tap to donate again
                </HeyloLink>
              </>
          ) : (
              <>
                <HeyloText style={{fontWeight: 'bold'}} variant={'body1'}>
                  Donate to support the event!
                </HeyloText>
                <div style={{
                  marginBottom: theme.spacing(1),
                  marginTop: theme.spacing(1),
                }}>
                  <ButtonGroup color="primary"
                               disabled={donationsDisabled}
                               aria-label="outlined primary button group">
                    {donationAmounts.map((amountInCents, index) => {
                      return (
                          <Button
                              key={`donateButton${index}`}
                              onClick={() => handleDonateClick(amountInCents, index)}
                              style={{
                                minWidth: 70,
                                width: '12vw',
                              }}
                          >
                            {amountInCents
                                ? `$${Math.round(amountInCents / 100)}`
                                : (donationAmounts.length > 1 ? 'other' : 'donate')
                            }
                          </Button>
                      );
                    })}
                  </ButtonGroup>
                </div>
                {donationsDisabled && renderAccountDisabled()}
              </>
          )}
        </div>

        <Divider/>
      </>
  );
};

export const DonateSection = () => {
  const {
    community,
    event,
    hasDonated,
    isAdmin,
    stripeAccount,
    stripeUser,
    userEmail,
    userId,
  } = useSelector((state: RootState) => ({
    community: SelectActiveCommunity(state),
    event: selectActiveEvent(state),
    hasDonated: selectHasDonatedToActiveEvent(state),
    isAdmin: SelectActiveUserIsAdminInActiveCommunity(state),
    stripeAccount: selectLinkedStripeAccount(state),
    stripeUser: selectStripeUser(state),
    userEmail: selectActiveUserEmail(state),
    userId: selectActiveUserId(state),
  }), shallowEqual);

  if (!community || !event || !stripeUser || !userEmail || !userId) {
    return null;
  }
  return <PureDonateSection
      community={community}
      event={event}
      hasDonated={hasDonated}
      isAdmin={isAdmin}
      stripeAccount={stripeAccount}
      stripeUser={stripeUser}
      userEmail={userEmail}
      userId={userId}
  />
};