import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {shallowEqual, useSelector} from 'react-redux';
import {selectIsMemberOfActiveMarketplace} from '@heylo/shared/src/features/marketplaces/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';

export const useMarketplaceAuth = () => {
  const {activeUserId, hasPermission} = useSelector((state: RootState) => ({
    activeUserId: selectActiveUserId(state),
    hasPermission: selectIsMemberOfActiveMarketplace(state),
  }), shallowEqual);

  return {hasPermission: activeUserId && hasPermission};
};