import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {HeyloButton} from '../../ui/button/HeyloButton';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectJoinRequestCommunityProfile} from '@heylo/shared/src/features/marketplaces/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {UserSettingsFirebase} from '@heylo/shared/src/features/userSettings/Firebase';

export const JoinRequestSuccessfullySent = ({onPress}: { onPress: () => void }) => {
  const text = useTextStyles();

  const {
    activeUserId,
    community,
  } = useSelector((state: RootState) => ({
    activeUserId: selectActiveUserId(state),
    community: selectJoinRequestCommunityProfile(state),
  }), shallowEqual);

  const {communityId = ''} = community;
  useEffect(() => {
    console.log('AddProspectiveCommunity', activeUserId, communityId);
    if (activeUserId && communityId) {
      UserSettingsFirebase.AddProspectiveCommunity(activeUserId, communityId);
    }
  }, []);

  return (
      <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '75%',
      }}>
        <Text style={text.h2}>
          👍
        </Text>
        <Text style={[text.h5, {marginVertical: 30}]}>
          Join request sent. A group member will get back to you shortly!
        </Text>
        <HeyloButton
            onPress={onPress}
            label={'Explore more groups'}
        />
      </View>
  );
};