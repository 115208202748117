import {assign, createMachine} from 'xstate';

export interface AuthContext {
  // A firebase anonymous user ID. Only used temporarily, during the account
  // creation flow. Will only be set while in the `signedOut` state.
  anonymousUserId: string;
  userId: string;
}

type AuthEvent =
    | { type: 'AUTH_CHANGE_NO_USER' }
    | { type: 'AUTH_CHANGE_ANONYMOUS_USER', userId: string }
    | { type: 'AUTH_CHANGE_USER', userId: string }
    | { type: 'SIGN_OUT_BEGIN' };


type AuthState =
    | {
  // The user's authentication state is not yet known.
  value: 'rehydrating';
  context: AuthContext;
}
    | {
  // In the process of signing the user in.
  value: 'signingIn';
  context: AuthContext;
}
    | {
  // User is full signed in
  value: 'signedIn';
  context: AuthContext;
}
    | {
  // In the process of signing the user out.
  value: 'signingOut';
  context: AuthContext;
}
    | {
  value: 'signedOut';
  context: AuthContext;
};

export const AuthMachine = createMachine<AuthContext, AuthEvent, AuthState>({
  id: 'authentication',
  initial: 'rehydrating',
  states: {
    rehydrating: {
      on: {
        AUTH_CHANGE_NO_USER: {
          actions: 'updateUserId',
          target: 'signedOut',
        },
        AUTH_CHANGE_ANONYMOUS_USER: {
          actions: 'updateAnonymousUserId',
          target: 'signedOut',
        },
        AUTH_CHANGE_USER: {
          actions: 'updateUserId',
          target: 'signingIn',
        },
      },
    },
    signingIn: {
      invoke: {
        id: 'invokeSigningIn',
        src: 'signingInService',
        onDone: {target: 'signedIn'},
        onError: {target: 'signingOut'},
      },
    },
    signedIn: {
      on: {
        SIGN_OUT_BEGIN: {target: 'signingOut'},
      },
    },
    signingOut: {
      invoke: {
        id: 'invokeSigningOut',
        src: 'signingOutService',
        onDone: {
          target: 'signedOut',
          actions: assign({anonymousUserId: '', userId: ''}),
        },
        onError: {
          target: 'signedIn',
        },
      },
    },
    signedOut: {
      on: {
        AUTH_CHANGE_ANONYMOUS_USER: {
          actions: 'updateAnonymousUserId',
        },
        AUTH_CHANGE_USER: {
          actions: 'updateUserId',
          target: 'signingIn',
        },
      },
    },
  },
}, {
  actions: {
    updateAnonymousUserId: assign((ctx, event: AuthEvent) => ({
      anonymousUserId: event.type === 'AUTH_CHANGE_ANONYMOUS_USER' ? event.userId : '',
      userId: '',
    })),
    updateUserId: assign((ctx, event: AuthEvent) => ({
      anonymousUserId: '',
      userId: event.type === 'AUTH_CHANGE_USER' ? event.userId : '',
    })),
  },
});
