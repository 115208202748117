import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useMarketplaceAuth} from '@heylo/components/src/features/marketplaces/useMarketplaceAuth';
import {WebEmailVerificationForm} from '../auth/WebEmailVerificationForm';
import {EmailVerificationContext} from '@heylo/shared/src/features/auth/Slice';
import {DiscoverCommunity} from './DiscoverCommunity';
import {Breadcrumbs} from '../navigation/Breadcrumbs';
import {
  resolveMarketplaceCasualName,
  resolveUriForMarketplace,
} from '@heylo/shared/src/features/marketplaces/Util';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  selectActiveMarketId,
  selectActiveMarketplace,
} from '@heylo/shared/src/features/marketplaces/Selectors';
import {
  selectActiveCommunityProfile,
  selectAllCommunityProfiles,
} from '@heylo/shared/src/features/communityProfiles/Selectors';
import {View} from 'react-native';
import {ScrollToTopOnMount} from '../navigation/ScrollToTopOnMount';
import {ActiveMarketplaceChanged} from '@heylo/shared/src/features/marketplaces/Slice';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {ActiveCommunityUpdated} from '@heylo/shared/src/features/communities/Slice';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {LoadingScreen} from '@heylo/components/src/features/loading/LoadingScreen';

export const DiscoverCommunityScreen = () => {
  const dispatch = useDispatch();
  const {communityId: urlCommunityId = ''} = useParams();

  const {
    activeCommunityId,
    activeMarketplace,
    activeMarketplaceId,
    communityProfile,
    urlCommunityProfile,
  } = useSelector((state: RootState) => ({
    activeCommunityId: SelectActiveCommunityId(state),
    activeMarketplace: selectActiveMarketplace(state) || {},
    activeMarketplaceId: selectActiveMarketId(state),
    communityProfile: selectActiveCommunityProfile(state),
    urlCommunityProfile: selectAllCommunityProfiles(state)[urlCommunityId],
  }), shallowEqual);

  useEffect(() => {
    if (activeCommunityId !== urlCommunityId) {
      dispatch(ActiveCommunityUpdated(urlCommunityId));
    }
  }, [activeCommunityId, urlCommunityId]);

  useEffect(() => {
    if (urlCommunityProfile) {
      const {marketplaceId = ''} = urlCommunityProfile;
      if (marketplaceId !== activeMarketplaceId) {
        dispatch(ActiveMarketplaceChanged(marketplaceId));
      }
    }
  }, [activeMarketplaceId, urlCommunityProfile]);

  const {
    communityName = '',
  } = communityProfile || {};

  const {
    nameFull: marketplaceName = '',
  } = activeMarketplace;

  const {hasPermission} = useMarketplaceAuth();
  if (!hasPermission) {
    return (
        <WebEmailVerificationForm
            context={EmailVerificationContext.DISCOVER_COMMUNITY}
            percent={'0%'}
        />
    );
  }
  if (Object.keys(communityProfile).length === 0) {
    return <LoadingScreen/>;
  }
  return (
      <View style={{flex: 1}}>
        <ScrollToTopOnMount/>
        <Breadcrumbs
            crumbs={[
              {label: 'Find groups', to: '/m/'},
              marketplaceName ?
                  {
                    label: `${resolveMarketplaceCasualName(activeMarketplace)} groups`,
                    to: resolveUriForMarketplace(activeMarketplace),
                  } : null,
            ]}
            title={communityName}
        />
        <View style={{paddingTop: StyleConstants.SPACING}}>
          <DiscoverCommunity/>
        </View>
      </View>
  )
};