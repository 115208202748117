import {selectActiveMarketplaceCommunities} from '@heylo/shared/src/features/marketplaces/Selectors';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {NormalizeForSearch} from '@heylo/shared/src/util/strings';
import {CommunityProfile} from '@heylo/shared/src/types/firebase-types';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {SearchSelectors} from '@heylo/shared/src/features/search/Selectors';
import {SearchFirebase} from '@heylo/shared/src/features/search/Firebase';
import {useDebounce} from 'use-debounce';
import moment from 'moment';

export const useGroupSearchState = ({filterText}: { filterText: string }) => {
  const dispatch = useDispatch();

  const [debouncedFilterText] = useDebounce(filterText, 200);

  const {
    activeGroups,
    searchIndex,
    searchIndexLastUpdateTimestamp,
  } = useSelector((state: RootState) => ({
    activeGroups: selectActiveMarketplaceCommunities(state),
    searchIndex: SearchSelectors.SearchIndex(state),
    searchIndexLastUpdateTimestamp: SearchSelectors.SearchIndexLastUpdateTimestamp(state),
  }), shallowEqual);

  useEffect(() => {
    if (searchIndex.length === 0
        || moment().diff(moment(searchIndexLastUpdateTimestamp), 'minutes') > 10) {
      console.warn('updating search index');
      dispatch(SearchFirebase.LoadSearchIndex());
    }
  }, [searchIndex]);

  const [searchResults, setSearchResults] = useState<CommunityProfile[]>([]);

  useEffect(() => {
    if (!debouncedFilterText) {
      setSearchResults([]);
      return;
    }

    const communityIdFilter = new Set<string>(activeGroups.map(g => g.communityId || ''));
    const normalizedFilterText = NormalizeForSearch(debouncedFilterText);
    setSearchResults(
        searchIndex
            .filter(spec => {
              const {
                communityId = '',
                communityName,
                locationName,
                marketplaceName,
              } = spec;
              if (!communityId || !communityName) {
                return false;
              }
              const notInActiveMarketplace = communityIdFilter.size > 0 && !communityIdFilter.has(communityId);
              if (notInActiveMarketplace) {
                return false;
              }
              return NormalizeForSearch(communityName).indexOf(normalizedFilterText) >= 0
                  || NormalizeForSearch(marketplaceName).indexOf(normalizedFilterText) >= 0
                  || NormalizeForSearch(locationName).indexOf(normalizedFilterText) >= 0;
            })
            .map((spec): CommunityProfile => {
              const {
                communityId = '',
                communityName = '',
                locationName = '',
                marketplaceName = ''
              } = spec;

              return {
                communityId,
                communityName,
                locationName: marketplaceName || locationName,
              };
            }),
    );
  }, [debouncedFilterText, searchIndex]);

  return {
    searchResults,
  };
};