import {createSelector} from 'reselect';
import {UserProfile} from '@heylo/shared/src/types/firebase-types';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

export const selectAllUserProfiles = (state: RootState) => state.userProfiles?.FIELD_USER_PROFILES;
export const SelectUserProfileLoaded = (state: RootState) => state.userProfiles?.userProfilesLoaded;

export const HasProfilePhoto = (profile: UserProfile): boolean => {
  return !!(profile.profilePictureUrl
      || profile.profilePictureHighRes?.url
      || profile.profilePictureMediumRes?.url
      || profile.profilePictureLowRes?.url);
};

export const GetBestUserProfilePhotoUrl = (profile: UserProfile | null | undefined, size: number): string => {
  if (!profile) {
    return '';
  }
  if (size <= 50) {
    const url = profile.profilePictureLowRes?.url;
    if (url) {
      return url;
    }
  }
  if (size <= 100) {
    const url = profile?.profilePictureMediumRes?.url;
    if (url) {
      return url;
    }
  }
  return profile.profilePictureHighRes?.url
      // TODO: remove this legacy field eventually
      || profile.profilePictureUrl
      || '';
};

export const SelectUserProfiles = (state: RootState)
    : { [userId: string]: UserProfile } =>
    state.userProfiles?.FIELD_USER_PROFILES;

export const SelectActiveUserProfile = createSelector(
    [selectActiveUserId, SelectUserProfiles],
    (userId, profiles)
        : UserProfile => {
      return profiles[userId] || {};
    });

export function SelectOneUserProfile(state: RootState, userId: string)
    : UserProfile {
  return state.userProfiles.FIELD_USER_PROFILES[userId] || {};
}

export function SelectUserAboutMe(state: RootState, userId: string) {
  return (SelectOneUserProfile(state, userId).bio || '').trim();
}

export function SelectUserFullName(state: RootState, userId: string) {
  return (SelectOneUserProfile(state, userId).fullName || '').trim();
}

export function SelectUserInterests(state: RootState, userId: string) {
  return (SelectOneUserProfile(state, userId).goals || '').trim();
}

export function SelectUserProfilePictureUrl(state: RootState, userId: string, size: number): string {
  const profile = SelectOneUserProfile(state, userId);
  return GetBestUserProfilePhotoUrl(profile, size);
}

