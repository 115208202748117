import moment from 'moment-timezone';

const formatEventTimestamp = (eventTimestamp: number, timezone: string, formatString: string): string => {
  return moment.tz(eventTimestamp, timezone).format(formatString);
};

const shouldShowEventTimezone = (eventTimezone: string): boolean => {
  return eventTimezone
      ? moment().tz(eventTimezone).utcOffset() !== moment().tz(moment.tz.guess()).utcOffset()
      : true;
};

export function humanReadableEventDate(eventTimestamp: number, eventTimezone: string): string {
  const formatString = 'MMM[\n]DD';
  return formatEventTimestamp(eventTimestamp, moment.tz.guess(), formatString);
}

export function humanReadableEventTime(eventTimestamp: number, eventTimezone: string, forceTimezone?: boolean): string {
  const formatString = forceTimezone || shouldShowEventTimezone(eventTimezone) ? 'h:mma z' : 'h:mma';
  return formatEventTimestamp(eventTimestamp, moment.tz.guess(), formatString);
}

export function humanReadableEventDateAndTime(eventTimestamp: number, eventTimezone?: string): string {
  const formatString = shouldShowEventTimezone(eventTimezone || moment.tz.guess())
      ? 'ddd MMM D [@] h:mma z'
      : 'ddd MMM D [@] h:mma';
  return formatEventTimestamp(eventTimestamp, moment.tz.guess(), formatString);
}

