import {
  GetBestUserProfilePhotoUrl,
  SelectUserProfiles,
} from '@heylo/shared/src/features/userProfiles/Selectors';
import React, {useEffect} from 'react';
import {HeyloButton, HeyloText} from 'lib/materialUi';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {
  SelectActiveCommunityId,
  SelectCommunityProspects,
} from '@heylo/shared/src/features/communities/Selectors';
import {IncrementUserImpression} from '@heylo/shared/src/features/userEvents/Actions';
import {ProspectiveMemberActions} from 'features/communityMembers/ProspectiveMemberActions';
import EditIcon from '@material-ui/icons/Edit';
import {useHistory} from 'react-router-dom';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import Linkify from 'linkifyjs/react';
import {useRootStyles} from 'styles/RootStyles';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {HeyloImage} from '@heylo/components/src/ui/image';
import {View} from 'react-native';

type OwnProps = {
  memberId: string,
};

// How the selected user is related to the active user.
export enum Relation {
  SELF,
  PROSPECT,
  MEMBER,
};

const mapState = (state: RootState, ownProps: OwnProps) => {
  const {memberId} = ownProps;
  const activeUserId = selectActiveUserId(state);
  const communityId = SelectActiveCommunityId(state);
  return {
    relation: memberId === activeUserId ? Relation.SELF
        : !!SelectCommunityProspects(state)[communityId]?.[memberId] ? Relation.PROSPECT
            : Relation.MEMBER,
    userProfile: SelectUserProfiles(state)[memberId] ?? {},
  }
};

const connector = connect(mapState, {});

type Props = ConnectedProps<typeof connector> & OwnProps;

export const PureMemberDetails = (props: Props) => {
  const classes = useRootStyles();

  const {
    relation,
    userProfile,
  } = props;

  const {
    bio,
    goals: interests,
    id: userId = '',
    fullName: name,
  } = userProfile;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(IncrementUserImpression(userId));
  }, [dispatch, userId]);

  const photoUrl = GetBestUserProfilePhotoUrl(userProfile, 500);

  return (
      <div style={{
        flexDirection: 'column',
        display: 'flex',
        overflow: 'hidden',
      }}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <View style={{width: '66%'}}>
          <HeyloImage
              aspectRatio={1}
              style={{
                borderRadius: 500,
              }}
              uri={photoUrl || '/person_circle.png'}
          />
          </View>
        </div>

        <HeyloText align={'center'} paragraph variant={'subtitle1'}>
          {name}
        </HeyloText>

        {bio && (
            <>
              <HeyloText variant={'subtitle2'}>
                ABOUT ME
              </HeyloText>
              <HeyloText paragraph variant={'body1'}>
                <Linkify options={{className: classes.textLink}}>
                  {bio}
                </Linkify>
              </HeyloText>
            </>
        )}

        {interests && (
            <>
              <HeyloText variant={'subtitle2'}>
                INTERESTS
              </HeyloText>
              <HeyloText paragraph variant={'body1'}>
                <Linkify options={{className: classes.textLink}}>
                  {interests}
                </Linkify>
              </HeyloText>
            </>
        )}

        {relation === Relation.PROSPECT && (
            <ProspectiveMemberActions userId={userId}/>
        )}

        {relation === Relation.SELF && (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <HeyloButton
                  color={'primary'}
                  fullWidth={false}
                  label={'Edit your profile'}
                  onClick={() => history.push('/userSettings')}
                  startIcon={<EditIcon/>}
              />
            </div>
        )}
      </div>
  );
};

export const MemberDetails = connector(PureMemberDetails);
