import moment from 'moment';
import {FirebaseDatabase} from '@heylo/firebase-database';

export const getServerTimestamp = async (): Promise<number> => {
  return new Promise((resolve) => {
    FirebaseDatabase().ref('/.info/serverTimeOffset')
      .once('value')
      .then(data => {
        const offset = data.val() || 0;
        resolve(Math.round(offset) + moment().valueOf());
      })
      .catch(e => {
        console.warn(e.message);
        resolve(moment().valueOf());
      });
  });
};