import moment from 'moment';
import {
  ContentSendState,
  SendState,
} from '@heylo/shared/src/features/ui/Slice';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

const resolveSendState = (state: ContentSendState | null): SendState => {
  if (!state) {
    return SendState.UNKNOWN;
  }
  const {state: sendState = SendState.UNKNOWN, timestamp = 0} = state;
  if (sendState === SendState.STARTED && moment().diff(timestamp, 'minutes') >= 3) {
    return SendState.ERROR;
  }
  return sendState;
};

export const CommunityContentSendStateSelector = (state: RootState, communityId: string, contentId: string)
    : SendState => {
  return resolveSendState(state.ui.FIELD_COMMUNITY_CONTENT_SEND_STATE[communityId]?.[contentId] || null);
};

export const ContentSendStateSelector = (state: RootState, contentId: string)
    : SendState => {
  return resolveSendState(state.ui.FIELD_CONTENT_SEND_STATE[contentId] || null);
};

export const selectLocalPhotoUris = (state: RootState) => state.ui.localPhotoUris ?? {};
