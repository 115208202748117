import {SUPPORTED_TIMEZONES} from '@heylo/shared/src/util/Timezones';
import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment-timezone';

type Props = {
  onChange: (val: string) => void,
  value: string,
};

export const TimezonePicker = (props: Props) => {
  const {onChange, value} = props;

  return (
      <>
        <FormControl variant='outlined'>
          <InputLabel id={'timezone-label'}>
            Timezone
          </InputLabel>
          <Select
              autoWidth
              labelId="timezone-label"
              label={'Timezone'}
              margin={'dense'}
              onChange={event => onChange(event.target.value as string)}
              renderValue={value => moment.tz(moment(), value as string).format('z')}
              value={value}
              variant={'outlined'}
          >
            {Object.entries(SUPPORTED_TIMEZONES).map(([value, label]) => (
                <MenuItem key={`timezone${value}`} value={value}>
                  {label}
                </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
  );
};