import {
  Message,
  Reaction,
  SharedPhoto,
} from '@heylo/shared/src/types/firebase-types';
import {FirebaseDatabase} from '@heylo/firebase-database';
import {ThreadListeners} from '@heylo/shared/src/features/firebase/Listeners';
import {LastReactionIdForThreadSelector} from '@heylo/shared/src/features/reactions/Selectors';
import {
  ACTION_ADD_REACTION,
  ACTION_REMOVE_REACTION,
  ACTION_STORE_COMMUNITY_PHOTO_REACTIONS,
} from '@heylo/shared/src/features/reactions/Slice';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';

export const FirebaseCreateMessageReaction = (message: Message, communityId: string, threadId: string, userId: string, reactionType: number | null) => {
  return new Promise(resolve => {
    const {key: messageId, image} = message;
    const reaction: Reaction = {
      messageId,
      // @ts-ignore
      reactionType,
      userId,
    };
    if (communityId) {
      reaction.communityId = communityId;
    }

    const promises: Promise<any>[] = [
      FirebaseDatabase().ref(`/reactions/${threadId}`).push(reaction),
    ];
    if (image && communityId) {
      promises.push(
          FirebaseDatabase().ref(`/communityPhotoReactions/${communityId}/${messageId}/${userId}`).set(reactionType),
      );
    }
    Promise.all(promises)
        .then(() => resolve())
        .catch(e => {
          console.error('error creating reaction', e.message);
          resolve();
        });
  });
};

export const FirebaseCreateCommunityPhotoReaction = (photo: SharedPhoto, userId: string, reactionType: number | null) => {
  return new Promise((resolve, reject) => {
    const {communityId, photoId, threadId} = photo;

    const promises: Promise<any>[] = [];
    if (communityId && photoId) {
      promises.push(FirebaseDatabase().ref(`/communityPhotoReactions/${communityId}/${photoId}/${userId}`).set(reactionType));
    }
    if (threadId) {
      const reaction: Reaction = {
        messageId: photoId,
        // @ts-ignore
        reactionType,
        userId,
      };
      if (communityId) {
        reaction.communityId = communityId || '';
      }
      promises.push(FirebaseDatabase().ref(`/reactions/${threadId}`).push(reaction));
    }

    Promise.all(promises)
        .then(() => resolve())
        .catch(reject);
  });
};

export const AttachThreadReactionListener = (userId: string, threadId: string, retryCount = 1)
    : ThunkReturnType<void> => (dispatch, getState) => {
  if (ThreadListeners.REACTIONS[threadId]) {
    return;
  }
  const state = getState();
  const lastReactionId = LastReactionIdForThreadSelector(state, threadId);
  const ref = FirebaseDatabase().ref(`/reactions/${threadId}`).orderByKey().startAt(lastReactionId).limitToLast(100);
  ThreadListeners.REACTIONS[threadId] = ref;
  ref.on('child_added', snapshot => {
    if (!snapshot) {
      return;
    }
    const reactionId = snapshot.key;
    if (reactionId === lastReactionId) {
      return;
    }
    const reaction: Reaction = snapshot.val();
    const {messageId, userId, reactionType} = reaction;
    if (!messageId || !userId || !reactionId) {
      return;
    }
    if (reactionType) {
      dispatch(ACTION_ADD_REACTION({
        threadId,
        messageId,
        userId,
        reactionId,
        reactionType,
      }));
    } else {
      dispatch(ACTION_REMOVE_REACTION({
        threadId,
        messageId,
        userId,
        reactionId,
      }));
    }
  }, (e: Error) => {
    console.log('lost connection to reactions', threadId, e.message);
    delete ThreadListeners.REACTIONS[threadId];
    if (retryCount <= 4) {
      setTimeout(() => {
        dispatch(AttachThreadReactionListener(userId, threadId, retryCount + 1));
      }, Math.pow(retryCount, 2) * 1000);
    }
  });
};

export const AttachCommunityPhotoReactionsListener = (communityId: string | null | undefined, photoId: string)
    : ThunkReturnType<void> => dispatch => {
  if (!communityId || !photoId) {
    return;
  }
  FirebaseDatabase().ref(`/communityPhotoReactions/${communityId}/${photoId}`)
      .on('value',
          snapshot => {
            const reactions: { [userId: string]: number } = snapshot?.val() || {};
            dispatch(ACTION_STORE_COMMUNITY_PHOTO_REACTIONS({
              communityId,
              photoId,
              reactions,
            }));
          },
          (e: Error) => {
            console.log('lost connection to community photo reactions', communityId, photoId, e);
          });
};

export const DetachCommunityPhotoReactionsListener = (communityId: string | null | undefined, photoId: string) => {
  if (!communityId || !photoId) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/communityPhotoReactions/${communityId}/${photoId}`);
  ref.off();
};