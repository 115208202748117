import React, {Ref} from 'react';
import {Platform, TextInput, TextInputProps} from 'react-native';

export const NativeTextInput = React.forwardRef((props: TextInputProps, ref: Ref<TextInput>) => {
  if (Platform.OS === 'web') {
    return (
        <div className={'disable-outline'}>
          <TextInput ref={ref} {...props}/>
        </div>
    );
  }
  return <TextInput ref={ref} {...props}/>;
});