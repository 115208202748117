import React from 'react';
import {Keyboard, TouchableWithoutFeedback} from 'react-native';

type Props = {
  children: React.ReactNode,
};

export const DismissKeyboard = (props: Props) => {
  const {} = props;
  return (
      <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        {props.children}
      </TouchableWithoutFeedback>
  );
};
