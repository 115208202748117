import {AmplitudeLogger} from '@heylo/shared/src/services/logging/AmplitudeLogger';
import {ILogger} from '@heylo/shared/src/services/logging/Logger';
import {WhitelistedCommunityEvents} from '@heylo/shared/src/constants/AnalyticsEvents';

export class CommunityAmplitudeLogger extends AmplitudeLogger implements ILogger {

  constructor(apiKey: string, buildVariant: string) {
    super('community', apiKey, buildVariant);
  }

  logEvent(eventCode: string, params?: any) {
    if (WhitelistedCommunityEvents[eventCode]) {
      // NB: only sending event parameters (i.e., explicitly dropping super
      // properties). Super properties are usually specific to the user
      // rather than the interaction taking place.
      super.logEvent(eventCode, params);
    }
  }

  setCurrentScreen(_: string) {
    // We don't log screens to Amplitude Communities.
  }

}