import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  NewCommunityReview,
  NewCommunityReviewState,
} from '@heylo/components/src/features/communityCreation/NewCommunityReview';
import {NextButton, ProgressHeader} from 'features/navigation/ProgressHeader';

export const NewGroupReviewScreen = () => {
  const history = useHistory();

  const [state, setState] = useState(NewCommunityReviewState.IDLE);

  useEffect(() => {
    switch (state) {
      case NewCommunityReviewState.SUCCESS:
        history.push('/newGroup/done');
        break;
    }
  }, [state]);

  const onNextPress = () => {
    setState(NewCommunityReviewState.SAVE_START);
  }

  const onBackPress = () => {
    history.goBack();
  }

  return (
      <ProgressHeader
          nextButton={(state === NewCommunityReviewState.SAVE_START || state === NewCommunityReviewState.SAVE_PENDING)
              ? NextButton.NEXT_BUSY : NextButton.NEXT}
          onBackPress={onBackPress}
          percent={'100%'}
          title={'Review group page'}
          onNextPress={onNextPress}
      >
        <NewCommunityReview
            fallbackImageSource={{uri: '/assets/hands-in.jpg'}}
            state={state}
            setState={setState}
        />
      </ProgressHeader>
  );
};