import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment';
import {
  CommunityPhotoAlbum,
  SharedPhoto,
} from '@heylo/shared/src/types/firebase-types';
import {DeepReadonly} from 'utility-types';
import {AppColdStart, AppReset} from '@heylo/shared/src/features/app/Actions';
import {UserLeftCommunity} from '@heylo/shared/src/features/communities/Slice';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';

export type PhotosState = {
  FIELD_ACTIVE_COMMUNITY_ALBUM: string,
  FIELD_COMMUNITY_ALBUMS: DeepReadonly<{ [communityId: string]: { [albumId: string]: CommunityPhotoAlbum } }>,
  FIELD_COMMUNITY_PHOTOS: DeepReadonly<{ [communityId: string]: { [photoId: string]: SharedPhoto } }>,
  FIELD_MOST_RECENT_COMMUNITY_PHOTO_TIMESTAMP: DeepReadonly<{ [communityId: string]: number }>,
  FIELD_MOST_RECENT_PRIVATE_CHAT_PHOTO_TIMESTAMP: DeepReadonly<{ [threadId: string]: number }>,
  FIELD_PRIVATE_CHAT_PHOTOS: DeepReadonly<{ [threadId: string]: { [photoId: string]: SharedPhoto } }>,
};

const initialState: PhotosState = {
  FIELD_ACTIVE_COMMUNITY_ALBUM: '',
  FIELD_COMMUNITY_ALBUMS: {},
  FIELD_COMMUNITY_PHOTOS: {},
  FIELD_MOST_RECENT_COMMUNITY_PHOTO_TIMESTAMP: {},
  FIELD_MOST_RECENT_PRIVATE_CHAT_PHOTO_TIMESTAMP: {},
  FIELD_PRIVATE_CHAT_PHOTOS: {},
};

const maybeIncreaseTimestamp = (id: string, photo: SharedPhoto, state: Draft<{ [communityOrThreadId: string]: number }>) => {
  const currentTimestamp = state[id] || 0;
  const {creationTimestamp: nextTimestamp = 0} = photo;
  if (nextTimestamp > 0 && (!currentTimestamp || nextTimestamp > currentTimestamp)) {
    state[id] = nextTimestamp;
  }
};

export const slice = createSlice({
  name: 'photos',
  initialState,
  reducers: {
    ACTION_ADD_ALBUM_PHOTOS: (state, action: PayloadAction<{ communityId: string, albumId: string, photoIds: ReadonlyArray<string> }>) => {
      const {communityId, albumId, photoIds} = action.payload;
      if (!state.FIELD_COMMUNITY_ALBUMS[communityId]) {
        state.FIELD_COMMUNITY_ALBUMS[communityId] = {};
      }
      if (!state.FIELD_COMMUNITY_ALBUMS[communityId][albumId]) {
        state.FIELD_COMMUNITY_ALBUMS[communityId][albumId] = {};
      }

      const newPhotos: { [photoId: string]: number } = {};
      for (const pid of photoIds) {
        newPhotos[pid] = moment().valueOf();
      }
      const mergedPhotos = Object.assign(
          newPhotos,
          state.FIELD_COMMUNITY_ALBUMS[communityId]?.[albumId]?.photos || {},
      );
      state.FIELD_COMMUNITY_ALBUMS[communityId][albumId].photos = mergedPhotos;
    },

    ACTION_REMOVE_ALBUM_PHOTOS: (state, action: PayloadAction<{ communityId: string, albumId: string, photoIds: ReadonlyArray<string> }>) => {
      const {communityId, albumId, photoIds} = action.payload;
      if (!state.FIELD_COMMUNITY_ALBUMS[communityId]) {
        state.FIELD_COMMUNITY_ALBUMS[communityId] = {};
      }
      if (!state.FIELD_COMMUNITY_ALBUMS[communityId][albumId]) {
        state.FIELD_COMMUNITY_ALBUMS[communityId][albumId] = {
          photos: {},
        };
      }
      for (const pid of photoIds) {
        delete state.FIELD_COMMUNITY_ALBUMS[communityId][albumId].photos![pid];
      }
    },

    ACTION_STORE_ACTIVE_COMMUNITY_ALBUM: (state, action: PayloadAction<string>) => {
      const albumId = action.payload;
      state.FIELD_ACTIVE_COMMUNITY_ALBUM = albumId;
    },

    ACTION_STORE_COMMUNITY_ALBUM: (state, action: PayloadAction<{ communityId: string, albumId: string, album: DeepReadonly<CommunityPhotoAlbum> | null }>) => {
      const {communityId, albumId, album} = action.payload;
      if (!album) {
        delete state.FIELD_COMMUNITY_ALBUMS[communityId]?.[albumId];
        return;
      }
      if (!state.FIELD_COMMUNITY_ALBUMS[communityId]) {
        state.FIELD_COMMUNITY_ALBUMS[communityId] = {};
      }
      state.FIELD_COMMUNITY_ALBUMS[communityId][albumId] = {...album};
    },

    ACTION_STORE_COMMUNITY_PHOTO: (state, action: PayloadAction<{
      communityId: string, localUri?: string, photoId: string, photo: DeepReadonly<SharedPhoto>
    }>) => {
      const {communityId, photoId, photo} = action.payload;
      maybeIncreaseTimestamp(communityId, photo, state.FIELD_MOST_RECENT_COMMUNITY_PHOTO_TIMESTAMP);
      if (!state.FIELD_COMMUNITY_PHOTOS[communityId]) {
        state.FIELD_COMMUNITY_PHOTOS[communityId] = {};
      }
      state.FIELD_COMMUNITY_PHOTOS[communityId][photoId] = photo;
      },

    ACTION_STORE_PRIVATE_CHAT_PHOTO: (state, action: PayloadAction<{
      localUri?: string, threadId: string, photoId: string, photo: DeepReadonly<SharedPhoto>
    }>) => {
      const {threadId, photoId, photo} = action.payload;
      maybeIncreaseTimestamp(threadId, photo, state.FIELD_MOST_RECENT_PRIVATE_CHAT_PHOTO_TIMESTAMP);
      if (!state.FIELD_PRIVATE_CHAT_PHOTOS[threadId]) {
        state.FIELD_PRIVATE_CHAT_PHOTOS[threadId] = {};
      }
      state.FIELD_PRIVATE_CHAT_PHOTOS[threadId][photoId] = photo;
    },
  },

  extraReducers: builder => builder
      .addCase(AppColdStart, (state) => {
        state.FIELD_ACTIVE_COMMUNITY_ALBUM = '';
      })
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState)
      .addCase(UserLeftCommunity, (state, action: PayloadAction<string>) => {
        const communityId = action.payload;
        delete state.FIELD_COMMUNITY_ALBUMS[communityId];
        delete state.FIELD_COMMUNITY_PHOTOS[communityId];
        delete state.FIELD_MOST_RECENT_COMMUNITY_PHOTO_TIMESTAMP[communityId];
      }),
});

const {actions, reducer: PhotosReducer} = slice;

const {
  ACTION_ADD_ALBUM_PHOTOS,
  ACTION_REMOVE_ALBUM_PHOTOS,
  ACTION_STORE_COMMUNITY_ALBUM,
  ACTION_STORE_COMMUNITY_PHOTO,
  ACTION_STORE_PRIVATE_CHAT_PHOTO,
} = actions;

export {
  ACTION_ADD_ALBUM_PHOTOS,
  ACTION_REMOVE_ALBUM_PHOTOS,
  ACTION_STORE_COMMUNITY_ALBUM,
  ACTION_STORE_COMMUNITY_PHOTO,
  ACTION_STORE_PRIVATE_CHAT_PHOTO,
  PhotosReducer,
};
