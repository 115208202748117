import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Keyboard, StyleSheet, Text, TextInput, View} from 'react-native';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {HeyloTextInput} from '../../ui/input/HeyloTextInput';
import {shallowEqual, useSelector} from 'react-redux';
import {
  selectActiveUserAboutMe,
  selectActiveUserFullName,
  selectActiveUserInterests,
  selectActiveUserProfilePhotoUri,
} from '@heylo/shared/src/features/userSettings/Selectors';
import {IsValidFullName} from '@heylo/shared/src/util/strings';
import {
  CommunityProfile,
  UserSettings,
} from '@heylo/shared/src/types/firebase-types';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {UserSettingsFirebase} from '@heylo/shared/src/features/userSettings/Firebase';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {HelperText} from 'react-native-paper';
import {selectActiveCommunityProfile} from '@heylo/shared/src/features/communityProfiles/Selectors';
import {EditableImage} from '../../ui/image/EditableImage';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

export enum EditUserProfileState {
  EMPTY,
  POPULATED,
  SUBMIT_START,
  SUBMIT_SUCCESS,
  SUBMIT_FAILURE,
}

enum ErrorState {
  NONE,
  INVALID_NAME,
  SERVER_ERROR,
}

type Props = {
  communityProfile?: CommunityProfile,
  onProfileSubmit: () => void,
  setState: Dispatch<SetStateAction<EditUserProfileState>>,
  state: EditUserProfileState,
  userBio: string,
  userFullName: string,
  userGoals: string,
  userId: string,
  userProfilePhotoUri: string,
};

export const PureEditUserProfile = (props: Props) => {
  const {
    communityProfile = {},
    onProfileSubmit,
    setState,
    state,
    userBio,
    userFullName,
    userGoals,
    userId,
    userProfilePhotoUri,
  } = props;
  const {communityId, communityName} = communityProfile;

  const logger = useLoggingService();
  const text = useTextStyles();

  const [errorState, setErrorState] = useState(ErrorState.NONE);

  const [currentBio, setBio] = useState<string>(userBio);
  useEffect(() => {
    setBio(userBio);
  }, [userBio]);

  const [currentFullName, setFullName] = useState<string>(userFullName);
  useEffect(() => {
    setFullName(userFullName);
  }, [userFullName]);

  const [currentGoals, setGoals] = useState<string>(userGoals);
  useEffect(() => {
    setGoals(userGoals);
  }, [userGoals]);

  useEffect(() => {
    // const populated = currentBio !== userBio ||
    //     currentFullName !== userFullName ||
    //     currentGoals !== userGoals;
    const populated = Boolean(currentBio) || Boolean(currentFullName) || Boolean(currentGoals);
    setState(populated ? EditUserProfileState.POPULATED : EditUserProfileState.EMPTY);
  }, [
    currentBio,
    currentFullName,
    currentGoals,
    // userBio,
    // userFullName,
    // userGoals,
  ]);

  useEffect(() => {
    switch (state) {
      case EditUserProfileState.SUBMIT_START:
        onSubmit();
        break;
    }
  }, [state]);

  const onSubmit = async () => {
    const bio = currentBio.trim() || '';
    const fullName = currentFullName.trim() || '';
    const goals = currentGoals.trim() || '';
    setBio(bio);
    setFullName(fullName);
    setGoals(goals);
    setErrorState(ErrorState.NONE);
    Keyboard.dismiss();
    try {
      if (!fullName || !IsValidFullName(fullName)) {
        setState(EditUserProfileState.SUBMIT_FAILURE);
        setErrorState(ErrorState.INVALID_NAME);
        return;
      }
      const settings: UserSettings = {
        bio,
        fullName,
        goals,
      };

      logger?.logEvent(AnalyticsEvent.PROFILE_UPDATE);
      await UserSettingsFirebase.UpdateUserSettings(userId, settings);

      setState(EditUserProfileState.SUBMIT_SUCCESS);
      onProfileSubmit();
    } catch (error) {
      console.warn('error updating user', error);
      setState(EditUserProfileState.SUBMIT_FAILURE);
      setErrorState(ErrorState.SERVER_ERROR)
    }
  }

  const onProfilePhotoChange = async (downloadUri: Promise<string>) => {
    const uri = await downloadUri;
    await UserSettingsFirebase.UpdateUserSettings(userId, {avatar: uri});
  }

  const aboutMeRef = useRef<TextInput>(null);

  return (
      <>
        <View style={styles.container}>
          <View style={{width: '60%'}}>
            <EditableImage
                aspectRatio={1}
                cropShape={'circle'}
                callToAction={(userProfilePhotoUri ? 'Edit' : 'Add') + ' profile photo'}
                imageUri={userProfilePhotoUri}
                onChange={onProfilePhotoChange}
                storagePath={'avatars'}
            />
          </View>

          <View style={{marginBottom: 10, marginTop: 20}}>
            <Text style={text.body1}>
              Help {communityName ? <Text
                style={text.bold}>{communityName}</Text> : 'others'} get to know you! Only groups that you have joined, or requested to join, can see your profile.
            </Text>
          </View>

          {errorState === ErrorState.SERVER_ERROR && (
              <View style={{marginBottom: 20}}>
                <Text style={[text.body1, text.error]}>
                  We were unable to update your settings. Please try again or contact Heylo Support
                </Text>
              </View>
          )}
          <View style={styles.infoContainer}>
            <View style={styles.textInputContainer}>
              <HeyloTextInput
                  autoCapitalize={'words'}
                  autoCompleteType={'name'}
                  autoCorrect={false}
                  dense
                  error={errorState === ErrorState.INVALID_NAME}
                  label={'Full name'}
                  onChangeText={setFullName}
                  onSubmitEditing={() => aboutMeRef.current!.focus()}
                  returnKeyType='done'
                  textContentType={'name'}
                  underlineColorAndroid='transparent'
                  value={currentFullName}
              />
              {errorState === ErrorState.INVALID_NAME && (
                  <HelperText style={text.body1} type={'error'}>
                    Name is required. Also make sure it doesn't have any symbols or numbers.
                  </HelperText>
              )}
            </View>

            <View style={styles.textInputContainer}>
              <HeyloTextInput
                  autoCapitalize={'sentences'}
                  autoCorrect={true}
                  dense
                  label={'About me'}
                  multiline={true}
                  onChangeText={setBio}
                  placeholder='E.g., background, neighborhood, occupation'
                  ref={aboutMeRef}
                  scrollEnabled={false}
                  value={currentBio}
              />
            </View>

            <View style={styles.textInputContainer}>
              <HeyloTextInput
                  autoCapitalize={'sentences'}
                  autoCorrect={true}
                  dense
                  label={'Interests'}
                  multiline={true}
                  onChangeText={setGoals}
                  // onSubmitEditing={onSubmit}
                  placeholder='E.g., yoga, photography, racing, etc.'
                  // ref={interestsRef}
                  scrollEnabled={false}
                  underlineColorAndroid='transparent'
                  value={currentGoals}
              />
            </View>
          </View>
        </View>
      </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    marginTop: 20,
    marginHorizontal: 20,
    marginBottom: 50,
  },
  infoContainer: {
    marginVertical: 10,
    width: '100%',
  },
  inputTitleText: {
    marginBottom: 5,
    marginTop: 20,
  },
  textInputContainer: {
    marginTop: StyleConstants.SPACING,
  },
});


export const EditUserProfile = (
    {
      onProfileSubmit,
      setState,
      state,
    }: {
      onProfileSubmit: () => void,
      setState: Dispatch<SetStateAction<EditUserProfileState>>,
      state: EditUserProfileState,
    }) => {
  const {
    aboutMe,
    communityProfile = {},
    fullName,
    interests,
    profilePhotoUri,
    userId,
  } = useSelector((state: RootState) => ({
    aboutMe: selectActiveUserAboutMe(state),
    communityProfile: selectActiveCommunityProfile(state),
    fullName: selectActiveUserFullName(state),
    interests: selectActiveUserInterests(state),
    profilePhotoUri: selectActiveUserProfilePhotoUri(state),
    userId: selectActiveUserId(state),
  }), shallowEqual);

  return (
      <PureEditUserProfile
          communityProfile={communityProfile}
          onProfileSubmit={onProfileSubmit}
          setState={setState}
          state={state}
          userBio={aboutMe}
          userFullName={fullName}
          userGoals={interests}
          userId={userId}
          userProfilePhotoUri={profilePhotoUri}
      />
  );
};