import {isProductionEnvironment} from 'lib/util';
import {GetHeyloConfig} from '@heylo/shared/src/config/App';
import {
  ResolveBuildVariant,
  ResolveHostForBuildVariant,
} from '@heylo/shared/src/util/BuildVariant';

export type SocialParams = {
  description?: string,
  imageUrl?: string,
  title?: string,
}

export enum Screen {
  INVITE
}

const getPathForScreen = (screen: Screen) => {
  switch (screen) {
    case Screen.INVITE:
    default:
      return '/invite';
  }
};

const generateLink = (path: string, social?: SocialParams): string => {
  const host = ResolveHostForBuildVariant(ResolveBuildVariant(process.env.REACT_APP_BUILD_VARIANT));
  const deepLink = encodeURIComponent(`${host}${path}`);
  const androidPackageName = 'com.piccup';
  const androidMinVersion = 1574368644;
  const iosBundleId = 'com.thepiccup.Piccup';
  const iosAppStoreId = '1445966180';
  const iosMinVersion = 1574368644;

  let {description, imageUrl, title} = social || {};
  description = description ?? `Meet your fellow members, see upcoming events, and stay connected!`;
  imageUrl = imageUrl ?? 'https://firebasestorage.googleapis.com/v0/b/piccup-82257.appspot.com/o/assets%2Fheylo-large.png?alt=media&token=97ca6e5c-db8f-4065-9439-7fb0342107bf';
  title = title ?? 'Join your group on Heylo!';

  const socialDescription = encodeURIComponent(description);
  const socialImage = encodeURIComponent(imageUrl);
  const socialTitle = encodeURIComponent(title);

  const subdomain = isProductionEnvironment() ? 'link' : 'dev.link';
  return `https://${subdomain}.heylo.co/?link=${deepLink}&apn=${androidPackageName}&amv=${androidMinVersion}&ibi=${iosBundleId}&isi=${iosAppStoreId}&imv=${iosMinVersion}&st=${socialTitle}&sd=${socialDescription}&si=${socialImage}`;
}

export const GenerateDynamicLinkForPath = (path: string, social?: SocialParams): string => {
  return generateLink(path, social);
}

export const GenerateDynamicLinkForScreen = (screen: Screen, social?: SocialParams): string => {
  const path = getPathForScreen(screen);
  return generateLink(path, social);
};