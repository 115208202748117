import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DeepReadonly} from 'utility-types';
import {AppReset} from '@heylo/shared/src/features/app/Actions';
import {UserLeftCommunity} from '@heylo/shared/src/features/communities/Slice';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';

export type ReactionsState = {
  FIELD_COMMUNITY_PHOTO_REACTIONS: DeepReadonly<{ [communityId: string]: { [photoId: string]: { [userId: string]: number } } }>,
  FIELD_MESSAGE_TO_REACTIONS: DeepReadonly<{ [messageId: string]: { [userId: string]: number } }>,

  // Map of threadId => most recent reactionId. Primarily used for optimizing
  // reaction listeners for each thread.
  FIELD_THREAD_TO_LAST_REACTION_ID: DeepReadonly<{ [threadId: string]: string }>,
}

const initialState: ReactionsState = {
  FIELD_COMMUNITY_PHOTO_REACTIONS: {},
  FIELD_MESSAGE_TO_REACTIONS: {},
  FIELD_THREAD_TO_LAST_REACTION_ID: {},
};

export const slice = createSlice({
  name: 'reactions',
  initialState,
  reducers: {

    ACTION_ADD_REACTION: (state, action: PayloadAction<{
      messageId: string,
      reactionId: string,
      reactionType: number,
      threadId: string,
      userId: string,
    }>) => {
      const {
        messageId,
        reactionId,
        reactionType,
        threadId,
        userId,
      } = action.payload;
      const latestReactionId = state.FIELD_THREAD_TO_LAST_REACTION_ID[threadId];
      if (reactionId <= latestReactionId) {
        return;
      }
      state.FIELD_THREAD_TO_LAST_REACTION_ID[threadId] = reactionId;
      if (!state.FIELD_MESSAGE_TO_REACTIONS) {
        state.FIELD_MESSAGE_TO_REACTIONS = {};
      }
      if (!state.FIELD_MESSAGE_TO_REACTIONS[messageId]) {
        state.FIELD_MESSAGE_TO_REACTIONS[messageId] = {};
      }
      state.FIELD_MESSAGE_TO_REACTIONS[messageId][userId] = reactionType;
    },

    ACTION_REMOVE_REACTION: (state, action: PayloadAction<{
      messageId: string,
      reactionId: string,
      threadId: string,
      userId: string,
    }>) => {
      const {messageId, reactionId, threadId, userId} = action.payload;
      state.FIELD_THREAD_TO_LAST_REACTION_ID[threadId] = reactionId;
      delete state.FIELD_MESSAGE_TO_REACTIONS?.[messageId]?.[userId];
    },

    ACTION_STORE_COMMUNITY_PHOTO_REACTIONS: (state, action: PayloadAction<{
      communityId: string,
      photoId: string,
      reactions: { [userId: string]: number }
    }>) => {
      const {communityId, photoId, reactions} = action.payload;
      if (!state.FIELD_COMMUNITY_PHOTO_REACTIONS) {
        state.FIELD_COMMUNITY_PHOTO_REACTIONS = {};
      }
      if (!state.FIELD_COMMUNITY_PHOTO_REACTIONS[communityId]) {
        state.FIELD_COMMUNITY_PHOTO_REACTIONS[communityId] = {};
      }
      state.FIELD_COMMUNITY_PHOTO_REACTIONS[communityId][photoId] = reactions;
    },
  },

  extraReducers: builder => builder
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState)
      .addCase(UserLeftCommunity, (state, action: PayloadAction<string>) => {
        const communityId = action.payload;
        delete state.FIELD_COMMUNITY_PHOTO_REACTIONS?.[communityId];
      }),
});

const {actions, reducer: ReactionsReducer} = slice;

const {
  ACTION_ADD_REACTION,
  ACTION_REMOVE_REACTION,
  ACTION_STORE_COMMUNITY_PHOTO_REACTIONS,
} = actions;

export {
  ACTION_ADD_REACTION,
  ACTION_REMOVE_REACTION,
  ACTION_STORE_COMMUNITY_PHOTO_REACTIONS,
  ReactionsReducer,
};