import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {EditableImage} from '../../ui/image/EditableImage';
import {HeyloTextInput} from '../../ui/input/HeyloTextInput';
import {StyleSheet, Text, View} from 'react-native';
import {DraftCommunityUpdated} from '@heylo/shared/src/features/communityCreation/Slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectDraftCommunityProfile} from '@heylo/shared/src/features/communityCreation/Selectors';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';

export enum NewCommunityDetailsFormState {
  IDLE,
  VALID,
  SUBMITTING,
  SUCCESS,
}

export const NewCommunityDetailsForm = ({state, setState}: {
  state: NewCommunityDetailsFormState,
  setState: Dispatch<SetStateAction<NewCommunityDetailsFormState>>,
}) => {
  const dispatch = useDispatch();
  const text = useTextStyles();

  const {
    communityProfile,
  } = useSelector((state: RootState) => ({
    communityProfile: selectDraftCommunityProfile(state),
  }), shallowEqual)

  const {
    heroImageUrl: initialHeroImageUri = '',
    description: initialAboutUs = '',
  } = communityProfile;

  const logger = useLoggingService();
  const [aboutUs, setAboutUs] = useState(initialAboutUs);
  const [heroImageUri, setHeroImageUri] = useState(initialHeroImageUri);
  const isValid = () => !!aboutUs || !!heroImageUri;

  useEffect(() => {
    switch (state) {
      case NewCommunityDetailsFormState.IDLE:
        if (isValid()) {
          setState(NewCommunityDetailsFormState.VALID);
        }
        break;
      case NewCommunityDetailsFormState.VALID:
        if (!isValid()) {
          setState(NewCommunityDetailsFormState.IDLE);
        }
        break;
      case NewCommunityDetailsFormState.SUBMITTING:
        dispatch(DraftCommunityUpdated({
          description: aboutUs,
          heroImageUrl: heroImageUri,
        }));
        logger.logEvent(AnalyticsEvent.NEW_GROUP_DETAILS_SUBMIT, {
          hasDescription: Boolean(aboutUs),
          hasPhoto: Boolean(heroImageUri),
        });
        setState(NewCommunityDetailsFormState.SUCCESS);
        break;
    }
  }, [state, aboutUs, heroImageUri]);

  const onImageChange = async (downloadUri: Promise<string>) => {
    const uri = await downloadUri;
    setHeroImageUri(uri);
    dispatch(DraftCommunityUpdated({heroImageUrl: uri}));
  };

  return (
      <>
        <View style={[styles.containerInput, {marginTop: 10}]}>
          <Text style={text.body1}>
            Help prospective members learn more about your group.
          </Text>
        </View>

        <View style={{marginTop: 20, width: '100%'}}>
          <EditableImage
              aspectRatio={1}
              callToAction={(heroImageUri ? 'Edit' : 'Add') + ' group photo'}
              cropShape={'square'}
              imageUri={heroImageUri}
              onChange={onImageChange}
              storagePath={'communities/heroImages'}
          />
        </View>

        <View style={styles.containerInput}>
          <Text style={[text.body1, text.bold]}>About us</Text>
          <Text style={text.body2}>What makes your group unique?</Text>
          <HeyloTextInput
              dense
              multiline
              placeholder={'Share your mission, goals or what your group is all about'}
              onChangeText={setAboutUs}
              value={aboutUs}
          />
        </View>
      </>
  )
};

const styles = StyleSheet.create({
  containerInput: {paddingTop: 20},
});