import React from 'react';
import {View} from 'react-native';
import {ActivityIndicator, IconButton} from 'react-native-paper';
import {connect, ConnectedProps} from 'react-redux';
import {SelectActiveCommunityIsSupportCommunity} from '@heylo/shared/src/features/communities/Selectors';
import {SelectIsUserACommunityAdmin} from '@heylo/shared/src/features/communityMembers/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {RootState} from '@heylo/shared/src/services/redux/Redux';


const mapStateToProps = (state: RootState) => {
  const userId = selectActiveUserId(state) || '';
  return {
    isAdmin: SelectIsUserACommunityAdmin(state, userId),
    isSupportCommunity: SelectActiveCommunityIsSupportCommunity(state),
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & {
  adminsOnly?: boolean,
  backgroundColor?: string,
  color?: string,
  iconName: string,
  onPress?: () => any,
  showActivityIndicator?: boolean,
  size?: number,
};

export const HeaderIcon = connector((props: Props) => {
  const {
    adminsOnly,
    backgroundColor,
    color,
    iconName,
    isAdmin,
    isSupportCommunity,
    onPress,
    showActivityIndicator,
    size,
  } = props;

  const palette = usePalette();

  if (isSupportCommunity
      || (adminsOnly && !isAdmin)) {
    return <View/>;
  }
  return (
      <>
        {showActivityIndicator ? (
            <View style={{marginRight: StyleConstants.SPACING}}>
              <ActivityIndicator
                  color={color || palette.primary.main}
                  size={30}
              />
            </View>
        ) : (
            <IconButton
                color={color || 'black'}
                icon={iconName}
                onPress={onPress}
                size={size || StyleConstants.SPACING * 2}
                style={{backgroundColor}}
            />
        )}
      </>
  );
});
