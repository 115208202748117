import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppReset} from '../app/Actions';
import {SigningOutAction} from '../auth/Slice';
import {CommunityCreated} from '../communities/Slice';
import {CommunityProfile} from '../../types/firebase-types';

type CommunityCreationState = {
  communityProfile: CommunityProfile,
}

const initialState: CommunityCreationState = {
  communityProfile: {},
};

export const slice = createSlice({
  name: 'communityCreation',
  initialState,
  reducers: {
    DraftCommunityUpdated: (state, action: PayloadAction<CommunityProfile>) => {
      state.communityProfile = {
        ...state.communityProfile,
        ...action.payload,
      };
    },
    DraftCommunityReset: (state) => {
      state.communityProfile = {};
    },
  },

  extraReducers: builder => builder
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState),
});

const {actions, reducer: CommunityCreationReducer} = slice;

const {
  DraftCommunityUpdated,
  DraftCommunityReset,
} = actions;

export {
  DraftCommunityUpdated,
  DraftCommunityReset,
  CommunityCreationReducer,
};
