import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {selectActiveEventId} from '@heylo/shared/src/features/events/Selectors';
import {shallowEqual, useSelector} from 'react-redux';
import {ActiveThreadIdSelector} from '@heylo/shared/src/features/threads/SimpleSelectors';

export const useNavigationUtils = () => {
  const {
    communityId,
    eventId,
    threadId,
  } = useSelector((state: RootState) => ({
    communityId: SelectActiveCommunityId(state),
    eventId: selectActiveEventId(state),
    threadId: ActiveThreadIdSelector(state),
  }), shallowEqual);

  const resolveUriForAddEvent = () => {
    return `/event/add?communityId=${communityId}`;
  }

  const resolveUriForDuplicateEvent = () => {
    return `/event/duplicate?communityId=${communityId}&eventId=${eventId}`;
  }

  const resolveUriForEditEvent = () => {
    return `/event/edit?communityId=${communityId}&eventId=${eventId}`;
  }

  const resolveUriForEventDetails = (eventIdOverride?: string) => {
    return `/event?communityId=${communityId}&eventId=${eventIdOverride || eventId}`;
  }

  const resolveUriForEventSummary = (communityIdOverride?: string) => {
    return `/events?communityId=${communityIdOverride || communityId}`;
  }

  const resolveUriForGroupPage = () => {
    return `/community?communityId=${communityId}`;
  }

  const resolveUriForEditGroupPage = () => {
    return `/community/edit?communityId=${communityId}`;
  }

  const resolveUriForTopicsSummary = (communityIdOverride?: string) => {
    return `/topics/${communityIdOverride || communityId}`;
  }

  const resolveUriForTopic = (threadIdOverride?: string) => {
    return `/topics/${communityId}/${threadIdOverride || threadId}`;
  }

  return {
    resolveUriForAddEvent,
    resolveUriForDuplicateEvent,
    resolveUriForEditEvent,
    resolveUriForEventDetails,
    resolveUriForEventSummary,
    resolveUriForGroupPage,
    resolveUriForEditGroupPage,
    resolveUriForTopic,
    resolveUriForTopicsSummary,
  }
}
