import QRCode from 'qrcode.react';
import React, {useEffect, useState} from 'react';
import {ShortenLink} from '../../lib/link/ShortenLink';

type Props = {
  link: string,
}

export const QrCode = (props: Props) => {
  const {
    link,
  } = props;

  const [shortenedLink, setShortenedLink] = useState('');

  useEffect(() => {
    ShortenLink(link)
        .then(shortenedLink => {
          setShortenedLink(shortenedLink || link);
        });
  }, [link]);

  if (!shortenedLink) {
    return null;
  }
  return (
      <div style={{
        backgroundColor: 'white',
        border: 'solid #000 2px',
        borderRadius: '1rem',
        height: '14rem',
        padding: '2rem',
      }}>
        <QRCode
            size={140}
            value={shortenedLink}
        />
      </div>
  );
};