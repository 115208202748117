import React, {createRef, useContext, useEffect, useState} from 'react';
import {
  CommunityProfile,
  Marketplace,
} from '@heylo/shared/src/types/firebase-types';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import isEmail from 'validator/lib/isEmail';
import {EmailTextInput} from '@heylo/components/src/features/auth/EmailTextInput';
import {Keyboard, Text, TextInput, View} from 'react-native';
import {EmailVerificationContext} from '@heylo/shared/src/features/auth/Slice';
import {HeyloImage} from '../../ui/image/HeyloImage';
import {HeyloTextInput} from '../../ui/input/HeyloTextInput';
import {ActivityIndicator, HelperText} from 'react-native-paper';
import {IsValidFullName} from '@heylo/shared/src/util/strings';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {
  EmailVerificationStartContext,
  EmailVerificationStartState,
} from './EmailVerificationStartContext';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {HeyloHyperlink} from '../../ui/link/Hyperlink';

type Props = {
  activeUserId?: string,
  communityProfile?: CommunityProfile,
  context: EmailVerificationContext,
  marketplace?: Marketplace,
  onSendEmailLink: (email: string, fullName: string) => Promise<void>,
  onSubmitPress: () => void,
};

export const PureEmailVerification = React.memo((props: Props) => {
  const {
    activeUserId,
    communityProfile = {},
    context,
    marketplace = {},
    onSendEmailLink,
    onSubmitPress,
  } = props;

  const palette = usePalette();
  const text = useTextStyles();

  const {
    category = '',
    emailVerificationDomain = '',
    logoUri: marketplaceLogoUri,
    nameAbbrev: marketAbbrevName,
    nameFull: marketFullName,
  } = marketplace;

  const {communityName, communityLogo} = communityProfile;

  const bannerUri = marketplaceLogoUri || communityLogo || '';
  const marketName = marketAbbrevName || marketFullName || '';

  const [state, setState] = useContext(EmailVerificationStartContext);
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');

  const getNormalizedFullName = () => {
    return fullName.trim();
  };

  const getNormalizedEmail = () => {
    const trimmedEmail = email.trim();
    return emailVerificationDomain
        ? [trimmedEmail, emailVerificationDomain].join('@')
        : trimmedEmail;
  };

  const areInputsValid = () => {
    const name = getNormalizedFullName();
    if (!activeUserId && !IsValidFullName(name)) {
      setState(EmailVerificationStartState.ERROR_NAME);
      return false;
    }
    const finalEmail = getNormalizedEmail();
    if (!isEmail(finalEmail)) {
      setState(EmailVerificationStartState.ERROR_EMAIL);
      return false;
    }
    return true;
  };


  const sendEmailLink = () => {
    Keyboard.dismiss();
    onSendEmailLink(getNormalizedEmail(), getNormalizedFullName())
        .catch(e => {
          console.warn('email link error', email, e);
          setState(EmailVerificationStartState.ERROR_SEND);
        });
  };

  useEffect(() => {
    switch (state) {
      case EmailVerificationStartState.SUBMIT_PRESSED:
        const valid = areInputsValid();
        if (valid) {
          onSubmitPress();
        }
        break;
      case EmailVerificationStartState.SENDING_EMAIL_LINK:
        sendEmailLink();
        break;
    }
  }, [state]);

  const demonym = category === 'school' ? 'student' : 'member';

  const containerText = {
    ...text.body1,
    marginBottom: 20,
    width: '100%',
  };

  const justificationText = (): React.ReactNode => {
    if (context === EmailVerificationContext.CREATE_COMMUNITY) {
      if (emailVerificationDomain) {
        return (
            <Text style={containerText}>
              To list your group for <Text
                style={text.bold}>{marketName}</Text> {demonym}s, please confirm you have a <Text
                style={text.bold}>@{emailVerificationDomain}</Text> email address.
            </Text>
        );
      } else {
        return (
            <Text style={containerText}>
              Before others join your group, please create your account.
            </Text>
        );
      }
    } else if (context === EmailVerificationContext.DISCOVER_COMMUNITY) {
      if (emailVerificationDomain) {
        return (
            <Text style={containerText}>
              <Text style={text.bold}>{communityName}</Text> is a part of <Text
                style={text.bold}>{marketName}</Text>.
              To view {marketName} groups, please enter your <Text
                style={text.bold}>@{emailVerificationDomain}</Text> email below.
            </Text>
        );
      } else {
        return (
            <Text style={containerText}>
              Welcome! To get started with <Text
                style={text.bold}>{communityName}</Text>, let's create your account.
            </Text>
        );
      }
    } else if (context === EmailVerificationContext.DISCOVER_MARKETPLACE) {
      if (emailVerificationDomain) {
        return (
            <Text style={containerText}>
              To view <Text
                style={text.bold}>{marketName}</Text> groups, please enter your <Text
                style={text.bold}>@{emailVerificationDomain}</Text> email below.
            </Text>
        );
      } else {
        return (
            <Text style={containerText}>
              Welcome to Heylo! To view <Text
                style={text.bold}>{marketName}</Text> groups, let's first create your account.
            </Text>
        );
      }
    }
    return (
        <Text style={containerText}>
          Enter your email address below to get started.
        </Text>
    );
  };


  const emailRef = createRef<TextInput>();
  const TOS_URL = 'https://www.heylo.co/terms';
  const PRIVACY_POLICY_URL = 'https://www.heylo.co/privacy';

  const renderEmailTextInput = () => {
    const domainVerificationRequired = !!emailVerificationDomain;
    const props = {
      error: state === EmailVerificationStartState.ERROR_EMAIL,
      onChangeText: setEmail,
      onSubmitEditing: () => setState(EmailVerificationStartState.SUBMIT_PRESSED),
      placeholder: domainVerificationRequired
          ? 'handle'
          : 'you@email.com',
      ref: emailRef,
      value: email,
      style: domainVerificationRequired ? {minWidth: 150} : {
        flex: 1,
        width: '100%',
      },
    };
    if (domainVerificationRequired) {
      return <HeyloTextInput dense label={'Email'} {...props}     />;
    }
    return <EmailTextInput {...props}/>;
  }

  if (state === EmailVerificationStartState.SENDING_EMAIL_LINK) {
    return (
        <View style={{alignItems: 'center', flexDirection: 'column'}}>
          <View style={{marginVertical: StyleConstants.SPACING}}>
            <ActivityIndicator size={'large'}/>
          </View>
          <Text style={text.body1}>Sending email to{'\n'}
            <Text style={text.bold}>{getNormalizedEmail()}</Text>
          </Text>
        </View>
    );
  }
  return (
      <View style={{alignItems: 'center'}}>
        {state === EmailVerificationStartState.ERROR_SEND && (
            <Text style={[text.body1, {color: palette.error.medium}]}>
              Something went wrong. We were not able to send you a verification email. Please try again or contact support@heylo.co
            </Text>
        )}

        {!!bannerUri && (
            <View style={{
              height: 100,
              marginBottom: StyleConstants.SPACING * 2,
              marginTop: StyleConstants.SPACING,
              width: '100%',
            }}>
              <HeyloImage
                  resizeMode={'contain'}
                  uri={bannerUri}
              />
            </View>
        )}

        <View style={{
          maxWidth: 300,
          width: '100%',
        }}>
          <View>
            {justificationText()}
          </View>

          {!activeUserId && (
              <View>
                <HeyloTextInput
                    dense
                    error={state === EmailVerificationStartState.ERROR_NAME}
                    label={'Your name'}
                    onChangeText={setFullName}
                    onSubmitEditing={() => emailRef.current!.focus()}
                    value={fullName}
                />
                {state === EmailVerificationStartState.ERROR_NAME && (
                    <HelperText type={'error'}>
                      Name must not be empty or contain numbers or symbols.
                    </HelperText>
                )}
              </View>
          )}

          <View style={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 10,
            width: '100%',
          }}>
            {renderEmailTextInput()}
            {!!emailVerificationDomain && (
                <Text style={[text.body1, text.bold, {
                  marginLeft: 5,
                  top: 3,
                }]}>
                  @{emailVerificationDomain}
                </Text>
            )}
          </View>
          {state === EmailVerificationStartState.ERROR_EMAIL && (
              <HelperText type={'error'}>
                Double-check your email for correctness.
              </HelperText>
          )}

          <HeyloHyperlink
              linkText={(value) => {
                switch (value) {
                  case TOS_URL:
                    return 'Terms of Service';
                  case PRIVACY_POLICY_URL:
                    return 'Privacy Policy'
                }
                return value;
              }}
          >
            <Text style={[text.body1, {
              color: palette.grey.main,
              marginTop: StyleConstants.SPACING * 2,
            }]}>
              By logging in to Heylo, you agree to our {TOS_URL} and {PRIVACY_POLICY_URL}.
            </Text>
          </HeyloHyperlink>
        </View>
      </View>
  );
});
