import React from 'react';
import {SafeAreaView, View} from 'react-native';
import {TextHeader} from './TextHeader';

type Props = {
  left?: React.ReactNode,
  center?: React.ReactNode,
  right?: React.ReactNode,
  title?: string,
  transparent?: boolean,
};
export const MobileHeader = (props: Props) => {
  const {
    left = <View/>,
    center = null,
    right = <View/>,
    title = '',
    transparent,
  } = props;

  return (
      <View style={{
        position: transparent ? 'absolute' : 'relative',
        width: '100%',
      }}>
        <SafeAreaView>
          <View style={{
            alignItems: 'center',
            flexDirection: 'row',
            height: 66,
            justifyContent: 'center',
            width: '100%',
          }}>
            {!!center && (
                <>
                  {center}
                </>
            )}
            {!!title && !center && (
                <TextHeader title={title}/>
            )}
            <View style={{position: 'absolute', left: 0}}>
              {left}
            </View>
            <View style={{position: 'absolute', right: 0}}>
              {right}
            </View>
          </View>
        </SafeAreaView>
      </View>
  );
};