import {View} from 'react-native';
import React from 'react';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';

export const NewCommunityProgressBar = ({width}: { width: string }) => {
  const palette = usePalette();
  return (
      <View style={{backgroundColor: palette.grey.lightest, width: '100%'}}>
        <View style={{
          backgroundColor: palette.primary.main,
          height: 3,
          width,
        }}/>
      </View>
  );
}