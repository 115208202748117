import {createSelector} from 'reselect';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {
  StripeAccount,
  StripeExternalAccount,
} from '@heylo/shared/src/types/firebase-types';
import {selectActiveEventId} from '@heylo/shared/src/features/events/Selectors';
import {ResolveStripeAccountStatus} from '@heylo/shared/src/features/stripe/Account';
import {StripeUserHasValidPaymentMethod} from '@heylo/shared/src/features/stripe/User';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

export const selectStripeAccounts = (state: RootState) => state.stripe.accounts;
export const selectStripeAccountToCommunityMap = (state: RootState) => state.stripe.accountToCommunityMap;
export const selectStripeCommunityToAccountMap = (state: RootState) => state.stripe.communityToAccountMap;
export const selectStripeEventDonations = (state: RootState) => state.stripe.eventDonations;
export const selectStripeUser = (state: RootState) => state.stripe.user;

export const selectStripeUserHasValidPaymentMethod = createSelector(
    [selectStripeUser],
    (user) => {
      return StripeUserHasValidPaymentMethod(user);
    });

export const selectActiveCommunityStripeAccountId = createSelector(
    [selectStripeCommunityToAccountMap, SelectActiveCommunityId],
    (mapping, activeCommunityId) => {
      return mapping[activeCommunityId]
    },
)

export const selectHasDonatedToActiveEvent = createSelector(
    [selectStripeEventDonations, selectActiveEventId],
    (donations, eventId) => {
      return !!donations[eventId];
    });

export const selectLinkedStripeAccount = createSelector(
    [selectStripeAccounts, selectActiveCommunityStripeAccountId],
    (accounts, accountId): StripeAccount | null => {
      return accounts[accountId] ?? null;
    });

export const selectLinkedStripeExternalAccount = createSelector(
    [selectLinkedStripeAccount],
    (account): StripeExternalAccount | null => {
      for (const ea of Object.values(account?.externalAccounts ?? {})) {
        if (ea.isDefault) {
          return ea;
        }
      }
      return null;
    });

export const selectActiveCommunityStripeAccountStatus = createSelector(
    [selectLinkedStripeAccount],
    (account) => {
      return ResolveStripeAccountStatus(account);
    });