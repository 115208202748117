import {assign, createMachine, DoneInvokeEvent} from 'xstate';
import {
  Community,
  Event,
  StripeAccount,
  StripeUser,
} from '@heylo/shared/src/types/firebase-types';
import {
  ResolveStripeAccountStatus,
  StripeAccountStatus,
} from '@heylo/shared/src/features/stripe/Account';
import {FirebaseFunctions} from '@heylo/firebase-database';
import {StripeUserHasValidPaymentMethod} from '@heylo/shared/src/features/stripe/User';
import {GetHeyloConfig} from '@heylo/shared/src/config/App';
import {
  ResolveBuildVariant,
  ResolveHostForBuildVariant,
} from '@heylo/shared/src/util/BuildVariant';
import {LoggingService} from '@heylo/shared/src/services/logging/LoggingService';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';


export interface EventDonationContext {
  buildVariant: string,
  community?: Community,
  errorCode?: string,
  errorMessage?: string,
  errorType?: string,
  firebaseEvent?: Event,
  logger: LoggingService,
  stripeAccount?: StripeAccount | null,
  stripeAccountStatus?: StripeAccountStatus,
  stripeCheckoutUri?: string,
  userEmail?: string,
  userId?: string,
}

export type EventDonationEvent =
    | {
  type: 'LOADED',
  community: Community,
  event: Event,
  isAdmin: boolean,
  stripeAccount: StripeAccount | null,
  userEmail: string,
  userId: string,
}
    | { type: 'DIALOG_OPEN', amountInCents: number }
    | { type: 'DIALOG_CLOSE' }
    | { type: 'DONATION_STARTED', amountInCents: number, stripeUser: StripeUser }

const stripeParams = (context: EventDonationContext, event: EventDonationEvent): any | null => {
  if (event.type !== 'DONATION_STARTED') {
    return null;
  }
  const {amountInCents, stripeUser} = event;
  if (!amountInCents || amountInCents < 100 || !stripeUser) {
    return null;
  }
  const {
    buildVariant,
    community,
    firebaseEvent,
    stripeAccount,
    userEmail,
    userId,
  } = context;
  if (!community || !firebaseEvent || !stripeAccount || !userEmail || !userId) {
    return null;
  }
  const {communityId, name: communityName} = community;
  const {name: eventName, eventId} = firebaseEvent;
  const {customerId} = stripeUser;
  return {
    accountId: stripeAccount.id,
    build: buildVariant,
    communityId,
    communityName,
    customerId,
    email: userEmail,
    eventId,
    eventName,
    paymentMethodId: stripeUser?.paymentMethod?.id,
    priceAmountCents: amountInCents,
    userId,
  };
}

const createPaymentIntent = async (context: EventDonationContext, event: EventDonationEvent)
    : Promise<void> => {
  return new Promise((resolve, reject) => {
    const params = stripeParams(context, event);
    if (!params) {
      return reject({type: 'heylo-bad-request'});
    }
    return FirebaseFunctions().httpsCallable('stripeCreatePaymentIntent')(params)
        .then((response) => {
          const {error} = response.data;
          if (error) {
            return reject(error);
          }
          return resolve();
        })
        .catch((e) => {
          console.warn('createPaymentIntent error:', JSON.stringify(e));
          return Promise.reject({type: 'heylo-server-error'});
        });
  });
};

const createStripeCheckoutSession = async (context: EventDonationContext, event: EventDonationEvent)
    : Promise<string> => {
  const params = stripeParams(context, event);
  if (!params) {
    return Promise.reject();
  }
  const {buildVariant} = context;
  const heyloConfig = GetHeyloConfig(buildVariant);
  return FirebaseFunctions().httpsCallable('stripeCreateDonationSession')(params)
      .then(response => {
        const {sessionId} = response?.data ?? {};
        if (!sessionId) {
          return Promise.reject();
        }
        const host = ResolveHostForBuildVariant(ResolveBuildVariant(buildVariant));
        return Promise.resolve(`${host}/stripe-checkout.html?pubKey=${heyloConfig.STRIPE_PUBLISHABLE_KEY}&sessionId=${sessionId}`);
      });
}

const donationsAreDisabled = (context: EventDonationContext, event: EventDonationEvent)
    : boolean => {
  if (event.type !== 'LOADED') {
    return false;
  }
  if (!eventHasDonationsEnabled(context, event)) {
    return false;
  }
  const {stripeAccount} = event;
  return ResolveStripeAccountStatus(stripeAccount) !== StripeAccountStatus.PAYMENTS_ENABLED;
}

const donationsAreHidden = (context: EventDonationContext, event: EventDonationEvent)
    : boolean => {
  if (event.type !== 'LOADED') {
    return false;
  }
  const {isAdmin} = event;
  return donationsAreDisabled(context, event) && !isAdmin;
}

const eventHasDonationsEnabled = (_: EventDonationContext, event: EventDonationEvent)
    : boolean => {
  if (event.type !== 'LOADED') {
    return false;
  }
  return !!event?.event?.donationsEnabled;
}

const hasPaymentMethod = (context: EventDonationContext, event: EventDonationEvent) => {
  if (event.type !== 'DONATION_STARTED') {
    return false;
  }
  const {stripeUser} = event;
  return StripeUserHasValidPaymentMethod(stripeUser);
}

const DialogCloseTransition = {
  actions: 'logEvent',
  target: '#eventDonations.inactive.enabled',
};

const donationErrorTransition = {
  actions: assign((context: EventDonationContext, event: DoneInvokeEvent<any>) => {
    const {code, message, type} = event.data;
    return {
      errorCode: code,
      errorMessage: message,
      errorType: type,
    };
  }),
  target: '#eventDonations.donationDialogOpen.error',
}

export const EventDonationMachine = createMachine<EventDonationContext, EventDonationEvent>({
  id: 'eventDonations',
  initial: 'inactive',
  states: {
    inactive: {
      initial: 'hidden',
      states: {
        // Donation UI is hidden from the user.
        hidden: {},
        enabled: {},
        // Donations have been disabled for one reason or another
        disabled: {},
      },
      on: {
        LOADED: [
          {
            cond: donationsAreHidden,
            target: 'inactive.hidden',
          },
          {
            cond: donationsAreDisabled,
            target: 'inactive.disabled',
          },
          {
            actions: 'loadData',
            cond: eventHasDonationsEnabled,
            target: 'inactive.enabled',
          },
          {
            target: 'inactive.hidden',
          },
        ],
        DIALOG_OPEN: {
          target: 'donationDialogOpen',
        },
      },
    },
    donationDialogOpen: {
      entry: 'logEvent',
      on: {
        DIALOG_CLOSE: DialogCloseTransition,
        DONATION_STARTED: [{
          target: 'donating.withPaymentIntent',
          cond: hasPaymentMethod,
        }, {
          target: 'donating.withStripeCheckout',
        }],
      },
      initial: 'normal',
      states: {
        normal: {},
        // An error occurred while trying to process a donation.
        error: {
          entry: 'logError',
        },
      },
    },
    donating: {
      entry: 'logEvent',
      initial: 'withStripeCheckout',
      states: {
        withPaymentIntent: {
          initial: 'processing',
          states: {
            processing: {
              invoke: {
                id: 'createPaymentIntent',
                src: createPaymentIntent,
                onDone: 'success',
                onError: donationErrorTransition,
              },
            },
            success: {
              invoke: {
                id: 'showSuccessDialog',
                src: 'successDialog',
                onDone: '#eventDonations.inactive.enabled',
                onError: donationErrorTransition,
              },
            },
          },
        },
        withStripeCheckout: {
          on: {
            DIALOG_CLOSE: DialogCloseTransition,
          },
          initial: 'creatingUri',
          states: {
            creatingUri: {
              invoke: {
                id: 'createStripeCheckoutSession',
                src: createStripeCheckoutSession,
                onDone: 'goToStripe',
                onError: donationErrorTransition,
              },
            },
            goToStripe: {
              invoke: {
                id: 'goToStripe',
                src: 'openStripeCheckoutUri',
                onDone: '#eventDonations.inactive.enabled',
                onError: donationErrorTransition,
              },
            },
          },
        },
      },
    },

  },
}, {
  actions: {
    loadData: assign((ctx, event: EventDonationEvent) => {
      if (event.type !== 'LOADED') {
        return {};
      }
      const {
        community,
        stripeAccount,
        event: firebaseEvent,
        userEmail,
        userId,
      } = event;
      return {
        community,
        firebaseEvent,
        stripeAccount,
        userEmail,
        userId,
      };
    }),

    logError: (ctx, event) => {
      const {errorCode, errorMessage, errorType} = ctx;
      ctx.logger.logEvent(AnalyticsEvent.DONATION_ERROR, {
        errorCode,
        errorMessage,
        errorType,
      });
    },

    logEvent: (ctx, event: EventDonationEvent) => {
      switch (event.type) {
        case 'DIALOG_CLOSE': {
          ctx.logger.logEvent(AnalyticsEvent.DONATION_CANCEL);
          break;
        }
        case 'DIALOG_OPEN': {
          const {amountInCents} = event;
          ctx.logger.logEvent(
              AnalyticsEvent.DONATION_SUGGESTION,
              {amountInDollars: Math.round(amountInCents / 100)})
          break;
        }
        case 'DONATION_STARTED': {
          const {amountInCents} = event;
          ctx.logger.logEvent(
              AnalyticsEvent.DONATION_SUBMIT,
              {amountInDollars: Math.round(amountInCents / 100)})
          break;
        }
      }
    },
  },
});
