import React from 'react';
import {Linking, Platform, StyleProp, TextStyle} from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';

type Props = {
  children: React.ReactNode,
  linkStyle?: StyleProp<TextStyle>;
  linkText?: string | ((text: string) => string);
  onPress?: () => void,
};

export const HeyloHyperlink = (props: Props) => {
  const text = useTextStyles();
  const {
    children,
    linkStyle = text.link,
    linkText,
    onPress
  } = props;

  const localOnPress = (url: string) => {
    if (typeof onPress === 'function') {
      onPress();
    }
    if (Platform.OS === 'web') {
      window.open(url,'_blank', 'noreferrer');
    } else {
      Linking.openURL(url);
    }
  };
  return (
      <Hyperlink
          linkDefault={false}
          linkStyle={linkStyle}
          linkText={linkText}
          onPress={localOnPress}
      >
        {children}
      </Hyperlink>
  );
};