import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Text, View} from 'react-native';
import {HeyloList} from '../../ui/list/HeyloList';
import {HeyloListSubheader} from '../../ui/list/HeyloListSubheader';
import {HeyloListItem} from '../../ui/list/HeyloListItem';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {selectDraftCommunityProfile} from '@heylo/shared/src/features/communityCreation/Selectors';
import {
  selectMarketMemberships,
  selectMarketplaces,
  selectSortedMetros,
  selectSortedSchools,
} from '@heylo/shared/src/features/marketplaces/Selectors';
import {Marketplace} from '@heylo/shared/src/types/firebase-types';
import {MarketplaceListItem} from '../marketplaces/MarketplaceListItem';
import {DraftCommunityUpdated} from '@heylo/shared/src/features/communityCreation/Slice';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';

export enum NewCommunityLocationFormState {
  IDLE,
  INPUT_CUSTOM_LOCATION,
  SELECTED,
  SKIP,
  SUBMITTING,
  NAV_DETAILS,
  NAV_EMAIL_VERIFICATION,
};

export type CustomLocation = {
  description?: string,
  mainText?: string,
  secondaryText?: string,
};

export const NewCommunityLocationForm = ({state, setState}: {
  state: NewCommunityLocationFormState,
  setState: Dispatch<SetStateAction<NewCommunityLocationFormState>>
}) => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const text = useTextStyles();

  const {
    activeUserId,
    communityProfile,
    marketplaceMemberships,
    marketplaces,
    metros,
    schools,
  } = useSelector((state: RootState) => ({
    activeUserId: selectActiveUserId(state),
    communityProfile: selectDraftCommunityProfile(state),
    marketplaceMemberships: selectMarketMemberships(state),
    marketplaces: selectMarketplaces(state),
    metros: selectSortedMetros(state),
    schools: selectSortedSchools(state),
  }), shallowEqual);

  const [customLocation, setCustomLocation] = useState<CustomLocation | null>(null);
  const [marketplaceId, setMarketplaceId] = useState('');

  useEffect(() => {
    const {marketplaceId, locationName} = communityProfile;
    console.log('init', marketplaceId, locationName);
    if (locationName) {
      setCustomLocation({
        description: locationName,
      });
      setMarketplaceId('');
    } else if (marketplaceId) {
      setCustomLocation(null);
      setMarketplaceId(marketplaceId);
    }
  }, []);

  useEffect(() => {
    switch (state) {
      case NewCommunityLocationFormState.SKIP:
        dispatch(DraftCommunityUpdated({
          marketplaceId: '',
          locationName: '',
        }));
        if (activeUserId) {
          setState(NewCommunityLocationFormState.NAV_DETAILS);
        } else {
          setState(NewCommunityLocationFormState.NAV_EMAIL_VERIFICATION);
        }
        break;

      case NewCommunityLocationFormState.SUBMITTING:
        logger.logEvent(AnalyticsEvent.NEW_GROUP_LOCATION_SUBMIT, {customLocation: customLocation?.description, marketplaceId})
        const requiresEmailVerification = marketplaceId
            && !!marketplaces[marketplaceId]?.emailVerificationDomain
            && !marketplaceMemberships[marketplaceId];
        if (!!activeUserId && !requiresEmailVerification) {
          setState(NewCommunityLocationFormState.NAV_DETAILS);
          return;
        }
        setState(NewCommunityLocationFormState.NAV_EMAIL_VERIFICATION);
        break;
    }
  }, [state]);

  useEffect(() => {
    if (!!marketplaceId || !!customLocation?.description) {
      setState(NewCommunityLocationFormState.SELECTED);
    } else {
      setState(NewCommunityLocationFormState.IDLE);
    }
  }, [marketplaceId, customLocation]);


  const clearCustomLocation = () => {
    setCustomLocation(null);
    dispatch(DraftCommunityUpdated({
      locationName: '',
    }));
  };

  const onPress = (marketplace: Marketplace) => {
    const {marketplaceId = ''} = marketplace;
    // setSelectedMarketplace(prev => prev === marketplace ? null :
    // marketplace);
    setMarketplaceId(prev => prev === marketplaceId ? '' : marketplaceId);
    dispatch(DraftCommunityUpdated({
      marketplaceId,
      locationName: '',
    }));
    clearCustomLocation();
  }

  const renderMarketplace = (marketplace: Marketplace) => {
    const {marketplaceId: id} = marketplace;
    if (!id) {
      return null;
    }
    return (
        <MarketplaceListItem
            context={'create'}
            key={`market${id}`}
            marketplace={marketplace}
            onPress={() => onPress(marketplace)}
            selected={marketplaceId === id}
        />
    );
  }

  const logger = useLoggingService();
  const onCustomLocationPress = () => {
    logger.logEvent(AnalyticsEvent.NEW_GROUP_CUSTOM_LOCATION);
    setState(NewCommunityLocationFormState.INPUT_CUSTOM_LOCATION);
  };

  return (
      <>
        <View style={{marginBottom: 10, marginTop: 30, marginHorizontal: 20}}>
          <Text style={text.body1}>
            Confirm your group’s location to add new members in your area.
          </Text>
        </View>

        {!!customLocation?.description && (
            <HeyloList>
              <HeyloListSubheader>CUSTOM</HeyloListSubheader>
              <HeyloListItem
                  leftElement={
                    <View style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <Text style={{
                        fontSize: 32,
                        textAlign: 'center',
                      }}>📍</Text>
                    </View>
                  }
                  onPress={clearCustomLocation}
                  primaryText={customLocation?.mainText || customLocation?.description}
                  primaryTextStyle={{color: 'white'}}
                  secondaryText={customLocation?.secondaryText}
                  secondaryTextStyle={{color: 'white'}}
                  style={{
                    backgroundColor: palette.secondary.main,
                    paddingHorizontal: 10,
                  }}
              />
            </HeyloList>
        )}

        <HeyloList>
          <HeyloListSubheader>SCHOOLS</HeyloListSubheader>
          {schools.map(value => renderMarketplace(value))}
        </HeyloList>

        <HeyloList>
          <HeyloListSubheader>CITIES</HeyloListSubheader>
          {metros.map(value => renderMarketplace(value))}
        </HeyloList>

        <HeyloList>
          <HeyloListItem
              rightElement={
                <View style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <MaterialCommunityIcon
                      color={palette.grey.main}
                      name='plus-circle'
                      size={30}
                  />
                </View>
              }
              onPress={onCustomLocationPress}
              primaryText={'Don\'t see your school or city?'}
              secondaryText={'Click here to add a new one!'}
              style={{
                backgroundColor: palette.secondary.light,
                paddingHorizontal: 10,
              }}
          />
        </HeyloList>
      </>
  );
};