import React, {useEffect, useRef, useState} from 'react';
import {
  Animated,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {REACTIONS} from '@heylo/shared/src/types/reactions';
import {StyleVars} from '../../styles/StyleVars';

type Props = {
  containerStyle?: ViewStyle,
  currentSelectedReaction: number | null,
  darkMode?: boolean,
  onPress: (reactionType: number | null) => void,
  visible: boolean,
};

export const ReactionChooser = React.memo((props: Props) => {
  const {
    containerStyle,
    currentSelectedReaction,
    darkMode,
    onPress,
    visible,
  } = props;

  const [reactionsMenuVisible, setReactionsMenuVisible] = useState(false);
  const reactionsMenuHeight = useRef(new Animated.Value(0)).current;
  const reactionsMenuOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (visible) {
      setReactionsMenuVisible(true);
      Animated.parallel([
        Animated.timing(reactionsMenuHeight, {
          toValue: 50,
          duration: 300,
          useNativeDriver: false,
        }),
        Animated.timing(reactionsMenuOpacity, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(reactionsMenuHeight, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        }),
        Animated.timing(reactionsMenuOpacity, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        }),
      ]).start(() => {
        setReactionsMenuVisible(false);
      });
    }
  }, [visible]);

  if (!reactionsMenuVisible) {
    return null;
  }
  return (
      <View style={containerStyle || {}}>
        <Animated.View style={{
          alignItems: 'center',
          height: reactionsMenuHeight,
          opacity: reactionsMenuOpacity,
          width: '100%',
        }}>
          <View
              style={[
                styles.reactionMenuContainer,
                darkMode ? styles.containerDarkMode : styles.containerLightMode,
                {width: Object.keys(REACTIONS).length * 35 + 10},
              ]}
          >
            {Object.entries(REACTIONS).map(([reactionTypeStr, reaction]) => {
              const reactionType = parseInt(reactionTypeStr);
              const isAlreadySelected = currentSelectedReaction === reactionType;
              return (
                  <TouchableOpacity
                      key={`reaction${reactionTypeStr}`}
                      onPress={() => onPress(isAlreadySelected ? null : reactionType)}
                      style={[
                        styles.reactionMenuButton,
                        {
                          backgroundColor: isAlreadySelected
                              ? (darkMode ? StyleVars.Colors.GreyMedium : StyleVars.Colors.GreyLighter)
                              : 'transparent',
                        },
                      ]}
                  >
                    <Text style={styles.reactionMenuText}>
                      {reaction.label}
                    </Text>
                  </TouchableOpacity>
              );
            })}
          </View>
        </Animated.View>
      </View>
  );
});

const styles = StyleSheet.create({
  reactionMenuContainer: {
    alignItems: 'center',
    borderRadius: 24,
    flexDirection: 'row',
    height: 40,
    justifyContent: 'space-evenly',
    marginTop: 5,
    marginBottom: 10,
    paddingVertical: 5,
  },
  containerDarkMode: {
    backgroundColor: StyleVars.Colors.GreyDarkest,
    borderColor: StyleVars.Colors.GreyDarkest,
    borderWidth: 1,
  },
  containerLightMode: {
    backgroundColor: StyleVars.Colors.White,
    elevation: 10,
    shadowColor: 'darkgray',
    shadowOpacity: 0.8,
    shadowRadius: 5,
    shadowOffset: {width: 0, height: 5},
  },
  reactionMenuButton: {
    alignItems: 'center',
    borderRadius: 30,
    height: 35,
    justifyContent: 'center',
    width: 35,
  },
  reactionMenuText: {
    color: '#000f',  // Necessary to make sure emoji are not transparent on
                     // Android.
    fontSize: 20,
  },
});