import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = createMuiTheme({
  shape: {
    borderRadius: 10,
  },
  palette: {
    // Seafoam
    primary: {
      light: 'hsl(153, 60%, 90%)',
      main: 'hsl(153, 60%, 49%)',
      contrastText: '#fff',
    },
    // Purple
    secondary: {
      light: 'hsl(258, 76%, 78%)',
      main: 'hsl(258, 76%, 61%)',
      contrastText: '#fff',
    },
  },
  spacing: 10,
  typography: {
    h3: {
      fontSize: 30,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 25,
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    subtitle1: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    subtitle2: {
      color: 'gray',
      fontSize: 16,
      fontWeight: 'bold',
    },
  },
});

export default theme;
// export default responsiveFontSizes(theme);
