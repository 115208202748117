import React, {createContext, Dispatch, SetStateAction} from 'react';

export enum EmailVerificationStartState {
  IDLE,
  SUBMIT_PRESSED,
  SENDING_EMAIL_LINK,
  ERROR_EMAIL,
  ERROR_NAME,
  ERROR_SEND,
  SUCCESS,
}

export const EmailVerificationStartContext = createContext<[
  EmailVerificationStartState,
  Dispatch<SetStateAction<EmailVerificationStartState>>
]>([EmailVerificationStartState.IDLE, () => {
}]);
