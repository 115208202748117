import React, {useEffect, useState} from 'react';
import {ActivityIndicator, HelperText} from 'react-native-paper';
import {Text, View} from 'react-native';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {FirebaseAuth} from '@heylo/firebase-database';
import {AuthFirebase} from '@heylo/shared/src/features/auth/Firebase';

export enum State {
  IDLE,
  BUSY,
  EXPIRED,
  BAD_REQUEST,
  SUCCESS,
}

export const PureSignInLinkScreen = ({state}: { state: State }) => {
  const text = useTextStyles();
  return (
      <View style={{
        alignSelf: 'center',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        maxWidth: 400,
        margin: StyleConstants.SPACING,
      }}>

        {state === State.EXPIRED ? (
            <>
              <Text style={text.h6}>
                Sign-in link expired
              </Text>
              <Text
                  style={[text.body1, {marginVertical: StyleConstants.SPACING}]}>
                Sign-in emails are only valid for 10 minutes. If you are using a recent sign-in link, double-check that you are using the most recent one.
              </Text>
            </>
        ) : state === State.BAD_REQUEST ? (
            <>
              <Text style={text.h6}>
                Sign-in link not valid
              </Text>
              <Text
                  style={[text.body1, {marginVertical: StyleConstants.SPACING}]}>
                This sign-in link was already used or is not valid. Try starting the sign-in process over.{'\n\n'}
                If you continue to have issues, please contact support@heylo.co
              </Text>
            </>
        ) : state === State.SUCCESS ? (
            <>
              <Text style={text.h6}>
                Success!
              </Text>
              <Text
                  style={[text.body1, {marginVertical: StyleConstants.SPACING}]}>
                Switch back to Heylo; you should be signed in.
              </Text>
            </>
        ) : (
            <>
              <ActivityIndicator accessibilityStates='' size={'large'}/>
              <Text style={[text.h6, {marginVertical: StyleConstants.SPACING}]}>
                Signing you in
              </Text>
              <Text style={text.body1}>
                This will only take a moment; hang tight!
              </Text>
            </>
        )}

        {state !== State.BUSY && (
            <HelperText type={'info'}>
              You can close this window.
            </HelperText>
        )}
      </View>
  );
};

export const SignInLinkScreen = () => {
  const [state, setState] = useState(State.IDLE);

  useEffect(() => {
    const signInLink = window.location.href;
    if (!FirebaseAuth().isSignInWithEmailLink(signInLink)) {
      setState(State.BAD_REQUEST);
      return;
    }

    setState(State.BUSY);
    AuthFirebase.SignInLinkOpened(signInLink)
        .then(result => {
          if (result === 'bad-request') {
            setState(State.BAD_REQUEST);
          }
          switch (result) {
            case  'bad-request':
              setState(State.BAD_REQUEST);
              break;
            case 'expired':
              setState(State.EXPIRED);
              break;
            case 'success':
              setState(State.SUCCESS);
              break;
          }
        })
        .catch((e: Error) => {
          console.warn('bad request: ', e);
          setState(State.BAD_REQUEST);
        });
  }, []);

  return <PureSignInLinkScreen state={state}/>
};