import React from 'react';
import ReactGA from 'react-ga';
import Grid from '@material-ui/core/Grid';

type Props = {
  link?: string
}

const visitAppStore = (label: string) => {
  ReactGA.event({
    category: 'Registration',
    action: 'Visit App Store',
    label,
  });
};

export const AppStoreBadge = (props: Props) => {
  const {link} = props;
  return (
      <Grid container justify={'center'} spacing={2}>
        <Grid item>
          <a href={link ?? 'https://play.google.com/store/apps/details?id=com.piccup'}
             onClick={() => visitAppStore('playStore')}
             target={'blank'}
          >
            <img
                alt={'Get it on Google Play'}
                src={'https://firebasestorage.googleapis.com/v0/b/piccup-82257.appspot.com/o/assets%2Fgoogle-play-badge.png?alt=media&token=46c59433-df67-41de-9c54-e80b7f267f52'}
                style={{height: '5rem'}}
            />
          </a>
        </Grid>

        <Grid item>
          <a href={link ?? 'https://apps.apple.com/us/app/heylo/id1445966180'}
             onClick={() => visitAppStore('appStore')}
             target={'blank'}
          >
            <img
                alt={'Download on the App Store'}
                src={'https://firebasestorage.googleapis.com/v0/b/piccup-82257.appspot.com/o/assets%2Fapp-store-badge.svg?alt=media&token=e8c26416-536f-42b6-982c-b71190c455b0'}
                style={{height: '5rem'}}
            />
          </a>
        </Grid>
      </Grid>
  );
};