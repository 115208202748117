import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {selectActiveUserHasEventWritePermissions} from '@heylo/shared/src/features/events/Selectors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {useNavigationUtils} from '../navigation/useNavigationUtils';
import {useHistory} from 'react-router-dom';
import {HeaderIcon} from '@heylo/components/src/features/navigation/HeaderIcon';
import {HeyloButton} from '@heylo/components/src/ui/button/HeyloButton';

export const AddEventButton = () => {
  const {
    editEventPermissions,
  } = useSelector((state: RootState) => ({
    editEventPermissions: selectActiveUserHasEventWritePermissions(state),
  }), shallowEqual);

  const history = useHistory();
  const nav = useNavigationUtils();
  const handleAddEvent = () => {
    history.push(nav.resolveUriForAddEvent());
  };

  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  if (!editEventPermissions) {
    return null;
  }
  if (matchXs) {
    return <HeaderIcon iconName={'calendar-plus'} onPress={handleAddEvent}/>;
  }
  return (
      <>
        <HeyloButton
            label={'Add event'}
            onPress={handleAddEvent}
            icon={'calendar'}
        />
      </>
  );
};