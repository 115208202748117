import {CommunitySearchIndexSpec} from '@heylo/shared/src/types/firebase-types';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {FirebaseDatabase} from '@heylo/firebase-database';
import {updateSearchIndex} from './Slice';

export const SearchFirebase = {

  LoadSearchIndex: (): ThunkReturnType<void> => dispatch => {
    FirebaseDatabase().ref('/communitySearchIndex').once('value')
        .then(snapshot => {
          const searchIndex: { [communityId: string]: CommunitySearchIndexSpec } = snapshot.val() || {};
          dispatch(updateSearchIndex(searchIndex));
        })
        .catch(e => {
          console.warn('failed to load /communitySearchIndex', e.message);
        });
  },

};
