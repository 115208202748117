import {
  ChatMembers,
  ChatStateShared,
  Message,
  PrivateChatScopedDeletion,
  Thread,
  ThreadMember,
  ThreadStatePerUser,
  ThreadStateShared,
  ThreadUpdate,
  UserChatState,
} from '@heylo/shared/src/types/firebase-types';
import {
  IsPrivateThreadType,
  IsThreadTypeCommunityBased,
  THREAD_TYPE_ANNOUNCEMENT,
  THREAD_TYPE_COMMUNITY,
  THREAD_TYPE_DIRECT,
  THREAD_TYPE_GROUP,
  THREAD_TYPE_SUPPORT,
} from '@heylo/shared/src/types/ThreadTypes';
import {FirebaseCommunityScopedDeletion} from '@heylo/shared/src/features/communities/Firebase';
import {
  ACTION_ADD_MESSAGE_UNSORTED,
  ACTION_ADD_PRIVATE_CHAT_DELETION,
  ACTION_ADD_THREAD_UPDATE,
  ACTION_BULK_ADD_MESSAGES,
  ACTION_COMPACT_THREAD,
  ACTION_DELETE_MESSAGE,
  ACTION_FINISH_LOADING_MORE_MESSAGES,
  ACTION_INCREMENT_UNREAD_MESSAGE_COUNT,
  ACTION_REMOVE_THREAD,
  ACTION_SET_THREAD_ACTIVITY_TIMESTAMP,
  ACTION_SET_THREAD_USER_MESSAGE_TIMESTAMP,
  ACTION_START_LOADING_MORE_MESSAGES,
  ACTION_STORE_PRIVATE_CHAT_LOOKUPS,
  ACTION_THREAD_STATE_PER_USER_UPDATED,
  ACTION_UPDATE_THREAD,
  ACTION_UPDATE_THREAD_LAST_READ_TIMESTAMP,
  ACTION_UPDATE_THREAD_MEMBERS,
  ThreadStateLoaded,
} from '@heylo/shared/src/features/threads/Slice';
import {
  DataSnapshot,
  FirebaseDatabase,
  FirebaseServerTimestamp,
  ThenableReference,
} from '@heylo/firebase-database';
import {
  CleanUpOneThreadListeners,
  Listeners,
  ThreadListeners,
} from '@heylo/shared/src/features/firebase/Listeners';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {
  ActiveThreadIdSelector,
  AllThreadUpdatesSelector,
  MostRecentPrivateChatScopedDeletionTimestamp,
  selectThreadLatestLoadedMessageTimestamp,
} from '@heylo/shared/src/features/threads/SimpleSelectors';
import {GetUserChatStateCollection} from '@heylo/shared/src/util/user-chat-state';
import {
  ActiveThreadSelector,
  MostRecentSupportChatMessageTimestamp,
} from '@heylo/shared/src/features/threads/Selectors';
import {listenForUserProfile} from '@heylo/shared/src/features/userProfiles/Firebase';
import {AttachThreadReactionListener} from '@heylo/shared/src/features/reactions/Firebase';
import {PhotosFirebase} from '@heylo/shared/src/features/photos/Firebase';
import {
  SelectActiveCommunityId,
  SelectActiveUserBelongsToSupportCommunity,
} from '@heylo/shared/src/features/communities/Selectors';
import {
  ACTION_SET_CONTENT_SEND_STATE,
  SendState,
} from '@heylo/shared/src/features/ui/Slice';
import moment from 'moment';
import {selectActiveCommunityMember} from '@heylo/shared/src/features/communityMembers/Selectors';
import {getServerTimestamp} from '@heylo/shared/src/services/firebase/ServerTime';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {Platform} from 'react-native';

const FirebaseRemoveUserFromGroupChat = (chatId: string, userId: string) => {
  return new Promise((resolve, reject) => {
    const updates: any = {};
    updates[`/chatMembers/${chatId}/members/${userId}`] = null;
    updates[`/chatMembers/${chatId}/updatedBy`] = userId;
    updates[`/chatMembers/${chatId}/updateTimestamp`] = FirebaseServerTimestamp();
    FirebaseDatabase().ref().update(updates)
        .then(resolve)
        .catch((e) => {
          console.error('error removing user from chat', e.message, chatId, userId);
          reject(e);
        });
  });
};

export const FirebaseLeaveConversation = (params: {
  communityId?: string,
  onLeave?: () => void,
  threadId: string,
  threadType: string,
  userId: string,
}) => {
  const {
    communityId,
    onLeave,
    threadId,
    threadType,
    userId,
  } = params;
  if (communityId && IsThreadTypeCommunityBased(threadType)) {
    leaveCommunityTopic(userId, communityId, threadId);
  } else if (IsPrivateThreadType(threadType)) {
    FirebaseRemoveUserFromGroupChat(threadId, userId);
  }
  if (typeof onLeave === 'function') {
    onLeave();
  }
}


export const FirebaseDeleteConversation = (params: {
  communityId: string,
  onDelete?: () => void,
  threadId: string,
  threadType: string,
  userId: string
}) => {
  const {
    communityId,
    onDelete,
    threadId,
    userId,
  } = params;
  deleteCommunityTopic(communityId, threadId, userId);
  if (typeof onDelete === 'function') {
    onDelete();
  }
};

export const LoadAndListenForThreadMessages = (
    threadId: string,
    path: string,
    startTimestamp: number,
    shouldIncrementUnreadCount: (message: Message) => void,
): ThunkReturnType<void> => dispatch => {
  console.count('LoadAndListenForThreadMessages');
  dispatch(loadInitialThreadMessages(
      threadId,
      path,
      (startTimestamp + 1) || 0,
      shouldIncrementUnreadCount,
  ))
      .then(lastTimestamp => {
        dispatch(attachThreadMessageListener(threadId, path, lastTimestamp + 1, shouldIncrementUnreadCount));
      })
      .catch((e: Error) => handleError(e, threadId));
};

const maybeIncrementUnreadCount = (threadId: string, messageId: string, message: Message, shouldIncrementUnreadCount: (message: Message) => void)
    : ThunkReturnType<void> => dispatch => {
  if (!message.deleted && shouldIncrementUnreadCount(message)) {
    dispatch(ACTION_INCREMENT_UNREAD_MESSAGE_COUNT({
      threadId,
      eventTimestamp: message.createdAt || 0,
    }));
  }
};

const loadInitialThreadMessages = (
    threadId: string,
    path: string,
    startTimestamp: number,
    shouldIncrementUnreadCount: (message: Message) => void,
): ThunkReturnType<Promise<number>> => dispatch => {
  return new Promise((resolve, reject) => {
    const NUM_MESSAGES_TO_LOAD = Platform.OS === 'web' ? 50 : 200;
    const ref = FirebaseDatabase().ref(path)
        .orderByChild('createdAt')
        .startAt(startTimestamp)
        .limitToLast(NUM_MESSAGES_TO_LOAD)
        .once('value').then(snapshot => {
          let lastTimestamp = startTimestamp;
          if (snapshot.exists()) {
            const messagesMap: { [messageId: string]: Message } = snapshot.val() || {};
            for (const [messageId, message] of Object.entries(messagesMap)) {
              // TODO: switch to using ACTION_BULK_ADD_MESSAGES, after the
              // logic
              //  for incrementing unread messages is moved to the Reducer as
              // well
              dispatch(ACTION_ADD_MESSAGE_UNSORTED({
                threadId,
                messageId,
                message,
              }));
              dispatch(maybeIncrementUnreadCount(threadId, messageId, message, shouldIncrementUnreadCount));
              lastTimestamp = Math.max(lastTimestamp, message.createdAt || 0);
            }
          }
          dispatch(ACTION_COMPACT_THREAD({threadId}));
          resolve(lastTimestamp);
        })
        .catch(reject);
  });
};

const attachThreadMessageListener = (
    threadId: string,
    path: string,
    startTimestamp: number,
    shouldIncrementUnreadCount: (message: Message) => void,
): ThunkReturnType<void> => dispatch => {
  if (ThreadListeners.MESSAGES[threadId]) {
    return;
  }
  const NUM_MESSAGES_TO_LOAD = 50;
  const ref = FirebaseDatabase()
      .ref(path)
      .orderByChild('createdAt')
      .startAt(startTimestamp)
      .limitToLast(NUM_MESSAGES_TO_LOAD);
  ThreadListeners.MESSAGES[threadId] = ref;
  ref.on('child_added', snapshot => {
        if (!snapshot) {
          return;
        }
        const message: Message = snapshot.val();
        const messageId = snapshot.key || '';
        if (!message || !messageId) {
          return;
        }
        dispatch(ACTION_ADD_MESSAGE_UNSORTED({threadId, messageId, message}));
        dispatch(maybeIncrementUnreadCount(threadId, messageId, message, shouldIncrementUnreadCount));
      },
      (e: Error) => handleError(e, threadId));
};

const handleError = (e: Error, threadId: string) => {
  console.log('lost connection to messages', e.message);
  delete ThreadListeners.MESSAGES[threadId];
};


const leaveCommunityTopic = (userId: string, communityId: string, threadId: string) => {
  return new Promise((resolve, reject) => {
    const updates: ThreadStatePerUser = {
      currentState: 'archived',
      currentStateTimestamp: FirebaseServerTimestamp(),
      // @ts-ignore
      notificationSettings: null,
    };
    FirebaseDatabase().ref(`/threadState/${userId}/${communityId}/${threadId}`)
        .update(updates)
        .then(resolve)
        .catch((e) => {
          console.error('error archiving thread', e.message, userId, communityId, threadId);
          reject(e);
        });
  });
};

export const deleteCommunityTopic = (communityId: string, threadId: string, ownerId: string) => {
  return FirebaseCommunityScopedDeletion(communityId, {ownerId, threadId});
};

export const FirebaseAddMessageToPrivateChat = (threadId: string, messageId: string, message: Message)
    : ThunkReturnType<Promise<void>> => dispatch => {
  return new Promise((resolve, reject) => {
    const ref = FirebaseDatabase().ref(`/chatMessages/${threadId}/${messageId}`).set(message);
    // Use a local timestamp for now. This will be replaced later when the
    // Firebase messages listener triggers.
    message.createdAt = moment().valueOf();
    dispatch(ACTION_ADD_MESSAGE_UNSORTED({threadId, messageId, message}));
    dispatch(ACTION_SET_CONTENT_SEND_STATE({
      contentId: messageId,
      sendState: SendState.STARTED,
    }));
    ref.then(() => {
      dispatch(ACTION_SET_CONTENT_SEND_STATE({
        contentId: messageId,
        sendState: SendState.SUCCESS,
      }));
      return resolve();
    }).catch((e: Error) => {
      dispatch(ACTION_SET_CONTENT_SEND_STATE({
        contentId: messageId,
        sendState: SendState.ERROR,
      }));
      console.error(`error adding message to ${threadId}: `, message, e);
      return reject(e);
    });
  });
};

export const FirebaseCreatePrivateTopic = (communityId: string, userId: string, memberIds: string[], chatName: string, imageUrl: string)
    : Promise<string> => {
  return new Promise<string>(async (resolve, reject) => {
    const chatType = THREAD_TYPE_GROUP;
    const chat: ChatStateShared = {
      chatCreationTimestamp: FirebaseServerTimestamp(),
      chatImageUrl: imageUrl,
      chatName,
      chatType,
      chatOwnerId: userId,
      communityId,
    };

    const promises: Promise<any>[] = [];
    const ref = FirebaseDatabase().ref('/chatStateShared').push(chat);
    const threadId = ref.key || '';
    promises.push(ref);

    const memberMap: { [userId: string]: ThreadMember } = {};
    for (const memberId of memberIds) {
      memberMap[memberId] = {joinTimestamp: FirebaseServerTimestamp()};
    }
    const chatMembers: ChatMembers = {
      chatType,
      members: memberMap,
      updateTimestamp: FirebaseServerTimestamp(),
      updatedBy: userId,
    };
    promises.push(FirebaseDatabase().ref(`/chatMembers/${threadId}`).set(chatMembers));
    await Promise.all(promises)
        .then(() => resolve(threadId))
        .catch((e: Error) => {
          console.warn('failed to create private chat', e.message);
          reject(e);
        });
  });
};

export const FirebaseCreateDirectChat = (userId1: string, userId2: string): Promise<string> => {
  return new Promise<string>(async (resolve, reject) => {
    const chatType = THREAD_TYPE_DIRECT;
    const chat: ChatStateShared = {
      // @ts-ignore
      chatCreationTimestamp: FirebaseServerTimestamp(),
      chatType,
    };

    const promises: Promise<any>[] = [];
    const ref = FirebaseDatabase().ref('/chatStateShared').push(chat);
    const threadId = ref.key || '';
    promises.push(ref);

    const memberMap: { [userId: string]: ThreadMember } = {};
    for (const memberId of [userId1, userId2]) {
      memberMap[memberId] = {joinTimestamp: FirebaseServerTimestamp()};
    }
    const chatMembers: ChatMembers = {
      chatType,
      members: memberMap,
      updateTimestamp: FirebaseServerTimestamp(),
    };
    promises.push(FirebaseDatabase().ref(`/chatMembers/${threadId}`).set(chatMembers));
    await Promise.all(promises)
        .then(() => resolve(threadId))
        .catch((e: Error) => {
          console.warn('failed to create private chat', e.message);
          reject(e);
        });
  });
};

export const FirebaseUpdateGroupChat = (params: {
  addedMemberIds?: string[],
  chatImageUrl: string,
  chatName: string,
  removedMemberIds?: string[],
  threadId: string,
  userId: string,
}): Promise<void> => {
  const {
    threadId,
    userId,
    chatImageUrl,
    chatName,
    addedMemberIds,
    removedMemberIds,
  } = params;
  return new Promise((resolve, reject) => {
    const updates: any = {};
    updates[`/chatStateShared/${threadId}/chatImageUrl`] = chatImageUrl;
    updates[`/chatStateShared/${threadId}/chatName`] = chatName;
    for (const memberId of addedMemberIds || []) {
      updates[`/chatMembers/${threadId}/members/${memberId}`] = {joinTimestamp: FirebaseServerTimestamp()};
    }
    for (const memberId of removedMemberIds || []) {
      updates[`/chatMembers/${threadId}/members/${memberId}`] = null;
    }
    updates[`/chatMembers/${threadId}/updatedBy`] = userId;
    updates[`/chatMembers/${threadId}/updateTimestamp`] = FirebaseServerTimestamp();
    FirebaseDatabase().ref().update(updates)
        .then(resolve)
        .catch((e: Error) => {
          console.warn('FirebaseUpdateGroupChat', updates, e);
          reject();
        });
  });
};

export const AttachPrivateChatListeners = (userId: string)
    : ThunkReturnType<void> => dispatch => {
  const handleUpdate = (snapshot: DataSnapshot | null) => {
    const threadId = snapshot?.key ?? '';
    const chatState: UserChatState = snapshot?.val() ?? {};
    dispatch(storeUserChatState(userId, threadId, chatState));
  };

  const handleRemoval = (snapshot: DataSnapshot | null) => {
    if (!snapshot) {
      return;
    }
    const threadId = snapshot.key;
    if (!threadId)
      return;
    dispatch(ACTION_REMOVE_THREAD({communityId: '', threadId}));
    CleanUpOneThreadListeners(threadId);
  };

  dispatch(attachDirectChatListeners(userId, handleUpdate, handleRemoval));
  dispatch(attachGroupChatListeners(userId, handleUpdate, handleRemoval));
  dispatch(attachSupportChatListeners(userId, handleUpdate, handleRemoval));
  dispatch(attachPrivateChatLookupListener(userId));
};

const attachDirectChatListeners = (
    userId: string,
    handleUpdate: (snapshot: DataSnapshot | null) => void,
    handleRemoval: (snapshot: DataSnapshot | null) => void,
): ThunkReturnType<void> => dispatch => {
  if (Listeners.USER_CHAT_STATE_DIRECT[userId]) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/userChatState/direct/${userId}`)
      .orderByChild('lastActivityTimestamp');
  Listeners.USER_CHAT_STATE_DIRECT[userId] = ref;
  const cancelCallback = (e: Error) => {
    console.log('lost connection to direct chats', e.message);
    delete Listeners.USER_CHAT_STATE_DIRECT[userId];
  };

  ref.on('child_added', handleUpdate, cancelCallback);
  ref.on('child_changed', handleUpdate, cancelCallback);
  ref.on('child_removed', handleRemoval, cancelCallback);
};

const attachGroupChatListeners = (
    userId: string,
    handleUpdate: (snapshot: DataSnapshot | null) => void,
    handleRemoval: (snapshot: DataSnapshot | null) => void,
): ThunkReturnType<void> => dispatch => {
  if (Listeners.USER_CHAT_STATE_GROUP[userId]) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/userChatState/group/${userId}`);
  Listeners.USER_CHAT_STATE_GROUP[userId] = ref;
  const cancelCallback = (e: Error) => {
    console.log('lost connection to group chats', e.message);
    delete Listeners.USER_CHAT_STATE_GROUP[userId];
  };
  ref.on('child_added', handleUpdate, cancelCallback);
  ref.on('child_changed', handleUpdate, cancelCallback);
  ref.on('child_removed', handleRemoval, cancelCallback);
};

const attachSupportChatListeners = (
    userId: string,
    handleUpdate: (snapshot: DataSnapshot | null) => void,
    handleRemoval: (snapshot: DataSnapshot | null) => void,
): ThunkReturnType<void> => (dispatch, getState) => {
  if (Listeners.USER_CHAT_STATE_SUPPORT[userId]) {
    return;
  }
  const state = getState();
  const mostRecentTimestamp = MostRecentSupportChatMessageTimestamp(state);
  const ref = FirebaseDatabase().ref(`/userChatState/support/${userId}`)
      .orderByChild('lastActivityTimestamp')
      .startAt(mostRecentTimestamp + 1);
  Listeners.USER_CHAT_STATE_SUPPORT[userId] = ref;

  const cancelCallback = (e: Error) => {
    console.warn('lost connection to support chats', e.message);
    delete Listeners.USER_CHAT_STATE_SUPPORT[userId];
  };
  ref.on('child_added', handleUpdate, cancelCallback);
  ref.on('child_changed', handleUpdate, cancelCallback);
  ref.on('child_removed', handleRemoval, cancelCallback);
};

export const LoadOneUserChatState = (threadType: string, threadId: string, userId: string)
    : ThunkReturnType<void> => dispatch => {
  const collection = GetUserChatStateCollection(threadType);
  if (!collection) {
    console.warn('cannot load /userChatState', threadType, threadId);
    return;
  }
  FirebaseDatabase().ref(`${collection}/${userId}/${threadId}`)
      .once('value')
      .then(snapshot => {
        const chatState: UserChatState = snapshot?.val() ?? {};
        dispatch(storeUserChatState(userId, threadId, chatState));
      });
};

const storeUserChatState = (userId: string, threadId: string, chatState: UserChatState)
    : ThunkReturnType<void> => (dispatch) => {
  const {
    chatType,
    lastActivityTimestamp,
    lastReadTimestamp,
  } = chatState;
  // console.count('storeUserChatState: ' + chatType);
  dispatch(ThreadStateLoaded({threadId, chatState}));
  // HACK: should use lastUserMessageTimestamp here to decide if we want to
  // increment the badge count, however we do not have a good means to
  // only update lastUserMessageTimestamp for some support chat users but
  // not others (the support team).
  if ((lastActivityTimestamp || 0) > (lastReadTimestamp || 0)) {
    dispatch(AttachListenersForOnePrivateChat(chatType || '', threadId, userId));
  }
};

export const AttachListenersForOnePrivateChat = (threadType: string, threadId: string, userId: string)
    : ThunkReturnType<void> => dispatch => {
  dispatch(attachMembersListener(threadType, threadId, userId));
  dispatch(attachSharedStateListener(threadId));
  dispatch(AttachThreadReactionListener(userId, threadId));
  dispatch(AttachDeletionsListener(threadId));
  dispatch(PhotosFirebase.attachPrivateChatPhotoListener(threadId));
};

const attachMembersListener = (threadType: string, threadId: string, userId: string)
    : ThunkReturnType<void> => dispatch => {
  if (ThreadListeners.MEMBERS[threadId]) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/chatMembers/${threadId}`);
  ThreadListeners.MEMBERS[threadId] = ref;
  ref.on('value', (snapshot) => {
    if (!snapshot) {
      return;
    }
    const chatMembers: ChatMembers = snapshot.val();
    if (!chatMembers || typeof chatMembers !== 'object') {
      return;
    }
    if (!chatMembers.members) {
      chatMembers.members = {};
    }
    dispatch(ACTION_UPDATE_THREAD_MEMBERS({
      threadId,
      members: chatMembers.members,
    }));
    for (const userId of Object.keys(chatMembers.members)) {
      listenForUserProfile(userId)(dispatch);
    }

    if (threadType === THREAD_TYPE_SUPPORT) {
      // For support chats, assume all members have always been there.
      dispatch(attachChatMessagesListener(threadId, userId, 0));
      dispatch(attachChatUpdatesListener(threadId, userId, 0));
    } else {
      for (const [memberId, threadMember] of Object.entries(chatMembers.members)) {
        if (memberId === userId) {
          // Listen for messages starting from when the current user joined
          // the chat.
          dispatch(attachChatMessagesListener(threadId, userId, threadMember.joinTimestamp || 0));
          dispatch(attachChatUpdatesListener(threadId, userId, threadMember.joinTimestamp || 0));
        }
      }
    }
  }, (e: Error) => {
    console.log('lost connection to private chat members', e.message);
    delete ThreadListeners.MEMBERS[threadId];
  });
};

const attachChatMessagesListener = (threadId: string, userId: string, userJoinTimestamp: number)
    : ThunkReturnType<void> => (dispatch, getState) => {
  if (ThreadListeners.MESSAGES[threadId]) {
    return;
  }
  console.count('attachChatMessagesListener');

  const path = `/chatMessages/${threadId}`;
  const state = getState();
  const mostRecentMessageTimestamp = selectThreadLatestLoadedMessageTimestamp(state, threadId);
  const startTimestamp = Math.max(userJoinTimestamp, mostRecentMessageTimestamp);
  const shouldIncrementUnreadCount = (message: Message) => {
    const {ownerId, source} = message;
    const state = getState();
    const suppressForActiveThread = ActiveThreadIdSelector(state) === threadId;
    const suppressForActiveUser = ownerId === userId;
    const suppressForPrePopulated = source === 'prePopulated';
    // HACK: computing the user's role locally instead of pre-computing it in
    // Firebase
    const suppressForSupportBot = source === 'supportBot' &&
        SelectActiveUserBelongsToSupportCommunity(state);
    return !suppressForActiveThread && !suppressForActiveUser && !suppressForPrePopulated && !suppressForSupportBot;
  };
  dispatch(LoadAndListenForThreadMessages(threadId, path, startTimestamp, shouldIncrementUnreadCount));
};

const attachPrivateChatLookupListener = (userId: string)
    : ThunkReturnType<void> => (dispatch) => {
  if (Listeners.DIRECT_CHAT_LOOKUP[userId]) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/chatLookup/${userId}`);
  Listeners.DIRECT_CHAT_LOOKUP[userId] = ref;
  const handleDisconnect = (e: Error) => {
    console.log('lost connection to /chatLookup', e.message);
    delete Listeners.DIRECT_CHAT_LOOKUP[userId];
  };
  ref.on('value', snapshot => {
    if (!snapshot) {
      return;
    }
    const chatLookups: { [userId: string]: string } = snapshot.val() || {};
    dispatch(ACTION_STORE_PRIVATE_CHAT_LOOKUPS({chatLookups}));
  }, handleDisconnect);
};

const attachChatUpdatesListener = (threadId: string, userId: string, userJoinTimestamp: number)
    : ThunkReturnType<void> => (dispatch, getState) => {
  if (ThreadListeners.UPDATES[threadId]) {
    return;
  }
  const NUM_MESSAGES_TO_LOAD = 20;
  const ref = FirebaseDatabase().ref(`/chatUpdates/${threadId}`)
      .orderByChild('updateTimestamp')
      .startAt(userJoinTimestamp)
      .limitToLast(NUM_MESSAGES_TO_LOAD);
  ThreadListeners.UPDATES[threadId] = ref;
  console.count('attachChatUpdatesListener');
  const state = getState();
  ref.on('child_added', (snapshot) => {
    if (!snapshot) {
      return;
    }
    const update: ThreadUpdate = snapshot.val();
    const updateId = snapshot.key;
    if (!updateId) {
      return;
    }
    update.key = updateId;
    if (AllThreadUpdatesSelector(state)[threadId]?.[updateId]) {
      return;
    }
    dispatch(ACTION_ADD_THREAD_UPDATE({threadId, updateId, update}));
    // if (update.updateType === 'member_added' && update.updateTargetUserId
    // === userId) { dispatch(IncrementUnreadMessageCount(chatId,
    // update.updateTimestamp || 0)); }
  }, (e: Error) => {
    console.log('lost connection to private chat updates', e.message);
    delete ThreadListeners.UPDATES[threadId];
  });
};

const attachSharedStateListener = (threadId: string)
    : ThunkReturnType<void> => (dispatch) => {
  if (ThreadListeners.SHARED_STATE[threadId]) {
    return;
  }
  // console.count('/chatStateShared listener');
  const ref = FirebaseDatabase().ref(`/chatStateShared/${threadId}`);
  ThreadListeners.SHARED_STATE[threadId] = ref;
  ref.on('value',
      snapshot => {
        if (!snapshot) {
          return;
        }
        const state: ChatStateShared = snapshot.val() || {};
        const {
          chatCreationTimestamp,
          chatImageUrl = '',
          chatName,
          chatOwnerId,
          chatType,
          communityId = '',
        } = state;
        const thread: Thread = {
          creationTimestamp: chatCreationTimestamp,
          heroImageUrl: chatImageUrl,
          threadType: chatType || THREAD_TYPE_GROUP,
        };
        if (chatName) {
          thread.name = chatName;
        }
        if (chatOwnerId) {
          thread.ownerId = chatOwnerId
        }
        dispatch(ACTION_UPDATE_THREAD({threadId, communityId, thread}));
      }, (e: Error) => {
        console.log('lost connection to shared thread state', e.message);
        delete ThreadListeners.SHARED_STATE[threadId];
        dispatch(ACTION_REMOVE_THREAD({communityId: '', threadId}));
      });
};

export const AttachDeletionsListener = (threadId: string)
    : ThunkReturnType<void> => (dispatch, getState) => {
  if (ThreadListeners.DELETIONS[threadId])
    return;
  const state = getState();
  const lastDeletionTimestamp = MostRecentPrivateChatScopedDeletionTimestamp(state, threadId);
  const ref = FirebaseDatabase().ref(`/deletions/privateChatScoped/${threadId}`)
      .orderByChild('timestamp')
      .startAt(lastDeletionTimestamp);
  ThreadListeners.DELETIONS[threadId] = ref;
  ref.on('child_added',
      snapshot => {
        if (!snapshot) {
          return;
        }
        const deletion: PrivateChatScopedDeletion = snapshot.val() || {};
        const {messageId} = deletion;
        if (messageId) {
          dispatch(ACTION_DELETE_MESSAGE({threadId, messageId}));
        }
        dispatch(ACTION_ADD_PRIVATE_CHAT_DELETION({
          threadId,
          deletionId: snapshot.key || '',
          deletion,
        }));
      },
      (e: Error) => {
        console.log('lost connection to chat deletions', e.message);
        delete ThreadListeners.DELETIONS[threadId];
      });
};

export const createCommunityChat = (userId: string, communityId: string, title: string, notes: string, imageUrl: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    const newThread: Thread = {
      creationTimestamp: FirebaseServerTimestamp(),
      heroImageUrl: imageUrl,
      name: title,
      notes,
      ownerId: userId,
      source: 'start_conversation',
    };
    try {
      const ref = FirebaseDatabase().ref(`/communities/${communityId}/communityThreads`).push(newThread);
      const threadId: string = ref.key || '';
      // NB: It can take a while for cloud functions to write to /threadState,
      // so we do an additional write here to ensure that the user immediately
      // appears to be joined to the topic. No need to wait for this to
      // complete, as we only care about the write affecting the local Firebase
      // DB.
      FirebaseDatabase().ref(`/threadState/${userId}/${communityId}/${threadId}/currentState`).set('subscribed');
      await ref;
      resolve(threadId);
    } catch (e) {
      reject(e);
    }
  });
};

export const getScrollMessages = (communityId: string, threadId: string, endTimestamp: number)
    : ThunkReturnType<Promise<void>> => (dispatch) => {
  return new Promise(resolve => {
    dispatch(ACTION_START_LOADING_MORE_MESSAGES());
    const NUM_MESSAGES_TO_FETCH = 100;
    let numMessagesLoaded = 0;
    const path = !!communityId ? `/messages/${communityId}/${threadId}` : `/chatMessages/${threadId}`;
    FirebaseDatabase().ref(path)
        .orderByChild('createdAt')
        .endAt(endTimestamp - 1)
        .limitToLast(NUM_MESSAGES_TO_FETCH)
        .once('value')
        .then((snapshot) => {
          const messages = snapshot?.val() || {};
          dispatch(ACTION_BULK_ADD_MESSAGES({
            threadId,
            messages,
          }));
          numMessagesLoaded = Object.keys(messages).length;
        })
        .catch((error) => {
          console.error(`error getting scroll messages from /${communityId}/${threadId}`, error.message);
          numMessagesLoaded = 0;
        })
        .finally(() => {
          dispatch(ACTION_FINISH_LOADING_MORE_MESSAGES({numMessagesLoaded}));
          resolve();
        });
  });
};

export const StoreAnnouncementThread = (userId: string, communityId: string, threadId: string)
    : ThunkReturnType<void> => dispatch => {
  const thread: Thread = {
    communityId,
    name: 'Announcements',
    threadType: THREAD_TYPE_ANNOUNCEMENT,
  };
  dispatch(ACTION_UPDATE_THREAD({threadId, communityId, thread}));
};

export const attachAllCommunityThreadListeners = (userId: string, communityId: string)
    : ThunkReturnType<void> => dispatch => {
  if (Listeners.COMMUNITY_THREADS[communityId]) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/communities/${communityId}/communityThreads`);
  Listeners.COMMUNITY_THREADS[communityId] = ref;
  const cancelCallback = (e: Error) => {
    console.log('lost connection to community threads', e.message);
    delete Listeners.COMMUNITY_THREADS[communityId];
  };
  ref.on('child_added', (snapshot) => {
    const threadId = snapshot?.key || '';
    const thread: Thread = snapshot?.val();
    thread.threadType = THREAD_TYPE_COMMUNITY;
    // console.count('attachAllCommunityThreadListeners: child added');
    dispatch(ACTION_UPDATE_THREAD({threadId, communityId, thread}));
  }, cancelCallback);
  ref.on('child_removed', (snapshot) => {
    const threadId = snapshot?.key || '';
    dispatch(ACTION_REMOVE_THREAD({communityId, threadId}));
    CleanUpOneThreadListeners(threadId);
  }, cancelCallback);
  ref.on('child_changed', (snapshot) => {
    const threadId = snapshot?.key || '';
    const thread: Thread = snapshot?.val();
    thread.threadType = THREAD_TYPE_COMMUNITY;
    // console.count('attachAllCommunityThreadListeners: child changed');
    dispatch(ACTION_UPDATE_THREAD({threadId, communityId, thread}));
  }, cancelCallback);
};

export const AttachThreadListeners = (userId: string, communityId: string, threadId: string)
    : ThunkReturnType<void> => dispatch => {
  dispatch(attachThreadMembersListener(communityId, threadId));
  dispatch(attachMessagesListener(userId, communityId, threadId));
  dispatch(attachThreadUpdatesListener(userId, communityId, threadId));
  dispatch(AttachThreadReactionListener(userId, threadId));
};

const attachMessagesListener = (userId: string, communityId: string, threadId: string)
    : ThunkReturnType<void> => (dispatch, getState) => {
  if (ThreadListeners.MESSAGES[threadId]) {
    return;
  }
  console.count('attachMessagesListener');

  const state = getState();
  const path = `/messages/${communityId}/${threadId}`;
  const mostRecentMessageTimestamp = selectThreadLatestLoadedMessageTimestamp(state, threadId);
  const shouldIncrementUnreadCount = (message: Message) => {
    const state = getState();
    const suppressForMessagesPredateJoiningCommunity = (message.createdAt || 0) < (selectActiveCommunityMember(state).joinTimestamp ?? 0);
    const suppressForActiveThread = ActiveThreadIdSelector(state) === threadId;
    const suppressForSupportUser = (message.source === 'supportBot' || message.source === 'prePopulated')
        && SelectActiveUserBelongsToSupportCommunity(state);
    const suppressForFirstSupportMessage = communityId === 'support' && message.source === 'prePopulated';
    const suppressForSelf = message.ownerId === userId;
    return !suppressForMessagesPredateJoiningCommunity
        && !suppressForActiveThread
        && !suppressForSupportUser
        && !suppressForFirstSupportMessage
        && !suppressForSelf;
  };

  dispatch(LoadAndListenForThreadMessages(threadId, path, mostRecentMessageTimestamp, shouldIncrementUnreadCount));
};

const attachThreadUpdatesListener = (userId: string, communityId: string, threadId: string)
    : ThunkReturnType<void> => (dispatch, getState) => {
  if (ThreadListeners.UPDATES[threadId]) {
    return;
  }
  const state = getState();
  const mostRecentMessageTimestamp = selectThreadLatestLoadedMessageTimestamp(state, threadId);
  const NUM_MESSAGES_TO_LOAD = 50;
  const ref = FirebaseDatabase().ref(`/threadUpdates/${communityId}/${threadId}`)
      .orderByChild('updateTimestamp')
      .startAt(mostRecentMessageTimestamp)
      .limitToLast(NUM_MESSAGES_TO_LOAD);
  ThreadListeners.UPDATES[threadId] = ref;
  ref.on('child_added', (snapshot) => {
    const update: ThreadUpdate = snapshot?.val();
    const updateId = snapshot?.key || '';
    if (!update || !updateId) {
      return;
    }
    update.key = updateId;
    if (AllThreadUpdatesSelector(state)[threadId]?.[updateId]) {
      return;
    }
    dispatch(ACTION_ADD_THREAD_UPDATE({threadId, updateId, update}));
  }, (e: Error) => {
    console.log('lost connection to private chat updates', e.message);
    delete ThreadListeners.UPDATES[threadId];
  });
};

const attachThreadMembersListener = (communityId: string, threadId: string)
    : ThunkReturnType<void> => (dispatch) => {
  if (ThreadListeners.MEMBERS[threadId]) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/threadMembers/${communityId}/${threadId}`);
  ThreadListeners.MEMBERS[threadId] = ref;
  ref.on('value', (snapshot) => {
    const threadId = snapshot?.key || '';
    const members: { [key: string]: ThreadMember } = snapshot?.val();
    if (!threadId || !members || typeof members !== 'object') {
      return;
    }
    dispatch(ACTION_UPDATE_THREAD_MEMBERS({threadId, members}));
    for (const userId of Object.keys(members)) {
      listenForUserProfile(userId)(dispatch);
    }
  }, (e: Error) => {
    console.log('lost connection to thread members', e.message);
    delete ThreadListeners.MEMBERS[threadId];
  });
};

export const AttachCommunityThreadStateListener = (communityId: string)
    : ThunkReturnType<void> => (dispatch) => {
  if (Listeners.COMMUNITY_SHARED_THREAD_STATE[communityId]) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/threadStateShared/${communityId}`);
  Listeners.COMMUNITY_SHARED_THREAD_STATE[communityId] = ref;
  console.count('AttachCommunityThreadStateListener');

  const handleUpdate = (snapshot: DataSnapshot | null) => {
    const threadState: ThreadStateShared = snapshot?.val();
    const threadId = snapshot?.key;
    if (!threadState || !threadId) {
      return;
    }
    dispatch(ACTION_SET_THREAD_ACTIVITY_TIMESTAMP({
      threadId,
      timestamp: threadState.lastActivityTimestamp || 0,
    }));
    dispatch(ACTION_SET_THREAD_USER_MESSAGE_TIMESTAMP({
      threadId,
      timestamp: threadState.lastUserMessageTimestamp || 0,
    }));
  };
  const handleError = (e: Error) => {
    console.warn('lost connection to shared thread state', communityId, e.message);
    delete Listeners.COMMUNITY_SHARED_THREAD_STATE[communityId];
  };
  ref.on('child_added', handleUpdate, handleError);
  ref.on('child_changed', handleUpdate, handleError);
};

export const AttachCommunityPerUserThreadStateListener = (userId: string, communityId: string)
    : ThunkReturnType<void> => dispatch => {
  if (Listeners.COMMUNITY_PER_USER_THREAD_STATE[communityId]) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/threadState/${userId}/${communityId}`);
  Listeners.COMMUNITY_PER_USER_THREAD_STATE[communityId] = ref;
  const handleValue = (snapshot: DataSnapshot | null) => {
    const threadState: ThreadStatePerUser = snapshot?.val();
    const threadId = snapshot?.key;
    if (!threadState || !threadId) {
      return;
    }
    dispatch(ACTION_THREAD_STATE_PER_USER_UPDATED({threadId, threadState}));
  };
  const handleError = (e: Error) => {
    console.log('lost connection to per-user thread state for community', communityId, e.message);
    delete Listeners.COMMUNITY_PER_USER_THREAD_STATE[communityId];
  };
  ref.on('child_added', handleValue, handleError);
  ref.on('child_changed', handleValue, handleError);
};

export const FirebaseAddMessageToCommunityChat = (communityId: string, threadId: string, messageId: string, message: Message)
    : ThunkReturnType<Promise<void>> => dispatch => {
  return new Promise((resolve, reject) => {
    const ref = FirebaseDatabase().ref(`/messages/${communityId}/${threadId}/${messageId}`)
        .set(message);
    dispatch(ACTION_SET_CONTENT_SEND_STATE({
      contentId: messageId,
      sendState: SendState.STARTED,
    }));
    ref.then(() => {
      dispatch(ACTION_SET_CONTENT_SEND_STATE({
        contentId: messageId,
        sendState: SendState.SUCCESS,
      }));
      resolve();
    }).catch((error) => {
      dispatch(ACTION_SET_CONTENT_SEND_STATE({
        contentId: messageId,
        sendState: SendState.ERROR,
      }));
      console.warn(`error adding message to ${communityId}/${threadId}: `, message, error);
      reject(error);
    });
  });
};

export const FirebaseUpdateThreadLastReadTimestamp = (threadType: string, userId: string, communityId: string, threadId: string)
    : ThunkReturnType<Promise<void>> => (dispatch) => {
  return new Promise(resolve => {
    let path = '';
    if (communityId) {
      path = `/threadState/${userId}/${communityId}/${threadId}/lastReadTimestamp`;
    } else {
      const collection = GetUserChatStateCollection(threadType);
      if (!collection) {
        console.warn('invalid threadType', threadType);
        return;
      }
      path = `/${collection}/${userId}/${threadId}/lastReadTimestamp`;
    }
    getServerTimestamp()
        .then(timestamp => dispatch(ACTION_UPDATE_THREAD_LAST_READ_TIMESTAMP({
          threadId,
          timestamp,
        })));
    FirebaseDatabase().ref(path)
        .set(FirebaseServerTimestamp())
        .then(resolve)
        .catch((e: Error) => {
          console.warn('failed to update last read timestamp', path, e.message);
          resolve();
        });
  });
};

export const FirebaseMaybeUpdateThreadLastReadTimestamp = ()
    : ThunkReturnType<Promise<void>> =>
    async (dispatch, getState) => {
      const state = getState();
      const userId = selectActiveUserId(state);
      const communityId = SelectActiveCommunityId(state);
      const thread = ActiveThreadSelector(state);
      const {threadId = '', threadType = ''} = thread;
      if (threadType && threadId && userId) {
        await dispatch(FirebaseUpdateThreadLastReadTimestamp(threadType, userId, communityId, threadId));
      }
    };

export const FirebaseJoinConversation = (userId: string, communityId: string, threadId: string) => {
  return new Promise((resolve, reject) => {
    const updates: any = {};
    updates['currentState'] = 'subscribed';
    updates['currentStateTimestamp'] = FirebaseServerTimestamp();
    updates['notificationSettings/newMessages'] = {
      enabled: true,
      timestamp: FirebaseServerTimestamp(),
    };
    FirebaseDatabase().ref(`/threadState/${userId}/${communityId}/${threadId}`)
        .update(updates)
        .then(resolve)
        .catch((e: Error) => {
          console.error('error joining thread', e.message, userId, communityId, threadId);
          reject(e);
        });
  });
};

export const FirebaseRemoveMessage = (threadType: string, communityId: string, threadId: string, messageId: string, ownerId: string)
    : ThenableReference => {
  if (IsThreadTypeCommunityBased(threadType)) {
    return FirebaseCommunityScopedDeletion(communityId, {
      messageId,
      ownerId,
      threadId,
      timestamp: FirebaseServerTimestamp(),
    });
  }
  return FirebasePrivateChatScopedDeletion(threadId, {
    messageId,
    ownerId,
    timestamp: FirebaseServerTimestamp(),
  });
};

const FirebasePrivateChatScopedDeletion = (threadId: string, deletion: PrivateChatScopedDeletion)
    : ThenableReference => {
  return FirebaseDatabase().ref(`/deletions/privateChatScoped/${threadId}`).push(deletion);
};