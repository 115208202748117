import React, {useMemo} from 'react';
import {getAbbreviatedLastActivityString} from '@heylo/shared/src/util/Time';
import moment from 'moment';
import {HeyloText} from '@heylo/web/src/lib/materialUi';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Colors} from '@heylo/web/src/styles/RootStyles';

type Props = {
  timestamp: number,
};

export const LastActiveMessage = (props: Props) => {
  const {timestamp} = props;
  const nowMoment = useMemo(() => moment(), [timestamp]);
  const activityString = getAbbreviatedLastActivityString(nowMoment, timestamp);
  const classes = useStyles();
  if (!activityString) {
    return null;
  }
  return (
      <div>
        <HeyloText align={'right'}
                   className={classes.textLabel}
                   color={'inherit'}
                   variant={'body1'}
        >
          {activityString}
        </HeyloText>
      </div>
  );
};

const useStyles = makeStyles({
  textLabel: {
    color: Colors.Grey,
    fontSize: '1.3rem',
  },
});