import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {View} from 'react-native';
import {
  EditUserProfile,
  EditUserProfileState,
} from '@heylo/components/src/features/userProfile/EditUserProfile';
import {NextButton, ProgressHeader} from '../navigation/ProgressHeader';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {resolveUriForGroup} from '@heylo/shared/src/features/marketplaces/Util';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';

export const JoinGroupEditProfileScreen = () => {
  const history = useHistory();
  const [state, setState] = useState(EditUserProfileState.EMPTY);

  const onBackPress = () => {
    history.goBack();
  }
  const onSubmit = () => setState(EditUserProfileState.SUBMIT_START);

  const {
    communityId,
  } = useSelector((state: RootState) => ({
    communityId: SelectActiveCommunityId(state),
  }), shallowEqual);

  const onSuccess = () => {
    history.replace(`${resolveUriForGroup(communityId)}/requestSent`);
  };

  return (
      <ProgressHeader
          nextButton={state !== EditUserProfileState.EMPTY
              ? NextButton.NEXT
              : NextButton.HIDDEN
          }
          onBackPress={onBackPress}
          onNextPress={onSubmit}
          percent={'66%'}
          title={'Edit profile'}
      >
        <View style={{
          alignSelf: 'center',
          maxWidth: 500,
          padding: StyleConstants.SPACING,
          height: '100%',
        }}>
          <EditUserProfile
              onProfileSubmit={onSuccess}
              state={state}
              setState={setState}
          />
        </View>
      </ProgressHeader>
  );
};