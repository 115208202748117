import {Thread} from '@heylo/shared/src/types/firebase-types';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

export const ActiveThreadIdSelector = (state: RootState): string => state.threads?.CURRENT_THREAD_ID || '';
export const AllCommunityThreadIdsSelector = (state: RootState) => state.threads?.FIELD_COMMUNITY_THREAD_IDS || {};
export const AllThreadsSelector = (state: RootState) => state.threads?.FIELD_THREADS || {};
export const AllThreadJoinStatesSelector = (state: RootState) => state.threads?.FIELD_THREAD_JOIN_STATE || {};
export const AllThreadLastActivityTimestampsSelector = (state: RootState) => state.threads?.FIELD_THREAD_LAST_ACTIVITY_TIMESTAMPS || {};
export const AllThreadLastUserMessageTimestampsSelector = (state: RootState) => state.threads?.FIELD_THREAD_LAST_USER_MESSAGE_TIMESTAMPS || {};
export const AllThreadLastReadTimestampsSelector = (state: RootState) => state.threads?.LAST_READ_TIMESTAMPS || {};
export const AllThreadMembersSelector = (state: RootState) => state.threads?.FIELD_THREAD_MEMBERS || {};
export const AllThreadMessagesSelector = (state: RootState) => state.threads?.FIELD_THREAD_MESSAGES || {};
export const AllThreadUpdatesSelector = (state: RootState) => state.threads?.FIELD_THREAD_UPDATES || {};
export const selectThreadIsLoadingMoreMessages = (state: RootState) => state.threads?.loadingMoreMessages ?? false;
export const selectThreadNumMessagesLoadedLast = (state: RootState) => state.threads?.numMessagesLoadedLast ?? -1;
export const selectThreadNumUnreadMessages = (state: RootState) => state.threads?.threadUnreadMessages || {};
export const AllDirectChatIdToUserIdsSelector = (state: RootState) => state.threads?.FIELD_PRIVATE_CHAT_THREAD_ID_TO_USER_ID || {};
export const AllUserIdToDirectChatIdsSelector = (state: RootState) => state.threads?.FIELD_PRIVATE_CHAT_USER_ID_TO_THREAD_ID || {};
export const selectRecentThreadContributorUserIds = (state: RootState) => state.threads?.threadMostRecentContributorUserIds || {};
export const threadInfoLastViewedTimestampSelector = (state: RootState) => state.threads?.FIELD_THREAD_INFO_LAST_VIEWED_TIMESTAMP || {};

export const OneThreadSelector = (state: RootState, threadId: string)
    : Thread => AllThreadsSelector(state)[threadId] || {};

export const selectThreadLatestLoadedMessageTimestamp = (state: RootState, threadId: string) => {
  return state.threads?.threadLoadedMessagesLatestTimestamp[threadId] || 0;
};

export const MostRecentPrivateChatScopedDeletionTimestamp = (state: RootState, threadId: string) => {
  return state.threads?.privateThreadMostRecentDeletionTimestamp[threadId] || 0;
};