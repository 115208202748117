import React from 'react';
import {CommunityProfile} from '@heylo/shared/src/types/firebase-types';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {Card, useTheme} from 'react-native-paper';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {Dimensions, Platform, Text, View} from 'react-native';
import {HeyloImage} from '../../ui/image/HeyloImage';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

export enum GroupCardUiType {
  DISCOVER,
  PROSPECTIVE,
  SEARCH_RESULT,
}

type Props = {
  community: CommunityProfile,
  onPress: (communityId: string) => void,
  showLocation?: boolean,
  uiType?: GroupCardUiType
};

export const GroupCard = React.memo((props: Props) => {
  const {
    community,
    onPress,
    uiType = GroupCardUiType.DISCOVER,
  } = props;

  const logger = useLoggingService();
  const text = useTextStyles();
  const theme = useTheme();

  const {
    communityId = '',
    communityName = 'Unnamed group',
    description,
    heroImageUrl,
    locationName,
  } = community;

  const handlePress = () => {
    logger.logEvent(AnalyticsEvent.COMMUNITY_PROFILE_TAPPED, {
      communityId,
      context: 'marketplace'
    });
    // Delay the press handling to give the animation a moment to show to the
    // user.
    setTimeout(() => onPress(communityId),
        Platform.OS === 'web' ? 200 : 1);
  };

  const showDescription = uiType === GroupCardUiType.DISCOVER && !!description;
  const showImage = uiType !== GroupCardUiType.PROSPECTIVE && !!heroImageUrl;
  const showLocation = uiType === GroupCardUiType.SEARCH_RESULT;

  const {width} = Dimensions.get('window');
  const matchXs = width < 600;

  return (
      <Card
          elevation={3}
          onPress={handlePress}
          style={{
            // @ts-ignore
            cursor: 'pointer',
          }}
      >
        <View style={{flexDirection: 'row'}}>
          <View
              style={{
                padding: StyleConstants.SPACING,
                justifyContent: 'center',
                width: showImage ? '67%' : '100%',
              }}
          >
            <Text numberOfLines={1} style={text.h6}>
              {communityName}
            </Text>
            {showLocation && !!locationName && (
                <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={text.subtitle1}
                >
                  {locationName}
                </Text>
            )}
            {showDescription && (
                <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={(Platform.OS === 'web' && !matchXs) ? 5 : 3}
                    style={text.body1}
                >
                  {description}
                </Text>
            )}
          </View>

          {showImage && (
              <View style={{width: '33%'}}>
                <HeyloImage
                    aspectRatio={1}
                    style={{
                      borderWidth: 0,
                      borderBottomRightRadius: theme.roundness,
                      borderTopRightRadius: theme.roundness,
                    }}
                    uri={heroImageUrl}
                />

              </View>
          )}
        </View>
      </Card>
  );
});
