import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {shortenedHumanReadableTimestamp} from '@heylo/shared/src/util/Time';
import {StyleVars} from '../../styles/StyleVars';

export const ThreadTimestamp = React.memo(({timestamp}: { timestamp: number }) => {
  return (
      <View style={styles.timestampContainer}>
        <Text style={styles.timestampText}>
          {shortenedHumanReadableTimestamp(timestamp)}
        </Text>
      </View>
  );
});

const styles = StyleSheet.create({
  timestampContainer: {
    marginTop: 15,
  },
  timestampText: {
    color: StyleVars.Colors.GreyMedium,
    fontFamily: StyleVars.Font,
    fontSize: 12,
    textAlign: 'center',
  },
});