import {
  EventDonationContext,
  EventDonationEvent,
  EventDonationMachine,
} from '@heylo/shared/src/features/stripe/EventDonationMachine';
import {useMachine} from '@xstate/react/lib';
import {Interpreter, State} from 'xstate';
import {useEffect} from 'react';
import {
  Community,
  Event,
  StripeAccount,
} from '@heylo/shared/src/types/firebase-types';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';

type Props = {
  build: string,
  community: Community,
  event: Event,
  isAdmin: boolean,
  openStripeCheckoutUri: (uri: string) => Promise<void>,
  showSuccess: () => Promise<void>,
  stripeAccount: StripeAccount | null,
  userEmail: string,
  userId: string,
};

export type EventDonationMachineSend = Interpreter<EventDonationContext, any, EventDonationEvent>['send'];
export type EventDonationMachineState = State<EventDonationContext, EventDonationEvent>;

export const useEventDonationMachine = (props: Props)
    : [EventDonationMachineState, EventDonationMachineSend] => {
  const {
    build,
    community,
    event,
    isAdmin,
    openStripeCheckoutUri,
    showSuccess,
    stripeAccount,
    userEmail,
    userId,
  } = props;

  const logger = useLoggingService();

  const [state, send] = useMachine(
      EventDonationMachine.withConfig({
        services: {
          openStripeCheckoutUri: async (context, event): Promise<void> => {
            const {data: stripeCheckoutUri} = event;
            await openStripeCheckoutUri(stripeCheckoutUri);
          },
          successDialog: showSuccess,
        },
      }, {
        buildVariant: build,
        logger,
      }));

  useEffect(() => {
    send({
      type: 'LOADED',
      community,
      event,
      isAdmin,
      stripeAccount,
      userEmail,
      userId,
    });
  }, [
    community,
    event,
    isAdmin,
    stripeAccount,
    userEmail,
    userId,
  ]);

  return [state, send];
};