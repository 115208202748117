import React, {useEffect, useRef, useState} from 'react';
import {
  Animated,
  Dimensions,
  Keyboard,
  LayoutChangeEvent,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {BackButton} from '@heylo/components/src/ui/header/BackButton';
import ConfirmButton from '@heylo/components/src/ui/header/ConfirmButton';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {
  THREAD_TYPE_COMMUNITY,
  THREAD_TYPE_GROUP,
} from '@heylo/shared/src/types/ThreadTypes';
import {Thread} from '@heylo/shared/src/types/firebase-types';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {IncrementUserInteraction} from '@heylo/shared/src/features/userEvents/Actions';
import {SwitchControl} from './SwitchControl';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {
  ACTION_SET_THREAD_STATE,
  ACTION_UPDATE_THREAD,
} from '@heylo/shared/src/features/threads/Slice';
import {
  createCommunityChat,
  FirebaseCreatePrivateTopic,
} from '@heylo/shared/src/features/threads/Firebase';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {HeyloTextInput} from '@heylo/components/src/ui/input/HeyloTextInput';
import {EditableImage} from '@heylo/components/src/ui/image';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {HeyloDialog} from '@heylo/components/src/ui/dialog/HeyloDialog';
import {StyleVars} from '@heylo/components/src/styles/StyleVars';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {MobileHeader} from '../../ui/header/MobileHeader';
import {DismissKeyboard} from '../../ui/keyboard/DismissKeyboard';
import {DropShadowScrollView} from '../../ui/scrollview/DropShadowScrollView';
import InputScrollView from 'react-native-input-scroll-view';
import {CloseButton} from '../../ui/header/CloseButton';
import { AddThreadMembersList } from '../threads/AddThreadMembersList';

const mapState = (state: RootState) => {
  return {
    activeCommunityId: SelectActiveCommunityId(state),
    currentUserId: selectActiveUserId(state) || '',
  };
};
const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & {
  onCreate: (threadId: string) => void,
  onCancel: () => void,
};

enum State {
  EMPTY,
  FILLED,
  SUBMITTING,
  SUCCESS,
}

export const CreateTopic = connector((props: Props) => {
  console.count('CreateTopic');

  const {
    activeCommunityId,
    currentUserId,
    onCreate,
    onCancel,
  } = props;

  const dispatch = useDispatch();
  const logger = useLoggingService();

  const [isPrivate, setIsPrivate] = useState(false);
  const [chatTitle, setChatTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const [currentMemberMap, setCurrentMemberMap] = useState({});
  const onMembersChanged = (members: any) => {
    setCurrentMemberMap(members);
  };

  const [alert, setAlert] = useState<[string, string] | null>(null);
  const [state, setState] = useState<State>(State.EMPTY);

  useEffect(() => {
    if (state === State.EMPTY || state === State.FILLED) {
      setState(chatTitle.length > 0 ? State.FILLED : State.EMPTY);
    }
  }, [chatTitle, state]);


  const verifyInputs = (name: string) => {
    if (!name || name.length < 3) {
      return false;
    }
    return true;
  };

  const createTopic = async () => {
    Keyboard.dismiss();
    if (isUploadingImage) {
      setAlert(['Image has not uploaded yet', 'Give it a moment and then try again']);
      return;
    }
    const trimmedName = chatTitle.trim();
    const trimmedNotes = notes.trim();
    if (!verifyInputs(trimmedName)) {
      setAlert(['Topic name must be 3+ letters long', 'Please enter a longer topic name to continue.']);
      return;
    }
    setState(State.SUBMITTING);
    let promise;
    let memberCount = 1;
    if (isPrivate) {
      const userIds = [currentUserId];
      userIds.push(...Object.keys(currentMemberMap));
      memberCount = userIds.length;
      promise = FirebaseCreatePrivateTopic(activeCommunityId, currentUserId, userIds, trimmedName, uploadedImageUrl)
    } else {
      promise = createCommunityChat(currentUserId, activeCommunityId, trimmedName, trimmedNotes, uploadedImageUrl)
    }
    await promise
        .then(threadId => {
          const threadType = isPrivate ? THREAD_TYPE_GROUP : THREAD_TYPE_COMMUNITY;
          logger.logEvent(AnalyticsEvent.CHAT_CREATED, {
            hasImage: !!uploadedImageUrl,
            hasNotes: !!trimmedNotes,
            memberCount,
            threadId,
            threadType,
          });
          const thread: Thread = {
            heroImageUrl: uploadedImageUrl,
            name: trimmedName,
            notes: trimmedNotes,
            threadType,
          };
          dispatch(ACTION_UPDATE_THREAD({
            threadId,
            communityId: activeCommunityId,
            thread,
          }));
          dispatch(IncrementUserInteraction(AnalyticsEvent.CHAT_CREATED));
          dispatch(ACTION_SET_THREAD_STATE({threadId, joined: true}));
          setState(State.SUCCESS);
          setTimeout(() => onCreate(threadId), 100);
        })
        .catch(e => {
          setState(State.FILLED);
          setTimeout(() => {
            setAlert(['Something went wrong!', 'Please try again or contact Heylo Support']);
          }, 10);
          console.warn(e);
        })
  };

  const publicSettingsOpacity = useRef(new Animated.Value(1)).current;
  const privateSettingsOpacity = useRef(new Animated.Value(0)).current;
  const animatedHorizontalPosition = useRef(new Animated.Value(0)).current;
  const [showMembersList, setShowMembersList] = useState(false);

  const toggleSettingsVisible = (showPrivateSettings: boolean) => {
    Keyboard.dismiss();
    if (showPrivateSettings) {
      setShowMembersList(true);
    }
    const duration = 300;
    Animated.parallel([
      Animated.timing(publicSettingsOpacity, {
        toValue: showPrivateSettings ? 0 : 1,
        duration: duration * 0.5,
        useNativeDriver: false,
      }),
      Animated.timing(privateSettingsOpacity, {
        toValue: showPrivateSettings ? 1 : 0,
        duration: duration * 0.5,
        useNativeDriver: false,
      }),
      Animated.timing(animatedHorizontalPosition, {
        toValue: showPrivateSettings ? -containerWidth : 0,
        duration,
        useNativeDriver: false,
      }),
    ]).start(() => {
      if (!showPrivateSettings) {
        setShowMembersList(false);
      }
    });
  };

  const uploadStarted = (imageUrl: Promise<string>) => {
    setIsUploadingImage(true);
    imageUrl.then(url => {
      setUploadedImageUrl(url);
      setIsUploadingImage(false);
    })
  };

  const [containerWidth, setContainerWidth] = useState(0);

  const onLayout = (e: LayoutChangeEvent) => {
    const {width} = e.nativeEvent.layout;
    setContainerWidth(width);
  };

  const scrollViewRef = useRef<InputScrollView>(null);
  const {height} = Dimensions.get('window');
  const keyboardOffset = (showMembersList && Platform.OS !== 'web') ? height * 0.5 : 0;

  return (
      <View
          onLayout={onLayout}
          style={{flex: 1}}
      >
        <MobileHeader
            left={Platform.OS === 'web' ? <CloseButton onPress={onCancel}/> : <BackButton/>}
            right={(state !== State.EMPTY && state !== State.SUCCESS)
                ? <ConfirmButton
                    onPress={createTopic}
                    showActivityIndicator={state === State.SUBMITTING}
                />
                : <View/>
            }
            title={'Create topic'}
        />
        <DropShadowScrollView
            ref={scrollViewRef}
            keyboardOffset={keyboardOffset}
        >
          <DismissKeyboard>
            <View style={{flex: 1}}>
              <View style={{alignSelf: 'center', width: '50%'}}>
                <EditableImage
                    aspectRatio={1}
                    callToAction={(Boolean(uploadedImageUrl) ? 'Edit' : 'Add') + ' topic photo'}
                    cropShape={'circle'}
                    imageUri={uploadedImageUrl}
                    onChange={uploadStarted}
                    storagePath={`communities/${activeCommunityId}/threads`}
                />
              </View>

              <View style={{
                marginHorizontal: StyleConstants.SPACING,
                marginTop: StyleConstants.SPACING,
              }}>
                <HeyloTextInput
                    dense
                    label={'Topic name'}
                    multiline={false}
                    onChangeText={setChatTitle}
                    placeholder={'e.g. Social planning'}
                    placeholderTextColor='#777'
                    underlineColorAndroid='transparent'
                    value={chatTitle}
                />

                <View style={styles.divider}/>

                <SwitchControl
                    onValueChange={value => {
                      if (scrollViewRef.current) {
                        console.log('scrolling!');
                        scrollViewRef.current.scrollTo({y: 0});
                      }
                      setIsPrivate(value);
                      toggleSettingsVisible(value);
                    }}
                    subtitle={'When a topic is private, it can only be viewed or joined by invitation.'}
                    title={'Make private'}
                    value={isPrivate}
                />
              </View>

              <View style={styles.divider}/>

              {containerWidth > 0 ? (
                  <View
                      style={{
                        flexDirection: 'row',
                        width: containerWidth * 2,
                      }}
                  >
                    <Animated.View style={{
                      left: animatedHorizontalPosition,
                      opacity: publicSettingsOpacity,
                      paddingHorizontal: StyleConstants.SPACING,
                      width: containerWidth,
                    }}>
                      <HeyloTextInput
                          autoCapitalize={'sentences'}
                          dense
                          label={'Topic description'}
                          multiline={true}
                          onChangeText={setNotes}
                          placeholder={'optional'}
                          placeholderTextColor='#777'
                          scrollEnabled={false}
                          underlineColorAndroid='transparent'
                          value={notes}
                      />
                    </Animated.View>

                    <Animated.View style={{
                      paddingBottom: height * 0.2,
                      display: showMembersList ? 'flex' : 'none',
                      opacity: privateSettingsOpacity,
                      left: animatedHorizontalPosition,
                      width: containerWidth,
                    }}>
                      <AddThreadMembersList onChange={onMembersChanged}/>
                    </Animated.View>
                  </View>
              ) : null}

              <HeyloDialog
                  body={alert?.[1] || ''}
                  onDismiss={() => setAlert(null)}
                  title={alert?.[0] || ''}
                  visible={Boolean(alert)}
              />

              <View style={{height: 50}}/>
            </View>
          </DismissKeyboard>
        </DropShadowScrollView>
      </View>
  );
});

const styles = StyleSheet.create({
  divider: {
    alignSelf: 'center',
    borderTopWidth: 1,
    borderColor: StyleVars.Colors.GreyLightest,
    height: 0,
    marginVertical: StyleConstants.SPACING,
    width: '90%',
  },
});