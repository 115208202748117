import React from 'react';
import {Button} from 'react-native-paper';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {Platform, ViewStyle} from 'react-native';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';

type Props = {
  busy?: boolean,
  color?: string,
  disabled?: boolean,
  icon?: string,
  onPress: () => void,
  label: string,
  mode?: 'contained' | 'outlined' | 'text'
  size?: 'small' | 'medium',
  style?: ViewStyle,
  width?: number | string,
};
export const HeyloButton = (props: Props) => {
  const palette = usePalette();
  const text = useTextStyles();

  const {
    busy,
    color,
    disabled,
    icon,
    mode = 'contained',
    onPress,
    label,
    size = 'medium',
    style = {},
    width,
  } = props;

  const isWeb = Platform.OS === 'web';

  const delayedOnPress = () => {
    // NB: Give the material ripple animation time to complete
    setTimeout(onPress, isWeb ? 200 : 1);
  }

  const isDisabled = busy || disabled;

  return (
      <Button
          color={color}
          contentStyle={{top: isWeb ? 1 : 0}}
          dark={mode === 'contained'}
          disabled={isDisabled}
          icon={
            icon
                ? ({color, size: iconSize}) => <MaterialCommunityIcon
                    color={color}
                    name={icon}
                    size={iconSize * 1.5}
                />
                : undefined
          }
          labelStyle={{
            ...(size === 'medium' ? text.body1 : text.body2),
            fontWeight: 'bold',
            letterSpacing: 0,
            marginLeft: icon ? 10 : undefined,
            marginVertical: size === 'medium' ? 9 : 4,
          }}
          loading={busy}
          mode={mode}
          onPress={delayedOnPress}
          style={{
            borderColor: mode === 'outlined'
                ? (isDisabled ? palette.grey.light : (color || palette.primary.main))
                : undefined,
            borderRadius: 100,
            borderWidth: mode === 'outlined' ? (size === 'medium' ? 2 : 1) : undefined,
            width: width || undefined,
            ...style,
          }}
      >
        {label}
      </Button>
  );
};