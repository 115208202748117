import {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  ActiveCommunityAnnouncementThreadSelector,
  JoinedActiveThreadsSelector,
  JoinedInactiveThreadsSelector,
  NotJoinedThreadsSelector,
} from '@heylo/shared/src/features/threads/Selectors';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {TopicsScreenLastViewedTimestampSelector} from '@heylo/shared/src/features/userEvents/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {IsThreadTypeCommunityBased} from '@heylo/shared/src/types/ThreadTypes';
import {
  AttachListenersForOnePrivateChat,
  AttachThreadListeners,
} from '@heylo/shared/src/features/threads/Firebase';
import {Thread} from '@heylo/shared/src/types/firebase-types';
import {selectChatInfoVisible} from '../userSettings/Selectors';
import {ActiveThreadIdSelector} from '../threads/SimpleSelectors';
import {RootState} from '../../services/redux/Redux';

export const useTopicsState = () => {

  const {
    activeThreadId,
    announcementThread,
    chatInfoVisible,
    communityId,
    joinedActiveTopics,
    joinedInactiveTopics,
    lastViewedTimestamp,
    notJoinedTopics,
    userId,
  } = useSelector((state: RootState) => ({
    activeThreadId: ActiveThreadIdSelector(state),
    // allChatNotificationsEnabled:
    // chatsNewMessageNotificationsEnabledSelector(state),
    announcementThread: ActiveCommunityAnnouncementThreadSelector(state),
    // appCanReceiveNotifications: appCanReceiveNotificationsSelector(state,
    // 'web'),
    chatInfoVisible: selectChatInfoVisible(state),
    communityId: SelectActiveCommunityId(state),
    // isCommunityOwner: SelectActiveUserIsCommunityOwner(state),
    joinedActiveTopics: JoinedActiveThreadsSelector(state),
    joinedInactiveTopics: JoinedInactiveThreadsSelector(state),
    lastViewedTimestamp: TopicsScreenLastViewedTimestampSelector(state),
    notJoinedTopics: NotJoinedThreadsSelector(state),
    // threadLastActivityTimestamps:
    // AllThreadLastActivityTimestampsSelector(state),
    userId: selectActiveUserId(state),
  }), shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    const announcementThreadId = announcementThread.threadId || '';
    if (announcementThreadId) {
      dispatch(AttachThreadListeners(userId, communityId, announcementThreadId));
    }
  }, [dispatch, announcementThread, userId, communityId]);

  useEffect(() => {

    for (const thread of joinedActiveTopics) {
      const {threadId = '', threadType = ''} = thread;
      if (IsThreadTypeCommunityBased(threadType)) {
        dispatch(AttachThreadListeners(userId, communityId, threadId));
      } else {
        dispatch(AttachListenersForOnePrivateChat(threadType, threadId, userId));
      }
    }
  }, [dispatch, joinedActiveTopics, userId, communityId]);

  const [stableLastViewedTimestamp] = useState(lastViewedTimestamp);
  const [newNotJoinedTopics, setNewNotJoinedTopics] = useState<ReadonlyArray<Thread>>([]);
  const [oldNotJoinedTopics, setOldNotJoinedTopics] = useState<ReadonlyArray<Thread>>([]);
  useEffect(() => {
    const newTopics: Thread[] = [];
    const oldTopics: Thread[] = [];
    notJoinedTopics.forEach(thread => {
      const {creationTimestamp = 0} = thread;
      if (creationTimestamp > stableLastViewedTimestamp) {
        newTopics.push(thread);
      } else {
        oldTopics.push(thread);
      }
    });
    setNewNotJoinedTopics(newTopics);
    setOldNotJoinedTopics(oldTopics);
  }, [notJoinedTopics, stableLastViewedTimestamp]);

  const totalThreadCount = joinedActiveTopics.length + joinedInactiveTopics.length + notJoinedTopics.length;

  return {
    activeThreadId,
    announcementThread,
    chatInfoVisible,
    joinedActiveTopics,
    joinedInactiveTopics,
    newNotJoinedTopics,
    oldNotJoinedTopics,
    totalThreadCount,
  };
};