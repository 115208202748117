import {Thread} from '../../types/firebase-types';
import {
  FirebaseDatabase,
  FirebaseServerTimestamp,
} from '@heylo/firebase-database';

export const FirebaseUpdateThread = (params: {
  communityId: string,
  imageUrl: string,
  name: string,
  notes: string,
  threadId: string,
  userId: string,
}) => {
  const {communityId, imageUrl, name, notes, threadId, userId} = params;
  const updates: Thread = {
    lastUpdateTimestamp: FirebaseServerTimestamp(),
    lastUpdateUserId: userId,
    heroImageUrl: imageUrl,
    name,
    notes,
  };
  return FirebaseDatabase().ref(`/communities/${communityId}/communityThreads/${threadId}`).update(updates);
};
