import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {CreateTopic} from '@heylo/components/src/features/topics/CreateTopic';
import {Modal, Portal} from 'react-native-paper';
import {useNavigationUtils} from '../navigation/useNavigationUtils';
import {useHistory} from 'react-router-dom';
import {Dimensions} from 'react-native';
import PerfectScrollbar from 'react-perfect-scrollbar';

type Props = {
  onClose: () => void,
  open: boolean,
};

export const CreateTopicDialog = (props: Props) => {
  const {onClose, open} = props;
  const history = useHistory();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));
  const nav = useNavigationUtils();

  const onCreate = (threadId: string) => {
    console.warn('todo: implement onCreate');
    setTimeout(
        () => history.push(nav.resolveUriForTopic(threadId)),
        100);
  }

  const {height} = Dimensions.get('window');

  return (
      <Portal>
        <Modal
            contentContainerStyle={{
              alignSelf: 'center',
              backgroundColor: 'white',
              borderRadius: theme.shape.borderRadius,
              maxWidth: 500,
              zIndex: 1300,
            }}
            onDismiss={onClose}
            visible={open}
        >
          <PerfectScrollbar>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: height * 0.75,
              maxHeight: height * 0.75,
            }}>
              <CreateTopic
                  onCancel={onClose}
                  onCreate={onCreate}
              />
            </div>
          </PerfectScrollbar>
        </Modal>
      </Portal>
  );
};