import React, {useState} from 'react';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {HeyloDialog, HeyloText} from 'lib/materialUi';
import {MembersFirebase} from '@heylo/shared/src/features/communityMembers/Firebase';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {shallowEqual, useSelector} from 'react-redux';

type Props = {
  userId: string,
}

export const ProspectiveMemberActions = (props: Props) => {
  const {userId} = props;

  const {communityId} = useSelector((state: RootState) => ({
    communityId: SelectActiveCommunityId(state),
  }), shallowEqual);
  const logger = useLoggingService();

  const [showIgnoreDialog, setShowIgnoreDialog] = useState(false);
  const toggleIgnoreDialog = () => {
    setShowIgnoreDialog(!showIgnoreDialog);
  };

  const [showAcceptDialog, setShowAcceptDialog] = useState(false);
  const toggleAcceptDialog = () => {
    setShowAcceptDialog(!showAcceptDialog);
  };

  const acceptMember = async () => {
    logger.logEvent(AnalyticsEvent.MEMBER_ACCEPT);
    MembersFirebase.ApproveProspectiveMember(communityId, userId);
  };

  const ignoreMember = () => {
    logger.logEvent(AnalyticsEvent.MEMBER_IGNORE);
    MembersFirebase.IgnoreProspectiveMember(communityId, userId);
  };

  return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color={'secondary'}
                    fullWidth={true}
                    onClick={toggleIgnoreDialog}
                    style={{borderRadius: '1rem'}}
                    variant={'outlined'}>
              IGNORE
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color={'primary'}
                    fullWidth={true}
                    onClick={toggleAcceptDialog}
                    style={{borderRadius: '1rem'}}
                    variant={'outlined'}>
              ACCEPT
            </Button>
          </Grid>
        </Grid>

        <HeyloDialog
            actions={[{
              label: 'Cancel',
            }, {
              label: 'Ignore',
              onClick: ignoreMember,
            }]}
            maxWidth={'xs'}
            onClose={toggleIgnoreDialog}
            open={showIgnoreDialog}
            title={'Confirm ignore?'}
        >
          <HeyloText variant={'body1'}>
            The user's request to join this group will be ignored, and they will NOT be notified.
          </HeyloText>
        </HeyloDialog>

        <HeyloDialog
            actions={[{
              label: 'Cancel',
            }, {
              label: 'Accept',
              onClick: acceptMember,
            }]}
            maxWidth={'xs'}
            onClose={toggleAcceptDialog}
            open={showAcceptDialog}
            title={'Confirm acceptance?'}
        >
          <HeyloText variant={'body1'}>
            You can always remove members from your group, if necessary.
          </HeyloText>
        </HeyloDialog>
      </>
  );
};