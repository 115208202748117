import {Modal, Portal} from 'react-native-paper';
import React, {useEffect} from 'react';

export const BlackoutModal = (
    {children, onDismiss, visible}: {
      children: JSX.Element | JSX.Element[],
      onDismiss: () => void,
      visible: boolean
    }) => {

  useEffect(() => {
    if (visible) {
      document.body.classList.add('stop-scrolling');
    } else {
      document.body.classList.remove('stop-scrolling');
    }
  }, [visible]);

  return (
      <Portal>
        <Modal
            contentContainerStyle={{
              backgroundColor: 'black',
              flex: 1,
              height: '100vh',
              position: 'absolute',
              top: 0,
              width: '100vw',
            }}
            dismissable={false}
            onDismiss={onDismiss}
            visible={visible}
        >
          {children}
        </Modal>
      </Portal>
  );
};