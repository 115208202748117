export type ImageUploadMetadata = {
  // Only used on web.
  blob?: string,
  contentType: string,
  height: number,
  // Only used on mobile.
  localUri?: string,
  width: number,
}

export type ImageUploadOptions = {
  cropShape: 'circle' | 'rect' | 'square' | 'freestyle' | 'none',
  maximumHeight: number,
  maximumWidth: number,
}

export class ImageUploader {
  onChooseImage = (useCamera: boolean, options: ImageUploadOptions): Promise<ImageUploadMetadata | null> => {
    console.warn('ImageUploader.onChooseImage not implemented');
    return Promise.resolve(null);
  };

  uploadImage = (storagePath: string, metadata: ImageUploadMetadata): Promise<string> => {
    return Promise.reject(new Error('ImageUploader.uploadImage not implemented'));
  };
}
