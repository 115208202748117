import React from 'react';
import {HeyloText} from '../../lib/materialUi';
import {useRouteMatch} from 'react-router-dom';
import {DonationResult, DonationResultType} from './DonationResult';

export const DonationResultScreen = () => {
  const isDonationSuccess = useRouteMatch('/donation/success');
  const state = isDonationSuccess ? DonationResultType.SUCCESS : DonationResultType.CANCELLED;
  return (
      <div className={'flexcolumn flexcenter'}
           style={{flex: 1}}>
        <DonationResult state={state}/>
        <div style={{marginTop: '6rem'}}>
          <HeyloText variant={'body1'}>
            You can close this window to go back to Heylo
          </HeyloText>
        </div>
      </div>
  );
};