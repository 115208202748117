import React from 'react';
import {Paragraph} from 'react-native-paper';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {TextProps, TextStyle} from 'react-native';

export const HeyloParagraph = (props: TextProps & {
  children: React.ReactNode,
  style?: TextStyle
}) => {
  const {
    children,
    style,
  } = props;
  const styles = useTextStyles();
  return <Paragraph style={style ?? styles.body1} {...props}>
    {children}
  </Paragraph>;
};