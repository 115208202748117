import React, {useContext, useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHeyloConfig} from '@heylo/shared/src/features/app/useHeyloConfig';
import {AuthFirebase} from '@heylo/shared/src/features/auth/Firebase';
import {PureEmailVerification} from '@heylo/components/src/features/marketplaces/PureEmailVerification';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectActiveMarketplace} from '@heylo/shared/src/features/marketplaces/Selectors';
import {EmailVerificationContext} from '@heylo/shared/src/features/auth/Slice';
import {selectDraftCommunityMarketplace} from '@heylo/shared/src/features/communityCreation/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {
  EmailVerificationStartContext,
  EmailVerificationStartState,
} from '../marketplaces/EmailVerificationStartContext';
import {FirebaseAuth} from '@heylo/firebase-database';
import {selectActiveCommunityProfile} from '@heylo/shared/src/features/communityProfiles/Selectors';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';

type Props = {
  context: EmailVerificationContext,
  onSuccess: () => void,
};
export const EmailVerification = (props: Props) => {
  const {
    context,
    onSuccess,
  } = props;
  const dispatch = useDispatch();
  const heyloConfig = useHeyloConfig();
  const [state, setState] = useContext(EmailVerificationStartContext);

  const {
    activeUserId,
    communityProfile,
    marketplace,
  } = useSelector((state: RootState) => ({
    activeUserId: selectActiveUserId(state),
    communityProfile: selectActiveCommunityProfile(state),
    marketplace: context === EmailVerificationContext.CREATE_COMMUNITY
        ? selectDraftCommunityMarketplace(state)
        : selectActiveMarketplace(state),
  }), shallowEqual);

  const logger = useLoggingService();
  useEffect(() => {
    switch (state) {
      case EmailVerificationStartState.ERROR_EMAIL:
        logger.logEvent(AnalyticsEvent.SUBMIT_ERROR, {error: 'email'});
        break;
      case EmailVerificationStartState.ERROR_NAME:
        logger.logEvent(AnalyticsEvent.SUBMIT_ERROR, {error: 'name'});
        break;
        case EmailVerificationStartState.ERROR_SEND:
        logger.logEvent(AnalyticsEvent.SUBMIT_ERROR, {error: 'send'});
        break;
    }
  }, [state]);

  const maybeSignInAnonymously = () => {
    const user = FirebaseAuth().currentUser;
    if (user && !user.isAnonymous) {
      setState(EmailVerificationStartState.SENDING_EMAIL_LINK);
      return;
    }
    FirebaseAuth().signInAnonymously()
        .then(credentials => {
          setState(EmailVerificationStartState.SENDING_EMAIL_LINK);
        })
        .catch(e => {
          console.warn('signInAnonymously failed', e.code);
        });
  };

  const onSendEmailLink = (email: string, fullName: string): Promise<void> => {
    return dispatch(AuthFirebase.SendSignInWithEmailLink({
      build: heyloConfig.BUILD_VARIANT_STRING,
      context,
      email,
      fullName,
    }))
        // @ts-ignore
        .then(() => {
          setState(EmailVerificationStartState.SUCCESS);
          const {emailVerificationDomain = ''} = marketplace || {};
          logger.logEvent(AnalyticsEvent.REGISTRATION_EMAIL_SENT, {email, emailVerificationDomain});
          onSuccess();
        });
  }

  return (
      <PureEmailVerification
          activeUserId={activeUserId}
          communityProfile={communityProfile}
          context={context}
          marketplace={marketplace}
          onSendEmailLink={onSendEmailLink}
          onSubmitPress={maybeSignInAnonymously}
      />
  );
};