import {NormalizeForSearch} from '@heylo/shared/src/util/strings';
import {UserProfile} from '@heylo/shared/src/types/firebase-types';

export const FilterMemberByName = (userProfile: UserProfile, filter: string) => {
  if (!filter) {
    return true;
  }
  return NormalizeForSearch(userProfile.fullName)
      .indexOf(NormalizeForSearch(filter)) >= 0;
};
