import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {
  SelectAuthState,
  selectAuthStateIsStable,
} from '@heylo/shared/src/features/auth/Selectors';
import {
  SignedInAction,
  SignedOutAction,
  SigningInAction,
  SigningOutAction,
} from '@heylo/shared/src/features/auth/Slice';
import {useAuthMachine} from '@heylo/shared/src/features/auth/useAuthMachine';
import {CleanUpAllSharedListeners} from '@heylo/shared/src/features/firebase/Listeners';
import {UserSettingsFirebase} from '@heylo/shared/src/features/userSettings/Firebase';
import {listenForUserProfile} from '@heylo/shared/src/features/userProfiles/Firebase';
import {Firebase} from 'features/firebase';
import {
  FirebaseUpdateCommunityImpressionMetrics,
  LoadUserImpressionMetrics,
  LoadUserInteractionMetrics,
} from '@heylo/shared/src/features/userEvents/Firebase';
import {UserDevicesFirebase} from '@heylo/shared/src/features/userDevices/Firebase';
import {StripeFirebase} from '@heylo/shared/src/features/stripe/Firebase';
import {AttachPrivateChatListeners} from '@heylo/shared/src/features/threads/Firebase';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {MarketplacesFirebase} from '@heylo/shared/src/features/marketplaces/Firebase';

const mapState = (state: RootState) => ({
  authState: SelectAuthState(state),
  authStateIsStable: selectAuthStateIsStable(state),
});
const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & {
  children: any | any[];
};

const handleSignIn = (userId: string, dispatch: any): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    try {
      const buildVariant = process.env.REACT_APP_BUILD_VARIANT ?? '';
      dispatch(UserSettingsFirebase.ListenForUserSettings(userId));
      dispatch(listenForUserProfile(userId));
      dispatch(LoadUserImpressionMetrics(userId));
      dispatch(LoadUserInteractionMetrics(userId));
      dispatch(UserDevicesFirebase.LoadUserDevices(userId));
      dispatch(StripeFirebase.UserListener(userId, buildVariant));
      dispatch(AttachPrivateChatListeners(userId));
      dispatch(MarketplacesFirebase.AttachMembershipListener(userId));
      resolve();
    } catch (e) {
      console.warn('error handling sign-in', e);
      reject(e);
    }
  });
};

const handleSignOut = (userId: string): Promise<void> => {
  return new Promise(async resolve => {
    CleanUpAllSharedListeners();
    // Must remove notification token before signing user out, to prevent
    // notifications from continuing to go to their device.
    await Promise.all([
      FirebaseUpdateCommunityImpressionMetrics(userId),
    ]);
    await Firebase.auth().signOut();
    resolve();
  });
};

export const AuthGate = connector((props: Props) => {
  const {
    authState,
    authStateIsStable,
  } = props;

  const dispatch = useDispatch();
  const [firebaseAuthLoaded, setFirebaseAuthLoaded] = useState(false);
  const [firebaseUserId, setFirebaseUserId] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(true);

  useEffect(() => {
    const unsubscribe = Firebase.auth().onAuthStateChanged(user => {
      const {
        isAnonymous = true,
        uid: userId = '',
      } = user || {};
      setFirebaseUserId(userId);
      setIsAnonymous(isAnonymous)
      setFirebaseAuthLoaded(true);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const state = useAuthMachine({
    authState,
    firebaseAuthLoaded,
    firebaseUserId,
    isAnonymous,
    signingInService: (userId: string) => handleSignIn(userId, dispatch),
    signingOutService: (userId: string) => handleSignOut(userId),
  });

  useEffect(() => {
    switch (state.value) {
      case 'signingIn':
        dispatch(SigningInAction());
        break;
      case 'signedIn':
        dispatch(SignedInAction(state.context.userId));
        break;
      case 'signingOut':
        dispatch(SigningOutAction());
        break;
      case 'signedOut':
        dispatch(SignedOutAction(state.context.anonymousUserId));
        break;
    }
  }, [state, dispatch]);

  return props.children;
});
