import {HeyloText} from '../../lib/materialUi';
import Confetti from 'react-dom-confetti';
import React, {useEffect, useState} from 'react';
import Grow from '@material-ui/core/Grow';
import BlockIcon from '@material-ui/icons/Block';

export enum DonationResultType {
  UNKNWOWN,
  SUCCESS,
  CANCELLED,
}

type Props = {
  state: DonationResultType,
}

export const DonationResult = (props: Props) => {
  const {state} = props;
  const [shootConfetti, setShootConfetti] = useState(false);

  useEffect(() => {
    setTimeout(() => setShootConfetti(true), 2000);
  }, []);

  if (state === DonationResultType.SUCCESS) {
    return (
        <Grow in={true} timeout={2500}>
          <div className={'flexcolumn flexcenter'}>
            <div style={{fontSize: '6rem'}}>
              🎉
            </div>
            <HeyloText align='center' variant={'h5'}>
              Thank you for donating!
            </HeyloText>
            <Confetti
                active={shootConfetti}
                config={{
                  angle: 110,
                  duration: 6000,
                  stagger: 1,
                }}
            />
            <Confetti
                active={shootConfetti}
                config={{
                  angle: 70,
                  duration: 6000,
                  stagger: 1,
                }}
            />
          </div>
        </Grow>
    );
  }
  if (state === DonationResultType.CANCELLED) {
    return (<div className={'flexcolumn flexcenter'}>
          <BlockIcon style={{
            height: '6rem',
            marginBottom: '2rem',
            width: '6rem',
          }}/>
          <HeyloText variant={'h5'}>
            The donation has been cancelled.
          </HeyloText>
        </div>
    );
  }

  return null;
};