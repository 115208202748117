import {StyleSheet, View} from 'react-native';
import React, {useState} from 'react';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AuthFirebase} from '@heylo/shared/src/features/auth/Firebase';
import {useHeyloConfig} from '@heylo/shared/src/features/app/useHeyloConfig';
import {ActivityIndicator, Text, useTheme} from 'react-native-paper';
import {HeyloDialog} from '@heylo/components/src/ui/dialog/HeyloDialog';

type Props = {
  email?: string,
};

enum State {
  IDLE,
  BUSY,
  DIALOG,
};

export const ResetPassword = React.memo((props: Props) => {
  const {email} = props;

  const logger = useLoggingService();
  const heyloConfig = useHeyloConfig();
  const theme = useTheme();

  const [state, setState] = useState(State.IDLE);

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const showDialog = (title: string, body: string) => {
    setState(State.DIALOG);
    setTitle(title);
    setBody(body);
  };

  const hideDialog = () => {
    setState(State.IDLE);
    setTitle('');
    setBody('');
  }

  const sendResetEmail = () => {
    if (!email) {
      showDialog('No email address', 'To reset your password, please start by entering your email address.');
      return;
    }

    setState(State.BUSY);
    AuthFirebase.SendPasswordResetEmail(email, heyloConfig.BUILD_VARIANT_STRING, logger)
        .then(() => {
          showDialog('Password reset email sent!', `Check "${email}" for an email from "Heylo Support"`);
        })
        .catch(e => {
          console.warn('failed to send password reset email', e);
          showDialog('Something went wrong', `We were not able to send you a password reset email to "${email}". Please try again or contact support@heylo.co for help`);
        });
  };

  return (
      <View style={styles.supportContainer}>
        <Text style={styles.supportLabel}>
          Forgot password?&nbsp;
        </Text>

        {state === State.IDLE && (
            <Text
                onPress={sendResetEmail}
                style={{
                  ...styles.textReset,
                  color: theme.colors.accent,
                }}
            >
              Reset
            </Text>
        )}

        {state === State.BUSY && (
            <ActivityIndicator/>
        )}

        <HeyloDialog
            body={body}
            onDismiss={hideDialog}
            title={title}
            visible={state === State.DIALOG}
        />

      </View>
  );
});

const styles = StyleSheet.create({
  supportContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    height: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    // marginTop: '0.5rem',
    marginTop: 10,
  },
  supportLabel: {
    // ...ESharedStyles.textGeneric,
    textAlign: 'center',
  },
  textReset: {
    // ...ESharedStyles.textLink,
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
});