import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export enum EmailVerificationContext {
  NONE,
  LOGIN,
  DISCOVER_MARKETPLACE,
  DISCOVER_COMMUNITY,
  CREATE_COMMUNITY,
}

export type AuthState = {
  activeUserId: string,
  // Can only be set while in authState `SIGNED_OUT`.
  anonymousUserId: string,
  authState: 'UNKNOWN' | 'USER_INITIATED_SIGN_OUT' | 'SIGNING_OUT' | 'SIGNED_OUT' | 'SIGNING_IN' | 'SIGNED_IN';
  // Set if a user is actively in the process of verifying an email address and
  // linking it to their account. Empty string otherwise.
  emailBeingVerified: string,
  // Whether or not `emailBeingVerified` has been verified yet.
  emailVerificationComplete: boolean,
  emailVerificationContext: EmailVerificationContext,
  emailVerificationFullName: string,
  // A client generated unique ID representing for each active email
  // verification attempt. Used to prevent session hijacking.
  emailVerificationSecret: string,
  // Set if the user has just opened a Firebase sign in link from an email
  signInWithEmailLink: string,
};

const initialState: AuthState = {
  activeUserId: '',
  anonymousUserId: '',
  authState: 'UNKNOWN',
  emailBeingVerified: '',
  emailVerificationComplete: false,
  emailVerificationContext: EmailVerificationContext.NONE,
  emailVerificationFullName: '',
  emailVerificationSecret: '',
  signInWithEmailLink: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    SignInWithEmailLinkOpened: (state, action: PayloadAction<string>) => {
      state.signInWithEmailLink = action.payload;
    },

    SignInWithEmailReset: (state) => {
      state.emailBeingVerified = '';
      state.emailVerificationComplete = false;
      state.emailVerificationContext = EmailVerificationContext.NONE;
      state.emailVerificationSecret = '';
      state.signInWithEmailLink = '';
    },

    SignInWithEmailStarted: (state, action: PayloadAction<{
      context: EmailVerificationContext,
      email: string,
      fullName: string,
      secret: string,
    }>) => {
      const {context, email, fullName, secret} = action.payload;
      state.emailBeingVerified = email;
      state.emailVerificationContext = context;
      state.emailVerificationFullName = fullName;
      state.emailVerificationSecret = secret;
    },

    SignInWithEmailComplete: (state) => {
      state.emailVerificationComplete = true;
    },

    SignedInAction: (state, action: PayloadAction<string>) => {
      state.activeUserId = action.payload;
      state.anonymousUserId = '';
      state.authState = 'SIGNED_IN';
    },
    SignedOutAction: (state, action: PayloadAction<string>) => {
      state.activeUserId = '';
      state.anonymousUserId = action.payload;
      state.authState = 'SIGNED_OUT';
    },
    SigningInAction: (state) => {
      state.authState = 'SIGNING_IN';
    },
    SigningOutAction: (state) => {
      state.authState = 'SIGNING_OUT';
    },
    UserInitiatedSignOutAction: (state) => {
      state.authState = 'USER_INITIATED_SIGN_OUT';
    },
  },
});

const {actions, reducer: AuthReducer} = authSlice;

const {
  SignInWithEmailComplete,
  SignInWithEmailLinkOpened,
  SignInWithEmailReset,
  SignInWithEmailStarted,
  SignedInAction,
  SignedOutAction,
  SigningInAction,
  SigningOutAction,
  UserInitiatedSignOutAction,
} = actions;

export {
  SignInWithEmailComplete,
  SignInWithEmailLinkOpened,
  SignInWithEmailReset,
  SignInWithEmailStarted,
  SignedInAction,
  SignedOutAction,
  SigningInAction,
  SigningOutAction,
  UserInitiatedSignOutAction,
  AuthReducer,
};