import React from 'react';
import {HeyloDialog} from '../../lib/materialUi';
import {DonationResult, DonationResultType} from './DonationResult';

type Props = {
  onClose: () => void,
  open: boolean
};

export const DonationSuccessDialog = (props: Props) => {
  const {onClose, open} = props;
  return (
      <HeyloDialog
          contentClassName={'no-scrollbars'}
          contentStyles={{height: 300, width: 300}}
          label={'Donation success!'}
          maxWidth={'xs'}
          onClose={onClose}
          open={open}
      >
        <div className={'flexcenter flexrow'} style={{height: '100%'}}>
          <DonationResult state={DonationResultType.SUCCESS}/>
        </div>
      </HeyloDialog>
  );
};