import {SelectActiveUserIsAdminInActiveCommunity} from '@heylo/shared/src/features/communityMembers/Selectors';
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {HeyloButton} from '../../lib/materialUi';
import EditIcon from '@material-ui/icons/Edit';
import {useHistory} from 'react-router-dom';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {useNavigationUtils} from '../navigation/useNavigationUtils';

export const EditCommunityInfoButton = () => {
  const history = useHistory();
  const nav = useNavigationUtils();

  const {
    isAdmin,
  } = useSelector((state: RootState) => ({
    isAdmin: SelectActiveUserIsAdminInActiveCommunity(state),
  }), shallowEqual);

  const navToEdit = () => {
    history.push(nav.resolveUriForEditGroupPage());
  };

  if (!isAdmin) {
    return null;
  }
  return (
      <>
        <HeyloButton
            color={'primary'}
            fullWidth={false}
            label={'Edit'}
            onClick={navToEdit}
            startIcon={<EditIcon/>}
        />
      </>
  );
};