import {assign, createMachine} from 'xstate';
import {StripeAccount} from '@heylo/shared/src/types/firebase-types';
import {
  ResolveStripeAccountStatus,
  StripeAccountStatus,
} from '@heylo/shared/src/features/stripe/Account';
import {FirebaseFunctions} from '@heylo/firebase-database';
import {StripeFirebase} from '@heylo/shared/src/features/stripe/Firebase';
import {GetHeyloConfig} from '@heylo/shared/src/config/App';


export interface CommunityPaymentSettingsContext {
  account?: StripeAccount,
  accountId: string,
  accountStatus: StripeAccountStatus,
  buildVariant: string,
  oauthUrl?: string,
  stripeDashboardLink?: string,
}

export type CommunityPaymentSettingsEvent =
    | {
  type: 'ACCOUNT_NOT_FOUND',
  communityId: string,
  communityName: string,
  userEmail: string,
  userFirstName: string,
  userLastName: string,
}
    | { type: 'ACCOUNT_UPDATED', account: StripeAccount }
    | { type: 'SYNC_ACCOUNT'};


type State =
    | {
  value: 'initializing';
  context: CommunityPaymentSettingsContext & {
    account: undefined,
    accountStatus: StripeAccountStatus.DOES_NOT_EXIST,
    oauthUrl: undefined,
    stripeDashboardLink: undefined,
  };
}
    | {
  value: 'accountNotLinked';
  context: CommunityPaymentSettingsContext & {
    account: undefined,
    accountStatus: StripeAccountStatus.DOES_NOT_EXIST,
    oauthUrl: string,
    stripeDashboardLink: undefined,
  };
}
    | {
  value: 'accountLinked';
  context: CommunityPaymentSettingsContext & {
    account: StripeAccount,
    oauthUrl: undefined,
    stripeDashboardLink: string,
  };
}
    | {
  value: 'error';
  context: CommunityPaymentSettingsContext & {
    accountStatus: StripeAccountStatus.ERROR,
  };
};


const handleAccountUpdated = async (context: CommunityPaymentSettingsContext, event: CommunityPaymentSettingsEvent)
    : Promise<string> => {
  const {accountId, buildVariant} = context;
  const response = await FirebaseFunctions().httpsCallable('stripeAccountDashboardLink')({
    accountId,
    build: buildVariant,
  })
  return response?.data ?? '';
}

const handleAccountNotLinked = async (context: CommunityPaymentSettingsContext, event: CommunityPaymentSettingsEvent)
    : Promise<string> => {
  if (event.type !== 'ACCOUNT_NOT_FOUND') {
    return '';
  }
  const {buildVariant} = context;
  const heyloConfig = GetHeyloConfig(buildVariant);
  const {communityId, communityName, userEmail, userFirstName, userLastName} = event;
  return await StripeFirebase.GenerateOAuthUrl({
    build: buildVariant,
    communityId,
    communityName,
    stripeConnectAccountId: heyloConfig.STRIPE_CONNECT_ACCOUNT_ID,
    userEmail,
    userFirstName,
    userLastName,
  });
};


export const CommunityPaymentSettingsMachine = createMachine<CommunityPaymentSettingsContext, CommunityPaymentSettingsEvent, State>({
  context: {
    accountId: '',
    accountStatus: StripeAccountStatus.DOES_NOT_EXIST,
    buildVariant: '',
  },
  id: 'communityPaymentSettings',
  initial: 'initializing',
  states: {

    initializing: {
      entry: 'syncStripeAccount',
      on: {
        ACCOUNT_NOT_FOUND: {
          target: 'accountNotLinked',
        },
        ACCOUNT_UPDATED: {
          target: 'accountLinked',
        },
      },
    },

    accountNotLinked: {
      entry: 'accountNotLinkedEntered',
      invoke: {
        id: 'accountNotLinked',
        src: handleAccountNotLinked,
        onDone: {
          actions: assign({oauthUrl: (ctx, event) => event.data}),
        },
        onError: {
          target: 'error',
        },
      },
      on: {
        ACCOUNT_UPDATED: {
          target: 'accountLinked',
        },
      },
    },

    accountLinked: {
      entry: 'accountLinkedEntered',
      invoke: {
        id: 'accountLinked',
        src: handleAccountUpdated,
        onDone: {
          actions: assign({stripeDashboardLink: (ctx, event) => event.data}),
        },
        onError: {
          target: 'error',
        },
      },
      on: {
        ACCOUNT_NOT_FOUND: {
          target: 'accountNotLinked',
        },
        ACCOUNT_UPDATED: {
          actions: 'accountLinkedEntered',
        },
        SYNC_ACCOUNT: {
          actions: 'syncStripeAccount',
        },
      },
    },

    error: {
      type: 'final',
    },

  },
}, {
  actions: {
    accountLinkedEntered: assign((ctx, event: CommunityPaymentSettingsEvent) => {
      if (event.type !== 'ACCOUNT_UPDATED') {
        return {};
      }
      const {account} = event;
      return {
        account,
        accountId: account.id,
        accountStatus: ResolveStripeAccountStatus(account),
      };
    }),

    accountNotLinkedEntered: assign((_ctx, _event) => {
      return {
        accountId: '',
        accountStatus: StripeAccountStatus.DOES_NOT_EXIST,
      };
    }),

    syncStripeAccount: (ctx) => {
      const {accountId, buildVariant} = ctx;
      if (accountId) {
        FirebaseFunctions().httpsCallable('stripeSyncAccount')({
          accountId,
          build: buildVariant,
        });
      }
    },
  },
});
