import {Thread} from '@heylo/shared/src/types/firebase-types';
import {CSSProperties, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {selectAllEvents} from '@heylo/shared/src/features/events/Selectors';
import {
  THREAD_TYPE_ANNOUNCEMENT,
  THREAD_TYPE_EVENT,
  THREAD_TYPE_GROUP,
} from '@heylo/shared/src/types/ThreadTypes';
import {ActiveCommunityTopicPhotoSelector} from '@heylo/shared/src/features/threads/Selectors';

export enum ThreadIconType {
  ANNOUNCEMENTS,
  PRIVATE,
  GENERAL,
  SOCIAL,
  DEFAULT,
};

type Props = {
  hasUnreadMessages?: boolean,
  imageScaling: string[],
  thread: Thread,
};

export const useThreadImageState = (props: Props) => {
  const {
    hasUnreadMessages,
    imageScaling,
    thread,
  } = props;

  console.count('useThreadImageState');

  const {
    threadId = '',
    threadSubType,
    threadType,
  } = thread;

  const {eventPhoto, threadPhotos} = useSelector((state: any) => {
    return {
      eventPhoto: threadType === THREAD_TYPE_EVENT ? selectAllEvents(state)[threadId]?.image : '',
      threadPhotos: ActiveCommunityTopicPhotoSelector(state)[threadId],
    }
  }, shallowEqual);

  const photos = useMemo(
      () => eventPhoto ? [eventPhoto] : threadPhotos,
      [eventPhoto, threadPhotos])

  const [backgroundColor, setBackgroundColor] = useState('hsl(0, 0%, 70%)');

  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [imageStyles, setImageStyles] = useState<CSSProperties[]>([]);

  useEffect(() => {
    const finalImages = photos?.slice(0, 3) || [];
    setImageUrls(finalImages);
    const additionalStyles = [
      [],
      [{bottom: 0, left: 0}, {top: 0, right: 0}],
      [{bottom: 0, left: 0}, {top: 0}, {bottom: 0, right: 0}],
    ];
    const numImages = finalImages.length;
    const styleIndex = numImages - 1;
    const imageScale = imageScaling[styleIndex];
    const styles: CSSProperties[] = [];
    for (let i = 0; i < numImages; i++) {
      styles.push({
        ...additionalStyles[styleIndex]?.[i] || {},
        height: imageScale,
        width: imageScale,
      });
    }
    setImageStyles(styles);
  }, [photos]);

  const [icon, setIcon] = useState(ThreadIconType.DEFAULT);
  useEffect(() => {
    switch (threadType) {
      case THREAD_TYPE_ANNOUNCEMENT:
        setIcon(ThreadIconType.ANNOUNCEMENTS);
        if (hasUnreadMessages) {
          setBackgroundColor('hsl(153, 60%, 49%)');
        }
        return;
      case THREAD_TYPE_GROUP:
        setIcon(ThreadIconType.PRIVATE);
        return;
    }
    switch (threadSubType) {
      case "general":
        setIcon(ThreadIconType.GENERAL);
        return;
      case "social":
        setIcon(ThreadIconType.SOCIAL);
        return;
    }
    setIcon(ThreadIconType.DEFAULT);
  }, [threadType, threadSubType, hasUnreadMessages]);

  return {
    backgroundColor,
    imageUrls,
    imageStyles,
    icon,
  };
};