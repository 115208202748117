import React, {useContext, useEffect, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  SectionList,
  SectionListData,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {SelectUserProfiles} from '@heylo/shared/src/features/userProfiles/Selectors';
import {
  ActiveThreadMembersSelector,
  threadJoinTimestampForUserSelector,
} from '@heylo/shared/src/features/threads/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {ChatScreenContext} from '@heylo/components/src/features/chat/ChatScreenContext';
import {MemberListItemCompact} from '@heylo/components/src/features/userProfile/MemberListItemCompact';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {threadInfoLastViewedTimestampSelector} from '@heylo/shared/src/features/threads/SimpleSelectors';
import {HeyloButton} from '../../ui/button/HeyloButton';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';

type OwnProps = {
  threadId: string,
  threadType: string,
};

const mapState = (state: RootState, ownProps: OwnProps) => {
  const {threadId} = ownProps;
  const userId = selectActiveUserId(state) || '';
  return {
    lastViewedTimestamp: threadInfoLastViewedTimestampSelector(state)[threadId] || 0,
    userThreadJoinTimestamp: threadJoinTimestampForUserSelector(state, threadId, userId),
    threadMembers: ActiveThreadMembersSelector(state),
    userId,
    userProfiles: SelectUserProfiles(state),
  };
};

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & OwnProps;

const {height, width} = Dimensions.get('window');
const MAX_HEIGHT = height * 0.25;

export const ChatMembers = connector((props: Props) => {
  const {
    lastViewedTimestamp,
    userThreadJoinTimestamp,
    threadMembers,
    userId,
    userProfiles,
  } = props;

  const [newMemberUserIds, setNewMemberUserIds] = useState<string[]>([]);
  const [oldMemberUserIds, setOldMemberUserIds] = useState<string[]>([]);

  const [collapsed, setCollapsed] = useState(true);
  const [isExpandable, setIsExpandable] = useState(false);
  const [totalMembers, setTotalMembers] = useState(0);

  useEffect(() => {
    const newMembers: string[] = [];
    let oldMembers: string[] = [];
    if (!userThreadJoinTimestamp) {
      // If user is not a member of the thread, then no members are considered
      // "new" to them.
      oldMembers = Object.keys(threadMembers);
    } else {
      const oneWeekAgo = moment().subtract(7, 'days').startOf('day').valueOf();
      const newMemberThreshold = Math.max(oneWeekAgo, userThreadJoinTimestamp, lastViewedTimestamp);
      for (const [userId, member] of Object.entries(threadMembers)) {
        if ((member.joinTimestamp ?? 0) > newMemberThreshold) {
          newMembers.push(userId);
        } else {
          oldMembers.push(userId);
        }
      }
    }
    setTotalMembers(newMembers.length + oldMembers.length);
    setNewMemberUserIds(
        _.sortBy(newMembers, userId => userProfiles[userId]?.fullName?.toLocaleLowerCase() || ''));
    let sortedOldMembers = _.sortBy(oldMembers, userId => userProfiles[userId]?.fullName?.toLocaleLowerCase() || '');
    if (collapsed && oldMembers.length > 5) {
      sortedOldMembers = sortedOldMembers.slice(0, 5);
      setIsExpandable(true);
    }
    setOldMemberUserIds(sortedOldMembers);
  }, [userId, userThreadJoinTimestamp, threadMembers, userProfiles, collapsed]);

  const {onUserPress} = useContext(ChatScreenContext);
  const renderMember = ({item, section}: { item: string, section: SectionListData<string> }) => {
    const {id} = section;
    return <MemberListItemCompact
        userId={item}
        isNew={id === 'new'}
        onPress={() => onUserPress(item)}
    />;
  };

  const palette = usePalette();
  const text = useTextStyles();

  return (
      <SafeAreaView>
        <View style={styles.containerLabel}>
          <Text style={text.subtitle1}>
            MEMBERS ({totalMembers})
          </Text>
        </View>

        <SectionList
            keyExtractor={(item) => item}
            sections={[
              {id: 'new', data: newMemberUserIds},
              {id: 'old', data: oldMemberUserIds},
            ]}
            renderItem={renderMember}
            scrollEnabled={false}
        />
        {(collapsed && isExpandable) ? (
            <>
              <View style={styles.containerButton}>
                <HeyloButton
                    color={palette.grey.main}
                    label={'show all'}
                    mode={'outlined'}
                    onPress={() => setCollapsed(false)}
                    size={'small'}
                />
              </View>
            </>
        ) : null}
      </SafeAreaView>
  );
});

const styles = StyleSheet.create({
  containerButton: {
    alignItems: 'center',
  },
  containerGradient: {
    bottom: 0,
    height: 30,
    position: 'absolute',
    width: '100%',
  },
  containerLabel: {
    marginBottom: StyleConstants.SPACING,
  },
});