import React, {useContext, useEffect, useMemo, useRef} from 'react';
import {
  Animated,
  Dimensions,
  FlatList,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {useSelector} from 'react-redux';
import {GetReactionLabel} from '@heylo/shared/src/types/reactions';
import {
  makeReactionListForMessageSelector,
  makeReactionListForPhotoSelector,
} from '@heylo/shared/src/features/reactions/Selectors';
import {ChatScreenContext} from './ChatScreenContext';
import {StyleVars} from '../../styles/StyleVars';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {MemberListItemCompact} from '../userProfile/MemberListItemCompact';

const REACTION_LIST_ITEM_HEIGHT = 50;

type Props = {
  darkMode?: boolean,
  messageId?: string,
  photoId?: string,
  visible: boolean,
};

export const ReactionsList = (props: Props) => {
  const {
    darkMode,
    messageId,
    photoId,
    visible,
  } = props;
  const {width, height} = Dimensions.get('window');
  const {onUserPress} = useContext(ChatScreenContext);

  const selectReactionList = useMemo(() => {
    return messageId
        ? makeReactionListForMessageSelector(messageId || '')
        : makeReactionListForPhotoSelector(photoId || '');
  }, [messageId, photoId])

  const reactions = useSelector(selectReactionList);

  const renderReaction = ({item}: { item: [string, number] }) => {
    const [userId, reactionType] = item;
    return (
        <View style={styles.reactionItemContainer}>
          <MemberListItemCompact
              darkMode={darkMode}
              onPress={() => onUserPress(userId)}
              userId={userId}
          />
          <Text style={{color: '#000f', fontSize: 30}}>
            {GetReactionLabel(reactionType)}
          </Text>
        </View>
    );
  };

  const text = useTextStyles();
  const listHeight = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const duration = 300;
    Animated.timing(listHeight, {
      toValue: visible
          ? Math.min(height * 0.5, (reactions.length + 1) * REACTION_LIST_ITEM_HEIGHT + 100)
          : 0,
      duration,
      useNativeDriver: false,
    }).start();
  }, [visible]);

  return (
      <Animated.View
          style={[styles.reactionsListContainer, {
            backgroundColor: darkMode ? 'hsl(0, 0%, 10%)' : StyleVars.Colors.White,
            borderColor: darkMode ? StyleVars.Colors.GreyDarkest : StyleVars.Colors.GreyLightest,
            borderTopWidth: visible ? 1 : 0,
            height: listHeight,
          }]}
      >
        <Text
            style={[text.h6, {
              color: darkMode ? StyleVars.Colors.White : StyleVars.Colors.Black,
              marginTop: 10,
              textAlign: 'center',
            }]}>
          Reactions
        </Text>
        <FlatList
            data={reactions}
            keyExtractor={item => item[0]}
            renderItem={renderReaction}
        />
      </Animated.View>
  );
};

const styles = StyleSheet.create({
  reactionsListContainer: {
    bottom: 0,
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
  },
  reactionItemContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    height: REACTION_LIST_ITEM_HEIGHT,
    justifyContent: 'space-between',
    marginHorizontal: 20,
  },
});