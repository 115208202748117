import React, {useState} from 'react';
import {ActionButton, RECTANGLE} from './ActionButton';
import ShareIcon from '@material-ui/icons/Reply';
import {HeyloText} from '../../lib/materialUi';
import Popover from '@material-ui/core/Popover';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';

type Props = {
  variant: 'square' | 'rectangle',
};

export const ShareButton = (props: Props) => {
  const {variant} = props;

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const logger = useLoggingService();

  const copyUrl = (e: any) => {
    setPopoverAnchorEl(e.currentTarget);
    setTimeout(() => setPopoverAnchorEl(null), 2000);
    navigator.clipboard.writeText(window.location.href);
    logger.logEvent(AnalyticsEvent.EVENTS_SHARE_SUCCESS);
  };

  return (
    <>
      <ActionButton
        icon={<ShareIcon className={'fa-flip-horizontal'}/>}
        label={'Share'}
        onClickHandler={copyUrl}
        variant={variant}
      />
      <Popover
          anchorEl={popoverAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={!!popoverAnchorEl}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
      >
        <div style={{padding: '1rem'}}>
          <HeyloText variant={'body1'}>
            Event link copied to clipboard! 👍
          </HeyloText>
        </div>
      </Popover>
    </>
  );
};
