import React, {CSSProperties} from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

interface Props {
  className?: string,
  error?: boolean,
  helperText?: string,
  IconStart?: React.ElementType,
  id?: string,
  inputProps?: any,
  label: string,
  onChange: (value: string) => void,
  margin?: "dense",
  maxLength?: number,
  multiline?: boolean,
  placeholder?: string,
  required?: boolean,
  rows?: number,
  rowsMax?: number,
  style?: CSSProperties,
  value: string,
  variant?: 'filled' | 'outlined' | 'standard',
}

export const HeyloTextInput = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const {
    className,
    error,
    helperText,
    IconStart,
    id,
    inputProps,
    label,
    maxLength,
    margin,
    multiline,
    onChange,
    placeholder,
    required,
    rows,
    rowsMax,
    style,
    value,
    variant = 'outlined',
  } = props;
  return (
      <TextField
          className={className}
          error={error}
          fullWidth={true}
          helperText={helperText}
          id={id}
          inputProps={{
            ...inputProps,
            maxLength,
          }}
          InputProps={IconStart ? {
            startAdornment: (
                <InputAdornment position="start">
                  <IconStart/>
                </InputAdornment>
            ),
          } : {}}
          inputRef={ref}
          label={label}
          margin={margin}
          multiline={multiline}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          required={required}
          rows={rows}
          rowsMax={rowsMax}
          style={style}
          value={value}
          variant={variant}
      />
  );
});