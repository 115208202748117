import React from 'react';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {
  FirebaseDeleteConversation,
  FirebaseLeaveConversation,
} from '@heylo/shared/src/features/threads/Firebase';
import {HeyloDialog} from '@heylo/components/src/ui/dialog/HeyloDialog';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {Thread} from '@heylo/shared/src/types/firebase-types';

export const LeaveChatAlert = (props: {
  onDismiss: () => void,
  onLeave?: () => void,
  thread: Thread,
  visible: boolean,
}) => {
  const {
    onDismiss,
    onLeave,
    thread,
    visible,
  } = props;

  const {
    userId,
  } = useSelector((state: RootState) => ({
    userId: selectActiveUserId(state) || '',
  }), shallowEqual);

  const {communityId = '', threadId = '', threadType = ''} = thread;
  const logger = useLoggingService();

  return (
      <HeyloDialog
          actions={[
            {
              color: 'black',
              label: 'Cancel',
              onPress: onDismiss,
            },
            {
              color: 'red',
              label: 'Leave',
              onPress: async () => {
                FirebaseLeaveConversation({
                  communityId,
                  onLeave,
                  threadId,
                  threadType,
                  userId,
                });
                logger.logEvent(AnalyticsEvent.CHAT_LEFT, {threadType});
              },
            },
          ]}
          body={'You will no longer receive notifications for this conversation.'}
          onDismiss={onDismiss}
          title={'Leave this topic?'}
          visible={visible}
      />
  );
};

export const DeleteConversationAlert = (props: {
  onDelete?: () => void,
  onDismiss: () => void,
  thread: Thread,
  visible: boolean,
}) => {
  const {
    onDelete,
    onDismiss,
    thread,
    visible,
  } = props;

  const {
    userId,
  } = useSelector((state: RootState) => ({
    userId: selectActiveUserId(state) || '',
  }), shallowEqual);

  const {communityId = '', threadId = '', threadType = ''} = thread;
  const logger = useLoggingService();
  return (
      <HeyloDialog
          actions={[
            {
              color: 'black',
              label: 'Cancel',
              onPress: onDismiss,
            },
            {
              color: 'red',
              label: 'Delete',
              onPress: async () => {
                FirebaseDeleteConversation({
                  communityId,
                  onDelete,
                  threadId,
                  threadType,
                  userId,
                });
                logger.logEvent(AnalyticsEvent.CHAT_THREAD_DELETE, {threadType});
              },
            },
          ]}
          body={'Members will no longer see this topic, and all messages and pictures will be lost.'}
          onDismiss={onDismiss}
          title={'Are you sure you want to delete this topic?'}
          visible={visible}
      />
  );
};