import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {
  selectActiveUserAboutMe,
  selectActiveUserFullName,
  selectActiveUserInterests,
  selectActiveUserProfilePhotoUri,
} from '@heylo/shared/src/features/userSettings/Selectors';
import {
  HeyloButton,
  HeyloDialog,
  HeyloText,
  HeyloTextInput,
} from 'lib/materialUi';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {UserSettingsFirebase} from '@heylo/shared/src/features/userSettings/Firebase';
import {useHistory} from 'react-router-dom';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {UserInitiatedSignOutAction} from '@heylo/shared/src/features/auth/Slice';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '@material-ui/core/Link';
import {UserSettings as UserSettingsType} from '@heylo/shared/src/types/firebase-types';
import Alert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';
import {IsValidFullName} from '@heylo/shared/src/util/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import {ScreenHeader} from '../ui/ScreenHeader';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {ActiveCommunityUpdated} from '@heylo/shared/src/features/communities/Slice';
import {EditableImage} from '@heylo/components/src/ui/image/EditableImage';
import {ScrollView, View} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

const mapState = (state: RootState) => {
  return {
    activeUserId: selectActiveUserId(state),
    initialAboutMe: selectActiveUserAboutMe(state),
    initialFullName: selectActiveUserFullName(state),
    initialInterests: selectActiveUserInterests(state),
    initialProfilePhotoUri: selectActiveUserProfilePhotoUri(state),
  };
};

const connector = connect(mapState, {
  dispatchSignOut: UserInitiatedSignOutAction,
});

type Props = ConnectedProps<typeof connector>;

enum FormState {
  INTIIAL,
  SUBMITTED,
  ERROR,
}

export const PureUserSettings = (props: Props) => {
  const {
    activeUserId,
    dispatchSignOut,
    initialAboutMe,
    initialFullName,
    initialInterests,
    initialProfilePhotoUri,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const logger = useLoggingService();

  const [aboutMe, setAboutMe] = useState('');
  const [interests, setInterests] = useState('');
  const [name, setName] = useState('');
  const [profilePhotoUri, setProfilePhotoUri] = useState('');
  const [showSignOutDialog, setShowSignOutDialog] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    dispatch(ActiveCommunityUpdated(''));
  }, []);

  useEffect(() => {
    setAboutMe(initialAboutMe);
    setInterests(initialInterests);
    setName(initialFullName);
    setProfilePhotoUri(initialProfilePhotoUri);
  }, [initialAboutMe, initialFullName, initialInterests, initialProfilePhotoUri]);

  useEffect(() => {
    if (initialAboutMe !== aboutMe
        || initialFullName !== name
        || initialInterests !== interests) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  }, [
    initialAboutMe, initialFullName, initialInterests,
    aboutMe, interests, name,
  ]);

  const handleNewProfilePhoto = async (downloadUri: Promise<string>) => {
    const uri = await downloadUri;
    setProfilePhotoUri(uri);
    UserSettingsFirebase.UpdateUserSettings(activeUserId, {avatar: uri});
  };

  const handleSignOut = async () => {
    setShowSignOutDialog(false);
    logger.logEvent(AnalyticsEvent.ACCOUNT_SIGN_OUT);
    dispatchSignOut();
    setTimeout(() => history.replace('/'), 20);
  };

  const [formState, setFormState] = useState(FormState.INTIIAL);
  const handleSaveClick = () => {
    setFormState(FormState.SUBMITTED);
    const trimmedName = name.trim();
    if (!trimmedName || !IsValidFullName(trimmedName)) {
      setFormState(FormState.ERROR);
      return;
    }
    const settings: UserSettingsType = {
      bio: aboutMe.trim(),
      fullName: name.trim(),
      goals: interests.trim(),
    };
    logger.logEvent(AnalyticsEvent.PROFILE_UPDATE);
    UserSettingsFirebase.UpdateUserSettings(activeUserId, settings)
        .then(() => setFormState(FormState.INTIIAL));
  };

  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();
  return (
      <ScrollView
          contentContainerStyle={{minHeight: '100%'}}
          style={{height: '100%'}}
      >
        <div>
          {matchXs && (
              <ScreenHeader title={'Settings'}/>
          )}
          <Container maxWidth={'sm'}>
            <View style={{
              alignItems: 'center',
              marginTop: StyleConstants.SPACING,
              width: '100%',
            }}>
              <View style={{minWidth: 250, width: '50%'}}>
                <EditableImage
                    aspectRatio={1}
                    alt={'Your profile'}
                    callToAction={(profilePhotoUri ? 'Edit' : 'Add') + ' profile photo'}
                    cropShape={'circle'}
                    imageUri={profilePhotoUri}
                    onChange={handleNewProfilePhoto}
                    storagePath={'avatars'}
                />
              </View>
            </View>

            <div className={classes.row}>
              <HeyloText variant={'h5'}>
                My profile
              </HeyloText>
            </div>

            <div className={classes.row}>
              <HeyloTextInput
                  error={formState === FormState.ERROR}
                  helperText={formState === FormState.ERROR ? 'Must not contain any special characters' : ''}
                  label={'Full name'}
                  onChange={setName}
                  value={name}/>
            </div>

            <div className={classes.row}>
              <HeyloTextInput
                  label={'About me'}
                  multiline
                  onChange={setAboutMe}
                  value={aboutMe}
              />
            </div>

            <div className={classes.row}>
              <HeyloTextInput
                  label={'Interests'}
                  multiline
                  onChange={setInterests}
                  value={interests}
              />
            </div>

            {formState === FormState.ERROR && (
                <Alert severity={'error'}>
                  Something's not quite right. Please double check the fields above.
                </Alert>
            )}

            {changesMade && (
                <div className={classes.row}
                     style={{display: 'flex', justifyContent: 'center'}}>
                  <HeyloButton
                      busy={formState === FormState.SUBMITTED}
                      color={'primary'}
                      fullWidth={false}
                      label={'Save'}
                      onClick={handleSaveClick}
                      startIcon={<SaveIcon/>}
                  />
                </div>
            )}


            <div className={classes.row}>
              <HeyloText variant={'h6'}>
                <Link color={'secondary'} href={'https://join.heylo.co/terms'}
                      target={'_blank'}
                      rel="noopener noreferrer">
                  Terms of Service
                </Link>
              </HeyloText>
            </div>

            <div className={classes.row}>
              <HeyloText variant={'h6'}>
                <Link color={'secondary'} href={'https://join.heylo.co/privacy'}
                      target={'_blank'}
                      rel="noopener noreferrer">
                  Privacy Policy
                </Link>
              </HeyloText>
            </div>

            <div className={classes.row}>
              <HeyloText variant={'h6'}>
                <Link color={'secondary'} href={'#'}
                      onClick={() => setShowSignOutDialog(true)}>
                  Sign out
                </Link>
              </HeyloText>
              <HeyloDialog
                  actions={[{
                    label: 'No',
                    onClick: () => setShowSignOutDialog(false),
                  }, {
                    color: 'secondary',
                    label: 'Yes',
                    onClick: handleSignOut,
                  }]}
                  closeButton={false}
                  label={'sign out'}
                  maxWidth={'xs'}
                  onClose={() => setShowSignOutDialog(false)}
                  open={showSignOutDialog}
                  title={'Sign out of Heylo?'}
              />
            </div>
          </Container>
        </div>
      </ScrollView>
  );
};

export const UserSettings = connector(PureUserSettings);

const useStyles = makeStyles(theme => ({
  row: {
    margin: '2rem 0',
  },
}));