import React, {useCallback, useMemo, useState} from 'react';
import {Image, LayoutChangeEvent, Platform, View} from 'react-native';
import {Props} from './HeyloImage';
import {ActivityIndicator} from 'react-native-paper';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';

enum State {
  INIT,
  LOADING,
  SUCCESS,
}

export const HeyloImage = React.memo((props: Props) => {
  console.count('count HeyloImage');

  const palette = usePalette();

  const {
    aspectRatio = 0,
    showLoadingIndicator = false,
    source = {},
    style = {},
    uri = '',
    ...rest
  } = props;

  const finalStyle = useMemo(() => {
    return Object.assign(
        {...style},
        // NB: Hack required to get images with aspect ratio to show on web
        aspectRatio > 0 ? {height: '100%', width: '100%'} : {},
    );
  }, []);

  const [state, setState] = useState(State.INIT);

  const [dimensions, setDimensions] = useState<{ height: number, width: number } | null>(null);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    console.count('HeyloImage: onLayout');
    const {height, width} = event.nativeEvent.layout;
    if (height > 0 && width > 0) {
      setDimensions({height, width});
    }
  }, []);

  const setLoaded = useCallback(() => {
    console.count('HeyloImage: loaded');
    setState(State.SUCCESS);
  }, []);

  const setLoading = useCallback(() => {
    console.count('HeyloImage: start');
    if (state === State.SUCCESS) {
      return;
    }
    setState(State.LOADING);
  }, []);

  const showSpinner = false;
  // const showSpinner = showLoadingIndicator && state === State.LOADING &&
  // dimensions && dimensions.height > 50 && dimensions.width > 50;
  const hasFixedAspectRatio = aspectRatio > 0;

  const imageElement = (
      <>
        <Image
            onLayout={(showLoadingIndicator && !dimensions) ? onLayout : undefined}
            // onLoad={showLoadingIndicator ? setLoaded : undefined}
            // onLoadStart={showLoadingIndicator ? setLoading : undefined}
            source={uri ? {uri} : source}
            {...rest}
            style={finalStyle}
        />
        {showSpinner && (
            <ActivityIndicator
                color={palette.grey.main}
                style={{
                  left: dimensions!.width / 2 - 12,
                  position: 'absolute',
                  top: dimensions!.height / 2 - (Platform.OS === 'web' ? 12 : 0),
                }}
            />
        )}
      </>
  );

  if (hasFixedAspectRatio) {
    return (
        <View style={hasFixedAspectRatio
            ? {
              paddingTop: `${(1 / aspectRatio) * 100}%`,
              width: '100%',
            }
            : {}}>
          <View style={hasFixedAspectRatio
              ? {
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
              }
              : {}}>
            {imageElement}
          </View>
        </View>
    );
  }
  return imageElement;
});

// @ts-ignore
HeyloImage.whyDidYouRender = true;