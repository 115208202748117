import {createSelector} from 'reselect';
import _ from 'lodash';
import {
  CommunityProfile,
  UserProfile,
} from '@heylo/shared/src/types/firebase-types';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {
  SelectActiveCommunityId,
  SelectCommunityMetadata,
} from '@heylo/shared/src/features/communities/Selectors';
import {SelectUserProfiles} from '@heylo/shared/src/features/userProfiles/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectProspectiveCommunityIdsSet} from '../userSettings/Selectors';

export const selectAllCommunityProfiles = (state: RootState) => state.communityProfiles.FIELD_COMMUNITY_PROFILES;

export const selectProspectiveCommunityProfiles = createSelector(
    [selectAllCommunityProfiles, selectProspectiveCommunityIdsSet],
    (profiles, prospectiveCommunityIds)
        : CommunityProfile[] => {
      return _.sortBy(
          Object.values(profiles || {}).filter(profile => prospectiveCommunityIds.has(profile.communityId || '')),
          profile => profile.communityName);
    });

export const selectJoinedCommunityProfiles = createSelector(
    [selectAllCommunityProfiles, selectProspectiveCommunityIdsSet, SelectCommunityMetadata],
    (profiles, prospectiveCommunityIds, communityMetadata)
        : CommunityProfile[] => {
      return _.sortBy(
          Object.values(profiles || {})
              .filter(profile => communityMetadata[profile.communityId || ''] && !prospectiveCommunityIds.has(profile!.communityId || '')),
          profile => profile.communityName);
    });

export const selectActiveCommunityProfile = createSelector(
    [selectAllCommunityProfiles, SelectActiveCommunityId],
    (profiles, activeCommunityId) => {
      return profiles[activeCommunityId] ?? {};
    });

export const selectActiveCommunityPageAmbassadors = createSelector(
    [selectActiveCommunityProfile, SelectUserProfiles],
    (communityProfile, allUserProfiles): UserProfile[] => {
      return Object.keys(communityProfile.ambassadorUserIds ?? {})
          .map(userId => allUserProfiles[userId] ?? null)
          .filter(profile => !!profile);
    });

export const selectActiveUserHasCommunityProfileAdminPermissions = createSelector(
    [selectActiveCommunityProfile, selectActiveUserId],
    (profile, userId) => {
      return (profile.leadUserIds ?? {})[userId || ''] ?? false;
    });
