import React from 'react';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {HeyloButton} from '../button/HeyloButton';

type Props = {
  onPress: () => void,
};

export const SkipButton = ({onPress}: Props) => {
  const palette = usePalette();
  return (
      <HeyloButton
          color={palette.grey.main}
          label={'SKIP'}
          mode={'text'}
          onPress={onPress}
      />
  );
};