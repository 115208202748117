import React, {useEffect, useState} from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {NAV_ITEMS} from 'features/TopNav/NavItems';
import {useHistory, useLocation} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Colors} from 'styles/RootStyles';
import {shallowEqual, useSelector} from 'react-redux';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {selectHasHeyloMobileApp} from '@heylo/shared/src/features/userDevices/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {GreenBadge} from '@heylo/components/src/ui/badge/GreenBadge';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {
  EventsBottomTabBadgeCountSelector,
  MembersBottomTabBadgeCountSelector,
} from '@heylo/shared/src/features/navigation/Selectors';
import {TotalUnreadTopicsSelector} from '@heylo/shared/src/features/threads/Selectors';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fffe',
    borderTop: 'solid #c4c4c4 1px',
    bottom: 0,
    position: 'fixed',
    width: '100vw',
    zIndex: 1000,
  },
  selected: {
    color: Colors.Secondary,
  },
});

export const BottomNav = () => {
  const [value, setValue] = useState('');
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    for (const item of NAV_ITEMS) {
      if (!item) continue;
      const matches = location.pathname.startsWith(item.routeMatch);
      if (matches) {
        setValue(item.routeMatch);
      }
    }
  }, [location]);

  const {
    badgeEvents,
    badgeMembers,
    badgeTopics,
    communityId,
    hasMobileApp,
  } = useSelector((state: RootState) => ({
    badgeEvents: EventsBottomTabBadgeCountSelector(state),
    badgeMembers: MembersBottomTabBadgeCountSelector(state),
    badgeTopics: TotalUnreadTopicsSelector(state),
    communityId: SelectActiveCommunityId(state),
    hasMobileApp: selectHasHeyloMobileApp(state),
  }), shallowEqual);

  const badges: { [key: string]: number } = {
    ['events']: badgeEvents,
    ['members']: badgeMembers,
    ['topics']: badgeTopics,
  };

  const classes = useStyles();
  return (
      <BottomNavigation
          className={classes.root}
          onChange={(event, newValue) => {
            setValue(newValue);
            for (const item of NAV_ITEMS) {
              if (!item) continue;
              if (item.routeMatch === newValue) {
                history.push(`${item.to}${communityId}`);
              }
            }
          }}
          value={value}
      >
        {NAV_ITEMS.map((item, index) => {
          if (!item) {
            return;
          }
          const {key, routeMatch, Icon, label} = item;
          if (hasMobileApp && key === 'app') {
            return;
          }
          const selected = value === routeMatch;
          const badgeCount = badges[key] || 0;
          return (
              <BottomNavigationAction
                  classes={{selected: classes.selected}}
                  data-testid={`nav-${key}`}
                  key={`bottomNav${index}`}
                  label={label}
                  icon={
                    <>
                      <Icon color={selected ? 'secondary' : undefined}/>
                      {badgeCount > 0 ? (
                          <div
                              style={{position: 'absolute', top: 5, right: 15}}>
                            <GreenBadge
                                containerStyle={{marginLeft: StyleConstants.SPACING / 4}}
                                value={badgeCount}
                            />
                          </div>
                      ) : null}
                    </>
                  }
                  value={routeMatch}
              />
          );
        })}
      </BottomNavigation>

  );
};