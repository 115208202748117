import React from 'react';
import {
  Dimensions,
  Keyboard,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import {useNavigation} from 'react-navigation-hooks';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';

type Props = {
  backgroundColor?: string,
  color?: string,
  onPress?: () => void,
  size?: number,
  styles?: ViewStyle,
};
export const BackButton = (props: Props) => {
  const {
    backgroundColor,
    color,
    onPress,
    size,
  } = props;

  const {height, width} = Dimensions.get('window');
  const styles = StyleSheet.create({
    backgroundButton: {
      alignItems: 'center',
      borderRadius: 100,
      height: StyleConstants.SPACING * 2,
      justifyContent: 'center',
      marginLeft: StyleConstants.SPACING,
      width: StyleConstants.SPACING * 2,
    },
    noBackgroundButton: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: width * 0.02,
      top: 1,
    },
  });

  const logger = useLoggingService();
  const navigation = useNavigation();

  const goBack = () => {
    // NB: Immediately dismissing the keyboard will not work on iOS.
    setTimeout(() => {
      Keyboard.dismiss();
    }, 100);
    logger.logEvent(AnalyticsEvent.BACK);
    if (typeof onPress === 'function') {
      onPress();
    } else {
      navigation.goBack();
    }
  };

  return (
      <TouchableWithoutFeedback
          hitSlop={{top: 10, bottom: 10, left: 10, right: 10}}
          onPress={goBack}
      >
        <View style={backgroundColor
            ? {...styles.backgroundButton, backgroundColor}
            : styles.noBackgroundButton}
        >
          <MaterialCommunityIcon
              color={color || 'black'}
              name='arrow-left'
              size={size || width * 0.07}
              style={backgroundColor ? {top: Platform.OS === 'ios' ? 1 : 0} : {}}
          />
        </View>
      </TouchableWithoutFeedback>
  );
};
