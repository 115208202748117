import React, {useCallback, useEffect, useRef, useState} from 'react';
import {createEditor, Editor, Node, Range, Transforms} from 'slate';
import {
  Editable,
  ReactEditor,
  Slate,
  useFocused,
  useSelected,
  withReact,
} from 'slate-react';
import {Mention, UserProfile} from '@heylo/shared/src/types/firebase-types';
import {useTheme} from 'react-native-paper';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {FlatList, View} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {Props} from './MentionsTextInput';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {shallowEqual, useSelector} from 'react-redux';
import {ActiveThreadMentionsAudience} from '@heylo/shared/src/features/threads/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {MentionSuggestion} from './MentionSuggestion';
import {withHistory} from 'slate-history'
import {FilterMemberByName} from '@heylo/shared/src/features/userProfiles/MemberNameFilter';

const withMentions = (editor: ReactEditor) => {
  const {isInline, isVoid} = editor;
  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element);
  }
  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element);
  }
  return editor;
};

type MentionNode = Node & {
  userProfile: UserProfile,
}

const insertMention = (editor: ReactEditor, range: Range, user: UserProfile) => {
  Transforms.select(editor, range);
  const {fullName = ''} = user;
  const mention: MentionNode = {
    children: [{text: `@${fullName}`}],
    type: 'mention',
    userProfile: user,
  };
  // Include a space after each mention, then move cursor to be after the space.
  Transforms.insertNodes(editor, [mention, {text: ' '}]);
  Transforms.move(editor, {distance: 2, unit: 'character'});
}

const Element = (props: any) => {
  const {attributes, children, element} = props;
  switch (element.type) {
    case 'mention':
      return (
          <MentionElement
              children={children}
              {...props}
          />
      );
    default:
      return (
          <p
              {...attributes}
              style={{
                fontSize: '16px',
                margin: 0,
              }}
          >
            {children}
          </p>
      );
  }
};

const palette = usePalette();

const MentionElement = ({attributes, children, element}: { attributes: any, children: any, element: any }) => {
  const selected = useSelected();
  const focused = useFocused();
  const userProfile: UserProfile = element.userProfile;
  const {fullName = ''} = userProfile;
  return (
      <span
          {...attributes}
          contentEditable={false}
          style={{
            backgroundColor: palette.secondary.light,
            borderRadius: '10px',
            boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
            display: 'inline-block',
            fontSize: '16px',
            fontWeight: 'bold',
            margin: '0 1px',
            padding: '4px',
            userSelect: 'none',
            verticalAlign: 'baseline',
          }}
      >
        {`@${fullName}`}
        {children}
    </span>
  );
}

enum State {
  IDLE,
  FOCUSED,
  MENTION_STARTED,
};

const SUGGESTION_HEIGHT = 38

export const MentionsTextInput = (
    {
      onApiReady,
      onChange,
      onSubmit,
      onTouch,
      placeholder,
    }: Props) => {
  console.count('MentionsTextInput');

  const initEditor = () => withMentions(withReact(withHistory(createEditor())));

  const [editor, setEditor] = useState<ReactEditor>(initEditor());

  const {
    activeUserId,
    userProfiles,
  } = useSelector((state: RootState) => ({
    activeUserId: selectActiveUserId(state),
    userProfiles: ActiveThreadMentionsAudience(state),
  }), shallowEqual);

  const [state, setState] = useState(State.IDLE);
  const [range, setRange] = useState<Range | null>();
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
  const [filteredUserProfiles, setFilteredUserProfiles] = useState<UserProfile[]>([]);
  const [mentionText, setMentionText] = useState('');
  const listRef = useRef<FlatList>(null);

  useEffect(() => {
    const keyword = mentionText.toLocaleLowerCase();
    const filtered = userProfiles.filter(user => {
      const {id} = user;
      return id !== activeUserId && FilterMemberByName(user, keyword)
    });
    setFilteredUserProfiles(filtered);
  }, [activeUserId, userProfiles, mentionText]);

  const onKeyDown = useCallback(event => {
    if (state === State.MENTION_STARTED && range) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          const prevIndex = selectedSuggestionIndex >= filteredUserProfiles.length - 1 ? 0 : selectedSuggestionIndex + 1;
          setSelectedSuggestionIndex(prevIndex);
          break;
        case 'ArrowUp':
          event.preventDefault();
          const nextIndex = selectedSuggestionIndex <= 0 ? filteredUserProfiles.length - 1 : selectedSuggestionIndex - 1;
          setSelectedSuggestionIndex(nextIndex);
          break;
        case 'Tab':
        case 'Enter':
          event.preventDefault();
          insertMention(editor, range, filteredUserProfiles[selectedSuggestionIndex]);
          setState(State.FOCUSED);
          break;
        case 'Escape':
          event.preventDefault();
          setState(State.FOCUSED);
          break;
      }
    } else {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          if (event.shiftKey) {
            Editor.insertBreak(editor);
          } else {
            onSubmit();
          }
          break;
      }
    }
  }, [selectedSuggestionIndex, range, filteredUserProfiles, onSubmit, state, listRef]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToIndex({
        animated: false,
        index: selectedSuggestionIndex,
        viewPosition: 0.5,
      });
    }
  }, [selectedSuggestionIndex]);


  const emptyValue = (): Node[] => ([
    {
      type: 'paragraph',
      children: [{text: ''}],
    },
  ]);

  const [value, setValue] = useState<Node[]>(emptyValue());

  useEffect(() => {
    onApiReady({
      clear: () => {
        // NB: must remove editor's internal state before modifying `value`
        // out of band, otherwise Slate Editor panics and throws errors.
        Transforms.removeNodes(editor);
        setValue(emptyValue());
        // NB: Moves the cursor to the start of the text input. The target is
        // the first text node of the first paragraph (i.e., a path of [0, 0]);
        setTimeout(() => Transforms.select(editor, [0, 0]), 100);
      },
      focus: () => ReactEditor.focus(editor),
    })
  }, [editor]);

  const renderElement = useCallback(props => <Element {...props} />, []);
  const theme = useTheme()

  const [menuVisible, setMenuVisible] = useState(false);
  useEffect(() => {
    setMenuVisible(state === State.MENTION_STARTED && filteredUserProfiles.length > 0);
  }, [state, filteredUserProfiles]);

  const [menuHeight, setMenuHeight] = useState(0);

  useEffect(() => {
    let newHeight = menuVisible
        ? (Math.min(4, filteredUserProfiles.length) * SUGGESTION_HEIGHT) + StyleConstants.SPACING / 2
        : 0;
    setMenuHeight(newHeight);
  }, [menuVisible, filteredUserProfiles]);

  const renderSuggestion = ({item: user, index}: { item: UserProfile, index: number }) => {
    const {id = ''} = user;
    return (
        <MentionSuggestion
            key={`mention${id}`}
            onPress={() => {
              if (range) {
                insertMention(editor, range, user);
                // Because the user clicked on a mention, the focus on the text
                // input was lost.
                ReactEditor.focus(editor);
              }
            }}
            selected={index === selectedSuggestionIndex}
            userProfile={user}
        />
    );
  };

  const getItemLayout = (data: any, index: number) => (
      {length: SUGGESTION_HEIGHT, offset: SUGGESTION_HEIGHT * index, index}
  );

  const serialize = (value: Node[]) => {
    return value.map(n => Node.string(n)).join('\n');
  }

  const resolveMentions = (value: Node[]) => {
    const mentions: { [userId: string]: Mention } = {};
    for (const paragraph of value) {
      const children = paragraph.children as MentionNode[];
      const userProfiles = children.filter((n: Node) => n.type === 'mention').map((n: MentionNode) => n.userProfile);
      for (const user of userProfiles) {
        const {id: userId, fullName} = user;
        if (userId && fullName) {
          mentions[userId] = {fullName: fullName};
        }
      }
    }
    return mentions;
  }

  return (
      <>
        {menuVisible && (
            <div style={{position: 'absolute'}}>
              <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    bottom: 5,
                    boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                    display: 'inline',
                    height: menuHeight,
                    overflow: 'hidden',
                    position: 'absolute',
                  }}
              >
                <View style={{
                  height: '100%',
                  paddingVertical: StyleConstants.SPACING / 4,
                }}>
                  <FlatList
                      data={filteredUserProfiles}
                      getItemLayout={getItemLayout}
                      ref={listRef}
                      renderItem={renderSuggestion}
                  />
                </View>
              </div>
            </div>
        )}
        <Slate
            editor={editor}
            onChange={(newValue: Node[]) => {
              // TODO: pull out into a useCallback
              setValue(newValue);

              const rawText = serialize(newValue);
              const mentions = resolveMentions(newValue);
              onChange(rawText, mentions);

              const {selection} = editor;

              if (selection && Range.isCollapsed(selection)) {
                const [start] = Range.edges(selection);

                const charBefore = Editor.before(editor, start, {unit: 'character'});
                const charBeforeRange = charBefore && Editor.range(editor, charBefore, start)
                const charBeforeText = charBeforeRange && Editor.string(editor, charBeforeRange)

                // TODO: i dont think this works for searching w space (for
                // ex. to get last names)
                const wordBefore = Editor.before(editor, start, {unit: 'word'});
                const pointBeforeWord = wordBefore && Editor.before(editor, wordBefore);
                const rangeWordBefore = pointBeforeWord && Editor.range(editor, pointBeforeWord, start);
                const wordBeforeText = rangeWordBefore && Editor.string(editor, rangeWordBefore);
                const wordBeforeMatchMention = wordBeforeText && wordBeforeText.match(/^@(\w+)$/);

                if (charBeforeText === '@') {
                  setState(State.MENTION_STARTED);
                  setMentionText('');
                  setRange(charBeforeRange);
                  setSelectedSuggestionIndex(0);
                  return;
                }
                if (wordBeforeMatchMention) {
                  setState(State.MENTION_STARTED);
                  setMentionText(wordBeforeMatchMention[1]);
                  setRange(rangeWordBefore);
                  setSelectedSuggestionIndex(0);
                  return;
                }
                setState(State.FOCUSED);
              }
            }}
            value={value}
        >
          <Editable
              className={'minimal-scrollbar'}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              renderElement={renderElement}
              style={{
                backgroundColor: 'white',
                border: `solid ${palette.grey.main} 1px`,
                borderRadius: `${theme.roundness}px`,
                margin: 0,
                maxHeight: 200,
                overflowY: 'scroll',
                padding: `${StyleConstants.SPACING / 2}px`,

              }}
          />
        </Slate>

      </>
  );
};
