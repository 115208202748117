import {createSelector} from 'reselect';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {EmailVerificationContext} from './Slice';

export const selectActiveUserId = (state: RootState): string => state.auth?.activeUserId || '';
export const selectAnonymousUserId = (state: RootState): string => state.auth?.activeUserId || state.auth?.anonymousUserId || '';
export const SelectAuthState = (state: RootState) => state.auth?.authState || 'UNKNOWN';
export const selectEmailBeingVerified = (state: RootState): string => state.auth?.emailBeingVerified || '';
export const selectEmailVerificationComplete = (state: RootState): boolean => state.auth?.emailVerificationComplete ?? false;
export const selectEmailVerificationContext = (state: RootState)
    : EmailVerificationContext => state.auth?.emailVerificationContext ?? EmailVerificationContext.NONE;
export const selectEmailVerificationFullName = (state: RootState): string => state.auth?.emailVerificationFullName || '';
export const selectEmailVerificationSecret = (state: RootState): string => state.auth?.emailVerificationSecret || '';
export const selectSignInWithEmailLink = (state: RootState): string => state.auth?.signInWithEmailLink || '';

export const selectAuthStateIsStable = createSelector(
    [SelectAuthState],
    (authState) => {
      return authState === 'SIGNED_OUT' || authState === 'SIGNED_IN';
    });

