import React, {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {MentionsTextInput, MentionsTextInputApi} from './MentionsTextInput';
import {
  THREAD_TYPE_ANNOUNCEMENT,
  THREAD_TYPE_DIRECT,
  THREAD_TYPE_GROUP,
  THREAD_TYPE_SUPPORT,
} from '@heylo/shared/src/types/ThreadTypes';
import {MessageFlags} from '@heylo/shared/src/constants/message/MessageFlags';
import {Mention, Message} from '@heylo/shared/src/types/firebase-types';
import {ReplyToText} from './ReplyToText';
import {StyleVars} from '../../styles/StyleVars';
import {IconButton, useTheme} from 'react-native-paper';
import {ChatImageUploader} from './ChatImageUploader';
import {ImageUploadMetadata} from '@heylo/shared/src/services/image/ImageUploader';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {HeyloDialog} from '../../ui/dialog/HeyloDialog';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {MESSAGE_MAX_WIDTH} from './Styles';

const validMessage = (messageText: string) => {
  return Boolean(messageText && !messageText.match(/^\s*$/));
};

type Props = {
  activeUserId: string,
  clearReplyToMessage: () => void,
  onApiReady: (api: MentionsTextInputApi) => void,
  onError: (message: string) => void,
  onImage: (imageUploadPromise: Promise<string>, image: ImageUploadMetadata) => void,
  onSendPress: (message: string, mentions: { [userId: string]: Mention }, flags: any) => void,
  onTouch: () => void,
  replyToMessage: Message | null,
  threadType: string,
};

export const MessageInput = React.memo((props: Props) => {
  const {
    activeUserId,
    clearReplyToMessage,
    onApiReady,
    onError,
    onImage,
    onSendPress,
    onTouch,
    replyToMessage,
    threadType,
  } = props;

  const [isValidMessage, setIsValidMessage] = useState(false);
  const [text, setText] = useState('');
  const [mentions, setMentions] = useState<{ [userId: string]: Mention }>({});

  const onChange = (text: string, mentions: { [userId: string]: Mention }) => {
    setIsValidMessage(validMessage(text));
    setMentions(mentions);
    setText(text);
  };

  const [apiRef, setApiRef] = useState<MentionsTextInputApi | null>(null);

  const sendMessage = (flags: any = {}) => {
    if (isValidMessage) {
      if (apiRef) {
        apiRef.clear();
      }
      setIsValidMessage(false);
      onSendPress(text, mentions, flags);
    }
  };

  const [showAnnouncementSendConfirmation, setShowAnnouncementSendConfirmation] = useState(false);

  const sendMessagePressed = () => {
    if (threadType === THREAD_TYPE_ANNOUNCEMENT) {
      setShowAnnouncementSendConfirmation(true);
    } else {
      sendMessage();
    }
  };

  const hideAlert = () => setShowAnnouncementSendConfirmation(false);

  const showCameraIcon = threadType !== THREAD_TYPE_ANNOUNCEMENT;

  let placeholderText = '';
  switch (threadType) {
    case THREAD_TYPE_ANNOUNCEMENT:
      placeholderText = 'Send announcement to everyone';
      break;
    case THREAD_TYPE_DIRECT:
    case THREAD_TYPE_GROUP:
      placeholderText = 'Send private message';
      break;
    case THREAD_TYPE_SUPPORT:
      placeholderText = 'Send message to Heylo team';
      break;
    default:
      placeholderText = 'Send message, @mention';
  }

  const localOnApiReady = (api: MentionsTextInputApi) => {
    setApiRef(api);
    onApiReady(api);
  };

  const palette = usePalette();
  const theme = useTheme();

  return (
      <View style={styles.containerInput}>

        {replyToMessage && (
            <View style={styles.containerReplyTo}>
              <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              >
                <ReplyToText
                    replyToMessage={replyToMessage}
                    senderUserId={activeUserId}
                />
                <IconButton
                    color={StyleVars.Colors.GreyMedium}
                    icon={'close'}
                    onPress={clearReplyToMessage}
                    size={StyleConstants.SPACING}
                    style={{backgroundColor: StyleVars.Colors.GreyLightest}}
                />
              </View>
              <View
                  style={[styles.containerReplyToMessage, {borderRadius: theme.roundness}]}>
                <View style={styles.containerReplyToMessageText}>
                  <Text
                      ellipsizeMode={'tail'}
                      numberOfLines={2}
                  >
                    {replyToMessage.content || 'Photo'}
                  </Text>
                </View>
              </View>
            </View>
        )}

        <View style={styles.messageInputContainer}>
          {showCameraIcon ? (
              <ChatImageUploader onChange={onImage} onError={onError}/>
          ) : (
              <View style={{width: StyleConstants.SPACING}}/>
          )}

          <View style={{flex: 1}}>
            <MentionsTextInput
                onApiReady={localOnApiReady}
                onChange={onChange}
                onSubmit={sendMessagePressed}
                onTouch={onTouch}
                placeholder={placeholderText}
            />
          </View>

          <IconButton
              color={isValidMessage ? StyleVars.Colors.Seafoam : StyleVars.Colors.GreyMedium}
              icon='send'
              onPress={sendMessagePressed}
              size={30}
          />
        </View>

        <HeyloDialog
            actions={[
              {
                color: 'black',
                label: 'Cancel',
                onPress: hideAlert,
              },
              {
                color: palette.secondary.main,
                label: 'In-app only',
                onPress: () => sendMessage({
                  [MessageFlags.SUPPRESS_ANNOUNCEMENT_EMAIL]: true,
                }),
              },
              {
                label: 'In-app and email',
                onPress: () => sendMessage(),
              },
            ]}
            body={'Choose how broadly this announcement goes.'}
            onDismiss={hideAlert}
            title={'How do you want to notify?'}
            visible={showAnnouncementSendConfirmation}
        />

      </View>
  );
});

const styles = StyleSheet.create({
  containerInput: {
    borderColor: StyleVars.Colors.GreyLightest,
    borderTopWidth: 1,
    paddingBottom: 6,
    paddingTop: 5,
    width: '100%',
  },
  containerReplyTo: {
    marginHorizontal: StyleConstants.SPACING,
  },
  containerReplyToMessage: {
    backgroundColor: StyleVars.Colors.GreyLightest,
    flexDirection: 'row',
  },
  containerReplyToMessageText: {
    flex: 1,
    marginHorizontal: StyleConstants.SPACING,
    paddingVertical: StyleConstants.SPACING,
    minWidth: 0,
    maxWidth: MESSAGE_MAX_WIDTH,
  },
  messageInputContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
});