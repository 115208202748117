import {createSelector} from 'reselect';
import {
  Community,
  CommunitySettings,
} from '@heylo/shared/src/types/firebase-types';
import {
  selectActiveUserCommunityIds,
  selectUserSettingsFetched,
} from '@heylo/shared/src/features/userSettings/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

const SUPPORT_COMMUNITIES = new Set<string>([
  '-Le0NSx4irn4GItD7BWr',  // production
  '-Ldjd0-vGwQbdCavR2lW',  // development
  'support',
]);

const IsSupportCommunity = (communityId: string) => {
  return SUPPORT_COMMUNITIES.has(communityId);
};

export const SelectActiveCommunityId = (state: RootState): string => state.communities?.FIELD_ACTIVE_COMMUNITY_ID || '';
export const SelectCommunityExperiments = (state: RootState) => state.communities?.FIELD_COMMUNITY_EXPERIMENTS || {};
export const SelectCommunityMembers = (state: RootState) => state.communities?.FIELD_COMMUNITY_MEMBERS || {};
export const SelectCommunityMetadata = (state: RootState) => state.communities?.FIELD_COMMUNITIES || {};
export const SelectCommunityProspects = (state: RootState) => state.communities?.FIELD_COMMUNITY_PROSPECTS || {};
export const SelectCommunitySettings = (state: RootState) => state.communities?.FIELD_COMMUNITY_SETTINGS || {};
export const SelectCommunityLatestDeletionIds = (state: RootState) => state.communities?.latestDeletionId || {};

export const selectPendingCreateCommunity = (state: RootState) => state.communities?.pendingCreateCommunity || null;

export const SelectActiveCommunity = createSelector(
    [SelectCommunityMetadata, SelectActiveCommunityId],
    (communities, activeCommunityId): Community | undefined => {
      return communities[activeCommunityId];
    });

export const SelectActiveUserCommunityIds = createSelector([SelectCommunityMetadata],
    (metadata): string[] => {
      return Object.keys(metadata);
    });

export const SelectActiveUserCommunityNames = createSelector([SelectCommunityMetadata],
    (metadata): string[] => {
      return Object.values(metadata).map(community => community.name ?? 'unknown');
    });

export const SelectActiveUserIsCommunityOwner = createSelector(
    [selectActiveUserId, SelectActiveCommunity],
    (userId, community) => {
      return userId === community?.ownerId;
    });

export const SelectAreCommunitiesLoaded = createSelector(
    [selectUserSettingsFetched, selectActiveUserCommunityIds, SelectCommunityMetadata],
    (settingsFetched, memberCommunityIds, loadedCommunities) => {
      if (!settingsFetched) {
        return false;
      }
      return memberCommunityIds.length === 0 || Object.keys(loadedCommunities).length > 0;
    });

export const SelectActiveUserBelongsToSupportCommunity = createSelector(
    [SelectCommunityMetadata, SelectAreCommunitiesLoaded],
    (communityMetadata, allCommunitiesLoaded): boolean | null => {
      if (!allCommunitiesLoaded) {
        return null;
      }
      for (const communityId of Object.keys(communityMetadata)) {
        if (IsSupportCommunity(communityId)) {
          return true;
        }
      }
      return false;
    });

export const SelectActiveCommunityIsSupportCommunity = createSelector(
    [SelectActiveCommunityId],
    (activeCommunityId) => {
      return IsSupportCommunity(activeCommunityId);
    });

export const SelectActiveCommunitySettings = createSelector(
    [SelectCommunitySettings, SelectActiveCommunityId],
    (allSettings, activeCommunityId): CommunitySettings => {
      return allSettings[activeCommunityId] ?? {};
    });

export const SelectActiveCommunityInstagramHandle = createSelector(
    [SelectActiveCommunitySettings],
    (settings): string => {
      const handle = settings.linkInstagram;
      if (handle) {
        return handle.indexOf('@') === 0 ? handle : `@${handle}`;
      }
      return '';
    });

export const SelectMostRecentCommunityScopedDeletionId = createSelector(
    [SelectActiveCommunityId, SelectCommunityLatestDeletionIds],
    (activeCommunityId, deletions) => {
      return deletions[activeCommunityId] ?? '';
    });

export const SelectActiveCommunityExperiments = createSelector(
    [SelectActiveCommunityId, SelectCommunityExperiments],
    (activeCommunityId, communityExperiments): { [experimentId: string]: string } => {
      return communityExperiments[activeCommunityId] ?? {};
    });
