import React from 'react';
import {Text, View, ViewStyle} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import {Message} from '@heylo/shared/src/types/firebase-types';
import {ResolveFirstAndLastName} from '@heylo/shared/src/util/strings';
import {SelectOneUserProfile} from '@heylo/shared/src/features/userProfiles/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {StyleVars} from '../../styles/StyleVars';

const getSenderShortName = (activeUserId: string, senderUserId: string, replyToUserId: string, senderFullName: string) => {
  if (activeUserId === senderUserId && activeUserId === replyToUserId) {
    return 'yourself';
  } else if (senderUserId === replyToUserId) {
    return 'themself';
  } else if (activeUserId === replyToUserId) {
    return 'you';
  } else if (senderFullName) {
    return ResolveFirstAndLastName(senderFullName)[0];
  }
  return 'someone';
};

type OwnProps = {
  containerStyle?: ViewStyle,
  pastTense?: boolean,
  replyToMessage: Message,
  senderUserId: string,
};

const mapState = (state: RootState, ownProps: OwnProps) => {
  const {replyToMessage} = ownProps;
  const replyToUserId = replyToMessage.ownerId || '';
  const replyToUserFullName = SelectOneUserProfile(state, replyToUserId).fullName || '';
  return {
    activeUserId: selectActiveUserId(state) || '',
    replyToUserFullName,
    replyToUserId,
  };
};
const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & OwnProps;

export const ReplyToText = connector((props: Props) => {
  const {
    activeUserId,
    containerStyle,
    pastTense,
    replyToUserId,
    replyToUserFullName,
    senderUserId,
  } = props;

  const replyToName = getSenderShortName(activeUserId, senderUserId, replyToUserId, replyToUserFullName);
  const isSelf = activeUserId === senderUserId;
  const text = useTextStyles();

  return (
      <View style={{
        ...(containerStyle || {}),
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: isSelf ? 'flex-end' : 'flex-start',
      }}>
        <MaterialCommunityIcon
            color={StyleVars.Colors.GreyDark}
            name={'reply'}
            size={20}
            style={{transform: [{rotateY: '180deg'}]}}
        />
        <Text style={[
          text.body2,
          {color: StyleVars.Colors.GreyDark},
        ]}>
          &nbsp;{pastTense ? 'Replied' : 'Replying'} to {replyToName}
        </Text>
      </View>);
});
