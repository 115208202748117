import React from 'react';
import {DropShadowScrollView} from '../../ui/scrollview/DropShadowScrollView';
import {
  CommunityProfile,
  Marketplace,
} from '@heylo/shared/src/types/firebase-types';
import {HeyloImage} from '../../ui/image/HeyloImage';
import {Text, View} from 'react-native';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {HeyloHyperlink} from '../../ui/link/Hyperlink';

type Props = {
  fallbackImageSource: number | { uri: string },
  marketplace?: Marketplace,
  // If true, render this group in a preview mode (admins only).
  preview?: boolean,
  profile: CommunityProfile,
};
export const CommunityProfilePage = (
    {
      fallbackImageSource,
      marketplace,
      preview,
      profile,
    }: Props) => {
  const palette = usePalette();
  const text = useTextStyles();

  const {
    communityName,
    description: aboutUs,
    heroImageUrl,
    locationName = 'No location specified',
    requirements: memberRequirements,
  } = profile;

  let location = locationName;
  if (marketplace) {
    const {iconEmoji, nameFull} = marketplace;
    location = [iconEmoji, nameFull].join(' ');
  }

  return (
      <DropShadowScrollView>
        {preview && (
            <Text style={[text.body1, {padding: StyleConstants.SPACING}]}>
              Review your group details. When it looks good, let's finish creating it!
            </Text>
        )}

        {!!heroImageUrl && (
            <HeyloImage
                aspectRatio={1}
                resizeMode={'cover'}
                uri={heroImageUrl}
            />
        )}

        {!heroImageUrl && preview && (
            <View>
              <HeyloImage
                  aspectRatio={1}
                  resizeMode={'cover'}
                  source={typeof fallbackImageSource === 'number' ? fallbackImageSource : undefined}
                  uri={typeof fallbackImageSource !== 'number' ? fallbackImageSource.uri : undefined}
              />
              <View style={{
                alignItems: 'center',
                height: '100%',
                padding: 50,
                position: 'absolute',
                justifyContent: 'center',
                width: '100%',
              }}>
                <View style={{
                  backgroundColor: palette.grey.dark,
                  padding: 20,
                  width: '100%',
                }}>
                  <Text style={[text.body1, text.bold, {
                    color: 'white',
                    textAlign: 'center',
                  }]}>
                    Heylo will use a stock photo.
                  </Text>
                </View>
              </View>
            </View>
        )}

        <View style={{padding: 20}}>
          <Text style={text.h5}>{communityName}</Text>
          {!!location && (
              <Text style={text.subtitle1}>{location}</Text>
          )}

          {!!aboutUs && (
              <View style={{marginTop: 20}}>
                <Text style={text.h6}>About us</Text>
                <HeyloHyperlink>
                  <Text style={text.body1}>{aboutUs}</Text>
                </HeyloHyperlink>
              </View>
          )}

          {!!memberRequirements && (
              <View style={{marginTop: 20}}>
                <Text style={text.h6}>Member requirements</Text>
                <Text style={text.body1}>{memberRequirements}</Text>
              </View>
          )}
        </View>

        <View style={{height: 100}}/>
      </DropShadowScrollView>
  )
};