import React, {useCallback, useEffect} from 'react';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {listenForUserProfile} from '@heylo/shared/src/features/userProfiles/Firebase';
import {Attendee} from 'features/event';
import {
  selectActiveCommunityProspects,
  SelectSortedActiveCommunityUserProfiles,
  selectUnviewedCommunityProspects,
} from '@heylo/shared/src/features/communityMembers/Selectors';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {listenForCommunityMembers} from '@heylo/shared/src/features/communityMembers/Firebase';
import {HeyloText} from 'lib/materialUi';
import {WebAppDispatch} from 'store';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {FlatList, Text, View} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {HeyloContainer} from '@heylo/components/src/ui/container/HeyloContainer';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {UserProfile} from '@heylo/shared/src/types/firebase-types';
import {IncrementCommunityImpression} from '@heylo/shared/src/features/userEvents/Actions';

type OwnProps = {
  selectedMemberId?: string
};

const mapState = (state: RootState, ownProps: OwnProps) => ({
  activeCommunityId: SelectActiveCommunityId(state),
  allUserProfiles: SelectSortedActiveCommunityUserProfiles(state),
  allProspects: selectActiveCommunityProspects(state),
  unviewedProspects: selectUnviewedCommunityProspects(state),
});

const connector = connect(mapState, {});

type Props = ConnectedProps<typeof connector> & OwnProps;

export const PureMembers = (props: Props) => {
  const {
    activeCommunityId,
    allProspects,
    allUserProfiles,
    selectedMemberId = '',
    unviewedProspects,
  } = props;

  const dispatch: WebAppDispatch = useDispatch();

  useEffect(() => {
    dispatch(listenForCommunityMembers(activeCommunityId));
    dispatch(IncrementCommunityImpression(activeCommunityId, 'MembersScreen'));
    return () => {
      dispatch(IncrementCommunityImpression(activeCommunityId, 'MembersScreen'));
    };
  }, [activeCommunityId, dispatch]);

  useEffect(() => {
    console.log('setup listeners closer to where users actually get rendered?');
    for (const userProfile of allUserProfiles) {
      const {id} = userProfile;
      if (id) {
        dispatch(listenForUserProfile(id));
      }
    }
  }, [allUserProfiles, dispatch]);

  const text = useTextStyles();
  const isAlone = allUserProfiles.length <= 1;
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  const memberKey = useCallback((item: UserProfile) => {
    const {id: userId = ''} = item;
    return `member${userId}`;
  }, []);

  const renderMember = useCallback(({item}: { item: UserProfile }) => {
    const {id: userId = ''} = item;
    if (!userId) {
      return null;
    }
    const selected = selectedMemberId === userId;
    const isNew = unviewedProspects.has(userId);
    return (
        <View style={{
          marginVertical: StyleConstants.SPACING / 2,
        }}>
          <Attendee
              isNew={isNew}
              selected={selected}
              userProfile={item}
          />
        </View>
    );
  }, [selectedMemberId, unviewedProspects]);

  if (isAlone) {
    return (
        <div style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          padding: StyleConstants.SPACING,
        }}>
          <Text style={[text.h5, text.center]}>
            Let's get this party started 🥳
          </Text>
          <Text style={[text.h6, text.center]}>
            Add members by email or sharing your link
          </Text>
        </div>
    );
  }
  return (
      <>
        <HeyloContainer maxWidth={'xs'}
                        style={{paddingBottom: matchXs ? 50 : 0}}>

          {allProspects.length > 0 && (
              <View style={{padding: StyleConstants.SPACING}}>
                <View style={{marginTop: '2rem'}}>
                  <HeyloText variant={'subtitle1'}>
                    Prospective members ({allProspects.length})
                  </HeyloText>
                </View>
                <FlatList
                    data={allProspects}
                    keyExtractor={memberKey}
                    renderItem={renderMember}
                />
              </View>
          )}

          {allUserProfiles.length > 0 && (
              <View style={{padding: StyleConstants.SPACING}}>
                <HeyloText variant={'subtitle1'}>
                  All members ({allUserProfiles.length})
                </HeyloText>
                <FlatList
                    data={allUserProfiles}
                    keyExtractor={memberKey}
                    renderItem={renderMember}
                />
              </View>
          )}
        </HeyloContainer>
      </>
  );
};

export const Members = connector(PureMembers);
