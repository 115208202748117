import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  CommunityJoinRequestNextScreen,
  selectJoinRequestNextScreen,
} from '@heylo/shared/src/features/marketplaces/Selectors';
import {resolveUriForGroup} from '@heylo/shared/src/features/marketplaces/Util';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';

export const useJoinRequestState = () => {
  const history = useHistory();

  const {
    communityId,
    nextJoinRequestScreen,
  } = useSelector((state: RootState) => ({
    communityId: SelectActiveCommunityId(state),
    nextJoinRequestScreen: selectJoinRequestNextScreen(state),
  }), shallowEqual);

  const navigateToNextScreen = (replace: boolean) => {
    let routeName = '';
    switch (nextJoinRequestScreen) {
      case CommunityJoinRequestNextScreen.EDIT_PROFILE:
        routeName = `${resolveUriForGroup(communityId)}/profile`;
        break;
      case CommunityJoinRequestNextScreen.SEND_REQUEST:
        routeName = `${resolveUriForGroup(communityId)}/requestSent`;
        break;
    }

    if (replace) {
      history.replace(routeName);
    } else {
      history.push(routeName);
    }
  }

  return {
    navigateToNextScreen,
  };
};