import React, {Dispatch, SetStateAction, useCallback, useEffect} from 'react';
import {Text} from 'react-native';
import {CommunityProfilePage} from '../communityProfile/CommunityProfilePage';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  selectDraftCommunityMarketplace,
  selectDraftCommunityProfile,
} from '@heylo/shared/src/features/communityCreation/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {v4 as uuidv4} from 'uuid';
import {
  FirebaseAddUserToCommunity,
  FirebaseCreateCommunity,
} from '@heylo/shared/src/features/communities/Firebase';
import {NewUserChannels} from '@heylo/shared/src/types/NewUserChannels';
import {CommunityProfilesFirebase} from '@heylo/shared/src/features/communityProfiles/Firebase';
import {useHeyloConfig} from '@heylo/shared/src/features/app/useHeyloConfig';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';

export enum NewCommunityReviewState {
  IDLE,
  SAVE_START,
  SAVE_PENDING,
  ERROR,
  SUCCESS,
}

export const NewCommunityReview = (
    {
      fallbackImageSource,
      state,
      setState,
    }: {
      fallbackImageSource: number | { uri: string },
      state: NewCommunityReviewState,
      setState: Dispatch<SetStateAction<NewCommunityReviewState>>
    }) => {
  const dispatch = useDispatch();
  const heyloConfig = useHeyloConfig();
  const logger = useLoggingService();

  const {
    communityProfile,
    marketplace,
    userId,
  } = useSelector((state: RootState) => ({
    communityProfile: selectDraftCommunityProfile(state),
    marketplace: selectDraftCommunityMarketplace(state),
    userId: selectActiveUserId(state),
  }), shallowEqual);

  const onSave = useCallback(() => {
    if (!NewCommunityReviewState.SAVE_START) {
      return;
    }
    setState(NewCommunityReviewState.SAVE_PENDING);
    const {communityName = ''} = communityProfile;
    const communityId = uuidv4();
    dispatch(FirebaseCreateCommunity({
      communityId,
      userId,
      name: communityName,
      buildVariant: heyloConfig.BUILD_VARIANT_STRING,
    }))
        // @ts-ignore
        .then(() => {
          logger.logEvent(AnalyticsEvent.COMMUNITY_CREATED);
          return Promise.all([
            dispatch(FirebaseAddUserToCommunity(communityId, userId, {
              isLead: true,
              joinChannel: NewUserChannels.CREATE_COMMUNITY,
            })),
            dispatch(CommunityProfilesFirebase.UpdateCommunityProfile(communityId, communityProfile)),
          ]);
        })
        .then(() => {
          setState(NewCommunityReviewState.SUCCESS);
        })
        .catch((e: Error) => {
          console.warn('error creating community', communityId, e);
          logger.logEvent(AnalyticsEvent.SUBMIT_ERROR, {error: e.toString()});
          setState(NewCommunityReviewState.ERROR);
        });
  }, [communityProfile, dispatch, heyloConfig, userId])

  useEffect(() => {
    switch (state) {
      case NewCommunityReviewState.SAVE_START:
        onSave();
        break;
    }
  }, [state, onSave]);

  const text = useTextStyles();
  return (
      <>
        {state === NewCommunityReviewState.ERROR && (
            <Text style={[
              text.body1,
              text.center,
              text.error,
              {marginTop: StyleConstants.SPACING},
            ]}>
              Something went wrong. Please try again or contact support@heylo.co
            </Text>
        )}
        <CommunityProfilePage
            fallbackImageSource={fallbackImageSource}
            marketplace={marketplace}
            preview
            profile={communityProfile}
        />

      </>
  );
};