import {FirebaseStorage} from '@heylo/firebase-database';

const CACHE_CONTROL = 'public, max-age=604800, immutable';

// TODO: move to FirebaseUtils.ts as soon as possible
export const UploadImageBlobWithContentType = (
    {
      contentType,
      imageWidth,
      imageHeight,
      fileName,
      storagePath,
      blob,
    }: {
      contentType: string,
      fileName: string,
      imageHeight?: number,
      imageWidth?: number,
      storagePath: string,
      blob: Blob,
    }): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const childRef = FirebaseStorage().ref(storagePath).child(fileName);
    childRef.put(blob, {
  cacheControl: CACHE_CONTROL,
  contentType,
  customMetadata: {
    imageWidth: imageWidth ? imageWidth.toString() : '',
    imageHeight: imageHeight ? imageHeight.toString() : '',
  },
    })
        .then(task => resolve(childRef.getDownloadURL()))
        .catch(e => {
          console.error('error uploading image', e);
          reject(e);
        });
  });
};