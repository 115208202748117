import React, {useEffect, useState} from 'react';
import {BottomNav, TopNav} from 'features/TopNav';
import {CommunityContent} from './CommunityContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import {Route, Switch} from 'react-router-dom';
import {LayoutChangeEvent, useWindowDimensions, View} from 'react-native';

export const CommunityNavigator = React.memo(() => {
  console.count('count CommunityNavigator');
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  const {height: windowHeight} = useWindowDimensions();

  const [headerHeight, setHeaderHeight] = useState(0);
  const onLayout = (event: LayoutChangeEvent) => {
    const {height} = event.nativeEvent.layout;
    setHeaderHeight(height);
  };

  const [contentHeight, setContentHeight] = useState(0);
  useEffect(() => {
    setContentHeight(windowHeight - headerHeight);
  }, [windowHeight, headerHeight]);

  return (
      <div style={{
        display: 'flex',
        flexGrow: 1,
        height: '100vh',
        maxHeight: '100vh',
        flexDirection: 'column',
      }}>
        <View onLayout={onLayout}>
          <TopNav/>
        </View>
        {matchXs && (
            <Switch>
              {/* Hide bottom nav for chat screen */}
              <Route path={'/topics/:communityId/:threadId'}/>
              <Route path={[
                '/community',
                '/events',
                '/members',
                '/topics',
              ]}>
                <BottomNav/>
              </Route>
            </Switch>
        )}
        <View style={{height: contentHeight || '100%'}}>
          <CommunityContent/>
        </View>
      </div>
  );
});
