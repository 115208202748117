import {CommunityProfile} from '@heylo/shared/src/types/firebase-types';
import {CommunityProfileLoaded} from '@heylo/shared/src/features/communityProfiles/Slice';
import {listenForUserProfile} from '@heylo/shared/src/features/userProfiles/Firebase';
import {Listeners} from '@heylo/shared/src/features/firebase/Listeners';
import {FirebaseDatabase} from '@heylo/firebase-database';
import {ThunkReturnType} from '../../services/redux/Redux';

export const CommunityProfilesFirebase = {

  AttachCommunityProfileListener: (communityId: string) => {
    return (dispatch: any) => {
      if (!communityId || Listeners.COMMUNITY_PROFILES[communityId]) {
        return;
      }
      const ref = FirebaseDatabase().ref(`/communityProfiles/${communityId}`);
      Listeners.COMMUNITY_PROFILES[communityId] = ref;
      ref.on('value',
          snapshot => {
            const profile: CommunityProfile = snapshot?.val();
            if (!profile) {
              return;
            }
            dispatch(CommunityProfileLoaded({communityId, profile}));
            const userIds = [...Object.keys(profile.ambassadorUserIds || {}), ...Object.keys(profile.leadUserIds || {})];
            for (const userId of userIds) {
              dispatch(listenForUserProfile(userId));
            }
          },
          (e: Error) => {
            console.log('lost connection to /communityProfiles', communityId, e.message);
            delete Listeners.COMMUNITY_PROFILES[communityId];
          });
    };
  },

  UpdateCommunityProfile: (communityId: string, profile: CommunityProfile)
      : ThunkReturnType<Promise<any>> => dispatch => {
    dispatch(CommunityProfileLoaded({communityId, profile}));
    const promises = [FirebaseDatabase().ref(`/communityProfiles/${communityId}`).update(profile)
        .catch(e => {
          console.warn('failed to update /communityProfiles', communityId, profile, e);
        })];
    if (profile.communityName) {
      promises.push(FirebaseDatabase().ref(`/communities/${communityId}/name`).set(profile.communityName)
          .catch(e => {
            console.warn('failed to update community name', communityId, profile, e);
          }));
    }
    return Promise.all(promises);
  },

};