import {
  MemberFieldSpec,
  Network,
  UserSettings,
} from '@heylo/shared/src/types/firebase-types';
import {MemberFieldSpecsChanged} from '@heylo/shared/src/features/communityMemberFields/Slice';
import {
  NetworkChanged,
  UserSettingsChanged,
} from '@heylo/shared/src/features/userSettings/Slice';
import {
  FirebaseDatabase,
  FirebaseServerTimestamp,
} from '@heylo/firebase-database';
import {
  Listeners,
  RetriableFirebaseListener,
} from '@heylo/shared/src/features/firebase/Listeners';
import {CommunityProfilesFirebase} from '@heylo/shared/src/features/communityProfiles/Firebase';
import {AttachCommunityMetadataListener} from '@heylo/shared/src/features/communities/Firebase';
import {CompactCommunities} from '@heylo/shared/src/features/communities/Slice';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {UserProfileCreated} from '@heylo/shared/src/features/userProfiles/Slice';

export const UserSettingsFirebase = {

  CreateUserSettings: (userId: string, settings: UserSettings)
      : ThunkReturnType<Promise<void>> => dispatch => {
    dispatch(UserProfileCreated({
      userId,
      profile: {fullName: settings.fullName},
    }));
    settings.creationTimestamp = FirebaseServerTimestamp();
    return FirebaseDatabase().ref(`/userSettings/${userId}`).update(settings);
  },

  UpdateUserSettings: (userId: string, settings: UserSettings) => {
    return FirebaseDatabase().ref(`/userSettings/${userId}`).update(settings);
  },

  ListenForUserSettings: (userId: string) => {
    return (dispatch: any) => {
      if (Listeners.USER_SETTINGS[userId]) {
        return;
      }
      const ref = FirebaseDatabase().ref('/userSettings/' + userId);
      Listeners.USER_SETTINGS[userId] = ref;
      ref.on('value', (snapshot) => {
        const settings: UserSettings = snapshot?.val();
        if (!settings) {
          return;
        }
        dispatch(UserSettingsChanged(settings));
        for (const communityId of Object.keys(settings.prospectiveCommunities || {})) {
          dispatch(CommunityProfilesFirebase.AttachCommunityProfileListener(communityId));
        }
        for (const communityId of Object.keys(settings.communities || {})) {
          dispatch(AttachCommunityMetadataListener(userId, communityId));
          dispatch(CommunityProfilesFirebase.AttachCommunityProfileListener(communityId));
        }
        for (const networkId of Object.keys(settings.networks || {})) {
          dispatch(UserSettingsFirebase.AttachListenerForNetwork(networkId));
        }
        dispatch(CompactCommunities(settings));
      }, (e: Error) => {
        console.log('lost connection to user settings', e.message);
        delete Listeners.USER_SETTINGS[userId];
      });
    };
  },

  AddProspectiveCommunity: (userId: string, communityId: string) => {
    return FirebaseDatabase()
        .ref(`/userSettings/${userId}/prospectiveCommunities/${communityId}`)
        .set(true);
  },

  AttachListenerForMemberFieldSpecs: (communityId: string) => {
    return (dispatch: any) => {
      if (Listeners.COMMUNITY_MEMBER_FIELD_SPEC[communityId]) {
        return;
      }
      RetriableFirebaseListener(retry => {
        const ref = FirebaseDatabase().ref(`/memberFieldSpecs/${communityId}`);
        Listeners.COMMUNITY_MEMBER_FIELD_SPEC[communityId] = ref;
        ref.on('value',
            snapshot => {
              const fieldMap: { [fieldId: string]: MemberFieldSpec } = snapshot?.val() || {};
              dispatch(MemberFieldSpecsChanged({communityId, spec: fieldMap}));
            },
            (e: Error) => {
              delete Listeners.COMMUNITY_MEMBER_FIELD_SPEC[communityId];
              if (retry(e)) {
                return;
              }
              console.warn('lost connection to /memberFieldSpecs', communityId, e.message);
            },
        );
      });
    };
  },

  // TODO: move this to a network-specific firebase file
  AttachListenerForNetwork: (networkId: string) => {
    return (dispatch: any) => {
      if (Listeners.NETWORK[networkId]) {
        return;
      }
      const ref = FirebaseDatabase().ref(`/networks/${networkId}`);
      Listeners.NETWORK[networkId] = ref;
      ref.on('value',
          snapshot => {
            const network: Network | null = snapshot?.val() || null;
            dispatch(NetworkChanged({networkId, network}));
          },
          (e: Error) => {
            console.log('lost connection to network', networkId, e.message);
            delete Listeners.NETWORK[networkId];
          });

    };
  },

  RemoveProspectiveCommunity: (userId: string, communityId: string) => {
    return FirebaseDatabase()
        .ref(`/userSettings/${userId}/prospectiveCommunities/${communityId}`)
        .set(null);
  },

};
