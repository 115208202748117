import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {
  MemberFieldResponse,
  MemberFieldSpec,
} from '@heylo/shared/src/types/firebase-types';
import _ from 'lodash';
import {DeepReadonly} from 'utility-types';
import {SigningOutAction} from '@heylo/shared/src/features/auth/Slice';
import {AppColdStart, AppReset} from '../app/Actions';

export type CommunityMemberSince = {
  month: string,
  year: number,
};

export type CommunityMemberFieldsState = {
  FIELD_MEMBER_FIELD_SPECS: DeepReadonly<{ [communityId: string]: { [fieldId: string]: MemberFieldSpec } }>,
  FIELD_MEMBER_FIELD_RESPONSES: DeepReadonly<{ [communityId: string]: { [userId: string]: { [fieldId: string]: MemberFieldResponse } } }>,
  FIELD_MEMBER_FIELD_RESPONSE_DRAFTS: DeepReadonly<{ [communityId: string]: { [fieldId: string]: string } }>,
  FIELD_MEMBER_SINCE_DRAFTS: DeepReadonly<{ [communityId: string]: CommunityMemberSince }>,
};

const initialState: CommunityMemberFieldsState = {
  FIELD_MEMBER_FIELD_SPECS: {},
  FIELD_MEMBER_FIELD_RESPONSES: {},
  FIELD_MEMBER_FIELD_RESPONSE_DRAFTS: {},
  FIELD_MEMBER_SINCE_DRAFTS: {},
};

const clearDrafts = (state: Draft<CommunityMemberFieldsState>) => {
  state.FIELD_MEMBER_FIELD_RESPONSE_DRAFTS = {};
  state.FIELD_MEMBER_SINCE_DRAFTS = {};
};

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    ClearMemberFieldDrafts: (state) => clearDrafts(state),

    MemberFieldSpecsChanged: (state, action: PayloadAction<{ communityId: string, spec: { [fieldId: string]: MemberFieldSpec } }>) => {
      const {communityId, spec} = action.payload;
      const newSpec = _.mapValues(spec, (fieldSpec, fieldId) => {
        fieldSpec.communityId = communityId;
        fieldSpec.fieldId = fieldId;
        return fieldSpec;
      });
      state.FIELD_MEMBER_FIELD_SPECS[communityId] = newSpec;
    },

    MemberFieldResponsesChanged: (state, action: PayloadAction<{ communityId: string, responses: { [userId: string]: { [fieldId: string]: MemberFieldResponse } } }>) => {
      const {communityId, responses} = action.payload;
      state.FIELD_MEMBER_FIELD_RESPONSES[communityId] = responses;
    },

    MemberFieldDraftChanged: (state, action: PayloadAction<{ communityId: string, fieldId: string, value: string }>) => {
      const {communityId, fieldId, value} = action.payload;
      if (!state.FIELD_MEMBER_FIELD_RESPONSE_DRAFTS[communityId]) {
        state.FIELD_MEMBER_FIELD_RESPONSE_DRAFTS[communityId] = {};
      }
      state.FIELD_MEMBER_FIELD_RESPONSE_DRAFTS[communityId][fieldId] = value;
    },

    MemberSinceDraftChanged: (state, action: PayloadAction<{ communityId: string, month: string, year: number }>) => {
      const {communityId, month, year} = action.payload;
      state.FIELD_MEMBER_SINCE_DRAFTS[communityId] = {month, year};
    },

    OneMemberFieldResponsesChanged: (state, action: PayloadAction<{ communityId: string, userId: string, responses: { [fieldId: string]: MemberFieldResponse } }>) => {
      const {communityId, userId, responses} = action.payload;
      if (!state.FIELD_MEMBER_FIELD_RESPONSES[communityId]) {
        state.FIELD_MEMBER_FIELD_RESPONSES[communityId] = {};
      }
      state.FIELD_MEMBER_FIELD_RESPONSES[communityId][userId] = responses;
    },
  },
  extraReducers: builder => builder
      .addCase(AppColdStart, (state) => clearDrafts(state))
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, () => initialState),

});

const {actions, reducer: CommunityMemberFieldsReducer} = slice;

const {
  ClearMemberFieldDrafts,
  MemberFieldDraftChanged,
  MemberFieldResponsesChanged,
  MemberFieldSpecsChanged,
  MemberSinceDraftChanged,
  OneMemberFieldResponsesChanged,
} = actions;

export {
  ClearMemberFieldDrafts,
  MemberFieldDraftChanged,
  MemberFieldResponsesChanged,
  MemberFieldSpecsChanged,
  MemberSinceDraftChanged,
  OneMemberFieldResponsesChanged,
  CommunityMemberFieldsReducer,
};