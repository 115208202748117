import {createSelector} from 'reselect';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {selectAllUserProfiles} from '@heylo/shared/src/features/userProfiles/Selectors';
import _ from 'lodash';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

export const AllMessageReactionsSelector = (state: RootState) => state.reactions.FIELD_MESSAGE_TO_REACTIONS || {};
export const AllCommunityPhotoReactionsSelector = (state: RootState) => state.reactions.FIELD_COMMUNITY_PHOTO_REACTIONS || {};

export const MessageReactionsSelector = (state: RootState, messageId: string) => AllMessageReactionsSelector(state)[messageId];

export const LastReactionIdForThreadSelector = (state: RootState, threadId: string) => {
  return state.reactions.FIELD_THREAD_TO_LAST_REACTION_ID[threadId] || '';
};

export const CurrentReactionForMessageSelector = (state: RootState, activeUserId: string, messageId: string) => {
  const allReactions = state.reactions.FIELD_MESSAGE_TO_REACTIONS[messageId] || {};
  for (const [reactionUserId, reactionType] of Object.entries(allReactions)) {
    if (activeUserId === reactionUserId) {
      return reactionType;
    }
  }
  return null;
};

export const ActiveCommunityPhotoReactionsSelector = createSelector(
    [AllCommunityPhotoReactionsSelector, SelectActiveCommunityId],
    (allReactions, activeCommunityId) => {
      return allReactions[activeCommunityId] || {};
    });

export const makeReactionListForMessageSelector = (messageId: string) => createSelector(
    AllMessageReactionsSelector,
    selectAllUserProfiles,
    (allMessageReactions, allUserProfiles)
        : [string, number][] => {
      const messageReactions = Object.entries(allMessageReactions[messageId] || {});
      return _.sortBy(messageReactions,
          ([userId]) => {
            const profile = allUserProfiles[userId];
            const fullName = profile?.fullName || '';
            return fullName.toLocaleLowerCase();
          });
    });

export const makeReactionListForPhotoSelector = (photoId: string) => createSelector(
    ActiveCommunityPhotoReactionsSelector,
    selectAllUserProfiles,
    (allCommunityPhotoReactions, allUserProfiles)
        : [string, number][] => {
      const allReactions = Object.entries(allCommunityPhotoReactions[photoId] || {});
      return _.sortBy(allReactions,
          ([userId]) => {
            const profile = allUserProfiles[userId]
            const fullName = profile?.fullName || '';
            return fullName.toLocaleLowerCase();
          });
    });

