import React, {useCallback, useContext, useMemo} from 'react';
import {StyleSheet, Text, View, ViewStyle} from 'react-native';
import {MessageBio} from './MessageBio';
import {Message} from './Message';
import {shallowEqual, useSelector} from 'react-redux';
import {ChatScreenContext} from './ChatScreenContext';
import {ReactionCounts} from '../reactions/ReactionCounts';
import {Message as FirebaseMessage} from '@heylo/shared/src/types/firebase-types';
import {ReactionChooser} from '../reactions/ReactionChooser';
import {SelectUserFullName} from '@heylo/shared/src/features/userProfiles/Selectors';
import {ContentSendStateSelector} from '@heylo/shared/src/features/ui/Selectors';
import {
  CurrentReactionForMessageSelector,
  MessageReactionsSelector,
} from '@heylo/shared/src/features/reactions/Selectors';
import {ThreadTimestamp} from './ThreadTimestamp';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {StyleVars} from '../../styles/StyleVars';

type Props = {
  anyMessageIsSelected: boolean,
  isSelected: boolean,
  isUser: boolean,
  message: FirebaseMessage,
  messageRepliedTo: FirebaseMessage | null,
  onImageSelected: (messageId: string) => void,
  onMessageSelected: (message: FirebaseMessage | null) => void,
  onReactionCountsPress: (message: FirebaseMessage) => void,
  onReactionPress: (message: FirebaseMessage, reactionType: number | null) => void,
  showLastReadMessage: boolean,
  showReactionsMenu: boolean,
  showSender: boolean,
  showTimestamp: boolean,
  userId: string,
};

export const MessageContainer = React.memo((props: Props) => {
  console.count('MessageContainer');

  const {
    anyMessageIsSelected,
    isSelected,
    isUser,
    message,
    messageRepliedTo,
    onImageSelected,
    onMessageSelected,
    onReactionPress,
    onReactionCountsPress,
    showLastReadMessage,
    showReactionsMenu,
    showSender,
    showTimestamp,
    userId,
  } = props;

  const messageId = message.key || '';

  const {
    currentSelectedReaction,
    reactions,
    sendState,
    userFullName,
  } = useSelector((state: RootState) => ({
    currentSelectedReaction: isSelected ? CurrentReactionForMessageSelector(state, userId, messageId) : null,
    reactions: MessageReactionsSelector(state, messageId),
    sendState: ContentSendStateSelector(state, messageId),
    userFullName: SelectUserFullName(state, userId),
  }), shallowEqual);

  const {threadType} = useContext(ChatScreenContext);

  const showBio = showSender || showTimestamp;

  const onPressAdd = useCallback(() => onMessageSelected(message), [message]);
  const onPressCount = useCallback(() => onReactionCountsPress(message), [message]);
  const onReactionChooserPress = useCallback((reactionType) => onReactionPress(message, reactionType), [message]);

  const hasReactions = reactions && reactions.size > 0;
  const reactionChooserStyle = useMemo<ViewStyle>(() => ({
    justifyContent: 'center',
    top: hasReactions ? -5 : 0,
    width: '100%',
  }), [hasReactions]);

  return (
      <View style={styles.container}>
        {showLastReadMessage && (
            <View style={styles.newMessagesContainer}>
              <View style={styles.newMessagesLine}/>
              <Text style={styles.newMessagesText}>
                ▼ New Messages ▼
              </Text>
              <View style={styles.newMessagesLine}/>
            </View>
        )}
        {showTimestamp && (
            <ThreadTimestamp timestamp={message.createdAt || 0}/>
        )}
        {showBio && (
            <MessageBio
                isUser={isUser}
                userId={userId}
                userFullName={userFullName}
                threadType={threadType}
            />
        )}

        <Message
            anyMessageIsSelected={anyMessageIsSelected}
            message={message}
            messageId={messageId}
            messageRepliedTo={messageRepliedTo}
            isSelected={isSelected}
            isUser={isUser}
            onImageSelected={onImageSelected}
            onMessageSelected={onMessageSelected}
            sendState={sendState}
            threadType={threadType}
        />

        <ReactionCounts
            containerStyle={{
              justifyContent: isUser ? 'flex-end' : 'flex-start',
              marginHorizontal: 40,
              top: -5,
            }}
            onPressAdd={onPressAdd}
            onPressCount={onPressCount}
            reactions={reactions}
            uniqueId={messageId}
        />

        <ReactionChooser
            containerStyle={reactionChooserStyle}
            currentSelectedReaction={currentSelectedReaction}
            onPress={onReactionChooserPress}
            visible={showReactionsMenu}
        />
      </View>
  );
});

// @ts-ignore
MessageContainer.whyDidYouRender = true;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  newMessagesContainer: {
    height: 18,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 10,
  },
  newMessagesLine: {
    width: '25%',
    height: 1,
    backgroundColor: 'lightgrey',
  },
  newMessagesText: {
    fontSize: 14,
    color: StyleVars.Colors.Purple,
    width: '50%',
    textAlign: 'center',
  },
});