import {
  CommunityMember,
  CommunityProspect,
  MemberFieldResponse,
} from '@heylo/shared/src/types/firebase-types';
import {
  ClearMemberFieldDrafts,
  CommunityMemberSince,
  OneMemberFieldResponsesChanged,
} from '@heylo/shared/src/features/communityMemberFields/Slice';
import {
  CommunityMembersLoaded,
  MemberSinceDraftsSaved,
  OneCommunityMemberUpdated,
} from '@heylo/shared/src/features/communities/Slice';
import {listenForUserProfile} from '@heylo/shared/src/features/userProfiles/Firebase';
import {
  FirebaseDatabase,
  FirebaseServerTimestamp,
} from '@heylo/firebase-database';
import {
  Listeners,
  RetriableFirebaseListener,
} from '@heylo/shared/src/features/firebase/Listeners';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {CommunityProspectStatus} from '@heylo/shared/src/types/CommunityProspect';

export const MembersFirebase = {

  LoadOneCommunityMember: (communityId: string, memberId: string, retryCount = 0)
      : ThunkReturnType<void> => dispatch => {
    FirebaseDatabase().ref(`/communityMembers/${communityId}/${memberId}`)
        .once('value')
        .then(snapshot => {
          const member: CommunityMember = snapshot.val() || {};
          dispatch(OneCommunityMemberUpdated({
            communityId,
            userId: memberId,
            member,
          }));
        })
        .catch(e => {
          console.info('failed to load one community member', communityId, memberId, e.message);
          if (retryCount <= 4) {
            setTimeout(() => {
              dispatch(MembersFirebase.LoadOneCommunityMember(communityId, memberId, retryCount + 1));
            }, Math.pow(retryCount, 2) * 1000);
          }
        });
  },

  LoadMemberFieldResponsesForCommunity: (communityId: string, userId: string)
      : ThunkReturnType<void> => dispatch => {
    const key = communityId + userId;
    if (Listeners.COMMUNITY_MEMBER_FIELD_RESPONSES[key]) {
      return;
    }
    RetriableFirebaseListener(retry => {
      const ref = FirebaseDatabase().ref(`/memberFieldResponses/${communityId}/${userId}`);
      Listeners.COMMUNITY_MEMBER_FIELD_RESPONSES[key] = ref;
      ref.on('value',
          snapshot => {
            const responses: { [key: string]: MemberFieldResponse } = snapshot?.val() || {};
            dispatch(OneMemberFieldResponsesChanged({
              communityId,
              userId,
              responses,
            }));
          },
          (e: Error) => {
            delete Listeners.COMMUNITY_MEMBER_FIELD_RESPONSES[key];
            if (retry(e)) {
              return;
            }
            console.warn('could not read from /memberFieldResponses', communityId, userId, e.message);
          });
    })
  },

  SaveMemberFieldResponseDrafts: (userId: string, drafts: { [communityId: string]: { [fieldId: string]: string } })
      : ThunkReturnType<void> => dispatch => {
    const updates: { [path: string]: any } = {};
    for (const [communityId, fields] of Object.entries(drafts)) {
      if (!fields)
        continue;
      updates[`/communityMembers/${communityId}/${userId}/profileUpdatedTimestamp`] = FirebaseServerTimestamp();
      for (const [fieldId, value] of Object.entries(fields)) {
        updates[`/memberFieldResponses/${communityId}/${userId}/${fieldId}/valueString`] = value;
      }
    }
    FirebaseDatabase().ref().update(updates)
        .catch(e => {
          console.warn('failed to write to /memberFieldResponses', e.message);
        });
    dispatch(ClearMemberFieldDrafts());
  },

  SaveMemberSinceDrafts: (userId: string, drafts: { [communityId: string]: CommunityMemberSince })
      : ThunkReturnType<void> => dispatch => {
    dispatch(MemberSinceDraftsSaved({userId, memberSinceMap: drafts}));
    const updates: any = {};
    for (const [communityId, memberSince] of Object.entries(drafts)) {
      const {month, year} = memberSince;
      if (month && year) {
        updates[`/communityMembers/${communityId}/${userId}/memberSinceMonth`] = month;
        updates[`/communityMembers/${communityId}/${userId}/memberSinceYear`] = year;
      }
    }
    FirebaseDatabase().ref().update(updates)
        .catch(e => {
          console.warn('failed to write member since drafts', userId, drafts, e.message);
        });
  },

  ApproveProspectiveMember: (communityId: string, memberId: string) => {
    const updates: CommunityProspect = {
      finalizationTimestamp: FirebaseServerTimestamp(),
      status: CommunityProspectStatus.APPROVED,
    };
    FirebaseDatabase().ref(`/communityProspects/${communityId}/${memberId}`).update(updates);
  },

  IgnoreProspectiveMember: (communityId: string, memberId: string) => {
    const updates: CommunityProspect = {
      finalizationTimestamp: FirebaseServerTimestamp(),
      status: CommunityProspectStatus.IGNORED,
    };
    FirebaseDatabase().ref(`/communityProspects/${communityId}/${memberId}`).update(updates);
  },

};

export const listenForCommunityMembers = (communityId: string)
    : ThunkReturnType<void> => dispatch => {
  if (!communityId) {
    return;
  }
  if (Listeners.COMMUNITY_MEMBERS[communityId]) {
    return;
  }
  const ref = FirebaseDatabase().ref(`/communityMembers/${communityId}`);
  Listeners.COMMUNITY_MEMBERS[communityId] = ref;
  ref.on('value', (snapshot) => {
    const members: { [userId: string]: CommunityMember } = snapshot?.val() || {};
    dispatch(CommunityMembersLoaded({communityId, members}));
    // TODO: consider optimizing this, so we're not loading listeners for
    // every member everytime you enter the app. This might be very expensive
    // for larger communities.
    for (const userId of Object.keys(members)) {
      dispatch(listenForUserProfile(userId));
    }
  }, (e: Error) => {
    console.log('lost connection to community members', e.message);
    delete Listeners.COMMUNITY_MEMBERS[communityId];
  });
};