import React from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';

export const LoadingScreen = () => {
  return (
      <View style={{alignItems: 'center', flex: 1, justifyContent: 'center',}}>
        <ActivityIndicator size={'large'}/>
      </View>
  );
};