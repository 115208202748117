import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {MarketplaceCommunitiesList} from '@heylo/components/src/features/marketplaces/MarketplaceCommunitiesList';
import {ScrollView, View} from 'react-native';
import {Breadcrumbs} from '../navigation/Breadcrumbs';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {HeyloFilterInput} from '@heylo/components/src/ui/input/HeyloFilterInput';
import {useMarketplaceAuth} from '@heylo/components/src/features/marketplaces/useMarketplaceAuth';
import {HeyloContainer} from '@heylo/components/src/ui/container/HeyloContainer';
import {WebEmailVerificationForm} from '../auth/WebEmailVerificationForm';
import {EmailVerificationContext} from '@heylo/shared/src/features/auth/Slice';
import {
  resolveMarketplaceCasualName,
  resolveUriForGroup,
} from '@heylo/shared/src/features/marketplaces/Util';
import {ScrollToTopOnMount} from '../navigation/ScrollToTopOnMount';
import {useActiveMarketplaceState} from '@heylo/components/src/features/marketplaces/useActiveMarketplaceState';


export const MarketplaceGroupsScreen = () => {
  console.count('MarketplaceGroupsScreen');

  const history = useHistory();
  const {marketplaceId: urlMarketplaceId = ''} = useParams();

  const {marketplace} = useActiveMarketplaceState(urlMarketplaceId);

  const onPress = (communityId: string) => {
    history.push(resolveUriForGroup(communityId));
  };

  const [filterText, setFilterText] = useState('');

  const {hasPermission} = useMarketplaceAuth();
  if (!hasPermission) {
    return (
        <WebEmailVerificationForm
            context={EmailVerificationContext.DISCOVER_MARKETPLACE}
            percent={'0%'}
        />
    );
  }
  return (
      <ScrollView
          contentContainerStyle={{minHeight: '100%'}}
          style={{height: '100%'}}
      >
        <HeyloContainer maxWidth={'xs'}>
          <ScrollToTopOnMount/>
          <div style={{
            backgroundColor: 'white',
            position: 'sticky',
            top: -1,
            zIndex: 10,
          }}>
            <Breadcrumbs crumbs={[{label: 'Find groups', to: '/m/'}]}
                         title={`${resolveMarketplaceCasualName(marketplace)} groups`}
            />
            <View style={{
              paddingHorizontal: StyleConstants.SPACING,
              paddingVertical: StyleConstants.SPACING,
            }}>
              <HeyloFilterInput
                  editable={true}
                  label={'Search by group, school, or city'}
                  onChangeText={setFilterText}
                  value={filterText}
              />
            </View>
          </div>
          <MarketplaceCommunitiesList
              filterText={filterText}
              onPress={onPress}
          />
          <View style={{height: 50}}/>
        </HeyloContainer>
      </ScrollView>
  );
};