import './wdyr';

// tslint:disable-next-line:no-import-side-effect
import 'tslib';
// tslint:disable-next-line:no-import-side-effect
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import {isProductionEnvironment} from './lib/util';

ReactGA.initialize('UA-128694114-1', {
  debug: !isProductionEnvironment(),
  testMode: !isProductionEnvironment(),
});

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
