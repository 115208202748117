import {WebAppDispatch} from 'store';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {
  HeyloButton,
  HeyloDialog,
  HeyloText,
  HeyloTextInput,
} from 'lib/materialUi';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, {useState} from 'react';
import SendIcon from '@material-ui/icons/Send';
import isEmail from 'validator/lib/isEmail';
import Alert from '@material-ui/lab/Alert';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {FirebaseCreateEmailInvites} from '@heylo/shared/src/features/invites/Firebase';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {ShareCommunityButton} from '../community/ShareCommunityButton';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

enum State {
  INITIAL,
  SUBMITTED,
  SUCCESS,
  ERROR,
}

const mapState = (state: RootState) => ({
  activeCommunityId: SelectActiveCommunityId(state),
  activeUserId: selectActiveUserId(state),
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & {
  onClose: () => void,
  open: boolean,
}

const buttonStyles = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '3rem',
  marginTop: '2rem',
};

export const AddMembersDialog = connector((props: Props) => {
  const {
    activeCommunityId,
    activeUserId,
    onClose,
    open,
  } = props;

  const dispatch: WebAppDispatch = useDispatch();
  const logger = useLoggingService();

  const [emails, setEmails] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [state, setState] = useState(State.INITIAL);

  const submitClicked = () => {
    setState(State.SUBMITTED);
    const errors = [];
    const inviteList = [];
    const tokens = emails.replace(/\s+/g, ',').split(',');
    for (const token of tokens) {
      const trimmed = token.trim();
      if (!trimmed) {
        continue;
      }
      if (isEmail(trimmed)) {
        inviteList.push(trimmed);
      } else {
        const match = trimmed.match(/<(.*?)>/);
        if (match && isEmail(match[1])) {
          inviteList.push(match[1]);
        } else {
          errors.push(trimmed);
        }
      }
    }
    if (errors.length > 0) {
      setState(State.ERROR);
      setErrorMessage(errors.slice(0, 3).join(', ') + (errors.length > 3 ? ', ...' : ''));
      return;
    }

    if (inviteList.length > 0) {
      logger.logEvent(AnalyticsEvent.INVITE_EMAIL_SENT, {count: inviteList.length});
      const plural = inviteList.length > 1;
      dispatch(FirebaseCreateEmailInvites(activeCommunityId, activeUserId, inviteList))
          .then(() => {
            setEmails('');
            setState(State.SUCCESS);
            setSuccessMessage(`Successfully sent ${inviteList.length} invite${plural ? 's' : ''}`);
          })
          .catch(e => {
            setState(State.ERROR);
            setErrorMessage('Some invites may not have been successfully created. Please try again or contact support@heylo.co');
          });
    }
  };

  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
      <HeyloDialog
          fullScreen={matchXs}
          fullWidth
          maxWidth={'sm'}
          onClose={onClose}
          open={open}
          title={'Add members'}
      >
        <HeyloText variant={'h5'}>
          Option #1: Share your group link
        </HeyloText>
        <HeyloText paragraph variant={'body1'}>
          Post this link anywhere you like (e.g. Instagram bio). Anyone can use it to request to join.
        </HeyloText>

        <div style={buttonStyles}>
          <ShareCommunityButton communityId={activeCommunityId}/>
        </div>

        <HeyloText variant={'h5'}>
          Option #2: Add by email
        </HeyloText>
        <HeyloText paragraph variant={'body1'}>
          Members you add by email will be invited to join. In addition, they will start to receive email updates for announcements and new events.
        </HeyloText>

        <HeyloTextInput
            error={state === State.ERROR}
            label={'Email addresses'}
            helperText={'Add one or more email addresses'}
            multiline
            onChange={setEmails}
            value={emails}
        />

        {state === State.ERROR && (
            <Alert severity={'error'}>
              Please double check these email addresses: {errorMessage}
            </Alert>
        )}

        {state === State.SUCCESS && (
            <Alert severity={'success'}>
              {successMessage}
            </Alert>
        )}

        <div style={buttonStyles}>
          <HeyloButton
              busy={state === State.SUBMITTED}
              color={'primary'}
              fullWidth={false}
              label={'Send invites'}
              onClick={submitClicked}
              startIcon={<SendIcon/>}
          />
        </div>
      </HeyloDialog>
  );
});