import React from 'react';
import {THREAD_TYPE_DIRECT} from '@heylo/shared/src/types/ThreadTypes';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {Text, View} from 'react-native';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';

type Props = {
  isActive?: boolean,
  threadType: string,
};

export const PrivateTopicLabel = React.memo((props: Props) => {
  const {isActive, threadType} = props;
  const palette = usePalette();
  const text = useTextStyles();
  return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <MaterialCommunityIcon
            color={isActive ? 'white' : palette.secondary.main}
            name="eye-off"
            size={20}
        />
        <Text
            style={{
              ...text.body1,
              color: isActive ? 'white' : palette.secondary.main,
              marginLeft: 5,
            }}
        >
          {threadType === THREAD_TYPE_DIRECT ? 'Direct chat' : 'Private topic'}
        </Text>
      </View>
  );
});