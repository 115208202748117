import React, {useEffect, useRef} from 'react';
import {
  Animated,
  Easing,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import {ActivityIndicator} from 'react-native-paper';

type Props = {
  onPress: () => void,
  showActivityIndicator?: boolean,
  visible?: boolean,
};

const ConfirmButton = (props: Props) => {
  const {
    onPress,
    showActivityIndicator,
    visible = true,
  } = props;

  const palette = usePalette();
  const animatedSize = useRef(new Animated.Value(0)).current;
  const maxSize = 40;

  useEffect(() => {
    if (visible) {
      Animated.timing(animatedSize, {
        easing: Easing.cubic,
        toValue: maxSize,
        duration: 400,
        useNativeDriver: false,
      }).start();
    }
  }, [visible]);

  if (showActivityIndicator) {
    return (
        <View style={{marginHorizontal: 20}}>
          <ActivityIndicator size={'small'}/>
        </View>
    );
  } else if (visible) {
    return (
        <TouchableOpacity
            onPress={() => {
              if (typeof onPress === 'function') {
                onPress();
              }
            }}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              width: maxSize,
              height: maxSize,
              marginRight: 10,
            }}
        >
          <Animated.View style={{
            alignItems: 'center',
            backgroundColor: palette.primary.main,
            borderRadius: 100,
            height: animatedSize,
            justifyContent: 'center',
            width: animatedSize,
          }}>
            <MaterialCommunityIcon
                color={'white'}
                name="check"
                size={28}
                style={{top: Platform.OS === 'ios' ? 1 : 0}}
            />
          </Animated.View>
        </TouchableOpacity>
    );
  }
  return <View/>;
}

export default ConfirmButton;