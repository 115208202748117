import {StripeCard, StripeUser} from '@heylo/shared/src/types/firebase-types';
import {HeyloDialog, HeyloText} from '../../lib/materialUi';
import PaymentIcon from '@material-ui/icons/Payment';
import DeleteIcon from '@material-ui/icons/Delete';
import React, {useEffect, useState} from 'react';
import {ResolveStripeCardFromPaymentMethod} from '@heylo/shared/src/features/stripe/StripeCard';
import {StripeFirebase} from '@heylo/shared/src/features/stripe/Firebase';
import {useDispatch} from 'react-redux';

type Props = {
  stripeUser: StripeUser | null,
};

export const PaymentMethod = (props: Props) => {
  const {stripeUser} = props;
  const [card, setCard] = useState<StripeCard | null>(null);
  useEffect(() => {
    setCard(ResolveStripeCardFromPaymentMethod(stripeUser?.paymentMethod));
  }, [stripeUser]);

  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const openDialog = () => {
    setShowRemoveDialog(true);
  };

  const closeDialog = () => {
    setShowRemoveDialog(false);
  };

  const dispatch = useDispatch();

  const removePaymentMethod = async () => {
    await dispatch(StripeFirebase.RemovePaymentMethod());
    closeDialog();
  };

  if (!card) {
    return null;
  }
  const {brand, last4, expiresYear, expiresMonth} = card;
  const renderPaymentIcon = () => {
    let imageUrl = '';
    switch (brand) {
      case 'Visa':
        imageUrl = '/assets/visa.png';
        break;
      case 'Mastercard':
        imageUrl = '/assets/mastercard.png';
        break;
      case 'American Express':
        imageUrl = '/assets/american-express.png';
        return;
      case 'Diners Club':
        imageUrl = '/assets/diners.png';
        return;
      case 'Discover':
        imageUrl = '/assets/discover.png';
        return;
      case 'JCB':
        imageUrl = '/assets/jcb.png';
        return;
    }
    if (imageUrl) {
      return (
          <img
              alt={brand}
              src={imageUrl}
              style={{
                height: '4rem',
                objectFit: 'contain',
                width: '6rem',
              }}
          />
      );
    }
    return <PaymentIcon/>;
  }
  return (
      <div className={'flexrow'} style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: 0,
        width: '100%',
      }}>
        <div className={'flexrow flexcenter'}>
          {renderPaymentIcon()}
          <div style={{margin: '1rem'}}>
            <HeyloText variant={'body1'}>
              {brand}
            </HeyloText>
            <HeyloText variant={'body1'}>
              {`•••• ${last4 || '????'} Exp ${expiresMonth || '??'}/${expiresYear || '??'}`}
            </HeyloText>
          </div>
        </div>

        <div onClick={openDialog}>
          <DeleteIcon/>
        </div>

        <HeyloDialog
            actions={[{
              label: 'Cancel',
            }, {
              label: 'Remove',
              onClick: removePaymentMethod,
            }]}
            maxWidth={'xs'}
            onClose={closeDialog}
            open={showRemoveDialog}
            title={'Remove payment method?'}
        >
          <HeyloText variant={'body1'}>
            Would you like to remove the linked payment method from your account?
          </HeyloText>
        </HeyloDialog>
      </div>

  );
};