import React, {useEffect, useRef, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {LoggingService} from '@heylo/shared/src/services/logging/LoggingService';
import {CommunityAmplitudeLogger} from '@heylo/shared/src/services/logging/CommunityAmplitudeLogger';
import {AuthenticatedAmplitudeLogger} from '@heylo/shared/src/services/logging/AuthenticatedAmplitudeLogger';
import {AnonymousAmplitudeLogger} from '@heylo/shared/src/services/logging/AnonymousAmplitudeLogger';
import {LoggingContext} from '@heylo/shared/src/services/logging/LoggingContext';
import {Firebase} from 'features/firebase';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {selectActiveCommunityProfile} from '@heylo/shared/src/features/communityProfiles/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {
  HasProfilePhoto,
  SelectActiveUserProfile,
} from '@heylo/shared/src/features/userProfiles/Selectors';
import {
  SelectActiveUserIsAdminInActiveCommunity,
  SelectActiveUserIsAmbassadorInActiveCommunity,
} from '@heylo/shared/src/features/communityMembers/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

const mapState = (state: RootState) => ({
  activeCommunityId: SelectActiveCommunityId(state),
  activeCommunityProfile: selectActiveCommunityProfile(state),
  activeUserId: selectActiveUserId(state),
  activeUserProfile: SelectActiveUserProfile(state),
  isAdmin: SelectActiveUserIsAdminInActiveCommunity(state),
  isAmbassador: SelectActiveUserIsAmbassadorInActiveCommunity(state),
});

const connector = connect(mapState);
type Props = ConnectedProps<typeof connector> & {
  children: any | any[];
};

const BUILD_VARIANT = process.env.REACT_APP_BUILD_VARIANT || '';

export const LoggingContextProvider = connector((props: Props) => {
  const {
    activeCommunityId,
    activeCommunityProfile,
    activeUserId,
    activeUserProfile,
    children,
    isAdmin,
    isAmbassador,
  } = props;

  const [anonId, setAnonId] = useState('');
  useEffect(() => {
    Firebase.installations().getId().then(setAnonId);
  }, []);


  const anonymousLogger = useRef(new AnonymousAmplitudeLogger(process.env.REACT_APP_AMPLITUDE_API_KEY_ANONYMOUS || '', BUILD_VARIANT)).current;
  const authenticatedLogger = useRef(new AuthenticatedAmplitudeLogger(process.env.REACT_APP_AMPLITUDE_API_KEY_AUTHENTICATED || '', BUILD_VARIANT)).current;
  const communityLogger = useRef(new CommunityAmplitudeLogger(process.env.REACT_APP_AMPLITUDE_API_KEY_COMMUNITIES || '', BUILD_VARIANT)).current;
  const logger = useRef(new LoggingService([anonymousLogger, authenticatedLogger, communityLogger]));

  useEffect(() => {
    const {communityName = ''} = activeCommunityProfile;
    communityLogger.setLoggingKey(activeCommunityId || '');
    communityLogger.setUserProperties({communityName});
    authenticatedLogger.setSuperProperties({
      communityId: activeCommunityId,
      communityName,
    })
    anonymousLogger.setSuperProperties({
      communityId: activeCommunityId,
      communityName,
    })
  }, [activeCommunityId, activeCommunityProfile, authenticatedLogger, communityLogger]);

  useEffect(() => {
    anonymousLogger.setLoggingKey(activeUserId ? '' : anonId);
    authenticatedLogger.setLoggingKey(activeUserId || '');
    communityLogger.setSuperProperties({userId: activeUserId});
  }, [anonId, activeUserId, anonymousLogger, authenticatedLogger, communityLogger]);

  useEffect(() => {
    if (!activeUserId || !activeUserProfile) {
      return;
    }
    const {fullName = '', bio = '', goals = ''} = activeUserProfile;
    authenticatedLogger.setUserProperties({
      fullName,
      hasAboutMe: !!bio,
      hasInterests: !!goals,
      hasProfilePhoto: HasProfilePhoto(activeUserProfile),
    });
  }, [activeUserId, activeUserProfile, authenticatedLogger]);

  useEffect(() => {
    authenticatedLogger.setSuperProperties({isAdmin, isAmbassador});
  }, [isAdmin, isAmbassador]);

  return (
      <LoggingContext.Provider value={logger.current}>
        {children}
      </LoggingContext.Provider>
  );
});
