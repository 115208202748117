import color from 'color';

export const usePalette = () => {
  const primary = color('#32c884');
  const secondary = color('#7D50E7');
  const grey = color('#8f8f8f');
  const red = color('red');

  return {
    primary: {
      lightest: primary.lighten(0.9).string(),
      light: primary.lighten(0.5).string(),
      main: primary.string(),
      dark: primary.darken(0.5).string(),
      darkest: primary.darken(0.9).string(),
    },
    secondary: {
      lightest: secondary.lighten(0.9).string(),
      light: secondary.lighten(0.5).string(),
      main: secondary.string(),
      dark: secondary.darken(0.5).string(),
      darkest: secondary.darken(0.9).string(),
    },
    grey: {
      lightest: grey.lighten(0.7).string(),
      light: grey.lighten(0.5).string(),
      main: grey.string(),
      dark: grey.darken(0.5).string(),
      darkest: grey.darken(0.7).string(),
    },
    error: {
      medium: red.string(),
    }
  };
};