import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {HeyloText} from '../../lib/materialUi';
import {TopicCard} from './TopicCard';
import {useTopicsState} from '@heylo/shared/src/features/topics/useTopicsState';
import {Thread} from '@heylo/shared/src/types/firebase-types';
import {ActiveCommunityImpressionsSelector} from '@heylo/shared/src/features/userEvents/Selectors';
import {ScrollToTopOnMount} from '../navigation/ScrollToTopOnMount';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {useHistory, useParams} from 'react-router-dom';
import {ACTION_SET_CURRENT_THREAD} from '@heylo/shared/src/features/threads/Slice';
import {ThreadScreen} from './ThreadScreen';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {ActiveCommunityUpdated} from '@heylo/shared/src/features/communities/Slice';
import useTheme from '@material-ui/core/styles/useTheme';
import {useMediaQuery} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {ScrollView, View} from 'react-native';
import {IncrementCommunityImpression} from '@heylo/shared/src/features/userEvents/Actions';
import {
  ChatInfoState,
  EditTopic,
} from '@heylo/components/src/features/topics/EditTopic';
import {useNavigationUtils} from '../navigation/useNavigationUtils';
import {AddThreadMembersModal} from '@heylo/components/src/features/threads/AddThreadMembersModal';
import {BottomStickyButton} from '@heylo/components/src/ui/button/BottomStickyButton';

const mapState = (state: RootState) => {
  return {
    communityImpressions: ActiveCommunityImpressionsSelector(state),
  };
};
const connector = connect(mapState, {});
type Props = ConnectedProps<typeof connector>;

enum Bucket {
  NEW,
  JOINED,
  NOT_JOINED,
}

export const PureTopicSummary = (props: Props) => {
  const {
    communityImpressions,
  } = props;

  const dispatch = useDispatch();

  const {
    communityId: urlCommunityId = '',
    threadId: urlThreadId = '',
  } = useParams();

  useEffect(() => {
    dispatch(ActiveCommunityUpdated(urlCommunityId));
    dispatch(IncrementCommunityImpression(urlCommunityId, 'TopicsScreen'));
    return () => {
      dispatch(IncrementCommunityImpression(urlCommunityId, 'TopicsScreen'));
    }
  }, [urlCommunityId]);

  useEffect(() => {
    dispatch(ACTION_SET_CURRENT_THREAD({threadId: urlThreadId}));
  }, [urlThreadId]);

  useEffect(() => {
    dispatch(IncrementCommunityImpression(urlCommunityId, urlThreadId));
  }, [urlCommunityId, urlThreadId]);

  const {
    activeThreadId,
    announcementThread,
    chatInfoVisible,
    joinedActiveTopics,
    joinedInactiveTopics,
    newNotJoinedTopics,
    oldNotJoinedTopics,
    totalThreadCount,
  } = useTopicsState();

  console.count('Topics');

  const renderSectionLabel = (label: string) => {
    return (
        <div style={{padding: StyleConstants.SPACING}}>
          <HeyloText variant={'subtitle2'}>
            {label}
          </HeyloText>
        </div>
    );
  }

  const renderTopics = (topics: ReadonlyArray<Thread>, bucket: Bucket) => {
    return (topics.map(thread => {
      const {threadId = ''} = thread;
      const isNew = bucket === Bucket.NEW && !communityImpressions[threadId];
      return (
          <div key={`topic${threadId}`}>
            <TopicCard
                isActive={threadId === urlThreadId}
                isNew={isNew}
                joined={bucket === Bucket.JOINED}
                thread={thread}
            />
          </div>
      );
    }));
  }
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchMd = useMediaQuery(theme.breakpoints.down('md'));

  const showTopicsList = !((matchSm && urlThreadId) || (matchMd && urlThreadId && chatInfoVisible));


  const history = useHistory();
  const nav = useNavigationUtils();

  const [addMembersDialogVisible, setAddMembersDialogVisible] = useState(false);
  const [state, setState] = useState(ChatInfoState.UNEDITED);

  const onAddMembers = () => {
    setAddMembersDialogVisible(true);
  }

  const onLeave = () => {
    history.push(nav.resolveUriForTopicsSummary());
  }

  const onSuccess = () => {
    setState(ChatInfoState.UNEDITED);
  }

  return (
      <>
        <ScrollToTopOnMount/>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
        }}>
          {showTopicsList ? (
              <PerfectScrollbar>
                <div style={{
                  borderRight: 'solid #c4c4c4 1px',
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                  maxWidth: 400,
                  padding: `${StyleConstants.SPACING / 2}px 0`,
                }}>
                  {announcementThread && (
                      <div>
                        <TopicCard
                            isActive={announcementThread.threadId === urlThreadId}
                            joined={true}
                            thread={announcementThread}
                        />
                      </div>
                  )}

                  {totalThreadCount === 0 && (
                      <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                      }}>
                        <HeyloText paragraph
                                   variant={'h3'}>No topics 😱</HeyloText>
                        <HeyloText paragraph
                                   variant={'h4'}>Create a topic to get the conversation started</HeyloText>
                      </div>
                  )}

                  {newNotJoinedTopics.length > 0 && (
                      <>
                        {renderSectionLabel('NEW TOPICS')}
                        {renderTopics(newNotJoinedTopics, Bucket.NEW)}
                      </>
                  )}

                  {renderSectionLabel('JOINED TOPICS')}
                  {renderTopics(joinedActiveTopics, Bucket.JOINED)}
                  {renderTopics(joinedInactiveTopics, Bucket.JOINED)}

                  {oldNotJoinedTopics.length > 0 && (
                      <>
                        {renderSectionLabel('NOT JOINED')}
                        {renderTopics(oldNotJoinedTopics, Bucket.NOT_JOINED)}
                      </>
                  )}

                  {matchXs ? (
                      <View style={{height: 50}}/>
                  ) : null}
                </div>
              </PerfectScrollbar>
          ) : null}

          {urlThreadId ? (
              <>
                <ScrollView
                    contentContainerStyle={{height: '100%'}}
                    key={`chat${urlThreadId}`}
                    style={{
                      borderRightColor: '#c4c4c4',
                      borderRightWidth: chatInfoVisible ? 1 : 0,
                      flex: 1,
                      flexShrink: 0,
                      height: '100%',
                    }}>
                  <ThreadScreen/>
                </ScrollView>

                {(chatInfoVisible && activeThreadId === urlThreadId) ? (
                    <>
                      <PerfectScrollbar style={{
                        maxWidth: 300,
                        maxHeight: '100%',
                      }}>
                        {/* Separate component required to apply padding without causing excessive scrolling */}
                        <View style={{
                          padding: StyleConstants.SPACING,
                        }}>
                          <EditTopic
                              onAddMembers={onAddMembers}
                              onLeave={onLeave}
                              onSuccess={onSuccess}
                              setState={setState}
                              state={state}
                          />
                          <BottomStickyButton
                              busy={state === ChatInfoState.SUBMIT_PENDING}
                              label={'save'}
                              onPress={() => setState(ChatInfoState.SUBMIT_START)}
                              visible={state === ChatInfoState.EDITED}
                          />
                        </View>
                      </PerfectScrollbar>
                      <AddThreadMembersModal
                          onClose={() => setAddMembersDialogVisible(false)}
                          open={addMembersDialogVisible}
                      />
                    </>
                ) : null}
              </>
          ) : null}
        </div>
      </>
  );
};

export const Topics = connector(PureTopicSummary);
