import React from 'react';
import {Text, View} from 'react-native';
import {Marketplace} from '@heylo/shared/src/types/firebase-types';
import {HeyloListItem} from '@heylo/components/src/ui/list/HeyloListItem';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {useHeyloConfig} from '@heylo/shared/src/features/app/useHeyloConfig';

type Props = {
  context: 'discover' | 'create',
  marketplace: Marketplace,
  onPress: () => void,
  selected?: boolean,
};

export const MarketplaceListItem = React.memo((props: Props) => {
  const {
    marketplace,
    onPress,
    selected,
  } = props;

  const {
    category,
    iconEmoji,
    nameFull,
    location = '',
    test = false,
  } = marketplace;

  const isMetro = category === 'metro';

  const secondaryTextTokens: string[] = [];
  if (!isMetro && location) {
    secondaryTextTokens.push(location);
  }
  const secondaryText = secondaryTextTokens.join(' • ');

  const heyloConfig = useHeyloConfig();
  const palette = usePalette();
  if (test && heyloConfig.BUILD_VARIANT_STRING === 'production') {
    return null;
  }
  return (
      <View style={{
        backgroundColor: selected ? palette.secondary.main : 'white',
      }}>
        <HeyloListItem
            leftElement={
              <View style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Text style={{
                  fontSize: StyleConstants.SPACING * 2,
                  lineHeight: StyleConstants.SPACING * 2 + 6,
                  textAlign: 'center',
                }}>
                  {iconEmoji}
                </Text>
              </View>
            }
            onPress={onPress}
            primaryText={nameFull}
            primaryTextStyle={selected ? {color: 'white'} : undefined}
            secondaryText={secondaryText}
            secondaryTextStyle={selected ? {color: 'white'} : undefined}
            style={{
              // @ts-ignore
              cursor: 'pointer',
              paddingHorizontal: StyleConstants.SPACING,
            }}
        />
      </View>
  );
});