import axios from 'axios';

export const ShortenLink = async (link: string): Promise<string> => {
  return new Promise(resolve => {
    axios({
      method: 'post',
      url: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_API_KEY}`,
      headers: {'content-type': 'application/json'},
      data: {
        "longDynamicLink": link,
        "suffix": {
          "option": "SHORT",
        },
      },
    })
        .then((response) => {
          if (response.data && response.data.shortLink) {
            resolve(response.data.shortLink);
          } else {
            resolve('');
          }
        })
        .catch(e => {
          console.error('error generating shortlink', e.message, link);
          resolve('');
        });
  });
};