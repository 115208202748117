export const AnalyticsEvent = {
  ACCOUNT_PASSWORD_RESET_COMPLETE: 'Account_PasswordResetComplete',
  ACCOUNT_PASSWORD_RESET_START: 'Account_PasswordResetStart',

  ACCOUNT_SIGN_OUT: 'Account_SignOut',
  ACCOUNT_UPGRADED: 'Account_Upgraded',

  ALBUM_ADD_COMPLETE: 'Album_AddComplete',
  ALBUM_ADD_FROM_ALL_PHOTOS: 'Album_AddFromAllPhotos',
  ALBUM_ADD_FROM_PHONE: 'Album_AddFromPhone',
  ALBUM_OPEN: 'Album_Open',
  ALBUM_REMOVE_PHOTOS: 'Album_RemovePhotos',
  ALBUM_REMOVE_PHOTOS_SUBMIT: 'Album_RemovePhotosSubmit',

  AMBASSADOR_ACCEPTED: 'Ambassador_Accepted',
  AMBASSADOR_REJECTED: 'Ambassador_Rejected',

  APP_COMPONENT_ERROR: 'App_ComponentError',
  APP_REDUX_ERROR: 'App_ReduxError',
  APP_UPDATE_BANNER_DISMISS: 'App_UpdateBannerDismiss',
  APP_UPDATE_BANNER_TAPPED: 'App_UpdateBannerTapped',

  BACK: 'Back',

  CHAT_CREATED: 'Conversations_Created',  // New topic created.
  CHAT_DELETE_MESSAGE: 'Chat_DeleteMessage',
  CHAT_EDIT_START: 'Chat_UpdateStart',
  CHAT_EDIT_SUBMIT: 'Chat_Update',
  CHAT_JOINED: 'Conversations_Joined',  // Joined an existing topic.
  CHAT_LEFT: 'Conversations_Left',  // Left an existing topic.
  CHAT_NEW_IMAGE: 'Chat_NewImage',
  CHAT_NEW_MESSAGE: 'Chat_NewMessage',
  CHAT_NEW_MEMBER_NOTIFICATIONS_ON: 'Chat_NewMemberNotificationsOn',
  CHAT_NEW_MEMBER_NOTIFICATIONS_OFF: 'Chat_NewMemberNotificationsOff',
  CHAT_NOTIFICATIONS_OPT_IN: 'Chat_NotificationsOptIn',
  CHAT_NOTIFICATIONS_OPT_OUT: 'Chat_NotificationsOptOut',
  CHAT_OPEN: 'Chat_Open',
  CHAT_THREAD_DELETE: 'Chat_ThreadDelete',
  CHAT_TOP_SHEET_TOGGLE: 'Chat_TopSheetToggle',

  COMMUNITY_CREATED: 'Community_Created',
  COMMUNITY_JOIN_NOW: 'Community_Join',
  COMMUNITY_JOIN_REQUEST: 'Community_JoinRequest',
  COMMUNITY_LEFT: 'Community_Left',
  COMMUNITY_PROFILE_TAPPED: 'Community_ProfileTapped',
  COMMUNITY_PROFILE_UPDATED: 'Community_ProfileUpdated',
  COMMUNITY_SETTINGS: 'Community_Settings',
  COMMUNITY_SHARE_LINK: 'Community_ShareLink',

  DOCUMENT_CREATE: 'Document_Create',
  DOCUMENT_DELETE: 'Document_Delete',
  DOCUMENT_CONTENT_TAPPED: 'Document_ContentTapped',
  DOCUMENT_UPDATE_START: 'Document_UpdateStart',
  DOCUMENT_UPDATE_COMPLETE: 'Document_UpdateComplete',

  // Cancels out of a donation flow.
  DONATION_CANCEL: 'Donation_Cancel',
  // Error while processing a donation.
  DONATION_ERROR: 'Donation_Error',
  // Submits a donation.
  DONATION_SUBMIT: 'Donation_Submit',
  // A suggested donation amount (e.g., on a donation-based event details page).
  DONATION_SUGGESTION: 'Donation_Suggestion',

  DRAWER_ADD_COMMUNITY: 'Drawer_AddCommunity',
  DRAWER_DISCOVER_COMMUNITIES: 'Drawer_DiscoverCommunities',
  DRAWER_RECOMMEND_HEYLO_CANCEL: 'Drawer_RecommendCancel',
  DRAWER_RECOMMEND_HEYLO_COPY: 'Drawer_RecommendCopy',
  DRAWER_RECOMMEND_HEYLO_START: 'Drawer_RecommendStart',
  DRAWER_OPEN: 'Drawer_Open',
  DRAWER_SETTINGS: 'Drawer_Settings',
  DRAWER_SWITCH_COMMUNITY: 'Drawer_SwitchCommunity',

  DYNAMIC_LINK_COMMUNITY_PROFILE: 'DynamicLink_CommunityProfile',
  DYNAMIC_LINK_DONATION_SUCCESS: 'DynamicLink_DonationSuccess',
  DYNAMIC_LINK_DONATION_CANCEL: 'DynamicLink_DonationCancel',
  DYNAMIC_LINK_EVENT_DETAILS: 'DynamicLink_EventDetails',
  DYNAMIC_LINK_INSTALL: 'DynamicLink_Install',
  DYNAMIC_LINK_INVITATION: 'DynamicLink_Invitation',
  DYNAMIC_LINK_MARKETPLACE: 'DynamicLink_Marketplace',
  DYNAMIC_LINK_MESSAGE: 'DynamicLink_Message',
  DYNAMIC_LINK_OPENED: 'DynamicLink_Opened',
  DYNAMIC_LINK_REJECTED: 'DynamicLink_Rejected',

  EMAIL_INVITE: 'Email_Invite',
  EMAIL_INVITE_REMINDER: 'Email_InviteReminder',

  EVENTS_COPY_TO_CALENDAR_NEVER: 'Events_CopyToCalendarNever',
  EVENTS_COPY_TO_CALENDAR_NO: 'Events_CopyToCalendarNo',
  EVENTS_COPY_TO_CALENDAR_YES: 'Events_CopyToCalendarYes',
  EVENTS_CREATE: 'Events_Create',
  EVENTS_CONTENT_TAPPED: 'Events_ContentTapped',
  EVENTS_DATETIME: 'Events_DateTime',
  EVENTS_DELETE: 'Events_Delete',
  EVENTS_LOCATION: 'Events_Location',
  EVENTS_RSVP: 'Events_Rsvp',
  EVENTS_RSVP_JOINING: 'Events_RsvpJoining',
  EVENTS_RSVP_INTERESTED: 'Events_RsvpInterested',
  EVENTS_RSVP_NOT_JOINING: 'Events_RsvpNotJoining',
  EVENTS_SHARE_START: 'Events_ShareStart',
  EVENTS_SHARE_SUCCESS: 'Events_ShareSuccess',
  EVENTS_SHARE_DISMISS: 'Events_ShareDismiss',
  EVENTS_SHOW_PAST: 'Events_ShowPast',
  EVENTS_UPDATE: 'Events_Update',

  IMAGE_DOWNLOAD: 'Image_Download',
  IMAGE_USER_PROFILE: 'Image_UserProfile',
  IMAGE_VIEWED: 'Image_Viewed',

  INSTAGRAM_SHARE_CANCEL: 'Instagram_ShareCancel',
  INSTAGRAM_SHARE_FAILED: 'Instagram_ShareFailed',
  INSTAGRAM_SHARE_PROCEED: 'Instagram_ShareProceed',
  INSTAGRAM_SHARE_STARTED: 'Instagram_ShareStarted',

  INTERESTED_CONTENT_TAPPED: 'Interested_ContentTapped',
  INTERESTED_SHOW_INACTIVE: 'Interested_ShowInactive',

  INTRO_CREATE_COMMUNITY: 'Intro_CreateCommunity',
  INTRO_FIND_COMMUNITY: 'Intro_FindCommunity',
  INTRO_LOGIN: 'Intro_Login',

  INVITE_BULK_IMPORT: 'Invite_BulkImport',
  INVITE_EMAIL_SENT: 'Invite_EmailSent',
  INVITE_LINK_COPIED: 'Invite_LinkCopied',
  INVITE_QR_CODE: 'Invite_QrCode',

  MARKETPLACE_PRESS: 'Marketplace_Press',

  MEMBER_ACCEPT: 'Member_Accept',
  MEMBER_ADD_MORE: 'Member_AddMore',
  // Logged from cloud function, at the moment in time a community member is
  // added to the community.
  MEMBER_CREATED: 'Member_Created',
  // Logged from cloud function, at the moment in time a community member is
  // removed from the community.
  MEMBER_DELETED: 'Member_Deleted',
  MEMBER_IGNORE: 'Member_Ignore',
  MEMBER_MENU: 'Member_Menu',
  MEMBER_PROFILE_TAPPED: 'Member_ProfileTapped',
  MEMBER_REMOVE: 'Member_Remove',

  MENTION_SELECTED: 'Mention_Selected',

  NAV_BOTTOM_TAB_PRESSED: 'Nav_BottomTabPressed',

  NEW_GROUP_CONFIRM_SUBMIT: 'NewGroup_ConfirmSubmit',
  NEW_GROUP_CUSTOM_LOCATION: 'NewGroup_CustomLocation',
  NEW_GROUP_DETAILS_SUBMIT: 'NewGroup_DetailsSubmit',
  NEW_GROUP_LOCATION_SUBMIT: 'NewGroup_LocationSubmit',
  NEW_GROUP_NAME_SUBMIT: 'NewGroup_NameSubmit',

  NOTIFICATION_ACTIVATION_EVENTS: 'Notification_ActivationEvents',
  NOTIFICATION_ACTIVATION_MESSAGING: 'Notification_ActivationMessaging',
  NOTIFICATION_COMMUNITY_ACTIVATION: 'Notification_CommunityActivation',
  NOTIFICATION_COUNTER_FACTUAL: 'Notification_CounterFactual',
  NOTIFICATION_EVENT_DONATION_REMINDER: 'Notification_DonationReminder',
  NOTIFICATION_EVENT_REMINDER: 'Notification_EventReminder',
  // TODO: Not used currently, but could be logged to `Heylo Communities`
  //  whenever an event starts.
  NOTIFICATION_EVENT_START: 'Notification_EventStart',
  NOTIFICATION_OPENED: 'Notification_Opened',

  // Takes user to Stripe account dashboard.
  PAYMENTS_ACCOUNT_DASHBOARD: 'Payments_AccountDashboard',
  // The first step in setting up payments (e.g., linking an account).
  PAYMENTS_SETUP: 'Payments_Setup',

  PROFILE_CREATE_COMPLETE: 'ProfileComplete_Complete',
  PROFILE_CREATE_SKIP: 'ProfileComplete_Skip',

  PROFILE_SEND_MESSAGE: 'Profile_SendMessage',
  PROFILE_UPDATE: 'Profile_Update',

  REACTIONS_ADD: 'Reactions_Add',
  REACTIONS_REMOVE: 'Reactions_Remove',

  REGISTRATION_EMAIL_FAILURE: 'Registration_EmailFailure',
  REGISTRATION_EMAIL_SENT: 'Registration_EmailSent',
  REGISTRATION_EMAIL_SIGNING_IN: 'Registration_EmailSigningIn',
  REGISTRATION_EMAIL_VERIFIED: 'Registration_EmailVerified',
  REGISTRATION_LOG_IN_FAILED: 'Registration_LogInFailed',
  REGISTRATION_LOG_IN_SUCCESS: 'Registration_LogIn',
  REGISTRATION_RESET: 'Registration_Reset',
  REGISTRATION_SIGN_UP_CANCEL: 'Registration_SignUpCancel',
  REGISTRATION_SIGN_UP_FAILED: 'Registration_SignUpFailed',
  REGISTRATION_SIGN_UP_SUCCESS: 'Registration_SignUpSuccess',

  SCREEN_VIEW: 'ScreenView',

  SKIP: 'Skip',
  SUBMIT_ERROR: 'Submit_Error',
  SUBMIT_SUCCESS: 'Submit_Success',

  SURVEY_NPS_DISMISS: 'Survey_NpsDismiss',
  SURVEY_NPS_SUBMIT: 'Survey_NpsSubmit',

  TOPICS_NEW: 'Topics_New',

  // Track taps that acknowledge & dismiss tutorial content.
  TUTORIAL_COMMUNITY_PROFILE_INTRO: 'Tutorial_CommunityProfileIntro',
  TUTORIAL_COMMUNITY_PROFILE_UPDATE: 'Tutorial_CommunityProfileUpdate',
  TUTORIAL_COMMUNITY_PROFILE_SHARE: 'Tutorial_CommunityProfileShare',
  TUTORIAL_CONTENT_NEW_MEMBERS: 'Tutorial_ContentNewMembers',
  TUTORIAL_EVENT_CREATE: 'Tutorial_EventCreate',
  TUTORIAL_EVENT_DIGEST: 'Tutorial_EventDigest',
  TUTORIAL_EVENT_EDIT: 'Tutorial_EventEdit',
  TUTORIAL_EVENT_RSVP: 'Tutorial_EventRsvp',
  TUTORIAL_EVENT_SAMPLE: 'Tutorial_EventSample',
  TUTORIAL_EVENT_SHARE: 'Tutorial_EventShare',
  TUTORIAL_INVITE_BY_EMAIL: 'Tutorial_InviteByEmail',
  TUTORIAL_INVITE_MEMBERS: 'Tutorial_InviteMembers',
  TUTORIAL_PROSPECTIVE_MEMBERS: 'Tutorial_ProspectiveMembers',
  TUTORIAL_SET_PROFILE_PHOTO: 'Tutorial_SetProfilePhoto',
  TUTORIAL_TOPIC_CREATE: 'Tutorial_TopicCreate',
  TUTORIAL_TOPIC_JOIN: 'Tutorial_TopicJoin',
  TUTORIAL_TOPIC_WELCOME_BEGIN: 'Tutorial_TopicWelcomeBegin',
  TUTORIAL_TOPIC_WELCOME_EDIT: 'Tutorial_TopicWelcomeEdit',
};

// Events that should be logged to Amplitude community-centric project.
export const WhitelistedCommunityEvents = {
  [AnalyticsEvent.ALBUM_ADD_COMPLETE]: true,
  [AnalyticsEvent.ALBUM_ADD_FROM_ALL_PHOTOS]: true,
  [AnalyticsEvent.ALBUM_ADD_FROM_PHONE]: true,
  [AnalyticsEvent.ALBUM_OPEN]: true,
  [AnalyticsEvent.ALBUM_REMOVE_PHOTOS]: true,
  [AnalyticsEvent.ALBUM_REMOVE_PHOTOS_SUBMIT]: true,
  [AnalyticsEvent.AMBASSADOR_ACCEPTED]: true,
  [AnalyticsEvent.AMBASSADOR_REJECTED]: true,
  [AnalyticsEvent.CHAT_CREATED]: true,
  [AnalyticsEvent.CHAT_DELETE_MESSAGE]: true,
  [AnalyticsEvent.CHAT_EDIT_START]: true,
  [AnalyticsEvent.CHAT_EDIT_SUBMIT]: true,
  [AnalyticsEvent.CHAT_JOINED]: true,
  [AnalyticsEvent.CHAT_LEFT]: true,
  [AnalyticsEvent.CHAT_NEW_IMAGE]: true,
  [AnalyticsEvent.CHAT_NEW_MESSAGE]: true,
  [AnalyticsEvent.CHAT_THREAD_DELETE]: true,
  [AnalyticsEvent.COMMUNITY_CREATED]: true,
  [AnalyticsEvent.COMMUNITY_PROFILE_TAPPED]: true,
  [AnalyticsEvent.COMMUNITY_PROFILE_UPDATED]: true,
  [AnalyticsEvent.COMMUNITY_SETTINGS]: true,
  [AnalyticsEvent.COMMUNITY_SHARE_LINK]: true,
  [AnalyticsEvent.DOCUMENT_CONTENT_TAPPED]: true,
  [AnalyticsEvent.DOCUMENT_CREATE]: true,
  [AnalyticsEvent.DOCUMENT_DELETE]: true,
  [AnalyticsEvent.DOCUMENT_UPDATE_START]: true,
  [AnalyticsEvent.DOCUMENT_UPDATE_COMPLETE]: true,
  [AnalyticsEvent.DONATION_CANCEL]: true,
  [AnalyticsEvent.DONATION_ERROR]: true,
  [AnalyticsEvent.DONATION_SUBMIT]: true,
  [AnalyticsEvent.DONATION_SUGGESTION]: true,
  [AnalyticsEvent.DRAWER_ADD_COMMUNITY]: true,
  [AnalyticsEvent.DRAWER_DISCOVER_COMMUNITIES]: true,
  [AnalyticsEvent.DRAWER_RECOMMEND_HEYLO_CANCEL]: true,
  [AnalyticsEvent.DRAWER_RECOMMEND_HEYLO_COPY]: true,
  [AnalyticsEvent.DRAWER_RECOMMEND_HEYLO_START]: true,
  [AnalyticsEvent.EVENTS_CONTENT_TAPPED]: true,
  [AnalyticsEvent.EVENTS_CREATE]: true,
  [AnalyticsEvent.EVENTS_DATETIME]: true,
  [AnalyticsEvent.EVENTS_DELETE]: true,
  [AnalyticsEvent.EVENTS_LOCATION]: true,
  [AnalyticsEvent.EVENTS_RSVP]: true,
  [AnalyticsEvent.EVENTS_RSVP_JOINING]: true,
  [AnalyticsEvent.EVENTS_RSVP_INTERESTED]: true,
  [AnalyticsEvent.EVENTS_RSVP_NOT_JOINING]: true,
  [AnalyticsEvent.EVENTS_SHARE_START]: true,
  [AnalyticsEvent.EVENTS_SHARE_SUCCESS]: true,
  [AnalyticsEvent.EVENTS_SHARE_DISMISS]: true,
  [AnalyticsEvent.EVENTS_SHOW_PAST]: true,
  [AnalyticsEvent.EVENTS_UPDATE]: true,
  [AnalyticsEvent.IMAGE_DOWNLOAD]: true,
  [AnalyticsEvent.INSTAGRAM_SHARE_CANCEL]: true,
  [AnalyticsEvent.INSTAGRAM_SHARE_FAILED]: true,
  [AnalyticsEvent.INSTAGRAM_SHARE_PROCEED]: true,
  [AnalyticsEvent.INSTAGRAM_SHARE_STARTED]: true,
  [AnalyticsEvent.IMAGE_USER_PROFILE]: true,
  [AnalyticsEvent.IMAGE_VIEWED]: true,
  [AnalyticsEvent.INTERESTED_CONTENT_TAPPED]: true,
  [AnalyticsEvent.INTERESTED_SHOW_INACTIVE]: true,
  [AnalyticsEvent.INVITE_BULK_IMPORT]: true,
  [AnalyticsEvent.INVITE_EMAIL_SENT]: true,
  [AnalyticsEvent.INVITE_LINK_COPIED]: true,
  [AnalyticsEvent.INVITE_QR_CODE]: true,
  [AnalyticsEvent.MEMBER_ACCEPT]: true,
  [AnalyticsEvent.MEMBER_ADD_MORE]: true,
  [AnalyticsEvent.MEMBER_CREATED]: true,
  [AnalyticsEvent.MEMBER_DELETED]: true,
  [AnalyticsEvent.MEMBER_IGNORE]: true,
  [AnalyticsEvent.MEMBER_MENU]: true,
  [AnalyticsEvent.MEMBER_PROFILE_TAPPED]: true,
  [AnalyticsEvent.MEMBER_REMOVE]: true,
  [AnalyticsEvent.NOTIFICATION_OPENED]: true,
  [AnalyticsEvent.PAYMENTS_ACCOUNT_DASHBOARD]: true,
  [AnalyticsEvent.PAYMENTS_SETUP]: true,
  [AnalyticsEvent.PROFILE_SEND_MESSAGE]: true,
  [AnalyticsEvent.PROFILE_UPDATE]: true,
  [AnalyticsEvent.REACTIONS_ADD]: true,
  [AnalyticsEvent.REACTIONS_REMOVE]: true,
  [AnalyticsEvent.SURVEY_NPS_DISMISS]: true,
  [AnalyticsEvent.SURVEY_NPS_SUBMIT]: true,
  [AnalyticsEvent.TOPICS_NEW]: true,
  [AnalyticsEvent.TUTORIAL_COMMUNITY_PROFILE_INTRO]: true,
  [AnalyticsEvent.TUTORIAL_COMMUNITY_PROFILE_UPDATE]: true,
  [AnalyticsEvent.TUTORIAL_COMMUNITY_PROFILE_SHARE]: true,
  [AnalyticsEvent.TUTORIAL_CONTENT_NEW_MEMBERS]: true,
  [AnalyticsEvent.TUTORIAL_EVENT_CREATE]: true,
  [AnalyticsEvent.TUTORIAL_EVENT_DIGEST]: true,
  [AnalyticsEvent.TUTORIAL_EVENT_EDIT]: true,
  [AnalyticsEvent.TUTORIAL_EVENT_RSVP]: true,
  [AnalyticsEvent.TUTORIAL_EVENT_SAMPLE]: true,
  [AnalyticsEvent.TUTORIAL_EVENT_SHARE]: true,
  [AnalyticsEvent.TUTORIAL_INVITE_BY_EMAIL]: true,
  [AnalyticsEvent.TUTORIAL_INVITE_MEMBERS]: true,
  [AnalyticsEvent.TUTORIAL_PROSPECTIVE_MEMBERS]: true,
  [AnalyticsEvent.TUTORIAL_SET_PROFILE_PHOTO]: true,
  [AnalyticsEvent.TUTORIAL_TOPIC_CREATE]: true,
  [AnalyticsEvent.TUTORIAL_TOPIC_JOIN]: true,
  [AnalyticsEvent.TUTORIAL_TOPIC_WELCOME_BEGIN]: true,
  [AnalyticsEvent.TUTORIAL_TOPIC_WELCOME_EDIT]: true,
};

export const BlacklistedCommunityEvents = {
  [AnalyticsEvent.ACCOUNT_PASSWORD_RESET_COMPLETE]: true,
  [AnalyticsEvent.ACCOUNT_PASSWORD_RESET_START]: true,
  [AnalyticsEvent.ACCOUNT_SIGN_OUT]: true,
  [AnalyticsEvent.ACCOUNT_UPGRADED]: true,
  [AnalyticsEvent.APP_COMPONENT_ERROR]: true,
  [AnalyticsEvent.APP_REDUX_ERROR]: true,
  [AnalyticsEvent.APP_UPDATE_BANNER_DISMISS]: true,
  [AnalyticsEvent.APP_UPDATE_BANNER_TAPPED]: true,
  [AnalyticsEvent.BACK]: true,
  [AnalyticsEvent.CHAT_NEW_MEMBER_NOTIFICATIONS_ON]: true,
  [AnalyticsEvent.CHAT_NEW_MEMBER_NOTIFICATIONS_OFF]: true,
  [AnalyticsEvent.CHAT_NOTIFICATIONS_OPT_IN]: true,
  [AnalyticsEvent.CHAT_NOTIFICATIONS_OPT_OUT]: true,
  [AnalyticsEvent.CHAT_OPEN]: true,
  [AnalyticsEvent.CHAT_TOP_SHEET_TOGGLE]: true,
  [AnalyticsEvent.COMMUNITY_LEFT]: true,
  [AnalyticsEvent.COMMUNITY_JOIN_NOW]: true,
  [AnalyticsEvent.COMMUNITY_JOIN_REQUEST]: true,
  [AnalyticsEvent.DRAWER_OPEN]: true,
  [AnalyticsEvent.DRAWER_SETTINGS]: true,
  [AnalyticsEvent.DRAWER_SWITCH_COMMUNITY]: true,
  [AnalyticsEvent.DYNAMIC_LINK_COMMUNITY_PROFILE]: true,
  [AnalyticsEvent.DYNAMIC_LINK_DONATION_CANCEL]: true,
  [AnalyticsEvent.DYNAMIC_LINK_DONATION_SUCCESS]: true,
  [AnalyticsEvent.DYNAMIC_LINK_EVENT_DETAILS]: true,
  [AnalyticsEvent.DYNAMIC_LINK_INSTALL]: true,
  [AnalyticsEvent.DYNAMIC_LINK_INVITATION]: true,
  [AnalyticsEvent.DYNAMIC_LINK_MESSAGE]: true,
  [AnalyticsEvent.DYNAMIC_LINK_OPENED]: true,
  [AnalyticsEvent.DYNAMIC_LINK_REJECTED]: true,
  [AnalyticsEvent.EVENTS_COPY_TO_CALENDAR_NEVER]: true,
  [AnalyticsEvent.EVENTS_COPY_TO_CALENDAR_NO]: true,
  [AnalyticsEvent.EVENTS_COPY_TO_CALENDAR_YES]: true,
  [AnalyticsEvent.INTRO_CREATE_COMMUNITY]: true,
  [AnalyticsEvent.INTRO_FIND_COMMUNITY]: true,
  [AnalyticsEvent.INTRO_LOGIN]: true,
  [AnalyticsEvent.MENTION_SELECTED]: true,
  [AnalyticsEvent.NAV_BOTTOM_TAB_PRESSED]: true,
  [AnalyticsEvent.NEW_GROUP_CONFIRM_SUBMIT]: true,
  [AnalyticsEvent.NEW_GROUP_CUSTOM_LOCATION]: true,
  [AnalyticsEvent.NEW_GROUP_DETAILS_SUBMIT]: true,
  [AnalyticsEvent.NEW_GROUP_LOCATION_SUBMIT]: true,
  [AnalyticsEvent.NEW_GROUP_NAME_SUBMIT]: true,
  [AnalyticsEvent.PROFILE_CREATE_COMPLETE]: true,
  [AnalyticsEvent.PROFILE_CREATE_SKIP]: true,
  [AnalyticsEvent.REGISTRATION_EMAIL_FAILURE]: true,
  [AnalyticsEvent.REGISTRATION_EMAIL_SENT]: true,
  [AnalyticsEvent.REGISTRATION_EMAIL_SIGNING_IN]: true,
  [AnalyticsEvent.REGISTRATION_EMAIL_VERIFIED]: true,
  [AnalyticsEvent.REGISTRATION_LOG_IN_FAILED]: true,
  [AnalyticsEvent.REGISTRATION_LOG_IN_SUCCESS]: true,
  [AnalyticsEvent.REGISTRATION_SIGN_UP_CANCEL]: true,
  [AnalyticsEvent.REGISTRATION_SIGN_UP_FAILED]: true,
  [AnalyticsEvent.REGISTRATION_SIGN_UP_SUCCESS]: true,
  [AnalyticsEvent.SCREEN_VIEW]: true,
};

export const ServerEvents = {
  [AnalyticsEvent.EMAIL_INVITE]: true,
  [AnalyticsEvent.EMAIL_INVITE_REMINDER]: true,
  [AnalyticsEvent.NOTIFICATION_COMMUNITY_ACTIVATION]: true,
  [AnalyticsEvent.NOTIFICATION_COUNTER_FACTUAL]: true,
  [AnalyticsEvent.NOTIFICATION_EVENT_DONATION_REMINDER]: true,
  [AnalyticsEvent.NOTIFICATION_EVENT_REMINDER]: true,
  [AnalyticsEvent.NOTIFICATION_EVENT_START]: true,
  [AnalyticsEvent.NOTIFICATION_ACTIVATION_EVENTS]: true,
  [AnalyticsEvent.NOTIFICATION_ACTIVATION_MESSAGING]: true,
};

const DeprecatedAnalyticsEvents = {
  DEPRECATED_COMMUNITY_GET_INVITED: 'Community_GetInvited',
  DEPRECATED_CHAT_INFO: 'Chat_Info',
  DEPRECATED_CHAT_UPDATE_HERO_IMAGE: 'Chat_UpdateHeroImage',
  DEPRECATED_CHAT_UPDATE_NOTES: 'Chat_UpdateNotes',
  DEPRECATED_COMMUNITY_CODE_ACCEPTED: 'CommunityCode_Accepted',
  DEPRECATED_COMMUNITY_CODE_REJECTED: 'CommunityCode_Rejected',
  DEPRECATED_COMMUNITY_INTEREST: 'Community_Interest',
  DEPRECATED_COMMUNITY_INTEREST_CANCEL: 'Community_InterestCancel',
  DEPRECATED_COMMUNITY_INTEREST_CONFIRM: 'Community_InterestConfirm',
  DEPRECATED_CONVERSATIONS_ARCHIVED: 'Conversations_Archived',
  DEPRECATED_CONVERSATIONS_SHOW_JOINED: 'Conversations_ShowJoined',
  DEPRECATED_CONVERSATIONS_SHOW_DISCOVER: 'Conversations_ShowDiscover',
  DEPRECATED_CONVERSATIONS_SHOW_ARCHIVED: 'Conversations_ShowArchived',
  DEPRECATED_DISCOVER_CONTENT_TAPPED: 'Discover_ContentTapped',
  DEPRECATED_DISCOVER_SHOW_ARCHIVED: 'Discover_ShowArchived',
  DEPRECATED_DRAWER_COMMUNITY_SETTINGS: 'Drawer_CommunitySettings',
  DEPRECATED_EVENTS_SHOW_NOTES: 'Events_ShowNotes',
  DEPRECATED_EVENTS_SHOW_ARCHIVED: 'Events_ShowArchived',
  DEPRECATED_EVENTS_SHOW_DISCOVER: 'Events_ShowDiscover',
  DEPRECATED_EVENTS_SHOW_JOINING: 'Events_ShowJoining',
  DEPRECATED_INTERESTED_NEW: 'Interested_New',
  DEPRECATED_INTRO_JOIN_EXISTING: 'Intro_JoinExisting',
  DEPRECATED_MEMBERS_SHOW_ALL: 'Members_ShowAll',
  DEPRECATED_MEMBERS_SHOW_JUST_JOINED: 'Members_ShowJustJoined',
  DEPRECATED_WELCOME_SCREEN_BACK: 'WelcomeScreen_Back',
  DEPRECATED_WELCOME_SCREEN_CONTINUE: 'WelcomeScreen_Continue',
};
