import React from 'react';
import {WebEmailVerificationForm} from '../auth/WebEmailVerificationForm';
import {EmailVerificationContext} from '@heylo/shared/src/features/auth/Slice';

export const NewGroupEmailVerificationScreen = () => {
  return (
      <WebEmailVerificationForm
          context={EmailVerificationContext.CREATE_COMMUNITY}
          percent={'60%'}
      />
  );
};