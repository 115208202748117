import {createSelector} from 'reselect';
import moment from 'moment-timezone';
import {
  SelectActiveCommunityId,
  SelectActiveCommunitySettings,
} from '@heylo/shared/src/features/communities/Selectors';
import {COMMUNITY_SETTINGS_POLICY_EVERYONE} from '@heylo/shared/src/features/communities/Constants';
import {
  Event,
  EventAttendance,
  UserProfile,
} from '@heylo/shared/src/types/firebase-types';
import {SelectUserProfiles} from '@heylo/shared/src/features/userProfiles/Selectors';
import {SelectActiveUserIsAdminInActiveCommunity} from '@heylo/shared/src/features/communityMembers/Selectors';
import _ from 'lodash';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

export const selectActiveEventId = (state: RootState) => state.events.activeEventId;
export const selectAllCommunityEventIds = (state: RootState) => state.events.communityEventIds;
export const selectAllEvents = (state: RootState) => state.events.events;
export const selectAllEventAttendance = (state: RootState) => state.events.eventAttendance;

export const selectActiveEvent = createSelector(
    [selectAllEvents, selectActiveEventId],
    (allEvents, activeEventId): Event => {
      return allEvents[activeEventId] ?? {};
    });

export const selectActiveEventAttendance = createSelector(
    [selectActiveEventId, selectAllEventAttendance],
    (eventId, attendance) => attendance[eventId] ?? {});

const sortedEventAttendance = (
    attendance: { [userId: string]: EventAttendance },
    rsvpFilter: 'yes' | 'no' | 'maybe',
    userProfiles: { [userId: string]: UserProfile })
    : UserProfile[] => {
  const users = Object.entries(attendance)
      .filter(([userId, rsvp]) => rsvp.going === rsvpFilter)
      .map(([userId]) => userProfiles[userId] ?? {});
  return _.sortBy(users, user => user.fullName?.toLocaleLowerCase() || 'Unknown')
};

export const selectActiveEventGoingMembers = createSelector(
    [selectActiveEventAttendance, SelectUserProfiles],
    (attendees, userProfiles) => {
      return sortedEventAttendance(attendees, 'yes', userProfiles);
    });

export const selectActiveEventInterestedMembers = createSelector(
    [selectActiveEventAttendance, SelectUserProfiles],
    (attendees, userProfiles) => {
      return sortedEventAttendance(attendees, 'maybe', userProfiles);
    });

export const selectActiveEventNotGoingMembers = createSelector(
    [selectActiveEventAttendance, SelectUserProfiles],
    (attendees, userProfiles) => {
      return sortedEventAttendance(attendees, 'no', userProfiles);
    });

export const EventBucket = {
  UNKNOWN: 'unknown',
  PAST: 'past',
  RECENT: 'recent',
  TODAY: 'today',
  IN_NEXT_WEEK: 'inNextWeek',
  IN_NEXT_MONTH: 'inNextMonth',
  MORE_THAN_ONE_MONTH: 'moreThanOneMonth',
};

const getToday = (timezone: string) => moment().tz(timezone).startOf('day');

const selectActiveCommunityEvents = createSelector(
    [SelectActiveCommunityId, selectAllCommunityEventIds, selectAllEvents],
    (activeCommunityId, communityEventIds, events)
        : Event[] => {
      return Object.keys(communityEventIds[activeCommunityId] ?? {})
          .map(eid => events[eid] ?? {});
    },
);

export const selectActiveCommunityBucketizedEvents = createSelector(
    [selectActiveCommunityEvents],
    (events)
        : { [bucket: string]: Readonly<Event>[] } => {
      const sortedEvents = _.sortBy(events, event => event.timestamp ?? 0);
      const bucketedEvents: { [bucket: string]: Event[] } = {};
      for (const event of sortedEvents) {
        const {
          timestamp = 0,
          timezone = moment.tz.guess(),
        } = event;
        let bucket = EventBucket.UNKNOWN;
        const eventTime = moment(timestamp).tz(timezone);
        if (eventTime >= getToday(timezone).add(30, 'days')) {
          bucket = EventBucket.MORE_THAN_ONE_MONTH;
        } else if (eventTime >= getToday(timezone).add(7, 'days')) {
          bucket = EventBucket.IN_NEXT_MONTH;
        } else if (eventTime >= getToday(timezone).add(1, 'days')) {
          bucket = EventBucket.IN_NEXT_WEEK;
        } else if (eventTime >= moment()) {
          bucket = EventBucket.TODAY;
        } else if (moment().diff(eventTime, 'hours') < 24) {
          bucket = EventBucket.RECENT;
        } else {
          bucket = EventBucket.PAST;
        }
        if (!bucketedEvents[bucket]) {
          bucketedEvents[bucket] = [];
        }
        bucketedEvents[bucket].push(event);
      }

      for (const [bucket, events] of Object.entries(bucketedEvents)) {
        let sorted = _.sortBy(events, e => e.timestamp);
        if (bucket === EventBucket.PAST) {
          sorted = sorted.reverse();
        }
        bucketedEvents[bucket] = sorted;
      }

      return bucketedEvents;
    });

export const selectNumUpcomingEvents = createSelector(
    [selectActiveCommunityBucketizedEvents],
    (bucketedEvents): number => {
      let count = 0;
      for (const [bucket, events] of Object.entries(bucketedEvents)) {
        if (bucket === EventBucket.PAST || bucket === EventBucket.UNKNOWN) {
          continue;
        }
        count += events.length;
      }
      return count;
    });

export const selectActiveUserHasEventWritePermissions = createSelector(
    [SelectActiveUserIsAdminInActiveCommunity, SelectActiveCommunitySettings],
    (isAdmin, settings)
        : boolean => {
      return isAdmin
          || settings.eventCreatePolicy === COMMUNITY_SETTINGS_POLICY_EVERYONE;
    });
