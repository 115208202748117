export const SUPPORTED_TIMEZONES = {
  'Pacific/Honolulu': '(-10:00) Hawaii Time',
  'America/Anchorage': '(-09:00) Alaska Time',
  'America/Los_Angeles': '(-08:00) Pacific Time - Los Angeles',
  'America/Tijuana': '(-08:00) Pacific Time - Tijuana',
  'America/Vancouver': '(-08:00) Pacific Time - Vancouver',
  'America/Whitehorse': '(-08:00) Pacific Time - Whitehorse',
  'America/Dawson_Creek': '(-07:00) Mountain Time - Dawson Creek',
  'America/Denver': '(-07:00) Mountain Time - Denver',
  'America/Edmonton': '(-07:00) Mountain Time - Edmonton',
  'America/Hermosillo': '(-07:00) Mountain Time - Hermosillo',
  'America/Phoenix': '(-07:00) Mountain Time - Arizona',
  'America/Yellowknife': '(-07:00) Mountain Time - Yellowknife',
  'America/Chicago': '(-06:00) Central Time - Chicago',
  'America/Mexico_City': '(-06:00) Central Time - Mexico City',
  'America/Regina': '(-06:00) Central Time - Regina',
  'America/Tegucigalpa': '(-06:00) Central Time - Tegucigalpa',
  'America/Winnipeg': '(-06:00) Central Time - Winnipeg',
  'America/New_York': '(-05:00) Eastern Time - New York',
  'America/Toronto': '(-05:00) Eastern Time - Toronto',
  'America/Halifax': '(-04:00) Atlantic Time - Halifax',
  'America/La_Paz': '(-04:00) La Paz',
  'America/Manaus': '(-04:00) Manaus',
  'America/Martinique': '(-04:00) Martinique',
  'America/Port_of_Spain': '(-04:00) Port of Spain',
  'America/Porto_Velho': '(-04:00) Porto Velho',
  'America/Puerto_Rico': '(-04:00) Puerto Rico',
  'America/Santo_Domingo': '(-04:00) Santo Domingo',
  'America/Thule': '(-04:00) Thule',
  'Atlantic/Bermuda': '(-04:00) Bermuda',
  'America/St_Johns': '(-03:30) Newfoundland Time - St. Johns',
  'America/Araguaina': '(-03:00) Araguaina',
  'America/Argentina/Buenos_Aires': '(-03:00) Buenos Aires',
  'America/Bahia': '(-03:00) Salvador',
  'America/Belem': '(-03:00) Belem',
  'America/Cayenne': '(-03:00) Cayenne',
  'America/Fortaleza': '(-03:00) Fortaleza',
  'America/Godthab': '(-03:00) Godthab',
  'America/Maceio': '(-03:00) Maceio',
  'America/Miquelon': '(-03:00) Miquelon',
  'America/Montevideo': '(-03:00) Montevideo',
  'America/Paramaribo': '(-03:00) Paramaribo',
  'America/Recife': '(-03:00) Recife',
  'America/Santiago': '(-03:00) Santiago',
  'America/Sao_Paulo': '(-02:00) Sao Paulo',
  'Antarctica/Palmer': '(-03:00) Palmer',
  'Antarctica/Rothera': '(-03:00) Rothera',
  'Atlantic/Stanley': '(-03:00) Stanley',
  'America/Noronha': '(-02:00) Noronha',
  'Atlantic/South_Georgia': '(-02:00) South Georgia',
  'America/Scoresbysund': '(-01:00) Scoresbysund',
  'Atlantic/Azores': '(-01:00) Azores',
  'Atlantic/Cape_Verde': '(-01:00) Cape Verde',
  'Africa/Abidjan': '(+00:00) Abidjan',
  'Africa/Accra': '(+00:00) Accra',
  'Africa/Bissau': '(+00:00) Bissau',
  'Africa/Casablanca': '(+00:00) Casablanca',
  'Africa/El_Aaiun': '(+00:00) El Aaiun',
  'Africa/Monrovia': '(+00:00) Monrovia',
  'America/Danmarkshavn': '(+00:00) Danmarkshavn',
  'Atlantic/Canary': '(+00:00) Canary Islands',
  'Atlantic/Faroe': '(+00:00) Faeroe',
  'Atlantic/Reykjavik': '(+00:00) Reykjavik',
  'Etc/': '(+00:00)  (no daylight saving)',
  'Europe/Dublin': '(+00:00) Dublin',
  'Europe/Lisbon': '(+00:00) Lisbon',
  'Europe/London': '(+00:00) London',
  'Africa/Algiers': '(+01:00) Algiers',
  'Africa/Ceuta': '(+01:00) Ceuta',
  'Africa/Lagos': '(+01:00) Lagos',
  'Africa/Ndjamena': '(+01:00) Ndjamena',
  'Africa/Tunis': '(+01:00) Tunis',
  'Africa/Windhoek': '(+02:00) Windhoek',
  'Europe/Amsterdam': '(+01:00) Amsterdam',
  'Europe/Andorra': '(+01:00) Andorra',
  'Europe/Belgrade': '(+01:00) Central European Time - Belgrade',
  'Europe/Berlin': '(+01:00) Berlin',
  'Europe/Brussels': '(+01:00) Brussels',
  'Europe/Budapest': '(+01:00) Budapest',
  'Europe/Copenhagen': '(+01:00) Copenhagen',
  'Europe/Gibraltar': '(+01:00) Gibraltar',
  'Europe/Luxembourg': '(+01:00) Luxembourg',
  'Europe/Madrid': '(+01:00) Madrid',
  'Europe/Malta': '(+01:00) Malta',
  'Europe/Monaco': '(+01:00) Monaco',
  'Europe/Oslo': '(+01:00) Oslo',
  'Europe/Paris': '(+01:00) Paris',
  'Europe/Prague': '(+01:00) Central European Time - Prague',
  'Europe/Rome': '(+01:00) Rome',
  'Europe/Stockholm': '(+01:00) Stockholm',
  'Europe/Tirane': '(+01:00) Tirane',
  'Europe/Vienna': '(+01:00) Vienna',
  'Europe/Warsaw': '(+01:00) Warsaw',
  'Europe/Zurich': '(+01:00) Zurich',
  'Africa/Cairo': '(+02:00) Cairo',
  'Africa/Johannesburg': '(+02:00) Johannesburg',
  'Africa/Maputo': '(+02:00) Maputo',
  'Africa/Tripoli': '(+02:00) Tripoli',
  'Asia/Amman': '(+02:00) Amman',
  'Asia/Beirut': '(+02:00) Beirut',
  'Asia/Damascus': '(+02:00) Damascus',
  'Asia/Gaza': '(+02:00) Gaza',
  'Asia/Jerusalem': '(+02:00) Jerusalem',
  'Asia/Nicosia': '(+02:00) Nicosia',
  'Europe/Athens': '(+02:00) Athens',
  'Europe/Bucharest': '(+02:00) Bucharest',
  'Europe/Chisinau': '(+02:00) Chisinau',
  'Europe/Helsinki': '(+02:00) Helsinki',
  'Europe/Istanbul': '(+02:00) Istanbul',
  'Europe/Kaliningrad': '(+02:00) Moscow-01 - Kaliningrad',
  'Europe/Kiev': '(+02:00) Kiev',
  'Europe/Riga': '(+02:00) Riga',
  'Europe/Sofia': '(+02:00) Sofia',
  'Europe/Tallinn': '(+02:00) Tallinn',
  'Europe/Vilnius': '(+02:00) Vilnius',
  'Africa/Khartoum': '(+03:00) Khartoum',
  'Africa/Nairobi': '(+03:00) Nairobi',
  'Antarctica/Syowa': '(+03:00) Syowa',
  'Asia/Baghdad': '(+03:00) Baghdad',
  'Asia/Qatar': '(+03:00) Qatar',
  'Asia/Riyadh': '(+03:00) Riyadh',
  'Europe/Minsk': '(+03:00) Minsk',
  'Europe/Moscow': '(+03:00) Moscow+00 - Moscow',
  'Asia/Tehran': '(+03:30) Tehran',
  'Asia/Baku': '(+04:00) Baku',
  'Asia/Dubai': '(+04:00) Dubai',
  'Asia/Tbilisi': '(+04:00) Tbilisi',
  'Asia/Yerevan': '(+04:00) Yerevan',
  'Europe/Samara': '(+04:00) Moscow+01 - Samara',
  'Indian/Mahe': '(+04:00) Mahe',
  'Indian/Mauritius': '(+04:00) Mauritius',
  'Indian/Reunion': '(+04:00) Reunion',
  'Asia/Kabul': '(+04:30) Kabul',
  'Antarctica/Mawson': '(+05:00) Mawson',
  'Asia/Aqtau': '(+05:00) Aqtau',
  'Asia/Aqtobe': '(+05:00) Aqtobe',
  'Asia/Ashgabat': '(+05:00) Ashgabat',
  'Asia/Dushanbe': '(+05:00) Dushanbe',
  'Asia/Karachi': '(+05:00) Karachi',
  'Asia/Tashkent': '(+05:00) Tashkent',
  'Asia/Yekaterinburg': '(+05:00) Moscow+02 - Yekaterinburg',
  'Indian/Kerguelen': '(+05:00) Kerguelen',
  'Indian/Maldives': '(+05:00) Maldives',
  'Asia/Calcutta': '(+05:30) India Standard Time',
  'Asia/Colombo': '(+05:30) Colombo',
  'Asia/Katmandu': '(+05:45) Katmandu',
  'Antarctica/Vostok': '(+06:00) Vostok',
  'Asia/Almaty': '(+06:00) Almaty',
  'Asia/Bishkek': '(+06:00) Bishkek',
  'Asia/Dhaka': '(+06:00) Dhaka',
  'Asia/Omsk': '(+06:00) Moscow+03 - Omsk, Novosibirsk',
  'Asia/Thimphu': '(+06:00) Thimphu',
  'Indian/Chagos': '(+06:00) Chagos',
  'Asia/Rangoon': '(+06:30) Rangoon',
  'Indian/Cocos': '(+06:30) Cocos',
  'Antarctica/Davis': '(+07:00) Davis',
  'Asia/Bangkok': '(+07:00) Bangkok',
  'Asia/Hovd': '(+07:00) Hovd',
  'Asia/Jakarta': '(+07:00) Jakarta',
  'Asia/Krasnoyarsk': '(+07:00) Moscow+04 - Krasnoyarsk',
  'Asia/Saigon': '(+07:00) Hanoi',
  'Asia/Ho_Chi_Minh': '(+07:00) Ho Chi Minh',
  'Indian/Christmas': '(+07:00) Christmas',
  'Antarctica/Casey': '(+08:00) Casey',
  'Asia/Brunei': '(+08:00) Brunei',
  'Asia/Choibalsan': '(+08:00) Choibalsan',
  'Asia/Hong_Kong': '(+08:00) Hong Kong',
  'Asia/Irkutsk': '(+08:00) Moscow+05 - Irkutsk',
  'Asia/Kuala_Lumpur': '(+08:00) Kuala Lumpur',
  'Asia/Macau': '(+08:00) Macau',
  'Asia/Makassar': '(+08:00) Makassar',
  'Asia/Manila': '(+08:00) Manila',
  'Asia/Shanghai': '(+08:00) China Time - Beijing',
  'Asia/Singapore': '(+08:00) Singapore',
  'Asia/Taipei': '(+08:00) Taipei',
  'Asia/Ulaanbaatar': '(+08:00) Ulaanbaatar',
  'Australia/Perth': '(+08:00) Western Time - Perth',
  'Asia/Pyongyang': '(+08:30) Pyongyang',
  'Asia/Dili': '(+09:00) Dili',
  'Asia/Jayapura': '(+09:00) Jayapura',
  'Asia/Seoul': '(+09:00) Seoul',
  'Asia/Tokyo': '(+09:00) Tokyo',
  'Asia/Yakutsk': '(+09:00) Moscow+06 - Yakutsk',
  'Pacific/Palau': '(+09:00) Palau',
  'Australia/Adelaide': '(+10:30) Central Time - Adelaide',
  'Australia/Darwin': '(+09:30) Central Time - Darwin',
  'Antarctica/DumontDUrville': '(+10:00) Dumont D\'Urville',
  'Asia/Magadan': '(+10:00) Moscow+07 - Magadan',
  'Asia/Vladivostok': '(+10:00) Moscow+07 - Yuzhno-Sakhalinsk',
  'Australia/Brisbane': '(+10:00) Eastern Time - Brisbane',
  'Australia/Hobart': '(+11:00) Eastern Time - Hobart',
  'Australia/Sydney': '(+11:00) Eastern Time - Melbourne, Sydney',
  'Pacific/Chuuk': '(+10:00) Truk',
  'Pacific/Guam': '(+10:00) Guam',
  'Pacific/Port_Moresby': '(+10:00) Port Moresby',
  'Pacific/Efate': '(+11:00) Efate',
  'Pacific/Guadalcanal': '(+11:00) Guadalcanal',
  'Pacific/Kosrae': '(+11:00) Kosrae',
  'Pacific/Norfolk': '(+11:00) Norfolk',
  'Pacific/Noumea': '(+11:00) Noumea',
  'Pacific/Pohnpei': '(+11:00) Ponape',
  'Asia/Kamchatka': '(+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
  'Pacific/Auckland': '(+13:00) Auckland',
  'Pacific/Fiji': '(+13:00) Fiji',
  'Pacific/Funafuti': '(+12:00) Funafuti',
  'Pacific/Kwajalein': '(+12:00) Kwajalein',
  'Pacific/Majuro': '(+12:00) Majuro',
  'Pacific/Nauru': '(+12:00) Nauru',
  'Pacific/Tarawa': '(+12:00) Tarawa',
  'Pacific/Wake': '(+12:00) Wake',
  'Pacific/Wallis': '(+12:00) Wallis',
  'Pacific/Apia': '(+14:00) Apia',
  'Pacific/Enderbury': '(+13:00) Enderbury',
  'Pacific/Fakaofo': '(+13:00) Fakaofo',
  'Pacific/Tongatapu': '(+13:00) Tongatapu',
  'Pacific/Kiritimati': '(+14:00) Kiritimati',
};