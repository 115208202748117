import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {persistReducer, persistStore} from 'redux-persist';
import localForage from 'localforage';
import {AppColdStart} from '@heylo/shared/src/features/app/Actions';
import {debounce} from 'lodash';
import {batchedSubscribe} from 'redux-batched-subscribe';
// @ts-ignore
import withReduxEnhancer from 'addon-redux/enhancer';
import createRootReducer from '@heylo/shared/src/services/redux/RootReducer';
import {isSafari} from 'react-device-detect';

const blacklist = [
  'navigation',
  'ui',
];
if (isSafari) {
  blacklist.push('threads');
}

const persistConfig = {
  blacklist,
  key: 'root',
  storage: localForage,
};

const persistedReducer = persistReducer(persistConfig, createRootReducer());

const createEnhancers = () => {
  const enhancers = [
    batchedSubscribe(debounce(notify => notify(), 10)),
  ];
  if (process.env.NODE_ENV !== 'production') {
    enhancers.push(withReduxEnhancer);
  }
  return enhancers;
};

const store = configureStore({
  middleware: getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
  enhancers: createEnhancers(),
  reducer: persistedReducer,
});

const persistor = persistStore(store, {}, () => {
  store.dispatch(AppColdStart())
});

export type WebAppDispatch = typeof store.dispatch

export {persistor};
export default store;
