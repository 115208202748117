import {IsThreadTypeCommunityBased} from '../../types/ThreadTypes';
import {GetUserChatStateCollection} from '../../util/user-chat-state';
import {Listeners} from '../firebase/Listeners';
import {
  NotificationSettings,
  OneNotificationSetting,
} from '../../types/firebase-types';
import {
  ACTION_SET_NOTIFICATION_SETTING_CHATS_MENTIONS,
  ACTION_SET_NOTIFICATION_SETTING_CHATS_NEW_MEMBERS,
  ACTION_SET_NOTIFICATION_SETTING_CHATS_NEW_MESSAGES,
  ACTION_SET_NOTIFICATION_SETTING_COMMUNITY_NEW_MEMBERS,
  ACTION_SET_NOTIFICATION_SETTING_COMMUNITY_NEW_TOPICS,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_DAY_OF_REMINDER,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_NEW,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_UPDATED,
  ACTION_SET_NOTIFICATION_SETTING_EVENTS_WEEKLY_REMINDER,
} from './Slice';
import {
  ChatNotificationType,
  CommunityNotificationType,
} from '../../constants/NotificationTypes';
import {ThunkReturnType} from '../../services/redux/Redux';
import {
  FirebaseDatabase,
  FirebaseServerTimestamp,
} from '@heylo/firebase-database';

export const updateNotificationSettingsChatsNewMembersPerThread = (params: {
  communityId?: string,
  enabled: boolean,
  threadId: string,
  threadType: string,
  userId: string,
}): Promise<void> => {
  const {communityId, enabled, userId, threadId, threadType} = params;
  if (IsThreadTypeCommunityBased(threadType)) {
    if (!communityId) {
      return Promise.resolve();
    }
    return updateNotificationSettings(`/threadState/${userId}/${communityId}/${threadId}/notificationSettings/newMembers`, enabled);
  } else {
    const collection = GetUserChatStateCollection(threadType);
    return updateNotificationSettings(`/${collection}/${userId}/${threadId}/notificationSettings/newMembers`, enabled);
  }
};

export const updateNotificationSettingsChatsNewMessagesPerThread = (params: {
  communityId?: string,
  enabled: boolean,
  threadId: string,
  threadType: string,
  userId: string,
}): Promise<void> => {
  const {communityId, enabled, userId, threadId, threadType} = params;
  if (IsThreadTypeCommunityBased(threadType)) {
    if (!communityId) {
      return Promise.resolve();
    }
    return updateNotificationSettings(`/threadState/${userId}/${communityId}/${threadId}/notificationSettings/newMessages`, enabled);
  } else {
    const collection = GetUserChatStateCollection(threadType);
    return updateNotificationSettings(`/${collection}/${userId}/${threadId}/notificationSettings/newMessages`, enabled);
  }
};

export const attachNotificationSettingsListener =(userId: string)
: ThunkReturnType<void> => (dispatch) => {
    if (Listeners.USER_SETTINGS_NOTIFICATIONS[userId])
      return;
    const ref = FirebaseDatabase().ref(`/userSettings/${userId}/notificationSettings`);
    Listeners.USER_SETTINGS_NOTIFICATIONS[userId] = ref;
    ref.on('value', (snapshot) => {
      const settings: NotificationSettings = snapshot?.val();
      if (!settings) {
        return;
      }

      if (typeof settings.communityNewMembers?.enabled === 'boolean') {
        dispatch(ACTION_SET_NOTIFICATION_SETTING_COMMUNITY_NEW_MEMBERS(settings.communityNewMembers.enabled));
      }
      if (typeof settings.communityNewTopics?.enabled === 'boolean') {
        dispatch(ACTION_SET_NOTIFICATION_SETTING_COMMUNITY_NEW_TOPICS(settings.communityNewTopics.enabled));
      }

      if (typeof settings.eventsDayOfReminder?.enabled === 'boolean') {
        dispatch(ACTION_SET_NOTIFICATION_SETTING_EVENTS_DAY_OF_REMINDER(settings.eventsDayOfReminder.enabled));
      }
      if (typeof settings.eventsNew?.enabled === 'boolean') {
        dispatch(ACTION_SET_NOTIFICATION_SETTING_EVENTS_NEW(settings.eventsNew.enabled));
      }
      if (typeof settings.eventsUpdated?.enabled === 'boolean') {
        dispatch(ACTION_SET_NOTIFICATION_SETTING_EVENTS_UPDATED(settings.eventsUpdated.enabled));
      }
      if (typeof settings.eventsWeeklyReminder?.enabled === 'boolean') {
        dispatch(ACTION_SET_NOTIFICATION_SETTING_EVENTS_WEEKLY_REMINDER(settings.eventsWeeklyReminder.enabled));
      }

      if (typeof settings.chatsMentions?.enabled === 'boolean') {
        dispatch(ACTION_SET_NOTIFICATION_SETTING_CHATS_MENTIONS(settings.chatsMentions.enabled));
      }
      if (typeof settings.chatsNewMembers?.enabled === 'boolean') {
        dispatch(ACTION_SET_NOTIFICATION_SETTING_CHATS_NEW_MEMBERS(settings.chatsNewMembers.enabled));
      }
      if (typeof settings.chatsNewCommunityMessage?.enabled === 'boolean') {
        dispatch(ACTION_SET_NOTIFICATION_SETTING_CHATS_NEW_MESSAGES(settings.chatsNewCommunityMessage.enabled));
      }
    },
        (e: Error) => {
      console.log('lost connection to notification settings', e.message);
      delete Listeners.USER_SETTINGS_NOTIFICATIONS[userId];
    });
};

export const updateNotificationSettingsEvents = (userId: string, notificationType: string, enabled: boolean) => {
  return updateNotificationSettings(`/userSettings/${userId}/notificationSettings/${notificationType}`, enabled);
};

export const updateNotificationSettingsChatsMentions = (userId: string, enabled: boolean) => {
  return updateNotificationSettings(`/userSettings/${userId}/notificationSettings/${ChatNotificationType.MENTIONS}`, enabled);
};

export const updateNotificationSettingsChatsNewMembers = (userId: string, enabled: boolean) => {
  return updateNotificationSettings(`/userSettings/${userId}/notificationSettings/${ChatNotificationType.NEW_MEMBERS}`, enabled);
};

export const updateNotificationSettingsChatsNewCommunityMessages = (userId: string, enabled: boolean) => {
  return updateNotificationSettings(`/userSettings/${userId}/notificationSettings/${ChatNotificationType.NEW_COMMUNITY_MESSAGE}`, enabled);
};

export const updateNotificationSettingsCommunityNewMembers = (userId: string, enabled: boolean) => {
  return updateNotificationSettings(`/userSettings/${userId}/notificationSettings/${CommunityNotificationType.NEW_MEMBER}`, enabled);
};

export const updateNotificationSettingsCommunityNewTopics = (userId: string, enabled: boolean) => {
  return updateNotificationSettings(`/userSettings/${userId}/notificationSettings/${CommunityNotificationType.NEW_TOPIC}`, enabled);
};

const updateNotificationSettings = (settingsPath: string, enabled: boolean): Promise<void> => {
  return new Promise((resolve, reject) => {
    const newSettings: OneNotificationSetting = {
      enabled: enabled,
      timestamp: FirebaseServerTimestamp(),
    };
    const updates: {[path: string]: OneNotificationSetting} = {};
    updates[settingsPath] = newSettings;
    FirebaseDatabase().ref().update(updates)
        .then(resolve)
        .catch((e) => {
          console.error('error updating notifications setting for this user', settingsPath, enabled, e.message);
          return reject(e);
        });
  });
};