import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Avatar} from '../userProfile/Avatar';
import React from 'react';
import {UserProfile} from '@heylo/shared/src/types/firebase-types';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

export const MentionSuggestion = (
    {
      onPress,
      selected,
      userProfile,
    }: {
      onPress: (userId: string, fullName: string) => void,
      selected?: boolean,
      userProfile: UserProfile,
    }) => {
  const {
    id: userId = '',
    fullName = '',
  } = userProfile;

  const palette = usePalette();

  return (
      <TouchableOpacity
          onPress={() => onPress(userId, fullName)}
          style={[styles.suggestionsRowContainer, selected ? {backgroundColor: palette.secondary.main} : {}]}>
        {/* NB: This view IS required in order for Avatar to be tappable */}
        <View>
          <Avatar size={30} userId={userId}/>
        </View>
        <View style={styles.userDetailsBox}>
          <Text
              style={[styles.displayNameText, {
                color: selected ? 'white' : 'black',
              }]}
              numberOfLines={1}
          >
            {fullName}
          </Text>
        </View>

      </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  suggestionsRowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: StyleConstants.SPACING / 2,
    paddingVertical: StyleConstants.SPACING / 4,
  },
  userDetailsBox: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: StyleConstants.SPACING / 2,
  },
  displayNameText: {
    fontSize: 16,
    color: 'black',
    opacity: .87,
  },
});
