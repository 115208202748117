import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {NewCommunityCreated} from '@heylo/components/src/features/communityCreation/NewCommunityCreated';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {SelectActiveCommunityId} from '@heylo/shared/src/features/communities/Selectors';
import {View} from 'react-native';
import {GenerateDynamicLinkForPath} from '../navigation/DynamicLink';
import {ShortenLink} from '../../lib/link/ShortenLink';

export const NewGroupCreatedScreen = () => {
  const history = useHistory();

  const {communityId} = useSelector((state: RootState) => ({
    communityId: SelectActiveCommunityId(state),
  }), shallowEqual);

  const [inviteLink, setInviteLink] = useState('');
  useEffect(() => {
    const link = GenerateDynamicLinkForPath(`/invite?communityId=${communityId}`);
    ShortenLink(link)
        .then(shortened => setInviteLink(shortened));
  }, []);

  const onNextPress = () => {
    history.replace(`/members?communityId=${communityId}`);
  }

  return (
      <View style={{
        alignItems: 'center',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <View style={{maxWidth: 500}}>
          <NewCommunityCreated
              onNext={onNextPress}
              shareLink={inviteLink}
          />
        </View>
      </View>
  );
};