// NotificationType contains unique identifiers for notification channels.
// See `NotificationId` for a unique identifier for all distinct types of
// notifications we send out.
export enum ChatNotificationType {
  NEW_COMMUNITY_MESSAGE = 'chatsNewCommunityMessage',
  NEW_MEMBERS = 'chatsNewMembers',
  NEW_PRIVATE_MESSAGE = 'chatsNewPrivateMessage',
  MENTIONS = 'chatsMentions',
}

export enum CommunityNotificationType {
  NEW_MEMBER = 'communityNewMembers',
  NEW_TOPIC = 'communityNewTopics',
}

export const EventNotificationType = {
  DAY_OF_REMINDER: 'eventsDayOfReminder',
  NEW_EVENT: 'eventsNew',
  UPDATED_EVENT: 'eventsUpdated',
  WEEKLY_REMINDER: 'eventsWeeklyReminder',
};

export enum GeneralNotificationType {
  MISCELLANEOUS_IMPORTANT = 'miscellaneousImportant',
}
