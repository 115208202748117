import {DrawerState} from '@heylo/shared/src/features/navigation/Slice';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {createSelector} from 'reselect';
import {
  ActiveCommunityImpressionsSelector,
  EventsScreenLastViewedTimestampSelector,
  MembersScreenLastViewedTimestampSelector,
  selectCommunityImpressions,
} from '../userEvents/Selectors';
import {
  EventBucket,
  selectActiveCommunityBucketizedEvents,
} from '../events/Selectors';
import {selectJoinedCommunityProfiles} from '../communityProfiles/Selectors';
import {
  SelectActiveCommunityId,
  SelectCommunityMembers,
} from '../communities/Selectors';
import {selectActiveUserId} from '../auth/Selectors';
import {
  AllCommunityThreadIdsSelector,
  AllThreadJoinStatesSelector,
  AllThreadLastActivityTimestampsSelector,
  AllThreadLastReadTimestampsSelector,
  selectThreadNumUnreadMessages,
} from '../threads/SimpleSelectors';
import {
  SelectRecentlyJoinedMembers,
  selectUnviewedCommunityProspects,
} from '../communityMembers/Selectors';
import {
  ActiveEventThreadsSelector,
  SortedPrivateChatsSelector,
} from '../threads/Selectors';
import {ActiveCommunityAlbumsSelector} from '../photos/Selectors';

export const selectDrawerIsOpen = (state: RootState) => state.navigation.drawerState === DrawerState.OPENED;

export const ContentBottomTabBadgeCountSelector = createSelector(
    [ActiveCommunityImpressionsSelector, ActiveCommunityAlbumsSelector],
    (communityImpressions, albums) => {
      let numUnviewedAlbums = 0;
      const albumIds = Object.keys(albums || {});
      for (const albumId of albumIds) {
        if (albumId !== 'all' && !communityImpressions[albumId]) {
          numUnviewedAlbums++;
        }
      }
      return numUnviewedAlbums;
    });

export const EventThreadsWithUnviewedMessagesSelector = createSelector(
    [ActiveEventThreadsSelector, AllThreadJoinStatesSelector, AllThreadLastActivityTimestampsSelector, AllThreadLastReadTimestampsSelector],
    (eventThreads, threadJoinStates, threadLastActivityTimestamps, threadLastReadTimestamps) => {
      return eventThreads.filter((thread, threadId) => {
        return (threadLastActivityTimestamps[threadId] || 0) > (threadLastReadTimestamps[threadId] || 0)
            && threadJoinStates[threadId];
      });
    });

export const PastEventThreadsWithUnviewedMessagesSelector = createSelector(
    [EventThreadsWithUnviewedMessagesSelector, selectActiveCommunityBucketizedEvents],
    (threads, bucketedEvents) => {
      const pastEventIds = new Set<string>(
          (bucketedEvents[EventBucket.PAST] ?? []).map(e => e.eventId ?? ''),
      );
      return threads.filter(thread => pastEventIds.has(thread.threadId || ''));
    });

export const EventsBottomTabBadgeCountSelector = createSelector(
    [EventsScreenLastViewedTimestampSelector, selectActiveCommunityBucketizedEvents, EventThreadsWithUnviewedMessagesSelector],
    (lastViewedTimestamp, bucketedEvents, eventsWithUnviewedMessages) => {
      const unviewedEventIds = new Set(eventsWithUnviewedMessages.map(thread => thread.threadId || ''));
      for (const [bucket, events] of Object.entries(bucketedEvents)) {
        if (bucket === EventBucket.PAST || bucket === EventBucket.UNKNOWN) {
          continue;
        }
        for (const e of events) {
          const {eventId = '', createdAt = 0} = e;
          if (createdAt > lastViewedTimestamp) {
            unviewedEventIds.add(eventId);
          }
        }
      }
      return unviewedEventIds.size;
    });

export const NumUnreadPrivateChatsSelector = createSelector(
    [SortedPrivateChatsSelector, selectThreadNumUnreadMessages],
    (chats, threadNumUnreadMessages) => {
      return chats.reduce((total, thread) => {
        const {threadId = ''} = thread;
        if (threadNumUnreadMessages[threadId] > 0) {
          return ++total;
        }
        return total;
      }, 0);
    });

export const MembersBottomTabBadgeCountSelector = createSelector(
    [
      SelectRecentlyJoinedMembers,
      MembersScreenLastViewedTimestampSelector,
      selectActiveUserId,
      selectUnviewedCommunityProspects,
    ],
    (recentMembers, lastViewedTimestamp, currentUserId, prospects) => {
      const numNewMembers = recentMembers
          .filter(communityMember => {
            const {id: userId = '', joinTimestamp = 0} = communityMember;
            return joinTimestamp > (lastViewedTimestamp || 0)
                && userId !== currentUserId;
          })
          .length;
      const numUnviewedProspects = prospects.size;
      return numNewMembers + numUnviewedProspects;
    });

export const CommunitiesWithUnviewedContentSelector = createSelector(
    [
      selectJoinedCommunityProfiles,
      selectCommunityImpressions,
      SelectCommunityMembers,
      selectActiveUserId,
      SelectActiveCommunityId,
      AllThreadJoinStatesSelector,
      AllThreadLastActivityTimestampsSelector,
      AllCommunityThreadIdsSelector,
    ],
    (
        joinedCommunityProfiles,
        impressions,
        communityMembers,
        activeUserId,
        activeCommunityId,
        allThreadsJoinState,
        allThreadsLastActivityTimestamps,
        allCommunityThreadIds,
    ): Set<string> => {
      const communityIds = joinedCommunityProfiles
          .filter(profile => {
            const {
              communityId,
              latestEventCreationTimestamp,
              latestMemberCreationTimestamp,
              latestProspectiveMemberCreationTimestamp,
              latestThreadCreationTimestamp,
            } = profile;

            if (!communityId || communityId === activeCommunityId) {
              return false;
            }

            const member = communityMembers[communityId]?.[activeUserId || ''] ?? {};
            const isAdmin = member.isLead ?? false;
            const isAmbassador = member.ambassadorStatus === 'active';
            const lastViewed = impressions[communityId]?.['Community']?.lastTimestamp ?? 0;

            if ((isAmbassador || isAdmin) &&
                lastViewed < (latestProspectiveMemberCreationTimestamp || 0)) {
              return true;
            }

            if (lastViewed < (latestEventCreationTimestamp || 0)
                || lastViewed < (latestMemberCreationTimestamp || 0)
                || lastViewed < (latestThreadCreationTimestamp || 0)) {
              return true;
            }

            const threadIds = allCommunityThreadIds[communityId] || {};
            for (const threadId of Object.keys(threadIds)) {
              if (allThreadsJoinState[threadId] &&
                  lastViewed < (allThreadsLastActivityTimestamps[threadId] || 0)) {
                return true;
              }
            }

            return false;
          })
          .map(profile => profile.communityId || '');
      return new Set(communityIds);
    });