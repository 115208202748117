import React, {useCallback, useEffect, useState} from 'react';
import {MarketplaceListItem} from '@heylo/components/src/features/marketplaces/MarketplaceListItem';
import {HeyloListSubheader} from '@heylo/components/src/ui/list/HeyloListSubheader';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {HeyloList} from '../../ui/list/HeyloList';
import {FlatList, Linking, Platform, Text, View} from 'react-native';
import {
  CommunityProfile,
  Marketplace,
} from '@heylo/shared/src/types/firebase-types';
import {RequestNewLocationCard} from './RequestNewLocationCard';
import {HeyloModal} from '../../ui/modal/HeyloModal';
import {GroupCard, GroupCardUiType} from './GroupCard';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {useGroupSearchState} from './useGroupSearchState';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  selectSortedMetros,
  selectSortedSchools,
} from '@heylo/shared/src/features/marketplaces/Selectors';
import {MarketplacesFirebase} from '@heylo/shared/src/features/marketplaces/Firebase';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';

export const MarketplaceList = (
    {
      context,
      filterText,
      onMarketplacePress,
      onGroupPress,
    }: {
      context: 'discover' | 'create',
      filterText: string,
      onMarketplacePress: (value: Marketplace) => void,
      onGroupPress: (communityId: string) => void,
    }) => {

  const dispatch = useDispatch();

  const {
    metros,
    schools,
  } = useSelector((state: RootState) => ({
    metros: selectSortedMetros(state),
    schools: selectSortedSchools(state),
  }), shallowEqual);

  useEffect(() => {
    dispatch(MarketplacesFirebase.LoadAllMarketplaces());
  }, []);

  const {searchResults} = useGroupSearchState({filterText});

  const text = useTextStyles();
  const [showModal, setShowModal] = useState(false);

  // TODO: replace with an in-app action
  const openMailClient = () => {
    if (Platform.OS !== 'web') {
      Linking.openURL(`mailto:support@heylo.co?subject=Bring Heylo to my school or city&body=Hey Heylo team,<br><br>Let's bring Heylo to my school or city!<br><br>[Please add your name]<br><br>[Please add your school or city name]<br><br>Can't wait to launch 🚀!<br><br>PS - A Heylo team member will be in touch in the next 24 hours.`)
          .catch(e => {
            console.warn('open url error', e);
            setShowModal(true);
          });
    }
  }

  const renderSearchResult = ({item}: { item: CommunityProfile }) => {
    const {communityId = '', communityName, locationName} = item;
    if (!communityName || !communityId) {
      return null;
    }
    return (
        <View style={{
          paddingHorizontal: StyleConstants.SPACING,
          paddingBottom: StyleConstants.SPACING,
        }}>
          <GroupCard
              community={item}
              onPress={() => onGroupPressWrapper(communityId)}
              uiType={GroupCardUiType.SEARCH_RESULT}
          />
        </View>
    );
  }

  const logger = useLoggingService();

  const onMarketplaceWrapper = useCallback((marketplace: Marketplace) => {
    const {marketplaceId = '', vanityUri = ''} = marketplace;
    logger.logEvent(AnalyticsEvent.MARKETPLACE_PRESS, {
      marketplaceId,
      vanityUri,
    });
    onMarketplacePress(marketplace);
  }, [onMarketplacePress, logger]);

  const onGroupPressWrapper = useCallback((communityId: string) => {
    logger.logEvent(AnalyticsEvent.COMMUNITY_PROFILE_TAPPED, {
      communityId,
      context: 'search',
    });
    onGroupPress(communityId);
  }, [onGroupPress, logger]);

  return (
      <>
        {!!filterText ? (
            <FlatList
                data={searchResults}
                keyExtractor={item => item.communityId ?? ''}
                renderItem={renderSearchResult}
            />
        ) : (
            <>
              <HeyloList>
                <HeyloListSubheader>SCHOOLS</HeyloListSubheader>
                {schools.map(value => {
                  const {marketplaceId} = value;
                  return (
                      <MarketplaceListItem
                          context={context}
                          key={`market${marketplaceId}`}
                          marketplace={value}
                          onPress={() => onMarketplaceWrapper(value)}
                      />
                  );
                })}
              </HeyloList>

              <HeyloList>
                <HeyloListSubheader>CITIES</HeyloListSubheader>
                {metros.map(value => {
                  const {marketplaceId} = value;
                  return (
                      <MarketplaceListItem
                          context={context}
                          key={`market${marketplaceId}`}
                          marketplace={value}
                          onPress={() => onMarketplaceWrapper(value)}
                      />
                  );
                })}
              </HeyloList>
            </>
        )}

        <RequestNewLocationCard onPress={openMailClient}/>
        <View style={{height: 50}}/>

        <HeyloModal visible={showModal} onDismiss={() => setShowModal(false)}>
          <Text style={text.h6}>
            Could not open email
          </Text>
          <Text style={text.body1}>
            To launch Heylo in a new location, please send us an email to support@heylo.co and we'll get back to you in 24 hours.
          </Text>
        </HeyloModal>
      </>
  );
};
