import {EmailInvite} from '@heylo/shared/src/types/firebase-types';
import {
  FirebaseDatabase,
  FirebaseServerTimestamp,
} from '@heylo/firebase-database';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {IncrementUserInteraction} from '@heylo/shared/src/features/userEvents/Actions';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import normalizeEmail from 'validator/lib/normalizeEmail';

export const FirebaseCreateEmailInvites = (communityId: string, userId: string, emails: string[])
    : ThunkReturnType<Promise<void>> => dispatch => {
  return new Promise<void>((resolve, reject) => {
    const promises: Promise<any>[] = [];
    const emailInvite: EmailInvite = {
      communityId,
      referrerUserId: userId,
      timestampCreation: FirebaseServerTimestamp(),
    };
    for (const email of emails) {
      const normalized = normalizeEmail(email);
      if (!normalized) {
        continue;
      }
      emailInvite.email = normalized;
      promises.push(
          FirebaseDatabase().ref(`/invites/emails/${userId}`).push(emailInvite));
    }
    Promise.all(promises)
        .then(() => {
          dispatch(IncrementUserInteraction(AnalyticsEvent.INVITE_EMAIL_SENT));
          resolve();
        })
        .catch(e => {
          console.warn('error creating email invites', e);
          reject();
        });
  });
};