import React, {CSSProperties} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {HeyloText} from 'lib/materialUi/HeyloText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'black',
  },
}));

interface DialogAction {
  color?: 'primary' | 'secondary',
  label: string,
  onClick?: () => void,
}

interface Props {
  actions?: DialogAction[],
  children?: any | any[],
  closeButton?: boolean,
  contentClassName?: string,
  contentStyles?: CSSProperties,
  disableBackdropClick?: boolean,
  fullScreen?: boolean,
  fullWidth?: boolean,
  maxWidth?: 'xs' | 'sm',
  onClose?: () => void,
  open: boolean,
  label?: string,
  title?: string,
}

export const HeyloDialog = (props: Props) => {
  const {
    actions,
    children,
    closeButton = true,
    contentClassName,
    contentStyles,
    disableBackdropClick,
    fullScreen,
    fullWidth,
    label,
    maxWidth,
    onClose,
    open,
    title,
  } = props;

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const classes = useStyles();

  return (
      <Dialog
          aria-label={title ?? label}
          disableBackdropClick={disableBackdropClick}
          fullScreen={fullScreen}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
      >
        {(!!title || closeButton) && (
            <DialogTitle disableTypography>
              <HeyloText variant={maxWidth === 'xs' ? 'h5' : 'h3'}>
                {title}
              </HeyloText>
              {closeButton ? (
                  <IconButton aria-label="close"
                              className={classes.closeButton}
                              onClick={onClose}>
                    <CloseIcon/>
                  </IconButton>
              ) : null}
            </DialogTitle>
        )}
        <DialogContent className={contentClassName} style={contentStyles}>
          {children}
        </DialogContent>
        {actions && actions.length > 0 && (
            <DialogActions>
              {actions.map((action, index) => {
                const {color, label: buttonLabel, onClick} = action;
                return (
                    <Button
                        color={color}
                        key={`dialogButton${index}`}
                        onClick={onClick ?? onClose}
                    >
                      {buttonLabel}
                    </Button>
                );
              })}
            </DialogActions>
        )}
      </Dialog>
  );
};
