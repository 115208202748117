import React, {useContext, useState} from 'react';
import {Keyboard, Platform} from 'react-native';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {IconButton, Menu} from 'react-native-paper';
import {useImageUploader} from '@heylo/shared/src/services/image/ImageUploaderContext';
import {ImageUploadMetadata} from '@heylo/shared/src/services/image/ImageUploader';
import {StyleVars} from '../../styles/StyleVars';
import {WebPhotoUploadDialog} from '../../ui/image/WebPhotoUploadDialog';
import {ChatScreenContext} from './ChatScreenContext';
import {IsPrivateThreadType} from '@heylo/shared/src/types/ThreadTypes';

enum State {
  IDLE,
  UPLOAD_ERROR,
}

export const ChatImageUploader = (
    {
      onChange,
      onError,
    }: {
      onChange: (downloadUri: Promise<string>, image: ImageUploadMetadata) => void,
      onError: (errorMessage: string) => void,
    }) => {

  const [state, setState] = useState(State.IDLE);

  const {thread} = useContext(ChatScreenContext);
  const {communityId, threadId, threadType} = thread;
  const imageUploadPath = IsPrivateThreadType(threadType)
      ? `privateChats/photos/${threadId}`
      : `communities/${communityId}/threads/${threadId}`

  const text = useTextStyles();

  const [menuVisible, setMenuVisible] = useState(false);
  const showMenu = () => {
    Keyboard.dismiss();
    setMenuVisible(true);
  }
  const hideMenu = () => setMenuVisible(false);

  const imageUploader = useImageUploader();

  const handleFromCamera = () => {
    hideMenu();
    if (Platform.OS === 'web') {
      console.warn('handleFromCamera not implemented on web');
      return;
    }
    uploadImageForMobile(true);
  }

  const fileInputRef = React.createRef<HTMLInputElement>();

  const handleFromStorage = () => {
    hideMenu();
    if (Platform.OS === 'web') {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      uploadImageForMobile(false);
    }
  }

  const uploadImageForMobile = async (useCamera: boolean) => {
    let metadata: ImageUploadMetadata | null = null;
    metadata = await imageUploader.onChooseImage(useCamera, {
      cropShape: 'none',
      maximumHeight: 2000,
      maximumWidth: 2000,
    });
    if (!metadata) {
      console.warn('no image metadata returned');
      return;
    }
    try {
      // NB: in rare cases, the keyboard will appear on iOS (e.g., create
      // community flow)
      Keyboard.dismiss();
      const uploadPromise = imageUploader.uploadImage(imageUploadPath, metadata);
      onChange(uploadPromise, metadata);
    } catch (e) {
      console.warn('Error uploading image', e);
      setState(State.UPLOAD_ERROR);
      onError('Error uploading image. Please try again!');
    }
  }

  const onPress = () => {
    if (Platform.OS === 'web') {
      handleFromStorage();
    } else {
      showMenu();
    }
  };

  return (
      <>
        {Platform.OS === 'web' && (
            <WebPhotoUploadDialog
                onCancel={() => setState(State.IDLE)}
                onChange={onChange}
                onError={() => setState(State.UPLOAD_ERROR)}
                ref={fileInputRef}
                storagePath={imageUploadPath}
            />
        )}
        <Menu
            anchor={
              <IconButton
                  color={StyleVars.Colors.Black}
                  icon='image'
                  onPress={onPress}
                  size={30}
              />
            }
            onDismiss={() => setMenuVisible(false)}
            theme={{roundness: 5}}
            visible={menuVisible}
        >
          <Menu.Item
              icon="camera"
              onPress={handleFromCamera}
              title="Use camera"
              titleStyle={text.body1}
          />
          <Menu.Item
              icon="upload"
              onPress={handleFromStorage}
              title="Upload photo"
              titleStyle={text.body1}
          />
        </Menu>
      </>
  );
}