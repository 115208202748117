import React, {useContext} from 'react';
import {Text, TouchableWithoutFeedback, View} from 'react-native';
import {Avatar} from '../userProfile/Avatar';
import {ChatScreenContext} from './ChatScreenContext';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';

type Props = {
  isUser?: boolean,
  threadType?: string,
  userFullName?: string,
  userId: string,
};

export const MessageBio = React.memo((props: Props) => {
  const {
    isUser,
    userFullName,
    userId,
  } = props;

  const {onUserPress} = useContext(ChatScreenContext);
  const onPress = () => {
    onUserPress(userId);
  }

  const text = useTextStyles();

  return (
      <View style={{
        flex: 1,
        flexDirection: isUser ? 'row-reverse' : 'row',
        width: '100%',
        height: 30,
        paddingHorizontal: 7,
      }}>
        <TouchableWithoutFeedback onPress={onPress}>
          {/* NB: This view IS required in order for Avatar to be tappable */}
          <View>
            <Avatar
                size={30}
                userId={userId}
            />
          </View>
        </TouchableWithoutFeedback>
        <View style={{width: 5}}/>
        <View style={{
          height: 30,
          justifyContent: 'center',
        }}>
          <Text
              onPress={onPress}
              style={text.body1}
          >
            {userFullName}
          </Text>
        </View>
        {/*{(isAmbassador || isLead) && (*/}
        {/*  <Icon*/}
        {/*    color={StyleVars.Colors.Black}*/}
        {/*    containerStyle={{*/}
        {/*      alignItems: 'center',*/}
        {/*      justifyContent: 'center',*/}
        {/*    }}*/}
        {/*    iconStyle={{*/}
        {/*      alignSelf: 'center',*/}
        {/*      minWidth: 20,*/}
        {/*      textAlign: 'center',*/}
        {/*    }}*/}
        {/*    name={isAmbassador ? 'fire' : 'bolt'}*/}
        {/*    size={isAmbassador ? 20 : 18}*/}
        {/*    type={isAmbassador ? 'material-community' : 'font-awesome'}*/}
        {/*  />*/}
        {/*)}*/}
      </View>
  );
});

// @ts-ignore
MessageBio.whyDidYouRender = true;
