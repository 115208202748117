import {useEffect, useState} from 'react';
import {ActiveMarketplaceChanged} from '@heylo/shared/src/features/marketplaces/Slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  selectActiveMarketId, selectActiveMarketplace,
  selectMarketplaces,
} from '@heylo/shared/src/features/marketplaces/Selectors';
import {Marketplace} from '@heylo/shared/src/types/firebase-types';
import {ActiveCommunityUpdated} from '@heylo/shared/src/features/communities/Slice';

export const useActiveMarketplaceState = (unresolvedMarketplaceId: string) => {
  const dispatch = useDispatch();

  const {
    activeMarketplaceId,
    allMarketplaces,
    marketplace = {},
  } = useSelector((state: RootState) => ({
    activeMarketplaceId: selectActiveMarketId(state),
    allMarketplaces: selectMarketplaces(state),
    marketplace: selectActiveMarketplace(state),
  }), shallowEqual);

  const [finalizedMarketplaceId, setFinalizedMarketplaceId] = useState(false);

  useEffect(() => {
    if (finalizedMarketplaceId || !allMarketplaces) {
      return;
    }
    const resolveMarketplaceId = (unresolvedMarketplaceId: string) => {
      if (allMarketplaces[unresolvedMarketplaceId]) {
        return unresolvedMarketplaceId;
      }
      for (const [id, marketplace] of Object.entries(allMarketplaces) as [string, Readonly<Marketplace>][]) {
        if (marketplace.vanityUri === unresolvedMarketplaceId) {
          return id;
        }
      }
      return unresolvedMarketplaceId;
    }
    const resolvedMarketplaceId = resolveMarketplaceId(unresolvedMarketplaceId);
    if (resolvedMarketplaceId !== activeMarketplaceId) {
      dispatch(ActiveMarketplaceChanged(resolvedMarketplaceId));
    }
    setFinalizedMarketplaceId(true);
  }, [allMarketplaces, activeMarketplaceId, unresolvedMarketplaceId]);

  useEffect(() => {
    dispatch(ActiveCommunityUpdated(''));
  }, []);

  return {activeMarketplaceId, marketplace};
};