import React, {useEffect, useRef, useState} from 'react';
import {
  Platform,
  Text,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import MaterialCommunityIcon
  from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {NativeTextInput} from './NativeTextInput';

type Props = TextInputProps & {
  label?: string,
  onActivated?: () => void,
  onBlur?: () => void,
  onChangeText: (text: string) => void,
  onDeactivated?: () => void,
  onFocus?: () => void,
  value?: string,
};

export const HeyloFilterInput = React.memo((props: Props) => {
  const {
    label,
    onActivated,
    onBlur,
    onChangeText,
    onDeactivated,
    onFocus,
    value,
  } = props;

  useEffect(() => {
    if (value) {
      setActivated(true);
    }
  }, [value]);

  const [activated, setActivated] = useState(false);
  const [focused, setFocused] = useState(false);
  useEffect(() => {
    if (!focused && !value) {
      setActivated(false);
      if (typeof onBlur === 'function') {
        onBlur();
      }
    }
  }, [focused, value]);

  useEffect(() => {
    if (activated && typeof onActivated === 'function') {
      onActivated();
    }
    if (!activated && typeof onDeactivated === 'function') {
      onDeactivated();
    }
  }, [activated]);

  const handleOnBlur = () => {
    setFocused(false);
  };

  const handleOnFocus = () => {
    setFocused(true);
    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  const text = useTextStyles();
  const palette = usePalette();
  const textInputRef = useRef<TextInput>(null);

  return (
      <TouchableWithoutFeedback
          onPress={() => {
            setActivated(true);
            setFocused(true);
            setTimeout(() => {
              if (textInputRef.current) {
                textInputRef.current.focus();
              }
            }, 50);
          }}
      >
        <View style={{
          backgroundColor: palette.grey.lightest,
          borderRadius: 100,
          height: 35,
          justifyContent: 'center',
          paddingLeft: StyleConstants.SPACING,
        }}>
          <View style={{
            justifyContent: 'center',
            display: activated ? 'flex' : 'none',
            height: activated ? '100%' : 0,
          }}>
            <NativeTextInput
                autoCapitalize={'words'}
                autoCorrect={false}
                multiline={false}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                ref={textInputRef}
                style={[text.body1, {paddingVertical: 5}]}
                underlineColorAndroid='transparent'
                value={value}
                {...props}
            />
            <TouchableOpacity
                onPress={() => {
                  handleOnBlur();
                  onChangeText('');
                  setActivated(false);
                }}
                style={{
                  height: 40,
                  justifyContent: 'center',
                  position: 'absolute',
                  right: 0,
                  top: Platform.OS === 'ios' ? -1 : undefined,
                  width: 40,
                }}
            >
              <MaterialCommunityIcon
                  color={palette.grey.main}
                  name={'close-circle'}
                  size={24}
              />
            </TouchableOpacity>
          </View>

          <View style={{
            alignItems: 'center',
            display: activated ? 'none' : 'flex',
            flexDirection: 'row',
            height: activated ? 0 : '100%',
            justifyContent: 'center',
          }}>
            <MaterialIcon
                color={palette.grey.main}
                name={'search'}
                size={24}
                style={{marginRight: 3}}
            />
            <Text style={{
              ...text.body1,
              color: palette.grey.main,
            }}>
              {label}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
  );
});