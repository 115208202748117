export const NewUserChannels = {
  UNKNOWN: 'UNKNOWN',
  // TODO: remove
  COMMUNITY_CODE: 'COMMUNITY_CODE',
  COMMUNITY_DISCOVER: 'COMMUNITY_DISCOVER',
  COMMUNITY_EVENT_LINK: 'COMMUNITY_EVENT_LINK',
  COMMUNITY_INVITE_LINK: 'COMMUNITY_INVITE_LINK',
  COMMUNITY_PROFILE_LINK: 'COMMUNITY_PROFILE_LINK',
  CREATE_COMMUNITY: 'CREATE_COMMUNITY',
  INSTALL_LINK: 'INSTALL_LINK',
};

