import {createSlice} from '@reduxjs/toolkit';

export enum DrawerState {
  CLOSED,
  OPENED
}

export type NavigationState = {
  drawerState: DrawerState,
};

const initialState: NavigationState = {
  drawerState: DrawerState.CLOSED,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    CloseDrawer: (state) => {
      state.drawerState = DrawerState.CLOSED;
    },
    OpenDrawer: (state) => {
      state.drawerState = DrawerState.OPENED;
    },
  },
});

const {actions, reducer: NavigationReducer} = navigationSlice;

export const {
  CloseDrawer,
  OpenDrawer,
} = actions;

export {NavigationReducer};