import {RootState} from '../../services/redux/Redux';
import {selectMarketplaces} from '../marketplaces/Selectors';
import {Marketplace} from '../../types/firebase-types';
import {createSelector} from 'reselect';

export const selectDraftCommunityProfile = (state: RootState) => state.communityCreation?.communityProfile ?? {};

export const selectDraftCommunityMarketplace = createSelector(
    [selectDraftCommunityProfile, selectMarketplaces],
    (profile, marketplaces): Marketplace | undefined => {
      const {marketplaceId = ''} = profile;
      return marketplaces[marketplaceId];
    });