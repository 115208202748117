import {
  THREAD_TYPE_DIRECT,
  THREAD_TYPE_GROUP,
  THREAD_TYPE_SUPPORT,
} from '../types/ThreadTypes';

export const GetUserChatStateCollection = (threadType: string | null | undefined) => {
  switch (threadType) {
    case THREAD_TYPE_DIRECT:
      return 'userChatState/direct';
    case THREAD_TYPE_GROUP:
      return 'userChatState/group';
    case THREAD_TYPE_SUPPORT:
      return 'userChatState/support';
  }
  console.warn('[GetUserChatStateCollection] unhandled threadType ' + threadType);
  return '';
};