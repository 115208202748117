import {ReadFile} from '@heylo/components/src/lib/dom/file/File';

export type Rectangle = { width: number, height: number, x: number, y: number };

export const CreateImageFromDataUri = (dataUri: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.src = dataUri
  });
};

const getRotatedImage = async (dataUri: string, rotation = 0) => {
  const image = await CreateImageFromDataUri(dataUri);
  const canvas = document.createElement('canvas');

  const orientationChanged =
      rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
  if (orientationChanged) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height
  }

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    console.error('2d context not available');
    return;
  }
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);

  return new Promise<string>(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file))
    }, 'image/jpeg')
  })
};

export const ReadImageFile = async (file: File) => {
  let dataUri = await ReadFile(file);
  // apply rotation if needed
  // const orientation = await getOrientation(file);
  // let rotation = 0;
  // switch (orientation) {
  //   case Orientation.BOTTOM_RIGHT:
  //     rotation = 180;
  //     break;
  //   case Orientation.RIGHT_TOP:
  //     rotation = 90;
  //     break;
  //   case Orientation.LEFT_BOTTOM:
  //     rotation = -90;
  //     break;
  // }
  // if (rotation) {
  //   dataUri = (await getRotatedImage(dataUri, rotation)) ?? '';
  // }
  return dataUri
};

export const CropImage = async (dataUri: string, pixelCrop: Rectangle): Promise<Blob | null> => {
  const image = await CreateImageFromDataUri(dataUri)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    console.error('2d context not available');
    return Promise.resolve(null);
  }
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  ctx.drawImage(image,
      -1 * pixelCrop.x,
      -1 * pixelCrop.y,);
  // As Base64 string; useful for debugging in browser.
  // return canvas.toDataURL('image/jpeg');
  return new Promise<Blob | null>(resolve => {
    canvas.toBlob(blob => resolve(blob))
  });
};
