import React, {useMemo} from 'react';
import {UserProfile} from '@heylo/shared/src/types/firebase-types';
import {GetBestUserProfilePhotoUrl} from '@heylo/shared/src/features/userProfiles/Selectors';
import {Colors} from 'styles/RootStyles';
import {useHistory, useLocation} from 'react-router-dom';
import {useLoggingService} from '@heylo/shared/src/services/logging/LoggingContext';
import {AnalyticsEvent} from '@heylo/shared/src/constants/AnalyticsEvents';
import {Text, TouchableOpacity, View} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {HeyloImage} from '@heylo/components/src/ui/image';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';

type Props = {
  isNew?: boolean,
  maxWidth?: number,
  selected?: boolean,
  userProfile: UserProfile,
}

export const Attendee = React.memo((props: Props) => {
  const {
    isNew,
    maxWidth,
    selected,
    userProfile,
  } = props;
  const {
    bio,
    goals: interests,
    id: userId = '',
    fullName: name,
  } = userProfile;
  const photoUrl = GetBestUserProfilePhotoUrl(userProfile, 100);

  const history = useHistory();
  const location = useLocation();
  const logger = useLoggingService();
  const text = useTextStyles();

  const handleClick = () => {
    const params = new URLSearchParams(location.search);
    const hadMemberIdAlready = params.get('memberId');
    params.set('memberId', userId);
    logger.logEvent(AnalyticsEvent.MEMBER_PROFILE_TAPPED);
    const newPath = location.pathname + `?${params.toString()}`;
    if (hadMemberIdAlready) {
      history.replace(newPath);
    } else {
      history.push(newPath);
    }
  };

  // const theme = useTheme();
  // const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  // TODO: remove this super hack. Without it, Attendees will break out of their
  // container and create a horizontal scroll or squish other content on the
  // screen (like sidebar).
  const MAX_WIDTH = 300;

  const style = useMemo(() => ({
    borderRadius: 1000,
    opacity: photoUrl ? 1 : 0.3,
  }), [photoUrl]);

  return (
      <TouchableOpacity
          onPress={handleClick}
          style={{
            alignItems: 'center',
            backgroundColor: selected ? '#f2f2f2' : 'transparent',
            borderRadius: selected ? 100 : 0,
            display: 'flex',
            flexDirection: 'row',
            maxWidth: maxWidth || MAX_WIDTH,
          }}>
        <View style={{
          height: StyleConstants.SPACING * 4,
          width: StyleConstants.SPACING * 4,
        }}>
          <HeyloImage
              aspectRatio={1}
              style={style}
              uri={photoUrl || '/person_circle.png'}
          />
        </View>

        <View style={{
          flex: 1,
          paddingLeft: StyleConstants.SPACING,
          paddingRight: selected ? StyleConstants.SPACING : 0,
          minWidth: 0,
          overflow: 'hidden',
        }}>
          <View style={{flex: 1, flexDirection: 'row'}}>
            <Text
                numberOfLines={1}
                ellipsizeMode={'tail'}
                style={[text.body1, text.bold]}
            >
              {name}
            </Text>
            {isNew && (
                <span style={{
                  alignItems: 'center',
                  backgroundColor: Colors.Primary,
                  borderRadius: 100,
                  color: 'white',
                  display: 'flex',
                  fontSize: '1.3rem',
                  fontWeight: 'bold',
                  marginLeft: '0.7rem',
                  padding: '0 0.5rem',
                }}>
              NEW
            </span>
            )}
          </View>

          <Text
              numberOfLines={1}
              ellipsizeMode={'tail'}
              style={[text.body1, {color: '#7B7B7B'}]}
          >
            {bio}
          </Text>
          <Text
              numberOfLines={1}
              ellipsizeMode={'tail'}
              style={[text.body1, {color: '#7B7B7B'}]}
          >
            {interests}
          </Text>

        </View>
      </TouchableOpacity>
  );
});

// @ts-ignore
Attendee.whyDidYouRender = true;