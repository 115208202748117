import makeStyles from '@material-ui/core/styles/makeStyles'
import {Colors} from '../../styles/RootStyles';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';

export const useSidebarStyles = makeStyles(theme => ({
  selected: {
    '& a': {
      backgroundColor: Colors.Secondary,
    },
    '& svg': {
      color: 'white',
    },
    '& p': {
      color: 'white',
      fontWeight: 'bold',
    },
  },
  containerIcon: {
    alignItems: 'center',
    display: 'flex',
    height: '3rem',
    justifyContent: 'center',
    marginRight: '0.7rem',
    width: '3rem',
  },
  containerRow: {
    alignItems: 'center',
    color: 'black',
    cursor: 'pointer',
    display: 'flex',
    height: '5rem',
    paddingLeft: `${StyleConstants.SPACING}px`,
    paddingRight: `${StyleConstants.SPACING}px`,
    textDecoration: 'none',
    textOverflow: 'ellipsis',
  },
}));
