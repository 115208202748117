import React from 'react';

export const HeyloLogo = () => {
  return (
      <a href={'https://join.heylo.co'}
         rel={'noopener noreferrer'}
         target={'_blank'}>
        <img
            alt={'Heylo logo'}
            src={'/heylo-small.png'}
            style={{
                height: '47px',
                width: '50px',
            }}
        />
      </a>
  );
}