import {createSelector} from 'reselect';
import {
  SelectActiveCommunityId,
  SelectActiveCommunitySettings,
  SelectCommunityMembers,
  SelectCommunityMetadata,
  SelectCommunityProspects,
} from '@heylo/shared/src/features/communities/Selectors';
import {
  CommunityMember,
  CommunityProspect,
  UserProfile,
} from '@heylo/shared/src/types/firebase-types';
import {
  HasProfilePhoto,
  SelectUserProfiles,
} from '@heylo/shared/src/features/userProfiles/Selectors';
import _ from 'lodash';
import moment from 'moment';
import {COMMUNITY_SETTINGS_POLICY_EVERYONE} from '../communities/Constants';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {selectUserImpressionMetrics} from '@heylo/shared/src/features/userEvents/Selectors';
import {
  selectActiveUserCommunityIds,
  selectProspectiveCommunityIdsSet,
} from '@heylo/shared/src/features/userSettings/Selectors';
import {RootState} from '@heylo/shared/src/services/redux/Redux';

const SortByName = (a: UserProfile, b: UserProfile) => {
  const aHasProfilePhoto = HasProfilePhoto(a);
  const bHasProfilePhoto = HasProfilePhoto(b);
  if (aHasProfilePhoto && !bHasProfilePhoto) {
    return -1;
  }
  if (!aHasProfilePhoto && bHasProfilePhoto) {
    return 1;
  }
  return (a.fullName || '').toLocaleLowerCase()
      .localeCompare((b.fullName || '').toLocaleLowerCase());
};

const SortByLastUpdateTime = (a: CommunityMember, b: CommunityMember) => {
  return (b.profileUpdatedTimestamp ?? 0) - (a.profileUpdatedTimestamp ?? 0);
};

export const SelectActiveCommunityMembers = createSelector(
    [SelectCommunityMembers, SelectActiveCommunityId],
    (members, communityId): { [userId: string]: CommunityMember } => {
      return members[communityId] ?? {};
    });

export const SelectActiveCommunityUserProfiles = createSelector(
    [SelectActiveCommunityMembers, SelectUserProfiles],
    (members, userProfiles): { [userId: string]: UserProfile } => {
      return _.mapValues(members, (member, userId) => userProfiles[userId] || {});
    });

export const SelectActiveCommunityLeadership = createSelector(
    [SelectActiveCommunityMembers, SelectUserProfiles],
    (members, userProfiles): UserProfile[] => {
      return Object.values(members)
          .filter(member => member.title)
          .map(member => userProfiles[member.id || ''] || {})
          .sort(SortByName);

    });

export const SelectSortedActiveCommunityUserProfiles = createSelector(
    [SelectActiveCommunityMembers, SelectUserProfiles],
    (allMembers, userProfiles)
        : UserProfile[] => {
      return Object.values(allMembers)
          .map(member => userProfiles[member.id || ''] || null)
          .filter(member => !!member)
          .sort(SortByName);
    });

export const SelectSupportCommunityMembers = createSelector(
    [SelectCommunityMembers, SelectCommunityMetadata],
    (allMembers, allCommunities) => {
      for (const communityId of Object.keys(allCommunities || {})) {
        if (allCommunities[communityId].type === 'support') {
          return allMembers[communityId] ?? {};
        }
      }
      return {};
    });

export const SelectRecentlyJoinedMembers = createSelector(
    [SelectActiveCommunityMembers],
    (allMembers): CommunityMember[] => {
      const sevenDaysAgo = moment().subtract(7, 'days').startOf('day').valueOf();
      return Object.values(allMembers)
          .filter(member => (member.joinTimestamp ?? 0) > sevenDaysAgo)
          .sort(SortByLastUpdateTime);
    });

export const SelectRecentlyJoinedUserProfiles = createSelector(
    [SelectRecentlyJoinedMembers, SelectUserProfiles],
    (recentlyJoined, userProfiles): UserProfile[] => {
      return recentlyJoined
          .map(member => userProfiles[member.id || ''] || {});
    });

export const SelectRecentlyUpdatedMembers = createSelector(
    [SelectActiveCommunityMembers, SelectRecentlyJoinedMembers, SelectUserProfiles],
    (allMembers, recentlyJoinedMembers, userProfiles)
        : UserProfile[] => {
      const recentlyJoinedUserIds = new Set(recentlyJoinedMembers.map(member => member.id || ''));
      const sevenDaysAgo = moment().subtract(7, 'days').startOf('day').valueOf();
      return Object.values(allMembers)
          .filter(member => (member.profileUpdatedTimestamp ?? 0) > sevenDaysAgo
              && !recentlyJoinedUserIds.has(member.id ?? ''))
          .sort(SortByLastUpdateTime)
          .map(member => userProfiles[member.id || ''] || {});
    });

export const selectActiveCommunityMember = createSelector(
    [selectActiveUserId, SelectActiveCommunityMembers],
    (userId, allMembers)
        : CommunityMember => {
      return allMembers[userId || ''] ?? {};
    });

export const selectActiveUserIsMemberOfActiveCommunity = createSelector(
    [SelectActiveCommunityId, selectActiveUserCommunityIds],
    (activeCommunityId, joinedCommunityIds): boolean => {
      const communityIds = new Set(joinedCommunityIds);
      return communityIds.has(activeCommunityId);
    });

export const selectActiveUserIsProspectOfActiveCommunity = createSelector(
    [selectProspectiveCommunityIdsSet, SelectActiveCommunityId],
    (prospectiveCommunityIds, activeCommunityId)
        : boolean => {
      return !!activeCommunityId && prospectiveCommunityIds.has(activeCommunityId);
    });

export const SelectActiveUserIsAmbassadorInActiveCommunity = createSelector(
    [SelectActiveCommunityMembers, selectActiveUserId],
    (members, currentUserId) => {
      return members[currentUserId || '']?.ambassadorStatus === 'active';
    });

export const SelectActiveUserIsAdminInActiveCommunity = createSelector(
    [SelectActiveCommunityMembers, selectActiveUserId],
    (members, currentUserId) => {
      return members[currentUserId || '']?.isLead ?? false;
    });

function SelectCurrentCommunityMember(state: RootState, userId: string): CommunityMember {
  return SelectActiveCommunityMembers(state)[userId] || {}
}

export function SelectIsUserACommunityAdmin(state: RootState, userId: string): boolean {
  return SelectCurrentCommunityMember(state, userId).isLead ?? false;
}

export const SelectActiveUserHasInviteMembersPermissions = createSelector(
    [SelectActiveUserIsAdminInActiveCommunity, SelectActiveCommunitySettings],
    (isAdmin, settings) => {
      return isAdmin
          || (settings.inviteMembersPolicy ?? COMMUNITY_SETTINGS_POLICY_EVERYONE) === COMMUNITY_SETTINGS_POLICY_EVERYONE;
    });

export const ActiveUserHasContentWritePermissionsSelector = createSelector(
    [SelectActiveUserIsAdminInActiveCommunity, SelectActiveCommunitySettings],
    (isAdmin, settings) => {
      return isAdmin
          || settings.contentWritePolicy === COMMUNITY_SETTINGS_POLICY_EVERYONE;
    });

export const selectCanViewCommunityProspects =  createSelector(
    [SelectActiveUserIsAdminInActiveCommunity, SelectActiveUserIsAmbassadorInActiveCommunity],
    (isAdmin, isAmbassador) => {
      return isAdmin || isAmbassador;
    });

export const selectActiveCommunityProspects = createSelector(
    [SelectActiveCommunityId, SelectCommunityProspects, SelectUserProfiles, selectCanViewCommunityProspects],
    (communityId, allProspects, userProfiles, hasPermission)
        : UserProfile[] => {
      if (!hasPermission) {
        return [];
      }
      const prospects: CommunityProspect[] = Object.values(allProspects[communityId] ?? {});
      return _.sortBy(prospects, prospect => prospect.creationTimestamp || 0)
          .reverse()
          .map(prospect => userProfiles[prospect.prospectiveUserId || ''] || {});
    });

export const selectUnviewedCommunityProspects = createSelector(
    [selectActiveCommunityProspects, selectUserImpressionMetrics],
    (prospects, impressions): Set<string> => {
      return new Set<string>(prospects
          .filter(profile => (impressions[profile.id || '']?.firstTimestamp ?? 0) === 0)
          .map(profile => profile.id || ''));
    });
