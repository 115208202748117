export const ReadFile = async (file: File) => {
  return new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (typeof reader.result?.toString === 'function') {
        resolve(reader.result.toString());
        return;
      }
      // @ts-ignore
      resolve(reader.result || '');
    });
    reader.readAsDataURL(file);
  })
};