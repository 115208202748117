import React from 'react';
import Switch from '@material-ui/core/Switch';
import {Props} from './HeyloSwitch';

export const HeyloSwitch = (props: Props) => {
  const {
    ariaLabel,
    onChange,
    value,
  } = props;
  return (
      <Switch
          checked={value}
          color={'secondary'}
          inputProps={{'aria-label': ariaLabel}}
          onChange={(e, checked) => onChange(checked)}
      />
  );
};