import React, {useEffect, useState} from 'react';
import Container from '@material-ui/core/Container';
import {MarketplaceList} from '@heylo/components/src/features/marketplaces/MarketplaceList';
import {HeyloFilterInput} from '@heylo/components/src/ui/input/HeyloFilterInput';
import {Marketplace} from '@heylo/shared/src/types/firebase-types';
import {useHistory} from 'react-router-dom';
import {ScrollView, View} from 'react-native';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {Breadcrumbs} from '../navigation/Breadcrumbs';
import {ScrollToTopOnMount} from '../navigation/ScrollToTopOnMount';
import {useDispatch} from 'react-redux';
import {ActiveMarketplaceChanged} from '@heylo/shared/src/features/marketplaces/Slice';
import {ActiveCommunityUpdated} from '@heylo/shared/src/features/communities/Slice';

export const MarketplacesScreen = () => {
  const history = useHistory();
  const [filterText, setFilterText] = useState('');

  const goToMarketplace = (value: Marketplace) => {
    const {marketplaceId, vanityUri} = value;
    history.push(`/m/${vanityUri || marketplaceId}`);
  }

  const goToGroup = (communityId: string) => {
    history.push(`/g/${communityId}`);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ActiveCommunityUpdated(''));
    dispatch(ActiveMarketplaceChanged(''));
  }, []);

  return (
      <ScrollView
          contentContainerStyle={{minHeight: '100%'}}
          style={{height: '100%'}}
      >
        <ScrollToTopOnMount/>
        <Container maxWidth={'sm'} style={{
          backgroundColor: 'white',
          padding: 0,
          paddingBottom: StyleConstants.SPACING,
          position: 'sticky',
          top: 0,
          zIndex: 10,
        }}>
          <Breadcrumbs title={'Find groups'}/>
          <View style={{
            paddingHorizontal: StyleConstants.SPACING,
            paddingTop: StyleConstants.SPACING,
          }}>
            <HeyloFilterInput
                editable={true}
                label={'Search by group, school, or city'}
                onChangeText={setFilterText}
                value={filterText}
            />
          </View>
        </Container>

        <Container maxWidth={'sm'} style={{padding: 0}}>
          <MarketplaceList
              context={'discover'}
              filterText={filterText}
              onGroupPress={goToGroup}
              onMarketplacePress={goToMarketplace}
          />
        </Container>

      </ScrollView>
  );
};