import {
  BuildVariant,
  ResolveBuildVariant,
} from '@heylo/shared/src/util/BuildVariant';

export type HeyloConfig = {
  AMPLITUDE_API_KEY_ANONYMOUS: string;
  AMPLITUDE_API_KEY_AUTHENTICATED: string;
  AMPLITUDE_API_KEY_COMMUNITIES: string;
  ANDROID_PACKAGE_NAME: string;
  BUILD_VARIANT: BuildVariant;
  BUILD_VARIANT_STRING: string;
  DEVELOPER_SETTINGS: boolean;
  FIREBASE_DYNAMIC_LINK_URL_SCHEME: string;
  GOOGLE_PLACES_API_KEY: string;
  IOS_BUNDLE_ID: string;
  STRIPE_CONNECT_ACCOUNT_ID: string;
  STRIPE_PUBLISHABLE_KEY: string;
};


export const GetHeyloConfig = (variant?: string): HeyloConfig => {
  const buildVariant = ResolveBuildVariant(variant);
  switch (buildVariant) {
    case BuildVariant.ALPHA:
      return {
        AMPLITUDE_API_KEY_ANONYMOUS: '4cb14e58b53f0834053d8154608b8890',
        AMPLITUDE_API_KEY_AUTHENTICATED: 'e6f72a39c346b096ac98d6fd289f2cc4',
        AMPLITUDE_API_KEY_COMMUNITIES: '6ebc08777c445cf44f13e135c8e94a8c',
        ANDROID_PACKAGE_NAME: 'com.piccup.debug',
        BUILD_VARIANT: BuildVariant.ALPHA,
        BUILD_VARIANT_STRING: 'alpha',
        DEVELOPER_SETTINGS: true,
        FIREBASE_DYNAMIC_LINK_URL_SCHEME: 'dev.link',
        GOOGLE_PLACES_API_KEY: 'AIzaSyBe4OTQoyjXo5_udFrCFQbRda1d0GSwgMA',
        IOS_BUNDLE_ID: 'com.piccup.debug',
        STRIPE_CONNECT_ACCOUNT_ID: 'ca_HPAOP6Il0FlXyRI4Yno9KdftnJRwxO5Z',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_51GqM2mJiN1vpbRBNZwk81l5GBFTczLFcnTJU8UaHimAcyOA6DFWwFYVrpDvNODJ3HheEVRo5NdJBARV1Uvj3L1GH0067MMwsNz',
      };
    case BuildVariant.BETA:
      return {
        AMPLITUDE_API_KEY_ANONYMOUS: 'fec6af8cfefbed2d7a667c7afe2ae61e',
        AMPLITUDE_API_KEY_AUTHENTICATED: 'bfddd43b35c1565195e311da1f0e2eb0',
        AMPLITUDE_API_KEY_COMMUNITIES: 'ff804a0300c8f31ea4de39bb7cf45bb7',
        ANDROID_PACKAGE_NAME: 'com.piccup.staging',
        BUILD_VARIANT: BuildVariant.BETA,
        BUILD_VARIANT_STRING: 'beta',
        DEVELOPER_SETTINGS: true,
        FIREBASE_DYNAMIC_LINK_URL_SCHEME: 'link',
        GOOGLE_PLACES_API_KEY: 'AIzaSyC8LKU83EyCRNv0iNUxf5vCAGW8CQOFVGM',
        IOS_BUNDLE_ID: 'com.piccup.staging',
        STRIPE_CONNECT_ACCOUNT_ID: 'ca_FqjXG69W1WGUi0jiGM7XAvoxtEzhVypq',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_HPGD12ymda6W9evIcbQKebkA00kb8nJyxI',
      };
    case BuildVariant.PRODUCTION:
    default:
      return {
        AMPLITUDE_API_KEY_ANONYMOUS: 'fec6af8cfefbed2d7a667c7afe2ae61e',
        AMPLITUDE_API_KEY_AUTHENTICATED: 'bfddd43b35c1565195e311da1f0e2eb0',
        AMPLITUDE_API_KEY_COMMUNITIES: 'ff804a0300c8f31ea4de39bb7cf45bb7',
        ANDROID_PACKAGE_NAME: 'com.piccup',
        BUILD_VARIANT: BuildVariant.PRODUCTION,
        BUILD_VARIANT_STRING: 'prod',
        DEVELOPER_SETTINGS: false,
        FIREBASE_DYNAMIC_LINK_URL_SCHEME: 'link',
        GOOGLE_PLACES_API_KEY: 'AIzaSyC8LKU83EyCRNv0iNUxf5vCAGW8CQOFVGM',
        IOS_BUNDLE_ID: 'com.thepiccup.Piccup',
        STRIPE_CONNECT_ACCOUNT_ID: 'ca_FqjXoM034VxwQRf7OILHfiMlm8xl2IYJ',
        STRIPE_PUBLISHABLE_KEY: 'pk_live_1mAnH7qLTmPwKuejRZL73b0Z002lZW3qb0',
      };
  }
};