import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Link from '@material-ui/core/Link';
import {useHistory} from 'react-router-dom';
import {Text, View} from 'react-native';
import {useTextStyles} from '@heylo/shared/src/services/styles/useTextStyles';
import {StyleConstants} from '@heylo/shared/src/styles/Styles';
import {SidebarIcon} from './SidebarIcon';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {IconButton} from 'react-native-paper';

export type Breadcrumb = {
  label: string,
  to: string,
};

type Props = {
  crumbs?: Array<Breadcrumb | null>,
  title: string,
};

export const Breadcrumbs = (props: Props) => {
  const {crumbs = [], title} = props;
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const text = useTextStyles();

  const {isSignedIn} = useSelector((state: RootState) => ({
    isSignedIn: !!selectActiveUserId(state),
  }), shallowEqual);

  const numCrumbs = crumbs.length;
  const hasCrumbs = numCrumbs > 0;

  const showBreadcrumbs = !matchXs && hasCrumbs;
  const onBreadcrumbPress = (crumb: Breadcrumb) => {
    history.push(crumb.to);
  };

  const showDrawerIcon = matchXs && !hasCrumbs && isSignedIn;

  const showBackButton = matchXs && !showDrawerIcon;
  const goBack = () => {
    if (hasCrumbs) {
      history.push(crumbs[numCrumbs - 1]?.to || '');
    } else {
      history.goBack();
    }
  };

  return (
      <View style={{
        alignItems: matchXs ? 'center' : 'baseline',
        flexDirection: 'row',
        justifyContent: matchXs ? 'center' : undefined,
        paddingLeft: matchXs ? undefined : StyleConstants.SPACING,
        paddingTop: StyleConstants.SPACING,
      }}>
        {showBreadcrumbs && (
            <Text style={[text.h6, {color: '#8f8f8f'}]}>
              {crumbs.map((crumb, index) => {
                if (!crumb) {
                  return null;
                }
                return (
                    <span key={`breadcrumb${index}`}
                          style={{cursor: 'pointer'}}>
                      <Link color='inherit'
                            onClick={() => onBreadcrumbPress(crumb)}>
                        {crumb.label}
                      </Link>
                      {' > '}
                    </span>
                );
              })}
            </Text>
        )}
        {showBackButton && (
            <View style={{position: 'absolute', left: 4}}>
              <IconButton
                  color={'black'}
                  icon={'arrow-left'}
                  onPress={goBack}
              />
            </View>
        )}
        {showDrawerIcon && (
            <View style={{position: 'absolute', left: 4}}>
              <SidebarIcon/>
            </View>
        )}

        <Text
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={[text.h5, matchXs ? {
              textAlign: 'center',
              width: '75vw',
            } : {}]}>
          {title}
        </Text>
      </View>
  );
};