export const GetExtensionForMimeType = (mime: string) => {
  switch (mime) {
    case 'image/jpg':
    case 'image/jpeg':
      return '.jpg';
    case 'image/png':
      return '.png';
    case 'image/gif':
      return '.gif';
  }
  return '';
};