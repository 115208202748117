import {
  Listeners,
  ThreadListeners,
} from '@heylo/shared/src/features/firebase/Listeners';
import {
  CommunityPhotoAlbum,
  SharedPhoto,
} from '@heylo/shared/src/types/firebase-types';
import {
  ACTION_ADD_ALBUM_PHOTOS,
  ACTION_REMOVE_ALBUM_PHOTOS,
  ACTION_STORE_COMMUNITY_ALBUM,
  ACTION_STORE_COMMUNITY_PHOTO,
  ACTION_STORE_PRIVATE_CHAT_PHOTO,
} from '@heylo/shared/src/features/photos/Slice';
import {
  LastPhotoTimestampForCommunitySelector,
  LastPhotoTimestampForPrivateChatSelector,
} from '@heylo/shared/src/features/photos/Selectors';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import moment from 'moment';
import {DataSnapshot, FirebaseDatabase} from '@heylo/firebase-database';

export class PhotosFirebase {
  static attachCommunityAlbumsListener = (communityId: string) => {
    return (dispatch: any) => {
      if (Listeners.PHOTOS_COMMUNITY_ALBUMS[communityId]) {
        return;
      }
      const ref = FirebaseDatabase().ref(`/communityPhotoAlbums/${communityId}`);
      Listeners.PHOTOS_COMMUNITY_ALBUMS[communityId] = ref;
      const updateAlbum = (snapshot: DataSnapshot | null) => {
        if (!snapshot) {
          return;
        }
        const albumId = snapshot.key;
        if (!albumId) {
          return;
        }
        const album: CommunityPhotoAlbum | null = snapshot.val() || null;
        if (album) {
          album.albumId = albumId;
          album.communityId = communityId;
        }
        dispatch(ACTION_STORE_COMMUNITY_ALBUM({communityId, albumId, album}));
      };
      const handleError = (e: Error) => {
        console.log('lost connection to albums', communityId, e.message);
        delete Listeners.PHOTOS_COMMUNITY_ALBUMS[communityId];
      };
      ref.on('child_added', updateAlbum, handleError);
      ref.on('child_changed', updateAlbum, handleError);
      ref.on('child_removed', updateAlbum, handleError);
    };
  };

  static attachCommunityPhotosListener = (communityId: string): ThunkReturnType<void> => (dispatch: any, getState) => {
    if (Listeners.PHOTOS_COMMUNITY_PHOTOS[communityId]) {
      return;
    }
    const state = getState();
    const mostRecentTimestamp = LastPhotoTimestampForCommunitySelector(state, communityId);
    const ref = FirebaseDatabase()
        .ref(`/communityPhotos/${communityId}`)
        .orderByChild('creationTimestamp')
        .startAt(mostRecentTimestamp + 1);
    Listeners.PHOTOS_COMMUNITY_PHOTOS[communityId] = ref;
    ref.on('child_added',
        snapshot => {
          const photoId = snapshot?.key;
          if (!snapshot || !photoId) {
            return;
          }
          const photo: SharedPhoto | null = snapshot.val() || null;
          if (!photo) {
            return;
          }
          if (!photo.photoId) {
            photo.photoId = photoId;
          }
          if (!photo.communityId) {
            photo.communityId = communityId;
          }
          dispatch(ACTION_STORE_COMMUNITY_PHOTO({
            communityId,
            photoId,
            photo,
          }));
        },
        (e: Error) => {
          console.log('lost connection to community photos', communityId, e.message);
          delete Listeners.PHOTOS_COMMUNITY_PHOTOS[communityId];
        });
  };

  static attachPrivateChatPhotoListener = (threadId: string)
      : ThunkReturnType<void> => (dispatch, getState) => {
    if (ThreadListeners.PRIVATE_CHAT_PHOTOS[threadId]) {
      return;
    }
    const state = getState();
    const mostRecentTimestamp = LastPhotoTimestampForPrivateChatSelector(state, threadId);
    const ref = FirebaseDatabase()
        .ref(`/chatPhotos/${threadId}`)
        .orderByChild('creationTimestamp')
        .startAt(mostRecentTimestamp + 1);
    ThreadListeners.PRIVATE_CHAT_PHOTOS[threadId] = ref;
    ref.on('child_added',
        snapshot => {
          const photoId = snapshot?.key;
          if (!snapshot || !photoId) {
            return;
          }
          const photo: SharedPhoto | null = snapshot.val() || null;
          if (!photo) {
            return;
          }
          photo.photoId = photoId;
          dispatch(ACTION_STORE_PRIVATE_CHAT_PHOTO({
            threadId,
            photoId,
            photo,
          }));
        },
        (e: Error) => {
          console.log('lost connection to chatPhotos', threadId, e);
          delete ThreadListeners.PRIVATE_CHAT_PHOTOS[threadId];
        });
  };

  static detachPrivateChatPhotoListener = (threadId: string) => {
    const ref = ThreadListeners.PRIVATE_CHAT_PHOTOS[threadId];
    if (ref) {
      ref.off();
    }
    delete ThreadListeners.PRIVATE_CHAT_PHOTOS[threadId];
  };

  static addPhotosToAlbum = (communityId: string, albumId: string, photoIds: string[])
      : ThunkReturnType<Promise<void>> => dispatch => {
    return new Promise((resolve, reject) => {
      const updates: { [photoId: string]: number } = {};
      photoIds.forEach(photoId => {
        updates[photoId] = moment().valueOf();
      });
      FirebaseDatabase()
          .ref(`/communityPhotoAlbums/${communityId}/${albumId}/photos`)
          .update(updates)
          .then(() => {
            dispatch(ACTION_ADD_ALBUM_PHOTOS({communityId, albumId, photoIds}));
            resolve();
          })
          .catch(e => {
            console.warn('addPhotosToAlbum', e.message);
            reject();
          });
    });
  };

  static removePhotosFromAlbum = (communityId: string, albumId: string, photoIds: string[])
      : ThunkReturnType<Promise<void>> => dispatch => {
    return new Promise<void>((resolve, reject) => {
      const updates: { [photoId: string]: null } = {};
      photoIds.forEach(photoId => {
        updates[photoId] = null;
      });
      FirebaseDatabase()
          .ref(`/communityPhotoAlbums/${communityId}/${albumId}/photos`)
          .update(updates)
          .then(() => {
            dispatch(ACTION_REMOVE_ALBUM_PHOTOS({
              communityId,
              albumId,
              photoIds,
            }));
            resolve();
          })
          .catch(e => {
            console.warn('removePhotosFromAlbum', e.message);
            reject();
          });
    });
  };
}
