import React, {useEffect} from 'react';
import {ScrollToTopOnMount} from '../navigation/ScrollToTopOnMount';
import {Chat} from '@heylo/components/src/features/chat/Chat';
import {ChatScreenContextProvider} from '@heylo/components/src/features/chat/ChatScreenContext';
import {useChatContextForWeb} from '../chat/useChatContextForWeb';
import {View} from 'react-native';

export const ThreadScreen = React.memo(() => {
  console.count('count ThreadScreen');

  const chatContext = useChatContextForWeb();
  useEffect(() => {
    console.count('count ThreadScreen chatContext');
  }, [chatContext]);

  return (
      <View style={{flex: 1, height: '100%'}}>
        <ScrollToTopOnMount/>
        <ChatScreenContextProvider value={chatContext}>
          <Chat key={`chat${chatContext.threadId}`}/>
        </ChatScreenContextProvider>
      </View>
  );
});

// @ts-ignore
ThreadScreen.whyDidYouRender = true;