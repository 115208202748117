import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  CloseDrawer,
  OpenDrawer,
} from '@heylo/shared/src/features/navigation/Slice';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {selectDrawerIsOpen} from '@heylo/shared/src/features/navigation/Selectors';
import {IconButton} from 'react-native-paper';

export const SidebarIcon = () => {
  const dispatch = useDispatch();

  const {
    isDrawerOpen,
  } = useSelector((state: RootState) => ({
    isDrawerOpen: selectDrawerIsOpen(state),
  }), shallowEqual);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    dispatch(drawerOpen ? CloseDrawer() : OpenDrawer());
  };

  useEffect(() => {
    setDrawerOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  return (
      <IconButton
          color={'black'}
          icon={'menu'}
          onPress={handleDrawerToggle}
      />
  );
};