import React from 'react';
import {View, ViewStyle} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import {
  CommunityContentSendStateSelector,
  ContentSendStateSelector,
} from '@heylo/shared/src/features/ui/Selectors';
import {SendState} from '@heylo/shared/src/features/ui/Slice';
import {ActivityIndicator} from 'react-native-paper';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';

type OwnProps = {
  communityId?: string,
  containerStyle?: ViewStyle,
  contentId: string,
};

const mapState = (state: RootState, ownProps: OwnProps) => {
  const {communityId, contentId} = ownProps;
  const sendState = communityId
      ? CommunityContentSendStateSelector(state, communityId, contentId)
      : ContentSendStateSelector(state, contentId);
  return {
    hasLoaded: sendState === SendState.SUCCESS,
  };
};

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & OwnProps;

export const LoadingContentIndicator = connector((props: Props) => {
  const {
    containerStyle,
    hasLoaded,
  } = props;

  const palette = usePalette();

  if (hasLoaded) {
    return <View/>;
  }
  return (
      <View style={containerStyle || {
        width: '100%',
      }}>
        <ActivityIndicator color={palette.grey.main} size={20}/>
      </View>
  );
});