import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {AppColdStart, AppReset} from '@heylo/shared/src/features/app/Actions';
import {DeepReadonly} from 'utility-types';
import {
  CommunityProfile,
  Marketplace,
  MarketplaceCommunity,
  MarketplaceMembership,
} from '@heylo/shared/src/types/firebase-types';
import {SigningOutAction} from '../auth/Slice';
import {Platform} from 'react-native';

export type MarketplacesState = {
  // If a user is in the process of joining a community, the community's
  // metadata will be stored here.
  activeCommunityJoinRequest: DeepReadonly<CommunityProfile> | null,
  activeMarketplaceId: string,
  marketplaceCommunities: DeepReadonly<{ [marketplaceId: string]: { [communityId: string]: MarketplaceCommunity } }>,
  marketplaceLastUpdateTimestamp: DeepReadonly<{ [marketplaceId: string]: number }>,
  marketplaceMemberships: DeepReadonly<{ [marketplaceId: string]: boolean }>,
  marketplaces: DeepReadonly<{ [marketplaceId: string]: Marketplace }>,
};

const initialState: MarketplacesState = {
  activeCommunityJoinRequest: null,
  activeMarketplaceId: '',
  marketplaceCommunities: {},
  marketplaceLastUpdateTimestamp: {},
  marketplaceMemberships: {},
  marketplaces: {},
};

const cleanupOrphanedMarketplaces = (marketplaceIds: Set<string>, state: Draft<MarketplacesState>) => {
  for (const marketplaceId of marketplaceIds) {
    delete state.marketplaces[marketplaceId];
    delete state.marketplaceLastUpdateTimestamp[marketplaceId];
    delete state.marketplaceMemberships[marketplaceId];
    if (state.activeMarketplaceId === marketplaceId) {
      state.activeMarketplaceId = '';
    }
  }
};

export const slice = createSlice({
  name: 'marketplaces',
  initialState,
  reducers: {

    ActiveMarketplaceChanged: (state, action: PayloadAction<string>) => {
      const marketplaceId = action.payload;
      if (typeof marketplaceId === 'string') {
        state.activeMarketplaceId = marketplaceId;
      }
    },

    CommunityJoinRequestUpdated: (state, action: PayloadAction<CommunityProfile | null>) => {
      state.activeCommunityJoinRequest = action.payload;
    },

    MarketplacesLoaded: (state, action: PayloadAction<{ [marketplaceId: string]: Marketplace }>) => {
      const marketsMap = action.payload;
      const orphanedMarketplaceIds = new Set(Object.keys(state.marketplaces));
      for (const [marketplaceId, marketplace] of Object.entries(marketsMap)) {
        state.marketplaces[marketplaceId] = {...marketplace, marketplaceId};
        orphanedMarketplaceIds.delete(marketplaceId);
      }
      cleanupOrphanedMarketplaces(orphanedMarketplaceIds, state);
    },

    MarketplaceCommunityLoaded: (state, action: PayloadAction<{ marketplaceId: string, communityId: string, community: MarketplaceCommunity }>) => {
      const {communityId, marketplaceId, community} = action.payload;
      if (!state.marketplaceCommunities) {
        state.marketplaceCommunities = {};
      }
      if (!state.marketplaceCommunities[marketplaceId]) {
        state.marketplaceCommunities[marketplaceId] = {};
      }
      state.marketplaceCommunities[marketplaceId][communityId] = {
        ...community,
        profile: {
          ...community.profile,
          communityId,
        },
      };

      const {timestampUpdated} = community;
      if (!state.marketplaceLastUpdateTimestamp) {
        state.marketplaceLastUpdateTimestamp = {};
      }
      state.marketplaceLastUpdateTimestamp[marketplaceId] =
          Math.max(
              state.marketplaceLastUpdateTimestamp[marketplaceId] || 0,
              timestampUpdated || 0);
    },

    MarketplaceMembershipsUpdated: (state, action: PayloadAction<{ [marketplaceId: string]: MarketplaceMembership }>) => {
      const membershipMap = action.payload;
      state.marketplaceMemberships = {};
      for (const marketplaceId of Object.keys(membershipMap)) {
        state.marketplaceMemberships[marketplaceId] = true;
      }
    },

  },

  extraReducers: builder => builder
      .addCase(AppColdStart, (state) => {
        if (Platform.OS === 'web') {
          // Web uses the URL to determine the active marketplace.
          return;
        }
        state.activeMarketplaceId = '';
      })
      .addCase(AppReset, () => initialState)
      .addCase(SigningOutAction, (state) => {
        state.activeCommunityJoinRequest = null;
        state.activeMarketplaceId = '';
        for (const [marketplaceId, marketplace] of Object.entries(state.marketplaces)) {
          const {emailVerificationDomain} = marketplace;
          if (emailVerificationDomain) {
            delete state.marketplaceMemberships[marketplaceId];
          }
        }
      }),

});

const {actions, reducer: MarketplacesReducer} = slice;

const {
  ActiveMarketplaceChanged,
  CommunityJoinRequestUpdated,
  MarketplaceCommunityLoaded,
  MarketplaceMembershipsUpdated,
  MarketplacesLoaded,
} = actions;

export {
  ActiveMarketplaceChanged,
  CommunityJoinRequestUpdated,
  MarketplaceCommunityLoaded,
  MarketplaceMembershipsUpdated,
  MarketplacesLoaded,
  MarketplacesReducer,
};
