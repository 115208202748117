import {ActiveEventChanged} from '@heylo/shared/src/features/events/Slice';
import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@heylo/shared/src/services/redux/Redux';
import {
  selectActiveEvent,
  selectActiveEventId,
} from '@heylo/shared/src/features/events/Selectors';

export const useActiveEventState = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const eventId = params.get('eventId');
    dispatch(ActiveEventChanged(eventId || ''));
  }, [location]);

  const {
    event,
    eventId,
  } = useSelector((state: RootState) => ({
    event: selectActiveEvent(state),
    eventId: selectActiveEventId(state),
  }), shallowEqual);

  return {event, eventId};
};