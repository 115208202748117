import {Button, Dialog, Paragraph, Portal} from 'react-native-paper';
import React from 'react';
import {usePalette} from '@heylo/shared/src/services/styles/usePalette';

type Props = {
  actions?: { color?: string, label: string, onPress: () => void }[]
  body: string,
  onDismiss: () => void,
  title: string,
  visible: boolean,
};

export const HeyloDialog = (props: Props) => {
  const {onDismiss} = props;
  const {
    actions = [{label: 'OK', onPress: onDismiss}],
    body,
    title,
    visible,
  } = props;
  const palette = usePalette();
  return (
      <Portal>
        <Dialog
            onDismiss={onDismiss}
            style={{alignSelf: 'center', maxWidth: 400}}
            visible={visible}
        >
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{body}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            {actions.map(action => {
              const {
                color = palette.primary.main,
                label = 'OK',
                onPress = onDismiss,
              } = action;
              return (
                  <Button
                      color={color}
                      key={`key${label}`}
                      onPress={() => {
                        onPress();
                        onDismiss();
                      }}
                  >
                    {label}
                  </Button>
              );
            })}
          </Dialog.Actions>
        </Dialog>
      </Portal>
  );
}