import {
  FirebaseDatabase,
  FirebaseFunctions,
  FirebaseServerTimestamp,
} from '@heylo/firebase-database';
import {v4 as uuidv4} from 'uuid';
import {
  Listeners,
  RetriableFirebaseListener,
} from '@heylo/shared/src/features/firebase/Listeners';
import {ThunkReturnType} from '@heylo/shared/src/services/redux/Redux';
import {
  StripeAccountMappingUpdated,
  StripeAccountUpdated,
  StripeRemovePaymentMethod,
  StripeUserUpdated,
} from './Slice';
import {
  StripeAccount,
  StripeCommunityPayment,
  StripeUser,
} from '@heylo/shared/src/types/firebase-types';
import {
  selectActiveUserEmail,
  selectActiveUserFullName,
} from '@heylo/shared/src/features/userSettings/Selectors';
import {selectActiveUserId} from '@heylo/shared/src/features/auth/Selectors';

export const StripeFirebase = {

  GenerateOAuthUrl: (params: {
    build: string,
    communityId: string,
    communityName: string,
    stripeConnectAccountId: string,
    userEmail: string,
    userFirstName: string,
    userLastName: string,
  }) => {
    return new Promise<string>(resolve => {
      const {
        build,
        communityId,
        communityName,
        stripeConnectAccountId,
        userEmail,
        userFirstName,
        userLastName,
      } = params;
      const secret = uuidv4();
      FirebaseDatabase().ref(`/stripe/oauth/${communityId}`).set({
        secret: secret,
        timestampCreation: FirebaseServerTimestamp(),
      })
          .then(() => resolve(
              `https://connect.stripe.com/express/oauth/authorize?client_id=${stripeConnectAccountId}&state=${build}:${communityId}:${secret}&scope=read_write&response_type=code&stripe_user[email]=${userEmail}&stripe_user[business_name]=${communityName}&stripe_user[first_name]=${userFirstName}&stripe_user[last_name]=${userLastName}&stripe_user[url]=https://app.heylo.co/community?communityId=${communityId}&stripe_user[business_name]=${communityName}&stripe_user[country]=US`));
    });
  },

  CommunityMetadataListener: (communityId: string)
      : ThunkReturnType<void> => dispatch => {
    if (Listeners.STRIPE_COMMUNITY_METADATA[communityId]) {
      return;
    }
    RetriableFirebaseListener(retry => {
      const ref = FirebaseDatabase().ref(`/stripe/communityToAccountMap/${communityId}`);
      Listeners.STRIPE_COMMUNITY_METADATA[communityId] = ref;
      ref.on('value', snapshot => {
        const accountId = snapshot?.val() ?? '';
        dispatch(StripeAccountMappingUpdated({communityId, accountId}));
        if (accountId) {
          dispatch(StripeFirebase.AccountListener(accountId));
        }
      }, (e: Error) => {
        delete Listeners.STRIPE_COMMUNITY_METADATA[communityId];
        if (retry(e)) {
          return;
        }
        console.warn('lost connection to stripe community metadata', communityId, e.message);
      });
    });
  },

  CommunityPaymentsListener: (communityId: string)
      : ThunkReturnType<void> => dispatch => {
    if (Listeners.STRIPE_COMMUNITY_PAYMENTS[communityId]) {
      return;
    }
    const ref = FirebaseDatabase().ref(`/stripe/communityPayments/${communityId}`);
    Listeners.STRIPE_COMMUNITY_PAYMENTS[communityId] = ref;
    ref.on('value', snapshot => {
      const payments: { [paymentId: string]: StripeCommunityPayment } = snapshot?.val() ?? {};
      // TODO: implement

      // dispatch(StripeAccountMappingUpdated({communityId, accountId}));
      // if (accountId) {
      //   dispatch(StripeFirebase.AccountListener(accountId));
      // }
    }, (e: Error) => {
      delete Listeners.STRIPE_COMMUNITY_PAYMENTS[communityId];
      console.warn(`lost connection to /stripe/communityPayments/${communityId} ${e.message}`);
    });
  },

  AccountListener: (accountId: string)
      : ThunkReturnType<void> => dispatch => {
    if (Listeners.STRIPE_ACCOUNTS[accountId]) {
      return;
    }
    const ref = FirebaseDatabase().ref(`/stripe/accounts/${accountId}`);
    Listeners.STRIPE_ACCOUNTS[accountId] = ref;
    ref.on('value', snapshot => {
      const account: StripeAccount = snapshot?.val() ?? {};
      dispatch(StripeAccountUpdated({accountId, account}));
    }, (e: Error) => {
      delete Listeners.STRIPE_ACCOUNTS[accountId];
      console.warn('lost connection to stripe account', accountId, e.message);
    });
  },

  UserListener: (userId: string, buildVariant: string)
      : ThunkReturnType<void> => (dispatch, getState) => {
    if (Listeners.STRIPE_USER[userId]) {
      return;
    }
    const ref = FirebaseDatabase().ref(`/stripe/users/${userId}`);
    Listeners.STRIPE_USER[userId] = ref;
    ref.on('value', snapshot => {
      const user: StripeUser | null = snapshot?.val() ?? null;
      dispatch(StripeUserUpdated(user));
      if (!user) {
        const state = getState();
        const email = selectActiveUserEmail(state);
        const name = selectActiveUserFullName(state);
        FirebaseFunctions().httpsCallable('stripeCreateCustomer')({
          build: buildVariant,
          email,
          name,
          userId,
        })
            .catch((e: Error) => {
              console.warn('stripeCreateCustomer failed', e);
            });
      }
    }, (e: Error) => {
      delete Listeners.STRIPE_USER[userId];
      console.warn('lost connection to /stripe/users', userId, e.message);
    });
  },

  RemovePaymentMethod: ()
      : ThunkReturnType<void> => async (dispatch, getState) => {
    const state = getState();
    const userId = selectActiveUserId(state);
    dispatch(StripeRemovePaymentMethod());
    await FirebaseDatabase().ref(`/stripe/users/${userId}/paymentMethod`).set(null);
  },
};